import { AppBar, Box, createStyles, Dialog, IconButton, makeStyles, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Toolbar, Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import printImg from "../../images/ACKReport/AckPrint.svg";
import { IAckBuilder } from '../../models/IAckReport/IAckBuilder';
import { IPrintAck } from '../../models/IAckReport/IPrintAck';
import { IRollClients } from '../../models/Roll/IRollClients';
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import Footer from '../Footer/Footer';
import AvatarTooltip from '../GlobalStyles/AvatarTooltip';
import LightTooltip from '../GlobalStyles/LightTooltip';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        tablebodyPrint: {
            overflowX: 'hidden',
            marginTop: 0,
        },
        appBar: {
            position: 'relative',
            color: 'white',
            backgroundColor: '#007fff',
            minHeight: '35px'
        },
        printTitle: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: 18,
            textAlign: 'center'
        },
        CloseIconColor: {
            color: 'white'
        },
        report: {
            overflowX: 'hidden'
        },
        menuButton: {
            marginRight: theme.spacing(2),
            width: 150
        },
        sumGap3: {
            gap: '5%',
            fontWeight: 'bold',
            marginTop: '8px',
            fontSize: 24,
            color: 'black'
        },
        sumGap5: {
            gap: '5%',
            marginTop: '3px',
            fontSize: 17,
            color: 'black'
        },
        sumGap4: {
            gap: '5%',
            fontSize: 15,
            color: 'green'
        },
        totalRecord: {
            gap: '5%',
            marginRight: '10px',
            backgroundColor: '#316b97',
            borderRadius: 10,
            color: 'white',
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
            width: '310px',
            height: '100px',
            marginTop: 15,
            flexDirection: 'row',
            justifyContent: 'center',
        },
        textStyle1: {
            textAlign: 'right',
            marginTop: '15px',
            marginRight: 10,
            fontSize: '14px'
        },
        textStyle: {
            textAlign: 'right',
            marginTop: '15px',
            fontSize: '14px'
        },
        bottomFooter: {
            marginBottom: '5px'
        },
        wrapper: {
            position: 'relative'
        },
        tableHeaderStyle: {
            background: "#007FFF",
            color: "white",
            fontSize: "14px"
        },
        tableCellColor: {
            background: "#007FFF",
            color: "white",
            padding: '3px 10px',
            fontSize: '12px'
        },
        tableCellStyle: {
            padding: '5px 10px',
            fontSize: '12px'
        },
        spinnerStyle: {
            marginTop: '350px',
            marginBottom: '5px'
        }
    })
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AckPrintView: React.FC<{ phaseId: number, isAvailable: boolean, startDate: string, endDate: string, client_code: IRollClients[] }> = (props) => {
    const { phaseId, isAvailable, startDate, endDate, client_code } = props;
    const { state } = useGlobalState();
    const classes = useRowStyles();
    const [printbox, setPrintBox] = useState(false);
    const componentRef = useRef<HTMLDivElement>(null);
    const pageStyle = `{ size: 11.7in 16.5in}`;
    const [sumAck, setSumAck] = useState<IPrintAck>();
    const [loading, setloading] = useState(false);
    const [exportAck, setExportAck] = useState<IAckBuilder[]>([]);

    const GetAckPrint = () => {
        setloading(true);
        (async () => {
            let requestExport = {
                "phaseId": phaseId,
                "clientCodes": client_code,
                "startDate": moment(startDate).format("YYYY-MM-DD"),
                "endDate": moment(endDate).format("YYYY-MM-DD"),
                "orderDirection": 1,
                "orderBy": "DBT_NO"
            }
            await usePost<{ ackReports: IAckBuilder[], ackReportSummary: IPrintAck }>("ExportAckReport", requestExport).then((r) => {
                setExportAck(r.data["ackReports"]);
                setSumAck(r.data["ackReportSummary"][0]);
            }).finally(() => {
                setloading(false);
            });
        })()
    }

    const handleOpenDialog = () => {
        setPrintBox(true);
        GetAckPrint();
    }

    const handleCloseDialog = () => {
        setPrintBox(false);
        setExportAck([]);
    }

    return (
        <React.Fragment>
            <div className={classes.wrapper}>
                <AvatarTooltip title="Print Report">
                    <IconButton>
                        <img src={printImg} alt="Icon for Print" width="25px" height="25px" onClick={(_) => { isAvailable ? handleOpenDialog() : null }} />
                    </IconButton>
                </AvatarTooltip>
            </div>
            <Dialog fullScreen open={printbox} onClose={handleCloseDialog} TransitionComponent={Transition}>
                <AppBar className={classes.appBar} >
                    <Toolbar className={classes.appBar}>
                        <Box display="flex" alignItems="center" alignContent="center" width="95%">
                            <Typography variant="h6" className={classes.printTitle}>
                                ACKNOWLEDGEMENT REPORT
                            </Typography>
                        </ Box>
                        <Box display="flex" alignItems="right" alignContent="right" width="5%">
                            <div>
                                <style type="text/css" media="print">
                                    {"\
                        @page { size: landscape; }\
                        "}
                                </style>
                                <ReactToPrint pageStyle={pageStyle}
                                    trigger={() =>
                                        <LightTooltip title="Print Reports List">
                                            <IconButton color="primary" id="RB_PrintIcon" aria-label="print" component="label">
                                                <img src={printImg} alt="Icon for Print" width="22px" height="22px" />
                                            </IconButton>
                                        </LightTooltip>
                                    }
                                    documentTitle={`Acknowledgement Report - ${moment(startDate).format(state?.GlobalUtils?.settingValue)} to ${moment(endDate).format(state?.GlobalUtils?.settingValue)}`}
                                    content={() => componentRef.current}
                                />
                            </div>
                            <IconButton edge="end" color="inherit" onClick={handleCloseDialog} aria-label="close">
                                <CloseIcon fontSize="small" className={classes.CloseIconColor} />
                            </IconButton>
                        </ Box>
                    </Toolbar>
                </AppBar>
                <div ref={componentRef} className={classes.report}>
                    {loading === true ?
                        <React.Fragment>
                            <Box display="flex" alignItems="center" justifyContent="center" className={classes.spinnerStyle}>
                                <div className="loader" />
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="center" >
                                <span>Loading Report Print Preview</span>
                            </Box>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Box display='flex' m={1}>
                                <Box width="80%" style={{ marginLeft: 10 }}>
                                    <img alt="ARM_Solutions" className={classes.menuButton} src="https://www.armsolutions.com/website/wp-content/themes/arm-solutions/style/images/header-logo.png" />
                                    {/*   <Typography variant='h6' className={classes.sumGap3}>
                                        Mosaic Comprehensive Care, PLLC
                                    </Typography> */}
                                    <Typography variant='h6' className={classes.sumGap5}>
                                        Placement Date : {moment(new Date()).format('DD/MM/YYYY')}
                                    </Typography>
                                    <Typography variant='h6' className={classes.sumGap4}>
                                        {phaseId === 1 ? "1ST Party" : phaseId === 3 ? "Contingency" : "Flat Fee"}
                                    </Typography>
                                </Box>
                                <Box width="20%" display="flex" className={classes.totalRecord} >
                                    <div>
                                        <Typography variant='h6' className={classes.textStyle1}>
                                            Total Number Placed &nbsp; :
                                        </Typography>
                                        <Typography variant='h6' className={classes.textStyle1}>
                                            Total Principal Placed &nbsp; :
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant='h6' className={classes.textStyle}>
                                            <b>{sumAck?.total_Placed}</b>
                                        </Typography>
                                        <Typography variant='h6' className={classes.textStyle}>
                                            <b>${sumAck?.total_Principal?.toFixed(2)}</b>
                                        </Typography>
                                    </div>
                                </Box>
                            </Box>
                            <Box display='flex' m={2} >
                                <TableContainer component={Paper} className={`${classes.tablebodyPrint} ${"scrollbox"} ${"on-scrollbar"}`} >
                                    <Table id="AckReportsDetailList" aria-label="customized table" size="small" stickyHeader >
                                        <TableHead>
                                            <TableRow className={classes.tableHeaderStyle}>
                                                <TableCell className={classes.tableCellColor}>PHASE</TableCell>
                                                <TableCell className={classes.tableCellColor}>PLACEMENT DATE</TableCell>
                                                <TableCell className={classes.tableCellColor}>CLIENT CODE</TableCell>
                                                <TableCell className={classes.tableCellColor}>AGENCY ID</TableCell>
                                                <TableCell className={classes.tableCellColor}>ACCOUNT ID</TableCell>
                                                <TableCell className={classes.tableCellColor}>NAME</TableCell>
                                                <TableCell className={classes.tableCellColor}>DELINQUENCY DATE</TableCell>
                                                <TableCell className={classes.tableCellColor} align='right'>AMOUNT PLACED</TableCell>
                                                <TableCell className={classes.tableCellColor}>REFERRAL DATE</TableCell>
                                                <TableCell className={classes.tableCellColor}>PC REFERRAL DATE</TableCell>
                                                <TableCell className={classes.tableCellColor}>STATUS</TableCell>
                                                <TableCell className={classes.tableCellColor}>DESCRIPTION</TableCell>
                                                <TableCell className={classes.tableCellColor}>STATUS CHANGE DATE</TableCell>
                                                <TableCell className={classes.tableCellColor}>DESK</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {exportAck?.map((row) => {
                                                return (
                                                    <TableRow key={row?.dbT_CLNT_ACNT1}>
                                                        <TableCell className={classes.tableCellStyle}>{row?.dbT_COMPANY}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{row?.placemenT_DATE !== null ? moment(row?.placemenT_DATE).format(state.GlobalUtils?.settingValue) : row?.placemenT_DATE}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{row?.dbT_CLIENT}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{row?.dbT_NO}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{row?.dbT_CLNT_ACNT1}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{row?.dbT_NAME}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{row?.dbT_LAST_CHG_DATE !== null ? moment(row?.dbT_LAST_CHG_DATE).format(state.GlobalUtils?.settingValue) : row?.dbT_LAST_CHG_DATE}</TableCell>
                                                        <TableCell className={classes.tableCellStyle} align='right'>${row?.amounT_PLACED?.toFixed(2)}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{moment(row?.dbT_REFERRAL_DATE).format(state.GlobalUtils?.settingValue)}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{moment(row?.dbT_PC_REFERRAL_DATE).format(state.GlobalUtils?.settingValue)}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{row?.status}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{row?.statuS_DESCRIPTION}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{moment(row?.statuS_CHANGE_DATE).format(state.GlobalUtils?.settingValue)}</TableCell>
                                                        <TableCell className={classes.tableCellStyle}>{row?.desk}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                            <TableRow>
                                                <TableCell className={classes.tableCellStyle} rowSpan={9} colSpan={9} />
                                                <TableCell className={classes.tableCellStyle} colSpan={4}>Total Number Placed</TableCell>
                                                <TableCell className={classes.tableCellStyle} align='right'><b>{sumAck?.total_Placed}</b></TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell className={classes.tableCellStyle} colSpan={4}>Total Principal Placed</TableCell>
                                                <TableCell className={classes.tableCellStyle} align='right'><b>${sumAck?.total_Principal?.toFixed(2)}</b></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <div className={classes.bottomFooter}>
                                <Footer />
                            </div>
                        </React.Fragment>
                    }
                </div>
            </Dialog>
        </React.Fragment>
    )
}

export default AckPrintView