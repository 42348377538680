import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { IAckList } from '../../models/IAckReport/IAckList';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useDelete } from '../../utils/apiHelper';
import { DialogTitleDelete } from '../GlobalStyles/DialogStyle';

const DeleteAckReport: React.FC<{ reportRow: IAckList, onDelete: () => void }> = (props) => {
  const { reportRow } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { state, dispatch } = useGlobalState();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [show, setShow] = React.useState(false);
  const [disable, setDisable] = useState(false);
  const [ProgressBar, setShowProgressBar] = useState(false);

  const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
      yesButton: {
        background: "green",
        color: "white",
        marginRight: "8px",
        marginTop: "7px",
        float: 'right',
        borderRadius: 20,
        marginBottom: '0.3cm',
        '&:hover': {
          background: "green",
          color: "white",
        }
      },
      noButton: {
        background: "red",
        color: "white",
        marginTop: "7px",
        marginBottom: '0.3cm',
        marginRight: "10px",
        borderRadius: 20,
        '&:hover': {
          background: "red",
          color: "white",
        }
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
    })
  );
  const classes = useRowStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (state?.userAccessContext?.role === 2) {
      setDisable(false);
    }
    else if (state?.userAccessContext?.role === 3) {
      setDisable(false);
    }
    else {
      setDisable(true);
    }
  }, []);

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShow(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  async function handleOk() {
    dispatch({ type: GlobalStateAction.Busy });
    setOpen(false);
    setShowProgressBar(true);
    try {
      await useDelete("DeleteAckReport?reportId=" + reportRow.id);
      props.onDelete();
      setShow(true);
      setShowProgressBar(false);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex });
      dispatch({ type: GlobalStateAction.Idle });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
    }
  }

  return (
    <React.Fragment>
      <div>
        <Button id="R_Delete_btn" aria-label="delete"
          onClick={() => { handleClickOpen(); }} disabled={disable}
          style={{ marginLeft: "20px", borderRadius: 30 }}>
          <DeleteForeverIcon style={disable ? { color: "gray", fontSize: 30 } : { color: "red", fontSize: 30 }} />
        </Button>
        <Snackbar className="snackBarStyle" open={show} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
            Report Deleted Successfully!
          </Alert>
        </Snackbar>
      </div>
      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <div>
        <Dialog
          PaperProps={{ style: { borderRadius: 10 } }}
          fullScreen={fullScreen}
          open={open}
          maxWidth={'sm'}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
              DELETE MESSAGE
            </Typography>
          </DialogTitleDelete>
          <DialogContent>
            <Typography variant="h6" gutterBottom>
              Are you sure, you want to delete this Report ?
            </Typography>
          </DialogContent>
          <DialogActions >
            <Button id="DR_ok_btn" onClick={handleOk} className={classes.yesButton} color="primary" autoFocus>
              Yes
            </Button>
            <Button id="DR_cancel_btn" autoFocus onClick={handleCancel} className={classes.noButton} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
}
export default DeleteAckReport