import {
    Backdrop,
    Button, Dialog, DialogActions, DialogContent, Link, makeStyles, Snackbar, Typography,
    useMediaQuery, useTheme
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import { PuffLoader } from 'react-spinners';
import { usePost } from '../../../../utils/apiHelper';
import { DialogTitleHeader } from '../../../GlobalStyles/DialogStyle';

const useStyles = makeStyles((theme) => ({
    fileDownloadLink: {
        fontSize: 13,
        color: "blue",
        marginTop: '5px'
    },
    yesbutton: {
        backgroundColor: 'green',
        fontSize: 12,
        borderRadius: '20px',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green',
            color: 'white',
        }
    },
    nobutton: {
        background: "red",
        fontSize: 12,
        borderRadius: "20px",
        color: 'white',
        '&:hover': {
            backgroundColor: 'red',
            color: 'white',
        }
    },
    titleheader: {
        fontWeight: 'bold',
        fontSize: 18,
        marginTop: '5px',
        color: 'white'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    contentStyle: {
        color: "black",
        fontSize: 18
    }
}))

const DownloadFiles: React.FC<{ fileNameUri: string, fileUrl: string }> = (props) => {
    const classes = useStyles();
    const { fileNameUri, fileUrl } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const [fileExists, setFileExists] = useState(false);
    const [ProgressBar, setshowProgressBar] = useState(false);

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setFileExists(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleClickDownload(isDownload: boolean) {
        setOpen(false);
        if (isDownload) {
            DownloadPdfFileAsync(fileUrl, fileNameUri);
        }
    };

    async function DownloadPdfFileAsync(blobUri: string, fileName: string) {
        setshowProgressBar(true);
        (async () => {
            let request = {
                "pdfUri": blobUri
            }
            await usePost<any>("File/PDFDocumentFileDownload", request).then((r) => {
                const arrayBuffer = base64ToArrayBuffer(r.data);
                createAndDownloadBlobFile(arrayBuffer, fileName);
            }).catch(() => {
                setFileExists(true);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })();
    }

    function base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    function createAndDownloadBlobFile(body, filename) {
        const blob = new Blob([body]);
        const fileName = `${filename}`;
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    return (
        <React.Fragment>
            <Typography variant="body1" display="block" className={classes.fileDownloadLink} gutterBottom>
                <Link onClick={() => { setOpen(true) }} color="inherit">
                    {fileNameUri}
                </Link>
            </Typography>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <Dialog maxWidth={'sm'} PaperProps={{ style: { borderRadius: 15 } }}
                fullScreen={fullScreen} open={open}
                onClose={(_) => { handleClose }}
                aria-labelledby="responsive-dialog-title">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        DOWNLOAD FILE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Typography variant="h6" className={classes.contentStyle} gutterBottom>
                        Are you sure, you want to Download this file ?
                    </Typography>
                </DialogContent>
                <DialogActions >
                    <Button variant="contained" size="small" onClick={(_) => { handleClickDownload(true); }} className={classes.yesbutton} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button variant="contained" size="small" autoFocus className={classes.nobutton} onClick={(_) => { handleClose(); }} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={fileExists} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={() => { setFileExists(false) }} severity="error" className="alertStyle">
                    File does not exists!
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default DownloadFiles