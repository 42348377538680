import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileIcon from '@material-ui/icons/FileCopy';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import { Skeleton } from '@mui/material';
import axios from 'axios';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { apiKey, MassFileTrigger } from '../../../constants/Constant';
import massuploadimg from '../../../images/admin/massupload.svg';
import { IUserInvited } from '../../../models/common/IUserInvited';
import { useGlobalState } from '../../../store/GlobalStore';
import { useFetch } from '../../../utils/apiHelper';
import { DialogTitleDelete, DialogTitleHeader } from '../../GlobalStyles/DialogStyle';

const useStyles = makeStyles((theme) => ({
    button: {
        background: "#007FFF",
        color: "white",
        marginTop: "8px",
        borderRadius: "20px"
    },
    yesbutton: {
        backgroundColor: 'green',
        borderRadius: '20px',
        color: 'white',
        fontSize: 12,
        '&:hover': {
            backgroundColor: 'green',
            color: 'white',
        }
    },
    nobutton: {
        background: "red",
        borderRadius: "20px",
        color: 'white',
        fontSize: 12,
        '&:hover': {
            backgroundColor: 'red',
            color: 'white',
        }
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    IconButtonripple: {
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    image: {
        width: '70px',
        height: '70px',
        textAlign: 'center'
    },
    card1: {
        display: 'block',
        width: '5.5cm',
        height: '4cm',
        marginLeft: '30px',
        marginTop: '0.5cm',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        textDecoration: 'none',
        '&:hover': {
            color: 'black',
            backgroundColor: 'white',
            borderRadius: '10px',
            transform: 'scale(1.1)',
            border: '2px blue solid',
            cursor: 'pointer',
            boxShadow: '0 10px 10px rgba(0, 0, 0, 0.4)',
        },
    },
    text: {
        color: "black",
        textAlign: "center",
        fontSize: '16px',
        fontWeight: 'bold',
    },

    yesButton: {
        background: "red",
        color: 'white',
        borderRadius: 20,
        right: '15px',
        '&:hover': {
            background: "red",
        }
    },
    MessageStyle: {
        marginTop: "15px",
        color: "black",
        fontSize: 17
    },
    loadingMessage: {
        marginTop: '10px'
    },
    paper: {
        padding: '6px 16px',
    },
    fabProgressOne: {
        color: 'green',
        position: 'absolute',
        top: 6,
        left: 410,
        zIndex: 1
    },
    buttonSuccess: {
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        },
    },
    fabProgressTwo: {
        color: 'green',
        position: 'absolute',
        top: 6,
        left: 410,
        zIndex: 1
    },
    fabProgressThree: {
        color: 'green',
        position: 'absolute',
        top: 6,
        left: 410,
        zIndex: 1
    },
    errorColor: {
        color: 'red'
    },
    titleColor: {
        color: 'black'
    }
}))

const MassUpload = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { state } = useGlobalState();
    const [isExists, setisExists] = useState(false);
    const [openDialogBox, setOpenDialogBox] = useState(false);
    const [message, setMessage] = useState<string>('');
    const [openTimeLine, setOpenTimeLine] = useState(false);
    const [UserVerify, setUserVerify] = useState(false);
    const [VerifyFiles, setVerifyFiles] = useState(false);
    const [UploadFiles, setUploadFiles] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const timer = useRef<number>();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAlertClose = () => {
        setOpenDialogBox(false);
    };

    const handleProgressClose = () => {
        setOpenTimeLine(false);
        setOpen(false);
    }

    const handleGatheringFiles = () => {
        if (!VerifyFiles) {
            setSuccess(false);
            setVerifyFiles(true);
            timer.current = window.setTimeout(() => {
                setSuccess(true);
                setVerifyFiles(false);
                handleUploadFiles();
            }, 2000);
        }
    }

    const handleUploadFiles = () => {
        if (!UploadFiles) {
            setSuccess(false);
            setUploadFiles(true);
            timer.current = window.setTimeout(() => {
                setSuccess(true);
                setUploadFiles(false);
            }, 2000);
        }
    }

    const uploadMassFiles = () => {
        (async () => {
            await axios.post(`${MassFileTrigger}/${state?.userAccessContext?.id}/${apiKey}?`).then((r) => {
                if (r.status === 200) {
                    setOpen(false);
                    setOpenTimeLine(false);
                    setisExists(false);
                    setOpenDialogBox(true);
                    setMessage("Files are uploaded successFully");
                }
            }).catch((err) => {
                if (err.response.status == 500 || err.response.status == 400) {
                    setOpen(false);
                    setOpenTimeLine(false);
                    setOpenDialogBox(true);
                    setMessage("Error while uploading files, verify that all files are valid.");
                }
            });
        })()
    }

    const HandleCheckValidUser = () => {
        (async () => {
            if (state.userAccessContext?.id !== undefined) {
                await useFetch<IUserInvited[]>(`User/CheckUserInvited?userId=${state.userAccessContext?.id}`).then((r) => {
                    setIsValid(r.data[0]?.user_id !== undefined && r.data[0]?.user_id !== 0 ? true : false);
                    setOpenTimeLine(true);
                    if (!UserVerify) {
                        setSuccess(false);
                        setUserVerify(true);
                        timer.current = window.setTimeout(() => {
                            setSuccess(true);
                            setUserVerify(false);
                            if (r.data[0]?.user_id !== undefined && r.data[0]?.user_id !== 0) {
                                uploadMassFiles();
                                handleGatheringFiles();
                            }
                        }, 2000);
                    }
                })
            };
        })()
    }

    useEffect(() => {
        (async () => {
            await useFetch<any>("File/BlobFileExists").then((r) => {
                setisExists(r?.data?.files > 0 ? true : false);
            });
        })()
    }, [])

    return (
        <React.Fragment>
            <Dialog fullScreen={fullScreen} open={openDialogBox} onClose={handleAlertClose} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        MESSAGE
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h6" className={classes.MessageStyle} gutterBottom>
                            {message}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" variant="contained" onClick={(e) => { setOpenDialogBox(false); }} className={classes.yesButton}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen={fullScreen} open={openTimeLine} maxWidth={'md'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleProgressClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        Mass File Upload
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <Timeline align="alternate">
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="subtitle1" className={classes.titleColor}>
                                    Verify User
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    <AccountCircleIcon style={{ fontSize: '40px' }} />
                                    {UserVerify && <CircularProgress size={60} className={`${buttonClassname}${classes.fabProgressOne}`} />}
                                </TimelineDot>
                                <TimelineConnector style={{ height: "30px" }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    {UserVerify ?
                                        <Skeleton animation="wave" />
                                        :
                                        (isValid) ?
                                            <Typography variant="h6" component="h1">
                                                User Verified Successfully
                                            </Typography>
                                            :
                                            <Typography variant="h6" component="h1" className={classes.errorColor}>
                                                User not allowed to Upload Mass Files
                                            </Typography>
                                    }
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="subtitle1" className={classes.titleColor}>
                                    Gathering the Files
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    <FileIcon style={{ fontSize: '40px' }} />
                                    {VerifyFiles && <CircularProgress size={60} className={`${buttonClassname}${classes.fabProgressTwo}`} />}
                                </TimelineDot>
                                <TimelineConnector style={{ height: "30px" }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    {UserVerify || VerifyFiles ?
                                        <Skeleton animation="wave" />
                                        :
                                        (isValid) ?
                                            <Typography variant="h6" component="h1">
                                                Files Are Ready To Upload
                                            </Typography>
                                            :
                                            <Typography variant="h6" component="h1" className={classes.errorColor}>
                                                Files are not ready to upload
                                            </Typography>
                                    }
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="subtitle1" className={classes.titleColor}>
                                    Upload Files
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    <CloudUploadIcon style={{ fontSize: '40px' }} />
                                    {UploadFiles && <CircularProgress size={60} className={`${buttonClassname}${classes.fabProgressThree}`} />}
                                </TimelineDot>
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper elevation={3} className={classes.paper}>
                                    {UserVerify || VerifyFiles || UploadFiles ?
                                        <Skeleton animation="wave" />
                                        :
                                        (isValid) ?
                                            <Typography variant="h6" component="h1">
                                                Uploading....
                                            </Typography>
                                            :
                                            <Typography variant="h6" component="h1" className={classes.errorColor}>
                                                Files are not uploaded
                                            </Typography>
                                    }
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" variant="contained" onClick={(e) => { setOpenTimeLine(false); setOpen(false); }} className={classes.yesButton} disabled={UserVerify || VerifyFiles || UploadFiles}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {isExists === true ?
                <React.Fragment>
                    <Grid item xs={12} sm={6} md={4} lg={2} >
                        <Box className={classes.card1} onClick={handleClickOpen}>
                            <IconButton id="Admin_Page_NotificationRollBack_Button" aria-label="notification_rollback" onClick={handleClickOpen} className={classes.IconButtonripple} disabled={state?.busy}>
                                <img src={massuploadimg} alt="Admin_Mass_Document_Upload" className={classes.image} />
                            </IconButton>
                            <Box>
                                <Typography variant="h6" className={classes.text} onClick={handleClickOpen}>
                                    Mass Document Upload
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </React.Fragment>
                :
                null
            }
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth={'sm'}
                PaperProps={{ style: { borderRadius: 10 } }}
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        MASS DOCUMENT UPLOAD
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography variant="h6" style={{ color: 'black' }}>
                            Are you sure, you want to upload the files ?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="MU_ok_btn" variant="contained" size="small" className={classes.yesbutton} onClick={(_) => { HandleCheckValidUser() }} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button id="MU_Cancel_Btn" variant="contained" size="small" autoFocus className={classes.nobutton} onClick={handleClose} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default MassUpload
