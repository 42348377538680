import { Box, Typography } from '@material-ui/core';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import 'react-circular-progressbar/dist/styles.css';

interface RadialProps {
    value: number;
}

function RadialProgress(props: RadialProps & { value: number },) {
    return (
        <div style={{ width: '100px', height: '100px', position: 'relative' }}>
            <CircularProgressbar
                value={props.value}
                text={`${props.value}%`}
                styles={buildStyles({
                    textColor: "#fff",
                    pathColor: "rgb(62 152 199)",
                    trailColor: "rgb(214 214 214)"
                })}
            />
        </div>
    )
}

export default RadialProgress;


export function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
) {
    return (
        <Box style={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} value={props.value} />
            <Box
                style={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    color: 'white',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    style={{ color: 'white' }}
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}