import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import Skeleton from '@mui/material/Skeleton';
import { Card, CardHeader, Box } from '@material-ui/core';
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  card: {
    borderRadius: 25,
    height: '9cm',
    boxShadow: '0px 1px 5px 1px rgba(103, 128, 159, 1)',
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 70vh - 210px)',
      minHeight: 'calc( 70vh - 210px)',
    },
  },
});
class BarChart extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { classes } = this.props;
    let resize = this?.props?.resize;
    const Prec = this.props?.chartData.map((p) => { return (p.prec) });
    const Cont = this.props?.chartData.map((q) => { return (q.cont) });
    const firstParty = this.props?.chartData.map((q) => { return (q.firstParty) });
    const MonthAge = this.props?.chartData.map((m) => { return (moment(m.referralDate, 'YYYYMMDD').format('MMMYY')) });

    const CHART_DATA = [
      {
        name: '1ST Party',
        data: firstParty
      },
      {
        name: 'Flat Fee',
        data: Prec
      }, {
        name: 'Contingency',
        data: Cont
      },
    ];

    const chartOptions = {
      stroke: { width: [2, 2, 2] },
      colors: ['#2D006B', '#ff0066', 'rgb(0, 0, 250)'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '90%',
          endingShape: 'rounded',
          borderRadius: 5
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 'bold',
          colors: ['#ffffff', '#ffffff', '#ffffff']
        },
      },
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            precision: 0
          },
        },
      ],
      chart: {
        type: 'bar',
        height: 280,
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: 'pink',
              opacity: 0.4
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 0.5
            }
          }
        },
        toolbar: {
          show: true,
          offsetY: -30,
          offsetX: -2,
          export: {
            csv: {
              filename: "Placement-History.csv",
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            svg: {
              filename: 'Placement-History',
            },
            png: {
              filename: 'Placement-History',
            }
          },
          autoSelected: 'zoom'
        },
      },
      fill: {
        type: ['gradient' / 'solid' / 'pattern' / 'image', 'solid'],
        gradient: {
          shade: 'dark',
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5
          }
        }
      },
      labels: MonthAge,
      xaxis: {
        startOnTick: true,
        endOnTick: true,
        labels: {
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM \'yy',
          }
        }
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (y) => {
            if (typeof y !== 'undefined') {
              return `${y.toFixed(0)}`;
            }
            return y;
          }
        }
      }
    };
    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardHeader title={<span style={{ fontSize: 20 }}>6 Month Account Placement History</span>} style={{ height: '55px' }} />
          {this.props.loading ? (
            <Skeleton variant="rectangular" width="100%" animation="pulse" style={{ background: "#d5d5d7", display: 'block' }}>
              <div style={{ paddingTop: '40%' }} />
            </Skeleton>
          ) :
            <Box sx={{ p: 1, pb: 1 }} >
              <ReactApexChart type="bar" series={CHART_DATA} options={chartOptions} height={resize === 0 ? 240 : resize >= 960 ? 370 : resize < 715 ? 190 : 240} />
            </Box>
          }
        </Card>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(BarChart);