import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    btnManTemp: {
        borderRadius: '20px',
        float: 'right',
        fontSize: 12,
        marginTop: '10px'
    },
    btnEditTemp: {
        float: 'right',
        fontSize: 12,
        backgroundColor: 'green',
        '&:hover': {
            background: "green",
        }
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    headerName: {
        color: 'blue',
        // marginTop: '5px'
    },
    clientCodeError: {
        color: 'red',
        marginTop: '5px',
        fontSize: 12
    },
    keyTextFiled: {
        width: '100%',
        marginTop: '8px'
    },
    table: {
        minWidth: '800px',
    },
    rowhead: {
        background: 'lightgray',
        color: 'black',
        fontSize: '14px',
        padding: '2px 5px 2px 8px',
        width: '23%'
    },
    tablebodyActivity: {
        maxHeight: 500,
        minHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 192px)',
            minHeight: 'calc( 100vh - 192px)',
        },
        marginTop: '10px',
    },
    TableCellStyle: {
        background: "#007FFF",
        color: "white",
        fontSize: 13,
        padding: '2px 15px',
        TextAlign: 'left'
    },
    TableCellStyle1: {
        fontSize: 13,
        padding: '2px 15px',
    },
    UserListStyle: {
        textAlign: 'center',
        marginLeft: '5px',
        marginTop: '5px',
        '@media (min-width: 600px)': {
            textAlign: 'left',
        },
    },
    root: {
        flexGrow: 1
    },
    copyTextField: {
        marginTop: '5px',
        '& .MuiInputBase-input': {
            color: 'gray'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '4px'
        },
    },
    outlinedInput: {
        '&$focused $notchedOutline': {
            border: '1.5px solid lightgray'
        },
    },
    notchedOutline: {
        color: 'white',
        border: '1.5px solid lightgray'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    createGroup: {
        borderRadius: 20,
        color: 'white',
        fontSize: 12,
        marginBottom: '0px',
        marginTop: '3px',
        background: "green",
        padding: '3px 10px',
        '&:hover': {
            background: "green",
        }
    },
    EmailSent: {
        borderRadius: 20,
        color: 'white',
        fontSize: 12,
        marginBottom: '0px',
        marginTop: '3px',
        background: "green",
        padding: '3px 10px',
        '&:hover': {
            background: "green",
        }
    },
    cancelGroup: {
        borderRadius: 20,
        color: 'white',
        fontSize: 12,
        // marginRight: '15px',
        marginTop: '3px',
        background: "red",
        padding: '3px 10px',
        '&:hover': {
            background: "red",
        }
    },
    disableText: {
        fontSize: 13,
        color: 'lightgray'
    },
    titleConfirm: {
        color: "black",
        fontSize: 17
    },
    divRoot: {
        padding: 8,
        flexGrow: 1
    },
    tablebody1: {
        maxHeight: 500,
        minHeight: 500,
        marginTop: '5px',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 120px)',
            minHeight: 'calc( 100vh - 120px)',
        },
    },
    ErrorMessageStyle1: {
        color: "red",
        marginTop: '5px',
        fontSize: 16
    },
    menuButton1: {
        width: '120px',
        height: '40px'
    },
    toolbarStyle: {
        backgroundColor: 'white',
        minHeight: '40px',
    },
    appBar: {
        position: 'relative',
        overflowY: 'hidden',
        minHeight: '40px',
        borderBottom: '3px solid #0072E5'
    },
    dialog: {
        '& .MuiDialog-paper': {
            overflowY: 'hidden'
        }
    },
    menuButton: {
        marginTop: '5px',
        marginBottom: '5px',
        height: '40px',
        backgroundColor: 'white',
        cursor: 'default',
        '&:hover': {
            backgroundColor: 'white',
        }
    },
    title: {
        flex: 1,
        color: '#0072E5',
        textAlign: 'center',
        fontSize: 18,
        marginRight: '100px'
    },
    closeIconButton: {
        color: '#000',
        fontSize: 22
    },
    userboxviewStyle: {
        display: 'block',
        borderRadius: '10px',
        borderColor: 'black',
        padding: '8px',
        '&:hover': {
            color: 'green',
            backgroundColor: 'white',
            transform: 'scale(1)',
            boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.4)',
            borderRadius: '5px'
        },
    },
    LabelBoxStyle: {
        margin: '6px 2px',
    },
    formLabel: {
        fontWeight: 'bold',
        color: 'green',
        fontSize: 12,
        marginTop: '5px'
    },
    boxBorder: {
        border: '2px solid gray',
        padding: '2px 8px',
        borderRadius: '5px',
        width: '100%',
        marginTop: '12px',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
    },
    textBoxStyle2: {
        fontSize: 10,
        '& .MuiChip-labelSmall': {
            fontSize: 10
        }
    },
    valueContainer: {
        "& > :not(:last-child)": {
            marginRight: '5px'
        },
        "& > *": {
            marginBottom: '5px'
        }
    },
    clientCodeBox: {
        width: '100%',
        marginTop: '5px',
        borderRadius: '4px 4px 8px 8px',
        '& .MuiOutlinedInput-root': {
            borderBottom: '3px solid blue',
            borderRadius: '4px 4px 8px 8px'
        }
    },
    paper2: {
        border: '2px solid black',
        height: 'auto',
        margin: 0
    },
    GreenColor: {
        color: 'green'
    },
    IconSizeStyle: {
        height: '20px',
        width: '20px'
    },
    CheckBoxBorder: {
        borderTop: '1px solid gray'
    },
    checkBoxStyle: {
        marginLeft: '5px'
    },
    textBoxStyle1: {
        marginTop: '9px',
        fontSize: 10,
        '& .MuiChip-labelSmall': {
            paddingLeft: '5px',
            paddingLight: '5px',
            fontSize: '10px'
        }
    },
    labelStyle: {
        fontWeight: 'bold',
        color: 'green',
        fontSize: 14,
        marginTop: '15px'
    },
    scrollMargin: {
        '& div:first-child': {
            marginBottom: '5px',
        },
        '& div:nth-child(3) div': {
            backgroundColor: '#2377e4 !important'
        },
        '& div:nth-child(3)': {
            top: '10px !important',
            bottom: '5px !important'
        },
    },
    clientStyle: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px'
    },
    dialogboxOverride: {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        height: '100%',
        border: "1px solid #0D324D",
        borderRadius: 15
    },
    yesButton: {
        background: "red",
        color: 'white',
        fontSize: 13,
        marginRight: '30px',
        borderRadius: 20,
        '&:hover': {
            background: "red",
        },
    },
    dialogaction: {
        marginBottom: '10px'
    },
    tablebody: {
        maxHeight: 150,
        minHeight: 150,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 280px)',
            minHeight: 'calc( 100vh - 280px)',
        },
        marginTop: '10px',
    },
    noRecordStyle: {
        color: "red",
        marginTop: "10px",
        textAlign: 'center'
    },
    DisabledOption: {
        color: theme.palette.text.disabled,
    },
}));
export { useStyles }