import { Checkbox, FormControl, FormControlLabel, ListItemText, Typography } from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import { useStyles } from './AG_TemplateCss';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { ListItemButton, ListItemIcon } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';

interface Activity {
    ActivityMapId: number;
    ActivityName: string;
    ParentId: number;
    Activities?: Activity[];
    isEnable: boolean;
}

interface Props {
    data: Activity[];
    onSaveActivity: (event: any, checked: any, parentActivityId: number) => void;
}

const EditAgentTreeView: React.FC<Props> = (props) => {
    const classes = useStyles();
    const { data } = props;

    const handleChecked = (e, checked, parentActivityId) => {
        props?.onSaveActivity(e, checked, parentActivityId);
    };

    const renderTree = (activities: Activity[]) => {
        return activities?.map(activity => (
            <React.Fragment key={activity.ActivityMapId}>
                <ListItemButton sx={{ pt: 0, pb: 0 }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                        {activity.Activities ? (
                            activity?.ActivityMapId === activity.ActivityMapId ? (
                                <IndeterminateCheckBoxOutlinedIcon fontSize="small" />
                            ) : (
                                <AddBoxOutlinedIcon fontSize="small" />
                            )
                        ) : (
                            <label>
                                <FormControl component="fieldset" >
                                    <FormControlLabel
                                        labelPlacement="end"
                                        id="AG_emailreceived"
                                        checked={activity?.isEnable}
                                        value={activity?.ActivityMapId}
                                        label={`${activity?.ActivityName}`}
                                        control={<Checkbox size="small" name={`${activity?.ActivityName}`} />}
                                        onChange={(e, selected) => { handleChecked(e, selected, activity?.ParentId); }}
                                        className={`${activity?.isEnable ? classes.permittedChecked : classes.permittedFiled}`}
                                    />
                                </FormControl>
                            </label>
                        )}
                    </ListItemIcon>
                    <ListItemText>
                        {activity.Activities ? (
                            <Typography variant="body2" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                {activity?.ActivityName}
                            </Typography>
                        ) : null}
                    </ListItemText>
                </ListItemButton>
                {activity.Activities && <ul>{renderTree(activity.Activities)}</ul>}
            </React.Fragment>
        ));
    };

    return (
        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.tableBodyTrack}>
            {renderTree(data)}
        </Scrollbars>
    );
};

export default EditAgentTreeView;
