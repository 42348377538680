import { Backdrop, Theme, createStyles, makeStyles, styled } from "@material-ui/core";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
// import { BarChart } from "@mui/x-charts/BarChart";
// import { axisClasses } from "@mui/x-charts";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { usePost } from "../utils/apiHelper";
import { useGlobalState } from '../store/GlobalStore';
import { Link } from 'react-router-dom';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import { pink } from "@material-ui/core/colors";
import { PuffLoader } from "react-spinners";
import Footer from "../components/Footer/Footer";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rectangleButton: {
      background:
        "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",
      color: "white",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    rectangleButton1: {
      background:
        "linear-gradient(310deg, rgb(0, 121, 145), rgb(120, 255, 214))",
      color: "white",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    rectangleButton2: {
      background: "linear-gradient(310deg, rgb(14, 210, 247), rgb(178, 254, 250))",
      color: "white",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    rectangleButton3: {
      background: "linear-gradient(310deg, rgb(97, 144, 232), rgb(167, 191, 232))",
      color: "white",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    linkStyle: {
      textDecoration: 'none',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
);

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const AgentDashboard = () => {
  const classes = useStyles();
  const { state } = useGlobalState();
  const [paymentCount, setPaymentCount] = useState<number>(0);
  const [backupCount, setBackupCount] = useState<number>(0);
  const [disputeCount, setDisputeCount] = useState<number>(0);
  const [ProgressBar, setProgressBar] = useState(false);

  const dataset = [
    {
      london: 59,
      paris: 57,
      newYork: 86,
      seoul: 21,
      month: "Jan",
    },
    {
      london: 50,
      paris: 52,
      newYork: 78,
      seoul: 28,
      month: "Fev",
    },
    {
      london: 47,
      paris: 53,
      newYork: 106,
      seoul: 41,
      month: "Mar",
    },
    {
      london: 54,
      paris: 56,
      newYork: 92,
      seoul: 73,
      month: "Apr",
    },
    {
      london: 57,
      paris: 69,
      newYork: 92,
      seoul: 99,
      month: "May",
    },
    {
      london: 60,
      paris: 63,
      newYork: 103,
      seoul: 144,
      month: "June",
    },
    {
      london: 59,
      paris: 60,
      newYork: 105,
      seoul: 319,
      month: "July",
    },
    {
      london: 65,
      paris: 60,
      newYork: 106,
      seoul: 249,
      month: "Aug",
    },
    {
      london: 51,
      paris: 51,
      newYork: 95,
      seoul: 131,
      month: "Sept",
    },
    {
      london: 60,
      paris: 65,
      newYork: 97,
      seoul: 55,
      month: "Oct",
    },
    {
      london: 67,
      paris: 64,
      newYork: 76,
      seoul: 48,
      month: "Nov",
    },
    {
      london: 61,
      paris: 70,
      newYork: 103,
      seoul: 25,
      month: "Dec",
    },
  ];

  const valueFormatter = (value) => `${value}mm`;

  useEffect(() => {
    (async () => {
      setProgressBar(true);
      let request = {
        "agentUserId": state?.AgentUserAccessContext?.agentUserId,
        "clientDetails": state?.AgentUserNotificationClientCodeDetails
      };
      await usePost<any>("Account/GetAgentDashboardCount", request).then((r) => {
        setPaymentCount(r?.data?.paymentCount?.paymentVerification);
        setBackupCount(r?.data?.backupCount?.backupDocumentation);
        setDisputeCount(r?.data?.disputeCount?.disputeValidation);
      })
    })().finally(() => {
      setProgressBar(false);
    })
  }, [])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0} padding={1}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        {state?.AgentUserAccessContext?.paymentVerification ?
          <Grid item xs={12} md={4} padding={1}>
            <Link className={classes.linkStyle} to="/agentNotifications">
              <Box
                display={"flex"}
                borderRadius={5}
                p={3}
                mt={0}
                marginLeft={0}
                boxShadow={5}
              >
                <Box p={0} width={"80%"}>
                  <Typography gutterBottom variant="h6">
                    <b>
                      Payment Verification Count <br />
                    </b>
                  </Typography>
                  <Typography gutterBottom variant="h3">
                    {paymentCount}
                  </Typography>
                  {/* <div style={{backgroundColor:'pink', width:'50px', display:'flex'}}>
                    <StackedBarChartIcon style={{  display:'flex'}}/>
                  </div> */}
                </Box>
                <Box
                  p={0}
                  width={"20%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box p={1} className={classes.rectangleButton}>
                    <LocalAtmIcon sx={{ width: "4rem", height: "4rem" }} />
                  </Box>
                </Box>
              </Box>
            </Link>
          </Grid>
          : null}
        {state?.AgentUserAccessContext?.disputeValidation ?
          <Grid item xs={12} md={4} padding={1}>
            <Link className={classes.linkStyle} to="/agentNotifications">
              <Box
                display={"flex"}
                borderRadius={5}
                p={3}
                mt={0}
                marginLeft={0}
                boxShadow={5}
              >
                <Box p={0} width={"80%"}>
                  <Typography gutterBottom variant="h6">
                    <b>
                      Dispute Validation Count <br />
                    </b>
                  </Typography>
                  <Typography gutterBottom variant="h3">
                    {disputeCount}
                  </Typography>
                </Box>
                <Box
                  p={0}
                  width={"20%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box p={1} className={classes.rectangleButton1}>
                    <Diversity3Icon sx={{ width: "4rem", height: "4rem" }} />
                  </Box>
                </Box>
              </Box>
            </Link>

          </Grid>
          : null}
        {state?.AgentUserAccessContext?.backupDocument ?
          <Grid item xs={12} md={4} padding={1}>
            <Link className={classes.linkStyle} to="/agentNotifications">
              <Box
                display={"flex"}
                borderRadius={5}
                p={3}
                mt={0}
                marginLeft={0}
                boxShadow={5}
              >
                <Box p={0} width={"80%"}>
                  <Typography gutterBottom variant="h6">
                    <b>
                      Backup Notification Count <br />
                    </b>
                  </Typography>
                  <Typography gutterBottom variant="h3">
                    {backupCount}
                  </Typography>
                </Box>
                <Box
                  p={0}
                  width={"20%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box p={1} className={classes.rectangleButton2}>
                    <ReceiptLongIcon sx={{ width: "4rem", height: "4rem" }} />
                  </Box>
                </Box>
              </Box>
            </Link>

          </Grid>
          : null}
        {/* <Grid container spacing={2}>
          <Grid item xs={6} >
          <Box boxShadow={5} p={3} mt={1}  marginLeft={0}>
            <BarChart
              dataset={dataset}
              xAxis={[{ scaleType: "band", dataKey: "month" }]}
              series={[
                // { dataKey: "london", label: "Email", valueFormatter },
                { dataKey: "paris", label: "Admin", valueFormatter },
                {
                  dataKey: "newYork",
                  label: "Case Created/Escalation",
                  valueFormatter,
                },
                { dataKey: "seoul", label: "Email (Inprogress)", valueFormatter },
              ]}
              {...chartSetting}
            />
          </Box>
          </Grid>
          <Grid item xs={6} display={"flex"}>
            <Grid container spacing={2}>
              <Grid item xs={12} display={"flex"}>
                <Box p={3} mt={1} width={"25%"} boxShadow={5} marginLeft={2} height={"65%"}  borderRadius={2} sx={{padding: "15px"}}>
                  <Box p={3} mb={0} mt={-1} width={"25%"} boxShadow={5} marginLeft={6} height={"25%"} sx={{ color: "white", borderRadius: "8px",  display: "flex", alignItems: "center", justifyContent: "center", background: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",}}>
                    <ReceiptLongIcon sx={{ width: "2rem", height: "2rem"}} />
                  </Box>
                  <Typography fontSize={'14px'}>
                    Email Sent
                  </Typography>
                  <Typography variant="h6">
                    22
                  </Typography>
                </Box>
                <Box p={3} mt={1} width={"25%"} boxShadow={5} marginLeft={2} height={"65%"} borderRadius={2} sx={{padding: "15px"}}>
                  <Box p={3} mb={0} mt={-1} width={"25%"} boxShadow={5} marginLeft={6} height={"25%"} 
                    sx={{color: "white", borderRadius: "8px",  display: "flex",  alignItems: "center",  justifyContent: "center", background: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))"}}>
                    <ReceiptLongIcon sx={{ width: "2rem", height: "2rem"}} />
                  </Box>
                  <Typography fontSize={'14px'}>
                    Email Recived
                  </Typography>
                  <Typography variant="h6">
                    18
                  </Typography>
                </Box>
                <Box p={3} mt={1} width={"25%"} boxShadow={5} marginLeft={2} height={"65%"} borderRadius={2} sx={{padding: "15px"}}>
                  <Box p={3} mb={0} mt={-1} width={"25%"} boxShadow={5} marginLeft={6} height={"25%"} 
                    sx={{color: "white", borderRadius: "8px",  display: "flex",  alignItems: "center",  justifyContent: "center", background: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))"}}>
                    <ReceiptLongIcon sx={{ width: "2rem", height: "2rem"}} />
                  </Box>
                  <Typography fontSize={'14px'}>
                    Demographic Update 
                  </Typography>
                  <Typography variant="h6">
                    25
                  </Typography>
                </Box>
                <Box p={3} mt={1} width={"25%"} boxShadow={5} marginLeft={2} height={"65%"} borderRadius={2} sx={{padding: "15px"}}>
                  <Box p={3} mb={0} mt={-1} width={"25%"} boxShadow={5} marginLeft={6} height={"25%"} 
                    sx={{color: "white", borderRadius: "8px",  display: "flex",  alignItems: "center",  justifyContent: "center", background: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))"}}>
                    <ReceiptLongIcon sx={{ width: "2rem", height: "2rem"}} />
                  </Box>
                  <Typography fontSize={'14px'}>
                    Invoice Recived
                  </Typography>
                  <Typography variant="h6">
                    10
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} display={"flex"} >
                <Box p={3} mt={1} width={"25%"} boxShadow={5} marginLeft={2} height={"65%"}  borderRadius={2} sx={{padding: "15px"}}>
                  <Box p={3} mb={0} mt={-1} width={"25%"} boxShadow={5} marginLeft={6} height={"25%"} sx={{ color: "white", borderRadius: "8px",  display: "flex", alignItems: "center", justifyContent: "center", background: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",}}>
                    <ReceiptLongIcon sx={{ width: "2rem", height: "2rem"}} />
                  </Box>
                  <Typography fontSize={'14px'}>
                    Email Sent
                  </Typography>
                  <Typography variant="h6">
                    22
                  </Typography>
                </Box>
                <Box p={3} mt={1} width={"25%"} boxShadow={5} marginLeft={2} height={"65%"} borderRadius={2} sx={{padding: "15px"}}>
                  <Box p={3} mb={0} mt={-1} width={"25%"} boxShadow={5} marginLeft={6} height={"25%"} 
                    sx={{color: "white", borderRadius: "8px",  display: "flex",  alignItems: "center",  justifyContent: "center", background: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))"}}>
                    <ReceiptLongIcon sx={{ width: "2rem", height: "2rem"}} />
                  </Box>
                  <Typography fontSize={'14px'}>
                    Email Recived
                  </Typography>
                  <Typography variant="h6">
                    18
                  </Typography>
                </Box>
                <Box p={3} mt={1} width={"25%"} boxShadow={5} marginLeft={2} height={"65%"} borderRadius={2} sx={{padding: "15px"}}>
                  <Box p={3} mb={0} mt={-1} width={"25%"} boxShadow={5} marginLeft={6} height={"25%"} 
                    sx={{color: "white", borderRadius: "8px",  display: "flex",  alignItems: "center",  justifyContent: "center", background: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))"}}>
                    <ReceiptLongIcon sx={{ width: "2rem", height: "2rem"}} />
                  </Box>
                  <Typography fontSize={'14px'}>
                    Demographic Update 
                  </Typography>
                  <Typography variant="h6">
                    25
                  </Typography>
                </Box>
                <Box p={3} mt={1} width={"25%"} boxShadow={5} marginLeft={2} height={"65%"} borderRadius={2} sx={{padding: "15px"}}>
                  <Box p={3} mb={0} mt={-1} width={"25%"} boxShadow={5} marginLeft={6} height={"25%"} 
                    sx={{color: "white", borderRadius: "8px",  display: "flex",  alignItems: "center",  justifyContent: "center", background: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))"}}>
                    <ReceiptLongIcon sx={{ width: "2rem", height: "2rem"}} />
                  </Box>
                  <Typography fontSize={'14px'}>
                    Invoice Recived
                  </Typography>
                  <Typography variant="h6">
                    10
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} display={"flex"}>
              <Box p={3} mt={1} width={"25%"} boxShadow={5} marginLeft={2} height={"65%"}  borderRadius={2} sx={{padding: "15px"}}>
                  <Box p={3} mb={0} mt={-1} width={"25%"} boxShadow={5} marginLeft={6} height={"25%"} sx={{ color: "white", borderRadius: "8px",  display: "flex", alignItems: "center", justifyContent: "center", background: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",}}>
                    <ReceiptLongIcon sx={{ width: "2rem", height: "2rem"}} />
                  </Box>
                  <Typography fontSize={'14px'}>
                    Email Sent
                  </Typography>
                  <Typography variant="h6">
                    22
                  </Typography>
                </Box>
                <Box p={3} mt={1} width={"25%"} boxShadow={5} marginLeft={2} height={"65%"} borderRadius={2} sx={{padding: "15px"}}>
                  <Box p={3} mb={0} mt={-1} width={"25%"} boxShadow={5} marginLeft={6} height={"25%"} 
                    sx={{color: "white", borderRadius: "8px",  display: "flex",  alignItems: "center",  justifyContent: "center", background: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))"}}>
                    <ReceiptLongIcon sx={{ width: "2rem", height: "2rem"}} />
                  </Box>
                  <Typography fontSize={'14px'}>
                    Email Recived
                  </Typography>
                  <Typography variant="h6">
                    18
                  </Typography>
                </Box>
                <Box p={3} mt={1} width={"25%"} boxShadow={5} marginLeft={2} height={"65%"} borderRadius={2} sx={{padding: "15px"}}>
                  <Box p={3} mb={0} mt={-1} width={"25%"} boxShadow={5} marginLeft={6} height={"25%"} 
                    sx={{color: "white", borderRadius: "8px",  display: "flex",  alignItems: "center",  justifyContent: "center", background: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))"}}>
                    <ReceiptLongIcon sx={{ width: "2rem", height: "2rem"}} />
                  </Box>
                  <Typography fontSize={'14px'}>
                    Demographic Update 
                  </Typography>
                  <Typography variant="h6">
                    25
                  </Typography>
                </Box>
                <Box p={3} mt={1} width={"25%"} boxShadow={5} marginLeft={2} height={"65%"} borderRadius={2} sx={{padding: "15px"}}>
                  <Box p={3} mb={0} mt={-1} width={"25%"} boxShadow={5} marginLeft={6} height={"25%"} 
                    sx={{color: "white", borderRadius: "8px",  display: "flex",  alignItems: "center",  justifyContent: "center", background: "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))"}}>
                    <ReceiptLongIcon sx={{ width: "2rem", height: "2rem"}} />
                  </Box>
                  <Typography fontSize={'14px'}>
                    Invoice Recived
                  </Typography>
                  <Typography variant="h6">
                    10
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
      <Box mt={50} width="70%">
        <div className="footer">
          <Footer />
        </div>
      </Box>
    </Box>
  );
};

export default AgentDashboard;
