import {
  Avatar, Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, makeStyles, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  TableSortLabel, TextField, Toolbar, Typography
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import InputAdornment from "@material-ui/core/InputAdornment";
import { createStyles, Theme } from '@material-ui/core/styles';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EmailIcon from '@material-ui/icons/Email';
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from '@material-ui/icons/Send';
import { Autocomplete } from "@material-ui/lab";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CloseIcon from '@mui/icons-material/Close';
import clsx from "clsx";
import _ from 'lodash';
import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';
import CloneUser from "../../components/GlobalAdmin/Users/CloneUser";
import GA_CreateUser from '../../components/GlobalAdmin/Users/GA_CreateUser';
import GA_EditUser from '../../components/GlobalAdmin/Users/GA_EditUser';
import ImpersonateUser from "../../components/GlobalAdmin/Users/ImpersonateUser";
import InviteUser from '../../components/GlobalAdmin/Users/InviteUser';
import AvatarTooltip from '../../components/GlobalStyles/AvatarTooltip';
import { DialogTitleHeader } from '../../components/GlobalStyles/DialogStyle';
import LightTooltip from "../../components/GlobalStyles/LightTooltip";
import DeleteUser from "../../components/Settings/DeleteUser";
import GlobalAdmin from '../../images/users/ClientAdmin.svg';
import FileAdmin from '../../images/users/FileAdmin.svg';
import ClientAdmin from '../../images/users/GlobalAdmin.svg';
import RegularUser from '../../images/users/RegularUser.svg';
import SuperAdmin from '../../images/users/SuperAdmin.svg';
import { IGetAllDomains } from "../../models/accesscontrol/IGetAllDomains";
import { IInviteCount } from "../../models/accesscontrol/IInviteCount";
import { IUserInfo } from '../../models/accesscontrol/IUserInfo';
import { IGetBlobFolders } from '../../models/Files/IGetBlobFolders';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
        marginBottom: "0%",
        margin: theme.spacing(2),
        width: '25ch',
      },
    },
    clientAdmin: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    globalAdmin: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    superAdminColor: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    regularUser: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    fileAdmin: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid gray',
    },
    table: {
      minWidth: 700,
    },
    grid: {
      padding: '2px 8px'
    },
    div: {
      flexGrow: 1,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    progressBar: {
      height: '6px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    domainStyle: {
      width: '100%',
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
      borderRadius: 10,
      border: '2px solid #215176',
      backgroundColor: 'white',
      fontSize: 13,
      textIndent: '10px',
      marginBottom: '5px',
    },
    searchBar: {
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
      borderRadius: 10,
      border: '2px solid #215176',
      width: '100%',
      fontSize: 14,
      textIndent: '10px',
      marginBottom: '5px',
    },
    boxStyle: {
      marginTop: '5px',
      marginLeft: '15px',
    },
    TextHighlight: {
      color: '#000000',
      fontWeight: 'bold',
      backgroundColor: 'yellow',
      fontSize: 13,
    },
    TextNormal: {
      color: 'black',
      fontSize: 13
    },
    tablebody: {
      maxHeight: 500,
      minHeight: 500,
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 245px)',
        minHeight: 'calc( 100vh - 245px)',
      },
    },
    invitationsend: {
      fontSize: 14,
      '@media (min-width: 600px) and (max-width: 660px)': {
        fontSize: 12,
      }
    },
    invitationbutton: {
      borderRadius: 20,
      height: '30px',
      background: "#215176",
      color: "white",
      '&:hover': {
        background: "#215176",
      }
    },
    paper1: {
      border: "2px solid black",
    },
    outlinedInput: {
      fontSize: 14,
      color: "black",
      paddingLeft: '5px',
      '&$focused $notchedOutline': {
        color: 'white',
        border: '0px'
      },
    },
    notchedOutline1: {
      color: 'white',
      border: '0px'
    },
    IconSizeStyle: {
      height: '22px',
      width: '22px'
    },
    buttonStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      '& .MuiBox-root': {
        padding: '5px',
      },
      '@media (min-width: 600px)': {
        justifyContent: "flex-end",
      }
    },
    UserListStyle: {
      textAlign: 'center',
      marginLeft: '5px',
      '@media (min-width: 600px)': {
        textAlign: 'left',
      },
    },
    TableCellStyle: {
      background: "#007FFF",
      color: "white",
      fontSize: 13,
      padding: '3px 15px'
    },
    outlinedInput1: {
      fontSize: 14,
      color: "black",
      paddingLeft: '5px',
      '&$focused $notchedOutline': {
        color: 'white',
        border: 'none',
      },
    },
    ErrorMessageStyle: {
      color: "red",
      marginTop: "10px"
    },
    footer: {
      marginTop: '15px',
      float: 'right',
    },
    searchTextStyle: {
      padding: '10px !important',
    },
    flexGrid: {
      display: 'flex',
      alignItems: 'center',
      '& .MuiBox-root': {
        padding: '5px',
      },
    },
    searchStyle: {
      padding: '0px 5px',
    },
    paginationCenter: {
      alignItems: 'center',
    },
    domainBorder: {
      borderRadius: '50px',
    },
    checkboxColor: {
      color: 'white !important',
    },
    contentEnd: {
      justifyContent: 'end',
    },
    usertablecellStyle: {
      padding: '3px 15px'
    },
    autoCompleteFont: {
      fontSize: 14,
    },
    usertablecellStyle1: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      maxWidth: '320px',
    },
  })
);

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    onSelectAllClick,
    numSelected, rowCount
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: "", disablePadding: false, label: "", sortable: false, },
    { id: "firstName", disablePadding: false, label: "FIRST NAME", sortable: true },
    { id: "lastName", disablePadding: false, label: "LAST NAME", sortable: true },
    { id: "userName", disablePadding: false, label: "EMAIL", sortable: true },
    { id: "upnEmailAddress", disablePadding: false, label: "UPN Email", sortable: true },
    { id: "", disablePadding: false, label: "", sortable: false, },
    { id: "", disablePadding: false, label: "", sortable: false, },
    { id: "Actions", disablePadding: false, label: "ACTIONS" },
    { id: "", disablePadding: false, label: "", sortable: false, order: false },
    { id: "Delete", disablePadding: false, label: "DELETE", sortable: false, order: false }
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.TableCellStyle} padding="checkbox">
          <Checkbox className={classes.checkboxColor} size="small"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all Users' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.TableCellStyle}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label || headCell.sortable}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      minHeight: '35px',
    },
    highlight:
      theme.palette.type === 'light'
        ? {
          borderRadius: 20,
          color: "white",
          backgroundColor: "#215176",
          minHeight: '30px',
          maxHeight: '30px',
          width: '150px'
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    title: {
      fontSize: 11,
      textAlign: 'center'
    },
    yesButton: {
      background: "green",
      color: 'white',
      borderRadius: 20,
      right: '15px',
      '&:hover': {
        background: "green",
      }
    },
    noButton: {
      background: "red",
      borderRadius: 20,
      display: 'flex',
      right: '10px',
      '&:hover': {
        background: "red",
      }
    },
    titleHeader: {
      fontWeight: 'bold',
      color: 'white'
    },
    MessageStyle: {
      marginTop: "15px",
      color: "black"
    },
    sendIconStyle: {
      color: 'white',
      fontSize: 18
    },
  }),
);

export default function User() {
  const classes = useRowStyles();
  const { dispatch } = useGlobalState();

  const [recordsPerPage, setRecordsPerPage] = useState<number>(25);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const [InviteCount, setInviteCount] = useState<IInviteCount>({});
  const [orderBy, setOrderBy] = React.useState("userName");
  const [order, setOrder] = React.useState("desc");
  const [searchText, setSearchText] = useState("");

  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [TextSearch, setTextSearch] = useState(false);

  const [ListPaymentContainers, setPaymentListContainers] = useState<IGetBlobFolders[]>([]);
  const [ListACKContainers, setACKListContainers] = useState<IGetBlobFolders[]>([]);
  const [ListContainers, setListContainers] = useState<IGetBlobFolders[]>([]);
  const [usersListResult, setUsersListResult] = useState<IUserInfo[]>([]);
  const [activeUsers, setactiveUsers] = useState<IGetAllDomains[]>([]);
  const [selected, setSelected] = React.useState<number[]>([]);
  const [sendInvite, setInvite] = useState(false);

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setInvite(false);
  };

  useEffect(() => {
    getUsersAsync();
  }, [activeUsers, pageNumber, order, orderBy, recordsPerPage])
  interface EnhancedTableToolbarProps {
    numSelected: number;
  }

  const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <Toolbar className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : null}
        {numSelected > 0 ? (
          <React.Fragment>
            <LightTooltip title="Bulk Invite">
              <IconButton aria-label="Bulk Invite" onClick={(_) => { handleClickOpen(); }}>
                <SendIcon className={classes.sendIconStyle} />
              </IconButton>
            </LightTooltip>
            <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
              <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                <Typography variant="h6" gutterBottom className={classes.titleHeader}>
                  INVITE USERS
                </Typography>
              </DialogTitleHeader>
              <DialogContent>
                <DialogContentText>
                  <Typography variant="h6" className={classes.MessageStyle}>
                    Are you sure, you want to Sent Invite to {numSelected} user ?
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions >
                <Button id="DU_ok_btn" variant="contained" onClick={BulkInviteAsync} className={classes.yesButton} >
                  Yes
                </Button>
                <Button id="DU_Cancel_btn" variant="contained" autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        ) : null}
      </Toolbar>
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setPageNumber(0);
    setshowProgressBar(true);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = usersListResult.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  function BulkInviteAsync() {
    (async () => {
      setshowProgressBar(true);
      dispatch({ type: GlobalStateAction.Busy });
      let request = {
        "userIds": selected
      };
      await usePost<any>("User/BulkInvite", request).finally(() => {
        setshowProgressBar(false);
        setSelected([]);
        setInvite(true);
      });
    })();
  }

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleSaveWithUserInfo = (userInfo: IUserInfo) => {
    getUsersAsync();
    GetInvitationCount();
  };

  const handleSave = () => {
    getUsersAsync();
    GetInvitationCount();
  };

  const handleDeleteSuccess = () => {
    getUsersAsync();
  };

  const handlePageChange = (event, page) => {
    setshowProgressBar(true);
    setPageNumber(page);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecordsPerPage(+event.target.value);
    setPageNumber(0);
  };

  useEffect(() => {
    setshowProgressBar(true);
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      await useFetch<IGetAllDomains[]>("User/GetAllDomains").then((ActiveUsersList) => {
        setactiveUsers(ActiveUsersList.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }, [])

  function onDomainSelect(event, value) {
    if (value != null) {
      setSearchText(value["domains"]);
    }
    else {
      setSearchText("");
    }
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        await useFetch<IGetAllDomains[]>("User/GetAllDomains").then((GetAllDomains) => {
          setactiveUsers(GetAllDomains.data);
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  function getUserInitials(name: string): string {
    const bySpace = name?.split(' ')
    if (bySpace.length > 1) {
      return bySpace[0][0] + bySpace[1][0]
    } else return name.slice(0, 2).toUpperCase()
  }

  useEffect(() => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      try {
        await useFetch<IInviteCount>("User/GetInvitationFirstLoggOnCount").then((UserInviteCount) => {
          setInviteCount(UserInviteCount.data);
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])

  const GetInvitationCount = () => {
    (async () => {
      await useFetch<IInviteCount>("User/GetInvitationFirstLoggOnCount").then((UserInviteCount) => {
        setInviteCount(UserInviteCount.data);
      });
    })()
  }

  function ClearUsersAsync() {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setshowProgressBar(true);
      let request = {
        "rowsPerPage": recordsPerPage,
        "searchParameters": "",
        "startingRecordNumber": (searchText !== "" ? (pageNumber * 0) + 1 : (pageNumber * recordsPerPage) + 1),
        "orderBy": orderBy,
        "orderDirection": order === "desc" ? 1 : 0
      }
      await usePost<{ usersList: IUserInfo[], noOfUsers: number }>("User/GetAllUsers", request).then((GetAllUsers) => {
        setUsersListResult(GetAllUsers.data["usersList"]);
        setTotalRecords(GetAllUsers.data.noOfUsers);
        setTextSearch(true);
        setSearchText("");
      }).finally(() => {
        setshowProgressBar(false);
      });
    })();
  }

  const getDefaultContainer = () => {
    let defaultContainer: IGetBlobFolders = {
      folder_Id: -1,
      folder_Name: 'None',
      fileContains: null
    };
    return defaultContainer;
  }

  useEffect(() => {
    (async () => {
      await useFetch<IGetBlobFolders[]>("File/GetBlobFolders").then((c) => {
        c.data.unshift(getDefaultContainer());
        let response = _.cloneDeep(c.data);
        setListContainers(response.filter(r => r.folder_Id || r.folder_Id === -1));
        setPaymentListContainers(response.filter(r => r.folder_Id === -1));
        setACKListContainers(response.filter(r => r.folder_Id === -1));
      });
    })()
  }, [])

  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <div className={classes.div}>
        <Grid className={classes.grid} >
          <Grid container spacing={0} xs={12}>
            <Grid item xs={2} sm={3} lg={3} >
              <Typography variant="h6" className={`${classes.UserListStyle} ${"headertitle"}`}>
                <b> USERS LIST </b>
              </Typography>
            </Grid>
            <Grid item xs={10} sm={5} lg={6} >
              <Grid container spacing={0}>
                <Grid item xs={6} className={`${classes.flexGrid} ${classes.contentEnd}`}>
                  <Box p={1}>
                    <Typography variant="h6" className={classes.invitationsend}>
                      Invitation Sent
                    </Typography>
                  </Box>
                  <Box p={1}>
                    <Button size="small" className={classes.invitationbutton}
                      variant="contained" startIcon={<EmailIcon />} >
                      {InviteCount.invitationSentDate}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6} className={classes.flexGrid}>
                  <Box p={1}>
                    <Button size="small" className={classes.invitationbutton} startIcon={<DoneAllIcon />}>
                      {InviteCount.firstLoggedOn}
                    </Button>
                  </Box>
                  <Box p={1}>
                    <Typography variant="h6" className={classes.invitationsend}>
                      Invitation Accepted
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} lg={3} direction="column" >
              <Box className={classes.buttonStyle} >
                <Box display="flex" p={1}>
                  <EnhancedTableToolbar numSelected={selected.length} />
                </Box>
                <Box display="flex" p={1}>
                  <GA_CreateUser onSave={handleSave} CloudContainer={ListContainers} PaymentContainer={ListPaymentContainers} AckContainer={ListACKContainers} />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item className={classes.searchStyle} xs={12} sm={6} md={6} lg={6}>
              <TextField value={searchText} variant="outlined"
                type='text' placeholder="Search Users (First Name, Last Name or Email)"
                className={classes.searchBar}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyPress={event => {
                  if (event.key === 'Enter' && searchText != "") {
                    getUsersAsync(); setShowSearchIcon(false);
                  } else if (event.key === 'Enter' && searchText === "") {
                    ClearUsersAsync(); setShowSearchIcon(true);
                  }
                }}
                inputProps={{ maxlength: 255 }}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.notchedOutline1,
                    input: classes.searchTextStyle,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {showSearchIcon === true ?
                        <IconButton onClick={(_) => { getUsersAsync(); setShowSearchIcon(false); }}>
                          <SearchIcon className={classes.IconSizeStyle} />
                        </IconButton>
                        :
                        <IconButton onClick={(_) => { ClearUsersAsync(); setShowSearchIcon(true); }}>
                          <CloseIcon className={classes.IconSizeStyle} />
                        </IconButton>
                      }
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item className={classes.searchStyle} xs={12} sm={6} md={6} lg={6}>
              <Autocomplete className={classes.domainBorder}
                id="free-solo-demo"
                autoComplete autoHighlight
                classes={{
                  paper: classes.paper1,
                  option: classes.autoCompleteFont
                }}
                options={activeUsers} onChange={onDomainSelect}
                getOptionLabel={(option) => option.domains}
                renderInput={(params) => (
                  <TextField placeholder="Select Domain"
                    {...params}
                    className={classes.domainStyle}
                    InputProps={{
                      ...params.InputProps, disableUnderline: true,
                      classes: {
                        root: classes.outlinedInput1,
                        input: classes.searchTextStyle,
                      }
                    }} />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid component={Paper} item xs={12}>
              <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table className="main-table" aria-label="customized table" size="small" stickyHeader>
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={usersListResult.length}
                  />
                  <TableBody>
                    {usersListResult.map(
                      (row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow key={index} hover selected={isItemSelected}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}>
                            <TableCell padding="checkbox" className={classes.usertablecellStyle}>
                              <Checkbox
                                size="small"
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                                onClick={(event) => handleClick(event, row.id)}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.usertablecellStyle}>
                              {row.global_admin === 3 ?
                                <AvatarTooltip title="Super Admin">
                                  <Avatar className={classes.superAdminColor} src={SuperAdmin}></Avatar>
                                </AvatarTooltip>
                                : row.global_admin === 2 ?
                                  <AvatarTooltip title="Global Admin">
                                    <Avatar className={classes.globalAdmin} src={GlobalAdmin}></Avatar>
                                  </AvatarTooltip>
                                  : row.global_admin === 1 ?
                                    <AvatarTooltip title="Client Admin">
                                      <Avatar className={classes.clientAdmin} src={ClientAdmin}></Avatar>
                                    </AvatarTooltip>
                                    : row.global_admin === 0 ?
                                      <AvatarTooltip title="Regular User">
                                        <Avatar className={classes.regularUser} src={RegularUser}>{getUserInitials(row.userName)}</Avatar>
                                      </AvatarTooltip>
                                      : <AvatarTooltip title="File Admin">
                                        <Avatar className={classes.fileAdmin} src={FileAdmin}>{getUserInitials(row.userName)}</Avatar>
                                      </AvatarTooltip>
                              }
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.usertablecellStyle}>
                              <span className={`${searchText.toLocaleLowerCase() === "" ? classes.TextNormal : row.firstName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                                {row.firstName}
                              </span>
                            </TableCell>
                            <TableCell className={classes.usertablecellStyle}>
                              <span className={`${searchText.toLocaleLowerCase() === "" ? classes.TextNormal : row.lastName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                                {row.lastName}
                              </span>
                            </TableCell>
                            <TableCell align="left" className={classes.usertablecellStyle}>
                              <span className={`${searchText.toLocaleLowerCase() === "" ? classes.TextNormal : row.userName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                                {row.userName}
                              </span>
                            </TableCell>
                            <TableCell align="left" className={classes.usertablecellStyle}>
                              <AvatarTooltip title={row?.upnEmailAddress}>
                                <span className={`${searchText.toLocaleLowerCase() === "" ? classes.TextNormal : row.upnEmailAddress.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ? classes.TextHighlight : classes.TextNormal}`}
                                  style={{ padding: '2px 10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block', maxWidth: '320px' }}>
                                  {row?.upnEmailAddress}
                                </span>
                              </AvatarTooltip>
                            </TableCell>
                            <TableCell align="center" className={classes.usertablecellStyle}>
                              <InviteUser userRow={row} onInvite={handleSave} checkSuperAdmin={row.global_admin === 3 || row.global_admin === 4} />
                            </TableCell>
                            <TableCell align="center" className={classes.usertablecellStyle}>
                              <ImpersonateUser userRow={row} checkSuperAdmin={row.global_admin === 3 || row.global_admin === 4} />
                            </TableCell>
                            <TableCell align="center" className={classes.usertablecellStyle}>
                              <CloneUser userRow={row} onSave={handleSave} checkSuperAdmin={row.global_admin === 3 || row.global_admin === 4} />
                            </TableCell>
                            <TableCell align="center" className={classes.usertablecellStyle}>
                              <GA_EditUser userRow={row} onSave={handleSaveWithUserInfo} onRemove={handleSave} checkSuperAdmin={row.global_admin === 3 || row.global_admin === 4} CloudContainer={ListContainers} PaymentContainer={ListPaymentContainers} AckContainer={ListACKContainers} />
                            </TableCell>
                            <TableCell className={classes.usertablecellStyle}>
                              <DeleteUser userRow={row} onDelete={handleDeleteSuccess} checkSuperAdmin={row.global_admin === 3 || row.global_admin === 4} />
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
                {TextSearch && !usersListResult.length ?
                  <Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
                    No records to display..
                  </Typography> : null}
              </TableContainer>

              <Box m={0} display="flex" style={{ flexDirection: 'row' }}>
                <Box display="flex" alignContent="flex-end" justifyContent="flex-end" width="70%" >
                  <div className={classes.footer}>
                    <Footer />
                  </div>
                </Box>
                <Box display="flex" alignContent="flex-center" justifyContent="flex-center" width="30%">
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={recordsPerPage}
                    page={pageNumber}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar open={sendInvite} className="snackBarStyle" anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
            Invitation Sent Successfully!
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment >
  )

  function getUsersAsync() {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setshowProgressBar(true);
      try {
        let request = {
          "rowsPerPage": recordsPerPage,
          "searchParameters": searchText?.trimLeft().trimRight(),
          "startingRecordNumber": ((pageNumber * recordsPerPage) + 1),
          "orderBy": orderBy,
          "orderDirection": order === "desc" ? 1 : 0
        }
        await usePost<{ usersList: IUserInfo[], noOfUsers: number }>("User/GetAllUsers", request).then((GetAllUsers) => {
          setUsersListResult(GetAllUsers.data["usersList"]);
          setTotalRecords(GetAllUsers.data.noOfUsers);
        });
        setTextSearch(true);
        setshowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })();
  }
}