import { Backdrop, DialogContentText, Grid, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Snackbar from '@material-ui/core/Snackbar';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CancelIcon from '@material-ui/icons/Cancel';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { emailRegex, firstNameregex, lastNameregex } from '../../../constants/Constant';
import { AccessContext } from "../../../models/accesscontrol/AccessContext";
import { IUserInfo } from '../../../models/accesscontrol/IUserInfo';
import { IWidgetInfo } from "../../../models/accesscontrol/IWidgetInfo";
import { WidgetMap } from "../../../models/accesscontrol/WidgetMap";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { DialogTitleDelete, DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './UsersCss';
import { regexValidEmailForARM } from '../../../constants/Constant';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';

const CloneUser: React.FC<{ userRow: IUserInfo, onSave: () => void, checkSuperAdmin: boolean }> = (props) => {
  const { userRow } = props;
  const { state, dispatch } = useGlobalState()
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstnameerror, setFirstNameError] = useState("");
  const [Lastnameerror, setLastNameError] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [Emailerror, setEmailError] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [cloneUser, setCloneUser] = useState(false);
  const [NameVal, setNameVal] = useState(false);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState<string>('');
  var newUser: AccessContext = new AccessContext();
  const [localState, setLocalState] = useState(newUser);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userDetails, SetUserDetails] = useState<any>();

  const handleAlertClose = () => {
    setAlertOpen(false);
    setOpen(true);
  };

  const handleClickOpen = () => {
    (async () => {
      setOpen(true);
      try {
        await useFetch<AccessContext>("User/GetUser?userId=" + userRow.id).then(async (UserDetails) => {
          await useFetch<IWidgetInfo[]>("User/GetWidgetsForClient?userId=" + userRow.id).then((ClientWidgets) => {
            for (let index = 1; index <= WidgetMap.size; index++) {
              const value = WidgetMap.get(index);
              if (value && ClientWidgets.data.filter(w => w.widgetId === index).length === 1) {
                UserDetails.data[value] = true;
              }
            };
            UserDetails.data.phone = "";
            setLocalState(UserDetails.data);
          });
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  };

  const handleClose = () => {
    setOpen(false);
    setFirstName("");
    setLastName("");
    setEmailVal("");
    setEmailError(null);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  }

  const handleCreateUserOpen = () => {
    setOpen(false);
    setDialogOpen(true);
  }

  const handleInviteExistingUsers = (userId: any) => {
    (async () => {
      await useFetch<IUserInfo>("User/InviteADExsitingUser?userId=" + userId);
    })()
  }

  const handleValidateUserExisitInAD = () => {
    try {
      (async () => {
        try {
          const response = await fetch("https://webviewapi.armsolutions.com/api/UserSignup/CheckEmailExist?userEmail=" + localState?.userName);
          const data = await response.json();
          if (data && data.length > 0) {
            SetUserDetails(data);
            handleCreateUserOpen();
          } else {
            setEmailError("This user does not exist in your organization, hence they can not be invited to WebView.");
          }
        } catch (e) {
          setEmailError("This user does not exist in your organization, hence they can not be invited to WebView.");
        }
      })().finally(() => {
      })
    } catch (error) {
      handleCreate();
    }
  }

  const handleCreate = async () => {
    localState.id = 0;
    let selectedWidgets: number[] = [];
    for (let index = 1; index <= WidgetMap.size; index++) {
      var value = WidgetMap.get(index);
      if (value && localState[value]) {
        selectedWidgets.push(index);
      }
    }
    setShowProgressBar(true);
    await usePost<{ userInfo: AccessContext, totalRecordCount: number }>("User/SaveUser", localState).then(async (SaveUserDetails) => {
      if (SaveUserDetails.status == 400) {
        dispatch({ type: GlobalStateAction.Error, error: SaveUserDetails.statusText })
      }
      else {
        const data = {
          'userId': SaveUserDetails?.data,
          'widgets': selectedWidgets?.join(',')
        };
        await usePost("User/AddWidgetsForClient", data).then((ClientsWidget) => {
          if (ClientsWidget.status == 400) {
            dispatch({ type: GlobalStateAction.Error, error: ClientsWidget.statusText })
          }
          if (ClientsWidget.status == 200) {
            props.onSave();
            setCloneUser(true);
            setOpen(false);
          }
        });
      }
      userDetails ? handleInviteExistingUsers(SaveUserDetails?.data) : null
    }).catch((err) => {
      if (err.response.status == 400) {
        setMessage(err.response.data);
        setAlertOpen(true);
        setOpen(false);
        setShowProgressBar(false);
        dispatch({ type: GlobalStateAction.Error, error: err.statusText })
      }
    }).finally(() => {
      setShowProgressBar(false);
      resetValues();
      setDialogOpen(false);
    });
  }

  const handleChange = (event) => {
    const name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.value
    });
  };

  const HandleFirstNameVal = (e) => {
    const FirstNameVal = e.target.value;
    if (FirstNameVal === "") {
      setFirstNameError("First Name is Required");
      setIsDisable(true);
    }
    else if (!FirstNameVal.match(firstNameregex)) {
      setFirstNameError("");
      setIsDisable(false);
      setFirstName(FirstNameVal); // only set when successful
    } else {
      setFirstNameError("Please enter characters only");
      setIsDisable(true);
    }
  };

  const HandleLastNameVal = (e) => {
    const LastNameVal = e.target.value;
    if (LastNameVal === "") {
      setLastNameError("Last Name is Required");
      setIsDisable(true);
    }
    else if (!LastNameVal.match(lastNameregex)) {
      setLastNameError("");
      setIsDisable(false);
      setLastName(LastNameVal); // only set when successful
    } else {
      setLastNameError("Please enter characters only");
      setIsDisable(true);
    }
  };

  const validateEmail = (e) => {
    const email = e.target.value;
    if (regexValidEmailForARM.test(email)) {
      setEmailError("");
      setIsDisable(false);
      setEmailVal(email);
    } else {
      setEmailError("please enter 'armsolutions.com' domain only");
      setIsDisable(true);
    }
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setCloneUser(false);
  };

  useEffect(() => {
    if (firstName === "" || lastName === "" || Lastnameerror.length > 0 || firstnameerror.length > 0) {
      setNameVal(true);
    } else {
      setNameVal(false);
    }
  }, [firstName, lastName, isDisable])

  function resetValues() {
    setFirstName("");
    setLastName("");
    setEmailVal("");
  }

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen} open={alertOpen} onClose={handleAlertClose} maxWidth={'sm'}
        aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
        <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
          <Typography variant="h6" className={classes.titleheader}>
            CLONE USER
          </Typography>
        </DialogTitleDelete>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h5" className={classes.MessageStyle} gutterBottom>
              {message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button id="DU_ok_btn" variant="contained" onClick={(e) => { setAlertOpen(false); setOpen(true); }} className={classes.okButton}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        <Button size="small" id="U_Clone_btn" className={classes.clonebutton} startIcon={<PeopleAltIcon />} variant="contained" color="primary" onClick={handleClickOpen} disabled={props.checkSuperAdmin && state.userAccessContext?.role === 2}>
          Clone
        </Button>
      </div>
      <div>
        <Dialog
          PaperProps={{ style: { borderRadius: 10 } }}
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth={'sm'}
          style={{ zIndex: 1000 }}
        >
          <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h6" className={classes.titleheader}>
              CLONE USER ( {props.userRow?.firstName + " " + props.userRow?.lastName} )
            </Typography>
          </DialogTitleHeader>
          <Backdrop className={classes.backdrop} open={ProgressBar}>
            <PuffLoader size={80} color={"white"} speedMultiplier={1} />
          </Backdrop>
          <DialogContent >
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} >
                <form noValidate autoComplete="off" >
                  <Grid container spacing={0}>
                    <Grid className={classes.colPaddingClone} item xs={12} sm={6} >
                      <TextField id="U_FirstName"
                        size="small" label="First Name"
                        variant="outlined"
                        onChange={(e) => { handleChange(e); HandleFirstNameVal(e); }}
                        helperText={firstnameerror} error={!!firstnameerror}
                        name="firstName"
                        inputProps={{ maxlength: 100 }}
                        className={classes.userNameEmail}
                        InputProps={{
                          classes: {
                            root: classes.blueBorderBottom,
                          },
                        }} />
                    </Grid>
                    <Grid className={classes.colPaddingClone} item xs={12} sm={6} >
                      <TextField id="U_LastName"
                        size="small" label="Last Name"
                        variant="outlined"
                        onChange={(e) => { handleChange(e); HandleLastNameVal(e); }}
                        helperText={Lastnameerror}
                        error={!!Lastnameerror}
                        name="lastName"
                        inputProps={{ maxlength: 100 }}
                        className={classes.userNameEmail}
                        InputProps={{
                          classes: {
                            root: classes.blueBorderBottom,
                          },
                        }} />
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid item className={classes.colPaddingClone} xs={12} sm={12} >
                <form noValidate autoComplete="off" >
                  <TextField id="U_Email"
                    label="Email" size="small"
                    variant="outlined"
                    onChange={(e) => { handleChange(e); validateEmail(e) }}
                    helperText={Emailerror}
                    inputProps={{ maxlength: 50 }}
                    error={!!Emailerror} name="userName"
                    className={classes.userNameEmail}
                    InputProps={{
                      classes: {
                        root: classes.blueBorderBottom,
                      },
                    }} />
                </form>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button id="U_CreateClone_btn" startIcon={<DoneAllIcon />} className={`${classes.cloneYesButton} ${classes.yesButton} `} variant="contained" onClick={(_) => { handleValidateUserExisitInAD(); }} color="primary" disabled={((!emailVal || !firstName || !lastName) || isDisable || NameVal)}>
              Clone User
            </Button>
            <Button id="U_Cancel_btn" startIcon={<CancelIcon />} className={`${classes.cloneNoButton} ${classes.noButton}`} variant="contained" onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={cloneUser} className="snackBarStyle" anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
            User has been cloned Successfully!
          </Alert>
        </Snackbar>
      </div>
      <Dialog open={dialogOpen} TransitionComponent={Transition} keepMounted
        onClose={handleDialogClose} aria-describedby="alert-dialog-slide-description" style={{ zIndex: 1100 }}
        PaperProps={{ style: { borderRadius: 15, maxHeight: 'calc(100% - 7px)' } }} >
        <DialogTitleDelete id="responsive-dialog-title" onClose={handleDialogClose}>
          <Typography variant="h5" gutterBottom className={classes.titleHeader}>
            CLONE AND INVITE AD USER
          </Typography>
        </DialogTitleDelete>
        <DialogContent>
          <Typography variant="h4" className={classes.MessageStyle} gutterBottom>
            This user already exists in your organization. Would you like to invite them to WebView?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<AccountCircleIcon />} size="small" style={{ borderRadius: '20px', backgroundColor: 'green', color: 'white' }} onClick={handleCreate}>Create User</Button>
          <Button startIcon={<CancelIcon />} size="small" style={{ borderRadius: '20px', backgroundColor: 'red', color: 'white' }} onClick={handleDialogClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment >
  );
}

export default CloneUser