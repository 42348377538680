import {
    Backdrop, Button, Dialog, DialogActions, DialogContent, Grid, Paper, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import moment from 'moment';
import { useState } from 'react';
import { PuffLoader } from 'react-spinners';
import { IActivityGroupName } from '../../../models/AgentTemplate/IAgentCustomizeControl';
import { useGlobalState } from '../../../store/GlobalStore';
import { useFetch } from '../../../utils/apiHelper';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { useStyles } from '../ManageGroup/ManageGroupCss';
import CreateManageGroup from './CreateManageGroup';
import DeleteManageGroup from './DeleteManageGroup';
import EditManageGroup from './EditManageGroup';

const ManageGroupDetails = () => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const [open, setOpen] = useState(false);
    const [ProgressBar, setshowProgressBar] = useState(false);
    const [activityGroupName, setActivityGroupName] = useState<IActivityGroupName[]>([]);

    const handleClickOpen = () => {
        setOpen(true);
        handleActivityGroupLoad();
    };

    const handleClose = () => {
        setOpen(false);
    };

    function EnhancedTableHead(props) {
        const {
            classes,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        const headCells = [
            { id: "group_Name", disablePadding: false, label: "GROUP NAME" },
            { id: "date_modified", disablePadding: false, label: "MODIFIED DATE" },
            { id: "Actions", disablePadding: false, label: "ACTIONS" },
            { id: "Delete", disablePadding: false, label: "DELETE" }
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            id="GAFAQ_TableHeader"
                            key={headCell.id}
                            className={classes.tablecellStyle}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const handleActivityGroupLoad = () => {
        setshowProgressBar(true);
        (async () => {
            await useFetch<IActivityGroupName[]>("AgentUser/GetActivityGroupName").then((r) => {
                setActivityGroupName(r?.data);
            });
        })().finally(() => {
            setshowProgressBar(false);
        })
    }

    return (
        <div>
            <Button id="CreateTemplate_btn" size="small" startIcon={<PlaylistAddIcon />} variant="contained" color="primary" onClick={handleClickOpen} className={classes.btnManTemp}>
                MANAGE GROUP
            </Button>

            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} maxWidth={'md'} TransitionComponent={Transition}
                onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        MANAGE GROUP DETAILS
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Backdrop className={classes.backdrop} open={ProgressBar}>
                        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <Grid container spacing={0}>
                        <Grid md={12}>
                            <CreateManageGroup onSave={handleActivityGroupLoad} />
                        </Grid>
                        <Grid md={12}>
                            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                                <Table aria-label="customized table" size="small" stickyHeader>
                                    <EnhancedTableHead
                                        classes={classes}
                                    />
                                    <TableBody>
                                        {activityGroupName?.map((row) => {
                                            return (
                                                <TableRow key={row?.activityGroupId}>
                                                    <TableCell className={classes.tablecell}>
                                                        {row?.activityGroupName}
                                                    </TableCell>
                                                    <TableCell className={classes.tablecell}>
                                                        {moment(row?.modifiedDate).format(state.GlobalUtils?.settingValue)} {moment(row?.modifiedDate).format('HH:mm:ss')}
                                                    </TableCell>
                                                    <TableCell className={classes.tablecell}>
                                                        <EditManageGroup row={row} onUpdate={handleActivityGroupLoad} />
                                                    </TableCell>
                                                    <TableCell className={classes.tablecell}>
                                                        <DeleteManageGroup activityGroupId={row?.activityGroupId} onDelete={handleActivityGroupLoad} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.cancelGroup} startIcon={<CancelIcon />} autoFocus onClick={handleClose} variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ManageGroupDetails