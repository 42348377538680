import {
    AppBar, Backdrop, Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent,
    DialogContentText, Grid, IconButton, Toolbar, Typography
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PuffLoader from "react-spinners/PuffLoader";
import { ARMLogo, clientBackup } from '../../constants/Constant';
import { IUserInvited } from '../../models/common/IUserInvited';
import { IClosedLetterDetails } from '../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { ILetterDetails } from '../../models/Inquiry/LetterDetails/ILetterDetails';
import { IFileGet } from "../../models/Roll/IFileGet";
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete, DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import useStyles from '../GlobalStyles/DocumentCss';
import LightTooltip from '../GlobalStyles/LightTooltip';
import CreditReportNotAffected from "./LetterDetails/CreditReportingNotAffected";
import CreditReportNotMentioned from "./LetterDetails/CreditReportingNotMentioned";
import CreditReportUpdated from "./LetterDetails/CreditReportUpdated";
import PaidLetter from "./LetterDetails/PaidLetter";

const DocumentUpload: React.FC<{ clientId: string, letterDetails: IClosedLetterDetails[], agencyId: string, accountId: string, onHideNotification: (dbtNo: string) => void, BlockAwaitAccess: boolean, GetDebtorLetterDetails: ILetterDetails[], dbt_status: string }> = (props) => {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const [FileDisplay, setFileDisplay] = useState<IFileGet[]>([]);
    const [open, setOpen] = useState(false);
    const [openDocs, setOpenDocs] = useState(false);
    const [serviceURL, setServiceURL] = useState('');
    const base = ("data:application/pdf;base64,");
    const [ProgressBar, setshowProgressBar] = useState(false);
    const theme = useTheme();
    const [alertOpen, setAlertOpen] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [fileExists, setFileExists] = useState(false);
    const [CheckInvited, setCheckInvited] = useState<number>(0);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [isValidDocument, setIsValidDocument] = useState<boolean>(false);

    const handleAlertClose = () => {
        setAlertOpen(false);
        setIsValidDocument(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseDocs = () => {
        setOpenDocs(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setFileExists(false);
    };

    useEffect(() => {
        (async () => {
            if (state.userAccessContext?.id !== undefined) {
                await useFetch<IUserInvited[]>(`User/CheckUserInvited?userId=${state?.userAccessContext?.id}`).then((r) => {
                    setCheckInvited(r?.data[0]?.user_id);
                });
            }
        })()
    }, []);

    const onSubmit = (selectedFile: File) => {
        if (selectedFile) {
            setshowProgressBar(true);
            if ((selectedFile?.type === 'image/jpeg' || selectedFile?.type === 'application/pdf') && selectedFile?.size > 0) {
                (async () => {
                    const formData = new FormData();
                    const currentDate = moment(new Date()).format('MMDDYY');
                    const fileExtension = selectedFile?.name?.split('.')?.pop();
                    const fileName = `${props?.agencyId}-${currentDate}${props?.clientId}-BU.${fileExtension}`;
                    formData.append("FormFile", selectedFile);
                    formData.append("FileName", fileName);
                    formData.append("FileSize", selectedFile?.size.toString());
                    formData.append("UserId", state?.userAccessContext?.id.toString());
                    formData.append("CategoryId", "3");

                    await usePost<any>("WebViewFiles/InsertBackUpFiles", formData).finally(() => {
                        setshowProgressBar(false);
                    });
                    props.onHideNotification(props?.agencyId);
                    UploadBackUpFile(selectedFile);
                })().finally(() => {
                    getFileDisplayAsync();
                })
            } else if (selectedFile?.size <= 0) {
                setAlertOpen(true);
                setIsValidDocument(true);
                setshowProgressBar(false);
            } else {
                setIsValidDocument(false);
                setshowProgressBar(false);
                setAlertOpen(true);
            }
        }
    }

    const UploadBackUpFile = (selectedFile: File) => {
        if (selectedFile) {
            if (selectedFile?.type === 'image/jpeg' || selectedFile?.type === 'application/pdf') {
                (async () => {
                    const formData = new FormData();
                    let dateTime = new Date(Date.now());
                    const currentDate = moment(dateTime).format('MMDDYY');
                    const fileExtension = selectedFile?.name?.split('.')?.pop();
                    const fileName = `${props?.agencyId}-${currentDate}${props?.clientId}-BU.${fileExtension}`;
                    formData.append("FormFile", selectedFile);
                    formData.append("ClientId", props?.clientId);
                    formData.append("file_name", fileName);
                    formData.append("userid", state?.userAccessContext?.id.toString());
                    formData.append("rid", props?.agencyId)
                    formData.append("upload_date", dateTime.toString());
                    formData.append("file_size", selectedFile?.size.toString());
                    formData.append("ContainerName", clientBackup);

                    await usePost<any>("File/FileInsert", formData);
                })().finally(() => {
                    getFileDisplayAsync();
                })
            }
        }
    }

    useEffect(() => {
        getFileDisplayAsync();
    }, [])

    async function getFileDisplayAsync() {
        dispatch({ type: GlobalStateAction.Busy });
        try {
            await useFetch<IFileGet[]>("File/FileDetails?DBTNumber=" + props?.agencyId).then((GetFileDetails) => {
                setFileDisplay(GetFileDetails.data);
            });
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    }

    function getPDFFileAsync(blobUri: string) {
        (async () => {
            setshowProgressBar(true);
            let request = {
                "blobUri": blobUri
            }
            await usePost<any>("File/GetBlobFileContent", request).then((r) => {
                setServiceURL(URL.createObjectURL(base64toBlob(base + r?.data)));
                setOpenDocs((r?.status === 404 || r?.status === 400) ? false : true);
            }).catch(() => {
                setFileExists(true);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })();

        const base64toBlob = (data: string) => {
            // Cut the prefix `data:application/pdf;base64` from the raw base 64
            const base64WithoutPrefix = data.substring('data:application/pdf;base64,'.length);
            const bytes = window.atob(base64WithoutPrefix);
            let length = bytes.length;
            let out = new Uint8Array(length);
            while (length--) {
                out[length] = bytes.charCodeAt(length);
            }
            return new Blob([out], { type: 'application/pdf' });
        };
    }


    async function DownloadFileAsync(blobUri: string, fileName: string) {
        (async () => {
            setshowProgressBar(true);
            let request = {
                "blobUri": blobUri
            }
            await usePost<any>("File/GetBlobFileContent", request).then((r) => {
                createAndDownloadBlobFile(base64ToArrayBuffer(r?.data), fileName);
            }).catch(() => {
                setFileExists(true);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })();
    }

    function base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    function createAndDownloadBlobFile(body, filename) {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            link.setAttribute('href', URL.createObjectURL(new Blob([body])));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    return (
        <React.Fragment>
            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={alertOpen} onClose={handleAlertClose} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        MESSAGE
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <DialogContentText>
                        {isValidDocument ?
                            <Typography variant="h5" className={classes.MessageStyle} gutterBottom>
                                The document upload failed due to an empty or corrupt file
                            </Typography>
                            :
                            <Typography variant="h5" className={classes.MessageStyle} gutterBottom>
                                Invalid File Type. Please select a PDF or JPG file.
                            </Typography>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button size="small" id="DU_ok_btn" variant="contained" onClick={(e) => { setAlertOpen(false); }} className={classes.yesButton}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Button id="I_DocumentUpload" variant="contained" color="primary" className={`${classes.documentButton} ${classes.buttoncloseSpacing}`}
                startIcon={<UploadFileIcon style={{ fontSize: 22 }} />}
                onClick={(_) => { handleClickOpen(); }} disabled={props.BlockAwaitAccess} >
                Document
            </Button>

            {openDocs ?
                <Dialog fullScreen className={classes.dialog} open={openDocs} onClose={handleCloseDocs} TransitionComponent={Transition}>
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar className={classes.toolbarStyle}>
                            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" >
                                <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                            </IconButton>
                            <Typography variant="h6" className={classes.title} >
                                <b>DOCUMENT VIEWER</b>
                            </Typography>
                            <IconButton edge="end" color="inherit" onClick={() => setOpenDocs(false)} aria-label="close" style={{ padding: '5px' }}>
                                <CloseIcon style={{ color: '#000', fontSize: 22 }} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
                        <Viewer fileUrl={serviceURL} plugins={[defaultLayoutPluginInstance]} />
                    </Worker>
                </Dialog> : null
            }


            <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose}
                classes={{ paper: classes.dialogeDocumentPaper }}
                PaperProps={{ style: { borderRadius: 15 } }}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        DOCUMENT UPLOAD
                    </Typography>
                </DialogTitleHeader>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent className={classes.dialogSpacingTop}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <DropzoneArea
                                onChange={(files) => onSubmit(files[0])}
                                acceptedFiles={['image/jpeg', 'application/pdf']}
                                showPreviews={false}
                                showPreviewsInDropzone={false}
                                filesLimit={1}
                            />
                        </Grid>
                        <Grid item xs={7} >
                            <Typography variant="h6" className={classes.typoTitle1} ><b>Documents</b></Typography>
                            <Box p={0} width="100%" flexShrink={0} bgcolor="white"  >
                                <Scrollbars autoHide={false}
                                    autoHeight
                                    className={classes.Documents}
                                    autoHeightMin={160}
                                    autoHeightMax={160}
                                    style={{ width: "100%" }}>
                                    {!FileDisplay.length ?
                                        < Typography variant="h6" gutterBottom className={classes.norecord}>
                                            No Documents to display..
                                        </Typography>
                                        : null}
                                    {FileDisplay?.map((f) => {
                                        let enable = f?.file_Name.split('.').pop() === 'pdf' ? true : f?.file_Name.split('.').pop() === 'PDF' ? true : false
                                        return (
                                            <React.Fragment>
                                                <div className={classes.documentStyle}>
                                                    <Box p={0} display="flex" justifyContent="flex-start" width="95%" bgcolor="white" style={{ backgroundColor: 'white' }}>
                                                        <Box p={0} width='79%' flexShrink={0} bgcolor="white" >
                                                            <Typography variant="subtitle2" className={classes.typoStyle}>
                                                                {f?.file_Name} - {moment(f?.upload_Date).format(state?.GlobalUtils?.settingValue)}
                                                            </Typography>
                                                        </Box>
                                                        <Box p={0} width='7%' flexShrink={0} bgcolor="white" >
                                                            <IconButton disabled={!enable} aria-label="delete" onClick={handleClickOpen} className={classes.Iconbutton}>
                                                                <LightTooltip title="Preview File">
                                                                    <VisibilityIcon onClick={() => { getPDFFileAsync(f.blobUri); }}
                                                                        className={!enable ? classes.NonVisible : classes.Visible} />
                                                                </LightTooltip>
                                                            </IconButton>
                                                        </Box>
                                                        <Box p={0} width='7%' flexShrink={0} bgcolor="white" >
                                                            <IconButton aria-label="delete" className={classes.Iconbutton}>
                                                                <LightTooltip title="Download File">
                                                                    <CloudDownloadIcon onClick={() => { DownloadFileAsync(f?.blobUri, f?.file_Name); }} className={classes.iconStyle} />
                                                                </LightTooltip>
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                </Scrollbars>
                            </Box>
                        </Grid>
                        <Grid item xs={5} >
                            <Typography variant="h6" className={classes.LetterStyle}> <b>Request a letter</b> </Typography>
                            <ButtonGroup
                                id="I_D_Letters"
                                fullWidth
                                aria-label="full width outlined button group"
                                className={classes.head}
                                classes={{ groupedOutlined: classes.groupedOutlined }} >
                                <PaidLetter dbtNumber={props?.agencyId} letterDetails={props?.letterDetails} GetDebtorLetterDetails={props?.GetDebtorLetterDetails} />
                                <CreditReportNotMentioned dbtNumber={props?.agencyId} letterDetails={props?.letterDetails} GetDebtorLetterDetails={props?.GetDebtorLetterDetails} />
                                <CreditReportUpdated dbtNumber={props?.agencyId} letterDetails={props?.letterDetails} GetDebtorLetterDetails={props?.GetDebtorLetterDetails} />
                                <CreditReportNotAffected dbtNumber={props?.agencyId} letterDetails={props?.letterDetails} GetDebtorLetterDetails={props?.GetDebtorLetterDetails} />
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id="I_D_Close_btn" variant="outlined" size="small" onClick={handleClose} startIcon={<CancelIcon />} className={classes.cancel} >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={fileExists} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    File does not exists!
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}

export default DocumentUpload