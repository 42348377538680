export function maskGuid(guid: string): string {
    // Show the first 4 characters, mask the middle characters, and show the last 4 characters
    const visibleChars = 4;
    const maskedChars = guid.length - (2 * visibleChars);

    const maskedGuid =
        guid.substring(0, visibleChars) +
        'X'.repeat(maskedChars) +
        guid.substring(guid.length - visibleChars);

    return maskedGuid;
};