import { createStyles } from '@material-ui/core';

const cardBodyStyle = createStyles({
  cardBody: {
    padding: '15px',
    flex: '1 1 auto',
    WebkitBoxFlex: 1,
  },
  cardBodyPlain: {
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  cardBodyProfile: {
  }
});

export default cardBodyStyle;
