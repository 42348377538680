import { useMsal } from '@azure/msal-react';
import {
  Button, ButtonBase, Container, Dialog, DialogContent, Divider, InputAdornment,
  makeStyles, TextField, Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import CookieConsent from "react-cookie-consent";
import { useHistory } from "react-router-dom";
import { PropagateLoader } from 'react-spinners';
import { loginRequest } from '../auth/authConfig';
import { DialogTitleHeader } from '../components/GlobalStyles/DialogStyle';
import * as constant from "../constants/Constant";
import { emailRegex, NameRegex, PhoneRegex } from '../constants/Constant';
import ARMlogo from '../images/header/header-logo.svg';
import { AccessContext } from '../models/accesscontrol/AccessContext';
import { IWidgetInfo } from '../models/accesscontrol/IWidgetInfo';
import { IProspectUsersDetails, IUserDetails } from '../models/accesscontrol/ProspectUsersDetails';
import { WidgetMap } from '../models/accesscontrol/WidgetMap';
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { useFetch, usePost } from '../utils/apiHelper';
import { ProspectClientInformation } from './Authentication/ProspectClientInformation';
import ReCaptchaComponent from '../utils/ReCaptcha';
import ResetPassword from './Authentication/ResetPassword';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: 0, bottom: 0, left: 0, right: 0,
    margin: 'auto',
    maxWidth: '100vw',
    height: '100vh',
    alignItems: "center",
    backgroundColor: '#215176',
    display: "flex",
    justifyContent: "center",
  },
  gridcontainer: {
    maxWidth: '520px',
    height: '400px',
    alignItems: "center",
    backgroundColor: 'white',
    justifyContent: "center",
    borderRadius: 30,
    display: "flex",
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
  },
  image: {
    width: '100%',
    height: '100%'
  },
  logo: {
    width: '400px',
    height: '130px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'cover',
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'black',
    fontSize: 38,
    marginTop: '25px'
  },
  loginbutton: {
    backgroundColor: '#283593',
    color: 'white',
    borderRadius: '5px',
    fontSize: '15px',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '75%',
    height: '45px',
    marginTop: '20px',
    '&:hover': {
      border: 'none',
      fontSize: '15px',
      fontWeight: 'bold',
      backgroundColor: '#3bb78f',
      boxShadow: '0px 15px 20px rgba(46, 229, 157, 0.4)',
      color: '#fff',
    }
  },
  continuebutton: {
    fontWeight: 'bold',
    backgroundColor: '#283593',
    color: 'white',
    width: '100%',
    padding: '8px',
    border: 'none',
    // marginTop: '10px',
    cursor: 'pointer',
    '&:hover': {
      border: 'none',
      padding: '8px',
      backgroundColor: '#3bb78f',
      boxShadow: '0px 15px 20px rgba(46, 229, 157, 0.4)',
    }
  },
  buttonbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 8,
    marginTop: '-0.5cm',
  },
  buttonbox1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '8px 0px '
  },
  resetbox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  buttonboxSignUp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: 8,
    marginTop: '10px',
  },
  divider: {
    width: '180px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#ff9800',
    fontWeight: 'bold',
    border: '2px solid #ff9800',
    borderRadius: '20px',
  },
  divider1: {
    width: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#ff9800',
    fontWeight: 'bold',
    border: '2px solid #ff9800',
    borderRadius: '20px',
  },
  cookies: {
    display: 'block',
  },
  cookiestext: {
    fontSize: '14px'
  },
  accountIcon: {
    color: '#0D324D', backgroundColor: 'white',
    borderRadius: '100%',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
  },
  loginSpan: {
    color: '#3bb78f',
    fontSize: '14px',
    marginLeft: '5px',
    '&:hover': {
      color: '#e91e63'
    }
  },
  createTitle: {
    padding: '10px',
    color: '#1b2fb7',
    fontSize: '23px',
  },
  createTitle1: {
    padding: '10px',
    color: '#1b2fb7',
    fontSize: '18px',
    marginLeft: '80px'
  },
  signNote: {
    backgroundColor: 'rgba(118, 53, 220, 0.08)',
    color: 'rgb(32,10,105)',
    borderRadius: '5px',
    padding: '5px',
    margin: '2px 0px 2px',
    fontSize: '13px'
  },
  alreadySignText: {
    padding: '6px 25px'
  },
  alreadySignText1: {
    padding: '0px 25px 10px'
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
      color: "green",
      border: '3px solid green'
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
      color: "green"
    },
    "& .MuiInputLabel-outlined": {
      color: "green"
    },
    "& .MuiOutlinedInput-input": {
      color: "green"
    }
  },
  rootError: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
      color: "red",
      border: '3px solid red',
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
      color: "red",
    },
    "& .MuiInputLabel-outlined": {
      color: "red",
    },
    "& .MuiOutlinedInput-input": {
      color: "red"
    },
  },
  disabledStyle: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "gray",
      color: "gray",
      border: '3px solid gray',
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "gray",
      color: "gray",
    },
    "& .MuiInputLabel-outlined": {
      color: "gray",
    },
    "& .MuiOutlinedInput-input": {
      color: "gray"
    },
  },
  textFiled: {
    marginTop: '5px'
  },
  titleHeaderStyle: {
    fontWeight: 'bold',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  itemOne: {
    "&.MuiDivider-root": {
      "&::before": {
        borderTop: "thin solid green"
      },
      "&::after": {
        borderTop: "thin solid blue"
      }
    },
    "& .MuiDivider-wrapper": {
      fontSize: 16
    }
  },
  captchaStyle: {
    boxSizing: 'border-box',
    borderRadius: '3px',
    width: '98%',
    fontSize: '15px',
    fontWeight: 'bold',
    letterSpacing: '5px',
    // padding: '6px',
    marginLeft: '4px',
    marginTop: '3px'
  },
  capchaGrid: {
    height: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '3px',
    border: '1px solid #b9b4b4',
    borderRadius: '3px'
  },
  canvasCapcha: {
    height: '100%',
    width: '100%',
    borderRadius: '3px'
  },
  refreshButton: {
    padding: '8px 9px',
    marginLeft: '5px',
    marginTop: '4px',
    borderRadius: '3px'
  },
  canvasGrid: {
    borderRadius: '3px',
    padding: '3px',
    boxSizing: 'border-box',
    width: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
  },
  capcha: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5px',
    borderRadius: '5px',
    padding: '5px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    background: 'whiteSmoke'
  }
}));

const Login: React.FC<{ isLoading: boolean, isAuth?: boolean, isAuthenticated?: boolean }> = (props) => {
  const { dispatch } = useGlobalState();
  const { accounts, instance, inProgress } = useMsal();
  let history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [emailerror, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [lastnameerror, setLastNameError] = useState("");
  const [orgEmailError, setOrgEmailError] = useState("");
  const [orgPhoneError, setOrgPhoneError] = useState("");
  const [firstnameerror, setFirstNameError] = useState("");
  const [organizationNameError, setorganizationNameError] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [managerEmailError, setManagerEmailError] = useState("");
  const [ProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [isPasswordProvided, setIsPasswordProvided] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isSignedupUser, setIsSignedupUser] = useState<boolean>(false);
  const [prospectEmailError, setProspectEmailError] = useState<string>("");
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [userDetailsList, setUserDetailsList] = useState<IProspectUsersDetails>();
  const [captchaValue, setCaptchaValue] = useState<string>("");
  const [captchaErrorMessage, setCaptchaErrorMessage] = useState<string>("");
  const [captchaLoaded, setCaptchaLoaded] = useState<boolean>(false);
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);


  let newUsersList: IUserDetails = {
    prospectEmailAddress: "",
    prospectFirstName: "",
    prospectLastName: "",
    prospectPhoneNumber: "",
    organizationName: "",
    organizationAdminEmailId: "",
    organizationPhoneNumber: "",
    password: "",
    confirmPassword: "",
    userName: ""
  }
  // localState to store the user information 
  const [localState, setLocalState] = useState<IUserDetails>(newUsersList);


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const validatePassword = (password) => {
    // Password validation criteria
    const minLength = 8;
    const regexUpperCase = /[A-Z]/;
    const regexLowerCase = /[a-z]/;
    const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    // Check minimum length
    if (password.length < minLength) {
      return "Password must be at least 8 characters long";
    }

    // Check for uppercase letters
    if (!regexUpperCase.test(password)) {
      return "Password must contain at least one uppercase letter";
    }

    // Check for lowercase letters
    if (!regexLowerCase.test(password)) {
      return "Password must contain at least one lowercase letter";
    }

    // Check for special characters
    if (!regexSpecialChar.test(password)) {
      return "Password must contain at least one special character";
    }

    // Return empty string if password is valid
    return "";
  };

  const handleValidatePassword = (event) => {
    // Call password validation function
    const error = validatePassword(event.target.value);
    // Set error message if password is invalid, otherwise clear error message
    if (error) {
      setPasswordError(error);
    } else {
      setPasswordError("");
    }
  }

  // Check if password and confirm password are the same
  const handleConfirmPasswordChange = (event) => {
    // Check if password and confirm password are the same
    if (localState?.password !== event?.target?.value) {
      setConfirmPasswordError("Password and Confirm Password didn’t match. Try again.");
    } else {
      setConfirmPasswordError("");
    }
  }

  const handleClientOpen = () => {
    setOpen(true);
    setCaptchaLoaded(true);
  }

  const handleClientClose = () => {
    setOpen(false);
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setManagerEmailError("");
    setPhoneError("");
    setOrgEmailError("");
    setOrgPhoneError("");
    setLocalState(newUsersList);
    setShowConfirmPassword(false);
    setShowPassword(false);
    setPasswordError("");
    setConfirmPasswordError("");
    setIsPasswordProvided(false);
    setProspectEmailError("");
    setorganizationNameError("");
    setCaptchaValue("");
    setCaptchaErrorMessage("");
    setCaptchaLoaded(false);
    setCaptchaResponse(null);
  }

  const handleSignin = () => {
    setOpen(false);
    if (!inProgress && accounts.length > 0) {
      const account = accounts[0];
      if (account) {
        try {
          instance.ssoSilent(loginRequest).then(a => {
            dispatch({
              type: GlobalStateAction.LoggedIn, user: {
                id: account.homeAccountId ?? "",
                name: a.account?.name ?? "",
                profileImage: "./images/header-logo.png",
                email: account.username ?? "",
                userToken: a.accessToken,
                homeAccountId: account.homeAccountId ?? "",
                localAccountId: account.localAccountId ?? "",
                objectId: a.uniqueId
              }
            })
          })
        }
        catch (exception) {
          instance.loginPopup(loginRequest)
        }
      }
    }
    else {
      instance.loginPopup(loginRequest).then(a => {
        dispatch({
          type: GlobalStateAction.LoggedIn, user: {
            id: a.account?.homeAccountId ?? "",
            name: a.account?.name ?? "",
            profileImage: "./images/header-logo.png",
            email: a.account?.username ?? "",
            userToken: a.accessToken,
            homeAccountId: a.account?.homeAccountId ?? "",
            localAccountId: a.account?.localAccountId ?? "",
            objectId: a.uniqueId
          }
        });
        (async () => {
          if (a.account !== null) {
            let request = { "email": a.account.username }
            let newRequestValue = { "userName": a.account.username }
            await usePost<IProspectUsersDetails>("User/GetNewUserDetails", newRequestValue).then(async (r) => {
              await usePost<any>("AgentUser/CheckAgentUser", request).then(async (r) => {
                let CheckAgentUser: boolean = false;
                CheckAgentUser = !r?.data[0]?.isAgentUser;
                if (CheckAgentUser) {
                  let requestAccount = { "userName": a?.account?.username };
                  await usePost<AccessContext>("User/GetUserInfoByEmail", requestAccount).then(async (userAcc) => {
                    dispatch({ type: GlobalStateAction.SetPermissions, userAccessContext: userAcc.data });
                    if (userAcc?.data?.role == 0 || userAcc?.data?.role == 1) {
                      await useFetch<IWidgetInfo[]>("User/GetWidgetsForClient?userId=" + userAcc?.data?.id).then((clientsWidgets) => {
                        for (let index = 1; index <= WidgetMap.size; index++) {
                          const value = WidgetMap.get(index);
                          if (value && clientsWidgets.data.filter(w => w.widgetId === index).length === 1) {
                            userAcc.data[value] = true;
                          }
                        };
                      });
                    }
                    dispatch({ type: GlobalStateAction.SetPermissions, userAccessContext: userAcc.data });

                    if (window.document.location.href === constant?.reportSubRouteKey
                      || window.document.location.href === constant?.reportSubRoute) {
                      history.push('/report');
                    } else if ((userAcc?.data.role !== 2 && userAcc?.data?.role !== 3) && (window.document.location.href === constant?.rollSubRouteKey || window.document.location.href === constant?.rollSubRoute) && (userAcc?.data?.rollAccounts === true)) {
                      history.push('/roll');
                    } else if (userAcc?.data?.role === 2 || userAcc?.data?.role === 3) {
                      history.push('/admin');
                    } else if ((userAcc?.data?.role === 0 || userAcc?.data?.role === 1) && userAcc?.data?.totalUserCount === 0) {
                      history.push('/dashboard');
                    } else if ((userAcc?.data?.role === 0 || userAcc?.data?.role === 1) && userAcc?.data?.totalUserCount > 0) {
                      history.push('/prospectUsersList');
                    } else {
                      history.push('/ManageClientFolderAccess')
                    }
                  });
                }
                else {
                  let request = { "emailAddress": a.account.username }
                  await usePost<any>("AgentUser/GetAgentUserByEmail", request).then((r) => {
                    dispatch({ type: GlobalStateAction.AgentUserAccessContext, AgentUserAccessContext: r?.data['agentUserDetails'][0] });
                    dispatch({ type: GlobalStateAction.AgentClientDetails, AgentClientDetails: r?.data['agentUserClientPermissions'] });
                    dispatch({ type: GlobalStateAction.AgentUserNotificationClientCodeDetails, AgentUserNotificationClientCodeDetails: r?.data['notificationClientDetails'] });
                    if (r?.data['agentUserDetails'][0]?.disputeValidation || r?.data['agentUserDetails'][0]?.paymentVerification || r?.data['agentUserDetails'][0]?.backupDocument) {
                      history.push('/agentdashboard');
                    } else {
                      history.push('/agentinquiry');
                    }
                  }).finally(() => {
                  });
                }
              });
            })
          }
        })()
      })
    }
  }

  const HandleFirstNameVal = (e) => {
    const FirstNameVal = e.target.value;
    if (FirstNameVal.match(NameRegex)) {
      setFirstNameError("");
    } else if (FirstNameVal === "") {
      setFirstNameError("First name is required");
    } else {
      setFirstNameError("Please enter characters only");
    }
  };

  const HandleLastNameVal = (e) => {
    const LastNameVal = e.target.value;
    if (LastNameVal.match(NameRegex)) {
      setLastNameError("");
    } else if (LastNameVal === "") {
      setLastNameError("Last name is required");
    } else {
      setLastNameError("Please enter characters only");
    }
  };

  const HandleUserEmailVal = (e) => {
    setIsPasswordProvided(true);
    setEmailError("");
    const EmailVal = e.target.value;
    if (EmailVal.match(emailRegex)) {
      setProspectEmailError(""); // log the data to the console
    } else if (EmailVal === "") {
      setProspectEmailError("Email address is required");
    } else {
      setProspectEmailError("Please enter valid email address");
    }
  };

  const HandlePhoneVal = (e) => {
    const PhoneVal = e.target.value;
    if (PhoneVal.match(PhoneRegex)) {
      setPhoneError("");
    } else if (PhoneVal === "") {
      setPhoneError("Phone number is Required");
    } else {
      setPhoneError("Please enter numbers only");
    }
  };

  const HandleManagerEmailVal = (e) => {
    const ManagerEmail = e.target.value;
    if (ManagerEmail.match(emailRegex)) {
      setManagerEmailError("");
    } else if (ManagerEmail === "") {
      setManagerEmailError("");
    } else {
      setManagerEmailError("Please enter valid mail address");
    }
  };

  const HandleOrgNameVal = (e) => {
    const OrganizationEmail = e.target.value;
    if (OrganizationEmail.match(NameRegex)) {
      setorganizationNameError("");
    } else if (OrganizationEmail === "") {
      setorganizationNameError("Organization Name is required");
    } else {
      setorganizationNameError("Please enter valid mail address");
    }
  };

  const HandleOrgContactVal = (e) => {
    const OrgPhone = e.target.value;
    if (OrgPhone.match(PhoneRegex)) {
      setOrgPhoneError("");
    } else if (OrgPhone === "") {
      setOrgPhoneError("Organization phone number is required");
    } else {
      setOrgPhoneError("Please enter numbers only");
    }
  };

  // to get the user details
  const handleGetProspectUserDetails = (event) => {
    let name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.value,
    });
  }

  const handleValidateAllField = () => {
    if (!localState?.organizationAdminEmailId?.length || !localState?.prospectEmailAddress?.length || !localState?.prospectFirstName?.length || !localState?.prospectLastName?.length || !localState?.prospectPhoneNumber?.length || !!firstnameerror?.length || !!lastnameerror?.length || !!emailerror?.length || !!phoneError?.length || !!managerEmailError?.length || !localState?.organizationName?.length) {
      if (localState?.organizationAdminEmailId === "") {
        setManagerEmailError("Manager/SPOC email is required");
      }
      if (localState?.organizationName === "") {
        setorganizationNameError("Organization Name is Reqiured");
      }
      if (localState?.prospectEmailAddress === "") {
        setEmailError("Email address is required");
      }
      if (localState?.prospectFirstName === "") {
        setFirstNameError("First name is required");
      }
      if (localState?.prospectLastName === "") {
        setLastNameError("Last name is required");
      }
      if (localState?.prospectPhoneNumber === "") {
        setPhoneError("Phone number is Required");
      }
    } else {
      handleSaveUserInformation();
    }
  }

  const handleValidateAllPasswordField = () => {
    if (!localState?.userName?.length || !localState?.prospectFirstName?.length || !localState?.prospectLastName?.length || !localState?.prospectPhoneNumber?.length || !!firstnameerror?.length || !!lastnameerror?.length || !!emailerror?.length || !!phoneError?.length ||
      !!managerEmailError?.length || !localState?.password?.length || !localState?.confirmPassword?.length || !localState?.organizationName?.length) {
      if (localState?.organizationName === "") {
        setorganizationNameError("Organization Name is Required");
      }
      if (localState?.prospectFirstName === "") {
        setFirstNameError("First name is required");
      }
      if (localState?.prospectLastName === "") {
        setLastNameError("Last name is required");
      }
      if (localState?.prospectPhoneNumber === "") {
        setPhoneError("Phone number is Required");
      }
      if (localState?.confirmPassword === "") {
        setConfirmPasswordError("Confirm Password is required");
      }
      if (localState?.password === "") {
        setPasswordError("Password is required");
      }
      if (localState?.userName === "") {
        setProspectEmailError("User Email is Required");
      }
    } else if (localState?.password !== localState?.confirmPassword) {
      setConfirmPasswordError("Password and Confirm Password didn’t match. Try again.");
      setLocalState({ ...localState, confirmPassword: null });
    } else {
      handleSaveUserInformation();
    }
  }

  const handleSaveUserInformation = async () => {
    const userEmail = !isPasswordProvided ? localState?.prospectEmailAddress : localState?.userName;
    setShowProgressBar(true);
    try {
      const response = await fetch("https://webviewapi.armsolutions.com/api/UserSignup/CheckEmailExist?userEmail=" + userEmail);
      const data = await response.json();
      if (!isPasswordProvided) {
        setEmailError("Email Already Exist in Azure AD try with another email");
      } else if (isPasswordProvided) {
        setProspectEmailError("Email Already Exist in Azure AD try with another email");
      }
      setShowProgressBar(false);
      console.log(data); // log the data to the console
    } catch (error) {

      /**
      * Calling the azure function to insert the Prospect Users Information
      */
      let url1 = 'https://webviewrpfunctions-uat.azurewebsites.net/api/AuthenticationValidatorForVariousIdentities?code=j7jEW6vLedqs9zyI9g9Z2er7Shu2sQeKiEESPT7mfCzoAzFuDrDINw==';
      const postData = async (url = url1, data = {}) => {
        const response = await fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data)
        });
        return response.json();
      };
      const data = {
        email: userEmail,
        givenName: localState?.prospectFirstName,
        surname: localState?.prospectLastName,
        extension_f0411468e83d4e8395bde3a20281a0e9_PhoneNumber: localState?.organizationPhoneNumber,
        extension_f0411468e83d4e8395bde3a20281a0e9_PointofContactEmailId: localState?.organizationAdminEmailId?.length ? localState?.organizationAdminEmailId : userEmail,
        extension_f0411468e83d4e8395bde3a20281a0e9_OrganizationName: localState?.organizationName,
        extension_f0411468e83d4e8395bde3a20281a0e9_OrganizationContactNumber: localState?.organizationPhoneNumber,
        isPasswordApproach: isPasswordProvided
      };
      const result = await postData(url1, data);
      console.log(result);
      console.error(error);
      if (result?.status === "400") {
        if (!isPasswordProvided) {
          setEmailError("Email already exist in our system, hence your sign up is rejected. Kindly use other email id.");
        } else if (isPasswordProvided) {
          setProspectEmailError("Email already exist in our system, hence your sign up is rejected. Kindly use other email id.");
        }
      } else if (result?.status === "200") {
        /**
          * Call the graph API to send an invitation to Signup user
          * 
          * Calling the graph API to Create a new user in the Azure AD
          */
        try {
          let request = {
            "fullName": localState?.prospectFirstName + localState?.prospectLastName,
            "userEmail": !isPasswordProvided ? localState?.prospectEmailAddress : localState?.userName,
            "givenName": localState?.prospectFirstName,
            "surname": localState?.prospectLastName,
            "password": localState?.password
          }
          const response = await fetch("https://webviewapi.armsolutions.com/api/UserSignup/SendSignupInvitation", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
          });
        } catch (error) {
          console.log(error); // log the data to the console
        }
        setEmailError("");
        setOpen(false); // closing the dialog box
        handleMapUserDetails();
      }
      setShowProgressBar(false);
    }
  }

  const handleMapUserDetails = () => {
    let newUserDetails: IProspectUsersDetails = {
      prospectEmailAddress: !isPasswordProvided ? localState?.prospectEmailAddress : localState?.userName,
      prospectGivenName: "",
      prospectLastName: "",
      prospectDisplayName: "",
      prospectPhoneNumber: "",
      isNewOrginization: false,
      isWebviewUser: false,
      isClientOnboarded: false,
      isUserActivated: false,
      isPermissionUpdated: false,
      isDocumentUploaded: false,
      isAdminApproved: false,
      isClientInfoConfirmed: false,
      prospectUserId: 0,
      prospectCreatedDate: new Date,
      organizationName: "",
      organizationAdminEmailId: "",
      organizationPhoneNumber: "",
      isClientSetup: false,
      isActive: false,
      isMultiBranch: false,
      isNegatedUser: false
    }
    setUserDetailsList(newUserDetails);
    setIsSignedupUser(true);
  }

  const handleCaptchaVerify = (dataFromChild) => {
    // Use the data from the child component in your condition
    if (dataFromChild?.length) {
      setCaptchaResponse(dataFromChild);
    } else if (!dataFromChild?.length) {
      setCaptchaResponse(null);
    }
  };

  return (
    <React.Fragment>
      {isSignedupUser ?
        <ProspectClientInformation userDetails={userDetailsList} isPasswordProvided={isPasswordProvided} />
        :
        <>
          <Container className={classes.container} component="div" >
            <Grid container spacing={0} className={classes.gridcontainer}>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom className={classes.title}>
                  Welcome To
                </Typography>
                <Divider variant="middle" className={classes.divider} />
              </Grid>
              <Grid item xs={12}>
                <img src={ARMlogo} alt="ARM_Solutions" className={classes.logo} />
              </Grid>
              {!props.isAuth ?
                <>
                  <Grid item xs={12} className={classes.buttonbox}>
                    <Button color="primary" variant="outlined" className={classes.loginbutton}
                      onClick={handleSignin} disabled={props.isLoading || props.isAuth}>Login</Button>
                  </Grid>
                  <Grid item xs={12} className={classes.resetbox}>
                    <ResetPassword />
                  </Grid>
                  <Grid item xs={12}>
                    <div className="line-container">
                      <div className="hr-left"></div>
                      <p className="line-text"><b>OR</b></p>
                      <div className="hr-right"></div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} >
                    <Typography variant='subtitle2' align='center' className={classes.alreadySignText1}>
                      Don't have an account? <ButtonBase className={classes.loginSpan} onClick={handleClientOpen} ><b>Create New One</b></ButtonBase>
                    </Typography>
                  </Grid>
                </>
                :
                <Grid item xs={12} className={classes.buttonbox} >
                  <PropagateLoader size={15} color={"blue"} speedMultiplier={1} />
                </Grid>
              }
              <div>
                {props.children}
              </div>
            </Grid>

            <CookieConsent
              location="bottom"
              buttonText="Accept Cookies"
              cookieName="WebViewCookies"
              style={{
                background: "white",
                color: 'black',
                paddingLeft: 250,
                paddingRight: 250,
                fontSize: 14,

              }}
              buttonStyle={{
                color: "#4e503b",
                fontSize: "14px",
                borderRadius: 10,
                padding: 8,
                fontWeight: "bolder",
                display: 'block'
              }}
            >
              <Typography gutterBottom className={classes.cookiestext}>
                We use cookies to enhance the user experience, analyze site traffic, and serve targeted,
                By continuing to use this website, Click on Accept cookies to get in to Website.
              </Typography>
            </CookieConsent>
          </Container>
        </>
      }

      <Dialog
        open={open} onClose={handleClientClose} maxWidth={'md'}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: {
            borderRadius: 20,
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
          }
        }} >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClientClose}>
          <Typography variant="h6" className={classes.titleHeaderStyle}>
            CREATE YOUR ACCOUNT
          </Typography>
        </DialogTitleHeader>
        <hr className='line-break' />
        <DialogContent>
          <Grid container spacing={1} >
            <Grid item xs={12} sm={12}>
              <Typography variant='h6' align='center' >
                <img src={ARMlogo} alt="ARM_Solutions" width="220px" height="70px" />
              </Typography>
              <Divider variant="middle" className={classes.divider1} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <div style={{ padding: '0 20px' }}>
                <Typography variant='subtitle2' align='justify' className={classes.signNote}>
                  <span><b>Note : </b></span>Please note that phone verification is required for signup. Your number will only be used to verify your identity for security purposes.
                </Typography>
              </div>
            </Grid>
          </Grid>

          <div className="container">
            <div className="row">
              <div style={{ boxShadow: '0 14px 28px rgba(0, 0, 0, .2), 0 10px 10px rgba(0, 0, 0, .2)' }}>
                <div className="col2" >
                  <Grid container spacing={1} >
                    <Grid item xs={12} sm={12}>
                      <TextField id="outlined-basic"
                        required
                        name="userName"
                        label="Email (Login user ID)"
                        variant="outlined"
                        size="small" fullWidth
                        disabled={!!localState?.prospectEmailAddress?.length}
                        className={!!localState?.prospectEmailAddress?.length ? classes.disabledStyle : prospectEmailError ? classes.rootError : classes.root}
                        inputProps={{ maxlength: 50 }}
                        onChange={(e) => { HandleUserEmailVal(e); handleGetProspectUserDetails(e); }}
                        helperText={prospectEmailError} error={!!prospectEmailError}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField id="outlined-basic"
                        required
                        name="password"
                        label="Password"
                        variant="outlined"
                        disabled={!!localState?.prospectEmailAddress?.length}
                        type={showPassword ? 'text' : 'password'}
                        size="small" fullWidth
                        className={classes.textFiled}
                        inputProps={{ maxlength: 50 }}
                        onChange={(e) => { handleGetProspectUserDetails(e); handleValidatePassword(e) }}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end" >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                tabIndex={-1}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                              </IconButton>
                            </InputAdornment>
                        }}
                        helperText={passwordError} error={!!passwordError}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField id="outlined-basic"
                        required
                        name="confirmPassword"
                        label="Confirm Password"
                        variant="outlined"
                        disabled={!!localState?.prospectEmailAddress?.length}
                        size="small" fullWidth
                        type={showConfirmPassword ? 'text' : 'password'}
                        className={classes.textFiled}
                        inputProps={{ maxlength: 50 }}
                        onChange={(e) => { handleGetProspectUserDetails(e); handleConfirmPasswordChange(e); }}
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end" >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownConfirmPassword}
                                tabIndex={-1}
                                edge="end"
                              >
                                {showConfirmPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                              </IconButton>
                            </InputAdornment>
                        }}
                        helperText={confirmPasswordError} error={!!confirmPasswordError}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="col2">
                <Grid container spacing={1} >
                  <Grid item xs={12} sm={6}>
                    <TextField id="outlined-basic"
                      required
                      name="prospectFirstName"
                      label="First Name"
                      variant="outlined"
                      className={classes.textFiled}
                      size="small" fullWidth
                      inputProps={{ maxlength: 50 }}
                      onChange={(e) => { HandleFirstNameVal(e); handleGetProspectUserDetails(e); }}
                      helperText={firstnameerror} error={!!firstnameerror}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField id="outlined-basic"
                      required
                      name="prospectLastName"
                      label="Last Name"
                      variant="outlined"
                      className={classes.textFiled}
                      size="small" fullWidth
                      inputProps={{ maxlength: 50 }}
                      onChange={(e) => { HandleLastNameVal(e); handleGetProspectUserDetails(e); }}
                      helperText={lastnameerror} error={!!lastnameerror}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField id="outlined-basic"
                      required
                      name="prospectPhoneNumber"
                      label="Phone Number"
                      variant="outlined"
                      className={classes.textFiled}
                      size="small" fullWidth
                      inputProps={{ maxLength: 16 }}
                      onChange={(e) => { HandlePhoneVal(e); handleGetProspectUserDetails(e); }}
                      helperText={phoneError} error={!!phoneError}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField id="outlined-basic"
                      required
                      name="organizationAdminEmailId"
                      label="If you’re part of an existing client, enter your administrators email address.  Otherwise, leave blank."
                      variant="outlined"
                      className={classes.textFiled}
                      size="small" fullWidth
                      inputProps={{ maxlength: 50 }}
                      onChange={(e) => { HandleManagerEmailVal(e); handleGetProspectUserDetails(e); }}
                      helperText={managerEmailError} error={!!managerEmailError}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField id="outlined-basic"
                      required
                      name="organizationName"
                      label="Organization Full Name"
                      variant="outlined"
                      // className={classes.textFiled}
                      style={{ marginTop: '3px' }}
                      size="small" fullWidth
                      inputProps={{ maxlength: 50 }}
                      onChange={(e) => { HandleOrgNameVal(e); handleGetProspectUserDetails(e); }}
                      helperText={organizationNameError} error={!!organizationNameError}
                    />
                    {/* </Grid>
                  <Grid item xs={12} sm={6}> */}
                    <TextField id="outlined-basic"
                      name="organizationPhoneNumber"
                      label="Organization Contact Number"
                      variant="outlined" fullWidth size="small"
                      inputProps={{ maxLength: 12 }}
                      // className={classes.textFiled}
                      style={{ marginTop: '14px' }}
                      onChange={(e) => { HandleOrgContactVal(e); handleGetProspectUserDetails(e); }}
                      helperText={orgPhoneError} error={!!orgPhoneError}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.capcha}>
                    {captchaLoaded ? (
                      <div style={{ marginTop: '8px' }}>
                        <ReCaptchaComponent onVerify={handleCaptchaVerify} />
                      </div>
                    )
                      : (
                        <div>Loading captcha...</div>
                      )}
                  </Grid>
                  {!ProgressBar ?
                    <Grid item xs={12} sm={12} className={classes.buttonbox1}>
                      <Button color="primary" variant="outlined" className={classes.continuebutton}
                        disabled={!!firstnameerror?.length || !!lastnameerror?.length || !!emailerror?.length || !!phoneError?.length || !!managerEmailError?.length ||
                          !!passwordError?.length || !!confirmPasswordError?.length || !!organizationNameError?.length || !captchaResponse?.length}
                        onClick={handleValidateAllPasswordField}>
                        Sign up
                      </Button>
                    </Grid>
                    :
                    <Grid item xs={12} className={classes.buttonbox1} justify='center'>
                      <PropagateLoader size={15} color={"blue"} speedMultiplier={1} />
                    </Grid>
                  }
                  <Grid item xs={12} sm={12}>
                    <Typography variant='subtitle2' align='center' className={classes.alreadySignText}>
                      Already have an account? <ButtonBase className={classes.loginSpan} onClick={handleSignin} disabled={props.isLoading || props.isAuth}><b>Login</b></ButtonBase>
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment >
  )
}

export default Login