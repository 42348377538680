import {
    Backdrop, Box, Button, Dialog, DialogActions, DialogContent, Snackbar, Typography
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import React, { useState } from 'react';
import important from '../../images/users/warning.svg';
import { usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete } from '../GlobalStyles/DialogStyle';
import useStyles from './AgentUserCss';
import { PuffLoader } from 'react-spinners';

const InviteAgentUser: React.FC<{ id: number, email: string, onSentInvite: () => void, InviteStatus: number }> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [valEmail, setValEmail] = useState(false);
    const [sendInvite, setInvite] = useState(false);
    const [warningOpen, setWarningOpen] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);

    const handleClickOpen = () => {
        setShowProgressBar(true);
        (async () => {
            try {
                if (props?.email.endsWith('armsolutions.com')) {
                    const response = await fetch("https://webviewapi.armsolutions.com/api/UserSignup/CheckEmailExist?userEmail=" + props?.email);
                    const data = await response.json();
                    if (data && data.length > 0) {
                        setOpen(true);
                    } else {
                        setWarningOpen(true);
                    }
                } else {
                    setOpen(true);
                }
            } catch (innerError) {
                setWarningOpen(true);
            }
        })().finally(() => {
            setShowProgressBar(false);
        })
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleInviteWarningClose = () => {
        setWarningOpen(false);
    }

    const handleCloseDialog = () => {
        setValEmail(false);
        setOpen(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setInvite(false);
    };

    const CheckAgentUserEmail = () => {
        (async () => {
            let valEmail = { "email": props?.email };
            await usePost("AgentUser/CheckAgentSecondaryEmail", valEmail).then(async (r) => {
                if (r?.data[0]?.isExists) {
                    setValEmail(true);
                } else {
                    let request = { "agentUserId": props?.id };
                    await usePost("AgentUser/InviteAgentUser", request);
                    props?.onSentInvite();
                }
            });
        })()
    }

    return (
        <React.Fragment>
            <div>
                <Button size="small" id="InviteUser_btn" startIcon={<ContactMailIcon />} onClick={handleClickOpen} variant="contained"
                    color="primary" style={{ borderRadius: 20 }} className={classes.button} disabled={props?.InviteStatus === 2}>
                    {props?.InviteStatus === 2 ? "Invited" : props?.InviteStatus === 1 ? "Re-Invite" : "Invite"}
                </Button>

                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                </Backdrop>

                <Dialog
                    PaperProps={{ style: { borderRadius: 10 } }}
                    open={valEmail}
                    maxWidth={'sm'}
                    onClose={handleCloseDialog}
                    TransitionComponent={Transition}
                    aria-labelledby="responsive-dialog-title" >
                    <DialogTitleDelete id="responsive-dialog-title" onClose={handleCloseDialog}>
                        <Typography variant="h6" gutterBottom className={classes.titleheader}>
                            SECONDARY EMAIL VALIDATION
                        </Typography>
                    </DialogTitleDelete>
                    <DialogContent>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <img src={important} alt="Icon for Invite user Warning" className={classes.imageSize} />
                        </Box>
                        <Typography variant="h6" className={classes.textprop} gutterBottom>
                            <b className={classes.textcolor}>"{props?.email} "</b> Email id conflicts with existing primary Email id. Please contact <b className={classes.textcolor}>"ClientService@armsolutions.com"</b> for resolution.
                        </Typography>
                    </DialogContent>
                    <DialogActions >
                        <Button id="CPO_OK_btn" size="small" color="primary" className={classes.yesButton1} autoFocus startIcon={<CheckCircleOutlineIcon />} onClick={() => { handleCloseDialog(); }}>
                            Yes
                        </Button>
                        <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.noButton1} autoFocus onClick={handleCloseDialog}
                            variant="contained" startIcon={<CancelIcon />}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    PaperProps={{ style: { borderRadius: 10 } }}
                    open={open}
                    maxWidth={'sm'}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    aria-labelledby="responsive-dialog-title" >
                    <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
                        <Typography variant="h6" gutterBottom className={classes.titleheader}>
                            {props?.InviteStatus === 1 ? "RE-INVITE USER" : "INVITE USER"}
                        </Typography>
                    </DialogTitleDelete>
                    <DialogContent>
                        <Typography variant="h6" className={classes.titleConfirm} gutterBottom>
                            Are you sure, you want to {props?.InviteStatus === 1 ? "Re-Invite" : "Invite"} {props?.email}. ?
                        </Typography>
                    </DialogContent>
                    <DialogActions >
                        <Button id="CPO_OK_btn" size="small" color="primary" className={classes.yesButton1} autoFocus startIcon={<CheckCircleOutlineIcon />} onClick={() => { setOpen(false); CheckAgentUserEmail(); setInvite(true); }}>
                            Yes
                        </Button>
                        <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.noButton1} autoFocus onClick={handleClose}
                            variant="contained" startIcon={<CancelIcon />}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <Snackbar open={sendInvite} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Invitation Sent Successfully!
                </Alert>
            </Snackbar>

            <Dialog
                open={warningOpen}
                PaperProps={{ style: { borderRadius: 10 } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleInviteWarningClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleInviteWarningClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleHeader}>
                        INVITE AD AGENT USER
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <Typography> <b>This user does not exist in your organization, hence they can not be invited to WebView.</b> </Typography>
                </DialogContent>
                <DialogActions>
                    <Button size='small' style={{ backgroundColor: 'red', color: 'white' }} onClick={handleInviteWarningClose}>Cancel</Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    );
}

export default InviteAgentUser