import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, { useState } from 'react';
import { INotificationCenter } from "../../models/NotificationCenter/INotificationCenter";
import { usePost } from '../../utils/apiHelper';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import LightTooltip from '../GlobalStyles/LightTooltip';
import useStyles from './NCCcss/NotiCenterCss';
import { Transition } from '../GlobalStyles/DialogBoxTransition';

const HideNotification: React.FC<{ Notify: INotificationCenter, onHide: () => void }> = (props) => {
    const { Notify } = props;
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const HIDENOF = () => {
        (async () => {
            let request = {
                "UserNotificationId": Notify?.userNotificationId,
            };
            await usePost("HideNotification", request);
            props.onHide();
        })()

    }
    return (
        <React.Fragment>
            <LightTooltip title="Hide">
                <IconButton id="NC_HideIcon" color="secondary" aria-label="upload picture" component="span" disabled={Notify.hiddenFlag ? true : false} onClick={handleClickOpen} style={{ padding: '4px' }}>
                    <VisibilityOffIcon />
                </IconButton>
            </LightTooltip>
            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen}
                open={open}
                PaperProps={{ style: { borderRadius: 10 } }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        {Notify?.notificationName?.toUpperCase()}
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Typography variant="h6" style={{ fontSize: 17 }} gutterBottom>
                        Are you sure, you want to Hide {Notify?.notificationName} Notification ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(_) => { HIDENOF(); handleClose(); }} size="small" variant="contained" autoFocus className={classes.buttonGreen} >
                        Yes
                    </Button>
                    <Button autoFocus onClick={handleClose} variant="contained" size="small" className={classes.buttonRed} >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    )
}

export default HideNotification
