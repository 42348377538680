import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Snackbar, Typography, useMediaQuery } from '@material-ui/core'
import React, { useState } from 'react'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { usePost } from '../../utils/apiHelper';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Transition } from '../GlobalStyles/DialogBoxTransition';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            borderRadius: "20px",
            background: "#215176",
            '&:hover': {
                background: "#215176",
            }
        },
        yesButton: {
            background: "green",
            color: 'white',
            borderRadius: 20,
            bottom: '0.1cm',
            right: '15px',
            '&:hover': {
                background: "green",
            }
        },
        noButton: {
            background: "red",
            borderRadius: 20,
            display: 'flex',
            right: '10px',
            bottom: '0.1cm',
            '&:hover': {
                background: "red",
            }
        },
        titleHeaderStyle: {
            fontWeight: 'bold', marginTop: '5px', color: 'white'
        }
    })
);

const DeleteFtpFiles: React.FC<{ fileId: number, onDelete: () => void }> = (props) => {
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isDeleted, setIsDeleted] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const HandleDeleteFile = () => {
        (async () => {
            let request = {
                "fileId": props?.fileId
            };
            await usePost<any>("Placements/DeleteFtpFile", request).then(() => {
                setOpen(false);
                props.onDelete();
            }).finally(() => {
                setIsDeleted(true);
            });
        })()
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsDeleted(false);
    };

    return (
        <div>
            <Button aria-label="delete" color="primary" onClick={(_) => { handleClickOpen(); }}>
                <DeleteForeverIcon style={{ color: 'red', fontSize: '25px' }} />
            </Button>
            <Dialog TransitionComponent={Transition}
                PaperProps={{ style: { borderRadius: 15 } }}
                maxWidth={'xs'}
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleHeaderStyle}>
                        DELETE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h6" style={{ marginTop: "10px", color: "black" }} gutterBottom>
                            Are you sure, you want to Delete this File ?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size="small" id="IM_ok_btn" variant="contained" onClick={HandleDeleteFile} className={classes.yesButton} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button size="small" id="IM_Cancel_Btn" variant="contained" autoFocus className={classes.noButton} onClick={handleClose} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={isDeleted} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    File Deleted Successfully!
                </Alert>
            </Snackbar>
        </div>
    )
}

export default DeleteFtpFiles