import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
//Icons
import { Backdrop, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import PuffLoader from 'react-spinners/PuffLoader';
import * as constants from '../../constants/Constant';
import addfolder from "../../images/files/add-folder.svg";
import { usePost } from '../../utils/apiHelper';
import AvatarTooltip from '../GlobalStyles/AvatarTooltip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(0.5),
            borderRadius: 20,
            fontSize: 15,
            fontWeight: 'bold'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(0),
            color: 'black',
        },
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white',
            fontSize: 18
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        yesButtonStyle: {
            background: "green",
            marginRight: "10px",
            borderRadius: 30,
            fontSize: 12,
            color: 'white',
            '&:hover': {
                background: "green",
            }
        },
        noButtonStyle: {
            background: "red",
            marginRight: "10px",
            borderRadius: 30,
            fontSize: 12,
            '&:hover': {
                background: "red",
            }
        }
    }),
);

const CreateNewFolder: React.FC<{ OnCreate: () => void }> = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const { dispatch } = useGlobalState();

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = useState('CloudContainer');
    const [inputVal, setInputVal] = useState<any>('');
    const [nameerror, setNameError] = useState("");
    const [CName, setCName] = useState<string>();

    const [ProgressBar, setShowProgressBar] = useState(false);
    const [isDisableCN, setIsDisableCN] = useState(false);
    const [open, setOpen] = useState(false);

    //Alert
    const [isCreate, setIsCreate] = useState(false);
    const [isCreated, setIsCreated] = useState(false);


    const handleRadioChange = (event) => {
        setValue(event.target.value);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsCreate(false);
    };
    const closeSnackbar1 = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsCreated(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setNameError(null);
    };

    const CreateNewContainer = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "containerName": CName,
                "containerType": value
            }
            await usePost<any>("File/CreateContainer", request).then((CreateContainer) => {
                setCName(CreateContainer.data);
                if (CreateContainer.status == 200) {
                    setIsCreated(true);
                    setShowProgressBar(false);
                }
                if (CreateContainer.status == 400) {
                    dispatch({ type: GlobalStateAction.Error, error: CreateContainer.statusText })
                    setShowProgressBar(false);
                }
            });

            await usePost<any>("File/InsertContainer", request).then((createContainer) => {
                setCName(createContainer.data);
            });
        })().catch((err) => {
            if (err.response.status == 500) {
                setIsCreate(true);
                setShowProgressBar(false);
            }
        }).finally(() => {
            props.OnCreate();
        });
    }

    const HandleFirstNameVal = (e) => {
        const FirstNameVal = e.target.value;
        if (constants.AllowAlphabets.test(FirstNameVal)) {
            setNameError("");
            setIsDisableCN(false);
            setCName(FirstNameVal);
        } else {
            setNameError("please enter lowercase characters only without white space");
            setIsDisableCN(true);
        }
    };

    const handleChange = (event) => {
        setInputVal(event.target.value);
    };

    return (
        <React.Fragment>
            <AvatarTooltip title="Create Folder">
                <img src={addfolder} alt="Icon for Delete" width="30px" height="30px" onClick={handleClickOpen} />
            </AvatarTooltip>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <Dialog
                fullScreen={fullScreen}
                PaperProps={{ style: { borderRadius: 10 } }}
                open={open}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        CREATE NEW FOLDER
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <TextField autoComplete='off' id="CNF_TextField" size="small" label="Name" variant="outlined" onChange={(e) => { handleChange(e); setCName(e.target.value); HandleFirstNameVal(e); }} inputProps={{ maxlength: 50 }} helperText={nameerror} error={!!nameerror}
                        style={{ width: 550, boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)', marginTop: '15px' }} />
                    <FormControl component="fieldset" style={{ marginTop: '20px', }}>
                        <FormLabel component="legend" >Select Folder Type</FormLabel>
                        <RadioGroup row value={value} onChange={handleRadioChange} style={{ marginTop: '5px' }}>
                            <FormControlLabel value="CloudContainer" control={<Radio size="small" />} label="Cloud Folder" />
                            <FormControlLabel value="PaymentContainer" control={<Radio size="small" />} label="Payment Folder" />
                            <FormControlLabel value="AckContainer" control={<Radio size="small" />} label="Acknowledgement Folder" />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions style={{ marginTop: "15px" }}>
                    <Button id="IM_ok_btn" size="small" className={classes.yesButtonStyle} variant="contained" onClick={(_) => { handleClose(); CreateNewContainer(); }} disabled={!inputVal || isDisableCN} color="primary" autoFocus >
                        Yes
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" variant="contained" autoFocus className={classes.noButtonStyle} onClick={handleClose} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar className="snackBarStyle" open={isCreate} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    Folder Already Exists.
                </Alert>
            </Snackbar>
            {<Snackbar className="snackBarStyle" open={isCreated} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar1}>
                <Alert onClose={closeSnackbar1} severity="success" className="alertStyle">
                    Folder Created Successfully.
                </Alert>
            </Snackbar>}
        </React.Fragment>
    )
}

export default CreateNewFolder