import { Collapse, Grid, IconButton, Typography } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { SnackbarOrigin } from '@material-ui/core/Snackbar';
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DateRangeIcon from '@material-ui/icons/DateRange';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@mui/icons-material/Close';
import * as _ from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PuffLoader from "react-spinners/PuffLoader";
import Beginorder from "../components/Dashboard/Beginorder";
import Footer from '../components/Footer/Footer';
import { DialogTitleHeader } from '../components/GlobalStyles/DialogStyle';
import graph from '../images/buttonIcons/dashboard_graph.svg';
import { IDashboardAgeByUser } from "../models/dashboard/IDashboardAgeByUser";
import { INotification } from '../models/dashboard/INotification';
import { INotificationCount } from "../models/dashboard/INotificationCount";
import { IPlacementHistoryByUser } from "../models/dashboard/IPlacementHistoryByUser";
import BarChart from '../pages/Dashboard/BarChart';
import LineChart from '../pages/Dashboard/LineChart';
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { useFetch, usePost } from '../utils/apiHelper';
import Card from './Card/Card';
import CardBody from './Card/CardBody';
import CardHeader from './Card/CardHeader';
import CardIcon from './Card/CardIcon';
import { AccessContext } from "../models/accesscontrol/AccessContext";
import { useMsal } from "@azure/msal-react";
export interface DisplayState extends SnackbarOrigin {
  open: boolean;
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid1: {
      marginBottom: "10px",
    },
    searchBox: {
      marginTop: '10px',
      borderRadius: 10,
      width: "98%",
      backgroundColor: '#d1f9d3',
      marginLeft: '0.5cm',
      color: 'black',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      padding: '0px 15px'
    },
    card: {
      color: theme.palette.text.secondary,
      height: '7cm',
      boxShadow: '0px 1px 5px 1px rgba(103, 128, 159, 1)',
      '&:hover': {
        transform: 'scale(1)',
        cursor: 'pointer',
        border: '3px blue solid',
        boxShadow: '0 10px 10px rgba(0, 0, 0, 0.4)',
      },
      [theme.breakpoints.down('lg')]: {
        maxHeight: 'calc( 55vh - 210px)',
        minHeight: 'calc( 55vh - 210px)',
      },
    },
    root: {
      flexGrow: 1,
    },
    grid: {
      padding: theme.spacing(2),
      height: '5.1cm',
      marginTop: '12px',
    },
    grid2: {
      marginTop: '25px',
    },
    graphAlign: {
      left: '40%',
      right: '40%',
    },
    fonts: {
      fontFamily: 'Segoe UI',
      color: "black",
      textAlign: "center",
      fontSize: 16
    },
    bottomGrid: {
      height: '50px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(2),
      color: theme.palette.info[500],
    },
    media: {
      height: 140,
    },
    div: {
      flexGrow: 1,
      overflowY: 'hidden',
      overflowX: 'hidden',
      marginTop: "-40px",
      height: 'auto',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#ffff',
    },
    iconbutton: {
      border: '2px #215176 solid',
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(1),
      right: theme.spacing(1),
    },
    stylefooter: {
      marginTop: '60px'
    },
    chartimg: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '35vw',
      textAlign: 'center'
    },
    dialogHeader: {
      fontWeight: 'bold',
      marginTop: '5px',
      color: 'white',
      textAlign: "center"
    },
    notificationCount: {
      color: "black",
      fontWeight: 400
    },
    notificationCount1: {
      color: "black",
      fontWeight: 400
    },
    cardHeader1: {
      padding: "5px",
    },
    cardHeader2: {
      padding: "10px",
    },
    cardBody: {
    },
    cardBodyNotif: {
    },
    linkStyle: {
      textDecoration: 'none',
    },
    barLinechartSpacing: {
      padding: '0px 15px 0px 17px',
    },
    barLinechartSpacing1: {
      padding: '0px 18px 0px 16px',
    },
    footer: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      left: 0,
      padding: '1rem',
      textAlign: 'center',
    }
  }))

const Dashboard = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();
  const { accounts } = useMsal();
  const [placementHistory, setPlacementHistory] = useState<IPlacementHistoryByUser[]>([]);
  const [notificationCount, setNotificationCount] = useState<INotificationCount[]>([]);
  const [DashboardAge, setDashboardAge] = useState<IDashboardAgeByUser[]>([]);
  const [Notification, setNotification] = useState<INotification[]>([]);

  const placementCount = notificationCount.map((c) => { return (c.daysSincePlaced) });
  const rollCount = notificationCount.map((c) => { return (c.remaining) });
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [ProgressBar, setShowProgressBar] = useState(true);
  const [dashboardData, setDasboardData] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);
  const [chartopen, setchartOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [NCount, setNCount] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  });

  const updateHeight = () => {
    setHeight(window.innerHeight);
  };

  const handleClosechart = () => {
    setchartOpen(false);
  };

  useEffect(() => {
    if (state?.userAccessContext?.clientCodes.join(",") === undefined || state?.userAccessContext?.id === undefined) {
      setInterval(() => {
        setDasboardData(true);
      }, 1000);
    }
    (async () => {
      if (dashboardData === true && state?.userAccessContext?.id != undefined) {
        setShowProgressBar(true);
        let request = { clients: state?.userAccessContext?.clientCodes.join(","), userId: state?.userAccessContext?.id };
        await usePost<INotificationCount[]>("Account/GetDashboardCount", request).then((getDashboardCount) => {
          setNotificationCount(getDashboardCount.data);
        }).finally(() => {
          setShowProgressBar(false);
        });
      } else {
        if (state?.userAccessContext?.id === undefined) {
          (async () => {
            let requestAccount = { "userName": accounts[0]?.username };
            await usePost<AccessContext>("User/GetUserInfoByEmail", requestAccount).then((userAcc) => {
              dispatch({ type: GlobalStateAction.SetPermissions, userAccessContext: userAcc.data });
            });
          })()
          setShowProgressBar(true);
          let request = { clients: state?.userAccessContext?.clientCodes.join(","), userId: state?.userAccessContext?.id };
          await usePost<INotificationCount[]>("Account/GetDashboardCount", request).then((getDashboardCount) => {
            setNotificationCount(getDashboardCount.data);
          }).finally(() => {
            setShowProgressBar(false);
          });
        } else {
          setShowProgressBar(true);
          let request = { clients: state?.userAccessContext?.clientCodes.join(","), userId: state?.userAccessContext?.id };
          await usePost<INotificationCount[]>("Account/GetDashboardCount", request).then((getDashboardCount) => {
            setNotificationCount(getDashboardCount.data);
          }).finally(() => {
            setShowProgressBar(false);
          });
        }
      }
    })().finally(() => {
      setShowProgressBar(false);
    })
  }, [dashboardData])

  useEffect(() => {
    if (state?.userAccessContext?.id === undefined) {
      setInterval(() => {
        setDasboardData(true);
      }, 1000);
    }
    (async () => {
      if (dashboardData === true && state?.userAccessContext?.id != undefined) {
        await useFetch<IDashboardAgeByUser[]>(`Account/DashBoardAgeByUser?UserId=${state?.userAccessContext?.id}`).then((r) => {
          setDashboardAge(r.data);
        });
      } else {
        if (state?.userAccessContext?.id === undefined) {
          (async () => {
            let requestAccount = { "userName": accounts[0]?.username };
            await usePost<AccessContext>("User/GetUserInfoByEmail", requestAccount).then(async (userAcc) => {
              dispatch({ type: GlobalStateAction.SetPermissions, userAccessContext: userAcc.data });
              await useFetch<IDashboardAgeByUser[]>(`Account/DashBoardAgeByUser?UserId=${userAcc.data?.id}`).then((r) => {
                setDashboardAge(r.data);
              });
            });
          })()
        } else {
          await useFetch<IDashboardAgeByUser[]>(`Account/DashBoardAgeByUser?UserId=${state?.userAccessContext?.id}`).then((r) => {
            setDashboardAge(r.data);
          });
        }
      }
    })().finally(() => {
      setShowProgressBar(false);
    })
  }, [dashboardData]);

  useEffect(() => {
    if (state?.userAccessContext?.id === undefined) {
      setInterval(() => {
        setDasboardData(true);
      }, 1000);
    }
    (async () => {
      if (dashboardData === true && state?.userAccessContext?.id != undefined) {
        await useFetch<IPlacementHistoryByUser[]>(`Account/PlacementHistoryByUser?UserId=${state?.userAccessContext?.id}`).then((r) => {
          setPlacementHistory(r.data);
        });
      } else {
        if (state?.userAccessContext?.id === undefined) {
          (async () => {
            let requestAccount = { "userName": accounts[0]?.username };
            await usePost<AccessContext>("User/GetUserInfoByEmail", requestAccount).then(async (userAcc) => {
              dispatch({ type: GlobalStateAction.SetPermissions, userAccessContext: userAcc.data });
              await useFetch<IPlacementHistoryByUser[]>(`Account/PlacementHistoryByUser?UserId=${userAcc.data?.id}`).then((r) => {
                setPlacementHistory(r.data);
              });
            });
          })()
        } else {
          await useFetch<IPlacementHistoryByUser[]>(`Account/PlacementHistoryByUser?UserId=${state?.userAccessContext?.id}`).then((r) => {
            setPlacementHistory(r.data);
          });
        }
      }
    })().finally(() => {
      setShowProgressBar(false);
    })
  }, [dashboardData]);

  useEffect(() => {
    if (state?.userAccessContext?.id === undefined) {
      setInterval(() => {
        setDasboardData(true);
      }, 1000);
    }
    (async () => {
      if (dashboardData === true) {
        let request = {
          "userId": state?.userAccessContext?.id,
          "clientId": state?.userAccessContext?.clientCodes?.join(',')
        }
        await usePost<any>("Account/NotificationCount", request).then((r) => {
          setNCount(r?.data[0]?.count);
        });
      } else {
        let request = {
          "userId": state?.userAccessContext?.id,
          "clientId": state?.userAccessContext?.clientCodes?.join(',')
        }
        await usePost<any>("Account/NotificationCount", request).then((r) => {
          setNCount(r?.data[0]?.count);
        });
      }
    })()
  }, [dashboardData]);

  useEffect(() => {
    (async () => {
      await useFetch<INotification[]>("DashboardNotification").then((NotificationData) => {
        setNotification(NotificationData.data);
      });
      setOpen(true);
    })()
  }, [])

  function handleDeleteNotification(rowIndex: number) {
    let notificationMessage = _.cloneDeep(Notification);
    notificationMessage = notificationMessage.filter((x) => x.id !== rowIndex);
    setNotification(notificationMessage);
  }

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <Grid xs={12} item className={classes.grid1}>
        {Notification.map((n => {
          return (
            <Collapse in={open}>
              <Alert serverity="info"
                className={classes.searchBox}
                action={
                  <IconButton
                    aria-label="Dashboard_Alert_Close_Button"
                    id="Dashboard_Alert_Close_Button"
                    color="inherit" size="small"
                    onClick={() => { handleDeleteNotification(n.id) }}>
                    <CloseIcon fontSize="inherit" style={{ fontSize: 25 }} />
                  </IconButton>
                }>
                <span style={{ fontSize: '12px' }}><b>{n.message}</b></span>
              </Alert>
            </Collapse>
          )
        }))
        }
      </Grid>

      <ul className="cards">
        <li>
          {state.userAccessContext?.unReadNotifications ?
            <Link className={classes.linkStyle} to="/notifications">
              <Card className={classes.card} >
                <CardHeader color="warning" stats={true} icon={true} className={classes.cardHeader1}>
                  <CardIcon color="warning">
                    <IconButton id="Notification_Button_Icon" component={Link} to="/notifications" aria-label="Notification_Button_Icon" style={{ color: "white" }} >
                      <NotificationsActiveIcon style={{ fontSize: 20 }} />
                    </IconButton>
                  </CardIcon>
                </CardHeader>
                <CardBody className={classes.cardBodyNotif} >
                  <Typography variant="h3" gutterBottom className={classes.notificationCount}>
                    <b>{NCount > 0 ? NCount : <Typography variant="h2" style={{ color: "black" }} gutterBottom > <b>0</b> </Typography>}</b>
                  </Typography>
                  <Typography variant="h6" className={classes.fonts} gutterBottom>
                    Total Unread Notifications
                  </Typography>
                </CardBody>
              </Card>
            </Link>
            : null}
        </li>
        <li>
          {
            state.userAccessContext?.flatFeeAcRem ?
              <Beginorder />
              : null
          }
        </li>
        <li>
          {
            state.userAccessContext?.acWaitingtoMove && state.userAccessContext?.rollAccounts ?
              <Card className={classes.card}>
                <Link className={classes.linkStyle} to="/roll">
                  <CardHeader color="danger" stats={true} icon={true} className={classes.cardHeader1}>
                    <CardIcon color="danger" style={{ backgroundColor: "pink" }}>
                      <IconButton id="Dashboard_RollButton" aria-label="Dashboard_RollButton" component={Link} to="/roll" style={{ color: "white" }} >
                        <TouchAppIcon fontSize="small" />
                      </IconButton>
                    </CardIcon>
                  </CardHeader>
                  <CardBody className={classes.cardBody}>
                    <Typography variant="h3" gutterBottom className={classes.notificationCount1}>
                      <b>{rollCount.length > 0 ? rollCount : <Typography variant="h3" style={{ color: "black" }} gutterBottom > <b>0</b> </Typography>}</b>
                    </Typography>
                    <Typography variant="h6" className={classes.fonts} gutterBottom>
                      Accounts Waiting to Move to Contingency Collections
                    </Typography>
                  </CardBody>
                </Link>
              </Card>
              : state.userAccessContext?.acWaitingtoMove ?
                <React.Fragment>

                  <Card className={classes.card}>
                    <CardHeader color="danger" stats={true} icon={true} className={classes.cardHeader1}>
                      <CardIcon color="danger" style={{ backgroundColor: "pink" }}>
                        <IconButton id="Dashboard_RollButton" disabled={true} aria-label="Dashboard_RollButton" style={{ color: "white" }} >
                          <TouchAppIcon fontSize="small" />
                        </IconButton>
                      </CardIcon>
                    </CardHeader>
                    <CardBody className={classes.cardBody}>
                      <Typography variant="h3" gutterBottom className={classes.notificationCount} >
                        <b>{rollCount.length > 0 ? rollCount : <Typography variant="h3" gutterBottom > <b>0</b> </Typography>}</b>
                      </Typography>
                      <Typography variant="h6" className={classes.fonts} gutterBottom>
                        Accounts Waiting to Move to Contingency Collections
                      </Typography>
                    </CardBody>
                  </Card>
                </React.Fragment>
                : null
          }
        </li>
        <li>
          {
            state.userAccessContext?.daysSincePlacmentofAc ?
              state.userAccessContext?.placeAccounts ?
                <Link className={classes.linkStyle} to="/placement" >
                  <Card className={classes.card}>
                    <CardHeader color="info" stats={true} icon={true} className={classes.cardHeader2}>
                      <CardIcon color="info">
                        <IconButton id="Dashboard_PlacementButton" component={Link} to="/placement" aria-label="Dashboard_PlacementButton" style={{ color: "white" }} >
                          <DateRangeIcon fontSize="small" />
                        </IconButton>
                      </CardIcon>
                    </CardHeader>
                    <CardBody className={classes.cardBody}>
                      <Typography variant="h3" gutterBottom className={classes.notificationCount}>
                        <b>{placementCount.length > 0 ? placementCount : <Typography variant="h3" style={{ color: "black" }} gutterBottom ><b>0</b></Typography>}</b>
                      </Typography>
                      <Typography variant="h6" className={classes.fonts} gutterBottom>
                        Days Since Your Last Placement of Accounts
                      </Typography>
                    </CardBody>
                  </Card>
                </Link>
                :
                <Card className={classes.card}>
                  <CardHeader color="info" stats={true} icon={true} className={classes.cardHeader2}>
                    <CardIcon color="info">
                      <IconButton id="Dashboard_PlacementButton" aria-label="Dashboard_PlacementButton" style={{ color: "white" }} >
                        <DateRangeIcon fontSize="small" />
                      </IconButton>
                    </CardIcon>
                  </CardHeader>
                  <CardBody className={classes.cardBody}>
                    <Typography variant="h3" gutterBottom className={classes.notificationCount}>
                      <b>{placementCount.length > 0 ? placementCount : <Typography variant="h3" style={{ color: "black" }} gutterBottom ><b>0</b></Typography>}</b>
                    </Typography>
                    <Typography variant="h6" className={classes.fonts} gutterBottom>
                      Days Since Your Last Placement of Accounts
                    </Typography>
                  </CardBody>
                </Card>

              : null
          }
        </li>
      </ul>
      <ul className="cards">
        <li>
          {state.userAccessContext?.placementHistoryofMonthAc ?
            <BarChart chartData={placementHistory} loading={ProgressBar} resize={height} />
            : null
          }
        </li>
        <li>
          {state.userAccessContext?.ageImpactofonAcResolution ?
            <LineChart chartData={DashboardAge} loading={ProgressBar} resize={height} />
            : null
          }
        </li>
      </ul>

      <div className={classes.footer}>
        <Footer />
      </div>
      <div className={classes.stylefooter}>
        {/*  <LightTooltip title="Click here to Learn more about the impact of age on account resolution">
          <Fab aria-label="fab Icon" size="small" className={classes.fab} color="primary" onClick={handleClickChartOpen}>
            <img width="20px" height="20px" src={Learn} alt="Learn more about the impact of age on account resolution" />
          </Fab>
        </LightTooltip > */}
        <Dialog
          PaperProps={{ style: { borderRadius: 15 } }}
          fullScreen={fullScreen}
          open={chartopen}
          onClose={handleClosechart}
          aria-labelledby="responsive-dialog-title"
          maxWidth={'md'}
        >
          <DialogTitleHeader id="responsive-dialog-title" onClose={handleClosechart}>
            <Typography variant="h6" gutterBottom className={classes.dialogHeader}>
              Learn more about Impact of age on Account Resolution
            </Typography>
          </DialogTitleHeader>

          <DialogContent>
            <Typography variant="h6" style={{ textAlign: "center", fontWeight: 'bold' }}>
              Declining Recovery by Age of Account
            </Typography>
            <Typography variant="subtitle2" style={{ textAlign: "center" }}>
              As reported by the ACA
            </Typography>
            <img alt="Chart Infotmation" src={graph} className={classes.chartimg} />
            <DialogContentText style={{ color: "black", textAlign: "justify", padding: "8px" }}>
              The age at which your accounts are sent to collections is the single most important factor in the performance on your accounts.When your internal efforts begin to show a decline in effectiveness, this is the point when 3rd Party intervention is needed.Generally, 60-75 days past due is the "sweet spot" for accounts to be submitted for collections.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </React.Fragment >
  )
}

export default Dashboard