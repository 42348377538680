import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, ExpansionPanelSummary, IconButton, Snackbar, TableCell, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
// Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LockIcon from '@mui/icons-material/Lock';
import Warning from '../../../images/agentUser/deleteWarning.svg';
// Models
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { IClientDetails } from "../../../models/AgentTemplate/IClientTemplateDetails";
import { usePost } from '../../../utils/apiHelper';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './AG_TemplateCss';

const DeleteTemplate: React.FC<{ onDelete: () => void, templateRow: number, masterFlag: number }> = (props) => {
    const classes = useStyles();
    const { masterFlag } = props;
    const [open, setOpen] = useState(false);
    const [errorDialogBox, setErrorDialogBox] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [deleteTemplate, setDeleteTemplate] = useState(false);
    const [clientDetails, setClientDetails] = useState<IClientDetails[]>([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setErrorDialogBox(false);
    };
    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setDeleteTemplate(false);
    };

    const handleOk = () => {
        setOpen(false);
        setShowProgressBar(true);
        (async () => {
            let request = {
                "activityTemplateId": props?.templateRow
            }
            usePost<IClientDetails[]>("AgentUser/DeleteActivityTemplate", request).then((r) => {
                setClientDetails(r?.data);
                handleCheck(!r?.data?.length);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    };

    const handleCheck = (check: boolean) => {
        if (check) {
            props.onDelete();
            setDeleteTemplate(true);
        } else {
            setErrorDialogBox(true);
        }
    };

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={100} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <div>
                {masterFlag === 0 ?
                    <IconButton id="CPO_Delete_btn" aria-label="delete" onClick={handleClickOpen} style={{ padding: '2px' }}>
                        <DeleteForeverIcon className={classes.deleteIcon} />
                    </IconButton>
                    :
                    <IconButton id="CPO_Delete_btn" aria-label="delete" onClick={handleClickOpen} style={{ padding: '2px' }} disabled>
                        <LockIcon className={classes.lockIcon} style={{ color: 'black' }} />
                    </IconButton>
                }
                <Dialog
                    PaperProps={{ style: { borderRadius: 10 } }}
                    open={open}
                    maxWidth={'xs'}
                    classes={{ paper: classes.deletePaperDialogue1 }}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    aria-labelledby="responsive-dialog-title" >
                    <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
                        <Typography variant="h6" gutterBottom className={classes.titleheader}>
                            DELETE TEMPLATE
                        </Typography>
                    </DialogTitleDelete>
                    <DialogContent>
                        <Typography variant="h6" gutterBottom>
                            Are you sure, you want to delete this template?
                        </Typography>
                    </DialogContent>
                    <DialogActions >
                        <Button id="CPO_OK_btn" size="small" color="primary" startIcon={<CheckCircleOutlineIcon />} onClick={handleOk} className={classes.yesButton} autoFocus>
                            Yes
                        </Button>
                        <Button id="CPO_Cancel_btn" size="small" color="primary" startIcon={<CancelIcon />} className={classes.noButton} autoFocus onClick={handleClose} variant="contained">
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
                {clientDetails?.length ?
                    <Dialog
                        open={errorDialogBox}
                        className={classes.dialogboxOverride}
                        PaperProps={{ style: { borderRadius: 15 } }}
                        maxWidth={'sm'} onClose={handleClose}
                        TransitionComponent={Transition}
                        aria-labelledby="responsive-dialog-title" >
                        <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
                            <Typography variant="h6" gutterBottom className={classes.titleheader}>
                                DELETE TEMPLATE
                            </Typography>
                        </DialogTitleDelete>
                        <DialogContent style={{ textAlign: 'center' }}>
                            <DialogTitle className={classes.titleHeaderOverride}>
                                <img src={Warning} alt="Block_Email" className={classes.warningIcon} />
                            </DialogTitle>
                            <Typography variant="h6" className={classes.textfield1}>
                                Are you sure want to Delete this template that affects <b>{clientDetails.length}</b> clients?
                            </Typography>
                        </DialogContent>
                        <Box display="flex" alignItems="center" justifyContent="center" className={classes.dialogaction}>
                            <Button id="IM_ok_btn" size="small" startIcon={<CheckCircleIcon />} variant="contained" className={classes.yesButton} onClick={() => { setErrorDialogBox(false); }} color="primary" autoFocus>
                                Yes
                            </Button>
                            <Button id="IM_Cancel_Btn" size="small" startIcon={<CancelIcon />} variant="contained" autoFocus className={classes.noButton} onClick={() => { setErrorDialogBox(false); }} color="primary">
                                No
                            </Button>
                        </Box>
                    </Dialog>
                    : null
                }
            </div>
            <Snackbar className="snackBarStyle" open={deleteTemplate} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    Template Deleted Successfully!
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default DeleteTemplate