import { Backdrop, Button, Dialog, DialogActions, DialogContent, Snackbar, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import { useStyles } from './ProspectUserCss';
// Icons       
import CancelIcon from '@material-ui/icons/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { PuffLoader } from 'react-spinners';
import * as constant from '../../constants/Constant';
import { IProspectUsersDetails } from '../../models/accesscontrol/ProspectUsersDetails';
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';

const ClientSetup: React.FC<{ userRow: IProspectUsersDetails, onUpdate: () => void }> = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const preventBackSlash = "Backslash";
    const { userRow, onUpdate } = props;
    const [open, setOpen] = React.useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [Emailerror, setEmailError] = useState("");
    const [EmailCCerror, setEmailCCError] = useState("");
    const [Description, setDescription] = useState("");
    const [isDisableEmail, setIsDisableEmail] = useState(false);
    const [isClientSetup, setIsClientSetup] = useState(false);
    const [localState, setLocalState] = useState<string>("clientservices@armsolutions.com");
    const [CCEmail, setCCEmail] = useState<string>("");
    const { state } = useGlobalState();
    const UserId = state.userAccessContext?.id;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setShowProgressBar(false);
        setEmailError("");
        setDescription("");
        setEmailCCError("");
        setCCEmail("");
    };

    const handleClickSetupClient = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "prospectUserId": userRow?.prospectUserId,
                "isClientOnboard": false,
                "isClientSetup": true,// Set Client Setup flag is Verified
                "userId": UserId,
                "message": Description,
                "emailSentTo": localState
            }
            await usePost<any>("User/VerifyOnboardClientSetup", request).then((r) => {
                if (r?.status === 200 || r?.status === 202) {
                    // Send the email when dataOps confirmed 
                    handleClickClientSetupConformation();
                }
                setIsClientSetup(true);
            }).finally(() => {
                setShowProgressBar(false);
                onUpdate();
            });
        })().finally(() => {
            setOpen(false);
        })
    }

    const handleClickClientSetupConformation = () => {
        //Email will trigger when send a correction to user
        let sendEmail = {
            "userName": localState,
            "clientCode": Description,
            "ccEmail": CCEmail,
            "organizationName": userRow?.organizationName
        }
        usePost<any>("SendClientSetupConformationEmail", sendEmail).finally(() => {
            setLocalState("clientservices@armsolutions.com");
            setEmailCCError("");
            setCCEmail("");
        });
        setOpen(false);
    }

    const handleClickSetupClientNagative = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "prospectUserId": userRow?.prospectUserId,
                "isClientOnboard": false,
                "isClientSetup": false,
                "userId": UserId,
                "message": Description,
                "emailSentTo": localState
            }
            await usePost<any>("User/VerifyOnboardClientSetup", request).then((r) => {
                if (r?.status === 200 || r?.status === 202) {
                    handleClickClientSetupComments();
                }
                setIsClientSetup(true);
            }).finally(() => {
                setShowProgressBar(false);
                onUpdate();
            });
        })().finally(() => {
            setOpen(false);
        })
    }

    const handleClickClientSetupComments = () => {
        //Email will trigger when send a correction to user
        let sendEmail = {
            "organizationName": userRow?.organizationName,
            "userName": localState,
            "ccEmail": CCEmail,
            "description": Description
        }
        usePost<any>("SendClientSetupCommentsEmail", sendEmail).finally(() => {
            setLocalState("clientservices@armsolutions.com");
            setEmailCCError("");
            setCCEmail("");
        });
        setOpen(false);
    }

    const preventSpecialCharacters = (e) => {
        if (constant.preventSpecialKeys.includes(e.key)) {
            e.preventDefault();
        } else if (e.code === preventBackSlash) {
            e.preventDefault();
        }
    };

    function handleOnBlur(event) {
        var emails = event.target.value;
        emails = emails?.split(",");
        for (var i = 0; i < emails.length; i++) {
            if (emails[i] == "" || !constant.regexValidEmail.test(emails[i])) {
                setEmailError("Formatting error:make sure all email addresses are correct and separated only by commas.");
                setIsDisableEmail(true);
                break;
            }
            else {
                setEmailError('');
                setIsDisableEmail(false);
            }
        }
    }

    function handleOnBlurccEmail(event) {
        var emails = event.target.value;
        emails = emails?.split(",");
        for (var i = 0; i < emails.length; i++) {
            if (emails[i] == "" || !constant.regexValidEmail.test(emails[i])) {
                setEmailCCError("Formatting error:make sure all email addresses are correct and separated only by commas.");
                setIsDisableEmail(true);
                break;
            }
            else {
                setEmailCCError('');
                setIsDisableEmail(false);
            }
        }
    }


    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsClientSetup(false);
    };

    return (
        <div>
            {userRow?.isNewOrginization ?
                <Button variant='contained' size="small" disabled={userRow?.isPermissionUpdated || !userRow?.isActive}
                    color='primary' startIcon={userRow?.isClientSetup ? <DoneAllIcon /> : <HowToRegIcon />} className={userRow?.isClientSetup ? classes.approvedBtn : classes.notApprovedBtn} onClick={() => { handleClickOpen() }}>
                    {userRow?.isClientSetup ?
                        <span>SETUP CLIENT</span>
                        :
                        <span>CLIENT SETUP</span>
                    }
                </Button>
                :
                null
            }

            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>

            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" className={classes.titleheader}>
                        CLIENT SETUP IN CSS-IMPACT CONFORMATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    {userRow?.isDocumentUploaded ?
                        <>
                            <Typography variant="h6" className={classes.textfield} gutterBottom>
                                Do you confirm onboarding this client with CSS-IMPACT?
                            </Typography>
                            <Typography variant="body2" className={`${classes.textfield} ${classes.onboardClient}`} gutterBottom >
                                <span><b>Note : </b></span> The email addressee will recieve this communication.
                            </Typography>
                            <TextField id="Email" label="Email" value={localState}
                                autoComplete="off"
                                size="small"
                                onChange={(e) => { handleOnBlur(e); setLocalState(e.target.value); }}
                                onKeyPress={preventSpecialCharacters}
                                placeholder="example@gmail.com , example2@gmail.com" fullWidth
                                variant="outlined" style={{ marginTop: "10px" }}
                                InputLabelProps={{
                                    shrink: true,
                                }} required helperText={Emailerror} error={!!Emailerror}
                                inputProps={{ maxlength: 100 }}
                            />
                             <TextField id="CC" label="CC Email"
                                value={CCEmail}
                                autoComplete="off"
                                size="small"
                                onChange={(e) => { handleOnBlurccEmail(e); setCCEmail(e.target.value); }}
                                onKeyPress={preventSpecialCharacters}
                                placeholder="example@gmail.com , example2@gmail.com" fullWidth
                                variant="outlined" style={{ marginTop: "15px" }}
                                InputLabelProps={{
                                    shrink: true,
                                }} required helperText={EmailCCerror} error={!!EmailCCerror}
                                inputProps={{ maxlength: 100 }}
                            />
                            <TextField
                                id="outlined-multiline-static"
                                label="Enter the Description"
                                multiline fullWidth rows={6}
                                style={{ marginTop: '15px' }}
                                variant="outlined"
                                onChange={(e) => { setDescription(e.target.value); }}
                            />
                        </>
                        :
                        <Typography variant="h6" className={classes.textfield} gutterBottom>
                            Contract File(s) have not been uploaded for this client, Please upload and confirm.
                        </Typography>
                    }
                </DialogContent>
                <DialogActions>
                    {userRow?.isDocumentUploaded ?
                        <>
                            <Button id="DU_ok_btn" size="small" variant="contained" startIcon={<DoneAllIcon />} disabled={((!localState || !Description) || isDisableEmail)} onClick={() => { handleClickSetupClientNagative(); }} className={classes.yesButton}>
                                SEND / COMMENTS
                            </Button>
                            <Button id="DU_ok_btn" size="small" variant="contained" startIcon={<DoneAllIcon />} disabled={((!localState || !Description) || isDisableEmail)} onClick={() => { handleClickSetupClient(); }} className={classes.yesButton}>
                                CONFIRM / SEND
                            </Button>
                            <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
                                Close
                            </Button>
                        </>
                        :
                        <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
                            Close
                        </Button>
                    }
                </DialogActions>
            </Dialog>

            <Snackbar className="snackBarStyle" open={isClientSetup} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Client Onboarded Successfully!
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ClientSetup