import {
    AppBar, Backdrop, Button, Checkbox, Dialog, DialogActions, DialogContent, ExpansionPanel, ExpansionPanelDetails, FormControlLabel, Grid, Toolbar, Typography
} from '@material-ui/core';
import React, { useState } from 'react';
// Icons
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import { ARMLogo } from '../../../constants/Constant';
import ViewListIcon from '@mui/icons-material/ViewList';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// Models
import { IconButton } from '@mui/material';
import { usePost } from '../../../utils/apiHelper';
import { IAgentDebtor } from '../../../models/AgentInquiry/IAgentDebtor';
import { Category, IAgentActivityListCount, IAgentUpdatedEmail } from '../../../models/AgentInquiry/IAgentActivityListCount';
// Componant
import _ from 'lodash';
import { PuffLoader } from 'react-spinners';
import { useStyles } from './AgentActivityCss';
import SaveIcon from '@material-ui/icons/Save';
import * as constant from '../../../constants/Constant';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useGlobalState } from '../../../store/GlobalStore';
import AgentTrackInteraction from './AgentTrackInteraction';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { IValidationError } from '../../../models/common/IValidationError';
import { InsertTransactionLog } from '../../TransactionLogs/InsertTransactionLog';
import CompactExpansionPanelSummary from '../../GlobalStyles/ExpansionPanelSummary';
import { IActivityContract } from '../../../models/AgentTemplate/IActivityContract';
import { IInsertTransactionLog } from '../../../models/UpdateHistory/GetTransactionOptions';
import { IActivityControlResult, IAgentActivityPermission } from '../../../models/AgentInquiry/IAgentActivityPermission';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';

const ManageActivity: React.FC<{ debtor: IAgentDebtor, isReadOnly: boolean, activityCount: IAgentActivityListCount[], updatedEmail: IAgentUpdatedEmail[], onRefresh: () => void }> = (props) => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const { debtor, isReadOnly } = props;
    const { activityCount, updatedEmail } = props;
    const [open, setOpen] = useState<boolean>(false);
    const [expandedGroup, setExpandedGroup] = useState(false);
    const [ProgressBar, setshowProgressBar] = useState(false);
    const [expandedPanel, setExpandedPanel] = useState('panel_0');
    const [NewActivityId, setNewActivityId] = useState<number>(0);
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const [insertLog, setInsertLog] = useState<IInsertTransactionLog[]>([]);
    const [activityFieldValue, setActivityFieldValue] = useState<string>("");
    const [saveActivity, setSaveActivity] = useState<IActivityContract[]>([]);
    const [validationErrors, setValidationErrors] = useState<IValidationError[]>([]);
    const [listEmailActivityJson, setListEmailActivityJson] = useState<Category[]>([]);
    const [listAdminActivityJson, setListAdminActivityJson] = useState<Category[]>([]);
    const [ActivityControlList, setActivityControlList] = useState<IActivityControlResult[]>([]);
    const [listCaseCreatedActivityJson, setListCaseCreatedActivityJson] = useState<Category[]>([]);

    const [localState, setlocalState] = useState({
        isEmailChecked: false,
        isAdminChecked: false,
        isCaseCreatedChecked: false,
    });

    const handleClose = () => {
        let newValues = {
            isEmailChecked: false,
            isAdminChecked: false,
            isCaseCreatedChecked: false,
        }
        setOpen(false);
        setSaveActivity([]);
        setlocalState(newValues);
        setConfirmationDialog(false);
        setActivityFieldValue("");
        setNewActivityId(0);
        props?.onRefresh();
    };

    const handleOpen = () => {
        setOpen(true);
        getActivities();
        getActivityDefaultMethod();
    }

    const getActivityDefaultMethod = () => {
        (async () => {
            let request = {
                "clientCode": debtor?.dbT_CLIENT,
                "phaseCode": debtor?.dbT_COMPANY
            }
            await usePost<any>("AgentUser/GetDefaultCommunicationMethod", request).then((r) => {
                r?.data?.defaultMethod === 1 || r?.data?.defaultMethod === 0 ?
                    setlocalState({
                        ...localState,
                        isEmailChecked: true
                    })
                    : r?.data?.defaultMethod === 2 ?
                        setlocalState({
                            ...localState,
                            isAdminChecked: true
                        })
                        : r?.data?.defaultMethod === 3 ?
                            setlocalState({
                                ...localState,
                                isCaseCreatedChecked: true
                            })
                            : null

            }).finally(() => {
                setOpen(true);
            });
        })().finally(() => {
            setshowProgressBar(false);
        })
    }

    const getActivities = () => {
        setshowProgressBar(true);
        (async () => {
            let request = {
                "agentUserId": state?.AgentUserAccessContext?.agentUserId,
                "clientCode": debtor?.dbT_CLIENT,
                "phaseCode": debtor?.dbT_COMPANY
            }
            await usePost<{ agentActivityPermissions: IAgentActivityPermission[], activityControlResult: IActivityControlResult[], emailActivityList: string, adminActivityList: string, caseCreatedActivityList: string }>("AgentUser/GetAgentUserActivity", request).then((r) => {
                setListEmailActivityJson(JSON.parse(r?.data?.emailActivityList));
                setListAdminActivityJson(JSON.parse(r?.data?.adminActivityList));
                setListCaseCreatedActivityJson(JSON.parse(r?.data?.caseCreatedActivityList));
                setActivityControlList(r?.data?.activityControlResult);
            }).finally(() => {
                setOpen(true);
            });
        })()
    }

    // Callback function to receive the value from the child
    const handleGetSelectedActivity = (e) => {
        let activityList = _.cloneDeep(saveActivity);
        let activityLog = _.cloneDeep(insertLog);
        if (saveActivity.findIndex(x => x.activityFieldId == e?.target?.value) <= -1) {
            let activityDetails = { activityFieldId: e?.target?.value };
            let logDetails = { operationName: e?.target?.name, operationText: e?.target?.name }
            activityLog.push(logDetails);
            activityList.push(activityDetails);
            setSaveActivity(activityList);
            setInsertLog(activityLog);
        }
        else {
            if (saveActivity.findIndex(x => x.activityFieldId == e?.target?.value) > -1) {
                let activityDetails = saveActivity.filter(x => x.activityFieldId !== e?.target?.value);
                let logDetails = insertLog.filter(x => x.operationName !== e?.target?.name);
                setSaveActivity(activityDetails);
                setInsertLog(logDetails);
            };
        }
    };

    const handleValidateControls = (event, activityControlResult) => {
        let controlValue = event.target.value;
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        for (var i = 0; i < activityControlResult?.length; i++) {
            if (activityControlResult[i]?.controlId === Number(event.target.id) && activityControlResult[i]?.regex !== null) {
                var regexValue = new RegExp(activityControlResult[i]?.regex);
                if (regexValue?.test(controlValue)) {
                    if (currentValidationerrorList.findIndex(x => x.errorNumber == event.target.id) > -1) {
                        let errorList = currentValidationerrorList.filter(x => x.errorNumber !== event.target.id);
                        setValidationErrors(errorList);
                    };
                } else if (controlValue === "") {
                    if (activityControlResult[i]?.label?.includes(event.target.name) && activityControlResult[i]?.isRequired) {
                        activityControlResult.map((x, index) => {
                            if (!regexValue?.test(controlValue)) {
                                let regexValidation: IValidationError = {
                                    errorNumber: event.target.id, field: event.target.name, errorMessage: " " + event.target.name + " is Required"
                                }; if (currentValidationerrorList.findIndex(x => x.errorNumber == event.target.id) <= -1) {
                                    currentValidationerrorList.push(regexValidation);
                                    setValidationErrors(currentValidationerrorList);
                                };
                            }
                        });
                    }
                } else {
                    if (activityControlResult[i]?.label?.includes(event.target.name)) {
                        activityControlResult.map((x, index) => {
                            if (!regexValue?.test(controlValue)) {
                                let regexValidation: IValidationError = {
                                    errorNumber: event.target.id, field: event.target.name, errorMessage: "Please Enter valid " + event.target.name + " "
                                }; if (currentValidationerrorList.findIndex(x => x.errorNumber == event.target.id) <= -1) {
                                    currentValidationerrorList.push(regexValidation);
                                    setValidationErrors(currentValidationerrorList);
                                };
                            }
                        });
                    }
                }
            }
        }
    }

    const handleTextChange = (event, fieldId, activityControlResult) => {
        setNewActivityId(fieldId);
        const regex = /(["\\])|(\b)|(\f)|(\n)|(\r)|(\t)/g;
        // Replace matched characters with a space
        const finalValue = event.target.value?.trim()?.replace(regex, '');
        if (validationErrors?.length) {
            for (var i = 0; i < activityControlResult?.length; i++) {
                validationErrors?.map((a) => {
                    if (activityControlResult[i]?.controlId != a.errorNumber) {
                        let newValue = activityFieldValue + 'μ' + fieldId + "α" + 'μ' + event.target.id + 'α' + event.target.name + 'α' + finalValue;
                        setActivityFieldValue(newValue);// set only when the Control without Error
                    }
                })
            }
        } else {
            let newValue = activityFieldValue + 'μ' + fieldId + "α" + 'μ' + event.target.id + 'α' + event.target.name + 'α' + finalValue;
            setActivityFieldValue(newValue);// only set when successful
        }
    };

    const handleDropDownChange = (event, fieldId) => {
        setNewActivityId(fieldId);
        let dropDownValue = event.target.value?.includes(constant.dorpDownSelectedValue) ? "" : event?.target.value?.trim();
        let newValue = activityFieldValue + 'μ' + fieldId + "α" + 'μ' + event.target.id + 'α' + event.target.name + 'α' + dropDownValue;
        setActivityFieldValue(newValue);
    };

    const InsertEntry = () => {
        setshowProgressBar(true);
        (async () => {
            let request = {
                "dbt_No": debtor?.dbT_NO,
                "createdBy": state?.AgentUserAccessContext?.agentUserId,
                "clientCode": debtor?.dbT_CLIENT,
                "phaseCode": debtor?.dbT_COMPANY,
                "createdDate": new Date(),
                "activityFieldValue": activityFieldValue,
                "activityIdValue": NewActivityId,
                "activityMapingDetail": saveActivity
            }
            await usePost("AgentUser/InsertActivityNotes", request);
            handleClose();
            props.onRefresh();
            InsertLog();
        })().finally(() => {
            setSaveActivity([]);
            setshowProgressBar(false);
        })
    }

    const InsertLog = () => {
        let feedOperation = [];
        insertLog?.forEach((a) => {
            let operations: IInsertTransactionLog = {
                operationName: a?.operationName, operationText: a?.operationText
            };
            feedOperation.push(operations);
        })
        InsertTransactionLog(state?.AgentUserAccessContext?.agentUserId, debtor?.dbT_NO, feedOperation, true);
        setInsertLog([]);
    }

    const handleCheckboxChange = (e) => {
        let name = e.target.name;
        if (saveActivity?.length) {
            setConfirmationDialog(true);
        } else {
            const updatedStates = {
                ...localState,
                [name]: e.target.checked,
            };

            if (e.target.checked) {
                // If a checkbox is checked, uncheck the others
                for (const key in updatedStates) {
                    if (key !== name) {
                        updatedStates[key] = false;
                    }
                }
            }
            setlocalState(updatedStates);
        }
    };

    const handleDialogClose = () => {
        setConfirmationDialog(false);
    }

    return (
        <React.Fragment>
            <Button id="Inquiry_closeAccount" variant="contained" onClick={() => { handleOpen() }} disabled={(isReadOnly) ? true : false}
                className={`${classes.button} ${classes.buttoncloseSpacing}`}
                startIcon={<ViewListIcon />} color="primary" size="small">
                ACTIVITIES
            </Button>

            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
                <AppBar position="fixed" className={classes.appBar} >
                    <Toolbar className={classes.toolbarStyle}>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" >
                            <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            <b>AGENT ACTIVITIES</b>
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={() => handleClose()} aria-label="close" style={{ padding: '5px' }}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent style={{ padding: '0px' }}>
                    <Grid container spacing={0} style={{ padding: '8px 16px' }}>
                        <Grid container spacing={1} style={{ padding: '0px 10px' }}>
                            {listEmailActivityJson?.length ?
                                <Grid item xs={12} md={4}>
                                    <FormControlLabel onChange={(e) => { handleCheckboxChange(e); }} style={{ display: 'flex', justifyContent: 'center' }}
                                        control={<Checkbox size="small" name="isEmailChecked" checked={localState?.isEmailChecked} />} label="Email"
                                    />
                                    <ExpansionPanel disabled={!localState?.isEmailChecked} className={classes.controlWidth1} defaultExpanded >
                                        <CompactExpansionPanelSummary onClick={(e) => { expandedGroup ? setExpandedGroup(false) : setExpandedGroup(true) }}
                                            expandIcon={<ExpandMoreIcon className={classes.expandicon} />} >
                                            <Typography align="left" variant="subtitle1" className={`${expandedPanel == 'panel_1' ? classes.TextNormal : classes.TextNormal1}`} >
                                                Email
                                            </Typography>
                                        </CompactExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.ContentStyle} style={{ padding: "0px" }}>
                                            <AgentTrackInteraction
                                                data={listEmailActivityJson}
                                                debtor={debtor}
                                                activityCount={activityCount}
                                                ActivityControlList={ActivityControlList}
                                                handleSaveSelectedActivities={handleGetSelectedActivity}
                                                handleChangeTextValues={handleTextChange}
                                                handleDropDownChanged={handleDropDownChange}
                                                handleValidateControl={handleValidateControls}
                                                isCommunicationMethodChecked={localState?.isEmailChecked}
                                                level={0}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                                : null
                            }
                            {listAdminActivityJson?.length ?
                                <Grid item xs={12} md={4}>
                                    <FormControlLabel onChange={(e) => { handleCheckboxChange(e); }} style={{ display: 'flex', justifyContent: 'center' }}
                                        control={<Checkbox size="small" name="isAdminChecked" checked={localState?.isAdminChecked} />} label="Admin"
                                    />
                                    <ExpansionPanel disabled={!localState?.isAdminChecked} className={classes.controlWidth1} defaultExpanded >
                                        <CompactExpansionPanelSummary onClick={(e) => { expandedGroup ? setExpandedGroup(false) : setExpandedGroup(true) }}
                                            expandIcon={<ExpandMoreIcon className={classes.expandicon} />} >
                                            <Typography align="left" variant="subtitle1" className={`${expandedPanel == 'panel_1' ? classes.TextNormal : classes.TextNormal1}`} >
                                                Admin
                                            </Typography>
                                        </CompactExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.ContentStyle} style={{ padding: "0px" }}>
                                            <AgentTrackInteraction
                                                data={listAdminActivityJson}
                                                debtor={debtor}
                                                activityCount={activityCount}
                                                ActivityControlList={ActivityControlList}
                                                handleSaveSelectedActivities={handleGetSelectedActivity}
                                                handleChangeTextValues={handleTextChange}
                                                handleDropDownChanged={handleDropDownChange}
                                                handleValidateControl={handleValidateControls}
                                                isCommunicationMethodChecked={localState?.isAdminChecked}
                                                level={0}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                                : null
                            }
                            {listCaseCreatedActivityJson?.length ?
                                <Grid item xs={12} md={4}>
                                    <FormControlLabel onChange={(e) => { handleCheckboxChange(e); }} style={{ display: 'flex', justifyContent: 'center' }}
                                        control={<Checkbox size="small" name="isCaseCreatedChecked" checked={localState?.isCaseCreatedChecked} />} label="Escalation"
                                    />
                                    <ExpansionPanel disabled={!localState?.isCaseCreatedChecked} className={classes.controlWidth1} defaultExpanded >
                                        <CompactExpansionPanelSummary onClick={(e) => { expandedGroup ? setExpandedGroup(false) : setExpandedGroup(true) }}
                                            expandIcon={<ExpandMoreIcon className={classes.expandicon} />} >
                                            <Typography align="left" variant="subtitle1" className={`${expandedPanel == 'panel_1' ? classes.TextNormal : classes.TextNormal1}`} >
                                                Escalation
                                            </Typography>
                                        </CompactExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.ContentStyle} style={{ padding: "0px" }}>
                                            <AgentTrackInteraction
                                                data={listCaseCreatedActivityJson}
                                                debtor={debtor}
                                                activityCount={activityCount}
                                                ActivityControlList={ActivityControlList}
                                                handleSaveSelectedActivities={handleGetSelectedActivity}
                                                handleChangeTextValues={handleTextChange}
                                                handleDropDownChanged={handleDropDownChange}
                                                handleValidateControl={handleValidateControls}
                                                isCommunicationMethodChecked={localState?.isCaseCreatedChecked}
                                                level={0}
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Grid>
                                : null
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ borderTop: '1px solid gray' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={6} >
                            {updatedEmail?.map((r) => {
                                return (
                                    <Typography className={classes.modifiedStyle}><i>Last modified by<b> &nbsp;{r?.emailAddress}</b></i></Typography>
                                )
                            })}
                        </Grid>
                        <Grid item xs={6} >
                            <Button id="I_CA_Close" variant="outlined" className={classes.cancelButton}
                                size="small" startIcon={<CancelIcon />} onClick={() => { handleClose(); }}>
                                Close
                            </Button>
                            <Button id="I_CA_Confirm" variant="contained" size="small" className={classes.confirmButton}
                                disabled={!saveActivity?.length || isReadOnly || !!validationErrors?.length} startIcon={<CheckCircleIcon />} onClick={() => { InsertEntry(); }}>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog >

            <Dialog open={confirmationDialog} onClose={handleDialogClose} TransitionComponent={Transition}
                classes={{ paper: classes.deletePaperDialogue }}
                Faria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }}>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleDialogClose}>
                    <Typography variant="h6" gutterBottom className={classes.HeaderStyle}>
                        Activity Details
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Typography variant="h6" gutterBottom className={classes.titleConfirm}>
                        The {localState?.isEmailChecked ? "Email" : localState?.isAdminChecked ? "Admin" : " Case Created / Escalation"} activities are captured would you like to save?
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.DialogBottom}>
                    <Button id="GM_Ok_btn" size="small" variant="contained" startIcon={<SaveIcon />}
                        onClick={(e) => { InsertEntry(); }} className={classes.confirmButton}>
                        Save
                    </Button>
                    <Button id="GM_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={() => { handleDialogClose(); }} color="primary" className={classes.noButton}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    )
}

export default ManageActivity