import moment from 'moment';

export function dateMasking(date: string): string{
    let DOB = moment(date).format("MM/DD/YYYY");
    let result = DOB?.match("[0-9]{2}([-/ .])[0-9]{2}[-/ .][0-9]{4}");
    let dateSplitted = result[0].split(result[1]);
    let month = dateSplitted[0];
    let year = dateSplitted[2]?.slice(-2);
    let DateWithMasking = DOB?.replace(month, "XX")?.replace(year, "XX");
    return DateWithMasking;
  }
