import {
    Backdrop, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, IconButton,
    makeStyles, Snackbar, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import * as moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from 'react';
// Icons
import ArchiveIcon from '@material-ui/icons/Archive';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
// Models
import { PuffLoader } from 'react-spinners';
import { IHideForAllUsers } from '../../../models/NotificationCenter/IHideForAllUsers';
import { INotificationCenter } from "../../../models/NotificationCenter/INotificationCenter";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';

const useStyles = makeStyles((theme) => ({
    topLeftBox: {
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    topRightBox: {
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    box: {
        display: "flex",
        marginTop: '-10px'
    },
    button: {
        borderRadius: 20,
        fontSize: 12
    },
    fontsizes: {
        fontSize: 15
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
    },
    textfiled: {
        textAlign: "left",
        color: 'red',
        fontSize: 12
    },
    textfiled1: {
        textAlign: "left",
        fontSize: 13
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}))

const NewPaymentFile: React.FC<{ NPF: INotificationCenter, onRefresh: () => void, onShowHidden: boolean }> = (props) => {
    const { NPF, onShowHidden } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [fileExists, setFileExists] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { state, dispatch } = useGlobalState();
    const [ProgressBar, setShowProgressBar] = useState(false);

    const { range } = extendMoment(moment);
    const start = moment.utc(NPF?.createdDate).add(30, "days");
    const end = Date.now();
    const timeRange = range(moment.utc(start), moment.utc(end));
    let DaysLeft = timeRange.diff('days');

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFileExists(false);
    };

    useEffect(() => {
        if (DaysLeft >= 0 && onShowHidden === false) {
            handleExpire();
        } else {
            null
        }
    }, [NPF.createdDate])

    const hidenotificationForAllUsers = () => {
        (async () => {
            let request = {
                "usernotificationid": NPF?.notificationid,
                "userid": state?.userAccessContext?.id,
                "debtorid": NPF?.extraData_1,
                "typeId": 7,
                "userRole": 1
            }
            await usePost<IHideForAllUsers>("HideForAllUsers", request);
        })()
    }

    const handleExpire = () => {
        (async () => {
            let request = {
                "notificationId": NPF?.notificationid,
                "userid": state?.userAccessContext?.id
            }
            await usePost<any>("ArchivePaymentFile", request);
            hidenotificationForAllUsers();
            props.onRefresh();
        })()
    }

    function getDownloadFileAsync() {
        (async () => {
            setShowProgressBar(true);
            dispatch({ type: GlobalStateAction.Busy });
            await useFetch<any>(`PaymentFileDownload?fileId=${NPF?.fileID}`).then((PaymentFileDownload) => {
                const arrayBuffer = base64ToArrayBuffer(PaymentFileDownload.data.fileContent);
                createAndDownloadBlobFile(arrayBuffer, PaymentFileDownload.data.fileName);
            }).catch(() => {
                setFileExists(true);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })();
    }

    function base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    function createAndDownloadBlobFile(body, filename) {
        const blob = new Blob([body]);
        const fileName = `${filename}`;
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    return (
        <React.Fragment>
            <Snackbar open={fileExists} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={() => { setFileExists(false) }} severity="error" className="alertStyle">
                    File does not exists!
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>

            <Box display="flex" p={0.3} >
                <Card style={{ minWidth: '100%', height: 75 }}>
                    <CardContent>
                        <Box display="flex" p={0} >
                            <Box p={0} width="84%" bgcolor="white" >
                                <Typography className={classes.textfiled1} variant="subtitle2" gutterBottom>
                                    {moment.utc(NPF.createdDate).format(state.GlobalUtils?.settingValue)} : New Payment File
                                    &nbsp; &nbsp;
                                    {NPF.extraData_2?.substring(NPF.extraData_2?.lastIndexOf("/") + 1, NPF.extraData_2?.length)}
                                </Typography>

                                {NPF.hiddenFlag ?
                                    <Typography variant="subtitle2" gutterBottom className={classes.textfiled}>
                                        This file is archived.
                                    </Typography>
                                    :
                                    <Typography variant="subtitle2" className={classes.textfiled} gutterBottom>
                                        This file will automatically archived in {DaysLeft >= 0 ? 0 : DaysLeft?.toString()?.split('-')} days.
                                    </Typography>
                                }
                            </Box>
                            <Box p={0} flexShrink={0} width="4%" bgcolor="white" style={{ marginTop: '7px' }}>
                                <IconButton color="primary" component="span"
                                    className={`${classes.topRightBox} ${classes.box} ${classes.button} ${classes.fontsizes}`}
                                    onClick={getDownloadFileAsync}>
                                    <LightTooltip title="Download">
                                        <CloudDownloadIcon style={{ fontSize: 28 }} />
                                    </LightTooltip>
                                </IconButton>
                            </Box>
                            <Box p={0} flexShrink={0} width="12%" bgcolor="white" style={{ marginTop: '8px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.button}
                                    startIcon={<ArchiveIcon />}
                                    onClick={handleClickOpen}
                                    disabled={NPF.hiddenFlag}>
                                    Archive Now
                                </Button>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>

            <Dialog TransitionComponent={Transition}
                PaperProps={{ style: { borderRadius: 10 } }}
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" className={classes.titleheader}>
                        NEW PAYMENT FILE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Typography variant="h6" style={{ color: "black" }} gutterBottom>
                        Are you sure, you want to Archive this File ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button id="ACK_ok_btn" variant="contained" size="small" onClick={(_) => { handleExpire(); handleClose(); }} style={{ background: "green", marginRight: "10px", borderRadius: 30 }} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button id="ACK_Cancel_Btn" variant="contained" size="small" autoFocus style={{ background: "red", marginRight: "10px", borderRadius: 30 }} onClick={handleClose} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

export default NewPaymentFile
