import React from 'react';
import {
    Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText,
    IconButton, makeStyles, Theme, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import { useDelete } from '../../../utils/apiHelper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { DialogTitleHeader } from '../../../components/GlobalStyles/DialogStyle';
import { Transition } from '../../../components/GlobalStyles/DialogBoxTransition';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        },
        yesbutton: {
            backgroundColor: 'green',
            borderRadius: '20px',
            color: 'white',
            '&:hover': {
                backgroundColor: 'green',
                color: 'white',
            }
        },
        nobutton: {
            background: "red",
            borderRadius: "20px",
            color: 'white',
            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            }
        }
    }),
);

const DeleteTemplate: React.FC<{ ResetAutoMap: () => void, GetMappingTemplate: () => void, mapped: number, templateId: number, validAutoMap: boolean, onDelete: () => void, globalTemplateId: number }> = (props) => {
    const theme = useTheme();
    const { templateId, globalTemplateId } = props;
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const [open, setOpen] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    async function DeleteMappingTemplate() {
        try {
            setOpen(false);
            dispatch({ type: GlobalStateAction.Busy });
            await useDelete(`ClientPlacement/DeleteMappingDetail?placement_map_id=${props.mapped}`);
            props.ResetAutoMap();
            props.GetMappingTemplate();
            props.onDelete();
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Idle });
            dispatch({ type: GlobalStateAction.Error, error: ex });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    }

    return (
        <React.Fragment>
            <IconButton aria-label="delete" onClick={() => { setOpen(true); }}
                disabled={!props.validAutoMap || templateId === globalTemplateId} >
                <DeleteForeverIcon fontSize="small" style={props.validAutoMap ? { color: "red", fontSize: 27 } : { color: "gray", fontSize: 27 }} />
            </IconButton>
            <Dialog TransitionComponent={Transition}
                PaperProps={{ style: { borderRadius: 10 } }}
                maxWidth={'sm'}
                fullScreen={fullScreen}
                open={open}
                onClose={() => { setOpen(false); }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={() => { setOpen(false); }}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        DELETE SAVE TEMPLATE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h6" gutterBottom style={{ color: 'black' }}>
                            Are you sure, you want to delete this mapping Template?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button size="small" id="IM_ok_btn" variant="contained" className={classes.yesbutton} onClick={() => { DeleteMappingTemplate(); }}
                        color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button size="small" id="IM_Cancel_Btn" variant="contained" autoFocus className={classes.nobutton} onClick={() => { setOpen(false); }} color="primary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default DeleteTemplate


