import { ExpansionPanelSummary, withStyles } from "@material-ui/core";

const summaryStyles = {
    root: {
        minHeight: 35,
        marginTop: 10,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5,
        border: "1px solid #215176",
        backgroundColor: "#215176",
        boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 1px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',
        "&$expanded": {
            minHeight: 30,
            color: "#fffff",
            borderTopRightRadius: 5,
            borderTopLeftRadius: 5,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            fontWeight: "bold",
            backgroundColor: "#215176",
        }
    },
    content: {
        margin: "4px 0",
        "&$expanded": {
            margin: "4px 0"
        }
    },
    expandIcon: {
        padding: 5
    },
    expanded: {}
};
const CompactExpansionPanelSummary = withStyles(summaryStyles)(
    ExpansionPanelSummary
);

export default CompactExpansionPanelSummary;