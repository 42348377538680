import { Checkbox, FormControl, FormControlLabel, ListItemText, Typography } from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { ListItemButton, ListItemIcon } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars';
import useStyles from './AgentUserCss';

interface Activity {
    ActivityMapId: number;
    ActivityName: string;
    ParentId: number;
    Activities?: Activity[];
    isEnable: boolean;
}

interface Props {
    data: Activity[];
    onSaveActivity: (event: any, checked: any, parentActivityId: number) => void;
    isReadOnly: any;
    currentTemplateList: any;
    allActivity: boolean;
}

const EditAgentUserTreeView: React.FC<Props> = (props) => {
    const classes = useStyles();
    const { data, currentTemplateList, isReadOnly, allActivity } = props;


    const handleChecked = (e, checked, parentActivityId) => {
        props?.onSaveActivity(e, checked, parentActivityId);
    };
    // activity.isEnable &&
    const renderTree = (activities: Activity[]) => {
        return activities?.map(activity => (
            <React.Fragment key={activity?.ActivityMapId}>
                <ListItemButton sx={{ pt: 0.5, pb: 0 }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                        {activity?.Activities ? (
                            activity?.ActivityMapId === activity?.ActivityMapId ? (
                                <IndeterminateCheckBoxOutlinedIcon fontSize="small" />
                            ) : (
                                <AddBoxOutlinedIcon fontSize="small" />
                            )
                        ) : (
                            <FormControl component="fieldset" >
                                <FormControlLabel
                                    labelPlacement="end"
                                    id="AG_emailreceived"
                                    checked={activity?.isEnable}
                                    value={activity?.ActivityMapId}
                                    disabled={currentTemplateList?.readOnly || !currentTemplateList || isReadOnly}
                                    label={<span className={classes.inputFontSize1}> {activity?.ActivityName} </span>}
                                    control={<Checkbox size="small" name={`${activity?.ActivityName}`} style={{ padding: '0px 11px' }} />}
                                    onChange={(e, selected) => { handleChecked(e, selected, activity?.ParentId); }}
                                // className={`${activity?.isEnable ? classes.permittedChecked : classes.permittedFiled}`}
                                />
                            </FormControl>
                        )}
                    </ListItemIcon>
                    <ListItemText>
                        {activity?.Activities ? (
                            <Typography variant="body2" style={{ fontSize: '14px', fontWeight: 'bold' }} >
                                {activity?.ActivityName}
                            </Typography>
                        ) : null}
                    </ListItemText>
                </ListItemButton>
                {activity?.Activities && <ul>{renderTree(activity?.Activities)}</ul>}
            </React.Fragment>
        ));
    };

    return (
        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.tableBodyTrack}>
            {renderTree(data)}
        </Scrollbars>
    );
};

export default EditAgentUserTreeView;
