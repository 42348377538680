import { makeStyles } from "@material-ui/core"


const useStylesTrustReport = makeStyles((theme) => ({
    div: {
        flexGrow: 1,
        padding: '16px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    maingrid: {
        backgroundColor: 'white',
        borderRadius: '10px',
        // border: '1px solid gray',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        filter: 'drop-shadow(0 -6mm 4mm gray))'
    },
    textStyle: {
        marginLeft: '5px',
        float: 'left',
        marginTop: '10px',
    },
    backButton: {
        borderRadius: "20px !important",
        fontSize: "12px !important",
        marginTop: '14px !important'
    },
    searchInput: {
        padding: '7px',
    },
    notchedOutline: {
        color: 'white !important',
        border: '0px !important'
    },
    outlinedInput: {
        fontSize: "13px !important",
        color: "black !important",
        '&$focused $notchedOutline': {
            color: 'white !important',
            border: '0px !important'
        },
    },
    tableBody: {
        maxHeight: 505,
        minHeight: 505,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 245px)',
            minHeight: 'calc( 100vh - 245px)',
        },
    },
    searchBox: {
        borderRadius: "5px",
        border: '2px solid #215176',
        height: '35px',
        textIndent: '10px',
        fontSize: 14,
        width: '100%',
        marginTop: '12px !important',
        paddingLeft: '10px',
        marginBottom: '5px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        '@media (max-width: 600px)': {
            marginBottom: '5px',
        },
    },
    IconSizeStyle: {
        height: '22px',
        width: '22px'
    },
    closeIconButton: {
        color: "#000 !important",
        fontSize: "22px !important",
    },
    TableCellHeadStyle: {
        background: "#007FFF !important",
        color: "white !important",
        fontSize: "12px !important",
        padding: '3px 0px 3px 10px !important'
    },
    TableCellBodyStyle: {
        fontSize: "13px !important",
        padding: '3px 0px 3px 10px !important'
    },
    boxBorder: {
        border: '1px solid #007fff',
        padding: '2px',
        borderRadius: '5px',
        width: '100%',
        // marginTop: '12px',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
    },
    textBoxStyle2: {
        fontSize: 10,
        '& .MuiChip-labelSmall': {
            fontSize: 10
        }
    },
    scrollMargin: {
        marginBottom: '0px',
        '& div:first-child': {
            marginBottom: '0px',
        },
        '& div:nth-child(3) div': {
            backgroundColor: '#2377e4 !important'
        },
        '& div:nth-child(3)': {
            bottom: '0px !important'
        },
    },
    valueContainer: {
        padding: '0px',
        "& > :not(:last-child)": {
            marginRight: '3px'
        },
        "& > *": {
            marginBottom: '3px'
        }
    },
    shareEmailContainer: {
        maxHeight: "calc( 100vh - 280px)",
        minHeight: "calc( 100vh - 280px)",
    },
    yesButton: {
        // borderRadius: 20,
        // fontSize: 12,
        // right: "15px",
    },
    noButton: {
        // borderRadius: 20,
        // right: "15px",
        // fontSize: 12,
    },
    NormalText: {
        color: 'black',
        fontSize: 12,
        marginLeft: '5px',
        float: 'left',
        marginTop: '10px',
    },
    TextHighlight: {
        backgroundColor: "yellow",
        fontSize: 12,
        marginLeft: '5px',
        float: 'left',
        marginTop: '10px',
    },
}))

export { useStylesTrustReport }