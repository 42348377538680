import {
  Box, CircularProgress, Backdrop, Button, Grid, makeStyles, Snackbar, TextField,
  Dialog, DialogActions, DialogContent, Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
//Icons
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
//Components
import Footer from '../../components/Footer/Footer';
import { DialogTitleHeader } from '../../components/GlobalStyles/DialogStyle';
//Constant
import * as constant from '../../constants/Constant';
//Models
import { useFetch, usePost } from '../../utils/apiHelper';
import { IClientDetails } from '../../models/client/IClientDetails';
import { IClientTypeCount } from '../../models/client/IClientTypeCount';
import { useGlobalState } from '../../store/GlobalStore';
import { IClientCodeConfirmation } from '../../models/client/IClientCodeConfirmation';
import { Transition } from '../../components/GlobalStyles/DialogBoxTransition';
import { ConfirmText } from '../../constants/Constant';


const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "1cm",
    alignItems: "center"
  },
  textfield: {
    width: "95%",
    backgroundColor: 'transparent',
    color: "white",
    fontSize: 13,
    fontWeight: 'bold',
    marginTop: '9px',
    borderRadius: '5px',
    border: 'none',
  },
  textfield1: {
    width: "95%",
    fontSize: 13,
    borderRadius: '5px',
    backgroundColor: 'white'
  },
  textfield2: {
    width: "100%",
    marginLeft: '12px',
    backgroundColor: 'white',
    borderRadius: '5px',
    fontSize: 13,
    marginTop: "5px"

  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  grid: {
    padding: '8px 10px'
  },
  secondGrid: {
    backgroundColor: '#ffffff',
    padding: '8px',
    marginTop: '5px',
    borderRadius: '5px',
    overflowY: 'auto',
    boxShadow: '0px 1px 2px 0px rgba(103, 128, 159, 1)',
    maxHeight: 550,
    minHeight: 550,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 195px)',
      minHeight: 'calc( 100vh - 195px)',
    }
  },
  div: {
    flexGrow: 1,
  },
  progressBar: {
    height: '6px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  yesbutton: {
    backgroundColor: 'green',
    borderRadius: '20px',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
      color: 'white',
    }
  },
  nobutton: {
    background: "red",
    borderRadius: "20px",
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
      color: 'white',
    }
  },
  titleheader: {
    fontWeight: 'bold',
    marginTop: '5px',
    color: 'white',
    fontSize: '18px'
  },
  title: {
    marginTop: '8px',
    float: 'left',
    marginLeft: '10px',
    fontSize: 28,
  },
  clientCode: {
    fontSize: 12
  },
  smallFont: {
    fontSize: 12
  },
  paper1: {
    border: "2px solid black",
    fontSize: 12
  },
  textStyle: {
    marginTop: '8px',
    marginLeft: '5px',
    float: 'left',
    fontSize: 21
  },
  dropDownStyle: {
    marginTop: '5px',
    width: '92%',
    borderRadius: 5,
    border: '2px solid #215176',
    backgroundColor: 'white',
    height: '40px',
    fontSize: 12,
    "& .MuiInputBase-root": {
      fontSize: '13px'
    }
  },
  outlinedInputStyle: {
    fontSize: 13,
    color: "black",
    border: 'none',
    '&$focused $notchedOutline': {
      color: 'white',
      border: 'none'
    },
  },
  outlinedInput: {
    fontSize: 13,
    color: "black",
    border: 'none',
    '&$focused $notchedOutline': {
      color: 'white',
      border: 'none'
    },
  },
  outlinedInput1: {
    fontSize: 13,
    '&$focused $notchedOutline': {
      color: 'white',
      border: 'none',
    },
  },
  confirmText: {
    marginLeft: '8px'
  },
  confirmButton: {
    borderRadius: 20,
    background: "#008000",
    marginLeft: '15px',
    color: "white",
    '&:hover': {
      backgroundColor: "#008000"
    }
  },
  confirmButton1: {
    borderRadius: 20,
    background: "#FF6666",
    marginLeft: '15px',
    color: "white",
    '&:hover': {
      backgroundColor: '#FF6666'
    }
  },
  noBorder: {
    border: "1px solid #215176",
    fontWeight: 'bold',
    borderBottom: "3px solid blue",
    '&:hover': {
      borderBottom: "3px solid blue",
    }
  },
  clientbutton: {
    borderRadius: 20,
    marginTop: '8px'
  },

}));

const ClientConfirmation = () => {
  const classes = useStyles();
  const { state } = useGlobalState();
  const [selectedClient, setSelectedClient] = useState<IClientCodeConfirmation>();
  const [confirmedBy, setConfirmedBy] = useState<string>('');
  const [confirmedCount, setconfirmedCount] = useState<number>(0);
  const [notConfirmedCount, setNotConfirmedCount] = useState<number>(0);
  const [clientCodeConfirmations, setClientCodeConfirmation] = useState<IClientCodeConfirmation[]>([]);
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const isEnabled = confirmedBy != undefined && confirmedBy?.length > 0 && selectedClient != undefined;
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [clientConfirmation, setClientConfirmationTrigger] = useState(false);
  const [disableText, setDisableText] = useState(false);
  const [buttonText, setButtonText] = useState("Confirm");

  // Build local State value based on the "IClientDetails" contract
  let defaultValues: IClientDetails = {
    clientCode: "",
    accountName1: "",
    accountName2: "",
    physicalAddress: "",
    daytoDayContactEmail: "",
    remitClientName: "",
    remittanceAddress: "",
    callerIDDemandPhoneNumber: "",
    daytoDayContactName: "",
    billingAddress: "",
    billingContactPhone: "",
    billingContactEmail: "",
    companyName: "",
    invoiceClientName: "",
    invoiceContactName: "",
    invoiceContactPhone: "",
    invoiceContactEmail: "",
    invoiceAddress: "",
    trustCheckClientName: "",
    trustCheckContactName: "",
    trustCheckContactPhone: "",
    trustCheckContactEmail: "",
    trustCheckAddress: "",
    creditCardsAccepted: "",
    billingClientName: "",
    billingContactName: "",
    creditReportingPreference: "",
    customerPaymentWebsite: "",
    remitPhoneNumber: "",
    isConfirmed: false,
    confirmedBy: "",
  }

  // Assign the default values to the localState
  const [localState, setLocalState] = useState(defaultValues);

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setClientConfirmationTrigger(false);
  };

  useEffect(() => {
    (async () => {
      if (state.userAccessContext.role === 2 || state.userAccessContext.role === 3) {
        const response = await useFetch<IClientTypeCount[]>("Client/ClientConfirmationTypeCount?userId=" + state.userAccessContext?.id + "&isAdmin=" + true);
        var confirmedRow = response.data.find(e => e.type === "Confirmed");
        setshowProgressBar(true);
        if (confirmedRow != null) {
          setconfirmedCount(confirmedRow.typeCount);
        }
        var notConfirmedRow = response.data.find(e => e.type === "Not confirmed");
        if (notConfirmedRow != null) {
          setNotConfirmedCount(notConfirmedRow.typeCount);
        }
      }
      else {
        const response = await useFetch<IClientTypeCount[]>("Client/ClientConfirmationTypeCount?userId=" + state.userAccessContext?.id + "&isAdmin=" + false);
        var confirmedRow = response.data.find(e => e.type === "Confirmed");
        if (confirmedRow != null) {
          setconfirmedCount(confirmedRow.typeCount);
        }
        var notConfirmedRow = response.data.find(e => e.type === "Not confirmed");
        if (notConfirmedRow != null) {
          setNotConfirmedCount(notConfirmedRow.typeCount);
        }
      }
    })().finally(() => {
      setshowProgressBar(false);
    })
  }, [selectedClient, confirmedCount, notConfirmedCount])

  useEffect(() => {
    (async () => {
      if (state.userAccessContext.role === 2 || state.userAccessContext.role === 3) {
        await useFetch<IClientCodeConfirmation[]>("Client/ClientCodeConfirmations?userId=" + state.userAccessContext?.id + "&isAdmin=" + true).then((r) => {
          setClientCodeConfirmation(r.data);
        }).finally(() => {
          setshowProgressBar(false);
        });
      }
      else {
        await useFetch<IClientCodeConfirmation[]>("Client/ClientCodeConfirmations?userId=" + state.userAccessContext?.id + "&isAdmin=" + false).then((r) => {
          setClientCodeConfirmation(r.data);
        }).finally(() => {
          setshowProgressBar(false);
        });
      }
    })()
  }, [])

  function handleConfirm() {
    (async () => {
      if (selectedClient != null) {
        selectedClient.isConfirmed = "Yes";
        selectedClient.confirmedBy = confirmedBy ?? "";
        selectedClient.userId = state.userAccessContext.id;
        await usePost<{ clientCodeConfirmation: IClientCodeConfirmation, totalRecordCount: number }>("Client/ClientStatusConfirmation", selectedClient)
          .finally(() => {
            setDialogOpen(false);
            setClientConfirmationTrigger(true);
            setDisableText(true);
            HandleOnConfirm();
          });

        //Confirmation Email
        let request = {
          "clientCode": selectedClient.clientCode,
          "phase": selectedClient.phase,
          "confirmedBy": selectedClient.confirmedBy,
          "userName": state.userAccessContext?.userName
        };
        await usePost<any>("SendClientConfirmationEmail", request);
      }
    })()
  }

  function onClientSelect(event, value) {
    setLocalState(defaultValues);
    setSelectedClient(value);
    setshowProgressBar(true);
    (async () => {
      await useFetch<IClientDetails>("Client/GetClientDetails?clientCode=" + value?.clientCode + "&companyCode=" + value?.phase).then((r) => {
        setLocalState(r.data);
        if (!r.data.confirmedBy) {
          setConfirmedBy("");
          setButtonText("Confirm")
          setDisableText(false);
        } else {
          setConfirmedBy(r.data.confirmedBy);
          setButtonText("Already Confirmed");
          setDisableText(true);
        }
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }

  function handleCloseDialog() {
    setDialogOpen(false);
  }

  function openConfirmDialog() {
    setDialogOpen(true);
  }

  const HandleConfirmationVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(ConfirmText)) {
      setError("");
      setIsValid(false);
      setConfirmedBy(newValue); // only set when successful
    } else {
      setError("please enter characters and numbers only");
      setIsValid(true);
    }
  };

  const HandleOnConfirm = () => {
    (async () => {
      if (state.userAccessContext.role === 2 || state.userAccessContext.role === 3) {
        const response = await useFetch<IClientTypeCount[]>("Client/ClientConfirmationTypeCount?userId=" + state.userAccessContext?.id + "&isAdmin=" + true);
        var confirmedRow = response.data.find(e => e.type === "Confirmed");
        if (confirmedRow != null) {
          setconfirmedCount(confirmedRow.typeCount);
        }
        var notConfirmedRow = response.data.find(e => e.type === "Not confirmed");
        if (notConfirmedRow != null) {
          setNotConfirmedCount(notConfirmedRow.typeCount);
        }
      }
      else {
        const response = await useFetch<IClientTypeCount[]>("Client/ClientConfirmationTypeCount?userId=" + state.userAccessContext?.id + "&isAdmin=" + false);
        var confirmedRow = response.data.find(e => e.type === "Confirmed");
        if (confirmedRow != null) {
          setconfirmedCount(confirmedRow.typeCount);
        }
        var notConfirmedRow = response.data.find(e => e.type === "Not confirmed");
        if (notConfirmedRow != null) {
          setNotConfirmedCount(notConfirmedRow.typeCount);
        }
      }
    })()
  }

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container spacing={0} className={classes.grid} wrap="wrap">
          <Grid item xs={6} sm={2} >
            <Typography variant="h5" gutterBottom className={`${classes.textStyle} ${"headertitle"}`}>
              <b> CLIENT CONFIRMATION </b>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} >
            <Autocomplete
              id="client-select"
              autoComplete autoHighlight
              size="small"
              classes={{ paper: classes.paper1, option: classes.smallFont }}
              options={clientCodeConfirmations}
              onChange={onClientSelect}
              getOptionLabel={(option) => option.clientName}
              className={classes.clientCode}
              loading={clientCodeConfirmations?.length === 0}
              renderOption={(option) => (
                <React.Fragment>
                  <span style={option.isConfirmed == "Yes" ? { color: "green" } : { color: "red" }}> {option.clientName}</span>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Client Code"
                  className={classes.dropDownStyle}
                  variant="outlined"
                  style={{ border: 'none', fontSize: 13 }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                    endAdornment: (
                      <React.Fragment>
                        {clientCodeConfirmations?.length === 0 ? <CircularProgress style={{ height: "25px", width: "25px" }} color="inherit" /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                    classes: {
                      root: classes.outlinedInputStyle
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} >
            <Box alignItems="flex-start" display="flex" justifyContent="flex-start">
              <Box display="flex" m={1}>
                <Typography variant="subtitle1" gutterBottom className={classes.confirmText}>
                  <b> CONFIRMED: </b>
                </Typography>
              </Box>
              <Box display="flex" m={1}>
                <Button id="Cinfo_ConfCount" size="small" className={classes.confirmButton}
                  variant="contained" startIcon={<NotificationsActiveIcon />} >
                  {confirmedCount}
                </Button>
              </Box>
              <Box display="flex" m={1}>
                <Typography variant="subtitle1" gutterBottom className={classes.confirmText}>
                  <b> NOT CONFIRMED : </b>
                </Typography>
              </Box>
              <Box display="flex" m={1}>
                <Button id="Cinto_NotConfCount" size="small" className={classes.confirmButton1}
                  variant="contained" startIcon={<NotificationsActiveIcon />} >
                  {notConfirmedCount}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid container spacing={0} className={`${classes.secondGrid} ${"scrollbox"} ${"on-scrollbar"}`}>
            <Grid item xs={6} sm={4}>
              <TextField
                id="Conf_clientCode" className={classes.textfield}
                size="small"
                defaultValue="Client Code"
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.noBorder
                  }
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} sm={8} >
              <TextField className={classes.textfield1}
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.outlinedInput1,
                  }
                }}
                value={localState?.clientCode} margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                id="Conf_clientCode" className={classes.textfield}
                size="small"
                defaultValue="Account Name1"
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.noBorder
                  }
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} sm={8} >
              <TextField className={classes.textfield1}
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.outlinedInput1,
                  }
                }}
                value={localState?.accountName1} margin="dense" variant="outlined" />
            </Grid>

            <React.Fragment>
              <Grid item xs={6} sm={4}>
                <TextField
                  id="Conf_clientCode" className={classes.textfield}
                  size="small"
                  defaultValue="Account Name2"
                  InputProps={{
                    readOnly: true,
                    classes: {
                      root: classes.outlinedInput,
                      notchedOutline: classes.noBorder
                    }
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} sm={8} >
                <TextField className={classes.textfield1}
                  InputProps={{
                    readOnly: true,
                    classes: {
                      root: classes.outlinedInput1,
                    }
                  }}
                  value={localState?.accountName2} margin="dense" variant="outlined" />
              </Grid>
            </React.Fragment>

            <Grid item xs={6} sm={4}>
              <TextField
                id="Conf_clientCode" className={classes.textfield}
                size="small"
                defaultValue="Physical Address"
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.noBorder
                  }
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} sm={8} >
              <TextField className={classes.textfield1}
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.outlinedInput1,
                  }
                }}
                value={localState?.physicalAddress} margin="dense" variant="outlined" />
            </Grid>

            {selectedClient?.phase === constant.FIRSTSTP || selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={6} sm={4}>
                  <TextField
                    id="Conf_clientCode" className={classes.textfield}
                    size="small"
                    defaultValue="Remit Client Name"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1,
                      }
                    }}
                    value={localState?.remitClientName} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null
            }

            {selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Remit Phone Number"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.callerIDDemandPhoneNumber} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}

            {selectedClient?.phase === constant.FIRSTSTP ?
              <React.Fragment>
                <Grid item xs={6} sm={4}>
                  <TextField
                    id="Conf_clientCode" className={classes.textfield}
                    size="small"
                    defaultValue="Caller Id/Demand Phone Number"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1,
                      }
                    }}
                    value={localState?.callerIDDemandPhoneNumber} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null
            }

            {selectedClient?.phase === constant.FIRSTSTP || selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Remit Address"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.remittanceAddress} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}

            {selectedClient?.phase === constant.FIRSTSTP || selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Customer Payment Website"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.customerPaymentWebsite} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}

            {selectedClient?.phase === constant.FIRSTSTP || selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Credit Cards Accepted"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.creditCardsAccepted} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}

            <Grid item xs={6} sm={4}>
              <TextField
                id="Conf_clientCode" className={classes.textfield}
                size="small"
                defaultValue="Day to Day Contact Name"
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.noBorder
                  }
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} sm={8} >
              <TextField className={classes.textfield1}
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.outlinedInput1,
                  }
                }}
                value={localState?.daytoDayContactName} margin="dense" variant="outlined" />
            </Grid>

            <Grid item xs={6} sm={4}>
              <TextField
                id="Conf_clientCode" className={classes.textfield}
                size="small"
                defaultValue="Day to Day Contact Email"
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.noBorder
                  }
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6} sm={8} >
              <TextField className={classes.textfield1}
                InputProps={{
                  readOnly: true,
                  classes: {
                    root: classes.outlinedInput1,
                  }
                }}
                value={localState?.daytoDayContactEmail} margin="dense" variant="outlined" />
            </Grid>

            {selectedClient?.phase === constant.FIRSTSTP || selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="Conf_BillingClientName" className={classes.textfield}
                    defaultValue="Billing Client Name"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.billingClientName} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}

            {selectedClient?.phase === constant.FIRSTSTP || selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="outlined-read-only-input" className={classes.textfield}
                    defaultValue="Billing Contact Name"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8}>
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.billingContactName} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}

            {selectedClient?.phase === constant.FIRSTSTP || selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={6} sm={4}>
                  <TextField
                    id="Conf_clientCode" className={classes.textfield}
                    size="small"
                    defaultValue="Billing Contact Phone"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1,
                      }
                    }}
                    value={localState?.billingContactPhone} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null
            }

            {selectedClient?.phase === constant.FIRSTSTP || selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={6} sm={4}>
                  <TextField
                    id="Conf_clientCode" className={classes.textfield}
                    size="small"
                    defaultValue="Billing Contact Email"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1,
                      }
                    }}
                    value={localState?.billingContactEmail} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null
            }

            {selectedClient?.phase === constant.FIRSTSTP || selectedClient?.phase === constant.PREC ?
              <React.Fragment>
                <Grid item xs={6} sm={4}>
                  <TextField
                    id="Conf_clientCode" className={classes.textfield}
                    size="small"
                    defaultValue="Billing Address"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1,
                      }
                    }}
                    value={localState?.billingAddress} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null
            }

            {selectedClient?.phase === constant.CONT ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    id="Conf_CompanyName"
                    className={classes.textfield}
                    size="small"
                    defaultValue="Invoice Client Name"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.invoiceClientName} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null
            }

            {selectedClient?.phase === constant.CONT ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="Conf_PhoneNumber" className={classes.textfield}
                    defaultValue="Invoice Contact Name"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.invoiceContactName} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null
            }

            {selectedClient?.phase === constant.CONT ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="Conf_Email" className={classes.textfield}
                    defaultValue="Invoice Contact Phone"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.invoiceContactPhone} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null
            }

            {selectedClient?.phase === constant.CONT ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Invoice Contact Email"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.invoiceContactEmail} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}

            {selectedClient?.phase === constant.CONT ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Invoice Address"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.invoiceAddress} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}

            {selectedClient?.phase === constant.CONT ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Trust Check Client Name"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.trustCheckClientName} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}
            {selectedClient?.phase === constant.CONT ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Trust Check Contact Name"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.trustCheckContactName} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}

            {selectedClient?.phase === constant.CONT ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Trust Check Contact Phone"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.trustCheckContactPhone} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}

            {selectedClient?.phase === constant.CONT ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Trust Check Contact Email"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.trustCheckContactEmail} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}
            {selectedClient?.phase === constant.CONT ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="Conf_Remittance" className={classes.textfield}
                    defaultValue="Trust Check Address"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }}
                    value={localState?.trustCheckAddress} margin="dense" variant="outlined" />
                </Grid>
              </React.Fragment>
              :
              null}

            {selectedClient?.phase === constant.PREC || selectedClient?.phase === constant.CONT ?
              <React.Fragment>
                <Grid item xs={6} sm={4} >
                  <TextField
                    size="small"
                    id="Conf_CRP" className={classes.textfield}
                    defaultValue="Credit Reporting"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput,
                        notchedOutline: classes.noBorder
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} sm={8} >
                  <TextField className={classes.textfield1}
                    value={localState?.creditReportingPreference} margin="dense" variant="outlined"
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: classes.outlinedInput1
                      }
                    }} />
                </Grid>
              </React.Fragment>
              : null
            }
            <Grid item xs={8} sm={10} >
              <TextField
                size="small"
                disabled={disableText}
                id="Conf_CRP" className={classes.textfield2}
                placeholder="Please type in your first and last name and click Confirm to confirm all information above is accurate." value={confirmedBy}
                onChange={(e) => { setConfirmedBy(e.target.value); HandleConfirmationVal(e); }}
                helperText={error} // error message
                error={!!error}
                inputProps={{
                  maxlength: 256
                }}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.noBorder
                  }
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4} sm={2} >
              <Button id="Conf_Confirm_btn" disabled={!isEnabled || isValid || selectedClient.isConfirmed == "Yes"}
                size="small" className={classes.clientbutton}
                variant="contained" color="primary" startIcon={<DoneAllIcon />} onClick={openConfirmDialog}>
                {buttonText}
              </Button>
            </Grid>
          </Grid >
        </Grid >

        <Dialog TransitionComponent={Transition}
          PaperProps={{ style: { borderRadius: 15 } }}
          open={isDialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialog}>
            <Typography variant="h6" gutterBottom className={classes.titleheader}>
              CLIENT CONFIRMATION
            </Typography>
          </DialogTitleHeader>
          <DialogContent>
            <Typography variant="h6" style={{ color: "black" }} gutterBottom>
              Are you sure, you want to confirm the Client ?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button id="Conf_Okay_btn" size="small" className={classes.yesbutton} onClick={handleConfirm} autoFocus>
              Yes
            </Button>
            <Button id="Conf_Cancel_btn" size="small" className={classes.nobutton} onClick={handleCloseDialog} >
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar className="snackBarStyle" open={clientConfirmation} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
            Client Confirmed Successfully.
          </Alert>
        </Snackbar>
        <Footer />
      </div >
    </React.Fragment >
  )
}

export default ClientConfirmation
