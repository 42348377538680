import { makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { IReportField } from '../../models/Reports/IReportField';
import { IReportOperator } from '../../models/Reports/IReportOperator';
import { IReportsList } from '../../models/Reports/ReportsList';
import { useGlobalState } from '../../store/GlobalStore';
import { StyledTableCell, StyledTableRow } from '../GlobalStyles/TableStyledCell';
import DeleteReport from './DeleteReport';
import EditReport from "./EditReport";
import ReportShare from './ReportShare';

const useStyles = makeStyles((theme) => ({
    textStyle: {
        textDecoration: 'none',
        cursor: 'pointer',
        color: 'black',
        fontSize: 13
    }
}))

const ReportsList: React.FC<{
    row: IReportsList, selected: boolean, onClick: (id: string) => void, onCreate: () => void,
    onDelete: () => void, clients?: any, states?: any, statuses?: any, phases?: any, isValid?: boolean,
    operators?: IReportOperator[], fieldLists?: IReportField[]
}> = (props) => {
    const classes = useStyles()
    const { row, clients, states, statuses, phases, isValid, operators, fieldLists } = props;
    const { state } = useGlobalState();
    let path = `/reportsBuilder/${row.id.toString()}/${row.name.toString()}`;
    const createdDate = moment(row.date_created).format(state.GlobalUtils?.settingValue);

    return (
        <React.Fragment>
            <StyledTableRow >
                <StyledTableCell onClick={(_) => props.onClick(row.id)} style={{ width: 550, }}>
                    <Typography className={classes.textStyle} component={Link} to={path}>{row.name}</Typography>
                </StyledTableCell>

                <StyledTableCell onClick={(_) => props.onClick(row.id)}  >
                    <Typography className={classes.textStyle} component={Link} to={path}>{createdDate}</Typography>
                </StyledTableCell>

                <StyledTableCell >
                    <EditReport getReports={props.onCreate} reportId={row.id} reportRow={row}
                        clients={clients} states={states} statuses={statuses} phases={phases} isValid={isValid}
                        operators={operators} fieldLists={fieldLists} />
                </StyledTableCell>

                {/* <StyledTableCell align="left" >
                    <ReportShare reportRow={row} reportName={row.name} />
                </StyledTableCell> */}

                <StyledTableCell >
                    <DeleteReport reportRow={row} onDelete={props.onDelete} />
                </StyledTableCell>
            </StyledTableRow>
        </React.Fragment>
    )
}

export default ReportsList