import { Collapse, makeStyles, TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as constant from '../../constants/Constant';
import { Columns } from '../../models/Inquiry/config';
import { IDebtor } from '../../models/Inquiry/IDebtor';
import { useGlobalState } from '../../store/GlobalStore';
import InquiryDetail from './InquiryDetail';

const useRowStyles = makeStyles({
    root: {
        fontSize: 11,
        textOverflow: 'ellipsis',
    },
    rootHidden: {
        display: "none"
    },
    image: {
        width: '20px',
        height: '17px',
    },
    TextHighlight: {
        color: '#000000',
        fontWeight: 'bold',
        fontSize: 11,
        backgroundColor: "yellow",
        textOverflow: 'ellipsis'
    },
    TextNormal: {
        color: 'black',
        fontSize: 11,
        textOverflow: 'ellipsis'
    },
    overwrapcell: {
        overflow: 'break-word'
    },
    clientId: {

    },
    table: {
        flexWrap: 'wrap',
        padding: 3,
        border: '1px solid #f0efef'
    },
    dateStyle: {
        marginLeft: '13px',
        align: "left",
    },
    customTable: {
        padding: "0px 1px", // <-- arbitrary value
        "& .MuiTableCell-sizeSmall": {
            padding: "0px 1px" // <-- arbitrary value
        }
    },
})

function usePrevious(data) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = data
    }, [data])
    return ref.current
}

const ExpandableRow: React.FC<{ row: IDebtor, selected: boolean, onClick: (accountId: string) => void, selectedDBTNumber: string, searchWord: string }> = (props) => {
    const { row, searchWord } = props;
    const classes = useRowStyles();
    const { state } = useGlobalState();

    const [service, setService] = useState('');
    const [GetDebtorNo, setGetDebtorNo] = useState<string>();
    const [isActiveStatus, setGetActivestatus] = useState(false);

    let lastChangeDate = moment(row?.dbT_LAST_CHG_DATE).format(state?.GlobalUtils?.settingValue);
    let referralDate = moment(row?.dbT_REFERRAL_DATE).format(state?.GlobalUtils?.settingValue);

    const prevCount = usePrevious(props?.selectedDBTNumber);
    const userId = state?.userAccessContext?.id.toString();


    useEffect(() => {
        if (row?.dbT_COMPANY === constant.CONT) {
            setService('Contingency');
        }
        if (row?.dbT_COMPANY === constant.PREC) {
            setService('Flat Fee');
        }
        else if (row?.dbT_COMPANY === constant.FIRSTSTP) {
            setService('1st Party');
        }
    }, [service])

    useEffect(() => {
        if (prevCount === undefined) {
            setGetDebtorNo("");
        } else if (prevCount === null || prevCount === "") {
            setGetDebtorNo(row?.dbT_NO);
        } else {
            setGetDebtorNo(prevCount);
        }
    }, [props.selectedDBTNumber, prevCount])

    return (
        <React.Fragment>
            <TableRow className={classes.table} style={row?.isActive === true && props?.selected ? { backgroundColor: "#1E90FF", fontSize: '12px' } : row?.isActive === true ? { backgroundColor: "white", color: "black", fontSize: '14px' } : props?.selected ? { backgroundColor: "#1E90FF", color: "white" } : { backgroundColor: "#e7eff9" }}
                onClick={(_) => { props.onClick(row.dbT_NO); (row.dbT_NO !== props.selectedDBTNumber ? null : setGetDebtorNo("")); }} key={row.dbT_NO} hover>
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : { fontSize: 11 }} scope="row" >
                    <span style={props?.selected === true && row?.isActive === true && searchWord.toLocaleLowerCase() === row.dbT_CLIENT.toLocaleLowerCase() ? { color: 'black' } : props?.selected === true && row?.isActive === false && searchWord.toLocaleLowerCase() === row?.dbT_CLIENT?.toLocaleLowerCase() ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive ? { color: 'black' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord?.toLocaleLowerCase() === row?.dbT_CLIENT?.toLocaleLowerCase() ? classes.TextHighlight : ""}`}>
                        {row.dbT_CLIENT}
                    </span>
                </TableCell>
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : { fontSize: 11 }} scope="row" >
                    <span style={props?.selected === true && row?.isActive === true && row?.clT_NAME_1?.toLocaleLowerCase()?.includes(searchWord?.toLocaleLowerCase()) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === false && row?.clT_NAME_1?.toLocaleLowerCase()?.includes(searchWord?.toLocaleLowerCase()) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive === true ? { color: 'black' } : props?.selected === true && searchWord === "" ? { color: 'white' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === "" ? classes.TextNormal : row?.clT_NAME_1?.toLocaleLowerCase()?.includes(searchWord?.toLocaleLowerCase()) ? classes.TextHighlight : ""}`}>
                        {row?.clT_NAME_1}
                    </span>
                </TableCell>
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11 }} >
                    <span style={props?.selected === true && row?.isActive === true && row?.dbT_NO?.includes(searchWord) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === false && row?.dbT_NO?.includes(searchWord) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive === true ? { color: 'black' } : props?.selected === true && searchWord === "" ? { color: 'white' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === "" ? classes.TextNormal : row?.dbT_NO?.includes(searchWord) ? classes.TextHighlight : ""}`}>
                        {row.dbT_NO}
                    </span>
                </TableCell>
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11 }} >
                    <span style={props?.selected === true && row?.isActive === true && row?.dbT_CLNT_ACNT1?.includes(searchWord) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === false && row?.dbT_CLNT_ACNT1?.includes(searchWord) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive === true ? { color: 'black' } : props?.selected === true && searchWord === "" ? { color: 'white' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === "" ? classes.TextNormal : row?.dbT_CLNT_ACNT1?.includes(searchWord) ? classes.TextHighlight : ""}`}>
                        {row.dbT_CLNT_ACNT1}
                    </span>
                </TableCell>
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11 }} >
                    <span style={props?.selected === true && row?.isActive === true && row?.dbT_CLNT_ACNT2?.includes(searchWord) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === false && row?.dbT_CLNT_ACNT2?.includes(searchWord) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive === true ? { color: 'black' } : props?.selected === true && searchWord === "" ? { color: 'white' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === "" ? classes.TextNormal : row?.dbT_CLNT_ACNT2?.includes(searchWord) ? classes.TextHighlight : ""}`}>
                        {row?.dbT_CLNT_ACNT2}
                    </span>
                </TableCell>
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11 }} >
                    <span style={props?.selected === true && row?.isActive === true && row?.dbT_FLD_24?.includes(searchWord) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === false && row?.dbT_FLD_24?.includes(searchWord) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive === true ? { color: 'black' } : props?.selected === true && searchWord === "" ? { color: 'white' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === "" ? classes.TextNormal : row?.dbT_FLD_24?.includes(searchWord) ? classes.TextHighlight : ""}`}>
                        {row?.dbT_FLD_24}
                    </span>
                </TableCell>
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11 }}>
                    <span style={props?.selected === true && row?.isActive === true && row?.dbT_NAME?.toLocaleLowerCase()?.includes(searchWord?.toLocaleLowerCase()) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === false && row?.dbT_NAME?.toLocaleLowerCase()?.includes(searchWord?.toLocaleLowerCase()) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive === true ? { color: 'black' } : props?.selected === true && searchWord === "" ? { color: 'white' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === "" ? classes.TextNormal : row?.dbT_NAME?.toLocaleLowerCase()?.includes(searchWord?.toLocaleLowerCase()) ? classes.TextHighlight : ""}`}>
                        {row?.dbT_NAME}
                    </span>
                </TableCell>
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11 }} >
                    <span style={props?.selected === true && row?.isActive === true && searchWord === row?.deM_ZIP ? { color: 'black' } : props?.selected === true && row?.isActive === false && searchWord === row?.deM_ZIP ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive ? { color: 'black' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === row?.deM_ZIP ? classes.TextHighlight : ""}`}>
                        {row?.deM_ADDR1},&nbsp;{row.deM_CITY},&nbsp;{row.deM_STATE},&nbsp;{row.deM_ZIP}
                    </span>
                </TableCell>
                <TableCell style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11 }} >
                    <span style={props?.selected === true && row?.isActive === true && row?.deM_PHONE1?.includes(searchWord) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === false && row?.deM_PHONE1?.includes(searchWord) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive === true ? { color: 'black' } : props?.selected === true && searchWord === "" ? { color: 'white' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === "" ? classes.TextNormal : row?.deM_PHONE1?.includes(searchWord) ? classes.TextHighlight : ""}`}>
                        {row?.deM_PHONE1}
                    </span>
                </TableCell>
                {/* <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11 }} align="center" >
                    <span style={props?.selected === true && row?.isActive === true && searchWord === row?.dbT_PRINCIPAL_DUE?.toFixed(2) ? { color: 'black' } : props?.selected === true && row?.isActive === false && searchWord === row?.dbT_PRINCIPAL_DUE?.toFixed(2) ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive ? { color: 'black' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === row.dbT_PRINCIPAL_DUE.toFixed(2) ? classes.TextHighlight : ""}`}>
                        ${row.dbT_PRINCIPAL_DUE.toFixed(2)}
                    </span>
                </TableCell>
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white", fontStyle: 'bold' } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11 }} >
                    <span style={props?.selected === true && row?.isActive === true && searchWord === row?.dbT_MISC1_DUE?.toFixed(2) ? { color: 'black' } : props?.selected === true && row?.isActive === false && searchWord === row?.dbT_MISC1_DUE?.toFixed(2) ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive ? { color: 'black' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === row?.dbT_MISC1_DUE?.toFixed(2) ? classes.TextHighlight : ""}`}>
                        ${row?.dbT_MISC1_DUE?.toFixed(2)}
                    </span>
                </TableCell> */}
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11 }} align="center" >
                    <span style={props?.selected === true && row?.isActive === true && searchWord === row?.dbT_PRINCIPAL_RECVD?.toFixed(2) ? { color: 'black' } : props?.selected === true && row?.isActive === false && searchWord === row?.dbT_PRINCIPAL_RECVD?.toFixed(2) ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive ? { color: 'black' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === row?.dbT_PRINCIPAL_RECVD?.toFixed(2) ? classes.TextHighlight : ""}`}>
                        ${row.dbT_PRINCIPAL_RECVD.toFixed(2)}
                    </span>
                </TableCell>
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11 }} align="center" >
                    <span style={props?.selected === true && row?.isActive === true && searchWord === row?.dbT_TOTAL_DUE?.toFixed(2) ? { color: 'black' } : props?.selected === true && row?.isActive === false && searchWord === row?.dbT_TOTAL_DUE?.toFixed(2) ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive ? { color: 'black' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === row?.dbT_TOTAL_DUE?.toFixed(2) ? classes.TextHighlight : ""}`}>
                        ${row?.dbT_TOTAL_DUE?.toFixed(2)}
                    </span>
                </TableCell>
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11 }} >
                    <span style={props?.selected === true && row?.isActive === true && row?.stS_DESC?.toLocaleLowerCase()?.includes(searchWord?.toLocaleLowerCase()) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === false && row?.stS_DESC?.toLocaleLowerCase()?.includes(searchWord?.toLocaleLowerCase()) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive === true ? { color: 'black' } : props?.selected === true && searchWord === "" ? { color: 'white' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === "" ? classes.TextNormal : row?.stS_DESC?.toLocaleLowerCase()?.includes(searchWord?.toLocaleLowerCase()) ? classes.TextHighlight : ""}`}>
                        {row?.stS_DESC.replace('|', " ")}
                    </span>
                </TableCell>
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11, marginLeft: '10px' }}>
                    <span style={props?.selected && row?.isActive ? { color: 'white' } : null} className={classes.dateStyle}>
                        {lastChangeDate !== 'Invalid date' ? lastChangeDate : ""}
                    </span>
                </TableCell>
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white" } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11, marginLeft: '10px' }} align="left">
                    <span style={props?.selected && row?.isActive ? { color: 'white' } : null}>
                        {referralDate !== 'Invalid date' ? referralDate : ""}
                    </span>
                </TableCell>
                <TableCell classes={{ root: classes.customTable }} style={props?.selected && row?.isActive === false ? { fontSize: 11, color: "white", fontStyle: 'bold' } : row?.isActive === true ? { fontSize: 11, color: "black" } : { fontSize: 11 }} >
                    <span style={props?.selected === true && row?.isActive === true && service?.toLocaleLowerCase()?.includes(searchWord?.toLocaleLowerCase()) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === false && service?.toLocaleLowerCase()?.includes(searchWord?.toLocaleLowerCase()) && searchWord != "" ? { color: 'black' } : props?.selected === true && row?.isActive === true ? { color: 'white' } : row?.isActive === true ? { color: 'black' } : props?.selected === true && searchWord === "" ? { color: 'white' } : props?.selected && row?.isActive === false ? { color: 'white' } : { color: 'black' }} className={`${searchWord === "" ? classes.TextNormal : service?.toLocaleLowerCase()?.includes(searchWord?.toLocaleLowerCase()) ? classes.TextHighlight : ""}`}>
                        {service}
                    </span>
                </TableCell>
            </TableRow>
            <TableRow className={props?.selected ? classes.root : classes.rootHidden}>
                <TableCell scope="row" colSpan={Columns.length} classes={{ root: classes.customTable }}>
                    <Collapse in={props.selected} timeout="auto" unmountOnExit >
                        <InquiryDetail inquiryDetail={row} isBlocked={isActiveStatus} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    )
}

export default ExpandableRow