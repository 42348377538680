import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import PersonIcon from '@material-ui/icons/Person';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useState } from 'react';
import { PuffLoader } from 'react-spinners';
import { regexValidEmail } from '../../constants/Constant';
import orgName from '../../images/agentUser/newOrganization.svg';
import updateEmail from '../../images/agentUser/updateEmail.svg';
import { IProspectUsersDetails } from '../../models/accesscontrol/ProspectUsersDetails';
import { usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete, DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import { useStyles } from './ProspectUserCss';
import { useGlobalState } from '../../store/GlobalStore';

const NegatedUser: React.FC<{ userRow: IProspectUsersDetails, onUpdate: () => void }> = (props) => {
    const { userRow } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { state, dispatch } = useGlobalState();
    const [Emailerror, setEmailError] = useState("");
    const [isDisable, setIsDisable] = useState(false);
    const [ModifiedReason, setModifiedReason] = useState("");
    const [ProgressBar, setshowProgressBar] = useState(false);
    const [negatedUserOpen, setNegatedUserOpen] = useState(false);
    const [isNewOrganization, setIsNewOrganization] = useState(0);
    const [swtichOrganization, setSwtichOrganization] = useState(false);
    const [newOrganizationAdminEmail, setNewOrganizationAdminEmail] = useState<string>("");

    const handleClose = () => {
        setOpen(false);
        handleOrganizationClose();
        setNegatedUserOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    }

    const handleOrganizationOpen = () => {
        setSwtichOrganization(true);
    }

    const handleOrganizationClose = () => {
        setEmailError("");
        setModifiedReason("");
        setSwtichOrganization(false);
        setNewOrganizationAdminEmail("");
    }

    const handleNegatedUserOpen = () => {
        setNegatedUserOpen(true);
    }

    const handleNegatedUserClose = () => {
        setNegatedUserOpen(false);
    }

    const handleNewEmailVal = (e) => {
        const email = e.target.value;
        // Validate if the given email is valid or not
        if (regexValidEmail.test(email)) {
            setEmailError("");
            setIsDisable(false);
        } else {
            setEmailError("Please enter valid Email Address");
            setIsDisable(true);
        }
    };

    // this function to read the Reason for why the user admin email is changed or why the account is switch to new client setup
    const handleModifiedReason = (event) => {
        setModifiedReason(event.target.value);
    }

    const handleConfirmNegatedUser = () => {
        setNegatedUserOpen(true);
    }

    const handleSubmit = () => {
        (async () => {
            let request = {
                "prospectUserId": userRow?.prospectUserId,
                "userId": state?.userAccessContext?.id,
                "isOrganizationEmailUpdated": isNewOrganization === 1 ? 0 : 1,
                "reason": ModifiedReason,
                "organizationAdminEmailId": isNewOrganization === 1 ? userRow?.prospectEmailAddress : newOrganizationAdminEmail,
                "oldAdminEmailId": userRow?.organizationAdminEmailId
            }
            await usePost("User/InsertProspectUserHistory", request).then((r) => {
            }).finally(() => {
                // Callback function once the modification history is tracked
                props?.onUpdate();
                handleClose();
            });
        })()
    };

    return (
        <div>
            <Button variant='contained' size="small" onClick={() => { handleOpen() }}
                className={classes.notApprovedBtn1} startIcon={<PersonIcon />}>
                Switch New ORGANIZATION
            </Button>

            <Dialog TransitionComponent={Transition} PaperProps={{ style: { borderRadius: 10 } }} open={open} onClose={handleClose}
                aria-labelledby="responsive-dialog-title" fullWidth={true} maxWidth={'xs'} >
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" className={classes.titleheader}>
                        SWITCH TO NEW ORGANIZATION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent style={{ padding: '25px' }}>
                    <Grid spacing={1} container >
                        <Grid item xs={12} className={classes.organizationGrid} onClick={() => { handleOrganizationOpen(); setIsNewOrganization(1); }}>
                            <Box m={0} width="20%">
                                <img src={orgName} width="50px" alt="org-name" />
                            </Box>
                            <Box m={0} width="80%">
                                <Typography variant='subtitle1'><b>Switch to New Organization</b></Typography>
                                {props?.userRow?.organizationName?.length ?
                                    <Typography variant='body2'>{props?.userRow?.organizationName}</Typography>
                                    :
                                    <Typography variant='body2' style={{ color: 'red' }}>
                                        Information not provided
                                    </Typography>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} className={classes.organizationGrid} onClick={() => { handleOrganizationOpen(); setIsNewOrganization(2); }}>
                            <Box m={0} width="20%">
                                <img src={updateEmail} width="50px" alt="org-name" />
                            </Box>
                            <Box m={0} width="80%">
                                <Typography variant='subtitle1'><b>Update Admin Email Address</b></Typography>
                                {props?.userRow?.organizationAdminEmailId?.length ?
                                    <Typography variant='body2'>{props?.userRow?.organizationAdminEmailId}</Typography>
                                    :
                                    <Typography variant='body2' style={{ color: 'red' }}>
                                        Information not provided
                                    </Typography>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog TransitionComponent={Transition}
                open={swtichOrganization} onClose={handleOrganizationClose} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleOrganizationClose}>
                    {isNewOrganization == 1 ?
                        <Typography variant="h6" className={classes.titleheader}>
                            CONFIRMATION MESSAGE
                        </Typography>
                        : isNewOrganization == 2 ?
                            <Typography variant="h6" className={classes.titleheader}>
                                UPDATE ADMIN EMAIL
                            </Typography>
                            : null
                    }
                </DialogTitleDelete>
                <DialogContent>
                    {isNewOrganization == 1 ?
                        <>
                            <Typography variant="subtitle1" className={classes.textfield} gutterBottom>
                                Are you sure, you want to <b>"Switch to New Organization"?</b>
                            </Typography>

                            <Grid container spacing={1} style={{ padding: '5px' }}>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' className={classes.textStyle}>
                                        <b>Please enter the reason to change new organization</b>
                                    </Typography>
                                    <TextField
                                        id="outlined-basic"
                                        multiline
                                        rows={4}
                                        label="Please enter the reason"
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => { handleModifiedReason(e); }}
                                        className={classes.newEmailAddressStyle}
                                    />
                                </Grid>
                            </Grid>
                        </>
                        : isNewOrganization == 2 ?
                            <Grid container spacing={1} style={{ padding: '5px' }}>
                                <Grid item xs={6}>
                                    <Typography variant='subtitle1' className={classes.textStyle}>
                                        <b>Organization Name</b>
                                    </Typography>
                                    <TextField
                                        id="outlined-basic"
                                        label="Organization Name"
                                        variant="outlined"
                                        value={props?.userRow?.organizationName}
                                        disabled
                                        size="small"
                                        className={classes.newEmailAddressStyle}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant='subtitle1' className={classes.textStyle}>
                                        <b>Organization Admin Email</b>
                                    </Typography>
                                    <TextField
                                        id="outlined-basic"
                                        label="Current Admin Email Id"
                                        value={props?.userRow?.organizationAdminEmailId}
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        className={classes.newEmailAddressStyle}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' className={classes.textStyle}>
                                        <b>New Admin Email Address</b>
                                    </Typography>
                                    <TextField
                                        id="outlined-basic"
                                        label="New Email Address"
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => { handleNewEmailVal(e); setNewOrganizationAdminEmail(e.target.value); }}
                                        helperText={Emailerror} error={!!Emailerror}
                                        className={classes.newEmailAddressStyle}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' className={classes.textStyle}>
                                        <b>Please enter the reason to change New Admin Email Address</b>
                                    </Typography>
                                    <TextField
                                        id="outlined-basic"
                                        multiline
                                        rows={4}
                                        label="Please enter the reason"
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => { handleModifiedReason(e); }}
                                        className={classes.newEmailAddressStyle}
                                    />
                                </Grid>
                            </Grid>
                            : null
                    }

                </DialogContent>
                <DialogActions>
                    {isNewOrganization == 1 ? <>
                        <Button id="DU_ok_btn" size="small" variant="contained" startIcon={<DoneAllIcon />}
                            className={classes.yesButton} disabled={!ModifiedReason?.length} onClick={handleConfirmNegatedUser}>
                            Yes
                        </Button>
                        <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleOrganizationClose} color="primary" className={classes.noButton}>
                            No
                        </Button>
                    </>
                        : isNewOrganization == 2 ?
                            <>
                                <Button id="DU_ok_btn" size="small" variant="contained" startIcon={<DoneAllIcon />}
                                    className={classes.yesButton} disabled={isDisable || !newOrganizationAdminEmail?.length || !ModifiedReason?.length} onClick={handleConfirmNegatedUser}>
                                    Submit
                                </Button>
                                <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleOrganizationClose} color="primary" className={classes.noButton}>
                                    Close
                                </Button>
                            </>
                            : null
                    }

                </DialogActions>
            </Dialog>

            <Dialog TransitionComponent={Transition} PaperProps={{ style: { borderRadius: 10 } }} open={negatedUserOpen} onClose={handleNegatedUserClose}
                aria-labelledby="responsive-dialog-title" fullWidth={true} maxWidth={'xs'} >
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleNegatedUserClose}>
                    <Typography variant="h6" className={classes.titleheader}>
                        INFORMATION MESSAGE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Typography variant='body2'>
                        This Client/Users has been considered as <b>"New Organization".</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button id="DU_ok_btn" size="small" variant="contained" startIcon={<DoneAllIcon />}
                        className={classes.yesButton} onClick={handleSubmit}>
                        Yes
                    </Button>
                    <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleNegatedUserClose} color="primary" className={classes.noButton}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>

        </div >
    )
}

export default NegatedUser