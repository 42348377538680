import { AppBar, Avatar, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { RemoveCircle } from '@mui/icons-material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EmailIcon from '@mui/icons-material/Email';
import HistoryIcon from "@mui/icons-material/History";
import PendingIcon from '@mui/icons-material/Pending';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Backdrop, Box, Chip, Dialog, DialogContent, IconButton, Paper, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { PuffLoader } from 'react-spinners';
import { ARMLogo } from '../../../constants/Constant';
import norecord from '../../../images/trustStatement/noFiles.svg';
import { ITrustStatmentReportHistory } from '../../../models/NotificationCenter/TrustStatementClientCodeResult';
import { UserRole, trustStatementReportShareStatus } from '../../../models/accesscontrol/ClientUserAccessContext';
import { useStyles } from '../../../pages/NotificationCenter/NotificationCenterCss';
import { useGlobalState } from '../../../store/GlobalStore';
import { useFetch } from '../../../utils/apiHelper';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import AvatarTooltip from '../../GlobalStyles/AvatarTooltip';
import GlobalAdmin from '../../../images/users/ClientAdmin.svg';
import ClientAdmin from '../../../images/users/GlobalAdmin.svg';
import SuperAdmin from '../../../images/users/SuperAdmin.svg';
import UserAvatar from '../../../images/webviewfiles/UserAvatar.png';

const TrustSharedUserHistory: React.FC<{ row: ITrustStatmentReportHistory }> = (props) => {
    const { row: trustStamentEmailHistoryDetails } = props;
    const classes = useStyles();
    const { state } = useGlobalState();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [TrustReportShareHistory, setTrustReportShareHistory] = useState<ITrustStatmentReportHistory[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);

    const handleClickOpen = () => {
        setOpen(true);
        GetTrustreportSharedHistory();
    };

    const handleClose = () => {
        setOpen(false);
    }

    const GetTrustreportSharedHistory = () => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<{ trustStamentEmailHistoryDetails: ITrustStatmentReportHistory[], totalCount: number }>("GetTrustReportShareHistoryById?reportSharedId=" + trustStamentEmailHistoryDetails?.reportSharedId).then((GetAllUsersHistory) => {
                setTrustReportShareHistory(GetAllUsersHistory?.data["trustStamentEmailHistoryDetails"]);
                setTotalCount(GetAllUsersHistory?.data["totalCount"]);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })();
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
    };


    return (
        <React.Fragment>
            <IconButton size='small' style={{ padding: "0px" }} onClick={handleClickOpen}>
                <LightTooltip title="User status history">
                    <HistoryIcon style={{ color: "#B30987" }} />
                </LightTooltip>
            </IconButton>

            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
                <AppBar position="fixed" className={classes.appBar} >
                    <Toolbar className={classes.toolbarStyle}>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" >
                            <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                        </IconButton>
                        <Typography variant="body1" className={classes.title}>
                            <b>{trustStamentEmailHistoryDetails?.emailAddress.toUpperCase()} ACCESS HISTORY</b>
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={() => handleClose()} aria-label="close" style={{ padding: '5px' }}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent >
                    <Grid container spacing={0}>
                        {!TrustReportShareHistory?.length ?
                            <Grid item xs={12}>
                                <Box m={0} p={0} mt={5} mb={5} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                    <img alt="noRecords" src={norecord} width="120px" style={{ textAlign: 'center' }} />
                                    <Typography variant='body2' align='center' color='red' mt={1} mb={1}>
                                        <b>No data to display</b>
                                    </Typography>
                                </Box>
                            </Grid>
                            :
                            <React.Fragment>
                                {TrustReportShareHistory?.length ?
                                    <Grid item xs={12}>
                                        <React.Fragment>
                                            <Typography variant='body2' mt={0} fontWeight={600}>
                                                User Status History Details
                                            </Typography>
                                            <Paper>
                                                <TableContainer component={Paper} style={{ marginTop: "10px" }} className={`${classes.trustReportShareHistoryContainer} ${"scrollbox"} ${"on-scrollbar"}`}>
                                                    <Table aria-label="simple table" stickyHeader>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="left" className={classes.TableCellHeadStyle}>USER ROLE</TableCell>
                                                                <TableCell align="left" className={classes.TableCellHeadStyle}>ACTION TAKEN BY</TableCell>
                                                                <TableCell align="left" className={classes.TableCellHeadStyle}>ACTION TAKEN DATE</TableCell>
                                                                <TableCell align="left" className={classes.TableCellHeadStyle}>STATUS</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {TrustReportShareHistory?.map((r) => (
                                                                <TableRow>
                                                                    <TableCell className={classes.tablecellTrustStatement}>
                                                                        {r?.role === UserRole.SuperAdmin ?
                                                                            <AvatarTooltip title="Super Admin">
                                                                                <Avatar className={classes.avatarStyle} src={SuperAdmin}></Avatar>
                                                                            </AvatarTooltip>
                                                                            : r?.role === UserRole.GlobalAdmin ?
                                                                                <AvatarTooltip title="Global Admin">
                                                                                    <Avatar className={classes.avatarStyle} src={GlobalAdmin}></Avatar>
                                                                                </AvatarTooltip>
                                                                                : r?.role === UserRole.ClientAdmin ?
                                                                                    <AvatarTooltip title="Client Admin">
                                                                                        <Avatar className={classes.avatarStyle} src={ClientAdmin}></Avatar>
                                                                                    </AvatarTooltip>
                                                                                    :
                                                                                    <AvatarTooltip title="System">
                                                                                        <Avatar className={classes.avatarStyle} src={UserAvatar}></Avatar>
                                                                                    </AvatarTooltip>
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell className={classes.tablecellTrustStatement}>
                                                                        <Typography variant='body2'>
                                                                            {r?.emailInvitedBy}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell className={classes.tablecellTrustStatement}>
                                                                        <Typography variant='body2'>
                                                                            {moment(r?.reportSharedDate).format(state.GlobalUtils?.settingValue)} {moment(r?.reportSharedDate).format('HH:mm:ss')}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell className={classes.tablecellTrustStatement}>
                                                                        <Chip label={<span><b>{r?.statusName}</b></span>}
                                                                            color={trustStatementReportShareStatus.UserSignupInvited == r?.statusId ? "primary" :
                                                                                trustStatementReportShareStatus.SignedInProspectUser == r?.statusId ? "warning" :
                                                                                    trustStatementReportShareStatus.TrustNotificationAccessProvided == r?.statusId ? "success" :
                                                                                        trustStatementReportShareStatus.TrustNotificationAccessNotProvided == r?.statusId ? "secondary" : "error"}
                                                                            icon={trustStatementReportShareStatus.UserSignupInvited == r?.statusId ? <EmailIcon /> :
                                                                                trustStatementReportShareStatus.SignedInProspectUser == r?.statusId ? <PendingIcon /> :
                                                                                    trustStatementReportShareStatus.TrustNotificationAccessProvided == r?.statusId ? <DoneAllIcon /> :
                                                                                        trustStatementReportShareStatus.TrustNotificationAccessNotProvided == r?.statusId ? <PersonOffIcon /> : <RemoveCircle />}
                                                                            size='small' variant="outlined" />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    id="Files_TablePagination"
                                                    rowsPerPageOptions={[20, 50, 100]}
                                                    component="div"
                                                    count={totalCount === undefined ? 0 : totalCount}
                                                    rowsPerPage={rowsPerPage}
                                                    style={{ borderTop: '1px solid lightgray' }}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                                />
                                            </Paper>
                                        </React.Fragment>
                                    </Grid>
                                    : null
                                }
                            </React.Fragment>
                        }
                    </Grid>
                </DialogContent>
            </Dialog >
        </React.Fragment >
    )
}

export default TrustSharedUserHistory