import {
    Backdrop, Box, Button, CardContent, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogContentText,
    FormControl, FormControlLabel, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, Snackbar,
    TextField, Theme, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
// Icons
import DoneAllIcon from '@mui/icons-material/DoneAll';
import agentactivity from '../../images/agentUser/agentactivity.svg';
import icon1 from '../../images/globalsetting/Icon1.svg';
import icon10 from '../../images/globalsetting/Icon10.svg';
import icon11 from '../../images/globalsetting/Icon11.svg';
import icon2 from '../../images/globalsetting/Icon2.svg';
import icon3 from '../../images/globalsetting/Icon3.svg';
import icon4 from '../../images/globalsetting/Icon4.svg';
import icon6 from '../../images/globalsetting/Icon6.svg';
import icon7 from '../../images/globalsetting/Icon7.svg';
import icon8 from '../../images/globalsetting/Icon8.svg';
import icon9 from '../../images/globalsetting/Icon9.svg';
import Template from '../../images/globalsetting/Template.png';
import emailgroup from '../../images/globalsetting/emailgroup.svg';
import keymanagement from '../../images/globalsetting/keymanagement.svg';
import timer from '../../images/session/Timer.svg';
import { GlobalDateTimeUtil } from '../../models/GlobalUtil/GlobalDateTimeUtil';
// Models
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { IGlobalUtil } from '../../models/GlobalUtil/IGlobalUtil';
import MultipleApi from '../../pages/multipleApi';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import Footer from '../Footer/Footer';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import AvatarTooltip from '../GlobalStyles/LightTooltipRight';
import AgentActivity from './GsettingAgentActivity/AgentActivity';
import ViewKeyManagement from './KeyManagement/ViewKeyManagement';
import GlobalMappingTemplate from './GlobalMappingTemplate/GlobalMappingTemplate';

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        width: '50%',
        textAlign: 'center',
        borderWidth: "5px",
        borderColor: "#007FFF !important",
        borderBottom: '4px solid blue',
        borderRadius: '10px',
        margin: '15px 0px',
        '@media screen and (max-width: 750px)': {
            width: '85%',
        }
    },
    button: {
        borderRadius: '20px',
        fontSize: '11px',
        padding: '4px 15px'
    },
    devMode: {
        margin: theme.spacing(2),
        width: 400,
        borderWidth: "5px",
        borderColor: "#007FFF !important",
        borderBottom: '4px solid blue',
        borderRadius: '10px'
    },
    yesButton: {
        background: "green",
        color: 'white',
        height: '25px',
        borderRadius: 20,
        right: '15px',
        '&:hover': {
            background: "green",
        }
    },
    noButton: {
        background: "red",
        height: '25px',
        borderRadius: 20,
        display: 'flex',
        right: '10px',
        '&:hover': {
            background: "red",
        }
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: '16px'
    },
    GridStyle: {
        borderRight: '1px solid gray',
        marginBottom: '10px',
        marginTop: '10px',
        justifyItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        display: 'flex',
        width: '70vw',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    dateFormatForWebview: {
        fontSize: '16px',
        textAlign: 'center'
    },
    divStyle1: {
        marginTop: '4cm',
        textAlign: 'center',
        '@media screen and (max-width: 950px)': {
            marginTop: '2cm',
        }
    },
    transformImage: {
        transform: 'rotateY(180deg)'
    },
    arrowIcon: {
        color: 'black',
        fontSize: 20
    },
    typographyForNote: {
        fontSize: '14px',
        fontWeight: 'bold',
        marginTop: '0.7cm',
    },
    spanStyle: {
        color: 'green',
        fontSize: '15px',
    },
    sidebarDivStyle: {
        marginTop: '4cm',
        textAlign: 'center',
        fontSize: '13px'
    },
    documentEmailStyle: {
        fontSize: '16px'
    },
    note1Style: {
        padding: '20px',
        fontSize: '14px'
    },
    beginReorderEmail: {
        marginTop: '0.5cm',
        fontSize: '16px'
    },
    settlementRequestEmail: {
        marginTop: '0.5cm',
        fontSize: '16px'
    },
    typographyStyle: {
        padding: '10px',
        marginTop: '0.7cm',
        fontSize: '14px'
    },
    uploadFileEmail: {
        marginTop: '0.5cm',
        fontSize: '16px'
    },
    confirmationMail: {
        marginTop: '0.5cm',
        fontSize: '16px'
    },
    unmappedFileEmail: {
        marginTop: '0.5cm',
        fontSize: '16px'
    },
    SupportEmail: {
        marginTop: '0.5cm',
        fontSize: '16px'

    },
    emailChangeStyle: {
        fontSize: '15px',
        marginTop: "20px"
    },
    emailChangeStyle1: {
        fontSize: '15px',
        marginTop: "10px"
    },
    BlockEmailStyle: {
        marginTop: '0.5cm',
        fontSize: '16px'
    },
    dateFormatStyle: {
        fontSize: '16px',
    },
    FormStyle: {
        fontSize: '16px',
        position: 'relative'
    },
    menuItem: {
        fontSize: '13px'
    },
    imageStyle: {
        textAlign: "center"
    },
    imageStyle1: {
        textAlign: "center",
        margin: '8px 0px',
    },
    spanTag: {
        fontSize: '15px'
    },
    imageDiv: {
        textAlign: "center",
        fontSize: '15px'
    },
    footer: {
        textAlign: 'center'
    },
    sidebar: {
        width: "300px",
        backgroundColor: 'white',
        transition: "all 0.2s ease",
        display: 'block',
        whiteSpace: 'nowrap',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        height: "calc(100vh - 100px)",
        borderLeft: '10px solid White',
        borderRight: '10px solid White'
    },
    sidebarItemExpandable: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '5px',
        transition: '0.2s',
        cursor: 'pointer',
        padding: '2px 12px',
        marginTop: '3px',
        height: 'auto',
        '&:hover': {
            backgroundColor: '#c8facd',
            color: '#00ab55'
        }
    },
    subsidebarItemExpandable: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '5px',
        transition: '0.2s',
        cursor: 'pointer',
        padding: '2px 3px',
        marginTop: '4px',
        marginRight: '8px',
        marginBottom: '3px',
        height: 'auto',
        '&:hover': {
            backgroundColor: '#c8facd',
            color: '#00ab55',
        }
    },
    sidebarItem: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '5px',
        transition: '0.2s',
        cursor: 'pointer',
        padding: '6px 10px',
        marginTop: '4px',
        height: 'auto',
        '&:hover': {
            backgroundColor: '#c8facd',
            color: '#00ab55'
        }
    },
    subsidebarItem: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '5px',
        transition: '0.2s',
        cursor: 'pointer',
        padding: '5px 7px',
        marginTop: '4px',
        marginRight: '8px',
        marginBottom: '4px',
        height: 'auto',
        '&:hover': {
            backgroundColor: '#c8facd',
            color: '#00ab55',
        }
    },
    sidebarCollapsed: {
        borderTopColor: 'white',
        width: "4rem",
        height: "calc(100vh - 100px)",
        backgroundColor: 'white',
        transition: "all 0.2s ease",
        display: 'block',
        whiteSpace: 'nowrap',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        borderLeft: '7px solid White',
        borderRight: '10px solid White'
    },
    textpropertyOut: {
        color: 'black',
        fontSize: '14px',
        paddingLeft: '16px',
        cursor: 'pointer',
    },
    textProperty: {
        visibility: 'hidden',
    },
    title: {
        color: "#474641",
        textAlign: "center",
        fontWeight: 'bold',
        fontSize: 40,
        verticalAlign: 'middle',
    },
    grid: {
        justifyItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        display: 'flex',
        height: '769px',
        backgroundColor: '#f3f4f6',
        boderLeft: '5px solid black'
    },
    emailfield: {
        justifyItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        display: 'flex',
    },
    paper1: {
        backgroundColor: 'white',
        border: '0px solid rgba(0, 0, 0, 0.125)',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
        transition: 'box-shadow 300ms',
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        height: '48vh',
        width: '100%',
    },
    dialogContentStyle: {
        fontWeight: 'bold',
        textAlign: 'left',
        marginTop: "15px",
        color: "black",
        fontSize: '16px'
    },
    countdown: {
        color: 'red',
        marginLeft: '5px'
    },
    image1: {
        marginTop: '0.2cm',
        width: '75px',
        height: '75px',
    },
    TitleStyle: {
        backgroundColor: '#007fff',
        color: 'white',
        borderRadius: '10px 10px 0px 0px',
        fontSize: '18px',
    },
    flexDispaly: {
        display: 'flex'
    },
    gridSpacing2: {
        margin: '60px 30px 0px'
    },
    flexDispalyDirection: {
        display: 'flex',
        '@media screen and (max-width: 750px)': {
            flexDirection: 'column',
        }
    },
    pageLayout: {
        maxHeight: 800,
        minHeight: 800,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 150px)',
            minHeight: 'calc( 100vh - 150px)',
        }
    },
    activeSidebar: {
        backgroundColor: '#c8facd',
        borderRadius: '5px'
    },
    sidebarScroll: {
        height: 'auto',
        marginLeft: '15px',
        marginRight: '10px'
    },
    sidebarScrollCollapsed: {
        height: 'auto',
        marginLeft: '6px'
    },
    emailGroup: {
        backgroundColor: '#a2cffe',
        borderRadius: 5,
        marginTop: '5px'
    },
    expandIcon: {
        backgroundColor: '#b0b0cd',
        padding: '2px',
        color: 'black',
        marginLeft: '60px',
        cursor: 'pointer',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px 0px',
        '&:hover': {
            backgroundColor: '#b0b0cd',
        }
    },
    iconImageExpand: {
        width: '25px',
        height: '25px'
    },
    iconImage: {
        width: '25px',
        height: '25px'
    }
}));

function GSettings() {
    const classes = useStyles();
    const { dispatch } = useGlobalState();
    const [GetGlobalSettings, setGetGlobalSettings] = useState([]);
    const [updateDate, setUpdateDate] = useState<string>('');
    const [UpdDocEmail, setUpdDocEmail] = useState<string>('');
    const [BeginEmail, setBeginEmail] = useState<string>('');
    const [SettlementEmail, setSettlementEmail] = useState<string>('');
    const [UpdFileEmail, setUpdFileEmail] = useState<string>('');
    const [SupportEmail, setSupportEmail] = useState<string>('');
    const [ClientConfirmationEmail, setClientConfirmationEmail] = useState<string>('');
    const [UnmappedFileEmail, setUnmappedFileEmail] = useState<string>('');
    const [UatEmail, setUatEmail] = useState<string>('');
    const [updClientDocEmail, setUpdClientDocEmail] = useState<string>('');
    const [blockEmail, setBlockEmail] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [isError, setIsError] = useState(false);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [activeTab, setActiveTab] = useState(0);
    const [HeaderName, setHeaderName] = useState<string>('');
    const [emailChange, setemailChange] = useState(14);
    const [isExpanded, setIsEpanded] = useState(true);
    const [seconds, setSeconds] = useState(10);
    const [EnableButton, setEnableButton] = React.useState(true);
    const [ProgressBar, setShowProgressBar] = useState(true);
    const [emailGroup, setEmailGroup] = useState(true);

    useEffect(() => {
        let interval = setInterval(() => {
            seconds > 0 && setSeconds(seconds => seconds - 1);
        }, 1000);
        if (seconds === 0) {
            setEnableButton(false);
        }
        return () => clearInterval(interval);
    }, [seconds])

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const UpdateMessage = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsCreate(false);
    };

    const ErrorMessage = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsError(false);
    };

    const handleClickCollapse = () => {
        setEmailGroup(!emailGroup);
    };

    const handleChangeDate = (event) => {
        setUpdateDate(event.target.value);
    };
    const handleClose = () => {
        setOpen(false);
        setEnableButton(true);
    };

    const handleOpenDialog = () => {
        setOpen(true);
        setEnableButton(true);
        setSeconds(10);
    }

    useEffect(() => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<IGlobalUtil[]>("GlobalSettings/GetAllSettings").then((GetAllGlobalSettings) => {
                setGetGlobalSettings(GetAllGlobalSettings.data);
                setUpdateDate(GetAllGlobalSettings.data[0].settingValue)
                setUpdDocEmail(GetAllGlobalSettings.data[2].settingValue);
                setBeginEmail(GetAllGlobalSettings.data[3].settingValue);
                setSettlementEmail(GetAllGlobalSettings.data[4].settingValue);
                setUpdFileEmail(GetAllGlobalSettings.data[6].settingValue);
                setSupportEmail(GetAllGlobalSettings.data[7].settingValue);
                setUatEmail(GetAllGlobalSettings.data[8].settingValue);
                setClientConfirmationEmail(GetAllGlobalSettings.data[9].settingValue);
                setUnmappedFileEmail(GetAllGlobalSettings.data[10].settingValue);
                setBlockEmail(GetAllGlobalSettings.data[8].is_Active);
                setUpdClientDocEmail(GetAllGlobalSettings.data[13].settingValue);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }, [])

    const updateDateSettings = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "programKey": 1,
                "value": updateDate
            }
            await usePost<any>("GlobalSettings/UpdateSettings", request).then((updateDateSettings) => {
                UpdateGlobalStateSettings();
                if (updateDateSettings.status === 202) {
                    setOpen(false);
                    setIsCreate(true);
                    setSeconds(10);
                }
                else {
                    setIsError(true);
                }
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    const updateDocEmailSettings = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "programKey": 3,
                "value": UpdDocEmail
            }
            await usePost<any>("GlobalSettings/UpdateSettings", request).then((updateDocEmailSettings) => {
                if (updateDocEmailSettings.status === 202) {
                    setOpen(false);
                    setIsCreate(true);
                }
                else {
                    setOpen(false);
                    setIsError(true);
                }
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    const updateClientDocEmailSettings = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "programKey": 15,
                "value": updClientDocEmail
            }
            await usePost<any>("GlobalSettings/UpdateSettings", request).then((updateDocEmailSettings) => {
                if (updateDocEmailSettings.status === 202) {
                    setOpen(false);
                    setIsCreate(true);
                }
                else {
                    setOpen(false);
                    setIsError(true);
                }
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    const updateBeginReorderEmailSettings = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "programKey": 4,
                "value": BeginEmail
            }
            await usePost<any>("GlobalSettings/UpdateSettings", request).then((BeginReorderEmailSettings) => {
                if (BeginReorderEmailSettings.status === 202) {
                    setOpen(false);
                    setIsCreate(true);
                }
                else {
                    setOpen(false);
                    setIsError(true);
                }
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    const updateSettlementEmailSettings = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "programKey": 5,
                "value": SettlementEmail
            }
            await usePost<any>("GlobalSettings/UpdateSettings", request).then((SettlementEmailSettings) => {
                if (SettlementEmailSettings.status === 202) {
                    setOpen(false);
                    setIsCreate(true);
                }
                else {
                    setOpen(false);
                    setIsError(true);
                }
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    const updateFileEmailSettings = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "programKey": 7,
                "value": UpdFileEmail
            }
            await usePost<any>("GlobalSettings/UpdateSettings", request).then((FileEmailSettings) => {
                if (FileEmailSettings.status === 202) {
                    setOpen(false);
                    setIsCreate(true);
                }
                else {
                    setOpen(false);
                    setIsError(true);
                }
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    const updateSupportEmailSettings = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "programKey": 8,
                "value": SupportEmail
            }
            await usePost<any>("GlobalSettings/UpdateSettings", request).then((SupportEmailSettings) => {
                if (SupportEmailSettings.status === 202) {
                    setOpen(false);
                    setIsCreate(true);
                }
                else {
                    setOpen(false);
                    setIsError(true);
                }
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    const updateUATEmail = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "programKey": 9,
                "value": UatEmail
            }
            await usePost<any>("GlobalSettings/UpdateSettings", request).then((UATEmail) => {
                BlockEmail();
                if (UATEmail.status === 202) {
                    setOpen(false);
                    setIsCreate(true);
                }
                else {
                    setOpen(false);
                    setIsError(true);
                }
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    const updateClientConfirmationEmail = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "programKey": 10,
                "value": ClientConfirmationEmail
            }
            await usePost<any>("GlobalSettings/UpdateSettings", request).then((ClientConfirmationEmail) => {
                if (ClientConfirmationEmail.status === 202) {
                    setOpen(false);
                    setIsCreate(true);
                }
                else {
                    setOpen(false);
                    setIsError(true);
                }
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    const updateUnmappedEmail = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "programKey": 12,
                "value": UnmappedFileEmail
            }
            dispatch({ type: GlobalStateAction.Busy });
            await usePost<any>("GlobalSettings/UpdateSettings", request).then((UnmappedEmail) => {
                if (UnmappedEmail.status === 202) {
                    setOpen(false);
                    setIsCreate(true);
                }
                else {
                    setOpen(false);
                    setIsError(true);
                }
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    const UpdateGlobalStateSettings = () => {
        (async () => {
            await useFetch<GlobalDateTimeUtil>("GlobalSettings/GetDateSettings").then((r) => {
                dispatch({
                    type: GlobalStateAction.GlobalUtils, GlobalUtils: {
                        enumKey: r.data.enumKey,
                        settingName: r.data.settingName,
                        settingValue: r.data.settingValue
                    }
                });
            })
        })()
    }

    const handleCheckboxChange = (event) => {
        setBlockEmail(event.target.checked)
    }

    const BlockEmail = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "activate": blockEmail
            };
            await usePost<any>("GlobalSettings/BlockEmailService", request);
            await useFetch<IGlobalUtil[]>("GlobalSettings/GetAllSettings").then((BlockEmailService) => {
                setGetGlobalSettings(BlockEmailService.data);
                setUatEmail(BlockEmailService.data[8].settingValue);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }

    const handleToggler = () => {
        setIsEpanded(s => !s);
    }

    return (
        <React.Fragment>
            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        {emailChange === 1 ? "Date Format"
                            : emailChange === 2 ? "Document Email Format"
                                : emailChange === 3 ? "Begin Reorder Email Format"
                                    : emailChange === 4 ? "Settlement Request Email Format"
                                        /*    : emailChange === 5 ? "Upload File Email Format" */
                                        : emailChange === 6 ? "Client Confirmation Email Format"
                                            : emailChange === 7 ? "Support Email Format"
                                                : emailChange === 8 ? "Unmapped File Email Format"
                                                    : emailChange === 10 ? "Block Email to Customers" : null}
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <DialogContentText>
                        <Box display='flex' alignItems='center' justifyContent='center'>
                            <img src={timer} alt="timer for Update global settings" className={classes.image1} />
                        </Box>
                        <Typography variant="h6" className={classes.dialogContentStyle} gutterBottom>
                            Are you sure, you want to update the {HeaderName.toLowerCase()}?
                            <span className={classes.countdown}>{seconds}</span> seconds.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    {activeTab == 0 ?
                        <Button id="DU_ok_btn" variant="contained" onClick={(e) => { updateDateSettings(); }} className={classes.yesButton} disabled={EnableButton}>
                            Yes
                        </Button>
                        : activeTab == 1 ?
                            <Button id="DU_ok_btn" variant="contained" onClick={(e) => { updateDocEmailSettings(); }} className={classes.yesButton} disabled={EnableButton}>
                                Yes
                            </Button>
                            : activeTab == 2 ?
                                <Button id="DU_ok_btn" variant="contained" onClick={(e) => { updateBeginReorderEmailSettings(); }} className={classes.yesButton} disabled={EnableButton}>
                                    Yes
                                </Button>
                                : activeTab == 3 ?
                                    <Button id="DU_ok_btn" variant="contained" onClick={(e) => { updateSettlementEmailSettings(); }} className={classes.yesButton} disabled={EnableButton}>
                                        Yes
                                    </Button>
                                    : activeTab == 4 ?
                                        <Button id="DU_ok_btn" variant="contained" onClick={(e) => { updateFileEmailSettings(); }} className={classes.yesButton} disabled={EnableButton}>
                                            Yes
                                        </Button>
                                        : activeTab == 5 ?
                                            <Button id="DU_ok_btn" variant="contained" onClick={(e) => { updateSupportEmailSettings(); }} className={classes.yesButton} disabled={EnableButton}>
                                                Yes
                                            </Button>
                                            : activeTab == 6 ?
                                                <Button id="DU_ok_btn" variant="contained" onClick={(e) => { updateClientConfirmationEmail(); }} className={classes.yesButton} disabled={EnableButton}>
                                                    Yes
                                                </Button>
                                                : activeTab == 7 ?
                                                    <Button id="DU_ok_btn" variant="contained" onClick={(e) => { updateUnmappedEmail(); }} className={classes.yesButton} disabled={EnableButton}>
                                                        Yes
                                                    </Button>
                                                    : activeTab == 8 ?
                                                        <Button id="DU_ok_btn" variant="contained" onClick={(e) => { updateUATEmail(); }} className={classes.yesButton} disabled={EnableButton}>
                                                            Yes
                                                        </Button>
                                                        : activeTab == 9 ?
                                                            <Button id="DU_ok_btn" variant="contained" onClick={(e) => { updateClientDocEmailSettings(); }} className={classes.yesButton} disabled={EnableButton}>
                                                                Yes
                                                            </Button>
                                                            : null
                    }
                    <Button id="DU_Cancel_btn" variant="contained" autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <div className={classes.flexDispaly}>
                <div className={`${isExpanded ? classes.sidebar : classes.sidebarCollapsed}`}>
                    <div className={classes.flexDispaly}>
                        <IconButton size="medium" onClick={handleToggler} style={{ padding: 4 }}>
                            {
                                isExpanded ?
                                    <DoubleArrowIcon fontSize="inherit" className={`${classes.transformImage} ${classes.arrowIcon}`} />
                                    :
                                    <DoubleArrowIcon fontSize="inherit" className={classes.arrowIcon} />
                            }
                        </IconButton>
                    </div>
                    <div className={`${!isExpanded ? classes.sidebarItemExpandable : classes.sidebarItem} ${emailChange == 14 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setemailChange(14)} >
                        <AvatarTooltip title="Manage Agent Activity">
                            <img src={keymanagement} alt="Icon for agent activity" className={`${!isExpanded ? classes.iconImageExpand : classes.iconImage}`} onClick={(_) => setemailChange(14)} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setemailChange(14)}>
                            Manage API Keys
                        </Typography>
                    </div>
                    <div className={`${!isExpanded ? classes.sidebarItemExpandable : classes.sidebarItem} ${emailChange == 13 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setemailChange(13)} >
                        <AvatarTooltip title="Global Template">
                            <img src={Template} alt="Icon for agent activity" width="32px" height="32px" onClick={(_) => setemailChange(13)} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setemailChange(13)}>
                            Global Template
                        </Typography>
                    </div>
                    <div className={`${!isExpanded ? classes.sidebarItemExpandable : classes.sidebarItem} ${emailChange == 11 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setemailChange(11)} >
                        <AvatarTooltip title="Manage Agent Activity">
                            <img src={agentactivity} alt="Icon for agent activity" className={`${!isExpanded ? classes.iconImageExpand : classes.iconImage}`} onClick={(_) => setemailChange(11)} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setemailChange(11)}>
                            Manage Agent Activity
                        </Typography>
                    </div>
                    <div className={`${!isExpanded ? classes.sidebarItemExpandable : classes.sidebarItem} ${emailChange == 1 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setemailChange(1)} >
                        <AvatarTooltip title="Change Date Format">
                            <img src={icon1} alt="Icon for change date format" className={`${!isExpanded ? classes.iconImageExpand : classes.iconImage}`} onClick={(_) => setemailChange(1)} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setemailChange(1)}>
                            Change Date Format
                        </Typography>
                    </div>
                    <div className={`${!isExpanded ? classes.sidebarItemExpandable : classes.sidebarItem} ${emailChange == 10 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setemailChange(10)} >
                        <AvatarTooltip title="Block Email to Customer">
                            <img src={icon10} alt="Icon for block email customer" className={`${!isExpanded ? classes.iconImageExpand : classes.iconImage}`} onClick={(_) => setemailChange(10)} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setemailChange(10)}>
                            Block Email to Customer
                        </Typography>
                    </div>

                    <div className={`${!isExpanded ? classes.sidebarItemExpandable : classes.sidebarItem} ${emailChange == 2 ? classes.activeSidebar : null} ${"ripple"}`} onClick={handleClickCollapse} >
                        <AvatarTooltip title="Notification Emails">
                            <img src={emailgroup} alt="Icon for Notification Emails" className={`${!isExpanded ? classes.iconImageExpand : classes.iconImage}`} onClick={handleClickCollapse} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={handleClickCollapse}>
                            <span>Notification Emails </span>
                        </Typography>
                        {isExpanded ?
                            <IconButton className={classes.expandIcon}>
                                {emailGroup ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                            :
                            null
                        }
                    </div>
                    <Collapse in={emailGroup} timeout="auto" unmountOnExit className={classes.emailGroup}>
                        <div className={`${isExpanded ? classes.sidebarScroll : classes.sidebarScrollCollapsed} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <div className={`${!isExpanded ? classes.subsidebarItemExpandable : classes.subsidebarItem} ${emailChange == 2 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setemailChange(2)} >
                                <AvatarTooltip title="Document Upload Email">
                                    <img src={icon2} alt="Icon for Document upload email" onClick={(_) => setemailChange(2)} className={`${!isExpanded ? classes.iconImageExpand : classes.iconImage}`} />
                                </AvatarTooltip>
                                <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setemailChange(2)}>
                                    Document Upload Email
                                </Typography>
                            </div>

                            <div className={`${!isExpanded ? classes.subsidebarItemExpandable : classes.subsidebarItem} ${emailChange == 3 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setemailChange(3)} >
                                <AvatarTooltip title="Begin Reorder Email">
                                    <img src={icon3} alt="Icon for begin reorder email" className={`${!isExpanded ? classes.iconImageExpand : classes.iconImage}`} onClick={(_) => setemailChange(3)} />
                                </AvatarTooltip>
                                <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setemailChange(3)}>
                                    Begin Reorder Email
                                </Typography>
                            </div>

                            <div className={`${!isExpanded ? classes.subsidebarItemExpandable : classes.subsidebarItem} ${emailChange == 4 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setemailChange(4)} >
                                <AvatarTooltip title="Settlement Email">
                                    <img src={icon4} alt="Icon for settlement email" className={`${!isExpanded ? classes.iconImageExpand : classes.iconImage}`} onClick={(_) => setemailChange(4)} />
                                </AvatarTooltip>
                                <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setemailChange(4)}>
                                    Settlement Email
                                </Typography>
                            </div>

                            <div className={`${!isExpanded ? classes.subsidebarItemExpandable : classes.subsidebarItem} ${emailChange == 6 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setemailChange(6)} >
                                <AvatarTooltip title="Client Confirmation Email">
                                    <img src={icon6} alt="Icon for client conformation email" className={`${!isExpanded ? classes.iconImageExpand : classes.iconImage}`} onClick={(_) => setemailChange(6)} />
                                </AvatarTooltip>
                                <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setemailChange(6)}>
                                    Client Confirmation Email
                                </Typography>
                            </div>

                            <div className={`${!isExpanded ? classes.subsidebarItemExpandable : classes.subsidebarItem} ${emailChange == 7 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setemailChange(7)} >
                                <AvatarTooltip title="Support Email">
                                    <img src={icon7} alt="Icon for support email" className={`${!isExpanded ? classes.iconImageExpand : classes.iconImage}`} onClick={(_) => setemailChange(7)} />
                                </AvatarTooltip>
                                <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setemailChange(7)}>
                                    Support Email
                                </Typography>
                            </div>

                            <div className={`${!isExpanded ? classes.subsidebarItemExpandable : classes.subsidebarItem} ${emailChange == 8 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setemailChange(8)} >
                                <AvatarTooltip title="Upload Unmapped File Email">
                                    <img src={icon8} alt="Icon for unmapped file email" className={`${!isExpanded ? classes.iconImageExpand : classes.iconImage}`} onClick={(_) => setemailChange(8)} />
                                </AvatarTooltip>
                                <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setemailChange(8)}>
                                    Upload Unmapped File Email
                                </Typography>
                            </div>
                        </div>
                    </Collapse>

                    <div className={`${classes.sidebarItem} ${emailChange == 9 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setemailChange(9)} >
                        <AvatarTooltip title="Unit Test">
                            <img src={icon9} alt="Icon for unit test" className={`${!isExpanded ? classes.iconImageExpand : classes.iconImage}`} onClick={(_) => setemailChange(9)} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setemailChange(9)}>
                            Unit Test
                        </Typography>
                    </div>
                </div>

                <Grid container spacing={0}>
                    <Grid xs={12} className={classes.pageLayout}>
                        <div className={classes.flexDispalyDirection}>
                            {emailChange == 1 ?
                                <Grid lg={7} xs={12}>
                                    <div className={classes.divStyle1}>
                                        <Typography variant="h4" className={classes.dateFormatForWebview}>
                                            <b>Global Date format for WebView</b>
                                        </Typography>
                                        <FormControl variant="outlined" size="small" className={classes.formControl}>
                                            <InputLabel htmlFor="outlined-age-native-simple">Date Format</InputLabel>
                                            <Select native
                                                value={updateDate}
                                                id="Gsettings_Date_DropDown"
                                                label="Date Format"
                                                name={GetGlobalSettings[0]?.settingName}
                                                onChange={(event) => { handleChangeDate(event); }}
                                            >
                                                <option value={"DD/MM/YYYY"}>DD/MM/YYYY</option>
                                                <option value={"MM/DD/YYYY"}>MM/DD/YYYY</option>
                                                <option value={"YYYY/MM/DD"}>YYYY/MM/DD</option>
                                            </Select>
                                        </FormControl>
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                            <Button variant="contained" startIcon={<DoneAllIcon />} color="primary" className={classes.button}
                                                onClick={(e) => { setActiveTab(0); setHeaderName("UPDATE DATE SETTINGS"); handleOpenDialog(); }} >
                                                Update
                                            </Button>
                                        </Box>
                                        <Typography className={classes.typographyForNote}>
                                            <span className={classes.spanStyle}><b><u>NOTE</u></b></span>
                                            &nbsp; : By updating Date format, specified format will be reflected all over the webview.
                                        </Typography>
                                    </div>
                                </Grid>
                                : emailChange == 2 ?
                                    <Grid lg={7} xs={12}>
                                        <div className={classes.sidebarDivStyle}>
                                            <Typography variant="h4" className={classes.documentEmailStyle}>
                                                <b>Override Document Email</b>
                                            </Typography>
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <Typography variant="h6" className={classes.FormStyle}>
                                                    From:
                                                </Typography>
                                                <TextField label="Document Upload Email" size="small" name={GetGlobalSettings[2]?.settingName} value={UpdDocEmail} variant="outlined"
                                                    className={classes.formControl} onChange={(e) => setUpdDocEmail(e.target.value)} />
                                            </Box>
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <Button variant="contained" startIcon={<DoneAllIcon />} color="primary" className={classes.button}
                                                    onClick={(e) => { setActiveTab(1); setHeaderName("UPDATE DOCUMENT EMAIL "); handleOpenDialog(); }} >
                                                    Update
                                                </Button>
                                            </Box>
                                            <Typography variant="h6" className={classes.note1Style}>
                                                <span className={classes.spanStyle}><b><u>NOTE</u></b></span>
                                                &nbsp; : When a user upload a document from (Inquiry, Roll, Report, Notification Center) in the webview, this email will be triggered.
                                            </Typography>
                                        </div>
                                    </Grid>
                                    : emailChange == 3 ?
                                        <Grid lg={7} xs={12}>
                                            <div className={classes.sidebarDivStyle}>
                                                <Typography variant="h4" className={classes.beginReorderEmail}>
                                                    <b>Override Begin Reorder Email</b>
                                                </Typography>
                                                <Box display="flex" justifyContent="center" alignItems="center">
                                                    <Typography variant="h6" className={classes.FormStyle}>
                                                        To:
                                                    </Typography>
                                                    <TextField label="Begin Reorder Email" size="small" variant="outlined" name={GetGlobalSettings[3]?.settingName} value={BeginEmail}
                                                        className={classes.formControl} onChange={(e) => setBeginEmail(e.target.value)} />
                                                </Box>
                                                <Box display="flex" justifyContent="center" alignItems="center">
                                                    <Button variant="contained" startIcon={<DoneAllIcon />} color="primary" className={classes.button}
                                                        onClick={(e) => { setActiveTab(2); setHeaderName("UPDATE DOCUMENT EMAIL "); handleOpenDialog(); }}>
                                                        Update
                                                    </Button>
                                                </Box>
                                            </div>
                                            <Typography variant="h6" className={classes.note1Style}>
                                                <span className={classes.spanStyle}><b><u>NOTE</u></b></span>
                                                &nbsp; : This is the Email Format for Support Email
                                            </Typography>
                                        </Grid>
                                        : emailChange == 4 ?
                                            <Grid lg={7} xs={12}>
                                                <div className={classes.sidebarDivStyle}>
                                                    <Typography variant="h4" className={classes.settlementRequestEmail}>
                                                        <b>Override Settlement Request Email</b>
                                                    </Typography>
                                                    <Box display="flex" justifyContent="center" alignItems="center">
                                                        <Typography variant="h6" className={classes.FormStyle} >
                                                            To:
                                                        </Typography>
                                                        <TextField label="Settlement Request Email" size="small" variant="outlined" className={classes.formControl}
                                                            name={GetGlobalSettings[4]?.settingName} value={SettlementEmail} onChange={(e) => setSettlementEmail(e.target.value)} />
                                                    </Box>
                                                    <Box display="flex" justifyContent="center" alignItems="center">
                                                        <Button variant="contained" color="primary" startIcon={<DoneAllIcon />} className={classes.button}
                                                            onClick={(e) => { setActiveTab(3); setHeaderName("UPDATE SETTLEMENT REQUEST EMAIL "); handleOpenDialog(); }} >
                                                            Update
                                                        </Button>
                                                    </Box>
                                                    <Typography variant="h6" className={classes.typographyStyle}>
                                                        <span className={classes.spanStyle}><b><u>NOTE</u></b></span>
                                                        &nbsp; : When a user is Willing to Accept / Reject / Sent Counter Offer for settlement request notification from Notification Center screen, this email will be triggered.
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            : emailChange == 5 ?
                                                <Grid lg={7} xs={12}>
                                                    <div className={classes.sidebarDivStyle}>
                                                        <Typography variant="h4" className={classes.uploadFileEmail}>
                                                            <b> Override Upload File Email </b>
                                                        </Typography>
                                                        <Box display="flex" justifyContent="center" alignItems="center">
                                                            <Typography variant="h6" className={classes.FormStyle}>
                                                                To:
                                                            </Typography>
                                                            <TextField label="Upload File Email" size="small" variant="outlined" className={classes.formControl} name={GetGlobalSettings[6]?.settingName} value={UpdFileEmail} onChange={(e) => setUpdFileEmail(e.target.value)} />
                                                        </Box>
                                                        <Box display="flex" justifyContent="center" alignItems="center">
                                                            <Button variant="contained" startIcon={<DoneAllIcon />} color="primary" className={classes.button}
                                                                onClick={(e) => { setActiveTab(4); setHeaderName("UPDATE UPLOAD FILE EMAIL "); handleOpenDialog(); }} >
                                                                Update
                                                            </Button>
                                                        </Box>
                                                        <Typography variant="h6" className={classes.typographyStyle}>
                                                            <span className={classes.spanStyle}><b><u>NOTE</u></b></span>
                                                            &nbsp; : When a user upload a document to a specified folder from Manage File Access screen, this email will be triggered.
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                : emailChange == 6 ?
                                                    <Grid lg={7} xs={12}>
                                                        <div className={classes.sidebarDivStyle}>
                                                            <Typography variant="h4" className={classes.confirmationMail}>
                                                                <b>Override Client Confirmation Email</b>
                                                            </Typography>
                                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                                <Typography variant="h6" className={classes.FormStyle} >
                                                                    To:
                                                                </Typography>
                                                                <TextField label="Client Confirmation Email" size="small" variant="outlined" className={classes.formControl} name="OverrideClientConfirmationEmail"
                                                                    value={ClientConfirmationEmail} onChange={(e) => setClientConfirmationEmail(e.target.value)} />
                                                            </Box>
                                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                                <Button variant="contained" startIcon={<DoneAllIcon />} color="primary" className={classes.button}
                                                                    onClick={(e) => { setActiveTab(6); setHeaderName("UPDATE CLIENT CONFIRMATION EMAIL "); handleOpenDialog(); }} >
                                                                    Update
                                                                </Button>
                                                            </Box>
                                                            <Typography variant="h6" className={classes.typographyStyle}>
                                                                <span className={classes.spanStyle}><b><u>NOTE</u></b></span>
                                                                &nbsp;: When a user would like to confirm a client from Client Confirmation screen, this email will be triggered.
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    : emailChange == 7 ?
                                                        <Grid lg={7} xs={12}>
                                                            <div className={classes.sidebarDivStyle}>
                                                                <Typography variant="h4" className={classes.SupportEmail}>
                                                                    <b> Override Upload Support Email </b>
                                                                </Typography>
                                                                <Box display="flex" justifyContent="center" alignItems="center">
                                                                    <Typography variant="h6" className={classes.FormStyle} >
                                                                        From:
                                                                    </Typography>
                                                                    <TextField label="Upload Support Email" size="small" variant="outlined" className={classes.formControl} name="OverrideSupportEmail"
                                                                        value={SupportEmail} onChange={(e) => setSupportEmail(e.target.value)} />
                                                                </Box>
                                                                <Box display="flex" justifyContent="center" alignItems="center">
                                                                    <Button variant="contained" startIcon={<DoneAllIcon />} color="primary" className={classes.button}
                                                                        onClick={(e) => { setActiveTab(5); setHeaderName("UPDATE SUPPORT EMAIL "); handleOpenDialog(); }}>
                                                                        Update
                                                                    </Button>
                                                                </Box>
                                                                <Typography variant="h6" className={classes.typographyStyle}>
                                                                    <span className={classes.spanStyle}><b><u>NOTE</u></b></span>
                                                                    &nbsp;: This is the Email Format for Support Email.
                                                                </Typography>
                                                            </div>
                                                        </Grid>
                                                        : emailChange == 8 ?
                                                            <Grid lg={7} xs={12}>
                                                                <div className={classes.sidebarDivStyle}>
                                                                    <Typography variant="h4" className={classes.unmappedFileEmail}>
                                                                        <b>Override Upload Unmapped File Email</b>
                                                                    </Typography>
                                                                    <Box display="flex" justifyContent="center" alignItems="center">
                                                                        <Typography variant="h6" className={classes.FormStyle} >
                                                                            To:
                                                                        </Typography>
                                                                        <TextField label="Upload Unmapped File Email" size="small" variant="outlined" className={classes.formControl} name="OverrideUnmappedFileEmail"
                                                                            value={UnmappedFileEmail} onChange={(e) => setUnmappedFileEmail(e.target.value)} />
                                                                    </Box>
                                                                    <Box display="flex" justifyContent="center" alignItems="center">
                                                                        <Button variant="contained" startIcon={<DoneAllIcon />} color="primary" className={classes.button}
                                                                            onClick={(e) => { setActiveTab(7); setHeaderName("UPDATE UNMAPPED FILE EMAIL "); handleOpenDialog(); }} >
                                                                            Update
                                                                        </Button>
                                                                    </Box>
                                                                    <Typography variant="h6" className={classes.typographyStyle}>
                                                                        <span className={classes.spanStyle}><b><u>NOTE</u></b></span>
                                                                        &nbsp;: When a user would like to upload a csv document from Placement screen this email will be triggered.
                                                                    </Typography>
                                                                </div>
                                                            </Grid>
                                                            : emailChange == 9 ?
                                                                <Grid lg={12} xs={12}>
                                                                    <MultipleApi />
                                                                </Grid>
                                                                : emailChange == 10 ?
                                                                    <Grid lg={7} xs={12}>
                                                                        <div className={classes.sidebarDivStyle}>
                                                                            <Typography variant="h4" className={classes.BlockEmailStyle}>
                                                                                <b>Block Email to Customers</b>
                                                                            </Typography>
                                                                            <Box display="flex" alignContent="center" justifyContent="center" >
                                                                                <FormControlLabel
                                                                                    id="GS_UAT_MODE_Checkbox"
                                                                                    control={<Checkbox
                                                                                        checked={blockEmail}
                                                                                        value={GetGlobalSettings[8]?.is_Active}
                                                                                        onChange={handleCheckboxChange}
                                                                                        name="checked"
                                                                                        color="primary" />}
                                                                                    label="Activate"
                                                                                />
                                                                                <TextField label="Block With Temporary Test Email-ID" size="small" variant="outlined" className={classes.devMode} name="OverrideUploadFileToEmail"
                                                                                    value={UatEmail} onChange={(e) => setUatEmail(e.target.value)} />
                                                                            </Box>
                                                                            <Box display="flex" alignContent="center" justifyContent="center" >
                                                                                <Button variant="contained" color="primary" startIcon={<DoneAllIcon />} className={classes.button} onClick={(e) => { setActiveTab(8); setHeaderName("UPDATE UPLOAD FILE TO EMAIL "); handleOpenDialog(); }}>
                                                                                    Update
                                                                                </Button>
                                                                            </Box>
                                                                            <Typography variant="h6" className={classes.typographyStyle}>
                                                                                <span className={classes.spanStyle}><b><u>NOTE</u></b></span>
                                                                                &nbsp;:  This is the Email Format for Block Email to Customers.
                                                                            </Typography>
                                                                        </div>
                                                                    </Grid>
                                                                    : emailChange == 11 ?
                                                                        <AgentActivity />
                                                                        : emailChange == 14 ?
                                                                            <ViewKeyManagement />
                                                                            : emailChange == 13 ?
                                                                                <GlobalMappingTemplate />
                                                                                : null}
                            {emailChange !== 11 && emailChange !== 9 && emailChange !== 12 && emailChange !== 13 && emailChange !== 14 ?
                                <Grid className={classes.gridSpacing2} lg={5} xs={12}>
                                    <div>
                                        <Typography variant="h6" align="center" display="block" className={classes.TitleStyle}>
                                            <b>{emailChange === 1 ? "Date Format"
                                                : emailChange === 2 ? "Document Email Format"
                                                    : emailChange === 3 ? "Begin Reorder Email Format"
                                                        : emailChange === 4 ? "Settlement Request Email Format"
                                                            : emailChange === 5 ? "Upload File Email Format"
                                                                : emailChange === 6 ? "Client Confirmation Email Format"
                                                                    : emailChange === 7 ? "Support Email Format"
                                                                        : emailChange === 8 ? "Unmapped File Email Format"
                                                                            : emailChange === 10 ? "Block Email to Customers" : null}</b>
                                        </Typography>
                                        <CardContent className={classes.paper1}>
                                            <Typography variant="body1" align="justify" gutterBottom className={classes.dateFormatStyle}>
                                                {emailChange === 1 ? <React.Fragment><div className={classes.imageStyle}><img alt="date_format" src={icon1} width="75px" height="75px" /></div><div className={classes.imageDiv}><p>DD/MM/YYYY : {`${moment(Date.now()).format('DD/MM/YYYY')}`}</p> <p>MM/DD/YYYY : {`${moment(Date.now()).format('MM/DD/YYYY')}`}</p> <p>YYYY/MM/DD : {`${moment(Date.now()).format('YYYY/MM/DD')}`}</p></div></React.Fragment>
                                                    : emailChange === 2 ? <span className={classes.spanTag}>A document has been uploaded to the account below. To view the document please navigate to the A.R.M. Solutions Web Portal located at https://webviewrp.armsolutions.com and look up the account using the search bar.</span>
                                                        : emailChange === 3 ? <span className={classes.spanTag}>Client Name: sample <br /><br /> Client ID: ARMSOL <br /><br /> Message: Test <br /><br /> (to respond, you may reply directly to this email) </span>
                                                            : emailChange === 4 ? <span className={classes.spanTag}>Sample@sample.com has Rejected/Accepted/Sent Counter Offer settlement offer and below are the details:</span>
                                                                : emailChange === 5 ? <span className={classes.spanTag}>A document has been uploaded to the folder "Test Folder". To view the document please navigate to the https://webviewrp.armsolutions.com and look up in Manage File Access menu. <p>Client ID: ARMSOL</p><p>Filename: TestFile.pdf</p></span>
                                                                    : emailChange === 6 ? <span className={classes.spanTag}>Client Services, User has confirmed that the following Client Setup information is accurate: <p> ARMSOL - PREC{`(${moment(Date.now()).format('MM/DD/YYYY')})`}</p></span>
                                                                        : emailChange === 7 ? <span className={classes.spanTag}>This Email is triggered from Azure Active Directory when a user is created from user screen.</span>
                                                                            : emailChange === 8 ? <span style={{ fontSize: '15px' }}><p>Hello,</p><p>You are receiving this email on behalf of A.R.M. Solutions to confirm your file upload was successfully received.  We will be reviewing it shortly.</p><p>Thanks,<br />A.R.M. Solutions</p></span>
                                                                                : emailChange === 10 ? <React.Fragment><div className={classes.imageStyle1}><img src={icon10} alt="block_email_customer" width="65px" height="65px" /></div><p>Caution:  By activating 'Block Email to Customers' feature all the Emails will be sent to ({UatEmail}) only. None others shall receive any Emails!</p></React.Fragment>
                                                                                    : null
                                                }
                                            </Typography>
                                            <Typography variant="h6" align="left" className={classes.emailChangeStyle} >
                                                {emailChange === 2 ? "Agency Account #:123456789" : emailChange === 4 ? `Notification Date: ${moment(Date.now()).format('MM/DD/YYYY')}` : null}
                                            </Typography>
                                            <Typography variant="h6" align="left" className={classes.emailChangeStyle1} >
                                                {emailChange === 2 ? "Client Account #: 12346789" : emailChange === 4 ? "Debtor no: 123456789" : null}
                                            </Typography>
                                            <Typography variant="h6" align="left" className={classes.emailChangeStyle1}>
                                                {emailChange === 2 ? "Client ID : ARMSOL" : emailChange === 4 ? "Original Offer: $10 discount" : null}
                                            </Typography>
                                            <Typography variant="h6" align="left" className={classes.emailChangeStyle1}>
                                                {emailChange === 2 ? "File Name: TestFile.pdf" : emailChange === 4 ? "Counter Offer: $15" : null}
                                            </Typography>
                                            <Typography variant="h6" align="left" className={classes.emailChangeStyle}>
                                                {emailChange === 2 || emailChange === 5 || emailChange === 6 ? <p>Thank You.</p> : null}
                                            </Typography>
                                            <Typography variant="h6" align="left" className={classes.emailChangeStyle1}>
                                                {emailChange === 3 || emailChange === 4 ? <React.Fragment><p>Thank You,<br /> sample@sample.com.</p></React.Fragment> : null}
                                            </Typography>
                                        </CardContent>
                                    </div>
                                </Grid>
                                : null}
                        </div>
                    </Grid >
                    {emailChange === 13 ? null : <Footer />}
                </Grid>
            </div>
            <Snackbar className="snackBarStyle" open={isCreate} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={UpdateMessage}>
                <Alert onClose={UpdateMessage} severity="success" className="alertStyle">
                    Settings Updated Successfully.
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={isError} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={ErrorMessage}>
                <Alert onClose={ErrorMessage} severity="error" className="alertStyle">
                    Error Occurred
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}
export default GSettings