import {
  Avatar,
  Box, Checkbox, Grid, IconButton, InputAdornment, makeStyles, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography
} from '@material-ui/core';
// Components
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
//Modules
import Footer from '../../components/Footer/Footer';
import AvatarTooltip from '../../components/GlobalStyles/AvatarTooltip';
import StyledCell from "../../components/GlobalStyles/StyledCell";
import CreateUser from "../../components/Settings/CreateUser";
import DeleteUser from "../../components/Settings/DeleteUser";
import EditUser from "../../components/Settings/EditUser";
import InviteUsersforCA from "../../components/Settings/InviteUsersforCA";
//Image Icon
import GlobalAdmin from '../../images/users/ClientAdmin.svg';
import FileAdmin from '../../images/users/FileAdmin.svg';
import ClientAdmin from '../../images/users/GlobalAdmin.svg';
import RegularUser from '../../images/users/RegularUser.svg';
import SuperAdmin from '../../images/users/SuperAdmin.svg';
//Models
import { createStyles, Theme } from '@material-ui/core/styles';
import { IUserInfo } from '../../models/accesscontrol/IUserInfo';
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    clientAdmin: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    globalAdmin: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    superAdminColor: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    regularUser: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid black',
    },
    fileAdmin: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: "100%",
      boxShadow: "inset 0px 0px 0px 5px white",
      border: '2px solid gray',
    },
    table: {
      minWidth: 700,
    },
    grid: {
      padding: '10px'
    },
    div: {
      flexGrow: 1,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    searchBox: {
      marginTop: '2px',
      borderRadius: "30px",
      border: '2px solid #215176',
      height: '38px',
      textIndent: '10px',
      width: '100%',
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    },
    progressBar: {
      height: '6px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    TextHighlight: {
      color: 'black',
      fontWeight: 'bold',
      background: "yellow",
      fontSize: 14,
    },
    TextNormal: {
      color: 'black',
      fontSize: 14
    },
    tablebody: {
      maxHeight: 490,
      minHeight: 490,
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 215px)',
        minHeight: 'calc( 100vh - 215px)',
      },
    },
    CheckBoxStyle: {
      backgroundColor: '#007FFF',
      color: "white"
    },
    TableCellStyle: {
      background: "#007FFF",
      color: "white",
      fontSize: 12,
      padding: '2px 10px'
    },
    label: {
      '&$focused': {
        color: 'white',
        border: '0px'
      },
    },
    outlinedInput: {
      fontSize: 15,
      color: "black",
      marginTop: '-3px',
      height: '38px',
      '&$focused $notchedOutline': {
        color: 'white',
        border: '0px'
      },
    },
    notchedOutline: {
      color: 'white',
      border: '0px'
    },
    ErrorMessageStyle: {
      color: 'red',
      marginTop: '10px',
      fontSize: 18,
      fontWeight: 'bold'
    },
    textStyle: {
      marginTop: '5px',
      marginLeft: '10px',
      float: 'left',
      fontSize: 22
    },
    pagination: {
      backgroundColor: '#F5F5F5',
      border: '2px solid #ECECEC'
    },
    footer: {
      marginTop: '15px',
      marginRight: '140px'
    },
  })
);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    onSelectAllClick,
    numSelected, rowCount
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: "", disablePadding: false, label: "", sortable: false },
    { id: "firstName", disablePadding: false, label: "FIRST NAME", sortable: true },
    { id: "lastName", disablePadding: false, label: "LAST NAME", sortable: true },
    { id: "userName", disablePadding: false, label: "EMAIL", sortable: true },
    { id: "", disablePadding: false, label: "", sortable: false },
    { id: "", disablePadding: false, label: "ACTIONS", sortable: false },
    { id: "", disablePadding: false, label: "DELETE", sortable: false },
  ]

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"
          className={classes.CheckBoxStyle}>
          <Checkbox style={{ color: 'white' }}
            size="small"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all Users' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false} className={classes.TableCellStyle}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label || headCell.sortable}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function UserAccessControl() {
  const classes = useRowStyles();
  const { state } = useGlobalState();
  const [usersList, setUsersList] = useState<IUserInfo[]>([])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("userName");
  const [searchText, setSearchText] = useState<string>('')
  const [selected, setSelected] = React.useState<number[]>([]);
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [showSearchIcon, setShowSearchIcon] = useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = usersList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    (newSelected);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSaveWithUserInfo = (userInfo: IUserInfo) => {
    getUsersAsync();
  };

  const handleSave = () => {
    getUsersAsync();
  };

  const handleDeleteSuccess = () => {
    getUsersAsync();
  };

  const HandleSearch = () => {
    getUsersAsync();
    setPage(0);
  }

  useEffect(GetUsers(), [])

  function GetUsers(): React.EffectCallback {
    return () => {
      getUsersAsync();
    };
  }

  function getUsersAsync() {
    (async () => {
      setshowProgressBar(true);
      let request = {
        "clients": state.userAccessContext?.clientCodes.join(","),
        "searchParameters": searchText?.trimLeft()?.trimRight()
      }
      await usePost<IUserInfo[]>("User/UsersForClientAdmin", request).then((UsersForClientAdmin) => {
        setUsersList(UsersForClientAdmin.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })();
  }

  function getUserInitials(name: string): string {
    const bySpace = name?.split(' ')
    if (bySpace.length > 1) {
      return bySpace[0][0] + bySpace[1][0]
    } else return name.slice(0, 2).toUpperCase()
  }

  const cancelSearch = () => {
    setshowProgressBar(true);
    setSearchText("");
    setShowSearchIcon(true);
    getUsersAsyncClear();
  }

  function getUsersAsyncClear() {
    (async () => {
      let request = {
        "clients": state.userAccessContext?.clientCodes.join(","),
        "searchParameters": ""
      }
      await usePost<IUserInfo[]>("User/UsersForClientAdmin", request).then((UsersForClientAdmin) => {
        setUsersList(UsersForClientAdmin.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })();
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container spacing={0} className={classes.grid} wrap="wrap">
          <Grid item xs={12} sm={2} >
            <Typography variant="h5" gutterBottom className={`${classes.textStyle} ${"headertitle"}`}>
              <b> MANAGE USERS </b>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} >
            <TextField value={searchText} variant="outlined"
              type='text' placeholder="Search Users"
              className={classes.searchBox}
              onChange={e => setSearchText(e.target.value)}
              inputProps={{ maxLength: 255 }}
              onKeyPress={event => {
                if (event.key === 'Enter' && searchText != "") {
                  HandleSearch(); setShowSearchIcon(false);
                } else if (event.key === 'Enter' && searchText === "") {
                  HandleSearch(); setShowSearchIcon(true);
                }
              }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                  notchedOutline: classes.notchedOutline,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {showSearchIcon === true ?
                      <IconButton onClick={e => { HandleSearch(); setShowSearchIcon(false); }}>
                        <SearchIcon />
                      </IconButton>
                      :
                      <IconButton onClick={e => { cancelSearch(); }}>
                        <CloseIcon />
                      </IconButton>
                    }
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={4} sm={2} >
            <CreateUser onSave={handleSave} />
          </Grid>
          <Grid item xs={12} component={Paper} style={{ marginTop: '8px' }}>
            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
              <Table size="small" aria-label="customized table" stickyHeader>
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={usersList.length}
                />
                <TableBody>
                  {stableSort(usersList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), getComparator(order, orderBy)).map(
                    (row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow key={index} hover selected={isSelected(row.id)}
                          role="checkbox"
                          aria-checked={isSelected(row.id)}
                          tabIndex={-1}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              size="small"
                              checked={isSelected(row.id)}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.global_Admin === 3 ?
                              <AvatarTooltip title="Super Admin">
                                <Avatar className={classes.superAdminColor} src={SuperAdmin}></Avatar>
                              </AvatarTooltip>
                              : row.global_Admin === 2 ?
                                <AvatarTooltip title="Global Admin">
                                  <Avatar className={classes.globalAdmin} src={GlobalAdmin}></Avatar>
                                </AvatarTooltip>
                                : row.global_Admin === 1 ?
                                  <AvatarTooltip title="Client Admin">
                                    <Avatar className={classes.clientAdmin} src={ClientAdmin}></Avatar>
                                  </AvatarTooltip>
                                  : row.global_Admin === 0 ?
                                    <AvatarTooltip title="Regular User">
                                      <Avatar className={classes.regularUser} src={RegularUser}>{getUserInitials(row.userName)}</Avatar>
                                    </AvatarTooltip>
                                    : <AvatarTooltip title="File Admin">
                                      <Avatar className={classes.fileAdmin} src={FileAdmin}>{getUserInitials(row.userName)}</Avatar>
                                    </AvatarTooltip>
                            }
                          </TableCell>
                          <StyledCell component="th" scope="row">
                            <span className={`${searchText === "" ? classes.TextNormal : row.firstName?.includes(searchText?.toUpperCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                              {row.firstName}
                            </span>
                          </StyledCell>
                          <StyledCell>
                            <span className={`${searchText === "" ? classes.TextNormal : row.lastName?.includes(searchText?.toUpperCase()) ? classes.TextHighlight : classes.TextNormal}`}>
                              {row.lastName}
                            </span>
                          </StyledCell>
                          <StyledCell component="th" align="left">
                            <span className={`${searchText === "" ? classes.TextNormal : row.userName?.includes(searchText) ? classes.TextHighlight : classes.TextNormal}`}>
                              {row.userName}
                            </span>
                          </StyledCell>
                          <StyledCell align="center">
                            <InviteUsersforCA userRow={row} onInvite={handleSave} />
                          </StyledCell>
                          <StyledCell align="left">
                            <EditUser userRow={row} onSave={handleSaveWithUserInfo} onRemove={handleSave} />
                          </StyledCell>
                          <StyledCell align="left">
                            <DeleteUser userRow={row} onDelete={handleDeleteSuccess} checkSuperAdmin={row.global_admin === 3} />
                          </StyledCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
              {!usersList.length && !ProgressBar ?
                <Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
                  No records to display..
                </Typography> : null}
            </TableContainer>
            <Box p={0} display="flex">
              <Box display="flex" justifyContent="flex-end" m={0} width="65%">
                <div className={classes.footer}>
                  <Footer />
                </div>
              </Box>
              <Box display="flex" alignContent="flex-end" justifyContent="flex-end" m={0} width="27%">
                <TablePagination
                  id="UAC_TablePagination"
                  rowsPerPageOptions={[15, 25, 50]}
                  component="div"
                  count={usersList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}