import DateFnsUtils from '@date-io/date-fns';
import {
    Backdrop,
    Box, Button, Checkbox, Chip, CircularProgress, ClickAwayListener, createStyles, Grid, IconButton, InputAdornment, makeStyles, Paper, TextField, Typography
} from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete, AutocompleteCloseReason } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CancelIcon from '@mui/icons-material/Cancel';
import CreateIcon from '@mui/icons-material/Create';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Theme } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory } from 'react-router';
import { PuffLoader } from 'react-spinners';
import { IAgentReportsContract } from '../../../models/AgentReport/IAgentReportContract';
import { IAckClientInfo } from '../../../models/IAckReport/IAckClientInfo';
import { useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';
import Footer from '../../Footer/Footer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        maingrid: {
            width: '600px',
            height: 'auto',
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '10px',
            boxShadow: '0px 1px 10px 1px rgba(103, 128, 159, 1)',
            [theme.breakpoints.up('xs')]: {
                width: '400px'
            },
            [theme.breakpoints.up('sm')]: {
                width: '450px'
            },
            [theme.breakpoints.up('md')]: {
                width: '500px'
            },
            [theme.breakpoints.up('lg')]: {
                width: '600px'
            },
        },
        textStyle: {
            color: 'blue',
            fontSize: '13px',
            textAlign: 'left',
            fontWeight: 'bold'
        },
        textField: {
            marginTop: '2px',
            '& .MuiFormControl-marginNormal': {
                marginTop: '0px',
                marginBottom: '5px'
            }
        },
        header: {
            color: 'rgb(11, 129, 90)',
            padding: '7px',
            fontSize: '16px',
            textAlign: 'center',
            marginTop: '5px',
            backgroundColor: 'rgba(16, 185, 129, 0.12)',
        },
        boxBorder: {
            border: '2px solid gray',
            padding: '2px 8px',
            marginTop: '10px',
            borderRadius: '5px',
            width: '100%',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
        },
        textBoxStyle2: {
            fontSize: 10,
            '& .MuiChip-labelSmall': {
                fontSize: 10
            }
        },
        scrollMargin: {
            '& div:first-child': {
                marginBottom: '5px',
            },
            '& div:nth-child(3) div': {
                backgroundColor: '#2377e4 !important'
            },
            '& div:nth-child(3)': {
                top: '10px !important',
                bottom: '5px !important'
            },
        },
        clientStyle: {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '20px',
            borderRadius: '10px',
        },
        valueContainer: {
            textAlign: 'left',
            "& > :not(:last-child)": {
                marginRight: '5px',
                marginTop: '2px'
            },
            "& > *": {
                marginBottom: '5px',
                marginTop: '2px'
            }
        },
        clientCodeBox: {
            width: '100%',
            borderRadius: '10px',
            marginTop: '2px'
        },
        paper2: {
            border: '2px solid black',
            height: 'auto',
            margin: 0
        },
        GreenColor: {
            color: 'green',
            fontSize: 12,
            marginLeft: '5px',
        },
        IconSizeStyle: {
            height: '25px',
            width: '25px'
        },
        CheckBoxBorder: {
            borderTop: '1px solid gray',
            display: 'flex',
            width: '100%'
        },
        ClientcheckBoxStyle: {
            padding: '0px',
            width: '50%',
            textAlign: 'left'
        },
        textBoxStyle: {
            marginTop: '5px',
            fontSize: 10,
            '& .MuiChip-labelSmall': {
                paddingLeft: '5px',
                paddingLight: '5px',
                fontSize: '10px'
            }
        },
        groupicon: {
            color: '#364F6B',
            fontSize: 40,
            textAlign: 'center'
        },
        submitBtn: {
            backgroundColor: 'green',
            color: 'white',
            marginRight: '15px',
            '&:hover': {
                backgroundColor: 'green',
                color: 'white',
            }
        },
        clearBtn: {
            backgroundColor: 'red',
            color: 'white',
            marginLeft: '15px',
            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            }
        },
        typhography: {
            fontSize: 15,
            textAlign: 'center'
        },
        grid1: {
            padding: '3px 15px 0px 26px'
        },
        paper: {
            border: '2px solid black'
        },
        pageLayout: {
            maxHeight: 800,
            minHeight: 800,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 125px)',
                minHeight: 'calc( 100vh - 125px)',
            }
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        }
    })
)

const usePopoverStyles = makeStyles({
    paper: {
        border: '2px solid black'
    }
});

const AgentCaseCreatedReport: React.FC<{ clients?: any }> = (props) => {
    const classes = useStyles();
    const [selectedClientCode, setSelectedClientCode] = useState([]);
    const [startDate, setStartDate] = useState<any>(new Date().setDate(new Date().getDate() - 1));
    const [endDate, setEndDate] = useState(new Date());
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [ClientCodeOpen, setClientCodeOpen] = useState(false);
    const [searchClientCode, setSearchClientCode] = useState("");
    const [confirmedClients, setConfirmedClients] = useState([]);
    const { state } = useGlobalState();
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [deselectClient, setDeselectClient] = useState(false);
    const [selectedPhase, setSelectedPhase] = useState<number>(-1);
    const [valClientCode, setValClientcode] = useState(0);
    const popoverClasses = usePopoverStyles();

    const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

    useEffect(() => {
        if (selectedClientCode?.length <= 0 || (moment(startDate).format("YYYY-MM-DD") > moment(endDate).format("YYYY-MM-DD"))
            || (selectedClientCode?.length === 1 && selectedPhase === -1) || (selectedPhase === -1) || (valClientCode > 100)
            || startDate === null || endDate === null) {
        } else {
        }
    }, [selectedClientCode, selectedPhase, startDate, endDate, valClientCode])

    const reset = () => {
        setCheckAll(false);
        setSelectedClientCode([]);
        setSelectedPhase(-1);
        setStartDate(new Date().setDate(new Date().getDate() - 1));
        setEndDate(new Date());
        setSearchClientCode("");
    };

    const handleSearchClient = (e) => {
        setSearchClientCode(e.target.value);
    }

    useEffect(() => {
        setValClientcode(selectedClientCode?.filter(r => r?.client_code?.includes("999"))?.length);
    }, [selectedClientCode])

    const onDelete = (clientcode) => () => {
        setSelectedClientCode((value) => value.filter((v) => v.client_code !== clientcode));
    }

    useEffect(() => {
        (async () => {
            let request = {
                "userId": state?.AgentUserAccessContext?.agentUserId,
                "client_code": ""
            }
            await usePost<IAckClientInfo[]>("Client/SearchAgentClientCode", request).then((r) => {
                setConfirmedClients(r.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }, [])

    const handleClickAway = (e) => {
        (async () => {
            setClientCodeOpen(false);
            let request = {
                "userId": state?.AgentUserAccessContext?.agentUserId,
                "client_code": searchClientCode?.trim().toLocaleUpperCase()
            }
            await usePost<IAckClientInfo[]>("Client/SearchAgentClientCode", request).then((r) => {
                setConfirmedClients(r.data);
            }).finally(() => {
                setSearchClientCode("");
                setShowProgressBar(false);
            });
        })()
    }

    function handleClientCodeSelect(event, valueone) {
        setSelectedClientCode(valueone);
    }
    const handleClickOpen = () => {
        setConfirmedClients([]);
        setLoading(true);
        (async () => {
            let request = {
                "userId": state?.AgentUserAccessContext?.agentUserId,
                "client_code": searchClientCode?.trim().toLocaleUpperCase()
            }
            await usePost<IAckClientInfo[]>("Client/SearchAgentClientCode", request).then((r) => {
                setConfirmedClients(r.data);
                setLoading(false);
            }).finally(() => {
                setSearchClientCode("");
                setShowProgressBar(false);
                setCheckAll(false);
                setLoading(false);
            });
        })()
    };

    const handleClear = () => {
        (async () => {
            setCheckAll(false);
            let request = {
                "userId": state?.AgentUserAccessContext?.agentUserId,
                "client_code": ""
            }
            await usePost<IAckClientInfo[]>("Client/SearchAgentClientCode", request).then((r) => {
                setConfirmedClients(r.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    };

    const checkAllChange = (event) => {
        setCheckAll(event.target.checked);
        if (event.target.checked) {
            let NewClientList = confirmedClients.filter((c) => {
                return !selectedClientCode.find((r) => {
                    return c.client_code == r.client_code
                })
            });
            setSelectedClientCode([...new Set(selectedClientCode?.concat(NewClientList?.map((r) => r)))]);
            setClientCodeOpen(false);
        }
        setCheckAll(false);
        handleClear();
        setSearchClientCode("");
    };

    const DeselectAllChange = (event) => {
        setDeselectClient(event.target.checked);
        if (event.target.checked) {
            var NewClientList = selectedClientCode.filter((c) => {
                return !confirmedClients.find((r) => {
                    return c.client_code == r.client_code
                })
            });
            setSelectedClientCode(NewClientList);
            setClientCodeOpen(false);
        }
        setDeselectClient(false);
        handleClear();
        setSearchClientCode("");
    };

    const handleOpen = () => {
        let GetClients: IAgentReportsContract[] = [];
        selectedClientCode.map((x) => {
            let ClientDetail: IAgentReportsContract = { dbT_CLIENT: x?.client_code };
            GetClients.push(ClientDetail);
        });
        history.push('/generateagentcasecreatedreport', {
            client_code: GetClients,
            startdate: startDate,
            enddate: endDate
        });
    }

    const handleFirstDateChange = (date: Date | null) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
    };

    return (
        <div className={classes.root}>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <div className={classes.pageLayout}>
                <Grid container spacing={0} alignItems="center" className={classes.maingrid}>
                    <Grid item xs={12} sm={12} md={12} className={classes.grid1} >
                        <Typography variant="h6" color="primary" gutterBottom className={classes.header}><b>AGENT CASE CREATED REPORT</b></Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={classes.grid1}>
                        <Typography variant="h6" color="primary" gutterBottom className={classes.textStyle}><b>START DATE</b></Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                PopoverProps={{ classes: popoverClasses }}
                                size="small"
                                disableToolbar
                                autoOk={true}
                                inputVariant="outlined"
                                variant="inline"
                                format={DateFormat}
                                className={classes.textField}
                                margin="normal"
                                id="DocUpLog_EndDate"
                                value={startDate}
                                style={{ width: '100%' }}
                                onChange={handleFirstDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                disableFuture={true}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} style={{ padding: '5px 26px 0px 15px' }}>
                        <Typography variant="h6" color="primary" gutterBottom className={classes.textStyle}><b>END DATE</b></Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                PopoverProps={{ classes: popoverClasses }}
                                size="small"
                                disableToolbar
                                autoOk={true}
                                inputVariant="outlined"
                                variant="inline"
                                format={DateFormat}
                                className={classes.textField}
                                margin="normal"
                                id="DocUpLog_EndDate"
                                value={endDate}
                                style={{ width: '100%' }}
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                disableFuture={true}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} style={{ padding: '5px 26px' }}>
                        <Typography variant="body2" color="primary" gutterBottom className={classes.textStyle} style={{ color: 'green' }}><b>SELECTED CLIENT CODE : {selectedClientCode?.length}</b></Typography>
                        <Paper className={classes.boxBorder}>
                            <Scrollbars autoHide
                                autoHideTimeout={1000}
                                autoHideDuration={10}
                                autoHeight
                                className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                                autoHeightMin={110}
                                autoHeightMax={110}>
                                {!selectedClientCode.length ?
                                    <div className={classes.clientStyle}>
                                        <GroupAddIcon className={classes.groupicon} />
                                        <Typography variant="h6" className={classes.typhography} >
                                            <b>Selected Client Codes</b>
                                        </Typography>
                                    </div>
                                    :
                                    <div className={`${classes.valueContainer}`} >
                                        {selectedClientCode.map((v) => (
                                            <Chip key={v.client_code} color="secondary" size="small" label={v.client_code} onDelete={onDelete(v.client_code)} />
                                        ))}
                                    </div>
                                }

                            </Scrollbars>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} style={{ padding: '5px 26px' }}>
                        {/* <Typography variant="h6" color="primary" gutterBottom className={classes.textStyle}><b>CHOOSE CLIENT CODE</b></Typography> */}
                        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
                            <Box>
                                <Autocomplete
                                    multiple
                                    loading={loading}
                                    filterOptions={(options, state) => options}
                                    className={classes.clientCodeBox}
                                    autoComplete={false}
                                    id="checkboxes-tags-demo"
                                    options={confirmedClients}
                                    classes={{ paper: classes.paper2 }}
                                    disableClearable
                                    value={selectedClientCode}
                                    open={ClientCodeOpen}
                                    renderTags={() => null}
                                    onChange={handleClientCodeSelect}
                                    onOpen={() => {
                                        setClientCodeOpen(true);
                                    }}
                                    onClose={(e: any, reason: AutocompleteCloseReason) => {
                                        if (reason === "toggleInput") {
                                            setClientCodeOpen(false);
                                        }
                                    }}
                                    getOptionDisabled={(option) => option.client_code}
                                    getOptionLabel={(option) => option.client_code}
                                    getOptionSelected={(option, value) => option.client_code == value.client_code}
                                    renderOption={(option, { selected }) => (
                                        <span className={`${selected ? classes.GreenColor : null}`} >
                                            {option?.client_code + "--" + option?.client_name}
                                        </span>

                                    )}
                                    ListboxProps={
                                        {
                                            style: {
                                                maxHeight: '150px',
                                                fontSize: '13px',
                                                padding: '0px 5px'
                                            }
                                        }
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth size="small"
                                            value={searchClientCode} variant="outlined"
                                            type='text' placeholder="Search Clients"
                                            onChange={(e) => { setSearchClientCode(e.target.value); }}
                                            onKeyDown={event => {
                                                if (event.key === 'Enter') { handleSearchClient(event); handleClickOpen(); setClientCodeOpen(true); }
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="primary" size={15} /> : null}
                                                        {params.InputProps.endAdornment}
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={(_) => { handleClickOpen(); setClientCodeOpen(true); }}>
                                                                <SearchIcon className={classes.IconSizeStyle} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                        <Box display="flex" width="100%" className={classes.CheckBoxBorder}>
                                                            <Box className={classes.ClientcheckBoxStyle} width="50%">
                                                                <Checkbox size="small" disabled={loading || !confirmedClients?.length}
                                                                    checked={checkAll}
                                                                    onChange={checkAllChange}
                                                                    id="check-all"
                                                                />
                                                                <span style={{ fontSize: 14 }}><b>SELECT ALL</b></span>
                                                            </Box>
                                                            <Box className={classes.ClientcheckBoxStyle} width="50%">
                                                                <Checkbox size="small" disabled={loading || !confirmedClients?.length}
                                                                    checked={deselectClient}
                                                                    onChange={(e) => { DeselectAllChange(e); }}
                                                                    id="check-all"
                                                                />
                                                                <span style={{ fontSize: 14 }}><b>DESELECT ALL</b></span>
                                                            </Box>
                                                        </Box>
                                                    </React.Fragment>
                                                ),
                                            }}
                                            className={classes.textBoxStyle}
                                        />
                                    )}
                                />
                            </Box>
                        </ClickAwayListener>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box m={2} >
                            <Button variant='contained' color='primary' size="small"
                                onClick={handleOpen} startIcon={<CreateIcon />}
                                className={classes.submitBtn}>
                                Generate Report
                            </Button>
                            <Button variant='contained' color='primary' size="small"
                                onClick={() => { reset(); }}
                                startIcon={<CancelIcon />}
                                className={classes.clearBtn}> clear Report
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </div>
    )
}

export default AgentCaseCreatedReport
