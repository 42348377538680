import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    TableCellStyle1: {
        fontSize: 13,
        padding: '4px 0px 4px 15px',
    },
    tablebodyActivity: {
        maxHeight: 500,
        minHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 210px)',
            minHeight: 'calc( 100vh - 210px)',
        },
    },
    boxStyle: {
        padding: '8px 10px'
    },
    TableCellStyle: {
        background: "#007FFF",
        color: "white",
        fontSize: 13,
        padding: '2px 0px 2px 15px',
        TextAlign: 'left'
    },
    btnManTemp1: {
        borderRadius: '20px',
        float: 'right',
        fontSize: 12,
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            background: "green",
        }
    },
    btnManTemp: {
        borderRadius: '20px',
        float: 'right',
        fontSize: 12,
    },
    customizeDialogePaper: {
        minWidth: "1000px"
    },
    activityName: {
        color: 'blue',
        marginTop: '5px',
        paddingLeft: '5px'
    },
    gridflex: {
        borderRadius: 8,
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'
    },
    gridflex1: {
        borderRadius: 8,
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'
    },
    customizeHeader: {
        textAlign: 'center',
        backgroundColor: '#007fff',
        color: 'white',
        height: '25px'
    },
    customizeHeader1: {
        textAlign: 'center',
        backgroundColor: '#007fff',
        color: 'white',
        height: '25px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    dialogePaper: {
        minWidth: "880px"
    },
    flexControl: {
        display: 'flex',
        flexDirection: 'column'
    },
    sidebar: {
        minWidth: "500px",
        // backgroundColor: '#a2cffe',
        transition: "all 0.2s ease",
        display: 'block',
        whiteSpace: 'nowrap',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        // boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px',
        borderRight: '1px solid gray',
        padding: '10px 10px',
    },
    mainmenu: {
        minWidth: "100%",
        display: 'block',
        paddingTop: '6px',
        border: '1px solid gray',
        boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px',
        maxHeight: 800,
        minHeight: 800,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 400px)',
            minHeight: 'calc( 100vh - 400px)',
        }
    },
    userboxviewStyle: {
        display: 'flex',
        borderRadius: '5px',
        borderColor: 'black',
        padding: '8px',
        marginTop: '15px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        alignItems: 'floatRight',
        justifyContent: 'floatRight',
        flexDirection: 'row',
        marginLeft: '10px',
        borderTop: '4px solid #3bb78f !important'
    },
    userStyleTextBox: {
        fontSize: '16px',
        textAlign: 'right',
        fontWeight: 'bold',
        backgroundColor: '#e5f5f4',
        borderRadius: '5px',
        color: '#03797a',
        padding: '4px',
    },
    root: {
        '& > *': {
            borderBottom: 'unset',
            marginBottom: "0%",
            margin: theme.spacing(2),
            width: '25ch',
            flexGrow: 1,
        },
    },
    permittedFiled: {
        backgroundColor: 'white',
        color: 'black',
        borderRadius: '5px',
        marginTop: '5px',
        marginLeft: '8px',
    },
    userStyleTextBox1: {
        fontSize: '16px',
        textAlign: 'right',
        marginLeft: '4px',
        fontWeight: 'bold',
        backgroundColor: '#e5f5f4',
        borderRadius: '5px',
        color: '#03797a',
        padding: '4px',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    userboxviewStyle1: {
        display: 'flex',
        borderRadius: '5px',
        borderColor: 'black',
        padding: '8px',
        marginTop: '-23px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        alignItems: 'floatRight',
        justifyContent: 'floatRight',
        flexDirection: 'row',
        marginLeft: '10px',
        borderTop: '4px solid #3bb78f !important'
    },
    cloudFolderStyle: {
        width: '100%',
        marginTop: '11px',
        borderRadius: '10px',
        fontSize: 12
    },
    dropdownStyle: {
        width: '100%',
        fontSize: 12,
    },
    autoCompleteFont: {
        fontSize: 13,
    },
    paper1: {
        border: "2px solid black",
    },
    flexDispaly: {
        // display: 'flex',
    },
    controlHead: {
        fontSize: '14px',
        textAlign: 'center'
    },
    customizeButton: {
        fontSize: 12,
        marginTop: '5px'
    },
    controlPadding: {
        padding: '3px 5px 5px 8px',
        // borderBottom: '0px solid white !important',
        '&.MuiTableCell-root': {
            // borderBottom: '0px solid white !important',
        }
    },
    activityDropDownStyle: {
        width: '100%',
        marginTop: '7px',
    },
    formControl: {
        display: 'flex',
        flexDirection: 'row',
    },
    controlName: {
        marginTop: '4px',
        marginLeft: '4px'
    },
    controlStyle: {
        width: '200px',
        fontSize: '14px',
        color: 'black',
        marginTop: '5px',
        textOverflow: 'ellipsis',
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: 'hidden'
    },
    regexStyle: {
        width: '150px',
        fontSize: '13px',
        color: 'black',
        textOverflow: 'ellipsis',
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: 'hidden'
    },
    actionStyle: {
        display: 'flex',
        gap: '10%'
    },
    dialogePaperFullScreen: {
        maxWidth: "1700px"
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    IconPadding: {
        padding: '0px',
        marginRight: '20px',
    },
    createtemplate: {
        borderRadius: 20,
        fontSize: 12,
        marginBottom: '0px',
        marginTop: '3px'
    },
    cancelbtn: {
        borderRadius: 20,
        background: "red",
        marginRight: '20px',
        fontSize: 12,
        '&:hover': {
            background: "red",
        }
    },
    titleConfirm: {
        color: "black",
        fontSize: 17
    },
    yesButton: {
        background: "green",
        color: 'white',
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "green",
        }
    },
    noButton: {
        background: "red",
        display: 'flex',
        bottom: '0.2cm',
        color: 'white',
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "red",
        }
    },
    outlinedInput: {
        fontSize: 14,
        color: "black",
        '&$focused $notchedOutline': {
            color: 'Red',
            border: '0px'
        },
    },
    fullLayout1: {
        maxHeight: 800,
        minHeight: 800,
        // overflowX: 'hidden',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 245px)',
            minHeight: 'calc( 100vh - 245px)',
        }
    },
    fullLayout: {
        maxHeight: 800,
        minHeight: 800,
        // overflowX: 'hidden',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 130px)',
            minHeight: 'calc( 100vh - 130px)',
        }
    },
    adminLayout: {
        maxHeight: 700,
        minHeight: 700,
        overflowX: 'hidden',
        border: '1px solid gray',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 410px)',
            minHeight: 'calc( 100vh - 410px)',
        }
    },
    rowhead: {
        background: 'lightgray',
        color: 'black',
        fontSize: '12px',
        padding: '2px 5px 2px 8px',
        width: '30%'
    },
    searchBox: {
        marginTop: '2px',
        borderRadius: 30,
        border: '2px solid #215176',
        height: '35px',
        textIndent: '10px',
        width: '100%',
        textAlign: 'center',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    },
    outlinedInputs: {
        fontSize: 15,
        color: "black",
        marginTop: '-12px',
        '&$focused $notchedOutline': {
            color: 'white',
            border: '0px'
        },
    },
    notchedOutline: {
        color: 'white',
        border: '0px'
    },
    WarningMessage: {
        color: "red",
        marginTop: "10px",
        textAlign: 'center'
    },
    NormalText: {
        color: "green",
        fontWeight: "bold"
    },
    disableText: {
        color: "gray",
        fontWeight: "bold"
    },
    GridButtonStyle: {
        alignItems: 'center',
        display: 'flex'
    },
    tableRowHover: {
        '&:hover': {
            cursor: 'pointer',
            color: 'black', // Set your desired background color
        },
    },
}))

export { useStyles }