import { Box, makeStyles } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import SendIcon from '@mui/icons-material/Send';
import { Button, Chip, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../../components/Footer/Footer';
import failedUpload from '../../../images/Placement/failedUpload.svg';
import successUpload from '../../../images/Placement/successUpload.svg';
import { IClientList } from '../../../models/Admin/ClientPlacements/IClientList';
import { IClientCodeMap } from '../../../models/Placements/IClientCodeMapped';
import { IDateFormatDetails } from '../../../models/Placements/IFixedDateFormat';
import { useGlobalState } from '../../../store/GlobalStore';
import SaveTemplate from '../SaveTemplate';
import DownloadRejectedAccounts from './DownloadRejectedAccounts';
import { IClientCodeExempted } from '../../../models/Placements/IPlacementRejectRules';

const useStyles = makeStyles((theme) => ({
  divStyle: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 800,
    minHeight: 800,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 125px)',
      minHeight: 'calc( 100vh - 125px)',
    }
  },
  container: {
    height: '70vh',
    alignItems: "center",
    backgroundColor: 'white',
    overflowY: 'hidden'
  },
  buttons: {
    borderRadius: 20,
    fontSize: 12,
    fontWeight: 'bold',
    background: "#007FFF",
    color: "white",
    marginTop: "10px",
  },
  div: {
    flexGrow: 1
  },
  box: {
    maxWidth: "100vh",
    marginTop: '0.1cm',
    // height: '12vh',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    backgroundColor: 'white',
    overflowY: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const Finish = (props) => {
  const classes = useStyles();
  let location = useLocation();
  const { state } = useGlobalState();

  let placementDuplicateAccountsList: any[] = props.location?.state?.placementDuplicateAccountsList;
  let clientCodeMappingList: IClientCodeMap[] = (props.location?.state?.clientCodeMappingList);
  let PlacementRejectedAccountsList = props.location?.state?.PlacementRejectedAccountsList;
  let ruleRejectedAccounts: any[] = props?.location?.state?.PlacementRejectedAccounts;
  let finalDateFormat: IDateFormatDetails = props?.location?.state?.finalDateFormat;
  let mappedRequireFields: any[] = props?.location?.state?.mappedRequireFields;
  let selectedHeaderList: string[] = props.location?.state?.selectedHeaderList;
  let placementAccountList = (props.location?.state?.placementAccountList);
  let filteredHeaderList = props.location?.state?.filteredHeaderList;
  let clientList: IClientList[] = props.location.state?.clientList;
  let isCSVUpload: boolean = props.location.state?.isCSVUpload;
  let mBalanceValue = (props.location?.state?.mBalanceValue);
  let clientCode: string = props.location.state?.clientCode;
  let ignoreRowNumber = (props.location?.state?.ignoreRows);
  let csvUrl = props.location?.state?.PlacementFaildRecords;
  let feeValue = (props.location?.state?.feeValue);
  let savingEnable = true;
  let enable = true;

  let phaseNumber: number = props.location.state.phaseNumber;
  let isClientCodeExempt: IClientCodeExempted = props?.location?.state?.isClientCodeExempt;
  let IsAccountSubmited: boolean = props?.location?.state?.IsAccountSubmited;

  history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    history.go(1);
  };

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={state.busy ? true : false}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <div className={classes.divStyle}>
          <Container maxWidth="sm" fixed className={classes.container} component="div" >
            {placementAccountList?.length === 0 ?
              <>
                <Box p={0} flexShrink={0} bgcolor="white" >
                  <img src={failedUpload} alt="" width={150} height={150} />
                </Box>
                <Box p={0} flexShrink={0} bgcolor="white" >
                  <Typography variant="h5" gutterBottom color={'red'} fontSize={40} >
                    <b> Sorry! </b>
                  </Typography>
                </Box>
                <Box p={0} flexShrink={0} bgcolor="white" >
                  <Typography variant="h6" gutterBottom color={'gray'} fontSize={15} >
                    We are currently unable to submit any accounts. Please review the data and try again.
                  </Typography>
                </Box>
              </>
              :
              <>
                <Box p={0} flexShrink={0} bgcolor="white" >
                  <img src={successUpload} alt="" width={150} height={150} />
                </Box>
                <Box p={0} flexShrink={0} bgcolor="white" >
                  <Typography variant="h5" gutterBottom color={'green'} fontSize={40} >
                    <b> Thank You! </b>
                  </Typography>
                </Box>
                <Box p={0} flexShrink={0} bgcolor="white" >
                  <Typography variant="h6" gutterBottom color={'gray'} fontSize={15} >
                    We will process your accounts shortly and you can expect your acknowledgment report in 24 - 48 hours!
                  </Typography>
                </Box>
              </>
            }
            <Grid container spacing={1} mt={1}>
              <Grid item xs={12} >
                <Grid container spacing={0.5}>
                  <Grid item xs={12} textAlign={'center'} mt={1}>
                    <Typography variant="h5" gutterBottom color={'green'} p={0.5} align='center' borderRadius={0.5} fontSize={16} fontWeight={700}>
                      Placed Accounts Count : {placementAccountList?.length}
                    </Typography>
                  </Grid>
                  {csvUrl != null ?
                    <Grid item xs={12} mt={1}>
                      <Grid item xs={12}>
                        <div className="line-container1">
                          <div className="hr-left1"></div>
                          <p className="line-text1"><b><Chip label="Download Placement Rejected Accounts" size="small" color='default' /></b></p>
                          <div className="hr-right1"></div>
                        </div>
                        <Grid container spacing={0.5}>
                          <Grid style={{ paddingTop: '12px' }} item xs={12} mt={1}>
                            <DownloadRejectedAccounts />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    : null}
                </Grid>
              </Grid>
              <Grid item xs={12} mt={1}>
                <Grid container spacing={0.5} >
                  <Grid item xs={isCSVUpload ? 6 : 12} textAlign={'center'} borderTop={'2px solid lightgray'}>
                    <Button id="Finish_Submit_btn" size="small" sx={{ mt: 1 }} fullWidth endIcon={<SendIcon />} className={classes.buttons} component={Link} to="/placement"
                      variant="contained" color="primary">
                      Submit Additional Accounts
                    </Button>
                  </Grid>
                  {isCSVUpload === true ?
                    <Grid item xs={6} textAlign={'center'} borderTop={'2px solid lightgray'}>
                      <Box pt={1}>
                        <SaveTemplate fees={feeValue} balance={mBalanceValue}
                          clientCode={clientCode} checkEnable={enable} selectedHeaderList={selectedHeaderList} savingTempEnable={savingEnable}
                          ignoreRowNumber={ignoreRowNumber} clientList={clientList} clientCodeMap={clientCodeMappingList} finalDateFormat={finalDateFormat} />
                      </Box>
                    </Grid>
                    : null}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Footer />
      </div >
    </React.Fragment >
  )
}

export default Finish
