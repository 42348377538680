import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    // InquiryDetail
    tabs: {
        backgroundColor: '#800080',
        marginRight: "2px",
        marginTop: "0%",
        width: "18%",
        boxShadow: "1px 3px 1px #9E9E9E",
        height: '0px',
        fontSize: 12,
        minHeight: '38px',
        color: "white",
        borderRadius: 5,
    },
    root: {
        border: "2px solid black",
        borderRadius: 8,
        height: "90%",
        marginTop: "7px",
        flexGrow: 1,
    },
    iconLabelWrapper: {
        flexDirection: "row"
    },
    labelContainer: {
        width: "auto",
        padding: 0
    },
    validationStyle: {
        border: 'red',
    },
    image1: {
        width: '30px',
        height: '30px',
    },
    image2: {
        width: '35px',
        height: '35px',
    },
    rotateIcon: {
        animation: "$spin 2s linear infinite"
    },
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        }
    },
    AwaitUsers: {
        backgroundColor: 'lightgray'
    },
    indicator: {
        top: "38px",
        backgroundColor: '#C51230',
        padding: "2px",
        border: "10%",
        width: '18% !important',
        [theme.breakpoints.up('xl')]: {
            width: '264px !important',
        }
    },
    appbarStyle: {
        borderRadius: 10,
        border: '1px black solid',
        maxHeight: 'auto',
        minHeight: 265,
        paddingBottom: '2px',
    },
    tabIdIT: {
        marginTop: "4px",
        marginLeft: "10px"
    },
    gridStyle: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    // Request
    rootRequest: {
        '& .MuiButton-label': {
            fontSize: 12,
        }
    },
    rootHidden: {
        display: "none"
    },
    buttons: {
        borderRadius: 20,
        marginLeft: '0.5cm',
        marginBottom: '2px',
        marginTop: '2px',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    buttonStyle: {
        borderRadius: 20,
        marginBottom: '2px',
        marginTop: '2px',
        background: "#007FFF",
        color: "white",
        '&:hover': {
            background: "#007FFF",
        }
    },
    chooseButton: {
        borderRadius: 20,
        fontSize: '11px',
        background: "#007FFF",
        color: "white",
        '&:hover': {
            background: "#007FFF",
        }
    },
    submitButton: {
        borderRadius: 20,
        marginLeft: '0.5cm',
        fontSize: '11px',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    BoxStyle: {
        width: '90%',
        textAlign: "center",
        fontSize: '16px',
        fontWeight: 'bold',
        border: '2px solid gray',
        borderRadius: '10px',
        minHeight: '120px'
    },
    HeaderStyle: {
        cursor: 'pointer',
        fontSize: 12,
        fontWeight: 'bold',
    },
    Textstyle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 12,
    },
    MessageStyle: {
        color: "black",
        fontSize: 16,
    },
    yesButton: {
        background: "red",
        color: 'white',
        fontSize: 12,
        borderRadius: 20,
        right: '15px',
        '&:hover': {
            background: "red",
        }
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    textFontStyle: {
        textAlign: 'left',
        fontSize: '13px',
    },
    forwardArrowMinus: {
        color: '#215176',
        transform: 'rotate(-90deg)',
        fontSize: 20,
    },
    forwardArrowPlus: {
        color: '#215176',
        transform: 'rotate(90deg)',
        fontSize: 20,
    },
    // SettlementRequest
    buttonSettle: {
        borderRadius: 20,
        background: "#007FFF",
        color: "white",
    },
    inputField: {
        height: '45px',
        fontSize: 14,
        borderRadius: '4px',
        position: 'relative',
        transition: '0.3s',
        width: "300px",
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    inputField1: {
        height: '42px',
        fontSize: 14,
        borderRadius: '4px',
        position: 'relative',
        transition: '0.3s',
        width: "320px",
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    Content: {
        fontWeight: 'bold',
        color: 'black'
    },
    buttonstyle: {
        borderRadius: '20px',
        backgroundColor: 'red',
        fontSize: 12,
        color: 'white',
        '&:hover': {
            background: "red",
        }
    },
    hidebutton: {
        display: 'flex',
        flexDirection: 'row'
    },
    tablecellstyle: {
        paddingBottom: '0px',
        paddingTop: '5px',
        fontSize: 13,
    },
    textAlignLeft: {
        textAlign: 'left',
        fontSize: 14,
        color: 'black',
        fontWeight: 'bold'
    },
    boxBorder: {
        border: 'black'
    },
    visibilityIconStyle: {
        cursor: 'pointer',
        fontSize: 25
    },
    typoSpacingLeft: {
        margin: '0px 20px',
    },
    btnBgGreen: {
        background: "#009900",
        '&:hover': {
            background: "green",
        }
    },
    btnBgRed: {
        backgroundColor: 'red',
        '&:hover': {
            background: "red",
        }
    },
    // submit
    rootSubmit: {
        border: "0.5px solid black",
        borderRadius: 8,
        width: "62%",
        height: "auto",
        margin: "0px",
        marginLeft: "6px",
        padding: '0px 5px !important'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    submitDiv: {
        marginLeft: "5px"
    },
    iconSpacing: {
        padding: '2px',
        marginLeft: '10px',
    },
    textSpan: {
        fontSize: 11,
    },
    textSpan2: {
        fontSize: 13,
    },
    // TransactionAuditHistory
    rootTransAuditHis: {
        border: "2px solid black",
        borderRadius: 8,
        height: "90%",
        marginTop: "7px"
    },
    ErrorMessageStyle: {
        color: "red",
        marginTop: "10px",
        fontSize: 14,
    },
    typoTitle: {
        marginTop: "0px",
        marginBottom: "10px",
        color: "blue"
    },
    typoTitle1: {
        textAlign: 'center',
        marginTop: "10px",
        color: "black",
        fontSize: 18
    },
    typoRecordData: {
        color: "#009900",
        fontSize: 16
    },
    // HideNotification
    buttonGreen: {
        borderRadius: "20px",
        background: "green",
        color: 'white',
        fontSize: 12,
        "&:hover": {
            backgroundColor: 'green',
        }
    },
    buttonRed: {
        background: "red",
        borderRadius: "20px",
        color: 'white',
        fontSize: 12,
        "&:hover": {
            backgroundColor: 'red',
        }
    },
    // Close and Remove
    button: {
        borderRadius: 5,
        color: 'white',
    },
    root1: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1)
        }
    },
    description: {
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        width: '85%',
        fontSize: 14,
        marginLeft: '30px'
    },
    root2: {
        borderRadius: 8,
        height: "90%",
        marginTop: "7px",
        marginLeft: "-30%"
    },
    RadioButtonStyle: {
        marginLeft: '0.5cm',
        marginTop: "0.3cm"
    },
    iconSpacingTop: {
        margin: "0px 3px",
        padding: '3px',
    },
    formSpacingLeft: {
        marginLeft: "0.5cm"
    },
    radioGroupSpacingTop: {
        marginTop: "0.2cm"
    },
    radioGroupSpacingLeft: {
        marginLeft: "1cm"
    },
    confirmButton: {
        color: "white",
        marginRight: "20px",
        float: 'right',
        borderRadius: 20,
        fontSize: 12,
        backgroundColor: 'green',
        '&:hover': {
            background: "green",
            color: "white",
        }
    },
    cancelButton: {
        background: "red",
        color: "white",
        marginRight: "35px",
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "red",
            color: "white",
        }
    },
    radioButtonStyle: {
        marginLeft: '0.5cm',
        marginTop: "0.3cm"
    },
    // Account History
    rootHistory: {
        border: "2px solid black",
        borderRadius: 8,
        height: "90%",
        marginTop: "7px"
    },
    typoRecordData1: {
        color: "#009900",
        fontSize: 13
    },
    typoRecordData2: {
        color: "black",
        fontSize: 13
    },
    // DocumentUpload
    head: {
        display: "flex",
        flexDirection: "column",
        borderSpacing: "1cm",
        width: 375,
        color: "primary",
        marginLeft: '25px',
        marginTop: '5px'
    },
    groupedOutlined: {
        "&:not(:first-child)": {
            marginLeft: -1
        },
        "&:not(:last-child)": {
            borderRightColor: "rgba(0, 0, 0, 0.23)"
        }
    },
    appBar: {
        position: 'relative',
        overflowY: 'hidden',
        minHeight: '40px',
        borderBottom: '2px solid #0072E5'
    },
    Documents: {
        border: "2px solid black",
        borderRadius: 8,
        height: "60%",
    },
    title: {
        flex: 1,
        color: '#0072E5',
        textAlign: 'center',
        fontSize: 18,
        marginRight: '100px'
    },
    LetterStyle: {
        color: "black",
        textAlign: 'center',
        fontSize: 18,
        marginTop: "10px",
    },
    dialogSpacingTop: {
        marginTop: '15px'
    },
    noDataRecord: {
        color: "red",
        marginTop: "10px",
        fontSize: 14,
        marginLeft: '5px'
    },
    typoStyle: {
        marginLeft: "10px",
        color: "black",
        fontSize: 13,
        marginTop: '5px'
    },
    iconStyle: {
        fontSize: 17,
        color: '#1E90FF',
        marginLeft: '5px'
    },
    buttonDocSpacing: {
        padding: '8px 25px'
    },
    buttoncloseSpacing: {
        padding: '8px 15px'
    },
    // CloseAccount
    descriptionCloseAccount: {
        width: '100%',
        marginLeft: "-1%",
        marginTop: "5px",
    },
    dialogSpacingLeft: {
        marginLeft: "-3cm"
    },
    circleStyle: {
        color: 'white',
        height: "15px",
        width: "15px",
        marginLeft: '5px'
    },
    documentname: {
        marginLeft: "10px",
        color: "black",
        fontSize: 14,
        marginTop: '10px'
    },
    Iconbutton: {
        padding: '4px 4px'
    },
    Visible: {
        fontSize: 22,
        color: '#1E90FF',
    },
    NonVisible: {
        fontSize: 22,
        color: '#AEAEAE',
    },
    indicator1: {
        top: "50px",
        backgroundColor: '#C51230',
        padding: "2px",
        border: "10%"
    },
    image3: {
        width: '30px',
        height: '30px',
        color: '#007FFF'
    },
    inquiryDetails: {
        '& .MuiBox-root': {
            padding: '0px 5px',
        }
    },

    yesButton1: {
        background: "green",
        color: 'white',
        fontSize: 12,
        borderRadius: 20,
        right: '15px',
        '&:hover': {
            background: "green",
        }
    },
    noButton: {
        background: "red",
        color: 'white',
        borderRadius: 20,
        fontSize: 12,
        right: '15px',
        '&:hover': {
            background: "red",
        }
    },
    menuButton: {
        marginTop: '5px',
        marginBottom: '5px',
        height: '40px',
        backgroundColor: 'white',
        cursor: 'default',
        '&:hover': {
            backgroundColor: 'white',
        }
    },
    menuButton1: {
        width: '120px',
        height: '40px'
    },
    dialog: {
        '& .MuiDialog-paper': {
            overflowY: 'hidden'
        }
    },
    formcontrol: {
        marginTop: "0.3cm"
    },
    formcontrol1: {
        marginTop: "0.2cm"
    },
    formcontrol2: {
        marginLeft: '1.3cm',
        marginTop: "0.2cm"
    },
    dialogePaper: {
        minWidth: "800px"
    },
    dialogeDocumentPaper: {
        minWidth: "950px"
    },
    documentStyle: {
        display: 'flex',
        flexDirection: 'row'
    },
    dialogeConformation: {
        minWidth: "700px"
    },
    tablecellPadding: {
        padding: '3px 0px 3px 10px'
    },
    paymentText: {
        fontSize: '13px'
    },
    customTable: {
        padding: "5px !important",
    },
    buttonBgStyle: {
        background: "#009900",
        padding: '8px 37px',
    },
    cancel: {
        background: "red",
        color: "white",
        fontSize: 12,
        borderRadius: 20,
        marginRight: '20px',
        '&:hover': {
            background: "red",
        }
    },
    toolbarStyle: {
        backgroundColor: 'white',
        minHeight: '40px'
    }
}))

export default useStyles