import { AppBar, Backdrop, Dialog, IconButton, makeStyles, Snackbar, Toolbar, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// Icons 
import CloseIcon from '@material-ui/icons/Close';
import PDFPreview from '../../../images/Placement/pdfpreview.svg';
// Models
import { IManageTemplateLogo } from '../../../models/Admin/ClientPlacements/IManageTemplateLogo';
// Componants
import { basePdf } from '../../../constants/Constant';
import { usePost } from '../../../utils/apiHelper';
import { ARMLogo } from '../../../constants/Constant';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { base64toBlobPDF } from '../../../utils/base64toBlob';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Transition } from '../../../components/GlobalStyles/DialogBoxTransition';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    overflowY: 'hidden',
    minHeight: '40px',
    borderBottom: '2px solid #0072E5'
  },
  title: {
    flex: 1,
    color: '#0072E5',
    textAlign: 'center',
    fontSize: 18,
    marginRight: '100px'
  },
  iconvisible: {
    textAlign: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  div: {
    flexGrow: 1,
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  menuButton: {
    marginTop: '5px',
    marginBottom: '5px',
    height: '40px',
    backgroundColor: 'white',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'white',
    }
  },
  menuButton1: {
    width: '120px',
    height: '40px'
  },
  dialog: {
    '& .MuiDialog-paper': {
      overflowY: 'hidden'
    }
  },
  toolbarStyle: {
    backgroundColor: 'white',
    minHeight: '40px'
  },
  pdfPadding: {
    marginLeft: '-1.5cm',
    marginTop: '10px'
  },
  flexDisplay: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const DocumentPDFViewer: React.FC<{ row: IManageTemplateLogo, clientName: string, allianceName: string }> = (props) => {
  const { allianceName } = props;
  const [serviceURL, setServiceURL] = useState('');
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [ProgressBar, setshowProgressBar] = useState(false);
  const [fileExists, setFileExists] = useState(false);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleClickOpen = () => {
    setshowProgressBar(true);
    getPDFFileAsync();
  };

  const handleClose = () => {
    setOpen(false);
  }
  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setFileExists(false);
  };

  function getPDFFileAsync() {
    (async () => {
      setshowProgressBar(true);
      let request = {
        "pdfUri": props?.row?.pdfUri
      }
      await usePost<any>("File/PDFDocumentFileDownload", request).then((r) => {
        let url = URL.createObjectURL(base64toBlobPDF(basePdf + r?.data));
        setServiceURL(url);
        setOpen((r?.status === 404 || r?.status === 400) ? false : true);
      }).catch(() => {
        setFileExists(true);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })();
  }

  return (
    <React.Fragment>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        {props?.clientName === "-2" ?
          null :
          <div className={classes.flexDisplay}>
            <IconButton aria-label="delete" onClick={handleClickOpen} className={classes.iconvisible} >
              <img src={PDFPreview} width="30px" height="30px" />
            </IconButton>
            <Typography variant="body1" style={{ marginTop: '15px' }}>
              <b>For help creating placement or update files, {/* from {allianceName} */} please view this attachment.</b>
            </Typography>
          </div>
        }
        {open ?
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
            <AppBar position="fixed" className={classes.appBar} >
              <Toolbar className={classes.toolbarStyle}>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" >
                  <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  <b>DOCUMENT VIEWER</b>
                </Typography>
                <IconButton edge="end" color="inherit" onClick={() => handleClose()} aria-label="close" style={{ padding: '5px' }}>
                  <CloseIcon style={{ color: '#000', fontSize: 22 }} />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
              <Viewer fileUrl={serviceURL}
                defaultScale={1.50} plugins={[defaultLayoutPluginInstance]}
              />
            </Worker>
          </Dialog>
          : null}
        <Snackbar open={fileExists} className="snackBarStyle" anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
            File does not exists!
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment >
  );
};

export default DocumentPDFViewer;