import React from 'react';
import { ICaseCreatedReport } from '../../../models/AgentReport/IAgentReportContract';
import { createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 12,
        },
        rootHidden: {
            display: "none"
        },
        collapsePadding: {
            padding: '20px'
        },
        tablefont: {
            fontSize: 12,
            padding: '5px 0px 5px 5px !important'
        }
    })
);

const AgentCaseCreatedExpandableRow: React.FC<{ row: ICaseCreatedReport, index: number }> = (props) => {
    const { row, index } = props;
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow key={index} >
                <TableCell className={classes.tablefont}>{row?.fieldName}</TableCell>
                <TableCell className={classes.tablefont}>{row?.fieldValue}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default AgentCaseCreatedExpandableRow;
