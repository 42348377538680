import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import ContentPasteGoTwoToneIcon from '@mui/icons-material/ContentPasteGoTwoTone';
import React, { useState } from 'react';
import { ITrustStatementSatisfied, IWebViewFiles } from '../../models/Files/IWebViewFiles';
import { IClientInfo } from '../../models/client/IClientInfo';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import TooltipSmall from '../GlobalStyles/TooltipSmall';
import useStyles from './WebViewFilesCss';

const MoveFileErrorToProcessFolder: React.FC<{ fileDetails: IWebViewFiles, subFolderNameList: IClientInfo[], onFetchDetails: () => void }> = (props) => {
    const { fileDetails, subFolderNameList, onFetchDetails } = props;
    const classes = useStyles();
    const { state } = useGlobalState();
    const [open, setOpen] = useState(false);
    const [selectedClients, setSelectedClients] = useState<any>([]);
    const [fileMoveReason, setFileMoveReason] = useState("");
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [clientCodeNotExist, setClientCodeNotExist] = useState('');
    const [validClientCode, setvalidClientCode] = useState<any>([]);

    const clientsLoading = subFolderNameList?.length == 0;

    const handleClose = () => {
        setOpen(false);
        setSelectedClients(null);
        setFileMoveReason("");
        setClientCodeNotExist("");
    };

    const handleClientSelect = (event, selected) => {
        setClientCodeNotExist("");
        setSelectedClients(selected);
        (async () => {
            setShowProgressBar(true);
            await useFetch<ITrustStatementSatisfied>(`WebViewFiles/CheckTrustStatmentExist?ClientCode=${selected?.client_code}`).then((r) => {
                if (r?.data?.isTrustStatementSatisfied === true) {
                    setvalidClientCode(selected?.client_code);
                }
                else {
                    setClientCodeNotExist(`User permission does not exist on ${selected?.client_code} client code.`);
                }
            })
        })().finally(() => {
            setShowProgressBar(false);
        })
    }

    const handleClickMoveFile = () => {
        (async () => {
            let request = {
                fileId: fileDetails?.fileId,
                clientCode: validClientCode,
                fileMoveReason: fileMoveReason,
                userId: state?.userAccessContext?.id,
                // errorId: fileDetails?.errorId
            }
            await usePost<any>("WebViewFiles/MoveFileToProcessFolder", request).finally(() => {
            }).finally(() => {
                onFetchDetails();
                setOpen(false);
            });
        })()
    }

    return (
        <React.Fragment>
            <TooltipSmall title="Move File">
                <IconButton className={classes.actionIcon} onClick={() => { setOpen(true); }} >
                    <ContentPasteGoTwoToneIcon className={classes.moveFile} />
                </IconButton>
            </TooltipSmall>

            <Dialog TransitionComponent={Transition} open={open} keepMounted
                className={classes.dialogbox}
                PaperProps={{
                    style: {
                        border: "1px solid #0D324D",
                        borderRadius: 15,
                        boxShadow: '0px 1px 15px 1px rgba(103, 128, 159, 1)',
                    }
                }}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h5" gutterBottom className={classes.titleStyle}>
                        MOVE FILE TO PROCESS FOLDER
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Grid container spacing={2} >
                        {clientCodeNotExist?.length ?
                            <Grid item xs={12}>
                                <Typography variant='body2' color='error' align='center'>
                                    <b>{clientCodeNotExist}</b>
                                </Typography>
                            </Grid>
                            : null
                        }

                        <Grid item xs={12}>
                            <Typography variant='body2' style={{ color: 'blue' }}>
                                <b>Choose Client Code</b>
                            </Typography>
                            <Autocomplete
                                id="combo-box-demo"
                                size='small'
                                options={subFolderNameList}
                                value={selectedClients}
                                classes={{ paper: classes.paperDropdown, input: classes.fontDropdown }}
                                onChange={(e, selected) => { handleClientSelect(e, selected); }}
                                getOptionLabel={(option) => option?.client_code}
                                getOptionSelected={(option, value) => option?.client_code == value?.client_code}
                                fullWidth
                                renderOption={(option, { selected }) => (
                                    <span className={`${selected ? classes.GreenColor : null}`} >
                                        {option?.client_code + "--" + option?.client_name}
                                    </span>
                                )}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label="Select Client Code"
                                        variant="outlined"
                                        margin="normal"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {clientsLoading ? <CircularProgress size={25} color="inherit" /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' style={{ color: 'blue' }}>
                                <b>Reason for Moving the File</b>
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                label="Reason for Moving the File"
                                multiline fullWidth
                                style={{ marginTop: '10px' }}
                                size='small'
                                value={fileMoveReason}
                                onChange={(e) => setFileMoveReason(e.target.value)}
                                rows={6}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id="IM_ok_btn" size="small" startIcon={<CheckCircleIcon />} variant="contained" onClick={() => { handleClickMoveFile() }} disabled={!selectedClients || !fileMoveReason || !!clientCodeNotExist?.length} className={classes.yesButton} color="primary" autoFocus>
                        Submit
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" startIcon={<CancelIcon />} variant="contained" onClick={() => { handleClose() }} autoFocus className={classes.noButton} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    )
}

export default MoveFileErrorToProcessFolder