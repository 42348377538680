import styled from "styled-components";

export const StyledLabel = styled.label < { checked: boolean } > `  
cursor: pointer;  
text-indent: -9999px;  
width: 50px;  
height: 28px;  
border: 2px solid #0c0d0d; /* Add a border property with the desired border color */
background: ${({ checked }) => (!checked ? "gray" : "#b6d3d4")};  
display: block;  
border-radius: 100px;  
position: relative;
&:after {    
content: "";    
position: absolute;    
left: ${({ checked }) => (!checked ? "3px" : "calc(55% - 5px)")};    
top: 0px;    
width: 24px;    
height: 24px;    
background: #fff;    
border-radius: 90px;    
transition: 0.3s;
}`;