import {MsalProvider} from '@azure/msal-react'
import {CssBaseline} from '@material-ui/core'
import {MuiThemeProvider} from '@material-ui/core/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import App from './App'
import {msalConfig} from './auth/authConfig'
import './index.css'
import {GlobalStateProvider} from './store/GlobalStore'
import theme from './themes'
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { reactPlugin } from "./webview-appinsights/webviewAppInsights";
import { AppInsightsContext, useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
  if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS) && event?.payload?.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

ReactDOM.render(
  <AppInsightsContext.Provider value={reactPlugin}>
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <GlobalStateProvider>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </MuiThemeProvider>
        </GlobalStateProvider>
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>
  </AppInsightsContext.Provider>,
  document.getElementById('root')
)