import {
    Avatar, Backdrop, Box, Button, Grid, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography
} from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
// Icons
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@material-ui/icons/Search";
import AvatarTooltip from '../../GlobalStyles/AvatarTooltip';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import SuperAdmin from '../../../images/users/SuperAdmin.svg';
import GlobalAdmin from '../../../images/users/ClientAdmin.svg';
import UserAvatar from '../../../images/webviewfiles/UserAvatar.png';
// Models
import EditTemplate from "./EditTemplate";
import { useStyles } from './AG_TemplateCss';
import DeleteTemplate from "./DeleteTemplate";
import CreateNewTemplate from "./CreateNewTemplate";
import Footer from '../../../components/Footer/Footer';
import { useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from "../../../utils/apiHelper";
import { IAgentActivities } from '../../../models/AgentTemplate/IAgentActivities';
import { IAgentTemplateList } from "../../../models/AgentTemplate/IAgentTemplateList";
import LightTooltip from '../../GlobalStyles/LightTooltip';
import { StyledLabel } from '../../../utils/StyledLabel';
import { ActivityList } from '../../../models/AgentInquiry/IAgentActivityListCount';
import { IActivityGroupName } from '../../../models/AgentTemplate/IAgentCustomizeControl';


export default function GAManageTemplate() {
    const classes = useStyles();
    const { state } = useGlobalState();
    const [orderBy, setOrderBy] = useState();
    const [order, setOrder] = useState("desc");
    const [ProgressBar, setShowProgressBar] = useState(true);
    const [showSearchIcon, setShowSearchIcon] = useState(true);

    const [pageNumber, setPageNumber] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>("");
    const [rowsPerPage, setRowsPerPage] = useState<number>(15);
    const [totalRecords, setTotalRecords] = useState<number>(0);

    const [listActivity, setListActivity] = useState<IAgentActivities[]>([]);
    const [agentTemplateList, setAgentTemplateList] = useState<IAgentTemplateList[]>([]);
    const [defaultTemplateList, setdefaultTemplateList] = useState<IAgentTemplateList[]>([]);

    const [activityGroupName, setActivityGroupName] = useState<IActivityGroupName[]>([]);
    const [listEmailActivityJson, setListEmailActivityJson] = useState<ActivityList[]>([]);
    const [listAdminActivityJson, setListAdminActivityJson] = useState<ActivityList[]>([]);
    const [listCaseCreatedActivityJson, setListCaseCreatedActivityJson] = useState<ActivityList[]>([]);

    const [ischecked, setIsChecked] = useState(false);
    let switchButton = 0;

    const handlePageChange = (event, page) => {
        setPageNumber(page);
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPageNumber(0);
    };

    useEffect(() => {
        getAgentActivityTemplateAsync();
    }, [rowsPerPage, pageNumber, order, orderBy]);

    async function getAgentActivityTemplateAsync() {
        setShowProgressBar(true);
        let request = {
            "searchText": searchText?.trim(),
            "orderBy": "",
            "orderDirection": 0,
            "rowsPerPage": rowsPerPage,
            "recordNumber": (pageNumber * rowsPerPage) + 1,
            "isFteTemplate": switchButton === 0 ? null : true
        }
        await usePost<{ activityTemplates: IAgentTemplateList[], totalCount: number }>('AgentUser/GetActivtyTemplate', request).then((r) => {
            setdefaultTemplateList(r?.data['activityTemplates']?.slice(0, 3));
            setAgentTemplateList(r?.data['activityTemplates']?.slice(3));
            setTotalRecords(r?.data?.totalCount);
        }).finally(() => {
            setShowProgressBar(false);
        });
    }

    async function clearAgentActivityTemplateAsync() {
        setShowProgressBar(true);
        let request = {
            "searchText": "",
            "orderBy": "",
            "orderDirection": 0,
            "rowsPerPage": rowsPerPage,
            "recordNumber": (pageNumber * rowsPerPage) + 1,
            "isFteTemplate": null
        }
        await usePost<{ activityTemplates: IAgentTemplateList[], totalCount: number }>('AgentUser/GetActivtyTemplate', request).then((r) => {
            setdefaultTemplateList(r?.data['activityTemplates']?.slice(0, 3));
            setAgentTemplateList(r?.data['activityTemplates']?.slice(3));
            setTotalRecords(r?.data?.totalCount);
        }).finally(() => {
            setShowProgressBar(false);
        });
    }

    function clearSearchValues() {
        setSearchText("");
        setPageNumber(0);
        clearAgentActivityTemplateAsync();
        setShowSearchIcon(true);
    }

    function handleSearchValues() {
        setPageNumber(0);
        setShowSearchIcon(false);
        getAgentActivityTemplateAsync();
    }

    useEffect(() => {
        (async () => {
            await useFetch<{ emailActivityList: string, adminActivityList: string, caseCreatedActivityList: string }>("AgentUser/GetAgentActivityList").then((r) => {
                setListEmailActivityJson(JSON.parse(r?.data?.emailActivityList));
                setListAdminActivityJson(JSON.parse(r?.data?.adminActivityList));
                setListCaseCreatedActivityJson(JSON.parse(r?.data?.caseCreatedActivityList));
            });
        })()
    }, [])

    useEffect(() => {
        (async () => {
            await useFetch<IActivityGroupName[]>("AgentUser/GetActivityGroupName").then((r) => {
                setActivityGroupName(r?.data);
            });
        })().finally(() => {
        })
    }, [])

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function handleSwitched(e: ChangeEvent<HTMLInputElement>) {
        setIsChecked(e.target.checked);
        if (e.target.checked === true) {
            switchButton = 1;
        } else if (!e.target.checked) {
            switchButton = 0;
        }
        getAgentActivityTemplateAsync();
    }

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { id: "", disablePadding: false, label: "", sortable: false, },
            { id: "userName", disablePadding: false, label: "USER NAME", sortable: true },
            { id: "activityTemplateName", disablePadding: false, label: " TEMPLATE NAME", sortable: true },
            { id: "createdDate", disablePadding: false, label: "CREATE DATE", sortable: true },
            { id: "modifiedDate", disablePadding: false, label: "MODIFIED DATE", sortable: true },
            { id: "", disablePadding: false, label: "ACTIONS", sortable: true },
            { id: "", disablePadding: false, label: "DELETE", sortable: false },
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.TableCellStyle}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "desc"}
                                onClick={createSortHandler(headCell.id)}>
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setPageNumber(0);
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container spacing={0} className={classes.maingrid}>
                    <Grid xs={12} sm={4} lg={3} item className={classes.Title1}>
                        <Typography variant="h6" gutterBottom className={`${classes.headingTitle} ${"headertitle"}`}>
                            <b> MANAGE ACTIVITY TEMPLATE </b>
                        </Typography>
                    </Grid>
                    <Grid xs={6} sm={5} lg={4} item>
                        <TextField variant="outlined"
                            size="small" value={searchText}
                            type='text' placeholder="Search User Name and Template Name"
                            className={classes.searchBox}
                            onChange={e => setSearchText(e.target.value)}
                            onKeyPress={event => {
                                if (event.key === 'Enter' && searchText != "") {
                                    handleSearchValues();
                                } else if (event.key === 'Enter' && searchText === "") {
                                    clearSearchValues();
                                }
                            }}
                            inputProps={{ maxlength: 256 }}
                            InputProps={{
                                classes: {
                                    root: classes.outlinedInput,
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.searchInput,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showSearchIcon === true ?
                                            <IconButton onClick={e => { handleSearchValues(); }}>
                                                <SearchIcon />
                                            </IconButton>
                                            :
                                            <IconButton onClick={e => { clearSearchValues(); }}>
                                                <CloseIcon />
                                            </IconButton>
                                        }
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} sm={1} lg={2} direction="column">
                        <Box className={classes.buttonStyle} >
                            <Box display="flex" p={1} style={{ paddingTop: '5px' }}>
                                <LightTooltip title="Switch to view FTE Permitted users">
                                    <StyledLabel htmlFor="checkbox" checked={ischecked}>
                                        <input
                                            id="checkbox"
                                            type="checkbox"
                                            checked={ischecked}
                                            onChange={handleSwitched} />
                                    </StyledLabel>
                                </LightTooltip>
                                <Typography style={{ padding: '2px 7px', fontSize: '16px' }}><b>FTE Template</b></Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid xs={6} sm={3} lg={3} item>
                        <CreateNewTemplate listEmailActivityJson={listEmailActivityJson} listAdminActivityJson={listAdminActivityJson}
                            activityGroupName={activityGroupName} listCaseCreatedActivityJson={listCaseCreatedActivityJson} onSave={getAgentActivityTemplateAsync} />
                        <Button id="CreateTemplate_btn" size="small" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />} variant="contained" component={Link}
                            to="/agenttemplate" color="primary" className={classes.btnManTemp} style={{ marginRight: '15px' }}>BACK</Button>
                    </Grid>
                    <Grid component={Paper} xs={12} sm={12} lg={12}>
                        <TableContainer component={Paper} className={` ${classes.tablebody1} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table aria-label="customized table" size="small" stickyHeader >
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={totalRecords}
                                />
                                <TableBody>
                                    {defaultTemplateList.map(
                                        (row) => {
                                            return (
                                                <TableRow className={`${row?.masterFlag != 0 ? classes.defaultTemplateColor1 : null}`} >
                                                    <TableCell component="th" scope="row" className={`${row?.masterFlag != 0 ? classes.defaulttempSystem : classes.TableCellStyle1}`}>
                                                        {row.userRole === 3 ?
                                                            <AvatarTooltip title="Super Admin">
                                                                <Avatar className={classes.superAdminColor} src={SuperAdmin}></Avatar>
                                                            </AvatarTooltip>
                                                            : row.userRole === 2 ?
                                                                <AvatarTooltip title="Global Admin">
                                                                    <Avatar className={classes.globalAdmin} src={GlobalAdmin}></Avatar>
                                                                </AvatarTooltip>
                                                                :
                                                                <AvatarTooltip title="System">
                                                                    <Avatar className={classes.superAdminColor} src={UserAvatar}></Avatar>
                                                                </AvatarTooltip>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1}>
                                                        <span className={`${!searchText?.length && row.masterFlag != 0 ? classes.defaultTemplateCellColor : row?.userName?.toUpperCase().includes(searchText?.toUpperCase()) ?
                                                            classes.TextHighlight : classes.TextNormal}`}>{row?.userName}</span>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                                        <span className={`${!searchText?.length && row.masterFlag != 0 ? classes.defaultTemplateCellColor : row?.activityTemplateName?.toUpperCase().includes(searchText?.toUpperCase()) ?
                                                            classes.TextHighlight : classes.TextNormal}`}>{row?.activityTemplateName}</span>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                                        <span className={`${row?.masterFlag != 0 ? classes.defaultTemplateCellColor : null}`}>{`${moment(row?.createdDate).format(state?.GlobalUtils?.settingValue)} ${moment(row?.createdDate).format("HH:mm:ss")}`}</span>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                                        <span className={`${row?.masterFlag != 0 ? classes.defaultTemplateCellColor : null}`}>{`${moment(row?.modifiedDate).format(state?.GlobalUtils?.settingValue)} ${moment(row?.modifiedDate).format("HH:mm:ss")}`}</span>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                                        <EditTemplate listEmailActivityJson={listEmailActivityJson} listAdminActivityJson={listAdminActivityJson} activityGroupName={activityGroupName}
                                                            listCaseCreatedActivityJson={listCaseCreatedActivityJson} row={row} onUpdate={getAgentActivityTemplateAsync} masterFlag={row?.masterFlag} />
                                                    </TableCell>
                                                    <TableCell align="left" className={`${row.masterFlag != 0 ? classes.defaulttempDelete : classes.TableCellStyle1}`}>
                                                        <DeleteTemplate onDelete={getAgentActivityTemplateAsync} templateRow={row?.activityTemplateId} masterFlag={row?.masterFlag} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    {stableSort(agentTemplateList, getComparator(order, orderBy)).map(
                                        (row, index) => {
                                            return (
                                                <TableRow className={`${row.masterFlag != 0 ? classes.defaultTemplateColor1 : null}`} >
                                                    <TableCell component="th" scope="row" className={`${row.masterFlag != 0 ? classes.defaulttempSystem : classes.TableCellStyle1}`}>
                                                        {row.userRole === 3 ?
                                                            <AvatarTooltip title="Super Admin">
                                                                <Avatar className={classes.superAdminColor} src={SuperAdmin}></Avatar>
                                                            </AvatarTooltip>
                                                            : row.userRole === 2 ?
                                                                <AvatarTooltip title="Global Admin">
                                                                    <Avatar className={classes.globalAdmin} src={GlobalAdmin}></Avatar>
                                                                </AvatarTooltip>
                                                                : null
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1}>
                                                        <span className={`${!searchText?.length ? classes.TextNormal : row?.userName?.toUpperCase()?.includes(searchText?.toUpperCase()) ?
                                                            classes.TextHighlight : row.masterFlag != 0 ? classes.defaultTemplateCellColor : classes.TextNormal}`}>{row?.userName}</span>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                                        <span className={`${!searchText?.length ? classes.TextNormal : row?.activityTemplateName?.toUpperCase()?.includes(searchText?.toUpperCase()) ?
                                                            classes.TextHighlight : row?.masterFlag != 0 ? classes.defaultTemplateCellColor : classes.TextNormal}`}>{row?.activityTemplateName}</span>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                                        <span className={`${row?.masterFlag != 0 ? classes.defaultTemplateCellColor : null}`}>{`${moment(row?.createdDate).format(state?.GlobalUtils?.settingValue)} ${moment(row?.createdDate).format("HH:mm:ss")}`}</span>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                                        <span className={`${row?.masterFlag != 0 ? classes.defaultTemplateCellColor : null}`}>{`${moment(row?.modifiedDate).format(state?.GlobalUtils?.settingValue)} ${moment(row?.modifiedDate).format("HH:mm:ss")}`}</span>
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                                        <EditTemplate listEmailActivityJson={listEmailActivityJson} listAdminActivityJson={listAdminActivityJson} activityGroupName={activityGroupName}
                                                            listCaseCreatedActivityJson={listCaseCreatedActivityJson} row={row} onUpdate={getAgentActivityTemplateAsync} masterFlag={row?.masterFlag} />
                                                    </TableCell>
                                                    <TableCell align="left" className={`${row?.masterFlag != 0 ? classes.defaulttempDelete : classes.TableCellStyle1}`}>
                                                        <DeleteTemplate onDelete={getAgentActivityTemplateAsync} templateRow={row?.activityTemplateId} masterFlag={row?.masterFlag} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                </TableBody>
                            </Table>
                            {!ProgressBar && !agentTemplateList.length && !defaultTemplateList.length ?
                                <Typography gutterBottom className={classes.ErrorMessageStyle}>
                                    No records to display..
                                </Typography> : null}
                        </TableContainer>
                        <Grid container spacing={0} >
                            <Box m={0} width="65%">
                                <div className="footer">
                                    <Footer />
                                </div>
                            </Box>
                            <Box m={0} width="35%">
                                <div className="pagination">
                                    <TablePagination
                                        rowsPerPageOptions={[15, 25, 50]}
                                        component="div"
                                        count={totalRecords}
                                        rowsPerPage={rowsPerPage}
                                        page={pageNumber}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div >
        </React.Fragment >
    )
}
