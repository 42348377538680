import React, { useState, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
// import ReCAPTCHA from 'react-recaptcha-v3';

interface CaptchaProps {
    onVerify: (response: string) => void;
}

const ReCaptchaComponent: React.FC<CaptchaProps> = ({ onVerify }) => {
    const [childData, setChildData] = useState('');

    const handleVerify = (response: string | null) => {
        if (response) {
            onVerify(response);
            setChildData(response);
            onVerify(response); // Call the callback function with the data
        } else {
            onVerify(response); // Call the callback function with the data
        }
    };

    const localSiteKeyPROD = "6LfervAnAAAAANnLuC7uzDgoDayx1md957bRYvhQ";

    return (
        <div>
            <ReCAPTCHA
                sitekey={localSiteKeyPROD}
                onChange={handleVerify}
                theme="light" // Change this based on your design
                size="small" // Change this based on your design
                type="image" // Use the image type for image verification
            />
        </div>
    );
}

export default ReCaptchaComponent