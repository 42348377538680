import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import PersonIcon from '@material-ui/icons/Person';
import React, { useState } from 'react';
import { IProspectUsersDetails } from '../../models/accesscontrol/ProspectUsersDetails';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import { useStyles } from './ProspectUserCss';
import orgMail from '../../images/Authentication/org-mail.svg';
import orgPhone from '../../images/Authentication/org-phone.svg';
import orgName from '../../images/Authentication/org-name.svg';
import { usePost } from '../../utils/apiHelper';
import { PuffLoader } from 'react-spinners';

const ViewOrganizationDetails: React.FC<{ userRow: IProspectUsersDetails }> = (props) => {
    const classes = useStyles();
    const { userRow } = props;
    const [open, setOpen] = useState(false);
    const [ProgressBar, setshowProgressBar] = useState(false);
    const [organizationDetails, setOrganizationDetails] = useState<IProspectUsersDetails[]>([]);

    const handleClose = () => {
        setOpen(false);
    };

    function GetOrganizationDetails() {
        setOpen(true);
        (async () => {
            setshowProgressBar(true);
            let request = {
                "organizationAdminEmailId": userRow?.organizationAdminEmailId
            }
            await usePost<{ prospectOrganizationResult: IProspectUsersDetails[] }>("User/GetOrganizationDetail", request).then((GetOrganizationDetails) => {
                setshowProgressBar(true);
                setOrganizationDetails(GetOrganizationDetails.data["prospectOrganizationResult"]);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })();
    }

    return (
        <div>
            <Button variant='contained' size="small" onClick={() => { GetOrganizationDetails(); }}
                className={classes.notApprovedBtn1} startIcon={<PersonIcon />}>
                Organization Details
            </Button>

            <Dialog TransitionComponent={Transition}
                PaperProps={{ style: { borderRadius: 10 } }}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={'xs'}
            >
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" className={classes.titleheader}>
                        VIEW ORGANIZATION DETAILS
                    </Typography>
                </DialogTitleHeader>
                <DialogContent style={{ padding: '25px 30px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.profileGrid}>
                            <Box m={0} width="20%">
                                <img src={orgName} width="50px" alt="org-name" />
                            </Box>
                            <Box m={0} width="80%">
                                <Typography variant='subtitle1'><b>Organization Name</b></Typography>
                                {organizationDetails[0]?.organizationName?.length ?
                                    < Typography variant='body2'>
                                        {organizationDetails[0]?.organizationName}
                                    </Typography>
                                    :
                                    <Typography variant='body2' style={{ color: 'red' }}>
                                        Information not provided
                                    </Typography>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} className={classes.profileGrid}>
                            <Box m={0} width="20%">
                                <img src={orgMail} width="50px" alt="org-name" />
                            </Box>
                            <Box m={0} width="80%">
                                <Typography variant='subtitle1'><b>Organization Email</b></Typography>
                                {organizationDetails[0]?.organizationAdminEmailId?.length ?
                                    <Typography variant='body2'>
                                        {organizationDetails[0]?.organizationAdminEmailId}
                                    </Typography>
                                    :
                                    <Typography variant='body2' style={{ color: 'red' }}>
                                        Information not provided
                                    </Typography>
                                }

                            </Box>
                        </Grid>
                        <Grid item xs={12} className={classes.profileGrid}>
                            <Box m={0} width="20%">
                                <img src={orgPhone} width="50px" alt="org-name" />
                            </Box>
                            <Box m={0} width="80%">
                                <Typography variant='subtitle1'><b>Organization Phone Number</b></Typography>
                                {organizationDetails[0]?.organizationPhoneNumber?.length ?
                                    <Typography variant='body2'>{organizationDetails[0]?.organizationPhoneNumber}</Typography>
                                    :
                                    <Typography variant='body2' style={{ color: 'red' }}>
                                        Information not provided
                                    </Typography>
                                }
                            </Box>
                        </Grid>
                        {/* We may use in Future
                        <Grid item xs={12} className={classes.profileGrid}>
                            <Box m={0} width="20%">
                                <img src={userPhone} width="50px" alt="org-name" />
                            </Box>
                            <Box m={0} width="80%">
                                <Typography variant='subtitle1'><b>User Phone Number</b></Typography>
                                {organizationDetails[0]?.prospectPhoneNumber?.length ?
                                    <Typography variant='body2'>{organizationDetails[0]?.prospectPhoneNumber}</Typography>
                                    :
                                    <Typography variant='body2' style={{ color: 'red' }}>
                                        Information not provided
                                    </Typography>
                                }
                            </Box>
                        </Grid> */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default ViewOrganizationDetails