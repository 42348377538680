import {
    Button, Dialog, DialogContent, Grid, IconButton, makeStyles, Typography
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SendIcon from "@material-ui/icons/Send";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import React, { useEffect, useRef, useState } from 'react';
import { IUserInvited } from '../../models/common/IUserInvited';
import { IBlockBalance } from '../../models/dashboard/IBlockBalance';
import Card from '../../pages/Card/Card';
import CardBody from '../../pages/Card/CardBody';
import CardHeader from '../../pages/Card/CardHeader';
import CardIcon from '../../pages/Card/CardIcon';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';

const useStyles = makeStyles((theme) => ({
    button: {
        paddingBottom: "10px",
        paddingTop: "10px",
        width: 165,
        borderRadius: 10,
        marginTop: "-40px"
    },
    description: {
        width: '100%',
        marginTop: "15px",
    },
    text: {
        marginLeft: "35%"
    },
    buttonSuccess: {
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700]
        }
    },
    card: {
        color: theme.palette.text.secondary,
        height: '7cm',
        boxShadow: '0px 1px 5px 1px rgba(103, 128, 159, 1)',
        '&:hover': {
            transform: 'scale(1)',
            cursor: 'pointer',
            border: '3px blue solid',
            boxShadow: '0 10px 10px rgba(0, 0, 0, 0.4)',
        },
        [theme.breakpoints.down('lg')]: {
            maxHeight: 'calc( 55vh - 210px)',
            minHeight: 'calc( 55vh - 210px)',
        }
    },
    fonts: {
        fontFamily: 'Segoe UI',
        color: "black",
        textAlign: "center",
        fontSize: 16
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    notificationCount: {
        color: "black",
        fontWeight: 400
    },
    cardHeader: {
        padding: "5px",
    },
    CardHeaderStyle: {
        padding: "5px",
        marginTop: "-1cm",
        marginLeft: "-0.7cm",
    },
    TypographyStyle: {
        marginTop: "10px",
        color: "black",
        textAlign: 'center'
    },
    ButtonStyle: {
        borderRadius: '20px',
        backgroundColor: "red",
        marginBottom: '10px',
        marginRight: '10px',
        float: 'right',
        '&:hover': {
            backgroundColor: "red",
        }
    },
    sendbutton: {
        marginLeft: "5cm",
        marginTop: "10px",
        paddingBottom: "8px",
        width: 165,
        fontSize: 12,
        borderRadius: 10,
    }
}))

const Beginorder = () => {
    const [open, setOpen] = useState(false);
    const { state } = useGlobalState();
    const [message, setMessage] = useState<string>("");
    const [inputVal, setinputVal] = useState('');
    const [CheckInvited, setCheckInvited] = useState<number>(0);
    const [success, setSuccess] = useState(false);
    const timer = useRef<number>();
    const classes = useStyles();
    const [BlockBalance, setBlockBalance] = useState<IBlockBalance[]>([]);
    const [IsTriggered, setIsTriggered] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        (async () => {
            await useFetch<IBlockBalance[]>("Account/BlockBalanceByUser?userId=" + state.userAccessContext?.id).then((BlockBalanceData) => {
                setBlockBalance(BlockBalanceData.data);
            });
        })()
    }, [])

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, [message]);

    const handleClose = () => {
        setOpen(false);
        setSuccess(false);
        setMessage("");
        setinputVal('');
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsTriggered(false);
    };

    useEffect(() => {
        (async () => {
            if (state.userAccessContext?.id !== undefined) {
                await useFetch<IUserInvited[]>(`User/CheckUserInvited?userId=${state.userAccessContext?.id}`).then((r) => {
                    setCheckInvited(r.data[0]?.user_id);
                });
            }
        })()
    }, []);

    const SendBeginReorderEmail = () => {
        (async () => {
            if (CheckInvited !== null && CheckInvited !== 0 && CheckInvited !== undefined) {
                let request = {
                    "userName": state?.userAccessContext?.userName,
                    "clientName": state?.userAccessContext?.userName?.split("@")[0],
                    "clientID": state?.userAccessContext?.clientCodes?.join(","),
                    "message": message
                }
                await usePost<any>("Account/SendBeginReorderEmail", request);
                setIsTriggered(true);
            }
        })()
    }

    const handleSettlementOfferClick = () => {
        SendBeginReorderEmail();
        setOpen(false);
        handleClose();
        setShowMessage(true);
    }

    return (
        <React.Fragment>
            <Card className={classes.card} onClick={() => { setOpen(true); }} >
                <CardHeader color="success" stats={true} icon={true} className={classes.CardHeaderStyle} >
                    <CardIcon color="success">
                        <IconButton id="Dashboard_BeginReorder" onClick={() => { setOpen(true); }} aria-label="Mail" style={{ color: "white" }} >
                            <MailOutlineIcon fontSize="small" />
                        </IconButton>
                    </CardIcon>
                </CardHeader>
                <CardBody>
                    <Typography variant="h3" gutterBottom className={classes.notificationCount} >
                        <b>{BlockBalance.reduce((sum, curr) => sum + curr.balance, 0)}</b>
                    </Typography>
                    <Typography variant="h6" className={classes.fonts} >
                        Flat Fee Account Remaining
                    </Typography>
                </CardBody>
            </Card>

            <Dialog  TransitionComponent={Transition} open={open} keepMounted onClose={handleClose} maxWidth={'sm'}
                PaperProps={{ style: { borderRadius: 10 } }}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        SEND MESSAGE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Grid item xs={12} >
                        <TextField
                            id="BeginReorder_MessageBox"
                            variant="outlined"
                            placeholder="Contact us"
                            size="medium"
                            multiline
                            rows={6}
                            value={message}
                            onChange={(e) => { setMessage(e.target.value); setinputVal(e.target.value); }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxlength: 512 }}
                            className={classes.description}
                        />
                    </Grid>
                    <Button
                        size="small"
                        id="BeginReorder_SendNotifications"
                        variant="contained"
                        color="primary"
                        className={`${classes.sendbutton}`}
                        disabled={!inputVal}
                        onClick={() => { handleSettlementOfferClick(); }}
                        endIcon={
                            <React.Fragment>
                                {success ? "" : <SendIcon />}
                            </React.Fragment>
                        }
                    >
                        Send Message
                    </Button>
                    <Typography variant="subtitle1" className={classes.text} gutterBottom>
                        Phone: (888) 772-6468
                    </Typography>

                </DialogContent>
            </Dialog>
            <Snackbar className="snackBarStyle" open={IsTriggered} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Message Sent Successfully!
                </Alert>
            </Snackbar>

            <Dialog TransitionComponent={Transition}
                PaperProps={{ style: { borderRadius: 15 } }}
                open={showMessage}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Typography variant="h6" className={classes.TypographyStyle} gutterBottom>
                        {/* {"Message"} */}
                        <DoneAllIcon style={{ color: 'green', fontSize: 60 }} />
                    </Typography>
                    <Typography variant="h6" className={classes.TypographyStyle} gutterBottom>
                        Your message has been sent.
                    </Typography>
                    <Typography variant="h6" className={classes.TypographyStyle} gutterBottom>
                        Phone: (888) 772-6468
                    </Typography>
                    <Button id="BR_Close" size="small" startIcon={<HighlightOffIcon />} onClick={(_) => { setShowMessage(false); }} variant="contained" className={classes.ButtonStyle} color="primary">
                        Close
                    </Button>
                </DialogContent>
            </Dialog>

        </React.Fragment >
    )
}

export default Beginorder





