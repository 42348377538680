import { Collapse, makeStyles, TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Columns } from '../../models/Inquiry/config';
import { IDebtor } from '../../models/Reports/IDebtor';
import { useGlobalState } from '../../store/GlobalStore';
import ReportsDetails from './ReportsDetails';

const useRowStyles = makeStyles({
    root: {
        fontSize: 12,
        wordBreak: 'break-word'
    },
    root1: {
        maxHeight: 30,

    },
    rootHidden: {
        display: "none"
    },
    selectedRow: {
        color: 'white',
        fontSize: '11px !important',
        padding: '0px 0px 0px 5px',
        backgroundColor: '#1E90FF'
    },
    nonSelectedRow: {
        color: 'black',
        fontSize: '11px !important',
        flexWrap: 'wrap',
        padding: 5
    },
    clientId: {
        maxHeight: 30,
    },
    table: {
        flexWrap: 'wrap',
        padding: 3,
        border: '1px solid #f0efef'
    },
    rowexpand: {
        padding: '0px 0px 0px 5px'
    }
})

function usePrevious(data) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = data
    }, [data])
    return ref.current
}

const ExpandableRow: React.FC<{ row: IDebtor, selected: boolean, onClick: (accountId: string) => void, selectedDBTNumber: string }> = (props) => {
    const { row } = props;
    const classes = useRowStyles();
    const { state } = useGlobalState();
    const referralDate = moment(row?.referralDate).format(state.GlobalUtils?.settingValue);
    const statusDate = moment(row?.statusDate).format(state.GlobalUtils?.settingValue);
    const [GetDebtorNo, setGetDebtorNo] = useState<string>();
    const [isActiveStatus, setGetActivestatus] = useState(false);
    const prevCount = usePrevious(props.selectedDBTNumber);

    useEffect(() => {
        if (prevCount === undefined) {
            setGetDebtorNo("");
        } else if (prevCount === null || prevCount === "") {
            setGetDebtorNo(row.agencyID);
        } else {
            setGetDebtorNo(prevCount);
        }
    }, [props.selectedDBTNumber, prevCount])


    return (
        <React.Fragment>
            <TableRow style={row.isActive === true && props.selected ? { backgroundColor: "#1E90FF", fontSize: 10 } : row.isActive === true ? { backgroundColor: "white", color: "black", fontSize: '14px' } : props.selected ? { backgroundColor: "white", fontSize: 10, textOverflow: 'ellipsis', flexWrap: 'wrap' } : null}
                onClick={(_) => { props.onClick(row.agencyID); (row.agencyID !== props.selectedDBTNumber ? null : setGetDebtorNo("")); }} className={`${classes.root} ${classes.root1} `}>
                <TableCell scope="row" className={props.selected === true ? classes.selectedRow : classes.nonSelectedRow} >
                    {row?.agencyID}
                </TableCell>
                <TableCell align='left' className={props.selected === true ? classes.selectedRow : classes.nonSelectedRow} >{row?.clntAcct1}</TableCell>
                <TableCell align='left' className={props.selected === true ? classes.selectedRow : classes.nonSelectedRow}>{row?.client_Name_1}</TableCell>
                <TableCell align='left' className={props.selected === true ? classes.selectedRow : classes.nonSelectedRow}>{row?.client_Name_2}</TableCell>
                <TableCell align='left' className={props.selected === true ? classes.selectedRow : classes.nonSelectedRow} style={{ textOverflow: 'ellipsis', flexWrap: 'wrap', wordBreak: 'break-all' }}>{row?.name}</TableCell>
                <TableCell align='left' className={props.selected === true ? classes.selectedRow : classes.nonSelectedRow}>{row?.address},&nbsp;{row.city},<br />{row.state},{row.zip}</TableCell>
                <TableCell align='left' className={props.selected === true ? classes.selectedRow : classes.nonSelectedRow}>{row?.phoneNumber}</TableCell>
                <TableCell align='left' className={props.selected === true ? classes.selectedRow : classes.nonSelectedRow}>${row?.principalDue.toFixed(2)}</TableCell>
                <TableCell align='left' className={props.selected === true ? classes.selectedRow : classes.nonSelectedRow}>${row?.fee.toFixed(2)}</TableCell>
                <TableCell align='left' className={props.selected === true ? classes.selectedRow : classes.nonSelectedRow}>${row?.principalReceived.toFixed(2)}</TableCell>
                <TableCell align='left' className={props.selected === true ? classes.selectedRow : classes.nonSelectedRow}>${row?.totalDue?.toFixed(2)}</TableCell>
                <TableCell align='left' className={props.selected === true ? classes.selectedRow : classes.nonSelectedRow}>{row?.status.replace('|', " ")}</TableCell>
                <TableCell align='left' className={props.selected === true ? classes.selectedRow : classes.nonSelectedRow}>{row?.statusDate !== null ? statusDate : null}</TableCell>
                <TableCell align='left' className={props.selected === true ? classes.selectedRow : classes.nonSelectedRow}>{row?.referralDate !== null ? referralDate : null}</TableCell>
            </TableRow>
            <TableRow className={props.selected ? classes.root : classes.rootHidden}>
                <TableCell scope="row" colSpan={Columns.length} className={classes.rowexpand}>
                    <Collapse in={props.selected} timeout="auto" unmountOnExit >
                        <ReportsDetails DebtorDetails={row} isBlocked={isActiveStatus} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    )
}

export default ExpandableRow