import {
  Button, Dialog, DialogActions, DialogContent, Typography, useMediaQuery, useTheme, makeStyles, Link, Snackbar
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useDownloadProgress } from '../../utils/apiHelper';
import { IDocumentDownloadFileResult } from '../../models/UploadLog/IDocumentDownloadFileResult';
import { IGetUploadLogData } from '../../models/UploadLog/GetUploadLogResult';
import { DialogTitleHeader } from '../../components/GlobalStyles/DialogStyle';
import Backdrop from '@material-ui/core/Backdrop';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AvatarTooltip from '../../components/GlobalStyles/AvatarTooltip';
import RadialProgress from '../../components/GlobalStyles/RadialProgress';

const useStyles = makeStyles((theme) => ({
  fileDownloadLink: {
    fontSize: 13,
    color: "blue"
  },
  yesbutton: {
    backgroundColor: 'green',
    borderRadius: '20px',
    color: 'white',
    fontSize: 12,
    '&:hover': {
      backgroundColor: 'green',
      color: 'white',
    }
  },
  nobutton: {
    background: "red",
    borderRadius: "20px",
    color: 'white',
    fontSize: 12,
    '&:hover': {
      backgroundColor: 'red',
      color: 'white',
    }
  },
  titleheader: {
    fontWeight: 'bold',
    marginTop: '5px',
    color: 'white',
    fontSize: 18
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}))

const DownloadDocumentUploadFile: React.FC<{ row: IGetUploadLogData }> = (props) => {
  const classes = useStyles();
  const { row } = props
  const { dispatch } = useGlobalState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const [fileExists, setFileExists] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose1 = () => {
    setOpen(false);
  };
  function handleClose(isDownload: boolean) {
    setOpen(false);
    if (isDownload) {
      getDownloadFileAsync();
    }
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setFileExists(false);
  };

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  function getDownloadFileAsync() {
    if (props?.row?.uploadMethod === 4) {
      handleDownloadAPIPlacementFile();
    } else {
      (async () => {
        setUploadLoading(true);
        dispatch({ type: GlobalStateAction.Busy });
        setProgress(0);
        try {
          intervalRef.current = setInterval(() => {
            setProgress(prev => (prev < 100 ? prev + 1 : 100));
          }, 2000);

          const progressUpdate = (progressEvent: ProgressEvent) => {
            if (progressEvent.total && progressEvent.total > 0) {
              const percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setProgress(percentComplete);
              if (percentComplete === 100) {
                clearInterval(intervalRef.current);
              }
            }
          };
          const responseDownloadFile = await useDownloadProgress<IDocumentDownloadFileResult>(`UploadLog/DownloadDocumentFile?fileId=${row.fileId}`, {},
            progressUpdate
          );
          const arrayBuffer = base64ToArrayBuffer(responseDownloadFile.data.fileContent);
          createAndDownloadBlobFile(arrayBuffer, responseDownloadFile.data.fileName);
          setProgress(100);
        } catch (error) {
          console.error('Download Error:', error);
          setFileExists(true);
        } finally {
          setUploadLoading(false);
          clearInterval(intervalRef.current);
          setProgress(0);
          dispatch({ type: GlobalStateAction.Idle });
        }
      })();
    }
  }

  const handleDownloadAPIPlacementFile = () => {
    (async () => {
      setUploadLoading(true)
      let request = {
        "blobUri": props?.row?.blobUri
      }
      await useDownloadProgress<any>("File/TrustStatementPDFFileDownload", request, (progressEvent) => {
        const percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentComplete);
      }).then((r) => {
        const arrayBuffer = base64ToArrayBuffer(r.data);
        createAndDownloadBlobFile(arrayBuffer, props?.row?.fileName);
      }).catch(() => {
        setFileExists(true);
      }).finally(() => {
        setOpen(false);
        setUploadLoading(false);
      });
    })()
  }

  function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  function createAndDownloadBlobFile(body, filename) {
    const blob = new Blob([body]);
    const fileName = `${filename}`;
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <React.Fragment>
      <Typography className={classes.fileDownloadLink} variant="body1" display="block" gutterBottom>
        <AvatarTooltip title={row?.fileName}>
          <Link href="#" onClick={handleClickOpen} color="inherit" style={{ width: '200px', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {row?.fileName}
          </Link>
        </AvatarTooltip>

        <Backdrop className={classes.backdrop} open={uploadLoading}>
          <RadialProgress value={progress} />
        </Backdrop>

      </Typography>
      <Dialog maxWidth={'sm'} fullScreen={fullScreen} PaperProps={{ style: { borderRadius: 15 } }}
        open={open} onClose={(_) => { handleClose(false); }} aria-labelledby="responsive-dialog-title"
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose1}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            DOWNLOAD FILE
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <Typography variant="h6" style={{ color: "black" }} gutterBottom>
            Are you sure, you want to Download this file ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" onClick={(_) => { handleClose(true); }} className={classes.yesbutton} color="primary" autoFocus>
            Yes
          </Button>
          <Button variant="contained" size="small" autoFocus className={classes.nobutton} onClick={(_) => { handleClose(false); }} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={fileExists} className="snackBarStyle" anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={() => { setFileExists(false) }} severity="error" className="alertStyle">
          File does not exists!
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default DownloadDocumentUploadFile