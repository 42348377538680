import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    div: {
        flexGrow: 1,
        padding: 8
    },
    searchBox: {
        borderRadius: "30px",
        border: '2px solid #215176',
        height: '35px',
        textIndent: '10px',
        fontSize: 14,
        width: '90%',
        paddingLeft: '10px',
        marginBottom: '5px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        '@media (max-width: 600px)': {
            marginBottom: '5px',
        },
    },
    outlinedInput: {
        fontSize: 14,
        color: "black",
        '&$focused $notchedOutline': {
            color: 'Red',
            border: '0px'
        },
    },
    notchedOutline: {
        color: 'white',
        border: '0px'
    },
    searchInput: {
        padding: '7px',
    },
    heading: {
        textAlign: 'left',
    },
    btnManTemp: {
        borderRadius: '20px',
        float: 'right',
        fontSize: 12
    },
    btnManTemp1: {
        borderRadius: '20px',
        float: 'left',
        fontSize: 12,
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    tablebody: {
        maxHeight: 500,
        minHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 210px)',
            minHeight: 'calc( 100vh - 210px)',
        },
    },
    tablebody1: {
        maxHeight: 500,
        minHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 200px)',
            minHeight: 'calc( 100vh - 200px)',
        },
    },
    TableCellStyle: {
        background: "#007FFF",
        color: "white",
        fontSize: 13,
        padding: '2px 0px 2px 15px',
        TextAlign: 'left'
    },
    TableCellStyle1: {
        fontSize: 13,
        padding: '4px 0px 4px 15px',
    },
    footer: {
        marginTop: '15px',
        float: 'right',
        marginRight: '60px',
        '& .MuiTypography-body1': {
            fontSize: 12,
        }
    },
    footerStyle: {
        "& > td > div": {
            height: 30,
            minHeight: 30
        },
        height: 30
    },
    paper1: {
        fontSize: 12,
        border: "2px solid black",
    },
    smallfont: {
        fontSize: 13,
    },
    selecttemplate: {
        width: '100%',
        height: 40,
        fontSize: 13,
        borderRadius: '5px',
        borderBottom: '3px solid blue',
        '& .MuiFormControl-marginNormal': {
            marginTop: '3px',
            fontSize: 13,
        }
    },
    formTemplateControl: {
        minWidth: 200,
    },
    dialogePaper: {
        minWidth: "880px"
    },
    dialogePaperFullScreen: {
        minWidth: "1500px"
    },
    customizeDialogePaper: {
        minWidth: "1500px"
    },
    manageTemp: {
        borderRadius: '20px',
        float: 'left',
        fontSize: 12,
    },
    createtemplate: {
        borderRadius: 20,
        fontSize: 12,
        marginBottom: '0px',
        marginTop: '3px'
    },
    customizeStyle: {
        borderRadius: 20,
        marginRight: '10px',
        fontSize: 12,
        marginTop: '5px'
    },
    addButton: {
        borderRadius: 20,
        marginRight: '10px',
        fontSize: 12,
        marginTop: '12px',
        marginLeft: '10px'
    },
    customizeButton: {
        fontSize: 12,
        marginTop: '5px'
    },
    cancelbtn: {
        borderRadius: 20,
        background: "red",
        marginRight: '20px',
        fontSize: 12,
        '&:hover': {
            background: "red",
        }
    },
    edittemplate: {
        borderRadius: 20,
        fontSize: 12,
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    textAlginEnd: {
        textAlign: 'left',
        fontSize: 12,
        padding: '2px 0px'
    },
    textStyle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    textStyleFont: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    textStyle1: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'blue'
    },
    gridImageStyle: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
    },
    titleConfirm: {
        color: "black",
        fontSize: 17
    },
    //Delete Template Css
    yesButton: {
        background: "green",
        color: 'white',
        bottom: '0.2cm',
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "green",
        }
    },
    noButton: {
        background: "red",
        display: 'flex',
        bottom: '0.2cm',
        color: 'white',
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "red",
        }
    },
    image: {
        width: "150px",
        height: "150px",
    },
    checkAlignStyle: {
        maxHeight: 150,
        minHeight: 150,
        minWidth: 420,
        maxWidth: 420,
    },
    manageTemplate: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        marginTop: '10px'
    },
    dialogePaperManage: {
        minWidth: "880px",
        overFlowY: 'hidden',
        overFlowX: 'hidden',
        '& .MuiDialogContent-root': {
            overflowY: 'hidden',
            overflowX: 'hidden'
        }
    },
    dialogeDelete: {
        minWidth: "480px",
    },
    managebtn: {
        borderRadius: '20px'
    },
    checkboxStyle: {
        width: '200px'
    },
    permission: {
        display: 'flex',
        flexDirection: 'row',
    },
    deleteIcon: {
        color: 'red',
        fontSize: '28px !important',
    },
    tablecellStyle: {
        background: '#007FFF',
        color: 'white'
    },
    managetable: {
        marginTop: '10px'
    },
    managetempltebtn: {
        borderRadius: 20,
        marginRight: '5px',
        fontSize: 12
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    boxStyle: {
        padding: '8px 10px'
    },
    tablebodyActivity: {
        maxHeight: 600,
        minHeight: 600,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 178px)',
            minHeight: 'calc( 100vh - 178px)',
        },
    },
    DisplayOrderStyle: {
        fontSize: 13,
        padding: '4px 0px 4px 15px',
        paddingLeft: '60px'
    },
    searchBox1: {
        borderRadius: "30px",
        border: '2px solid #215176',
        height: '35px',
        textIndent: '10px',
        fontSize: 14,
        width: '98%',
        paddingLeft: '10px',
        marginBottom: '5px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        '@media (max-width: 600px)': {
            marginBottom: '5px',
        },
    },
    activityName: {
        color: 'blue',
        marginTop: '5px'
    },
    textfieldname: {
        transition: "all 0.5s ease",
        borderRadius: '5px',
        fontSize: '12px',
        marginTop: '10px'
    },
    closeButton: {
        background: "red",
        borderRadius: 20,
        marginRight: '10px',
        color: 'white',
        fontSize: 12,
        '&:hover': {
            background: "red",
        },
    },
    blueBorderBottom: {
        fontSize: 14,
        borderRadius: '4px 4px 8px 8px',
        '& .MuiOutlinedInput-input': {
            borderBottom: '3px solid blue',
            borderRadius: '4px 4px 8px 8px',

        },
        '& .MuiInputBase-input': {
            padding: '11px',
        }
    },
    redBorderBottom: {
        fontSize: 14,
        borderRadius: '4px 4px 8px 8px',
        '& .MuiOutlinedInput-input': {
            borderBottom: '3px solid red',
            borderRadius: '4px 4px 8px 8px',
        },
        '& .MuiInputBase-input': {
            padding: '11px',
        }
    },
    greenBorderBottom: {
        fontSize: 14,
        borderRadius: '4px 4px 8px 8px',
        borderColor: 'green',
        '& .MuiOutlinedInput-input': {
            borderBottom: '4px solid green',
            borderRadius: '4px 4px 8px 8px',
            '& >fieldset': {
                borderColor: 'green',
            },
        },
        '& .MuiInputBase-input': {
            padding: '11px',
            borderColor: 'green',
        },
    },
    blueBorderColor: {
        borderWidth: 1
    },
    redBorderColor: {
        borderColor: "red",
        borderWidth: 2
    },
    greenBorderColor: {
        borderColor: "green",
        borderWidth: 2
    },
    inputFontSize: {
        fontSize: '15px'
    },
    textFieldBorderRed: {
        transition: "all 0.5s ease",
        borderRadius: '5px',
        fontSize: '12px',
        marginTop: '10px',
    },
    textFieldBorderGreen: {
        transition: "all 0.5s ease",
        borderRadius: '5px',
        fontSize: '12px',
        marginTop: '10px',
        '& .MuiInput-underline:after': {
            borderColor: 'green',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'blue',
        },
    },
    gridflex: {
        borderRadius: 8,
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'
    },
    tableStyle: {
        borderRadius: 8,
    },
    flexDispaly: {
        display: 'flex',
    },
    sidebar: {
        minWidth: "100px",
        // backgroundColor: '#a2cffe',
        transition: "all 0.2s ease",
        display: 'block',
        whiteSpace: 'nowrap',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        // boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px',
        borderRight: '1px solid gray',
        padding: '10px 10px',
    },
    mainmenu: {
        minWidth: "400px",
        display: 'block',
        padding: '10px 10px',
        borderRight: '1px solid gray',
        boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px',
        /*  maxHeight: 800,
         minHeight: 800,
         [theme.breakpoints.up('lg')]: {
             maxHeight: 'calc( 100vh - 400px)',
             minHeight: 'calc( 100vh - 400px)',
         } */
    },
    rightMenu: {
        display: 'block',
        padding: '10px 10px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px',
    },
    customizeHeader: {
        padding: '5px 5px',
        textAlign: 'center',
        backgroundColor: '#007fff',
        color: 'white'
    },
    controlHead: {
        fontSize: '14px',
        textAlign: 'center'
    },
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    labelStyle: {
        fontSize: '15px',
        marginTop: '5px'
    },
    adminLayout: {
        maxHeight: 800,
        minHeight: 800,
        // overflowX: 'hidden',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 410px)',
            minHeight: 'calc( 100vh - 410px)',
        }
    },
    fullLayout: {
        maxHeight: 800,
        minHeight: 800,
        // overflowX: 'hidden',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 130px)',
            minHeight: 'calc( 100vh - 130px)',
        }
    },
    fullLayout1: {
        maxHeight: 800,
        minHeight: 800,
        // overflowX: 'hidden',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 245px)',
            minHeight: 'calc( 100vh - 245px)',
        }
    },
    rowhead: {
        background: 'lightgray',
        color: 'black',
        fontSize: '12px',
        padding: '2px 5px 2px 8px'
    },
    smallFont: {
        textOverflow: 'ellipsis',
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: 'hidden',
        '&:hover': {
            overflow: 'visible',
            transition: '0.10s',
        }
    },
    controlStyle: {
        width: '200px',
        fontSize: '14px',
        color: 'black',
        marginTop: '5px',
        textOverflow: 'ellipsis',
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: 'hidden'
    },
    regexStyle: {
        width: '150px',
        fontSize: '13px',
        color: 'black',
        textOverflow: 'ellipsis',
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: 'hidden'
    },
    controlPadding: {
        padding: '3px 5px 5px 8px',
        // borderBottom: '0px solid white !important',
        '&.MuiTableCell-root': {
            // borderBottom: '0px solid white !important',
        }
    },
    title: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    activityDropDownStyle: {
        width: '100%',
        marginTop: '7px',
    },
    formControl: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexControl: {
        display: 'flex',
        flexDirection: 'column'
    },
    controlName: {
        marginTop: '4px',
        marginLeft: '4px'
    },
    editDisplay: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '1%',
        marginTop: '12px'
    },
    actionStyle: {
        display: 'flex',
        gap: '10%'
    },
    groupName: {
        width: '100%',
        marginTop: 10
    },
    saveActivity: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '1%',
        marginTop: '5px'
    },
    IconPadding: {
        padding: '0px',
        marginRight: '20px',
    }
}));
export { useStyles }