import { IAPIError } from "./../models/IApiError";
import { AxiosResponse } from "axios";
import { ErrorConstants } from "../constants/ErrorConstants";
import { instance } from "./axisoInstance";
import { loginRequest } from "../auth/authConfig";
import { msalInstance } from "../index";

async function getToken(): Promise<string> {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });
  return response.accessToken;
}

export function parseApiResponse<T>(
  apiCall: Promise<AxiosResponse<any>>,
  resolve: (value: AxiosResponse<T> | PromiseLike<AxiosResponse<T>>) => void,
  reject: any
) {
  apiCall
    .then((res) => {
      return res;
    })
    .then((body) => {
      if (body.status == 200 || body.status == 202) {
        resolve(body);
      } else {
        const unhandledError: IAPIError = {
          code: ErrorConstants.UnhandledError.Code,
          message: ErrorConstants.UnhandledError.Message,
        };
        reject("error");
      }
    })
    .catch((err) => {
      reject(err);
    });
}

export async function useFetch<T>(
  requestUrl: string,
  userToken: string = ""
): Promise<AxiosResponse<T>> {
  var promise = instance.get(requestUrl, {
    headers: {
      Authorization: "Bearer " + (await getToken()),
    },
  });

  return new Promise<AxiosResponse<T>>((resolve1, reject1) => {
    parseApiResponse(promise, resolve1, reject1);
  });
}

export async function usePostDownload<T>(
  requestUrl: string,
  requestData: any,
  userToken: string = ""
): Promise<AxiosResponse<T>> {
  let promise = instance.post(requestUrl, requestData, {
    headers: {
      Authorization: "Bearer " + (await getToken()),
    },
    responseType: "arraybuffer",
  });

  return new Promise<AxiosResponse<T>>((resolve1, reject) => {
    parseApiResponse(promise, resolve1, reject);
  });
}

export async function usePost<T>(
  requestUrl: string,
  requestData: any,
  userToken: string = ""
): Promise<AxiosResponse<T>> {
  let promise = instance.post(requestUrl, requestData, {
    headers: {
      Authorization: "Bearer " + (await getToken()),
    },
  });

  return new Promise<AxiosResponse<T>>((resolve1, reject) => {
    parseApiResponse(promise, resolve1, reject);
  });
}

export async function useDelete<T>(
  requestUrl: string,
  userToken: string = ""
): Promise<AxiosResponse<T>> {
  let promise = instance.delete(requestUrl, {
    headers: {
      Authorization: "Bearer " + (await getToken()),
    },
  });

  return new Promise<AxiosResponse<T>>((resolve1, reject) => {
    parseApiResponse(promise, resolve1, reject);
  });
}

export async function useUploadProgress<T>(
  requestUrl: string,
  requestData: any,
  userToken: string = "",
  onProgress?: (uploadedBytes: ProgressEvent) => void
): Promise<AxiosResponse<T>> {
  let promise = instance.post(requestUrl, requestData, {
    headers: {
      Authorization: "Bearer " + (await getToken()),
    },
    onUploadProgress: (progressEvent) => {
      if (onProgress) {
        onProgress(progressEvent);
      }
    },
  });

  return new Promise<AxiosResponse<T>>((resolve1, reject) => {
    parseApiResponse(promise, resolve1, reject);
  });
}

export async function useDownloadProgress<T>(
  requestUrl: string,
  requestData: any,
  onProgress?: (progress: ProgressEvent) => void
): Promise<AxiosResponse<T>> {
  const token = await getToken();
  let promise = instance.get(requestUrl, {
    headers: {
      Authorization: "Bearer " + (await getToken()),
    },
    params: requestData,
    onDownloadProgress: (progressEvent) => {
      if (onProgress) {
        onProgress(progressEvent);
      }
    },
  });
  return new Promise<AxiosResponse<T>>((resolve1, reject) => {
    parseApiResponse(promise, resolve1, reject);
  });
}

export async function usePostDownloadProgress<T>(
  requestUrl: string,
  requestData: any,
  onProgress?: (progress: ProgressEvent) => void
): Promise<AxiosResponse<T>> {
  const token = await getToken();

  let promise = instance.post(requestUrl, requestData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    onDownloadProgress: (progressEvent) => {
      if (onProgress) {
        onProgress(progressEvent);
      }
    },
    responseType: 'blob', // Ensure that the response is treated as a file
  });

  return new Promise<AxiosResponse<T>>((resolve, reject) => {
    parseApiResponse(promise, resolve, reject);
  });
}
