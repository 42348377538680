import React from "react";
import {
    Grid, Paper, Table, TableBody, TableCell, TextField, TableContainer, TableHead, TableRow, Backdrop, Typography,
    IconButton, InputAdornment, Box, TablePagination
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useStyles } from "./GlobalTemplateCSS";
import CreateMappingTemplate from "./CreateMappingTemplate";
import EditMappingTemplate from "./EditMappingTemplate";
import { IGetBlobFolders } from "../../../models/Files/IGetBlobFolders";
import { useFetch, usePost } from "../../../utils/apiHelper";
import { IGlobalMappingTemplateList } from "../../../models/Placements/IMappingTemplate";
import _ from "lodash";
import PuffLoader from "react-spinners/PuffLoader";
import { ITemplateList } from "../../../models/Admin/ClientPlacements/ITemplateList";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@material-ui/icons/Close";
import Footer from "../../Footer/Footer";
import { useGlobalState } from "../../../store/GlobalStore";
import { IAllTemplateList } from "../../../models/Admin/ClientPlacements/IAllTemplateList";

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const headCells = [
    { id: "TemplateName", disablePadding: false, label: "TEMPLATE NAME", sortable: true, },
    { id: "FolderName", disablePadding: false, label: "FOLDER NAME", sortable: true, },
    { id: "CreateDate", disablePadding: false, label: "MAP TEMPLATE", sortable: true, },
    { id: "Edit", disablePadding: false, label: "ACTION", sortable: true },
];

function EnhancedTableHead(props) {
    const { classes } = props;
    const useStyles = makeStyles({
        table: {
            minWidth: 700,
        },
    });

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        id="CPO_TableHeader"
                        className={classes.TableCellStyle}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const GlobalMappingTemplate = () => {
    const classes = useStyles();
    const [ListContainers, setListContainers] = useState<IGetBlobFolders[]>([]);
    const [showProgress, setShowProgressBar] = useState(false);
    const [GlobalTemplateList, setGlobalTemplateList] = useState<IGlobalMappingTemplateList[]>([]);
    const { state } = useGlobalState();
    const [searchParameters, setSearchParameters] = useState<string>(null);
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [recordsPerPage, setRecordsPerPage] = useState<number>(25);
    const UserName = state.userAccessContext?.id;
    const [getAllTemplateList, setGetAllTemplateList] = useState<ITemplateList[]>([]);

    useEffect(() => {
        ContainerList();
        LoadTemplateList();
    }, []);

    useEffect(() => {
        GlobalGetAllTemplateList();
    }, [pageNumber, recordsPerPage]);

    /* List all the save mapping template */
    const GlobalGetAllTemplateList = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "searchParameter": searchParameters,
                "rowsPerPage": recordsPerPage,
                "startingRecordNumber": ((pageNumber * recordsPerPage) + 1),
            };
            await usePost<{ searchMappingResult: IGlobalMappingTemplateList[]; totalCount: number; }>("Placements/SearchGlobalMappingTemplate", request).then((result) => {
                setGlobalTemplateList(result?.data?.searchMappingResult);
                setTotalRecords(result?.data?.totalCount);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })();
    };

    const LoadTemplateList = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "rowsPerPage": 10000,
                "startingRecordNumber": 1
            }
            await usePost<{ templateDetails: IAllTemplateList[], totalCount: number }>("ClientPlacement/GetAllTemplate", request).then((GetAllTemplateList) => {
                setGetAllTemplateList(GetAllTemplateList?.data["templateDetails"]);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })().finally(() => {
            setShowProgressBar(false);
        });
    };


    /* List all the save mapping template */
    const clearSearchResult = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "searchParameter": null,
                "rowsPerPage": recordsPerPage,
                "startingRecordNumber":
                    searchParameters.trim() !== ""
                        ? pageNumber * 0 + 1
                        : pageNumber * recordsPerPage + 1,
            };
            await usePost<{ searchMappingResult: IGlobalMappingTemplateList[]; totalCount: number; }>("Placements/SearchGlobalMappingTemplate", request)
                .then((result) => {
                    setGlobalTemplateList(result?.data?.searchMappingResult);
                    setTotalRecords(result?.data?.totalCount);
                })
                .finally(() => {
                    setShowProgressBar(false);
                });
        })();
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecordsPerPage(+event.target.value);
        setPageNumber(0);
    };

    const handlePageChange = (event, page) => {
        setShowProgressBar(true);
        setPageNumber(page);
    };

    const ContainerList = () => {
        (async () => {
            await useFetch<IGetBlobFolders[]>("File/GetBlobFolders")?.then((c) => {
                c?.data?.unshift(getDefaultContainer());
                let response = _.cloneDeep(c?.data);
                setListContainers(
                    response?.filter((r) => r?.folder_Id || r?.folder_Id === -1)
                );
            });
        })().finally(() => {
            setShowProgressBar(false);
        });
    };

    function clearSearchValues() {
        setSearchParameters("");
        setShowSearchIcon(true);
    }

    const getDefaultContainer = () => {
        let defaultContainer: IGetBlobFolders = {
            folder_Id: -1,
            folder_Name: "None",
            fileContains: null,
        };
        return defaultContainer;
    };

    return (
        <Grid container spacing={1} className={classes.boxStyle}>
            <Grid item xs={3}>
                <Typography variant="h6" style={{ textAlign: "left" }}>
                    <b>MANAGE GLOBAL TEMPLATE</b>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField
                    value={searchParameters}
                    variant="outlined"
                    type="text"
                    placeholder="Search..."
                    className={classes.searchBox}
                    onChange={(e) => {
                        setSearchParameters(e.target.value);
                    }}
                    onKeyPress={(event) => {
                        if (event.key === "Enter" && searchParameters != "") {
                            GlobalGetAllTemplateList();
                            setShowSearchIcon(false);
                        } else if (event.key === "Enter" && searchParameters === "") {
                            clearSearchResult();
                            setShowSearchIcon(true);
                        }
                    }}
                    inputProps={{ maxlength: 255 }}
                    InputProps={{
                        classes: {
                            root: classes.outlinedInputs,
                            notchedOutline: classes.notchedOutline,
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                {showSearchIcon === true ? (
                                    <IconButton onClick={(e) => { GlobalGetAllTemplateList(); setShowSearchIcon(false); }} style={{ marginLeft: 15 }} >
                                        <SearchIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={(e) => { clearSearchResult(); setSearchParameters(""); clearSearchValues(); }} >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Backdrop className={classes.backdrop} open={showProgress}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <Grid item xs={2}>
                <CreateMappingTemplate onSave={GlobalGetAllTemplateList} CloudContainer={ListContainers} />
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper} className={`${classes.tablebodyActivity} ${"scrollbox"} ${"on-scrollbar"}`} >
                    <Table aria-label="customized table" size="small" stickyHeader>
                        <EnhancedTableHead classes={classes} />
                        <TableBody>
                            {GlobalTemplateList?.map((row) => (
                                <StyledTableRow key={row?.placement_map_id}>
                                    <TableCell component="th" scope="row">
                                        {row?.map_name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row?.file_spec}
                                    </TableCell>
                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                        {row?.map_TemplateName}
                                    </TableCell>
                                    <TableCell align="left" className={classes.TableCellStyle1} >
                                        <EditMappingTemplate CloudContainer={ListContainers} row={row} onUpdate={GlobalGetAllTemplateList} TemplateList={getAllTemplateList} />
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {!showProgress && !GlobalTemplateList?.length ?
                        <Typography variant="h6" gutterBottom className={classes.WarningMessage}>
                            No records to display...
                        </Typography>
                        : null
                    }
                </TableContainer>
                <Box m={0} display="flex" style={{ flexDirection: "row" }}>
                    <Box m={0} width="52%">
                        <div className="footer">
                            <Footer />
                        </div>
                    </Box>
                    <Box m={0} width="48%">
                        <div className="pagination">
                            <TablePagination
                                rowsPerPageOptions={[25, 50, 100]}
                                component="div"
                                count={totalRecords}
                                rowsPerPage={recordsPerPage}
                                page={pageNumber}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </div>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default GlobalMappingTemplate;
