import { AppBar, Toolbar } from '@material-ui/core';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import * as constant from "../constants/Constant";
import Authorize from '../images/header/header-logo.svg';
import { SignOutButton } from './Authentication/SignOutButton';

export const LogoutSignin: React.FC<{ userName: string }> = (props) => {

    return (
        <React.Fragment>
            <AppBar position="static" style={{ backgroundColor: 'White' }}>
                <Toolbar>
                    <Grid container spacing={3}>
                        <Grid item xs={2}>
                            <img alt="ARM_Solutions Logo" width="160px" height="55px" src={constant.ARMLogo} />
                        </Grid>
                        <Grid item xs={8} >
                            <Typography align='center' color={'black'} mt={2}>
                                Logged in as : <span style={{ color: 'blue' }}><b>{props?.userName}</b></span>
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
                            <SignOutButton />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Grid container spacing={0}>
                <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} p={6} >
                    <img src={Authorize} alt="401_Authorize" width="800px" height="250px" />
                    <Typography variant='body1' color={'#0136a1'} fontSize={'21px'} textAlign={'center'} pt={3}>
                        Your authentication was successful; however, your account is not set up with WebView. <br />please reach out to <b style={{ color: 'blue' }}>"{constant.serviceEmailID}"</b>.
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment >
    );
};

