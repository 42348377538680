import { IInsertTransactionLog } from '../../models/UpdateHistory/GetTransactionOptions'
import { usePost } from '../../utils/apiHelper'

export function InsertTransactionLog(userId: number, dbt_No: string, transactionlogs: IInsertTransactionLog[],isAgentUser: boolean): any {
  (async () => {
    let loadHistory = {
      "updatedBy": userId,
      "dbt_no": dbt_No,
      "operations": transactionlogs,
      "isAgentUser": isAgentUser
    }
    await usePost('Log/TransactionHistoy', loadHistory)
  })()
}
