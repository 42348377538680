export const ErrorConstants = {
  NoDataFound: {
    Code: 'NoDataFound',
    Message: 'No Data',
  },

  RequestTimedOut: {
    HttpStatusCode: 408,
    Code: 'RequestTimedOut',
    Message: 'Request timed out',
  },

  UnhandledError: {
    Code: 'UnhandledError',
    Message: 'Unhandled Error',
  },
}
