import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: 'white',
        minWidth: 'auto',
        maxWidth: "95%",
        marginBottom: "10px",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10,
        minHeight: 550,
        maxHeight: 550,
        border: '2px solid #215176',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    },
    searchIcon: {
        borderRadius: 50,
        background: "white",
        width: '65%',
        height: '40px',
        textAlign: 'left',
        display: 'flex',
        border: '2px #215176 solid',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        fontSize: 14,
        textIndent: '10px',
        marginTop: '5px'
    },
    searchIcon1: {
        borderRadius: 50,
        background: "white",
        width: '65%',
        height: '40px',
        border: '2px #215176 solid',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        fontSize: 14,
        textIndent: '10px',
        marginLeft: '-45px',
        marginTop: '5px'
    },
    description: {
        border: '2px #215176 solid',
        width: "100%",
        height: '160px',
        borderRadius: 10,
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        marginTop: '10px',
        fontSize: 14,
    },
    description1: {
        border: '2px #215176 solid',
        width: "90%",
        borderRadius: 10,
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        marginTop: '10px',
        minHeight: '163px',
        maxHeight: '163px',
        marginLeft: '15px'
    },
    msgDescription: {
        marginTop: 0,
    },
    paper: {
        width: '100%',
        overflow: 'auto',
        overflowX: 'hidden',
        borderTop: '2px #215176 solid',
        minHeight: '240px',
        maxHeight: '240px',
    },
    gridstyle: {
        width: '100%',
        border: '2px #215176 solid',
        marginTop: "18px",
        borderRadius: 5,
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    div: {
        flexGrow: 1
    },
    grid: {
        padding: '0px',
        borderRadius: 8,
    },
    maingrid: {
        backgroundColor: 'white',
        borderRadius: '20px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    sendNotification: {
        width: '235px',
        height: '35px',
        alignItems: 'right',
        textAlign: 'right',
        borderRadius: 50,
        fontSize: 12,
        fontWeight: 'bold',
        marginLeft: '35px',
        marginTop: '5px'
    },
    clientlist: {
        textAlign: 'left',
        fontSize: 14,
    },
    clientcheckbox: {
        color: "#1bc42c",
        width: 50,
        height: 22
    },
    titleHeader: {
        textAlign: 'center',
        marginLeft: 20
    },
    selectallbutton: {
        marginTop: '80px',
        fontSize: 12,
        fontWeight: 'bold',
        borderRadius: 20,
        alignItems: "center",
        justifyContent: "center",
        width: "8.5vw",
    },
    removeallbutton: {
        fontSize: 12,
        margin: '0px 5px',
        fontWeight: 'bold',
        borderRadius: 20,
        marginTop: '20px',
        width: "8.5vw",
    },
    label: {
        '&$focused': {
            color: 'white',
            border: '0px'
        },
    },
    focused: {},
    outlinedInput: {
        fontSize: 16,
        '&$focused $notchedOutline': {
            color: 'white',
            border: '0px'
        },
    },
    notchedOutline: {
        color: 'white',
        border: '0px'
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    yesButton: {
        background: "green",
        color: 'white',
        borderRadius: 20,
        fontSize: 12,
        right: '15px',
        '&:hover': {
            background: "green",
        }
    },
    noButton: {
        background: "red",
        borderRadius: 20,
        display: 'flex',
        color: 'white',
        right: '10px',
        fontSize: 12,
        '&:hover': {
            background: "red",
        }
    },
    MessageStyle: {
        color: "black",
        fontSize: 18
    },
    circlarBarStyle: {
        color: 'white',
        height: "20px",
        width: "20px",
        marginLeft: '5px'
    },
    ErrorMessageStyle: {
        color: "red",
        marginTop: '5px',
        fontSize: 12
    },
    checkBoxStyle: {
        color: "#00e676",
        width: 50,
        height: 22,
    },
    btnFont: {
        fontSize: 15,
    },
    textUser: {
        textAlign: 'left',
        fontSize: 13
    },
    inputFieldSize: {
        padding: '7px 14px',
        fontSize: 14
    },
    btnSpacing: {
        margin: '10px 0px',
        display: 'flex',
    },
    gridPadding: {
        padding: '0px 5px',
    },
    selectboxpadding: {
        paddingTop: 0,
    },
    bottomMargin: {
        marginBottom: '10px',
    },
    clientlistSpacing: {
        marginBottom: '0px',
        marginTop: '0px',
    },
    agencyfiled: {
        marginTop: '0.3cm',
        borderRadius: '10px',
        width: "90%",
        textAlign: 'left',
        fontSize: 20,
        border: "2px solid #215176",
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    agencyFiledErrorStyle: {
        marginTop: '0.3cm',
        borderRadius: '10px',
        width: "90%",
        fontSize: 20,
        border: "1px solid red",
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)'
    },
    agencyfieldnames: {
        marginTop: '17px',
        fontSize: 14,
        textAlign: 'left',
        marginLeft: '15px'
    },
    clientheader: {
        fontSize: 16,
        textAlign: 'center',
    },
    root2: {
        border: "2px solid #215176",
        borderRadius: 8,
        width: '90%',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        alignItems: 'center',
        marginTop: '12px',
        minHeight: '160px',
        maxHeight: '160px',
    },
    ulstyle: {
        listStyleType: "none",
        margin: '10px',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    selectReason: {
        width: "80%",
        marginLeft: '22px',
        border: '2px #215176 solid',
        borderRadius: 5,
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        marginTop: '10px',
    },
    paper1: {
        border: "2px solid black",
    },
    selectFieldPadding: {
        padding: 0,
        fontSize: 12
    },
    fileFontSize: {
        fontSize: 12,
        wordBreak: 'break-all',
        textAlign: 'left'
    },
    fileUploadButton: {
        borderRadius: 20,
        fontSize: 12,
        marginRight: '1cm',
        background: "#007FFF",
        color: "white",
        '&:hover': {
            background: "#007FFF",
        }
    },
    smallFont: {
        fontSize: 12
    },
    adminLayout: {
        maxHeight: 790,
        minHeight: 790,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 120px)',
            minHeight: 'calc( 100vh - 120px)',
        }
    },
    containerStyle: {
        height: '100vh',
        position: 'absolute',
        top: 0, bottom: 0, left: 0, right: 0,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
    },
    textStyle: {
        fontSize: 12,
        marginTop: '-5px'
    },
    paper2: {
        width: '100%',
        overflow: 'auto',
        overflowX: 'hidden',
        borderRadius: 10,
        borderTop: '2px #215176 solid',
        minHeight: '220px',
        maxHeight: '220px',
    },
    ErrorMessageStyle1: {
        color: "red",
        marginTop: '5px',
        fontSize: 12,
        fontWeight: 'bold',
    },
    tablebody: {
        maxHeight: 470,
        minHeight: 470,
        marginTop: '5px',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 215px)',
            minHeight: 'calc( 100vh - 215px)',
        },
    },
}));
export { useStyles };
