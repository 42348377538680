import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    div: {
        padding: 8
    },
    searchBox: {
        borderRadius: "30px",
        border: '2px solid #215176',
        height: '35px',
        textIndent: '10px',
        fontSize: 14,
        width: '95%',
        marginTop: '8px',
        paddingLeft: '10px',
        marginBottom: '5px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        '@media (max-width: 600px)': {
            marginBottom: '5px',
        },
    },
    outlinedInput: {
        fontSize: 14,
        color: "black",
        '&$focused $notchedOutline': {
            color: 'white',
            border: '0px'
        },
    },
    notchedOutline: {
        color: 'white',
        border: '0px'
    },
    searchInput: {
        padding: '7px',
    },
    heading: {
        textAlign: 'left',
    },
    headingTitle: {
        textAlign: 'left',
        marginLeft: '15px',
    },
    btnManTemp: {
        borderRadius: '20px',
        float: 'right',
        fontSize: 12,
        marginTop: '10px',
        marginRight: '10px'
    },
    btnManTempFilter: {
        borderRadius: '20px',
        float: 'right',
        fontSize: 12,
        marginTop: '10px',
        marginRight: '10px'
    },
    btnManTemp1: {
        borderRadius: '20px',
        float: 'left',
        fontSize: 12,
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    tablebody: {
        maxHeight: 500,
        minHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 211px)',
            minHeight: 'calc( 100vh - 211px)',
        },
    },
    tablebody1: {
        maxHeight: 500,
        minHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 210px)',
            minHeight: 'calc( 100vh - 210px)',
        },
    },
    activityBody: {
        maxHeight: 500,
        minHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 215px)',
            minHeight: 'calc( 100vh - 215px)',
        },
    },
    TableCellStyle: {
        background: "#007FFF",
        color: "white",
        fontSize: 13,
        padding: '2px 0px 2px 15px',
        TextAlign: 'left'
    },
    TableCellStyleHead: {
        background: "#007FFF",
        color: "white",
        fontSize: 13,
        padding: '2px 0px 2px 15px',
        TextAlign: 'left',
        border: '1px solid #007FFF'
    },
    TableCellDelete: {
        fontSize: 13,
        padding: '5px 0px 5px 15px',
        TextAlign: 'left',
        border: '1px solid #007FFF'
    },
    TableCellManage: {
        background: "#a2cffe",
        color: "black",
        fontSize: 13,
        padding: '2px 0px 2px 15px',
        TextAlign: 'left'
    },
    TableCellStyle1: {
        fontSize: 13,
    },
    reportCell: {
        padding: '3px 11px',
        fontSize: 13,
    },
    footer: {
        marginTop: '15px',
        float: 'right',
        marginRight: '60px',
        '& .MuiTypography-body1': {
            fontSize: 12,
        }
    },
    footerStyle: {
        "& > td > div": {
            height: 30,
            minHeight: 30
        },
        height: 30
    },
    clientheader: {
        fontSize: 14,
        textAlign: 'center',
    },
    paper1: {
        fontSize: 12,
        border: "2px solid black",
    },
    smallfont: {
        fontSize: 13,
    },
    autoCompleteFont: {
        fontSize: 13,
        padding: '4px 12px'
    },
    selecttemplate: {
        width: '94%',
        height: 40,
        fontSize: 13,
        borderRadius: '5px',
        borderBottom: '3px solid blue',
        '& .MuiFormControl-marginNormal': {
            marginTop: '3px',
            fontSize: 13,
        }
    },
    selecttemplate1: {
        marginTop: '8px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        borderRadius: 5,
        border: '2px solid #215176',
        backgroundColor: 'white',
        height: '38px',
        fontSize: 12,
        width: '100%',
        '& .MuiFormControl-marginNormal': {
            marginTop: '-1px',
            marginLeft: '0.1px',
            height: '35px',
            fontSize: 13,
            border: 'none'
        }
    },
    outlinedInput1: {
        fontSize: 13,
        color: "black",
        padding: '6px 30px 6px 10px !important',
        '&$focused $notchedOutline': {
            color: 'white',
            border: 'none',
        },
    },
    formTemplateControl: {
        minWidth: 200,
    },
    dialogePaper: {
        minWidth: "1500px"
    },
    dialogePaper1: {
        minWidth: "70%",
        minHeight: "70%"
    },
    dialogePaper2: {
        minWidth: "700px"
    },
    manageTemp: {
        borderRadius: '20px',
        float: 'left',
        fontSize: 12,
    },
    createtemplate: {
        borderRadius: 20,
        marginRight: '20px',
        fontSize: 12,
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    sidebar: {
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        borderRight: '1px solid gray',
        padding: '10px 10px',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px'
    },
    ReportScroll: {
        width: '100%',
        overflow: 'auto',
        overflowX: 'hidden',
        minHeight: '320px',
        maxHeight: '320px',
    },
    paddingCheckbox:
    {
        padding: '10px 5px 10px 10px'
    },
    cancelbtn: {
        borderRadius: 20,
        background: "red",
        marginRight: '20px',
        fontSize: 12,
        '&:hover': {
            background: "red",
        }
    },
    edittemplate: {
        borderRadius: 20,
        marginRight: '10px',
        fontSize: 12,
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    textAlginEnd: {
        textAlign: 'left',
        fontSize: 12,
        padding: '2px 0px'
    },
    textStyle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    textStyleFont: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    textStyle1: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'blue'
    },
    gridImageStyle: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
    },
    titleConfirm: {
        color: "black",
        fontSize: 17
    },
    //Delete Template Css
    yesButton: {
        background: "green",
        color: 'white',
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "green",
        }
    },
    noButton: {
        background: "red",
        color: 'white',
        fontSize: 12,
        marginRight: '20px',
        borderRadius: 20,
        '&:hover': {
            background: "red",
        }
    },
    noButton1: {
        background: "red",
        color: 'white',
        fontSize: 12,
        marginRight: 5,
        borderRadius: 20,
        '&:hover': {
            background: "red",
        }
    },
    image: {
        width: "150px",
        height: "150px",
    },
    checkAlignStyle: {
        maxHeight: 150,
        minHeight: 150,
        minWidth: 420,
        maxWidth: 420,
    },
    manageTemplate: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        marginTop: '5px'
    },
    dialogePaperManage: {
        minWidth: "1050px",
        overFlowY: 'hidden',
        overFlowX: 'hidden',
        '& .MuiDialogContent-root': {
            overflowY: 'hidden',
            overflowX: 'hidden'
        }
    },
    dialogeDelete: {
        minWidth: "480px",
    },
    managebtn: {
        borderRadius: '20px',
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    checkboxStyle: {
        width: '380px'
    },
    permission: {
        display: 'flex',
        flexDirection: 'row',
    },
    deleteIcon: {
        color: 'red',
        fontSize: '28px !important',
    },
    lockIcon: {
        color: 'black',
        fontSize: '28px !important',
    },
    tablecellStyle: {
        background: '#007FFF',
        color: 'white'
    },
    managetable: {
        marginTop: '10px',
    },
    managetempltebtn: {
        borderRadius: 20,
        marginRight: '5px',
        fontSize: 12
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    ErrorMessageStyle: {
        color: "red",
        marginTop: "10px",
        fontSize: '16px',
        fontWeight: 'bold'
    },
    TextHighlight: {
        color: 'black',
        fontWeight: 'bold',
        background: "yellow",
    },
    TextNormal: {
        color: 'black',
        fontSize: 13
    },
    TextNormal1: {
        color: 'white',
        fontSize: 15
    },
    submitbtn: {
        borderRadius: 20,
        marginRight: '10px',
        fontSize: 12,
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    globalAdmin: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    superAdminColor: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    maingrid: {
        backgroundColor: 'white',
        borderRadius: '10px',
        // border: '1px solid gray',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        filter: 'drop-shadow(0 -6mm 4mm gray))'
    },
    Title1: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginTop: '8px'
    },
    readOnlyAccess: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        marginLeft: '10px',
        marginTop: '10px'
    },
    formControlLabel: {
        backgroundColor: 'rgba(84, 214, 44, 0.16)', //#fadb7
        padding: '0px 8px 0px 0px',
        borderRadius: '5px',
        color: 'rgb(34, 154, 22)',
        float: 'left'
    },
    formControlLabelDisable: {
        backgroundColor: '#B4B9BF', //#fadb7
        padding: '0px 8px 0px 0px',
        borderRadius: '5px',
        color: 'rgb(34, 154, 22)',
        float: 'left'
    },
    hiddenLabel: {
        backgroundColor: 'rgba(255, 72, 66, 0.16)', //#fadb7
        padding: '0px 8px 0px 0px',
        borderRadius: '5px',
        color: 'rgb(183, 33, 54)',
        float: 'right',
        marginLeft: '25px'
    },
    hiddenLabelDisable: {
        backgroundColor: '#B4B9BF', //#fadb7
        padding: '0px 8px 0px 0px',
        borderRadius: '5px',
        color: 'rgb(183, 33, 54)',
        float: 'right',
        marginLeft: '25px'
    },
    checkboxPhase: {
        color: 'rgb(34, 154, 22)',
        padding: '5px'
    },
    HiddencheckboxPhaseDisabled: {
        color: '#e2e8ef',
        padding: '5px'
    },
    HiddencheckboxPhase: {
        color: 'rgb(183, 33, 54)',
        padding: '5px'
    },
    templateIcon: {
        color: 'green',
        marginTop: '10px',
        marginLeft: '5px',
    },
    manageActivityDesign: {
        border: '2px solid gray',
        padding: '10px 5px',
        marginTop: '10px',
        borderRadius: '5px'
    },
    manageActivityDesignDisable: {
        border: '2px solid gray',
        padding: '10px 5px',
        marginTop: '10px',
        borderRadius: '5px',
        background: '#e2e8ef'
    },
    deletePaperDialogue: {
        minWidth: "800px",
    },
    deletePaperDialogue1: {
        minWidth: "500px",
    },
    expandicon: {
        backgroundColor: '#215176',
        color: 'white',
        borderRadius: 30
    },
    deleteTemplate: {
        '&.MuiAccordion-root:before': {
            height: '0px'
        }
    },
    defaultTemplate: {
        backgroundColor: '#bbdefb',
        borderRadius: 5,
        padding: '0px 10px'
    },
    avatarStyle: {
        width: '30px',
        height: '30px',
        marginLeft: '5px',
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black'
    },
    defaultTemplateColor1: {
        backgroundColor: 'rgba(73,204,144,.1)',
        '& .MuiTableCell-root': {
            borderColor: '#49cc90',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px'
        }
    },
    defaultTemplateCellColor: {
        color: 'black', //#215176
        fontWeight: 'bold',
    },
    defaulttempSystem: {
        borderLeft: '2px solid #49cc90',
        '& .MuiTableCell-root': {
            borderBottomLeftRadius: '5px',
            borderTopLeftRadius: '5px',
        }
    },
    defaulttempDelete: {
        borderRight: '2px solid #49cc90',
        '& .MuiTableCell-root': {
            borderBottomRightRadius: '5px',
            borderTopRightRadius: '5px',
            borderRightColor: '#49cc90',
        }
    },
    dialogboxOverride: {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        height: '100%',
        border: "1px solid #0D324D",
        borderRadius: 15
    },
    titleHeaderOverride: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px'
    },
    Image: {
        height: 60,
        width: 60,
    },
    textfield1: {
        textAlign: 'center',
        color: 'black',
        fontSize: 18
    },
    textfield2: {
        textAlign: 'center',
        color: 'black',
        fontSize: 15
    },
    dialogaction: {
        gap: '3%',
        marginBottom: '10px'
    },
    unsubscribeButton: {
        backgroundColor: 'lightgray',
        border: '1px solid gray',
        color: 'black',
        fontWeight: 'bold',
    },
    readonlyTemplate: {
        backgroundColor: '#ffbda8',
        border: '1px solid #bf8877',
        color: 'black',
        fontWeight: 'bold',
    },
    globalTemplatebutton: {
        backgroundColor: 'rgba(73,204,144,.4)',
        border: '1px solid #49cc90',
        color: 'black',
        fontWeight: 'bold',
    },
    NormalTemplatebutton: {
        backgroundColor: '#90caf9',
        border: '1px solid #5c6bc0',
        color: 'black',
        fontWeight: 'bold',
    },
    deleteAccordign: {
        padding: '0px 15px',
        borderLeft: '5px solid #ffe564',
        background: 'rgba(255,229,100,0.2)'
    },
    warningIcon: {
        width: '45px',
        Height: '35px'
    },
    globalTemplatedropDown: {
        color: 'green',
        fontWeight: 'bold',
        borderRadius: '10px'
    },
    starIcon: {
        color: '#ff9800',
        marginTop: '1px'
    },
    ContentStyle: {
        fontSize: 13,
        border: '1px solid #215176',
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5,
        boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 1px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',
    },
    deleteStyle: {
        color: 'blue'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    ClientCodeListButton: {
        borderRadius: '20px',
        float: 'right',
        fontSize: 12,
        marginRight: '10px'
    },
    linkStylefont: {
        fontSize: '12px'
    },
    colorcodeTemplate: {
        padding: '5px',
        marginRight: '5px',
        marginTop: '8px'
    },
    tooltipPadding: {
        padding: '2px'
    },
    tooltipText: {
        fontSize: 12,
        marginLeft: '10px'
    },
    tooltipText1: {
        fontSize: 12,
        textAlign: 'center',
        fontWeight: 'bold'
    },
    unsubscribeIcon: {
        border: '1px solid gray',
        backgroundColor: 'lightgray',
        color: 'lightgray',
        borderRadius: '100%',
        fontSize: '16px'
    },
    readonlyIcon: {
        backgroundColor: '#ffbda8',
        color: '#ffbda8',
        border: '1px solid #bf8877',
        borderRadius: '100%',
        fontSize: '16px'
    },
    globalTemplateIcon: {
        backgroundColor: '#b6ebd3',
        color: '#b6ebd3',
        border: '1px solid #49cc90',
        borderRadius: '100%',
        fontSize: '16px'
    },
    NormalTemplateIcon: {
        border: '1px solid #5c6bc0',
        backgroundColor: '#90caf9',
        color: '#90caf9',
        borderRadius: '100%',
        fontSize: '16px'
    },
    filterStyle: {
        backgroundColor: 'yellow',
        border: '1px solid gray',
        color: 'black',
        fontSize: 12,
        fontWeight: 'bold',
    },
    filterStyle1: {
        backgroundColor: 'white',
        border: '1px solid gray',
        color: 'black',
        fontSize: 12,
        fontWeight: 'bold',
    },
    overRideButtonStyle: {
        borderRadius: 20,
        marginTop: '10px',
        fontSize: 12,
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    titleHead: {
        textAlign: 'left',
        marginLeft: '5px',
        marginTop: '10px',
    },
    filterGrid: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    permittedFiled: {
        backgroundColor: 'white',
        color: 'black',
        borderRadius: '5px',
        marginTop: '5px',
        marginLeft: '0px',
        width: '400px',
        fontSize: '10px'
    },
    permittedChecked: {
        backgroundColor: '#ebf2ff',
        color: '#2164cd',
        borderRadius: '5px',
        marginTop: '5px',
        marginLeft: '0px',
        width: '400px',
        fontSize: '10px'
    },
    tableBody: {
        maxHeight: 400,
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    reportHeader: {
        color: 'blue',
        marginTop: '5px'
    },
    controlSpace1: {
        width: '95%',
        marginTop: '8px'
    },
    controlSpace2: {
        width: '100%',
        marginTop: '8px'
    },
    controlSpace3: {
        width: '100%',
        marginTop: '8px',
        borderRadius: 3
    },
    gridflex: {
        padding: '5px 5px',
        borderRadius: '5px',
        marginTop: '5px',
        borderTop: '4px solid #3bb78f',
        overflowX: 'hidden',
        maxHeight: 580,
        minHeight: 580,
        borderBottom: '1px solid gray',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 500px)',
            minHeight: 'calc( 100vh - 500px)',
        },
        boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
    },
    controlPadding: {
        padding: '0px 5px',
        borderBottom: '0px solid white !important',
        '&.MuiTableCell-root': {
            borderBottom: '0px solid white !important',
        }
    },
    controlDisplay: {
        width: '100%',
        marginTop: '5px',
        marginBottom: '7px',
        '& .MuiIconButton-root': {
            padding: '0px'
        }
    },
    backButton: {
        borderRadius: 20,
        marginTop: '10px',
        marginRight: '5px',
        fontSize: '11px'
    },
    buttonStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: '5px',
        marginTop: '5px',
        '& .MuiBox-root': {
            padding: '5px',
        },
        '@media (min-width: 600px)': {
            justifyContent: "flex-end",
        }
    },
    FTEChecked: {
        backgroundColor: '#ebf2ff',
        color: '#2164cd',
        borderRadius: '5px',
        marginTop: '5px',
        marginLeft: '15px',
        width: '260px',
        fontWeight: 'bold'
    },
    controlWidth1: {
        borderRadius: 5,
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            width: '100%',
            minHeight: 'calc( 100vh - 280px) !important',
            maxHeight: 'calc( 100vh - 280px) !important',
        }
    },
    tableBodyTrack: {
        minHeight: 'calc( 100vh - 316px) !important',
        maxHeight: 'calc( 100vh - 316px) !important',
    },
}));
export { useStyles };
