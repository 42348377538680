import * as React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useGlobalState } from '../store/GlobalStore'

export interface ProtectedRouteProps extends RouteProps { }

export const PrivateRoute: React.FC<ProtectedRouteProps> = (props) => {
  const {state} = useGlobalState()

  return (!state.user || !state.user.userToken) ? (
    <Redirect to={{pathname: '/login'}} />
  ) : (
      <Route {...props} />
    )
}

export default PrivateRoute
