import { IUserPermissions } from '../accesscontrol/IUserPermissions'
import { ClientUserAccessContext } from './ClientUserAccessContext'
import { INotificationPermissions } from './INotificationPermissions'
import { IWidgetsPermissions } from './IWidgetsPermissions'

export class AccessContext extends ClientUserAccessContext implements IUserPermissions, INotificationPermissions, IWidgetsPermissions {
    sendEmailOnAttachements: boolean = false;
    multiplePlacementAccess: boolean = false;
    multiClientCodePlacementAccess: boolean = false;
    clientConfirmation: boolean = false;
    downloadfile: boolean = false;
    deletefile: boolean = false;
    uploadfile: boolean = false;
    createfolder: boolean = false;
    deletefolder: boolean = false;
    isGlobalSettings: boolean = false;
    isAdfLogs: boolean = false;
    isUserLoginStatus: boolean = false;
    ftpFilesContains: string = '';
    paymentFileContains: string = '';
    ackFileContains: string = '';
    isAzureFunc: boolean = false;
    rollReportNotification = false;
    agentCreateUser = false;
    agentEditReport = false;
    agentReadOnlyAccess = false;
    performanceReport: boolean = false;
    additionalChargesReport: boolean = false;
    isProspectUser: boolean = false;
    totalUserCount: number = 0;

    //Notification Persmissions
    disputeValidationNotification: boolean = false;
    paymentVerificationNotification: boolean = false;
    fileIssuesNotification: boolean = false;
    backupDocumentRequestNotification: boolean = false;
    settlementRequestsNotification: boolean = false;
    generalNotification: boolean = false;
    ackReportNotification: boolean = false;
    trustStatement: boolean = false;

    //Widget Permissions
    flatFeeAcRem: boolean = false;
    acWaitingtoMove: boolean = false;
    daysSincePlacmentofAc: boolean = false;
    placementHistoryofMonthAc: boolean = false;
    ageImpactofonAcResolution: boolean = false;
    unReadNotifications: boolean = false;

    constructor() {
        super();
    }

    upnEmailAddress: string = '';
}

//User Role
export enum Permission {
    None = 100,
    Enabled = 1,
    Disabled = 0,
}

//User Role
export enum UserRole {
    None = 100,
    ClientUser = 0,
    ClientAdmin = 1,
    GlobalAdmin = 2,
    SuperAdmin = 3,
    FileAdmin = 4,
    AgentUser = 5
}