import {
    Box, Button, Collapse, Dialog, DialogActions, DialogContent, TableCell, TableRow, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import InfoIcon from '@mui/icons-material/Info';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { Columns } from '../../models/Inquiry/config';
import { IDebtor } from '../../models/NotificationCenter/IDebtor';
import { IHideForAllUsers } from '../../models/NotificationCenter/IHideForAllUsers';
import { INotificationCenter } from '../../models/NotificationCenter/INotificationCenter';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete, DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import LightTooltip from '../GlobalStyles/LightTooltip';
import useStyles from './NCCcss/NotiCenterCss';
import InquiryDetail from './NCInquiryDetail';

function usePrevious(data) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = data
    }, [data])
    return ref.current
}

const ExpandableRow: React.FC<{ Notify: INotificationCenter, selected: boolean, onClick: (accountId: string) => void, onHideAll: () => void, onRefresh: () => void, selectedDBTNumber: string, IsInvited: boolean, dashboardCountResult: () => void }> = (props) => {
    const { Notify } = props
    const { state } = useGlobalState();
    const classes = useStyles()
    const [debtors, setDebtors] = useState<IDebtor[]>([]);
    const [CounterOfferAmount, setCounterOfferAmount] = useState<any>('');
    const [offerAccept, setOfferAccept] = React.useState(false);
    const [offerReject, setOfferReject] = React.useState(false);
    const [sendOffer, setSendOffer] = React.useState(false);
    const [isActiveStatus, setGetActivestatus] = useState(false);
    const [GetDebtorNo, setGetDebtorNo] = useState<string>();
    const componentRef = useRef();
    const prevCount = usePrevious(props.selectedDBTNumber);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [confirmDialogBox, setConfirmDialogBox] = useState(false);
    const [message, setMessage] = useState<string>("");
    const [notificationId, setNotificationId] = useState<number>(0);

    const handleAlertClose = () => {
        setConfirmDialogBox(false);
    };

    useEffect(() => {
        (async () => {
            await useFetch<IDebtor[]>("GetNotificationDetails?DBTNumber=" + Notify.extraData_3).then((GetNotificationDetails) => {
                setDebtors(GetNotificationDetails.data);
            });
        })()
    }, [])

    const SendSettlementEmail = (action: number) => {
        (async () => {
            if (props?.IsInvited) {
                let request = {
                    "username": state.userAccessContext?.userName,
                    "fullName": state.userAccessContext?.firstName + "" + state?.userAccessContext?.lastName,
                    "action": action,
                    "debtorNo": Notify?.extraData_3,
                    "originalOffer": Notify?.extraData_2,
                    "notificationDate": moment(Notify?.createdDate).format(state.GlobalUtils?.settingValue),
                    "counterOffer": CounterOfferAmount
                }
                await usePost<any>("SendSettlementEmail", request);
            }
        })().finally(() => {
            props?.dashboardCountResult();
        })
    }

    const setMessageId = (action: number) => {
        {
            if (action === 3) {
                setMessage("Are you sure you want to Send the Offer to this User ? ");
                setNotificationId(action);
            } else if (action === 2) {
                setNotificationId(action);
                setMessage("Are you sure you want to Reject the Offer ?");
            } else {
                setMessage(" Are you sure you want to Accept the Offer");
                setNotificationId(action);
            }
        }
    }

    const sendNotification = (id: number) => {
        if (id === 3) {
            SendSettlementEmail(id);
            hidenotificationForAllUsers();
            setSendOffer(true);
        } else if (id === 2) {
            SendSettlementEmail(id);
            hidenotificationForAllUsers();
            setOfferReject(true);
        } else {
            SendSettlementEmail(id);
            hidenotificationForAllUsers();
            setOfferAccept(true);
        }
    }

    const hidenotificationForAllUsers = () => {
        (async () => {
            let request = {
                "usernotificationid": Notify?.notificationid,
                "userid": state.userAccessContext?.id,
                "debtorid": Notify?.extraData_3,
                "typeId": 4,
                "userRole": 1
            }
            await usePost<IHideForAllUsers>("HideForAllUsers", request);
            props.onHideAll();
            props.onRefresh();
        })()
    }
    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbarForAccept = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOfferAccept(false);
    };
    const closeSnackbarForReject = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOfferReject(false);
    };
    const closeSnackbarForSendOffer = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSendOffer(false);
    };
    const CounterOfferValueChange = (value: string | undefined): void => {
        setCounterOfferAmount(value === '0' || '' ? '' : value || '');
    };

    useEffect(() => {
        if (prevCount === undefined) {
            setGetDebtorNo("");
        } else if (prevCount === null || prevCount === "") {
            setGetDebtorNo(Notify.extraData_3);
        } else {
            setGetDebtorNo(prevCount);
        }
    }, [Notify.extraData_3, prevCount, props.selectedDBTNumber])

    const handleCloseDescription = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <TableRow className={classes.rootRequest} >
                <TableCell className={classes.tablecellstyle}>
                    {moment(Notify?.createdDate).format(state.GlobalUtils?.settingValue)}  Settlement offer : Client Acct #{Notify?.extraData_1} / Agency Acct #{Notify?.extraData_3} - ${Notify?.extraData_2.substring(0, Notify?.extraData_2.indexOf("µ"))}
                    <Box display="flex" width="100%" alignItems="center" p={1} bgcolor="white" >
                        <Box p={0} width="12%" bgcolor="white" >
                            <Typography className={classes.textAlignLeft} variant="subtitle2" gutterBottom>
                                Principal
                            </Typography>
                            <Typography className={classes.textAlignLeft} variant="subtitle2" gutterBottom>
                                ${Notify.principalAmount?.toFixed(2)}
                            </Typography>
                        </Box>
                        <Box p={0} width="12%" bgcolor="white" >
                            <Typography className={classes.textAlignLeft} variant="subtitle2" gutterBottom>
                                Late Fee
                            </Typography>
                            <Typography className={classes.textAlignLeft} variant="subtitle2" gutterBottom>
                                ${Notify.lateFeeAmount?.toFixed(2)}
                            </Typography>
                        </Box>
                        <Box p={0} width="12%" bgcolor="white" >
                            <Typography className={classes.textAlignLeft} variant="subtitle2" gutterBottom>
                                Total
                            </Typography>
                            <Typography className={classes.textAlignLeft} variant="subtitle2" gutterBottom>
                                ${(Notify.principalAmount + Notify.lateFeeAmount).toFixed(2)}
                            </Typography>
                        </Box>
                        <Box p={0} width="30%" flexShrink={0} bgcolor="white" >
                            <CurrencyInput
                                className={classes.inputField1}
                                id="SettlementNo_Offer_TextField"
                                value={CounterOfferAmount}
                                placeholder="$ Counter Offer"
                                maxLength={13}
                                decimalsLimit={2}
                                prefix='$'
                                onValueChange={CounterOfferValueChange}
                                autoComplete='off'
                            />
                        </Box>
                        <Box p={0} width="13%" flexShrink={0} bgcolor="white" >
                            <Button id="Settlment_SendOffer_Button" className={classes.buttonSettle} startIcon={<LocalOfferIcon />} variant="contained"
                                color="primary" size='small' onClick={() => { setConfirmDialogBox(true); setMessageId(3) }}>
                                Send Offer
                            </Button>
                        </Box>
                        <Box p={0} width="12%" flexShrink={0} bgcolor="white" >
                            <Button id="Settlement_Accept_Button" className={`${classes.buttonSettle} ${classes.btnBgGreen} `}
                                startIcon={<DoneAllIcon />} variant="contained" size='small' color="primary" onClick={() => { setConfirmDialogBox(true); setMessageId(1); }}>
                                Accept
                            </Button>
                        </Box>
                        <Box p={0} flexShrink={0} width="10%" bgcolor="white" >
                            <Button id="Settlement_Reject_Button" className={`${classes.buttonSettle} ${classes.btnBgRed} `}
                                startIcon={<NotInterestedIcon />} variant="contained" size='small' color="primary" onClick={() => { setConfirmDialogBox(true); setMessageId(2); }}>
                                Reject
                            </Button>
                        </Box>
                        <Box p={0} flexShrink={0} bgcolor="white" width="10%" className={classes.boxBorder}>
                            <div className={classes.hidebutton}>
                                <LightTooltip title="Show Description">
                                    <InfoIcon color="primary" className={classes.visibilityIconStyle} onClick={(e) => { setOpen(true); }} />
                                </LightTooltip>
                                <Typography className={classes.typoSpacingLeft}><b>OR</b></Typography>
                                {props.selected === true ?
                                    <LightTooltip title="View Account Details">
                                        <ArrowForwardIosIcon className={classes.forwardArrowMinus} onClick={(_) => { props.onClick(Notify.extraData_3); (Notify.extraData_3 !== props.selectedDBTNumber ? null : setGetDebtorNo("")); }} />
                                    </LightTooltip>
                                    : <LightTooltip title="View Account Details">
                                        <ArrowForwardIosIcon className={classes.forwardArrowPlus} onClick={(_) => { props.onClick(Notify.extraData_3); (Notify.extraData_3 !== props.selectedDBTNumber ? null : setGetDebtorNo("")); }} />
                                    </LightTooltip>
                                }
                            </div>
                        </Box>
                    </Box>
                </TableCell>
            </TableRow>

            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen}
                open={open}
                onClose={handleCloseDescription}
                PaperProps={{ style: { borderRadius: 15 } }}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDescription}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        DESCRIPTION
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Typography variant="h6" gutterBottom className={classes.Content}>
                        {Notify?.extraData_2?.split("µ")[1]}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant="contained" size="small" startIcon={<CancelIcon />} onClick={handleCloseDescription} className={classes.buttonstyle}>
                        close
                    </Button>
                </DialogActions>
            </Dialog>

            {
                debtors.map((sr) => {
                    return (
                        <TableRow className={props.selected ? classes.root : classes.rootHidden} ref={componentRef}>
                            <TableCell scope="row" colSpan={Columns.length}>
                                <Collapse in={props.selected} timeout="auto" unmountOnExit >
                                    <InquiryDetail Notify={Notify} inquiryDetail={sr} isBlocked={isActiveStatus} />
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    );
                })
            }
            <Snackbar className="snackBarStyle" open={offerAccept} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbarForAccept}>
                <Alert onClose={closeSnackbarForAccept} severity="success" className="alertStyle">
                    Settlement Offer Accepted.
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={offerReject} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbarForReject}>
                <Alert onClose={closeSnackbarForReject} severity="error" className="alertStyle">
                    Settlement Offer Rejected.
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={sendOffer} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbarForSendOffer}>
                <Alert onClose={closeSnackbarForSendOffer} severity="success" className="alertStyle">
                    Counter settlement offer sent.
                </Alert>
            </Snackbar>
            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={confirmDialogBox} onClose={handleAlertClose} maxWidth={'xs'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        CONFIRMATION MESSAGE
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <Typography variant="h6" className={classes.MessageStyle} gutterBottom>
                        {message}
                    </Typography>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" variant="contained" size="small" onClick={(e) => { sendNotification(notificationId); setConfirmDialogBox(false); }} className={classes.yesButton1}>
                        Yes
                    </Button>
                    <Button id="DU_ok_btn" variant="contained" size="small" onClick={(e) => { setConfirmDialogBox(false); }} className={classes.noButton}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    )
}
export default ExpandableRow