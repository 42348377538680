import { Backdrop, Box, DialogActions, makeStyles, Slide, Typography, Snackbar, Dialog, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { TransitionProps } from '@material-ui/core/transitions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import React from 'react';
import { IListAllFaq } from "../../../models/GA_Faq/IListAllFaq";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            display: "flex",
        },
        centerBox: {
            justifyContent: "center",
            alignItems: "center"
        },
        DialogBottom: {
            marginRight: '20px'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        editbutton: {
            borderRadius: 20,
            fontSize: 12,
            backgroundColor: 'green',
            '&:hover': {
                background: "green",
            }
        },
        question: {
            border: "3px solid",
            width: "100%",
            fontSize: 15,
            borderRadius: 10
        },
        answer: {
            border: "3px solid",
            fontSize: 15,
            padding: "10px",
            width: "100%",
            borderRadius: 10
        },
        updatefaq: {
            borderRadius: '20px',
            backgroundColor: 'green',
            fontSize: 12,
            '&:hover': {
                background: "green",
            }
        },
        titleHeader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        }
    })
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditFAQ: React.FC<{ faqRow: IListAllFaq, onSave: (faqRow: IListAllFaq) => void }> = (props) => {
    const { faqRow } = props;
    const classes = useRowStyles();
    const { state, dispatch } = useGlobalState();
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    var updateFaq: IListAllFaq = {
        id: faqRow.id,
        question: faqRow.question,
        answer: faqRow.answer,
        date_created: faqRow.date_created,
        position: faqRow.position,
    }

    const [localState, setLocalState] = React.useState(updateFaq);

    const HandleSave = () => {
        (async () => {
            setOpen(false);
            dispatch({ type: GlobalStateAction.Busy });
            try {
                await usePost('Faq/UpdateFaq', localState);
                props.onSave(localState);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLocalState(updateFaq);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        setLocalState({
            ...localState,
            [name]: event.target.value
        });
    };

    return (
        <React.Fragment>
            <Button size="small" id="GAFAQ_Edit_btn" startIcon={<BorderColorIcon />} className={classes.editbutton} variant="contained" color="primary" onClick={(_) => { handleClickOpen(); }}>
                Edit
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                PaperProps={{ style: { borderRadius: 15 } }}
                fullWidth={true} TransitionComponent={Transition}
                maxWidth={'md'}>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleHeader}>
                        EDIT FAQ
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Typography variant="overline" style={{ textAlign: "center", fontSize: 15 }} display="block" gutterBottom>
                        <b>Question</b>
                    </Typography>
                    <Box component="span" m={1} //margin
                        className={`${classes.centerBox} ${classes.box}`} >
                        <TextareaAutosize
                            className={classes.question}
                            rowsMax={8}
                            aria-label="maximum height"
                            name="question"
                            value={localState.question}
                            onChange={handleChange}
                        />
                    </Box>
                    <Typography variant="overline" style={{ textAlign: "center", fontSize: 15 }} display="block" gutterBottom>
                        <b>Answer</b>
                    </Typography>
                    <Box component="span" m={1} //margin
                        className={`${classes.centerBox} ${classes.box}`} >
                        <TextareaAutosize
                            className={classes.answer}
                            rowsMax={20}
                            name="answer"
                            aria-label="maximum height"
                            value={localState.answer}
                            onChange={handleChange}
                        />
                    </Box>
                </DialogContent>
                <DialogActions className={classes.DialogBottom}>
                    <Button size="small" id="GAFAQ_Update_btn" startIcon={<DoneAllIcon />} autoFocus onClick={(_) => { setShow(true); HandleSave(); }} disabled={!localState.answer || !localState.question}
                        color="primary" variant="contained"
                        className={classes.updatefaq}>
                        Update FAQ
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default EditFAQ