import { DialogContent, Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import moment from 'moment';
import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import printImg from "../../../images/ACKReport/AckPrint.svg";
import header from '../../../images/header/header-logo.svg';
import { IClosedLetterDetails } from '../../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { ILetterDetails } from '../../../models/Inquiry/LetterDetails/ILetterDetails';
import { useGlobalState } from '../../../store/GlobalStore';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import LightTooltip from '../../GlobalStyles/LightTooltip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: 'relative',
            backgroundColor: "#007FFF"
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        letterbutton: {
            marginTop: "5px",
            borderRadius: 20,
            fontSize: 12,
            width: 350
        },
        head: {
            display: "flex",
            flexDirection: "column",
            borderSpacing: "1cm",
            width: 375,
            color: "primary",
        },
        menuButton: {
            marginRight: theme.spacing(2),
            width: 220
        },
        report: {
            overflowX: 'hidden'
        },
        dividerStyle: {
            marginTop: "5px",
            height: '2px',
            background: "black"
        },
        TypographyStyle: {
            fontSize: 13,
            marginTop: "30px"
        },
        TypographyStyle1: {
            fontSize: 13,
            marginTop: "3px"
        },
        TypographyStyle2: {
            fontSize: 13,
            marginTop: "40px"
        },
        TypographyStyle3: {
            fontSize: 13,
            marginTop: "10px"
        },
        TypographyStyle4: {
            marginTop: "20px",
            fontSize: 13
        }
    }),
);


const PaidLetter: React.FC<{ dbtNumber: string, letterDetails: IClosedLetterDetails[], GetDebtorLetterDetails: ILetterDetails[] }> = (props) => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const [open, setOpen] = React.useState(false);
    const componentRef = useRef<HTMLDivElement>(null);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleOpen} disabled={(!props?.letterDetails[0]?.paid_Letter)} size="small"
                className={classes.letterbutton} variant="contained" color="primary" startIcon={<FileCopyIcon />}>
                Paid Letter
            </Button>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Paid Letter
                        </Typography>
                        <ReactToPrint
                            trigger={() =>
                                <LightTooltip title="Print Letter">
                                    <IconButton color="primary" id="RB_PrintIcon" aria-label="print" component="label">
                                        <img src={printImg} alt="Icon for Print" width="35px" height="30px" />
                                    </IconButton>
                                </LightTooltip>
                            }
                            content={() => componentRef.current}
                        />
                    </Toolbar>
                </AppBar>
                {props.GetDebtorLetterDetails.map((letter) => {
                    return (
                        <div ref={componentRef} className={classes.report}>
                            <DialogContent dividers>
                                <img alt="ARM Solutions" className={classes.menuButton} src={header} />
                                <Divider className={classes.dividerStyle} />
                                <Typography variant="body1" align="left" className={classes.TypographyStyle3}>
                                    {`${moment(new Date()).format('MMMM DD,YYYY')}`}
                                </Typography>
                                <Typography variant="h6" align="left" className={classes.TypographyStyle4}>
                                    {letter?.dbT_NAME?.toUpperCase()}
                                </Typography>
                                <Typography variant="h6" align="left" style={{ fontSize: 13 }}>
                                    {letter?.deM_ADDR1?.toUpperCase()}
                                </Typography>
                                <Typography variant="h6" align="left" style={{ fontSize: 13 }}>
                                    {letter?.deM_CITY?.toUpperCase()}, {letter?.deM_STATE?.toUpperCase()} , {letter?.deM_ZIP?.toUpperCase()}
                                </Typography>
                                <Typography variant="h6" align="left" className={classes.TypographyStyle}>
                                    Client Name: {letter?.clT_NAME_1?.toUpperCase()}
                                </Typography>
                                <Typography variant="h6" align="left" className={classes.TypographyStyle1}>
                                    Client Account Number: {letter?.dbT_CLNT_ACNT1?.toUpperCase()}
                                </Typography>
                                <Typography variant="h6" align="left" className={classes.TypographyStyle1}>
                                    Customer Account Number: {letter?.dbT_NO?.toUpperCase()}
                                </Typography>
                                <Typography variant="h6" align="left" className={classes.TypographyStyle2}>
                                    Dear {letter?.dbT_NAME?.toUpperCase()},
                                </Typography>
                                <Typography variant="h6" gutterBottom className={classes.TypographyStyle}>
                                    This is to advise you that we have been informed that your account referred on {moment(letter?.dbT_REFERRAL_DATE?.split('T')[0]).format(state.GlobalUtils?.settingValue)}, referenced above, is now a resolved account.
                                    our records indicate that {letter?.dbT_NAME?.toUpperCase()}'s account {letter.dbT_NO} has been satisfied.
                                    Your account with us has been updated to reflect this. All collection activity has been stopped. Furthermore, if your credit was affected, this mark will be updated.
                                </Typography>
                                <Typography variant="h6" gutterBottom className={classes.TypographyStyle}>
                                    Should you have any questions regarding this, please feel free to contact us directly at 888-772-6468.
                                </Typography>
                                <Typography variant="h6" align="left" className={classes.TypographyStyle}>
                                    Sincerely,<br />
                                    The Customer Service Team<br />
                                    A.R.M. Solutions
                                </Typography>
                                <Divider className={classes.dividerStyle} />
                                <Grid item xs={12}>
                                    <Typography variant="body1" align="left" gutterBottom style={{ fontSize: 13, marginTop: '7px' }}>
                                        <b>California License No.: 10207-99   PO Box 2929, Camarillo, CA 93011 &nbsp;&nbsp;&nbsp;<b style={{ color: 'red' }}>T</b>  888.772.6468 &nbsp;&nbsp;&nbsp;<b style={{ color: 'red' }}>F</b> 805.830.1104</b>
                                        <b style={{ color: 'red', float: 'right' }}>ARMSolutions.com</b>
                                    </Typography>
                                </Grid>
                            </DialogContent>
                        </div>
                    )
                })}
            </Dialog >
        </div >
    );
}

export default PaidLetter
