import { Backdrop, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import { AccessContext } from '../../../models/accesscontrol/AccessContext';
import { IUserInfo } from '../../../models/accesscontrol/IUserInfo';
import { IWidgetInfo } from '../../../models/accesscontrol/IWidgetInfo';
import { WidgetMap } from '../../../models/accesscontrol/WidgetMap';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './UsersCss';

const ImpersonateUser: React.FC<{ userRow: IUserInfo, checkSuperAdmin: boolean }> = (props) => {
  const { state, dispatch } = useGlobalState()
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [ProgressBar, setshowProgressBar] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
    setshowProgressBar(true);
    useFetch<AccessContext>("User/GetUser?userId=" + props.userRow.id).then((r) => {
      var response = r.data;
      useFetch<IWidgetInfo[]>("User/GetWidgetsForClient?userId=" + response.id).then((r) => {
        for (let index = 1; index <= WidgetMap.size; index++) {
          const value = WidgetMap.get(index);
          if (value && r.data.filter(w => w.widgetId === index).length === 1) {
            response[value] = true;
          }
          dispatch({
            type: GlobalStateAction.SetPermissions, userAccessContext: response
          })
        };

        if (response.role === 2 || response.role === 3) {
          history.push('/admin');
          setshowProgressBar(false);
        } else if (response.role === 0 || response.role === 1) {
          history.push('/dashboard');
          setshowProgressBar(false);
        }
        else {
          history.push('/ManageClientFolderAccess');
          setshowProgressBar(false);
        }
      });
    });
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <Button size="small" id="U_Impersonate" startIcon={<SupervisedUserCircleIcon />} className={classes.button} variant="contained" color="primary" onClick={handleClickOpen} disabled={props.checkSuperAdmin && state.userAccessContext?.role === 2}>
        Impersonate
      </Button>
      <Dialog
        classes={{ paper: classes.dialogePaper }}
        PaperProps={{ style: { borderRadius: 15 } }}
        maxWidth={'sm'}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
          <Typography variant="h6" className={classes.titleHeaderStyle}>
            IMPERSONATE
          </Typography>
        </DialogTitleHeader>
        <DialogContent>
          <Typography variant="h6" className={classes.textfield} gutterBottom>
            Are you sure, you want to Impersonate {props.userRow.userName} user ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button id="IM_ok_btn" size="small" variant="contained" onClick={handleOk} className={classes.yesButton} color="primary" autoFocus>
            Yes
          </Button>
          <Button id="IM_Cancel_Btn" size="small" variant="contained" autoFocus className={classes.noButton} onClick={handleClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ImpersonateUser