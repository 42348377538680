import React, { useState } from 'react'
import { IGetKeyValues } from '../../../models/GLobalSettings/IGetKeyValueDetails';
import { usePost } from '../../../utils/apiHelper';
import { Backdrop, Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { DialogTitleDelete } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './ManagementCSS';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { PuffLoader } from 'react-spinners';
import { useGlobalState } from "../../../store/GlobalStore";

const DisableAPIKey: React.FC<{ row: IGetKeyValues, onFetchDetails: () => void }> = (props) => {
    const { row, onFetchDetails } = props;
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const { state } = useGlobalState();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickSubmit = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "apiKeyId": props?.row?.apiKeyId,
                "userId": state?.userAccessContext?.id,
            };
            await usePost("GlobalSettings/DisableAPIKey", request).then((result) => {
            });
            setShowProgressBar(false);
            onFetchDetails();
            setOpen(false);
        })()
    }
    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen} disabled={!row?.isDisable} color="primary" size="small">Disable</Button>
            <Dialog
                PaperProps={{ style: { borderRadius: 10 } }}
                open={open}
                maxWidth={'xs'}
                onClose={handleClose}
                TransitionComponent={Transition}
                aria-labelledby="responsive-dialog-title" >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        CONFIRMATION MESSAGE
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <Backdrop className={classes.backdrop} open={ProgressBar}>
                        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <Typography variant="h6" className={classes.titleConfirm} gutterBottom>
                        Are you sure, you want to disable API Key  ?
                    </Typography>
                </DialogContent>
                <DialogActions >
                    <Button id="CPO_OK_btn" size="small" color="primary" className={classes.createGroup} autoFocus onClick={handleClickSubmit}>
                        Yes
                    </Button>
                    <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.cancelGroup} autoFocus onClick={handleClose} variant="contained">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DisableAPIKey