import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    buttons: {
        borderRadius: 20,
        background: "#007FFF",
        marginTop: '12px',
        color: 'white',
        fontSize: 12,
        '&:hover': {
            background: "#007FFF",
            color: 'white',
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    countButton: {
        borderRadius: 20,
        background: "#007FFF",
        marginTop: '12px',
        color: 'white',
        fontSize: 12,
        '&:hover': {
            background: "#007FFF",
            color: 'white',
        }
    },
    heading: {
        fontWeight: 'bold',
        backgroundColor: '#007FFF',
        borderRadius: 30,
        color: 'white',
        '&:hover': {
            backgroundColor: '#007FFF',
        }
    },
    details: {
        alignItems: "center",
        padding: '0px 16px',
        marginBottom: '5px',
    },
    card: {
        borderRadius: '5px',
        backgroundColor: 'white',
        width: '100%',
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        border: '1px solid black',
        maxHeight: 460,
        minHeight: 460,
        overflow: 'auto hidden',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 235px)',
            minHeight: 'calc( 100vh - 235px)',
        },
        '& .MuiCardContent-root': {
            padding: '16px 15px 24px',
        },
    },
    grid: {
        padding: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    maingrid: {
        backgroundColor: 'white',
        borderRadius: '5px',
        border: '1px solid gray',
        '& .MuiButton-label': {
            fontSize: 12,
        },
    },
    Center: {
        justifyContent: "center",
        alignItems: "center"
    },
    Title: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginTop: '10px',
    },
    dateField: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    textField1: {
        width: '100%',
        margin: '7px 0px',
        '& .MuiInputLabel-root': {
            fontSize: 14
        },
        '& .MuiInputBase-input': {
            fontSize: 14
        },
        '& .MuiIconButton-root': {
            padding: '0px'
        }
    },
    dateStyle: {
        width: '100%',
        marginTop: '10px',
        marginLeft: '5px',
        '& .MuiInputLabel-root': {
            fontSize: 14
        },
        '& .MuiInputBase-input': {
            fontSize: 14
        },
        '& .MuiIconButton-root': {
            padding: '0px'
        }
    },
    buttonStyle: {
        marginLeft: '0px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    /* Side Bar Style */
    sidebar: {
        width: "18rem",
        backgroundColor: 'white',
        transition: "all 0.2s ease",
        display: 'block',
        whiteSpace: 'nowrap',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        height: "calc(100vh - 100px)",
        borderLeft: '10px solid White',
        borderRight: '10px solid White'
    },
    sidebarCollapsed: {
        borderTopColor: 'white',
        width: "5rem",
        height: "calc(100vh - 100px)",
        backgroundColor: 'white',
        transition: "all 0.2s ease",
        display: 'block',
        whiteSpace: 'nowrap',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        borderLeft: '7px solid White',
        borderRight: '10px solid White'
    },
    sidebarHeader: {
        display: 'flex',
        padding: '5px 5px',
        '& .MuiSvgIcon-root': {
            fontSize: 20
        },
    },
    sidebarItem: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '5px',
        transition: '0.2s',
        cursor: 'pointer',
        padding: '8px 10px',
        marginTop: '4px',
        height: 'auto',
        '&:hover': {
            backgroundColor: '#c8facd',
            color: '#00ab55'
        }
    },
    textpropertyOut: {
        color: 'black',
        paddingLeft: '7px',
        cursor: 'pointer',
        marginLeft: '15px',
        transitionDelay: '0.2s',
        fontSize: 16
    },
    textProperty: {
        visibility: 'hidden',
    },
    transformImage: {
        transform: 'rotateY(180deg)'
    },
    arrowIcon: {
        color: 'white'
    },
    IconStyle: {
        height: '17px',
        width: '17px'
    },
    MessageStyle: {
        width: '70%',
        marginTop: '15px',
        marginLeft: '40px',
    },
    ErrorMessageStyle: {
        color: "red",
        fontSize: 16
    },
    TitleStyle: {
        color: "white",
        textAlign: "left",
        fontSize: 16,
        height: 32,
        marginTop: '5px',
        marginLeft: '10px',
    },
    DividerStyle: {
        marginTop: "5px",
        height: '2px',
        background: "black"
    },
    gridstyle: {
        marginTop: '5px',
    },
    textValueStyle: {
        textAlign: "left",
        fontSize: 13,
        fontWeight: 'bold',
        marginTop: '5px'
    },
    paper: {
        border: '2px solid black'
    },
    warningColor: {
        width: '100%',
        borderRadius: '10px',
    },
    GridStyle: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    footerStyle: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    textdisplay: {
        color: 'black',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    paginationStyle: {
        backgroundColor: 'white',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        borderRadius: '5px',
        marginTop: '5px'
    },
    footer: {
        marginTop: '15px',
        float: 'right',
    },
    contentGrid: {
        padding: '10px 0px 0px',
    },

    // This ScrollBars minWidth Need to be changed because % not be exceed 100
    scrollbars: {
        '@media (min-width: 1180px) and (max-width: 1267px)': {
            minWidth: '110%',
        },
        '@media (min-width: 1100px) and (max-width: 1180px)': {
            minWidth: '120%',
        },
        '@media (min-width: 950px) and (max-width: 1100px)': {
            minWidth: '140%',
        },
        '@media (min-width: 700px) and (max-width: 950px)': {
            minWidth: '200%',
        },
        '@media (min-width: 500px) and (max-width: 700px)': {
            minWidth: '250%',
        },
        '@media (max-width: 500px)': {
            minWidth: '350%',
        },
    },
    scrollBarGn: {
        '@media (min-width: 500px) and (max-width: 850px)': {
            minWidth: '125%',
        },
        '@media (max-width: 500px)': {
            minWidth: '150%',
        },
    },
    doubleArrowColor: {
        color: 'black',
    },
    doubleArrowRotate: {
        transform: 'rotateY(180deg)'
    },
    filterBtnStyle: {
        width: '20px',
        fontSize: 12
    },
    errorMsg: {
        color: 'red',
        fontSize: 12,
        marginTop: '0px',
        fontWeight: 'bold'
    },
    hideNotifySpacing: {
    },
    flexDirection: {
        flexDirection: 'row',
        padding: '0px 8px',
    },
    boxBorderBottom: {
        borderBottom: '1px solid black',
    },
    boxBorderBottom1: {
        borderBottom: '2px solid #00000021'
    },
    summaryHeight: {
        height: "10%",
        margin: '0px',
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '10px 0px 0px',
        },
        '& .MuiAccordionSummary-content': {
            margin: '10px 0px',
        },
        '& .MuiAccordion-root.Mui-expanded': {
            margin: '1px 0px',
        }
    },
    expandIconStyle: {
        color: '#215176',
        fontSize: 30,
    },
    boxFloatRight: {
        float: 'right',
    },
    readNotify: {
        marginTop: '8px',
    },
    ListIconStyle: {
        borderRadius: '10px',
        height: '38px',
    },
    boxStyle: {
        borderBottom: '1px solid black',
        borderRadius: '12px 12px 0px 0px',
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "#007fff",
        opacity: '0.9',
    },
    mainBoxStyle: {
        borderRadius: '14px',
        border: '1px solid black',
        marginTop: '11px'
    },
    TitleStyleBorder: {
        color: "black",
        textAlign: "left",
        borderBottom: "1px solid",
        fontSize: 16,
    },
    notifyCenterFile: {
        '& .MuiAccordionSummary-content': {
            margin: '5px 0px',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '5px 0px',
        },
    },
    notifyCenterPvDv: {
        '& .MuiTableCell-root': {
            padding: '10px',
        }
    },
    fileTextStyle: {
        fontSize: 13
    },
    fileTextStyle1: {
        fontSize: 13
    },
    innerScroll: {
        borderRadius: '16px',
    },
    lastborderScroll: {
        '& .MuiBox-root:last-child': {
            borderBottom: 'none',
        },
        '& .MuiTableRow-root:last-child .MuiTableCell-body': {
            borderBottom: 'none',
        },
    },
    allNotification: {
        '& .MuiTableRow-root': {
            border: 'none',
        }
    },
    notifyTablePagination: {
        display: 'block !important',
    },
    activeSidebar: {
        backgroundColor: '#c8facd',
        borderRadius: '5px'
    },
    activeSidebarText: {
        color: '#00ab55'
    },
    actions: {
        maxHeight: 500,
        minHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 270px)',
            minHeight: 'calc( 100vh - 270px)',
        }
    },
    settlementBody: {
        maxHeight: 500,
        minHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 300px)',
            minHeight: 'calc( 100vh - 300px)',
        }
    },
    tableHeaderStyle: {
        color: "white",
        fontSize: "16px",
        overflow: 'wrap',
        whiteSpace: 'pre-wrap',
        background: "#007FFF",
    },
    totalCount: {
        fontSize: 14,
        marginTop: '15px',
        '@media (min-width: 600px) and (max-width: 660px)': {
            fontSize: 12,
        }
    },
    totalCountButton: {
        borderRadius: '100%',
        width: '30px',
        height: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        background: "#215176",
        marginTop: '12px',
        fontSize: 12,
        color: "white",
        '&:hover': {
            background: "#215176",
        }
    },
    flexGrid: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiBox-root': {
            padding: '5px',
        },
    },
    tablecellPadding: {
        padding: '3px 0px 3px 10px'
    },
    paymentText: {
        fontSize: '13px'
    },
    videoIcon: {
        '& .MuiBox-root': {
            margin: '0px',
        }
    },
    TableCellHeadStyle: {
        background: "#007FFF",
        color: "white",
        fontSize: 12,
        padding: '3px 0px 3px 10px'
    },
    root: {
        fontSize: 11,
        textOverflow: 'ellipsis',
    },
    rootHidden: {
        display: "none"
    },
    CheckBoxStyle: {
        padding: "5px !important"
        // color: "#00e676",

    },
    fileNameStyle: {
        minWidth: '35px !important',
    },
    listIconSize: {
        // marginTop: '5px',
        fontSize: '20px',
        marginLeft: '5px'
    },
    fileText: {
        fontSize: '11px',
        marginLeft: "5px !important"
    },
    trustStatement: {
        // marginTop: '10px',
        maxHeight: 'calc( 100vh - 330px)',
        minHeight: 'calc( 100vh - 330px)',
    },
    searchClientCode: {
        borderRadius: "5px",
        border: '1px lightgray solid',
        width: '98%',
        height: '37px',
        textIndent: '7px',
        paddingLeft: '0px',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        marginTop: '5px',
        backgroundColor: 'transparent'
    },
    searchClientFiles: {
        borderRadius: "5px",
        border: '2px black solid',
        width: '100%',
        height: '35px',
        textIndent: '7px',
        paddingLeft: '0px',
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        marginTop: '5px',
    },
    ftpoutlinedInput1: {
        fontSize: 12,
        color: "black",
        marginTop: '-9px',
        '&$focused $notchedOutline': {
            color: 'white',
            border: '0px'
        },
        '&.MuiOutlinedInput-adornedEnd': {
            paddingRight: '5px'
        }
    },
    ftpoutlinedInput: {
        fontSize: 12,
        color: "black",
        marginTop: '-8px',
        '&$focused $notchedOutline': {
            color: 'white',
            border: '0px'
        },
        '&.MuiOutlinedInput-adornedEnd': {
            paddingRight: '5px'
        }
    },
    ftpnotchedOutline: {
        color: 'white',
        border: '0px'
    },
    smallFontSize: {
        fontSize: "14px"
    },
    noRecordsColor: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '95px',
    },
    NormalText: {
        color: 'black',
        fontSize: 12
    },
    TextHighlight: {
        backgroundColor: "yellow",
        fontSize: 12
    },
    archieveStyle: {
        marginLeft: "10px",
        padding: 0
    },
    listStyle: {
        padding: "0px !important",
        cursor: 'pointer !important',
    },
    archievedStyle: {
        backgroundColor: 'rgba(73,204,144,.1)',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px',
        '& .MuiTableCell-root': {
            borderColor: '#49cc90',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px'
        }
    },
    filterButton: {
        borderRadius: "20px !important",
        marginRight: '5px !important',
        marginTop: '15px !important',
        fontSize: "12px !important"
    },
    clearFilterButton: {
        borderRadius: "20px !important",
        marginTop: '15px !important',
        fontSize: "12px !important",
        marginLeft: '10px !important'
    },
    IconCount: {
        borderRadius: '20px',
        backgroundColor: '#007FFF',
        marginLeft: '5px',
        fontSize: '12px',
        color: 'white',
        height: '10px',
        width: '10px'
    },
    iconCountProprty: {
        visibility: 'hidden',
    },
    /* Full Screen Dialog Box UI Css */
    yesButton: {
        background: "green",
        color: "white",
        borderRadius: 20,
        fontSize: 12,
        right: "15px",
        "&:hover": {
            background: "green",
        },
    },
    noButton: {
        background: "red",
        borderRadius: 20,
        display: "flex",
        color: "white",
        right: "10px",
        fontSize: 12,
        "&:hover": {
            background: "red",
        },
    },
    titleheader: {
        fontWeight: "bold",
        color: "white",
        fontSize: 18,
    },
    onboardClient: {
        backgroundColor: "rgba(118, 53, 220, 0.08)",
        color: "rgb(32,10,105)",
        borderRadius: "5px",
        padding: "10px",
    },
    dialog: {
        '& .MuiDialog-paper': {
            overflowY: 'hidden'
        }
    },
    toolbarStyle: {
        backgroundColor: 'white',
        minHeight: '40px !important'
    },
    closeIcon: {
        color: '#000',
        fontSize: 22
    },
    appBar: {
        position: 'relative',
        overflowY: 'hidden',
        minHeight: '45px',
        borderBottom: '2px solid #0072E5'
    },
    title: {
        flex: 1,
        color: '#0072E5',
        textAlign: 'center',
        fontSize: 18,
        marginRight: '100px'
    },
    menuButton: {
        marginTop: '5px',
        marginBottom: '5px',
        height: '45px',
        backgroundColor: 'white',
        cursor: 'default',
        '&:hover': {
            backgroundColor: 'white',
        }
    },
    menuButton1: {
        width: '120px',
        height: '40px'
    },
    boxBorder: {
        border: '1px solid #007fff',
        padding: '2px 8px',
        borderRadius: '5px',
        width: '100%',
        marginTop: '12px',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
    },
    scrollMargin: {
        '& div:first-child': {
            marginBottom: '0px',
        },
        '& div:nth-child(3) div': {
            backgroundColor: '#2377e4 !important'
        },
        '& div:nth-child(3)': {
            bottom: '0px !important'
        },
    },
    emailAddresssFont: {
        fontSize: 10,
        '& .MuiChip-labelSmall': {
            fontSize: 10
        }
    },
    valueContainer: {
        padding: '5px',
        "& > :not(:last-child)": {
            marginRight: '5px'
        },
        "& > *": {
            marginBottom: '5px'
        }
    },
    avatarStyle: {
        width: '25px',
        height: '25px',
        marginLeft: '5px',
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black'
    },
    textBoxStyle2: {
        fontSize: 12,
        '& .MuiChip-labelSmall': {
            fontSize: 12
        }
    },
    trustStatementReportShareContainer: {
        // marginTop: '10px',
        maxHeight: 'calc( 100vh - 200px)',
        minHeight: 'calc( 100vh - 200px)',
    },
    trustReportShareHistoryContainer: {
        // marginTop: '10px',
        maxHeight: 'calc( 100vh - 170px)',
        minHeight: 'calc( 100vh - 170px)',
    },
    tablecellTrustStatement: {
        padding: '7px 0px 7px 10px'
    },
}))

export { useStyles }