import { Box, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { ITransactionHistory } from '../../models/Inquiry/TransactionHistory/ITransactionHistory';
import { IDebtor } from '../../models/Reports/IDebtor';
import { useGlobalState } from '../../store/GlobalStore';
import { RollSpinner } from '../GlobalStyles/Loading';

const useStyles = makeStyles((theme) => ({
    tabs: {
        backgroundColor: theme.palette.primary.main,
        marginRight: "2px",
        marginTop: "0%",
        width: "15%",
    },
    root: {
        border: "2px solid black",
        borderRadius: 8,
    },
    girdView: {
        color: "blue",
        margin: '0px'
    },
    textStyle: {
        color: "#009900",
        fontSize: 13
    },
    textStyle1: {
        color: "black",
        fontSize: 13
    },
    norecord: {
        color: 'red'
    }
}))

const ReportAccountHistory: React.FC<{ debtors: IDebtor, histories: ITransactionHistory[], mapped: boolean }> = (props) => {
    const { histories } = props
    const classes = useStyles();
    const { state } = useGlobalState();

    return (
        <React.Fragment >
            <h4 className={classes.girdView}> Notes</h4>
            <Box p={1} width="100%" flexShrink={0} bgcolor="white" className={classes.root} >
                <Scrollbars autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={10}
                    autoHeight
                    autoHeightMin={135}
                    autoHeightMax={135}
                    style={{ width: "100%" }}>
                    {histories.length > 0 ?
                        histories.map((Histories) => {
                            if (Histories.activityType !== "0") {
                                return (
                                    <p className={`${Histories.activityType === "T" ? classes.textStyle : classes.textStyle1}`}>
                                        {moment(Histories.activityDt).format(state.GlobalUtils?.settingValue)}
                                        &nbsp;{Histories.activity?.split('|')[0]}
                                    </p>
                                )

                            } else {
                                return (
                                    < Typography variant="h6" gutterBottom className={classes.norecord}>
                                        No Records to Display
                                    </Typography>
                                )
                            }
                        })
                        : <RollSpinner />
                    }
                </Scrollbars>
            </Box>
        </React.Fragment>
    )
}

export default ReportAccountHistory
