import {
    Backdrop, Button, createStyles, Dialog, DialogActions, DialogContent, makeStyles,
    Snackbar, Theme, Typography, useMediaQuery, useTheme, IconButton
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
// Icons
import CancelIcon from '@material-ui/icons/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
// Componants
import { Transition } from '../../components/GlobalStyles/DialogBoxTransition';
import { IProspectUsersDetails } from '../../models/accesscontrol/ProspectUsersDetails';
import { useDelete, usePost } from '../../utils/apiHelper';
import { DialogTitleDelete } from '../GlobalStyles/DialogStyle';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        },
        yesButton: {
            background: "green",
            color: 'white',
            borderRadius: 20,
            padding: '2px',
            right: '15px',
            '&:hover': {
                background: "green",
            }
        },
        noButton: {
            background: "red",
            borderRadius: 20,
            padding: '2px',
            display: 'flex',
            right: '10px',
            '&:hover': {
                background: "red",
            }
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        textfield: {
            marginTop: "5px",
            color: "black"
        },
        deleteIcon: {
            color: 'red',
        },
        deleteBtnRadius: {
            borderRadius: 30,
            fontSize: 11,
            color: 'white',
            backgroundColor: 'red',
            '&:hover': {
                background: "red",
            }
        },
        rejectButton: {
            fontSize: 11,
            borderRadius: 20,
            gap: '5%',
            marginLeft: '15px',
            backgroundColor: 'red',
            '&:hover': {
                background: "red",
            }
        },
        deleteIconButton: {
            padding: '5px',
            marginLeft: '25px'
        }
    })
);

const DeleteProspectUser: React.FC<{ userRow: IProspectUsersDetails, onDelete: () => void }> = (props) => {
    const { userRow } = props
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [DeleteUser, setDeleteUser] = useState(false);
    const classes = useRowStyles();
    const [ProgressBar, setShowProgressBar] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setShowProgressBar(true);
    };

    const handleClose = () => {
        setOpen(false);
        setShowProgressBar(false);

    };

    const handleOk = () => {
        setOpen(false);
        useDelete("User/DeleteProspectUsers?prospectUserName=" + userRow?.prospectEmailAddress).then(() => {
            setDeleteUser(true);
            SendEmailtoRejectedUsers();
        }).finally(() => {
            props.onDelete();
            setShowProgressBar(false);
        });
    };

    const SendEmailtoRejectedUsers = () => {
        //Email will trigger when a user is Rejected
        let sendEmail = {
            "userName": userRow?.prospectEmailAddress,
            "organizationAdminEmailId": userRow?.prospectDisplayName,
            "organizationPhoneNumber": userRow?.prospectDisplayName,
            "organizationName": userRow?.prospectDisplayName
        }
        usePost<any>("SendEmailtoRejectedUsers", sendEmail);
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setDeleteUser(false);
    };

    return (
        <React.Fragment>
            <div>
                <IconButton className={classes.deleteIconButton} disabled={userRow?.isPermissionUpdated || !userRow?.isActive} onClick={handleClickOpen}>
                    <DeleteOutlineIcon className={`${(userRow?.isPermissionUpdated || !userRow?.isActive) ? null : classes.deleteIcon}`} />
                </IconButton>

                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Dialog TransitionComponent={Transition}
                    fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth={'sm'}
                    aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                    <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
                        <Typography variant="h6" className={classes.titleheader}>
                            REJECT USER ({userRow?.prospectGivenName} {userRow?.prospectLastName} )
                        </Typography>
                    </DialogTitleDelete>
                    <DialogContent>
                        <Typography variant="h6" className={classes.textfield} gutterBottom>
                            Are you sure, you want to reject this user and disable access to this tool?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button id="DU_ok_btn" size="small" variant="contained" startIcon={<DoneAllIcon />} onClick={(e) => { handleOk(); }} className={classes.yesButton}>
                            Yes
                        </Button>
                        <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar open={DeleteUser} className="snackBarStyle" anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={4000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                        User has been Rejected Successfully!
                    </Alert>
                </Snackbar>
            </div>
        </React.Fragment>
    );
}

export default DeleteProspectUser