import {
    AppBar, Backdrop, Grid, Table, TableBody, TableCell, TableContainer,
    TableHead, TablePagination, TableRow, Toolbar, useMediaQuery, useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { RemoveCircle } from '@mui/icons-material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EmailIcon from '@mui/icons-material/Email';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PendingIcon from '@mui/icons-material/Pending';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Box, Chip, Dialog, DialogContent, IconButton, Paper, Tab, Tabs, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { PuffLoader } from 'react-spinners';
import { ARMLogo } from '../../../constants/Constant';
import norecord from '../../../images/trustStatement/noFiles.svg';
import { ITrustStatementExistingUserList, ITrustStatmentReportHistory } from '../../../models/NotificationCenter/TrustStatementClientCodeResult';
import { trustStatementReportShareStatus } from '../../../models/accesscontrol/ClientUserAccessContext';
import { useStyles } from '../../../pages/NotificationCenter/NotificationCenterCss';
import { useGlobalState } from '../../../store/GlobalStore';
import { useFetch } from '../../../utils/apiHelper';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import TrustSharedUserHistory from './TrustSharedUserHistory';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const SendEmailUserList: React.FC<{ clientCode: string, folderId: number }> = (props) => {
    const { clientCode, folderId } = props;
    const classes = useStyles();
    const { state } = useGlobalState();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [trustStamentEmailHistoryDetails, setTrustStamentEmailHistoryDetails] = useState<ITrustStatmentReportHistory[]>([]);
    const [trustStatementExistingUserList, setTrustStatementExistingUserList] = useState<ITrustStatementExistingUserList[]>([]);
    const [sharedUserTotalCount, setSharedUserTotalCount] = useState<number>(0);
    const [existUserTotalCount, setExistUserTotalCount] = useState<number>();
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [rowsPerPageUser, setRowsPerPageUser] = useState(20);
    const [pageUser, setPageUser] = useState(0);
    const [rowsPerPageExistUser, setRowsPerPageExistUser] = useState(20);
    const [pageExistUser, setPageExistUser] = useState(0);
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleOpen = () => {
        setOpen(true);
        GetSharedReportEmailHistory();
    }

    const handleClose = () => {
        setOpen(false);
        setValue(0);
        setPageExistUser(0);
        setRowsPerPageExistUser(20);
        setPageUser(0);
        setRowsPerPageUser(20);
        setExistUserTotalCount(null);
        setSharedUserTotalCount(null);
        setTrustStatementExistingUserList(null);
        setTrustStamentEmailHistoryDetails(null);
    }

    const handleChangeUser = (event: unknown, newPage: number) => {
        setPageUser(newPage);
    };

    const handleChangeRowsPerPageUser = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPageUser(+event.target.value);
    };

    const handleChangePageExistUser = (event: unknown, newPage: number) => {
        setPageExistUser(newPage);
    };

    const handleChangeRowsPerPageExistUser = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPageExistUser(+event.target.value);
    };

    const GetSharedReportEmailHistory = () => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<{
                trustStamentEmailHistoryDetails: ITrustStatmentReportHistory[], sharedUserTotalCount: number,
                trustStatementExistingUserList: ITrustStatementExistingUserList[], existUserTotalCount: number
            }>("GetTrustStatementReportShareHistory?subFolderId=" + folderId).then((result) => {
                setTrustStamentEmailHistoryDetails(result?.data["trustStamentEmailHistoryDetails"]);
                setSharedUserTotalCount(result?.data["sharedUserTotalCount"]);
                setTrustStatementExistingUserList(result?.data["trustStatementExistingUserList"]);
                setExistUserTotalCount(result?.data["existUserTotalCount"]);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })();
    };

    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
            </div>
        );
    }


    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <React.Fragment>
            <IconButton size='small' onClick={() => { handleOpen(); }}>
                <LightTooltip title="Users List">
                    <InfoOutlinedIcon color='success' fontSize='small' />
                </LightTooltip>
            </IconButton>

            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
                <AppBar position="fixed" className={classes.appBar} >
                    <Toolbar className={classes.toolbarStyle}>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" >
                            <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            <b>TRUST STATEMENT ACCESS FOR CLIENTCODE - {clientCode}</b>
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={() => handleClose()} aria-label="close" style={{ padding: '5px' }}>
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                </Backdrop>

                <DialogContent >
                    <Grid container spacing={0}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Newly Invited Users" {...a11yProps(0)} />
                                    <Tab label="Already Mapped Users" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <Paper>
                                    <TableContainer style={{ marginTop: "10px" }} className={`${classes.trustStatementReportShareContainer} ${"scrollbox"} ${"on-scrollbar"}`}>
                                        <Table aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.TableCellHeadStyle}>EMAIL ADDRESS</TableCell>
                                                    <TableCell align="left" className={classes.TableCellHeadStyle}>REPORT SHARED BY</TableCell>
                                                    <TableCell align="left" className={classes.TableCellHeadStyle}>REPORT SHARED DATE</TableCell>
                                                    <TableCell align="left" className={classes.TableCellHeadStyle}>STATUS</TableCell>
                                                    <TableCell align="left" className={classes.TableCellHeadStyle}>HISTORY</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {trustStamentEmailHistoryDetails?.map((r) => (
                                                    <TableRow>
                                                        <TableCell className={classes.tablecellTrustStatement}>
                                                            <Typography variant='body2'>
                                                                {r?.emailAddress}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.tablecellTrustStatement}>
                                                            <Typography variant='body2'>
                                                                {r?.emailInvitedBy}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.tablecellTrustStatement}>
                                                            <Typography variant='body2'>
                                                                {moment(r?.reportSharedDate).format(state.GlobalUtils?.settingValue)} {moment(r?.reportSharedDate).format('HH:mm:ss')}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.tablecellTrustStatement}>
                                                            <Chip label={<span><b>{r?.statusName}</b></span>}
                                                                color={trustStatementReportShareStatus.UserSignupInvited == r?.statusId ? "primary" :
                                                                    trustStatementReportShareStatus.SignedInProspectUser == r?.statusId ? "warning" :
                                                                        trustStatementReportShareStatus.TrustNotificationAccessProvided == r?.statusId ? "success" :
                                                                            trustStatementReportShareStatus.TrustNotificationAccessNotProvided == r?.statusId ? "secondary" : "error"}
                                                                icon={trustStatementReportShareStatus.UserSignupInvited == r?.statusId ? <EmailIcon /> :
                                                                    trustStatementReportShareStatus.SignedInProspectUser == r?.statusId ? <PendingIcon /> :
                                                                        trustStatementReportShareStatus.TrustNotificationAccessProvided == r?.statusId ? <DoneAllIcon /> :
                                                                            trustStatementReportShareStatus.TrustNotificationAccessNotProvided == r?.statusId ? <PersonOffIcon /> : <RemoveCircle />}
                                                                size='small' variant="outlined" />
                                                        </TableCell>
                                                        <TableCell className={classes.tablecellTrustStatement}>
                                                            <TrustSharedUserHistory row={r} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        {!trustStamentEmailHistoryDetails?.length && !ProgressBar ?
                                            <Box m={0} p={0} mt={5} mb={5} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                                <img alt="noRecords" src={norecord} width="120px" style={{ textAlign: 'center' }} />
                                                <Typography variant='body2' align='center' color='red' mt={1} mb={1}>
                                                    <b>No data to display</b>
                                                </Typography>
                                            </Box>
                                            : null}
                                    </TableContainer>
                                    <TablePagination
                                        id="Files_TablePagination"
                                        rowsPerPageOptions={[20, 50, 100]}
                                        component="div"
                                        count={existUserTotalCount === undefined ? 0 : existUserTotalCount}
                                        rowsPerPage={rowsPerPageUser}
                                        style={{ borderTop: '1px solid lightgray' }}
                                        page={pageUser}
                                        onChangePage={handleChangeUser}
                                        onChangeRowsPerPage={handleChangeRowsPerPageUser}
                                    />
                                </Paper>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <Paper>
                                    <TableContainer style={{ marginTop: "10px" }} className={`${classes.trustStatementReportShareContainer} ${"scrollbox"} ${"on-scrollbar"}`}>
                                        <Table aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.TableCellHeadStyle}>EMAIL ADDRESS</TableCell>
                                                    <TableCell align="left" className={classes.TableCellHeadStyle}>FIRST NAME</TableCell>
                                                    <TableCell align="left" className={classes.TableCellHeadStyle}>LAST NAME</TableCell>
                                                    <TableCell align="left" className={classes.TableCellHeadStyle}>STATUS</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {trustStatementExistingUserList?.map((r) => (
                                                    <TableRow>
                                                        <TableCell width={'23%'} className={classes.tablecellTrustStatement}>
                                                            <Typography variant='body2'>
                                                                {r?.userName}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell width={'23%'} className={classes.tablecellTrustStatement}>
                                                            <Typography variant='body2'>
                                                                {r?.firstName}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.tablecellTrustStatement}>
                                                            <Typography variant='body2'>
                                                                {r?.lastName}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell className={classes.tablecellTrustStatement}>
                                                            <Chip label={<span><b>Trust Notification Access Provided</b></span>} color={'success'}
                                                                icon={<DoneAllIcon />} size='small' variant="outlined" />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        {!trustStatementExistingUserList?.length && !ProgressBar ?
                                            <Box m={0} p={0} mt={5} mb={5} display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
                                                <img alt="noRecords" src={norecord} width="120px" style={{ textAlign: 'center' }} />
                                                <Typography variant='body2' align='center' color='red' mt={1} mb={1}>
                                                    <b>No data to display</b>
                                                </Typography>
                                            </Box>
                                            : null}
                                    </TableContainer>
                                    <TablePagination
                                        id="Files_TablePagination"
                                        rowsPerPageOptions={[20, 50, 100]}
                                        component="div"
                                        count={sharedUserTotalCount === undefined ? 0 : sharedUserTotalCount}
                                        rowsPerPage={rowsPerPageExistUser}
                                        style={{ borderTop: '1px solid lightgray' }}
                                        page={pageExistUser}
                                        onChangePage={handleChangePageExistUser}
                                        onChangeRowsPerPage={handleChangeRowsPerPageExistUser}
                                    />
                                </Paper>
                            </CustomTabPanel>
                        </Box>
                    </Grid>
                </DialogContent>
            </Dialog >
        </React.Fragment >
    )
}

export default SendEmailUserList