import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { ITemplateList } from "../../../models/Admin/ClientPlacements/ITemplateList";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { useStyles } from './ClientPlacementOptionsCss';

function Template({ clientCode, selectedTemplateId, templateList, onTemplateSelected }: { clientCode: string, selectedTemplateId: number, templateList: ITemplateList[], onTemplateSelected: (templateId: number, clientCode: string) => void }) {
  const classes = useStyles();

  const handleChange = (event, selected) => {
    if (selected) {
      const selectedValue = templateList.find(x => x.placement_template_id === selected.placement_template_id);
      if (selectedValue) {
        onTemplateSelected(selectedValue.placement_template_id, clientCode);
      }
    }
  };

  return (
    <React.Fragment>
      <FormControl variant="filled" className={classes.formTemplateControl}>
        <Autocomplete size="small"
          id="client-template"
          disabled={clientCode.includes('999')}
          className={classes.selecttemplate}
          classes={{
            paper: classes.paper1,
            option: classes.smallfont
          }}
          defaultValue={templateList.find(x => x.placement_template_id === selectedTemplateId)}
          autoHighlight
          options={templateList}
          onChange={handleChange} disableClearable={true}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.placement_template_id === value.placement_template_id}
          renderOption={(option) => (
            <React.Fragment>
              {option.name}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Template" size="small" margin="normal" variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
                style: {
                  fontSize: 14
                }
              }}
            />
          )} />
      </FormControl>
    </React.Fragment>
  );
}
export default Template