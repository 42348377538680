import {
    createStyles, Drawer, List, ListItem, makeStyles, Theme, useMediaQuery, useTheme
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Routes from '../Routes'
import { useGlobalState } from "../store/GlobalStore"

const styles = makeStyles((theme: Theme) => createStyles({
    closeIcon: {
        marginRight: theme.spacing(0.5)
    },
    headSection: {
        width: 200
    },
    blackList: {
        backgroundColor: theme.palette.common.black,
        height: "100%"
    },
    noDecoration: {
        textDecoration: "none !important"
    },
    toolbar: theme.mixins.toolbar,
    appHeaderLinkMobile: {
        color: '#007fff',
        padding: '12px 22px',
        textDecoration: 'none',
        marginLeft: '2px',
        fontWeight: "bold",
        width: '100%',
    },
    listMenu: {
        borderBottom: '1px solid #007fff',
        padding: '0px',
        '&:hover': {
            backgroundColor: '#007fff',
        },
        '&:hover a': {
            color: '#fff',
        }
    }
}))

interface INavigationDrawerProps {
    open: boolean,
    onClose: () => void
}

const NavigationDrawer: React.FC<INavigationDrawerProps> = (props) => {
    const { open, onClose } = props
    const { state, dispatch } = useGlobalState();
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))
    const classes = styles()
    const allowedRoutes = Routes.filter(r => { return r.UserRole?.some(role => role == state.userAccessContext?.role) });
    const [finalRoutes, setFinalRoutes] = useState([]);

    useEffect(() => {
        window.onresize = () => {
            if (matches && open) {
                onClose()
            }
        }
    }, [matches, open, onClose])

    useEffect(() => {
        (async () => {
            let filteredRoutes = allowedRoutes.filter(x => !x.noNavLink);
            let reportId = filteredRoutes.findIndex(x => x.title === "Reports");
            let placementId = filteredRoutes.findIndex(x => x.title === "Placement");
            let rollId = filteredRoutes.findIndex(x => x.title === "Roll");
            if (state.userAccessContext?.editReports === false) {
                delete filteredRoutes[reportId];
            }
            if (state.userAccessContext?.rollAccounts === false) {
                delete filteredRoutes[rollId];
            }
            if (state.userAccessContext?.placeAccounts === false) {
                delete filteredRoutes[placementId];
            }
            setFinalRoutes(filteredRoutes);
        })()
    }, [state.userAccessContext?.role])

    return (
        <Drawer variant="temporary" open={open} onClose={onClose} anchor="right">
            <nav className={classes.toolbar}>
                <List >
                    <React.Fragment>
                        {finalRoutes.map((route, key) => {
                            return (
                                <ListItem className={classes.listMenu}>
                                    <Link
                                        id="Header_NavigationLink"
                                        className={classes.appHeaderLinkMobile}
                                        to={route.path}
                                        key={route.title}>
                                        {route.title}
                                    </Link>
                                </ListItem>
                            )
                        })}
                    </React.Fragment>
                </List>
            </nav>
        </Drawer >
    )
}

export default NavigationDrawer