import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    div: {
        flexGrow: 1,
        padding: 8
    },
    outlinedInput: {
        fontSize: 14,
        color: "black",
        '&$focused $notchedOutline': {
            color: 'Red',
            border: '0px'
        },
    },
    notchedOutline: {
        color: 'white',
        border: '0px'
    },
    heading: {
        textAlign: 'left',
    },
    btnManTemp: {
        borderRadius: '20px',
        float: 'right',
        fontSize: 12
    },
    btnManTemp1: {
        borderRadius: '20px',
        float: 'left',
        fontSize: 12,
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    tablebody: {
        maxHeight: 500,
        minHeight: 500,
        marginTop: '10px',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 210px)',
            minHeight: 'auto',
        },
    },
    TableCellStyle: {
        background: "#007FFF",
        color: "white",
        fontSize: 13,
        padding: '2px 0px 2px 15px',
        TextAlign: 'left'
    },
    TableCellStyle1: {
        fontSize: 13,
        padding: '4px 0px 4px 15px',
    },
    paper1: {
        fontSize: 12,
        border: "2px solid black",
    },
    smallfont: {
        fontSize: 13,
    },
    selecttemplate: {
        width: '100%',
        height: 40,
        fontSize: 13,
        borderRadius: '5px',
        borderBottom: '3px solid blue',
        '& .MuiFormControl-marginNormal': {
            marginTop: '3px',
            fontSize: 13,
        }
    },
    manageTemp: {
        borderRadius: '20px',
        float: 'left',
        fontSize: 12,
    },
    createGroup: {
        borderRadius: 20,
        color: 'white',
        fontSize: 12,
        marginBottom: '0px',
        marginTop: '3px',
        background: "green",
        padding: '3px 10px',
        '&:hover': {
            background: "green",
        }
    },
    cancelGroup: {
        borderRadius: 20,
        color: 'white',
        fontSize: 12,
        marginRight: '15px',
        marginTop: '3px',
        background: "red",
        padding: '3px 10px',
        '&:hover': {
            background: "red",
        }
    },
    customizeStyle: {
        borderRadius: 20,
        marginRight: '10px',
        fontSize: 12,
        marginTop: '5px'
    },
    addButton: {
        borderRadius: 20,
        marginRight: '10px',
        fontSize: 12,
        marginTop: '12px',
        marginLeft: '10px'
    },
    customizeButton: {
        fontSize: 12,
        marginTop: '5px'
    },
    cancelbtn: {
        borderRadius: 20,
        background: "red",
        marginRight: '20px',
        fontSize: 12,
        '&:hover': {
            background: "red",
        }
    },
    edittemplate: {
        borderRadius: 20,
        fontSize: 12,
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    titleheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white',
        fontSize: 18
    },
    textAlginEnd: {
        textAlign: 'left',
        fontSize: 12,
        padding: '2px 0px'
    },
    textStyle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    textStyleFont: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    textStyle1: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'blue'
    },
    gridImageStyle: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
    },
    titleConfirm: {
        color: "black",
        fontSize: 17
    },
    //Delete Template Css
    yesButton: {
        background: "green",
        color: 'white',
        bottom: '0.2cm',
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "green",
        }
    },
    noButton: {
        background: "red",
        display: 'flex',
        bottom: '0.2cm',
        color: 'white',
        fontSize: 12,
        borderRadius: 20,
        '&:hover': {
            background: "red",
        }
    },
    dialogeDelete: {
        minWidth: "480px",
    },
    managebtn: {
        borderRadius: '20px'
    },
    checkboxStyle: {
        width: '200px'
    },
    permission: {
        display: 'flex',
        flexDirection: 'row',
    },
    deleteIcon: {
        color: 'red',
        fontSize: '28px !important',
    },
    tablecellStyle: {
        background: '#007FFF',
        color: 'white',
    },
    managetable: {
        marginTop: '10px'
    },
    managetempltebtn: {
        borderRadius: 20,
        marginRight: '5px',
        fontSize: 12
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    boxStyle: {
        padding: '8px 10px'
    },
    tablebodyActivity: {
        maxHeight: 600,
        minHeight: 600,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 178px)',
            minHeight: 'calc( 100vh - 178px)',
        },
    },
    DisplayOrderStyle: {
        fontSize: 13,
        padding: '4px 0px 4px 15px',
        paddingLeft: '60px'
    },
    activityName: {
        color: 'blue',
        marginTop: '5px'
    },
    textfieldname: {
        transition: "all 0.5s ease",
        borderRadius: '5px',
        fontSize: '12px',
        marginTop: '10px'
    },
    closeButton: {
        background: "red",
        borderRadius: 20,
        marginRight: '10px',
        color: 'white',
        fontSize: 12,
        '&:hover': {
            background: "red",
        },
    },
    tablecell: {
        fontSize: 13,
        padding: '3px 15px'
    },
    groupNameTextfield: {
        width: '100%',
        marginTop: '10px'
    }
}));
export { useStyles }