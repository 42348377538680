import {
    Backdrop, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel,
    Grid, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import _ from 'lodash';
import React, { useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
// Icons
import BorderColorIcon from '@material-ui/icons/BorderColor';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoIcon from '@mui/icons-material/Info';
import StarIcon from '@mui/icons-material/Star';
import Caution from '../../../images/ADF/complain.png';
// Models
import * as constant from "../../../constants/Constant";
import { IAgentTemplate } from "../../../models/AgentTemplate/IAgentTemplate";
import { IAgentUserList, IClientDetails, IClientPermissionResult, IClientPhaseDetails, IClientTemplateDetails } from "../../../models/AgentTemplate/IClientTemplateDetails";
import { ITemplatesList } from "../../../models/AgentTemplate/ITemplatesList";
import { useGlobalState } from '../../../store/GlobalStore';
import { usePost } from "../../../utils/apiHelper";
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import TooltipSmall from '../../GlobalStyles/TooltipSmall';
import { useStyles } from './AG_TemplateCss';
import { IFTEHistoryDetail } from '../../../models/AgentUser/IAgentClientDetails';

const Template: React.FC<{ onSave: () => void, templateList: ITemplatesList[], agentTemplate: IAgentTemplate }> = (props) => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const [open, setOpen] = useState(false);
    const { templateList, agentTemplate } = props;
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [selected1STPValue, setSelected1STPValue] = useState(false);
    const [selectedPRECValue, setSelectedPRECValue] = useState(false);
    const [selectedCONTValue, setSelectedCONTValue] = useState(false);
    const [templateUpdate, setTemplateUpdate] = useState(false);
    const [isSubmit, setisSubmit] = useState(false);
    const [modified, setModified] = useState(false);
    const [clientPhaseDetails, setClientPhaseDetails] = useState<IClientPhaseDetails[]>([]);
    const [newTemplateDetails, setNewTemplateDetails] = useState<IClientTemplateDetails[]>([]);
    const [clientTemplateDetails, setClientTemplateDetails] = useState < IClientTemplateDetails[] > ([]);
    const [totalCount, setTotalCount] = useState(0);
    const [templateAssignedUsersList, setTemplateAssignedUsersList] = useState<IFTEHistoryDetail[]>([]);

    const AgentFteInsert = "AgentUser/InsertFTEHistory";
    let newAgentUsersList = [];

    function localTemplate(
        clientCode: string,
        phaseCode: string,
        templateId: number,
        readOnly: boolean,
        isModified: boolean
    ): IClientTemplateDetails {
        return { clientCode, phaseCode, templateId, readOnly, isModified };
    }

    const templateData = [
        localTemplate(agentTemplate?.clientCode, "1STP", 0, false, false),
        localTemplate(agentTemplate?.clientCode, "PREC", 0, false, false),
        localTemplate(agentTemplate?.clientCode, "CONT", 0, false, false),
    ]

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setTemplateUpdate(false);
    };
    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = () => {
        setOpen(false);
        setClientTemplateDetails([]);
        setSelected1STPValue(false);
        setSelectedPRECValue(false);
        setSelectedCONTValue(false);
    };

    const handleCloseDialog = () => {
        setisSubmit(false);
    }

    let newAgentTemplate: IAgentTemplate = {
        clientName: agentTemplate?.clientName,
        clientCode: agentTemplate?.clientCode,
        firstParty: agentTemplate?.firstParty,
        flatFee: agentTemplate?.flatFee,
        contingency: agentTemplate?.contingency,
        isContingencyReadOnly: agentTemplate?.isContingencyReadOnly,
        isFirstPartyReadOnly: agentTemplate?.isFirstPartyReadOnly,
        isFlatFeeReadOnly: agentTemplate?.isFlatFeeReadOnly
    }

    let phaseDetails: IClientPhaseDetails = {
        isFirstParty: (clientPhaseDetails[0]?.isFirstParty == undefined ? true : clientPhaseDetails[0]?.isFirstParty),
        isFlatFee: (clientPhaseDetails[0]?.isFlatFee == undefined ? true : clientPhaseDetails[0]?.isFlatFee),
        isContingency: (clientPhaseDetails[0]?.isContingency == undefined ? true : clientPhaseDetails[0]?.isContingency)
    };

    const handleReadOnlyChecked = (e, checked) => {
        setModified(true);
        let updateReadOnlyPermission = _.cloneDeep(clientTemplateDetails);
        updateReadOnlyPermission.forEach((i) => {
            if (i.phaseCode === e.target.value) {
                i.readOnly = checked,
                    i.isModified = true
            }
        });
        setClientTemplateDetails(updateReadOnlyPermission);
    }

    const handleChange = (selected, phase: string) => {
        let newClientTemplateList = _.cloneDeep(clientTemplateDetails);
        phase == "1STP" ? setSelected1STPValue(true) : phase === "PREC" ? setSelectedPRECValue(true) : setSelectedCONTValue(true)
        newClientTemplateList.map((r, i) => {
            if (templateList.find(x => x?.activityTemplateId === selected?.activityTemplateId) && (phase == r?.phaseCode)) {
                r.templateId = selected?.activityTemplateId,
                    r.isModified = true
            }
        })
        setClientTemplateDetails(newClientTemplateList);
        setModified(true);
    };

    const handleClickOpen = () => {
        (async () => {
            let request = { "clientCode": agentTemplate?.clientCode };
            await usePost<{ clientPermissionResults: IClientPermissionResult[], clientPhaseDetails: IClientPhaseDetails[] }>("AgentUser/GetClientTemplatePermission", request).then((result) => {
                let agentTemplateDetails = _.cloneDeep(clientTemplateDetails);
                if (result?.data?.clientPermissionResults?.length !== 0) {
                    result.data.clientPermissionResults.map((template) => {
                        let selectedTemplateDetails: IClientTemplateDetails = {
                            templateId: template?.templateId,
                            clientCode: agentTemplate?.clientCode,
                            phaseCode: template?.phaseCode,
                            readOnly: template?.readOnly,
                            isModified: false
                        }
                        agentTemplateDetails.push(selectedTemplateDetails);
                    });
                } else {
                    templateData?.map((r) => {
                        let selectedTemplateDetails: IClientTemplateDetails = {
                            templateId: 0,
                            clientCode: r?.clientCode,
                            phaseCode: r?.phaseCode,
                            readOnly: r?.readOnly,
                            isModified: false
                        }
                        agentTemplateDetails.push(selectedTemplateDetails);
                    })
                }
                setClientPhaseDetails(result.data?.clientPhaseDetails);
                setClientTemplateDetails(agentTemplateDetails);
                setNewTemplateDetails(agentTemplateDetails);
            }).finally(() => {
                setOpen(true);
            });
        })()
    };

    // To get the total number of agent users mapped with the client code
    async function handleSave() {
        let UpdatedClientTemplate = [];
        let updatedAgentUsersList = [];
        let previousActivityTemplateId = 0;
        clientTemplateDetails.forEach((r) => {
            if (r?.isModified) {
                let InsertTemplate: IClientDetails = { clientCode: r?.clientCode, phaseCode: r?.phaseCode }
                UpdatedClientTemplate.push(InsertTemplate);
            }
        });
        setShowProgressBar(true);
        (async () => {
            let request = {
                "activityTemplateId": 1,
                "clientDetails": UpdatedClientTemplate
            }
            await usePost<{ agentClientDetails: IClientDetails[], totalCount: number, agentUserList: IAgentUserList[] }>('AgentUser/GetMappedClientCodeDetail', request).then((r) => {
                setTotalCount(r?.data?.totalCount);
                r?.data?.agentUserList?.forEach((result) => {
                    newTemplateDetails?.forEach((r) => {
                        if (r?.phaseCode === result?.phaseCode) {
                            previousActivityTemplateId = r?.templateId
                        }
                    })
                    let newResult: IFTEHistoryDetail = {
                        agentUserId: result?.agentUserId,
                        clientCode: result?.clientCode,
                        phaseCode: result?.phaseCode,
                        previousActivityTemplateId: previousActivityTemplateId,
                        newActivityTemplateId: null
                    }
                    updatedAgentUsersList?.push(newResult);
                })
                setTemplateAssignedUsersList(updatedAgentUsersList);
            }).finally(() => {
                setShowProgressBar(false);
                setisSubmit(true);
            });
        })()
    }

    async function handleSubmitTemplate() {
        setisSubmit(false);
        setShowProgressBar(true);
        let UpdatedClientTemplate = [];
        clientTemplateDetails.forEach((r) => {
            if (r?.isModified) {
                let InsertTemplate: IClientTemplateDetails = { clientCode: r?.clientCode, phaseCode: r?.phaseCode, readOnly: r?.readOnly, templateId: r?.templateId }
                UpdatedClientTemplate.push(InsertTemplate);
            }
        });
        // Added new logic to get the updated Activity template id based on the client code and phase
        let newActivityTemplateId = 0;
        templateAssignedUsersList?.forEach((result) => {
            clientTemplateDetails?.forEach((r) => {
                if (r?.phaseCode === result?.phaseCode) {
                    newActivityTemplateId = r?.templateId
                }
            })
            let newResult: IFTEHistoryDetail = {
                agentUserId: result?.agentUserId,
                clientCode: result?.clientCode,
                phaseCode: result?.phaseCode,
                previousActivityTemplateId: result?.previousActivityTemplateId,
                newActivityTemplateId: newActivityTemplateId
            }
            newAgentUsersList?.push(newResult);
        })

        let request = {
            "modifiedBy": state?.userAccessContext?.id,
            "clientTemplateDetails": UpdatedClientTemplate
        }
        await usePost('AgentUser/ManageClientActivityTemplate', request).then(() => {
            props.onSave();
            setTemplateUpdate(true);
        }).finally(() => {
            handleInsertFTEHistory();
            setModified(false);
            setShowProgressBar(false);
            handleCloseDialog();
            handleClose();
        });
    }

    const handleInsertFTEHistory = () => {
        let request = {
            "agentFteHistory": newAgentUsersList,
            "fteAction": 3,
            "fteActionTakenBy": state?.userAccessContext?.id,
        }
        usePost<any>(AgentFteInsert, request).then((r) => {
        }).finally(() => {
            setShowProgressBar(false);
        });
    }

    return (
        <React.Fragment>
            <Button id="CreateTemplate_btn" size="small" startIcon={<BorderColorIcon />} variant="contained"
                color="primary" className={classes.managebtn} onClick={handleClickOpen}>Edit</Button>
            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} TransitionComponent={Transition}
                onClose={handleClose} aria-labelledby="responsive-dialog-title" maxWidth={'md'}>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        MANAGE ACTIVITY TEMPLATE
                    </Typography>
                </DialogTitleHeader>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={100} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent style={{ overflowY: 'hidden' }}>
                    <Grid container spacing={0}>
                        <TableContainer component={Paper} className={classes.managetable}>
                            <Table aria-label="customized table" size="small" stickyHeader>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.tablecellStyle} >
                                            <span><b>CLIENT CODE</b></span>
                                        </TableCell>
                                        <TableCell className={classes.tablecellStyle} >
                                            <span><b>CLIENT NAME</b></span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body2" >
                                                {newAgentTemplate.clientCode}
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography variant="body2" >
                                                {newAgentTemplate.clientName}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid xs={12} className={classes.manageTemplate}>
                            <Grid xs={12} sm={4}
                                className={`${phaseDetails?.isFirstParty ? classes.manageActivityDesign : classes.manageActivityDesignDisable}`}>
                                <div style={{ display: 'flex' }}>
                                    <Box width="90%">
                                        <Typography variant="h6" className={classes.textStyleFont}>
                                            1ST PARTY
                                        </Typography>
                                    </Box>
                                    {!phaseDetails?.isFirstParty ? <Box width="10%" >
                                        <TooltipSmall title="This Client Code Doesn't have Permission to 1ST Party">
                                            <InfoIcon style={{ color: 'gray' }} />
                                        </TooltipSmall>
                                    </Box> : null}
                                </div>
                                <div className={classes.readOnlyAccess}>
                                    <FormControl component="fieldset" >
                                        <FormControlLabel
                                            label="Read Only"
                                            labelPlacement="end"
                                            id="AG_emailreceived"
                                            className={classes.formControlLabel}
                                            checked={!phaseDetails?.isFirstParty ? clientTemplateDetails[2]?.readOnly : clientTemplateDetails[0]?.readOnly}
                                            disabled={!phaseDetails?.isFirstParty} value={constant.FIRSTSTP}
                                            control={<Checkbox onChange={(e, checked) => { handleReadOnlyChecked(e, checked); }}
                                                size="small" name="readOnly" className={classes.checkboxPhase} />} />
                                    </FormControl>
                                </div>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <Autocomplete size="small"
                                        id="client-template"
                                        className={classes.selecttemplate}
                                        disabled={!phaseDetails?.isFirstParty}
                                        options={templateList} disableClearable={true}
                                        getOptionLabel={(option) => option.activityTemplateName}
                                        classes={{ paper: classes.paper1, option: classes.smallfont }}
                                        onChange={(e, selected) => { handleChange(selected, constant.FIRSTSTP) }}
                                        getOptionSelected={(option, value) => option.activityTemplateId === value.activityTemplateId}
                                        defaultValue={templateList.find(x => x.activityTemplateName === newAgentTemplate?.firstParty)}
                                        filterOptions={(x) => x.filter((x) => x.activityTemplateName !== constant.defaultTempFlatFee && x.activityTemplateName !== constant.defaultTempContingency)}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                <span className={`${option.activityTemplateName == constant.defaultTempFirstParty || option.activityTemplateName == constant.defaultTempFlatFee || option.activityTemplateName == constant.defaultTempContingency ? classes.globalTemplatedropDown : null}`}>
                                                    {option.activityTemplateName}

                                                    {option.activityTemplateName == constant.defaultTempFirstParty || option.activityTemplateName == constant.defaultTempFlatFee || option.activityTemplateName == constant.defaultTempContingency ?
                                                        <StarIcon className={classes.starIcon} style={{ fontSize: 17 }} />
                                                        : null}
                                                </span>
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Select Template"
                                                size="small" margin="normal" variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                    style: { fontSize: 14 }
                                                }}
                                            />
                                        )} />
                                    {phaseDetails.isFirstParty && selected1STPValue ?
                                        <CheckCircleIcon className={classes.templateIcon} />
                                        : null
                                    }
                                </div>
                            </Grid>
                            <Grid xs={12} sm={4}
                                className={`${phaseDetails?.isFlatFee ? classes.manageActivityDesign : classes.manageActivityDesignDisable}`}>
                                <div style={{ display: 'flex' }}>
                                    <Box width="90%">
                                        <Typography variant="h6" className={classes.textStyleFont}>
                                            FLAT FEE
                                        </Typography>
                                    </Box>
                                    {!phaseDetails?.isFlatFee ? <Box width="10%" >
                                        <TooltipSmall title="This Client Code Doesn't have Permission to Flat Fee">
                                            <InfoIcon style={{ color: 'gray' }} />
                                        </TooltipSmall>
                                    </Box> : null}
                                </div>
                                <div className={classes.readOnlyAccess}>
                                    <FormControl component="fieldset" >
                                        <FormControlLabel
                                            label="Read Only"
                                            labelPlacement="end"
                                            id="AG_emailreceived"
                                            disabled={!phaseDetails?.isFlatFee}
                                            checked={!phaseDetails?.isFirstParty ? clientTemplateDetails[1]?.readOnly : clientTemplateDetails[2]?.readOnly}
                                            className={classes.formControlLabel} value={constant.PREC}
                                            control={<Checkbox onChange={(e, checked) => { handleReadOnlyChecked(e, checked); }}
                                                size="small" name="readOnly" className={classes.checkboxPhase} />} />
                                    </FormControl>
                                </div>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <Autocomplete size="small"
                                        id="client-template"
                                        className={classes.selecttemplate}
                                        disabled={!phaseDetails?.isFlatFee}
                                        options={templateList} disableClearable={true}
                                        getOptionLabel={(option) => option.activityTemplateName}
                                        classes={{ paper: classes.paper1, option: classes.smallfont }}
                                        onChange={(e, selected) => { handleChange(selected, constant.PREC) }}
                                        defaultValue={templateList.find(x => x.activityTemplateName === newAgentTemplate?.flatFee)}
                                        getOptionSelected={(option, value) => option.activityTemplateId === value.activityTemplateId}
                                        filterOptions={(x) => x.filter((x) => x.activityTemplateName !== constant.defaultTempFirstParty && x.activityTemplateName !== constant.defaultTempContingency)}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                <span className={`${option.activityTemplateName == constant.defaultTempFirstParty || option.activityTemplateName == constant.defaultTempFlatFee || option.activityTemplateName == constant.defaultTempContingency ? classes.globalTemplatedropDown : null}`}>
                                                    {option.activityTemplateName}

                                                    {option.activityTemplateName == constant.defaultTempFirstParty || option.activityTemplateName == constant.defaultTempFlatFee || option.activityTemplateName == constant.defaultTempContingency ?
                                                        <StarIcon className={classes.starIcon} style={{ fontSize: 17 }} />
                                                        : null}
                                                </span>
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Select Template"
                                                size="small" margin="normal" variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                    style: { fontSize: 14 }
                                                }}
                                            />
                                        )} />
                                    {phaseDetails.isFlatFee && selectedPRECValue ?
                                        <CheckCircleIcon className={classes.templateIcon} />
                                        : null
                                    }
                                </div>
                            </Grid>
                            <Grid xs={12} sm={4}
                                className={`${phaseDetails?.isContingency ? classes.manageActivityDesign : classes.manageActivityDesignDisable}`}>
                                <div style={{ display: 'flex' }}>
                                    <Box width="90%">
                                        <Typography variant="h6" className={classes.textStyleFont}>
                                            CONTINGENCY
                                        </Typography>
                                    </Box>
                                    {!phaseDetails?.isContingency ? <Box width="10%" >
                                        <TooltipSmall title="This Client Code Doesn't have Permission to Contingency">
                                            <InfoIcon style={{ color: 'gray' }} />
                                        </TooltipSmall>
                                    </Box> : null}
                                </div>
                                <div className={classes.readOnlyAccess}>
                                    <FormControl component="fieldset" >
                                        <FormControlLabel
                                            label="Read Only"
                                            labelPlacement="end"
                                            id="AG_emailreceived"
                                            value={constant.CONT}
                                            className={classes.formControlLabel}
                                            disabled={!phaseDetails?.isContingency}
                                            checked={!phaseDetails?.isFirstParty ? clientTemplateDetails[0]?.readOnly : clientTemplateDetails[1]?.readOnly}
                                            control={<Checkbox onChange={(e, checked) => { handleReadOnlyChecked(e, checked); }}
                                                size="small" name="readOnly" className={classes.checkboxPhase} />} />
                                    </FormControl>
                                </div>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <Autocomplete size="small"
                                        id="client-template"
                                        className={classes.selecttemplate}
                                        disabled={!phaseDetails?.isContingency}
                                        options={templateList} disableClearable={true}
                                        getOptionLabel={(option) => option.activityTemplateName}
                                        classes={{ paper: classes.paper1, option: classes.smallfont }}
                                        onChange={(e, selected) => { handleChange(selected, constant.CONT) }}
                                        getOptionSelected={(option, value) => option.activityTemplateId === value.activityTemplateId}
                                        defaultValue={templateList.find(x => x.activityTemplateName === newAgentTemplate?.contingency)}
                                        filterOptions={(x) => x.filter((x) => x.activityTemplateName !== constant.defaultTempFirstParty && x.activityTemplateName !== constant.defaultTempFlatFee)}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                <span className={`${option.activityTemplateName == constant.defaultTempFirstParty || option.activityTemplateName == constant.defaultTempFlatFee || option.activityTemplateName == constant.defaultTempContingency ? classes.globalTemplatedropDown : null}`}>
                                                    {option.activityTemplateName}

                                                    {option.activityTemplateName == constant.defaultTempFirstParty || option.activityTemplateName == constant.defaultTempFlatFee || option.activityTemplateName == constant.defaultTempContingency ?
                                                        <StarIcon className={classes.starIcon} style={{ fontSize: 17 }} />
                                                        : null}
                                                </span>
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Select Template"
                                                size="small" margin="normal" variant="outlined"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                    style: { fontSize: 14 }
                                                }}
                                            />
                                        )} />
                                    {phaseDetails.isContingency && selectedCONTValue ?
                                        <CheckCircleIcon className={classes.templateIcon} />
                                        : null
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent >
                <DialogActions>
                    <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.btnManTemp1} startIcon={<DoneAllIcon />} autoFocus onClick={() => { handleSave() }} variant="contained" disabled={!modified}>
                        Save
                    </Button>
                    <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.cancelbtn} startIcon={<HighlightOffIcon />} autoFocus onClick={handleClose} variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog >

            <Dialog TransitionComponent={Transition} open={isSubmit}
                className={classes.dialogboxOverride}
                PaperProps={{
                    style: {
                        borderRadius: 15
                    }
                }}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialog}>
                    <Typography variant="h5" gutterBottom className={classes.titleheader}>
                        OVERRIDE CHANGES
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <DialogTitle className={classes.titleHeaderOverride}>
                        <img src={Caution} alt="Block_Email" className={classes.Image} />
                    </DialogTitle>
                    <Typography variant="h6" gutterBottom className={classes.textfield1}>
                        Are you sure to override this new template that affects {totalCount} users?
                    </Typography>
                </DialogContent>

                <Box display="flex" alignItems="center" justifyContent="center" className={classes.dialogaction}>
                    <Button id="IM_ok_btn" size="small" startIcon={<CheckCircleIcon />} variant="contained" className={classes.yesButton} onClick={() => { handleSubmitTemplate(); }} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" startIcon={<CancelIcon />} variant="contained" autoFocus className={classes.noButton} onClick={() => { handleCloseDialog(); }} color="primary">
                        No
                    </Button>
                </Box>
            </Dialog>

            <Snackbar className="snackBarStyle" open={templateUpdate} anchorOrigin={{
                vertical: 'top', horizontal: 'center'
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Agent Template Updated Successfully!!
                </Alert>
            </Snackbar>
        </React.Fragment >
    );
}
export default Template