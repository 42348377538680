import { AppBar, Box, Grid, IconButton, Tab, Tabs, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React, { useEffect, useState } from 'react';
import { rollVerbiage } from '../../constants/Constant';
import { IAccStatus } from '../../models/Inquiry/IAccStatus';
import { IGetRolledPlacement } from '../../models/Inquiry/IGetRolledPlacement';
import { IClosedLetterDetails } from '../../models/Inquiry/LetterDetails/IClosedLetterDetails';
import { ILetterDetails } from '../../models/Inquiry/LetterDetails/ILetterDetails';
import { ITransactionHistory } from '../../models/Inquiry/TransactionHistory/ITransactionHistory';
import { IDebtor } from '../../models/NotificationCenter/IDebtor';
import { IHideForAllUsers } from '../../models/NotificationCenter/IHideForAllUsers';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import LightTooltipLeft from "../GlobalStyles/LightTooltipLeft";
import { RollSpinner } from '../GlobalStyles/Loading';
import AccountDetails from './NCAccountDetails';
import AccountHistory from './NCAccountHistory';
import useStyles from './NCCcss/NotiCenterCss';
import TransactionAuditHistory from './NCTransactionAuditHistory';
import { INotificationCenter } from '../../models/NotificationCenter/INotificationCenter';

interface TabPanelProps {
    children?: React.ReactNode,
    index: any,
    value: any
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}  >
            {value === index && (
                <Box p={5}>
                    <Typography >{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const InquiryDetail: React.FC<{ Notify: INotificationCenter, inquiryDetail: IDebtor, disable?: IGetRolledPlacement, isBlocked: boolean }> = (props) => {
    const { inquiryDetail, isBlocked, Notify } = props;
    const [value, setValue] = React.useState(0);
    const { state } = useGlobalState();
    const classes = useStyles();
    const UserName = state.userAccessContext?.id;
    const [isClosed, setisClosed] = useState<IGetRolledPlacement>();
    const [Transaction, setTransaction] = useState<ITransactionHistory[]>([]);
    const [GetLetterDetails, setGetLetterDetails] = useState<ILetterDetails[]>([]);
    const [visibleLetter, setVisibleLetter] = useState<IClosedLetterDetails[]>([]);
    const [mapped, setMapped] = useState(false);
    const [history, setHistory] = useState<ITransactionHistory[]>([]);
    const [checkData, setCheckData] = React.useState(false);
    const [ProgressBar, setshowProgressBar] = useState(false);
    const [AccDetails, setAccDetails] = useState<IAccStatus>();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    }

    useEffect(() => {
        (async () => {
            await useFetch<ITransactionHistory[]>(`DebtorHistory?DBTNumber=${inquiryDetail.dbT_NO}`).then((GetDebtorHistory) => {
                setHistory(GetDebtorHistory.data);
                setMapped(GetDebtorHistory.data.length > 0 ? true : false);
            });
        })()
    }, [])

    useEffect(() => {
        (async () => {
            await useFetch<ITransactionHistory[]>(`GetTransactionHistory?DBTNumber=${inquiryDetail.dbT_NO}`).then((GetTransactionHistory) => {
                setTransaction(GetTransactionHistory.data);
                setCheckData(GetTransactionHistory.data.length > 0 ? true : false);
            });
        })()
    }, [])

    useEffect(() => {
        (async () => {
            setshowProgressBar(true);
            await useFetch<IGetRolledPlacement[]>(`RolledPlacement?DBTNumber=${inquiryDetail.dbT_NO}`).then((data) => {
                setisClosed(data?.data[0]);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }, []);

    useEffect(() => {
        (async () => {
            setshowProgressBar(true);
            await useFetch<IAccStatus>(`Account/GetAccStatus?dbt_no=${inquiryDetail.dbT_NO}`).then((r) => {
                setAccDetails(r?.data);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }, [])

    const GetAccStatus = () => {
        (async () => {
            await useFetch<IAccStatus>(`Account/GetAccStatus?dbt_no=${inquiryDetail.dbT_NO}`).then((r) => {
                setAccDetails(r?.data);
            });
        })()
    }

    useEffect(() => {
        if (visibleLetter.length <= 0) {
            getClosedLetterDetails();
        }
    }, []);

    async function getClosedLetterDetails() {
        await useFetch<IClosedLetterDetails[]>(`AccountLetterDetailsGet?DBTNumber=${inquiryDetail.dbT_NO.toString()}`).then((GetClosedLetterDetails) => {
            setVisibleLetter(GetClosedLetterDetails.data);
        });
    }

    const DisableButton = () => {
        (async () => {
            await useFetch<IGetRolledPlacement[]>(`RolledPlacement?DBTNumber=${inquiryDetail.dbT_NO}`).then((data) => {
                setisClosed(data?.data[0]);
            });
        })()
    }

    useEffect(() => {
        (async () => {
            let request = { "debtorId": inquiryDetail.dbT_NO };
            await usePost<{ letterDetails: ILetterDetails[] }>("GetLetterDetails", request).then((GetLetterDetails) => {
                setGetLetterDetails(GetLetterDetails.data["letterDetails"]);
            });
        })()
    }, [])


    const hidenotificationForAllUsers = () => {
        (async () => {
            let request = {
                "usernotificationid": Notify?.notificationid,
                "userid": UserName,
                "debtorid": inquiryDetail.dbT_NO,
                "typeId": 5,
                "userRole": 1
            }
            await usePost<IHideForAllUsers>("HideForAllUsers", request);
        })()
    }

    return (
        <Box>
            <AppBar position="static" color="transparent" className={classes.appbarStyle} >
                <Tabs id="NC_Tab" style={{ marginTop: "4px", marginLeft: "10px" }}
                    value={value} onChange={handleChange}
                    classes={{ indicator: classes.indicator }} aria-label="simple tabs example">
                    <Tab id="I_AccountDetails" className={`${classes.tabs} ${classes.iconLabelWrapper}`} label="Account Details" />
                    <Tab id="I_AccountHistory" className={classes.tabs} label="Account History" />
                    <Tab id="I_TransactionAuditHistory" className={classes.tabs} label="Transaction Audit History" />
                    <Grid item xs={7} className={classes.gridStyle}>
                        {isClosed?.rejectedRoll === true ?
                            <IconButton id="Active_Status" className="close-account-info" >
                                <LightTooltipLeft title={rollVerbiage}>
                                    <InfoIcon className={classes.image1} />
                                </LightTooltipLeft>
                            </IconButton>
                            : null
                        }
                    </Grid>
                </Tabs>
                <div className={classes.inquiryDetails}>
                    <TabPanel value={value} index={0}>
                        {ProgressBar ? <RollSpinner /> :
                            <React.Fragment>
                                <AccountDetails Notify={Notify} debtors={inquiryDetail} letterDetails={visibleLetter} isEnable={isClosed} onEroll={DisableButton} onHideAll={hidenotificationForAllUsers} BlockAwaitUsers={isBlocked} GetDebtorLetterDetails={GetLetterDetails} GetAccStatus={AccDetails} OnGetDetails={GetAccStatus} />
                            </React.Fragment>
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        < AccountHistory debtors={inquiryDetail} histories={history} mapped={mapped} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <TransactionAuditHistory debtors={inquiryDetail} transactions={Transaction} mapped={checkData} />
                    </TabPanel>
                </div>
            </AppBar >
        </Box >
    )
}

export default InquiryDetail