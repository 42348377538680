export const msalConfig = {

  auth: {
    authority: 'https://login.microsoftonline.com/533a72b0-15d7-4efd-9921-2c7d9041fb4a',
    clientId: '519906f2-fdf0-4f43-8410-4e8e8d83a8fc',
    redirectUri: window.document.location.origin,
    postLogoutRedirectUri: window.document.location.origin,
    scopes: ['api://3b5604f7-1a91-4d87-8a51-71c34788710d/access_as_user'],
  },
  system: {
    iframeHashTimeout: 10000,
  },
}

export const loginRequest = {
  scopes: msalConfig.auth.scopes,
}