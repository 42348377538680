import { Button, ButtonGroup, Paper } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 200,
    marginLeft: "35%"
  },
  root: {
    display: "flex",
    flexDirection: "column",
    borderSpacing: "1cm",
    width: 250,
    color: "primary",
  },
  groupedOutlined: {
    "&:not(:first-child)": {
      marginLeft: -1
    },
    "&:not(:last-child)": {
      borderRightColor: "rgba(0, 0, 0, 0.23)"
    }
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function NativeSelects() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl" component={Paper} style={{ marginTop: "1.5cm", marginLeft: "1cm", marginRight: "1cm", borderRadius: 30 }}>
        <div className="searchbar">
          <h1 style={{ marginRight: "80%" }}>
            SET NOTIFICATION
          </h1>
        </div>
        <div className={classes.container}>
          <ButtonGroup
            id="SendNotification_BtnGroup"
            fullWidth
            aria-label="full width outlined button group"
            className={classes.root}
            classes={{ groupedOutlined: classes.groupedOutlined }} >
            <Button id="SN_NewFileUpload_btn" style={{ borderRadius: 20 }} variant="contained" color="primary" component={Link} to="/newfileupload">New File Upload</Button>
            <Button id="SN_Settlement_btn" style={{ marginTop: "8px", borderRadius: 20 }} variant="contained" color="primary" component={Link} to="/settlementauthorization">Settlement Authorization</Button>
            <Button id="SN_Message_btn" style={{ marginTop: "8px", borderRadius: 20 }} variant="contained" color="primary" component={Link} to="/message">Message</Button>
          </ButtonGroup>
        </div>
      </Container>
    </React.Fragment>
  );
}