import { Box, Button, Dialog, DialogContent, Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Typography } from '@mui/material';
import moment from "moment";
import { useEffect, useState } from 'react';
import { IClientCodeContract, IClientCodeDetails, IGetKeyValues } from "../../../models/GLobalSettings/IGetKeyValueDetails";
import { useGlobalState } from "../../../store/GlobalStore";
import { useFetch } from '../../../utils/apiHelper';
import StyledTableRow from "../../GlobalStyles/StyledTableRow";
import CreateKeyManagement from './CreateKeyManagement';
import EditKeyDetails from './EditKeyDetails';
import { useStyles } from './ManagementCSS';
import ViewAPIKeyHistory from './ViewAPIKeyHistory';
import DisableAPIKey from "./DisableAPIKey";
import { DialogTitleHeader } from "../../GlobalStyles/DialogStyle";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Transition } from "../../GlobalStyles/DialogBoxTransition";
import { maskGuid } from "../../../utils/dataMasking";

function EnhancedTableHead(props) {
  const { classes } = props;

  const headCells = [
    { id: "label_Name", numeric: false, disablePadding: false, label: "KEY NAME", sortable: true },
    { id: "control_Name", numeric: false, disablePadding: false, label: "CLIENT NAME", sortable: true },
    { id: "date_field", numeric: false, disablePadding: false, label: "MODIFIED DATE", sortable: true },
    { id: "control_Values", numeric: false, disablePadding: false, label: "START DATE", sortable: true },
    { id: "control_Values", numeric: false, disablePadding: false, label: "EXPIRY DATE", sortable: true },
    { id: "control_Values", numeric: false, disablePadding: false, label: "HISTORY", sortable: true },
    { id: "control_Values", numeric: false, disablePadding: false, label: "ACTIONS", sortable: true },
    { id: "control_Values", numeric: false, disablePadding: false, label: "", sortable: false },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id} id="CPO_TableHeader"
            className={classes.TableCellStyle}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTable(props) {
  const { classes } = props;

  const headCells = [
    { id: "clientCode", numeric: false, disablePadding: false, label: "CLIENT CODE", sortable: true },
    { id: "clientName", numeric: false, disablePadding: false, label: "CLIENT NAME", sortable: true },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell key={headCell.id} id="CPO_TableHeader" className={classes.TableCellStyle} >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const ViewKeyManagement = () => {
  const classes = useStyles();
  const { state } = useGlobalState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [getKeyValues, setGetKeyValues] = useState<IGetKeyValues[]>([]);
  const [ClientCodeList, setClientCodeList] = useState<IClientCodeDetails[]>([]);
  const [existingClientCodeList, setExistingClientCodeList] = useState<IClientCodeContract[]>([]);

  useEffect(() => {
    getAllAPIKeyValues();
    handleGetExistingClientCodeList();
  }, [])

  const handleGetExistingClientCodeList = () => {
    (async () => {
      await useFetch<IClientCodeContract[]>("GlobalSettings/GetExistingClientCodeList").then((result) => {
        setExistingClientCodeList(result.data);
      });
    })();
  }

  const getAllAPIKeyValues = () => {
    handleGetExistingClientCodeList();
    setProgressBar(true);
    useFetch<IGetKeyValues[]>('GlobalSettings/GetKeyVaultDetails').then((result) => {
      setGetKeyValues(result?.data);
    }).finally(() => {
      setProgressBar(false);
    })
  }

  const handleCloseDialogBox = () => {
    setDialogOpen(false);
  }

  const handleClientDialogOpen = (id: number) => {
    setDialogOpen(true);
    (async () => {
      await useFetch<IClientCodeDetails[]>("GlobalSettings/GetClientCodeList?ApiKeyId=" + id).then((r) => {
        setClientCodeList(r.data);
      }).finally(() => {
      });
    })()
  }

  return (
    <div className={classes.divRoot}>
      <Grid container spacing={0} >
        <Grid item xs={6} sm={8}>
          <Typography variant="h6" style={{ textAlign: "left" }} className={`${classes.UserListStyle} ${"headertitle"}`}>
            <b>API KEY MANAGEMENT</b>
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          <CreateKeyManagement clientList={existingClientCodeList} onSave={getAllAPIKeyValues} />
        </Grid>

        <TableContainer component={Paper} className={`${classes.tablebodyActivity} ${"scrollbox"} ${"on-scrollbar"}`}>
          <Table aria-label="customized table" size="small" stickyHeader>
            <EnhancedTableHead classes={classes} />
            <TableBody>
              {getKeyValues?.map((row) => (
                <StyledTableRow key={row?.clientKey}>
                  <TableCell align="left" className={!row?.isDisable ? classes.disableText : classes.TableCellStyle1}>
                    {maskGuid(row?.clientKey)}
                  </TableCell>
                  <TableCell align="left" className={!row?.isDisable ? classes.disableText : classes.TableCellStyle1}>
                    {row?.clientName} &nbsp;&nbsp; {<Link style={{ cursor: 'pointer' }} onClick={(e) => { handleClientDialogOpen(row?.apiKeyId) }}> View Client Code </Link>}
                  </TableCell>
                  <TableCell align="left" className={!row?.isDisable ? classes.disableText : classes.TableCellStyle1}>
                    {moment(row?.modifiedDate)?.format(state?.GlobalUtils?.settingValue)}
                  </TableCell>
                  <TableCell align="left" className={!row?.isDisable ? classes.disableText : classes.TableCellStyle1}>
                    {moment(row?.startDate)?.format(state?.GlobalUtils?.settingValue)}
                  </TableCell>
                  <TableCell align="left" className={!row?.isDisable ? classes.disableText : classes.TableCellStyle1}>
                    {moment(row?.expiryDate)?.format(state?.GlobalUtils?.settingValue)}
                  </TableCell>
                  <TableCell align="left" className={classes.TableCellStyle1}>
                    <ViewAPIKeyHistory apiKeyId={row?.apiKeyId} />
                  </TableCell>
                  <TableCell align="left" className={classes.TableCellStyle1}>
                    <EditKeyDetails row={row} onSave={getAllAPIKeyValues} clientList={existingClientCodeList} />
                  </TableCell>
                  <TableCell align="left" className={classes.TableCellStyle1}>
                    <DisableAPIKey row={row} onFetchDetails={getAllAPIKeyValues} />
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {!getKeyValues?.length && !progressBar ? <Typography variant="h6" gutterBottom className={classes.noRecordStyle}>
            No records to display...
          </Typography> : null}
        </TableContainer>
      </Grid>

      <Dialog TransitionComponent={Transition} open={dialogOpen}
        className={classes.dialogboxOverride}
        PaperProps={{ style: { borderRadius: 15 } }}
        maxWidth={'sm'}
        aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseDialogBox}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            CLIENT CODE LIST
          </Typography>
        </DialogTitleHeader>
        <DialogContent >
          <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
            <Table aria-label="customized table" size="small" stickyHeader>
              <EnhancedTable classes={classes} />
              <TableBody>
                {ClientCodeList?.map((row) => (
                  <StyledTableRow >
                    <TableCell align="left" className={classes.TableCellStyle1}>
                      {row?.clientCode}
                    </TableCell>
                    <TableCell align="left" className={classes.TableCellStyle1}>
                      {row?.clientName}
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {!ClientCodeList?.length ? <Typography variant="h6" gutterBottom className={classes.noRecordStyle}>
              No records to display...
            </Typography> : null}
          </TableContainer>

        </DialogContent>
        <Box display="flex" alignItems="right" justifyContent="right" className={classes.dialogaction}>
          <Button id="IM_ok_btn" size="small" startIcon={<CheckCircleIcon />} variant="contained" className={classes.yesButton} onClick={() => { setDialogOpen(false); }}>
            Cancel
          </Button>
        </Box>
      </Dialog>
    </div>
  )
}

export default ViewKeyManagement
