import { Backdrop, DialogActions, Slide, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import { IUpdateFaq } from '../../../models/GA_Faq/IUpdateFaq';
import { useDelete } from '../../../utils/apiHelper';
import { DialogTitleDelete } from '../../GlobalStyles/DialogStyle';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      display: "flex",
    },
    DialogBottom: {
      marginRight: '20px'
    },
    yesButton: {
      background: "green",
      color: 'white',
      borderRadius: 20,
      fontSize: 12,
      '&:hover': {
        background: "green",
      }
    },
    noButton: {
      background: "red",
      borderRadius: 20,
      display: 'flex',
      fontSize: 12,
      '&:hover': {
        background: "red",
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    deletetitle: {
      fontWeight: 'bold',
      marginTop: '5px',
      color: 'white',
      fontSize: 18
    },
    deletetitle1: {
      color: "black",
      fontSize: 17,
      fontWeight: 'bold'
    },
    deleteicon: {
      borderRadius: 30
    }
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteFAQ: React.FC<{ faqRow: IUpdateFaq, onDelete: () => void }> = (props) => {
  const { faqRow } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const classes = useRowStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [ProgressBar, setShowProgressBar] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setOpen(false);
    setShowProgressBar(true);
    useDelete("Faq/DeleteFaq?Id=" + faqRow.id).then(() => {
      props.onDelete();
    }).finally(() => {
      setShowProgressBar(false);
    });
  };

  return (
    <React.Fragment>
      <div>
        <Button size="small" id="GAFAQ_Delete_btn" aria-label="delete" onClick={handleClickOpen} className={classes.deleteicon}>
          <DeleteForeverIcon style={{ color: 'red', fontSize: 28 }} />
        </Button>
        <div>
          <Backdrop className={classes.backdrop} open={ProgressBar}>
            <PuffLoader size={80} color={"white"} speedMultiplier={1} />
          </Backdrop>
          <Dialog
            fullScreen={fullScreen} open={open} onClose={handleClose}
            aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 15, width: 580 } }} TransitionComponent={Transition}>
            <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
              <Typography variant="h6" gutterBottom className={classes.deletetitle}>
                DELETE FAQ
              </Typography>
            </DialogTitleDelete>
            <DialogContent>
              <Typography variant="h6" className={classes.deletetitle1} gutterBottom>
                Are you sure, you want to delete this faq ?
              </Typography>
            </DialogContent>
            <DialogActions className={classes.DialogBottom}>
              <Button id="GAFAQ_ok_btn" size="small" variant="contained" onClick={handleOk} className={classes.yesButton}>
                Yes
              </Button>
              <Button id="GAFAQ_Cancel_btn" size="small" variant="contained" autoFocus onClick={handleClose} color="primary" className={classes.noButton}>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div >
      </div>
    </React.Fragment>
  );
}
export default DeleteFAQ