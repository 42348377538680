import { useMsal } from "@azure/msal-react";
import React from "react";
import Button from '@mui/material/Button';
import { msalConfig } from '../../auth/authConfig';

const loginbutton = {
    backgroundColor: '#283593',
    color: 'white',
    borderRadius: '30px',
    fontSize: '15px',
    fontWeight: 'bold',
    marginTop: '10px',
}

export const SignOutButton = () => {
    const { instance } = useMsal();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const signout = () => {
        window.onbeforeunload = null;
        instance.logout({
            postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        })
    }

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                style={loginbutton}
                onClick={(e) => { handleClick(e); signout() }} >
                Sign Out
            </Button>
        </div>
    )
}