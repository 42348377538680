
import {
  Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent,
  DialogContentText, Grid, IconButton, Snackbar, TableContainer, TextField, Typography
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PersonIcon from '@material-ui/icons/Person';
import SendIcon from '@material-ui/icons/Send';
import { Autocomplete } from '@material-ui/lab';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import React, { useEffect, useRef, useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import Footer from '../../../components/Footer/Footer';
import upload from '../../../images/files/uploading.svg';
import { IClientCodeName } from "../../../models/SendNotification/IClientCodeName";
import { IInsertNotification } from '../../../models/SendNotification/IInsertNotification';
import { IStaticText } from "../../../models/SendNotification/IStaticText";
import { IUserForClient } from "../../../models/SendNotification/IUserForClient";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './NotificationCss';

export default function NativeSelects() {
  const classes = useStyles();
  const fileUpload = useRef(null);
  const { dispatch } = useGlobalState();
  const [ClientCodes, setClientCodes] = useState<IClientCodeName[]>([]);
  const [UsersForClient, setUsersForClient] = useState<IUserForClient[]>([]);
  const [SelectedClientCodes, setSelectedClientCodes] = useState<IClientCodeName[]>([]);
  const [SelectedUsers, setSelectedUsers] = useState<IUserForClient[]>([]);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [Description, setDescription] = useState<string>("");
  const [SearchTerm, setSearchTerm] = useState("");
  const [UserSearchTerm, setUserSearchTerm] = useState("");
  const [SelectStaticText, setSelectStaticText] = useState<IStaticText[]>([]);
  const [SelectTextType, setSelectTextType] = useState<any>();
  const [searchText, setSearchText] = useState<string>('');
  const [isValidReason, setIsValidReason] = useState(true);
  const [selectedClient, setSelectedClient] = useState<string>('');
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [buttonLoading, setButtonLoading] = useState(false);
  const [confirmDialogBox, setConfirmDialogBox] = useState(false);

  const handleAlertClose = () => {
    setConfirmDialogBox(false);
    setButtonLoading(false);
  };
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const getUsersForClient = (clientCodes) => {
    (async () => {
      setshowProgressBar(true);
      try {
        let request = {
          "client_code": clientCodes,
          "name": SearchTerm?.trimLeft().trimRight(),
          "isSettlementOption": false
        }
        await usePost<IUserForClient[]>("GetUsersForClient", request).then((GetUsersForClient) => {
          setUsersForClient(GetUsersForClient.data);
        });
        setshowProgressBar(false);
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }

  const handleClientCodeToggle = (client: IClientCodeName) => () => {
    let newSelectedClientCodes = [...SelectedClientCodes];

    if (newSelectedClientCodes.some(c => c.clientCode == client.clientCode)) {
      newSelectedClientCodes = newSelectedClientCodes.filter(c => c.clientCode != client.clientCode);
    } else {
      newSelectedClientCodes.push(client);
    }

    let clientcodes = "";
    for (var checkClientCode in newSelectedClientCodes) {
      clientcodes += newSelectedClientCodes[checkClientCode].clientCode;
      clientcodes += ',';
    }

    setSelectedClientCodes(newSelectedClientCodes);
    getUsersForClient(clientcodes);
    setSelectedClient(clientcodes);
    setshowProgressBar(true);
  }

  const handleUserToggle = (user: IUserForClient) => () => {
    let newSelectedUsers = [...SelectedUsers];
    if (newSelectedUsers.some(u => u.id == user.id)) {
      newSelectedUsers = newSelectedUsers.filter(u => u.id != user.id);
    } else {
      newSelectedUsers.push(user);
    }
    setSelectedUsers(newSelectedUsers);
  }

  const handleAllRight = () => {
    let clientcodes = "";
    for (var checkClientCode in ClientCodes) {
      clientcodes += ClientCodes[checkClientCode].clientCode;
      clientcodes += ',';
    }
    getUsersForClient(clientcodes);
    setSelectedClientCodes(ClientCodes);
    setshowProgressBar(false);
  };

  const handleAllLeftClient = () => {
    let clientcodes = "";
    getUsersForClient(clientcodes);
    setSelectedClientCodes([]);
    setshowProgressBar(false);
  }

  const handleCheckedRight = () => {
    setSelectedUsers(UsersForClient);
    setshowProgressBar(false);
  };

  const handleAllLeft = () => {
    setSelectedUsers([]);
    setshowProgressBar(false);
  };

  useEffect(() => {
    (async () => {
      try {
        await useFetch<IStaticText[]>('GetStaticText').then((GetStaticTextList) => {
          setSelectStaticText(GetStaticTextList.data);
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [])

  const GetClientLists = () => {
    setshowProgressBar(true);
    (async () => {
      await useFetch<IClientCodeName[]>(`GetClientSearchList?searchText=${searchText.trim()}`).then((GetClientSearchList) => {
        setClientCodes(GetClientSearchList.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }

  async function onStaticTextSelect(event, value) {
    await useFetch<IStaticText[]>('GetStaticTextById?id=' + value.id)
    setIsValidReason(false);
    setSelectTextType(value.statictext);
  }

  useEffect(() => {
    (async () => {
      setshowProgressBar(true);
      await useFetch<IClientCodeName[]>('GetClientCodeList').then((GetClientCodeList) => {
        setClientCodes(GetClientCodeList.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }, [])

  const getClientUsersAsync = () => {
    setshowProgressBar(true);
    (async () => {
      dispatch({ type: GlobalStateAction.Busy })
      let request = {
        "client_code": selectedClient,
        "name": SearchTerm?.trimLeft().trimRight(),
        "isSettlementOption": false
      }
      await usePost<IUserForClient[]>("GetUsersForClient", request).then((GetUsersForClient) => {
        setUsersForClient(GetUsersForClient.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }

  const GetSelectedUserID = () => {
    let userIds = "";
    SelectedUsers.forEach((user) => {
      userIds += user.id;
      userIds += ","
    })
    return userIds.substr(0, userIds.length - 1);
  }

  function reset() {
    setDescription("");
    setSelectedFile(null);
  }

  const onSubmit = () => {
    setConfirmDialogBox(false);
    if (SelectedUsers.length > 0) {
      setButtonLoading(true);
      (async () => {
        const formData = new FormData();
        formData.append("TypeId", "3");
        formData.append("ExtraData1", GetSelectedUserID());
        formData.append("ExtraData2", SelectTextType);
        formData.append("ExtraData3", Description);
        formData.append("ExtraData4", "");
        formData.append("File", selectedFile);
        await usePost<IInsertNotification>("InsertAdminNotification", formData).finally(() => {
          setOpen(true);
          setButtonLoading(false);
          reset();
        });
      })()
    }
  }

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.target.files && setSelectedFile(event.target.files[0]);
  }

  const handleChange = (event) => {
    setDescription(event.target.value);
  };

  const clientCodeList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" color="secondary" className={classes.clientheader} >
        <b>CLIENTS</b>
      </Typography>
      <div className={`${classes.paper} ${"scrollbox"} ${"on-scrollbar"}`}>
        <List dense className={classes.selectboxpadding} component="div" role="list">
          {ClientCodes.slice(0, 150).map((client, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem" button onClick={handleClientCodeToggle(client)}>
                <ListItemIcon>
                  <Checkbox
                    size="small" style={{ color: "#1bc42c" }}
                    className={classes.checkBoxStyle}
                    id="SA_ClientCode_Checkbox"
                    checked={SelectedClientCodes.some(c => c.clientCode == client.clientCode)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText className={classes.clientlistSpacing} id={labelId}>
                  <Typography variant="body2" className={classes.textUser}>
                    <b>{client.clientCode}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          {searchText && !ClientCodes.length ?
            <Typography variant="h6" className={classes.ErrorMessageStyle}>
              No Clients to display..
            </Typography> : null}
          <ListItem />
        </List>
      </div>
    </React.Fragment>
  );

  const usersList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" className={classes.clientheader} color="secondary">
        <b>USERS</b>
      </Typography>
      <div className={`${classes.paper} ${"scrollbox"} ${"on-scrollbar"}`}>
        <List dense className={classes.selectboxpadding} component="div" role="list">
          {UsersForClient.filter(({ firstName, lastName }) => (firstName + lastName).toLowerCase().includes(UserSearchTerm.toLowerCase())).map((user, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem" button onClick={handleUserToggle(user)}>
                <ListItemIcon>
                  <Checkbox
                    className={classes.checkBoxStyle}
                    size="small" style={{ color: "#1bc42c" }}
                    id="NF_Users_Checkbox"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    checked={SelectedUsers.some(u => u.id == user.id)}
                  />
                </ListItemIcon>
                <ListItemText className={classes.clientlistSpacing} id={labelId}>
                  <Typography variant="body2" className={classes.textUser}>
                    <b>{user.firstName} {user.lastName}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          {SearchTerm && !UsersForClient.length ?
            <Typography variant="h6" className={classes.ErrorMessageStyle}>
              No Users to display..
            </Typography> : null}
          <ListItem />
        </List>
      </div>
    </React.Fragment>
  );

  const selectedUsersList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" className={classes.clientheader} color="secondary" >
        <b>ADD TO NOTIFICATION</b>
      </Typography>
      <div className={`${classes.paper} ${"scrollbox"} ${"on-scrollbar"}`}>
        <List dense className={classes.selectboxpadding} component="div" role="list">
          {SelectedUsers.map(({ firstName, lastName }, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem">
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText className={classes.clientlistSpacing} id={labelId}>
                  <Typography variant="body2" className={classes.textUser}>
                    <b>{firstName}{lastName}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className={classes.div}>
        <TableContainer className={`${classes.adminLayout} ${"scrollbox"} ${"on-scrollbar"}`}>
          <Backdrop className={classes.backdrop} open={ProgressBar}>
            <PuffLoader size={80} color={"white"} speedMultiplier={1} />
          </Backdrop>
          <CssBaseline />
          <Typography variant="h5" className={`${"headertitle"} ${classes.titleHeader}`}>
            <b> NEW FILE UPLOAD</b>
          </Typography>
          <Container fixed className={classes.container}>
            <Grid container spacing={0}>
              <Grid item className={classes.gridPadding} xs={4} >
                <TextField
                  id="NF_Description"
                  placeholder="Enter the Description..."
                  size="small"
                  multiline
                  rows={5}
                  value={Description}
                  onChange={handleChange}
                  inputProps={{ maxlength: 256 }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  className={classes.description1}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4} >
                <Box alignItems="center" display="flex" justifyContent="center" >
                  <Box className={classes.root2}>
                    <IconButton aria-label="delete" disabled={true} style={{ color: "black" }}>
                      <img src={upload} alt="Icon for Download" width="28px" height="28px" />
                    </IconButton>
                    <Typography className={classes.textStyle} variant="button" display="block" gutterBottom >
                      Select a file
                    </Typography>
                    <ul className={classes.ulstyle}>
                      <form encType="multipart/form-data" onSubmit={(e) => e.preventDefault()}>
                        <Button size="small" className={classes.fileUploadButton} component="label"
                          variant="contained" startIcon={<CloudUploadIcon />}>
                          Choose File
                          <input
                            id="NF_SelectFile"
                            onChange={changeHandler}
                            name="file"
                            ref={fileUpload}
                            hidden
                            multiple type="file"
                          />
                        </Button>
                      </form>
                    </ul>
                    <Typography variant="subtitle1" gutterBottom >
                      {selectedFile ? selectedFile.name : ""}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4} >
                <Autocomplete
                  id="NF_SelectReason"
                  size="small"
                  autoComplete autoHighlight
                  options={SelectStaticText}
                  classes={{
                    paper: classes.paper1,
                    option: classes.smallFont,
                    input: classes.smallFont
                  }}
                  onChange={onStaticTextSelect}
                  getOptionLabel={(option) => option.statictext}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Reason" margin="normal" variant="outlined"
                      className={classes.selectReason}
                      inputProps={{
                        ...params.inputProps,
                        classes: {
                          root: classes.outlinedInput,
                          focused: classes.focused,
                          notchedOutline: classes.notchedOutline,
                          input: classes.selectFieldPadding,
                        }
                      }}
                    />
                  )}
                />
              </Grid>

            </Grid>
            <Grid container spacing={0} style={{ marginTop: '0.3cm' }}>
              <Grid item xs={3}>
                <input value={searchText}
                  type='text' placeholder="Search Clients"
                  className={classes.searchIcon1}
                  onChange={e => setSearchText(e.target.value)}
                  onKeyPress={event => { if (event.key === 'Enter') { GetClientLists(); } }}
                  maxLength={255}
                />
              </Grid>
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={3}>
                <input value={SearchTerm}
                  type='text' placeholder="Search Users"
                  className={classes.searchIcon}
                  onChange={e => setSearchTerm(e.target.value)}
                  onKeyPress={event => { if (event.key === 'Enter') { getClientUsersAsync(); } }}
                  maxLength={255}
                />
              </Grid>
              <Grid item xs={1}>
              </Grid>
              <Grid item xs={3}>
                <Button id="NF_SendNotification" className={classes.sendNotification} endIcon={<SendIcon />}
                  disabled={!Description || !SelectedUsers || isValidReason || !SelectedUsers.length} variant="contained" color="primary"
                  onClick={(_) => { SelectedUsers.length > 0 ? setConfirmDialogBox(true) : onSubmit() }} >
                  Send Notification
                  {buttonLoading === true ? <CircularProgress size="small" className={classes.circlarBarStyle} /> : null}
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={0} justify='space-evenly'>
              <Grid item xs={5} sm={6} md={2} className={classes.gridstyle} >
                {clientCodeList()}
              </Grid>
              <Grid item xs={5} sm={4} md={2}>
                <Grid>
                  <Button
                    id="NF_SelectAll_btn"
                    className={classes.selectallbutton}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleAllRight}
                    aria-label="move all right"
                    startIcon={<GroupAddIcon fontSize="large" style={{ fontSize: 25 }} />} >
                    Select All
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    id="NF_RemoveAllClient_btn"
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.removeallbutton}
                    onClick={handleAllLeftClient}
                    disabled={SelectedClientCodes.length === 0}
                    aria-label="move all left" startIcon={<PersonRemoveAlt1Icon fontSize="large" style={{ fontSize: 25 }} />} >
                    Remove All
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={5} sm={6} md={2} className={classes.gridstyle} >
                {usersList()}
              </Grid>
              <Grid item xs={5} sm={5} md={2}>
                <Grid>
                  <Button
                    id="NF_AddAll_btn"
                    variant="contained"
                    color="primary"
                    className={classes.selectallbutton}
                    size="small"
                    onClick={handleCheckedRight}
                    aria-label="move selected right" startIcon={<GroupAddIcon fontSize="large" style={{ fontSize: 25 }} />}>
                    Add All
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    id="NF_RemoveAll_btn"
                    variant="contained"
                    color="primary"
                    className={classes.removeallbutton}
                    size="small"
                    onClick={handleAllLeft}
                    aria-label="move all left" startIcon={<PersonRemoveAlt1Icon fontSize="large" style={{ fontSize: 25 }} />}>
                    Remove All
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={5} sm={5} md={2} className={classes.gridstyle} >
                {selectedUsersList()}
              </Grid>
            </Grid>
          </Container>
        </TableContainer>
        <Footer />
      </div >
      <Snackbar open={open} className="snackBarStyle" anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" className="alertStyle">
          File Uploaded Successfully !
        </Alert>
      </Snackbar>
      <Dialog TransitionComponent={Transition}
        fullScreen={fullScreen} open={confirmDialogBox} onClose={handleAlertClose} maxWidth={'sm'}
        aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
        <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
          <Typography variant="h6" className={classes.titleheader}>
            CONFIRMATION MESSAGE
          </Typography>
        </DialogTitleDelete>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6" className={classes.MessageStyle}>
              Are you sure you want to send this notification to {SelectedUsers.length} users?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button id="DU_ok_btn" size="small" variant="contained" onClick={(e) => { onSubmit(); }} className={classes.yesButton}>
            Yes
          </Button>
          <Button id="DU_ok_btn" size="small" variant="contained" onClick={(e) => { setConfirmDialogBox(false); }} className={classes.noButton}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment >
  );
}