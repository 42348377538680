import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
                marginBottom: "0%",
                width: '100%',
                marginTop: '15px'
            },
        },
        ButtonStyle: {
            borderRadius: 20,
            fontSize: 11,
            marginTop: '3px',
            marginBottom: '3px'
        },
        titleHeaderStyle: {
            fontWeight: 'bold',
            color: 'white',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
        },
        yesButton: {
            background: "green",
            color: 'white',
            borderRadius: 20,
            fontSize: 12,
            '&:hover': {
                background: "green",
            },
        },
        noButton: {
            background: "red",
            borderRadius: 20,
            marginRight: '20px',
            color: 'white',
            fontSize: 12,
            '&:hover': {
                background: "red",
            },
        },
        firstTextfield: {
            fontSize: 13,
            borderRadius: '4px 4px 8px 8px',
            width: '48%',
            '& .MuiOutlinedInput-root': {
                borderRadius: '4px 4px 8px 8px',
            },
            "& input::placeholder": {
                fontSize: "12px"
            }
        },
        lastTextfield: {
            borderRadius: '4px 4px 8px 8px',
            width: '49%',
            '& .MuiOutlinedInput-root': {
                borderRadius: '4px 4px 8px 8px',
            }
        },
        firstnameInputSpacing: {
            marginRight: '5px',
        },
        blueBorderBottom: {
            fontSize: 14,
            borderRadius: '4px 4px 8px 8px',
            '& .MuiOutlinedInput-input': {
                borderBottom: '3px solid blue',
                borderRadius: '4px 4px 8px 8px',

            },
            '& .MuiInputBase-input': {
                padding: '11px',
            }
        },
        redBorderBottom: {
            fontSize: 14,
            borderRadius: '4px 4px 8px 8px',
            '& .MuiOutlinedInput-input': {
                borderBottom: '3px solid red',
                borderRadius: '4px 4px 8px 8px',
            },
            '& .MuiInputBase-input': {
                padding: '11px',
            }
        },
        greenBorderBottom: {
            fontSize: 14,
            borderRadius: '4px 4px 8px 8px',
            borderColor: 'green',
            '& .MuiOutlinedInput-input': {
                borderBottom: '4px solid green',
                borderRadius: '4px 4px 8px 8px',
                '& >fieldset': {
                    borderColor: 'green',
                },
            },
            '& .MuiInputBase-input': {
                padding: '11px',
                borderColor: 'green',
            },
        },
        textFieldBorder: {
            transition: "all 0.5s ease",
            borderRadius: '5px',
            fontSize: '12px',
            width: '98%',
            marginTop: '10px'
        },
        textFieldBorderRed: {
            transition: "all 0.5s ease",
            borderRadius: '5px',
            fontSize: '12px',
            width: '98%',
            marginTop: '10px',
        },
        textFieldBorderGreen: {
            transition: "all 0.5s ease",
            borderRadius: '5px',
            fontSize: '12px',
            width: '98%',
            marginTop: '10px',
            '& .MuiInput-underline:after': {
                borderColor: 'green',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'blue',
            },
        },
        textFieldBorderEdit: {
            transition: "all 0.5s ease",
            borderRadius: '5px',
            fontSize: '12px',
            width: '98%',
            marginTop: '10px'
        },
        notchedOutline1: {
            color: 'white',
            // border: '2px solid green'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        titleHeader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white',
            fontSize: 18,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
        },
        userStyle: {
            fontSize: '15px',
            fontWeight: 'bold',
            '& .MuiIconButton-root': {
                padding: '0px 8px'
            }
        },
        userStyle1: {
            fontSize: '15px',
            fontWeight: 'bold',
            padding: '0px 10px',
            '& .MuiIconButton-root': {
                padding: '0px 8px'
            }
        },
        GreenColor: {
            fontSize: 12,
            marginLeft: '5px',
            color: 'green',
            minWidth: 350
        },
        clientCodeStyle: {
            fontSize: 12,
            marginLeft: '5px',
            minWidth: 350
        },
        clientCodeBox: {
            width: '98%',
            borderRadius: '4px 4px 8px 8px',
            '& .MuiOutlinedInput-root': {
                borderBottom: '3px solid blue',
                borderRadius: '4px 4px 8px 8px'
            }
        },
        dropdownStyle: {
            width: '100%',
            padding: '0px 10px',
            fontSize: 12,
        },
        checkBoxStyle: {
            marginLeft: '5px'
        },
        permission: {
            margin: 0,
            fontSize: '12px',
            width: '320px'
        },
        editButton: {
            fontSize: 11,
            borderRadius: 20,
            backgroundColor: 'green',
            '&:hover': {
                background: "green",
            }
        },
        button: {
            fontSize: 11,
            borderRadius: 20,
            background: "#215176",
            '&:hover': {
                background: "#215176",
            }
        },
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white',
            fontSize: 18
        },
        userNameEmail: {
            minWidth: '98%',
            fontSize: 13,
            marginTop: '15px'
        },
        cloneEmail: {
            minWidth: '99%',
            fontSize: 13,
            marginTop: '15px'
        },
        cloneYesButton: {
            bottom: '0.2cm',
            marginTop: '0.3cm',
            fontWeight: 'bold',
            padding: '4px 10px',
        },
        cloneNoButton: {
            marginTop: '0.3cm',
            bottom: '0.2cm',
            fontWeight: 'bold',
            padding: '4px 10px',
        },
        textBoxStyle: {
            marginTop: '13px',
            fontSize: 13,
            width: '100%',
            '& .MuiChip-labelSmall': {
                paddingLeft: '5px',
                paddingLight: '5px',
                fontSize: '13px'
            },
            '& .MuiAutocomplete-noOptions': {

            }
        },
        LabelBoxStyle: {
            margin: '6px 2px',
            marginTop: '12px'
        },
        IconSizeStyle: {
            height: '20px',
            width: '20px'
        },
        paper2: {
            border: '2px solid black',
            height: 'auto',
            margin: 0
        },
        scrollMargin: {
            '& div:first-child': {
                marginBottom: '5px',
            },
            '& div:nth-child(3) div': {
                backgroundColor: '#2377e4 !important',
            },
            '& div:nth-child(3)': {
                top: '10px !important',
                bottom: '5px !important'
            },
        },
        valueContainer: {
            "& > :not(:last-child)": {
                marginRight: '5px'
            },
            "& > *": {
                // marginTop: '5px',
                marginBottom: '5px'
            }
        },
        boxBorder: {
            padding: '2px 8px',
            borderRadius: '5px',
            width: '98%',
            marginTop: '12px',
            border: '2px solid #3bb78f',
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
        },
        textBoxStyle2: {
            fontSize: 10,
            // padding: '2px',
            '& .MuiChip-labelSmall': {
                fontSize: 10
            }
        },
        ClientcheckBoxStyle: {
            padding: '0px'
        },
        CheckBoxBorder: {
            borderTop: '1px solid gray'
        },
        CheckBoxPhaseStyle: {
            fontSize: 14,
            padding: '0px',
            marginLeft: '4px'
        },
        CheckBoxPhaseStyle1: {
            fontSize: 14,
            padding: '0px',
            marginLeft: '4px'
        },
        CheckBoxPhase: {
            color: 'green',
        },
        formLabel: {
            fontWeight: 'bold',
            color: 'green',
            fontSize: 14,
        },
        dialogbox: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            height: '100%'
        },
        dialogePaper: {
            minWidth: "1050px"
        },
        dialogePaperCreateUser: {
            minWidth: "1080px",
            maxHeight: 'calc(100% - 19px)'
        },
        dialogePaperActivity: {
            minWidth: "1500px",
        },
        inputFontSize: {
            fontSize: '15px',
        },
        inputFontSize1: {
            fontSize: '14px',
            whiteSpace: 'nowrap',
            width: '280px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'relative',
            display: 'inline-block',
            marginTop: '7px',
            marginLeft: '0px'
        },
        showFontSize: {
            fontSize: '15px',
            marginLeft: '2px'
        },
        titleConfirm: {
            color: "black",
            fontSize: 17
        },
        yesButton1: {
            background: "green",
            color: 'white',
            fontSize: 12,
            borderRadius: 20,
            '&:hover': {
                background: "green",
            }
        },
        noButton1: {
            background: "red",
            display: 'flex',
            color: 'white',
            fontSize: 12,
            borderRadius: 20,
            '&:hover': {
                background: "red",
            }
        },
        deleteIcon: {
            color: 'red',
            fontSize: '28px !important',
        },
        userboxviewStyle: {
            display: 'block',
            borderRadius: '10px',
            borderColor: 'black',
            padding: '8px',
            '&:hover': {
                backgroundColor: 'white',
                transform: 'scale(1)',
                boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.4)',
                borderRadius: '5px',
                '& .MuiTypography-root:first-child': {
                    color: 'green',
                }
            },
        },
        checkboxStyle: {
            width: '200px',
            fontSize: 12
        },
        checkboxStyle1: {
            width: '180px',
            fontSize: 12,
            marginLeft: '2px'
        },
        showallCheckbox: {
            // // width: '220px',
            // fontSize: 12,
            // marginLeft: '2px',
            // // marginTop: '4px',
            // borderRadius: '3px',
            // background: 'white',
            // borderTop: '3.5px solid #61affe',
            // boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
            padding: '0px 10px 10px 10px'
        },
        showCheckbox: {
            padding: '4px 5.5px',
            marginLeft: '3px'
        },
        TableCellStyle: {
            background: "#007FFF",
            color: "white",
            fontSize: 13,
            padding: '2px 0px 2px 15px',
            TextAlign: 'left'
        },
        TableCellStyle1: {
            background: "#007FFF",
            color: "white",
            fontSize: 13,
            padding: '2px 0px 2px 15px',
            TextAlign: 'left'
        },
        TableCellStyleActivity: {
            background: "#007FFF",
            color: "white",
            fontSize: 13,
            padding: '3px 15px',
            TextAlign: 'left'
        },
        tablebodypadding: {
            padding: '0px 0px 0px 15px',
        },
        tablebodypadding1: {
            color: "black",
            fontSize: 13,
            padding: '3px 3px 3px 10px',
        },
        activitybtn: {
            fontSize: 12,
            borderRadius: 20
        },
        activitybtn1: {
            color: '#2b6def',
            width: '200px',
            borderRadius: '20px',
            backgroundColor: '#e6ebff',
            marginTop: '5px',
            '&:hover': {
                width: '200px',
                borderRadius: '20px',
                fontWeight: 'bold',
                backgroundColor: '#3bb78f',
                boxShadow: '0px 10px 10px rgba(46, 229, 157, 0.4)',
                color: '#fff',
            }
        },
        clientCodePhase: {
            height: ' 22px',
            minWidth: '35px',
            lineHeight: 0,
            borderRadius: '6px',
            cursor: 'default ',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            display: 'inline - flex',
            justifyContent: 'center',
            padding: '5px 8px',
            color: 'rgb(34, 154, 22)',
            fontSize: '0.75rem',
            fontFamily: "Public Sans",
            backgroundColor: 'rgba(84, 214, 44, 0.16)',
            textTransform: 'capitalize'
        },
        gridflex: {
            borderRadius: 8,
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'
        },
        fullviewIcon: {
            padding: '4px',
            marginLeft: '30px'
        },
        fullviewIcon1: {
            padding: '4px',
            marginLeft: '5px'
        },
        sidebar: {
            minWidth: "200px",
            // backgroundColor: '#a2cffe',
            transition: "all 0.2s ease",
            display: 'block',
            whiteSpace: 'nowrap',
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
            // boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px',
            borderRight: '1px solid gray',
            padding: '10px 10px',
        },
        ManageTab: {
            transition: "all 0.2s ease",
            boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px',
            padding: '10px 15px',
        },
        ManageTabSelectedRow: {
            backgroundColor: 'rgba(97,175,254,.2)',
            borderColor: '#61affe',
            border: '2px solid #61affe',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px',
            transition: "all 0.2s ease",
            padding: '10px 15px',
        },
        sidebarItem: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: '5px',
            transition: '0.2s',
            cursor: 'pointer',
            padding: '10px 10px',
            marginTop: '4px',
            height: 'auto',
            '&:hover': {
                backgroundColor: '#c8facd',
                color: '#00ab55',
            }
        },
        support: {
            textAlign: 'center',
            padding: '10px 10px',
            position: 'absolute',
            left: 70,
            bottom: 50,
            zIndex: 1
        },
        loaderSaving: {
            textAlign: 'center',
            padding: '2px 2px',
        },
        flexDispaly: {
            display: 'flex',
        },
        textpropertyOut: {
            color: 'black',
            cursor: 'pointer',
            marginLeft: '15px',
            transitionDelay: '0.2s',
            fontSize: '15px !important',
            fontWeight: 'bold'
        },
        supportStyle: {
            fontSize: 13,
            wordBreak: 'break-all',
            width: '200px',
            textAlign: 'center'
        },
        mainmenu: {
            padding: '0px',
            width: '100%',
            borderRight: '1px solid gray',
            boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px',
        },
        activeSidebar: {
            backgroundColor: '#c8facd',
            borderRadius: '5px',
            color: '#00ab55',
        },
        activeSidebarText: {
            color: '#00ab55'
        },
        activityStyle: {
            padding: '3px 15px',
        },
        textprop: {
            marginTop: "15px",
            color: "black"
        },
        imageSize: {
            height: '80px',
            width: '80px'
        },
        textcolor: {
            color: 'blue'
        },
        searchBox: {
            borderRadius: "30px",
            border: '2px solid #215176',
            height: '35px',
            textIndent: '10px',
            fontSize: 14,
            width: '100%',
            marginTop: '5px',
            paddingLeft: '10px',
            marginBottom: '0px',
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
            '@media (max-width: 600px)': {
                marginBottom: '5px',
            },
        },
        outlinedInput: {
            fontSize: 14,
            color: "black",
            '&$focused $notchedOutline': {
                color: 'white',
                border: '0px'
            },
        },
        notchedOutline: {
            color: 'white',
            border: '0px'
        },
        outlinedInput2: {
            fontSize: 15,
            color: "black",
            marginTop: '-3px',
            '&$focused $notchedOutline': {
                color: 'white',
                border: '0px'
            },
        },
        notchedOutline2: {
            color: 'white',
            border: '0px'
        },
        searchInput: {
            padding: '7px',
        },
        checkboxColor: {
            color: 'white !important',
            padding: '1px'
        },
        checkboxColor1: {
            color: 'white !important',
            padding: '3px 8px'
        },
        tableBodyActivity: {
            maxHeight: 500,
            minHeight: 500,
            overflowX: 'hidden',
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 330px)',
                minHeight: 'calc( 100vh - 330px)',
            },
        },
        cardDesign: {
            display: 'flex',
            borderTop: '4px solid #3bb78f',
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
        },
        activityBox: {
            display: 'flex',
            flexDirection: 'column',
            padding: '8px',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        },
        usertablecellStyle: {
            padding: '3px 2px'
        },
        tableCellPermission: {
            padding: '0px',
        },
        tableCellPermission1: {
            padding: '0px',
        },
        permissionCheckbox: {
            // minHeight: 350,
            // maxHeight: 350,
            marginTop: '5px',
            overflowX: 'hidden',
            // [theme.breakpoints.up('lg')]: {
            //     maxHeight: 'calc( 100vh - 370px)',
            //     minHeight: 'calc( 100vh - 370px)',
            // },
        },
        loadingText: {
            marginTop: '16px',
            fontSize: '14px',
            paddingLeft: '5px'
        },
        loadingDiv: {
            display: 'flex',
            marginLeft: '10px'
        },
        manageTitle: {
            marginTop: '4px',
            textAlign: 'left',
            width: '400px',
            fontSize: '17px',
            // borderBottom:'1px dashed lightgray'
        },
        existButton: {
            background: "red",
            color: 'white',
            fontSize: 12,
            borderRadius: 20,
            right: '15px',
            '&:hover': {
                background: "red",
            }
        },
        selectedRow: {
            backgroundColor: 'rgba(97,175,254,.2)',
            borderColor: '#61affe',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px',
            '& .MuiTableCell-root': {
                padding: '5px 15px',
                borderColor: '#61affe',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px'
            }
        },
        unSelectedRow: {
            backgroundColor: 'white',
            color: 'black',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px',
            '& .MuiTableCell-root': {
                padding: '5px 15px',
                borderColor: '#c7c6c6',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px'
            }
        },
        selectedCheckbox: {
            padding: '4px 2px',
        },
        selectedRowReadHidden: {
            backgroundColor: '#8fd3fe',
            // backgroundColor: '#ebf4fb',
            padding: '0px 0px 0px 15px',
        },
        deletePaperDialogue: {
            minWidth: "650px",
        },
        checkBoxClientPhase: {
            // backgroundColor: '#bbdefb',
            borderRadius: '5px',
            // backgroundColor: '#E7EBF0',
            borderTop: '4px solid #3bb78f',
            marginBottom: '4px',
            width: '100%',
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
        },
        emailLoading: {
            display: 'flex'
        },
        spinnerStyle: {
            marginTop: '17px',
            paddingLeft: '5px'
        },
        checkboxSeletct: {
            padding: '3px 4px',
            marginLeft: '10px'
        },
        checkboxSeletct1: {
            padding: '3px 4px',
            marginLeft: '19px'
        },
        blueBorderColor: {
            borderWidth: 1
        },
        redBorderColor: {
            borderColor: "red",
            borderWidth: 2
        },
        greenBorderColor: {
            borderColor: "green",
            borderWidth: 2
        },
        norecord: {
            color: "red",
            textAlign: 'center',
            fontSize: 17
        },
        paddingCheckbox: {
            backgroundColor: 'white',
            color: 'black',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px',
            '& .MuiTableCell-root': {
                padding: '5px 15px',
                borderColor: '#c7c6c6',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px'
            }
        },
        readonlyTemplate: {
            backgroundColor: '#bfc0c0',
            borderColor: 'gray',
            color: 'black',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px',
            '& .MuiTableCell-root': {
                padding: '5px 15px',
                borderColor: 'gray',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px'
            }
        },
        selectedTemplate: {
            backgroundColor: '#bcf8ec',
            borderColor: 'gray',
            color: 'black',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px',
            '& .MuiTableCell-root': {
                padding: '5px 15px',
                borderColor: 'gray',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px'
            }
        },
        readonlyIcon: {
            marginTop: '10px',
            backgroundColor: '#f9bca8',
            marginLeft: '5px',
            '&:hover': {
                background: "#f9bca8",
            }
        },
        divStyle: {
            flexDirection: 'row',
            display: 'flex'
        },
        readOnlyTabSelectedRow: {
            backgroundColor: '#e5e5f0',
            borderColor: 'gray',
            border: '2px solid gray',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 0px',
            transition: "all 0.2s ease",
            padding: '10px 15px',
        },
        phaseGridStyle: {
            display: 'flex',
            flexDirection: 'row'
        },
        dialogboxOverride: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            height: '100%',
            border: "1px solid #0D324D",
            borderRadius: 15
        },
        titleHeaderOverride: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px'
        },
        warningIcon: {
            width: '45px',
            Height: '35px'
        },
        textfield1: {
            textAlign: 'center',
            color: 'black',
            fontSize: 18
        },
        textfield2: {
            textAlign: 'center',
            color: 'black',
            fontSize: 15
        },
        dialogaction: {
            gap: '3%',
            marginBottom: '10px'
        },
        TemplateNameStyle: {
            display: 'flex',
            alignContent: 'flex-start',
            justifyContent: 'flex-start',
            marginTop: '8px'
        },
        switchPermission: {
            marginTop: '5px',
            float: 'right',
            flexDirection: 'row',
            display: 'flex'
        },
        activityFont: {
            marginTop: '7px'
        },
        deleteIconButton: {
            padding: '1px',
        },

        /* Agent user Create/Edit */
        appBar: {
            position: 'relative',
            overflowY: 'hidden',
            minHeight: '40px !important',
            borderBottom: '2px solid #0072E5'
        },
        title: {
            flex: 1,
            color: '#0072E5',
            textAlign: 'center',
            fontSize: 18,
            marginRight: '100px'
        },
        menuButton: {
            marginTop: '5px',
            marginBottom: '5px',
            height: '40px',
            backgroundColor: 'white',
            cursor: 'default',
            '&:hover': {
                backgroundColor: 'white',
            }
        },
        menuButton1: {
            width: '120px',
            height: '40px'
        },
        dialog: {
            '& .MuiDialog-paper': {
                overflowY: 'hidden'
            }
        },
        toolbarStyle: {
            backgroundColor: 'white',
            minHeight: '40px !important'
        },
        searchIcon: {
            borderRadius: 50,
            background: "white",
            width: '100%',
            height: '30px',
            border: '2px #215176 solid',
            // boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
            fontSize: 12,
            textIndent: '10px',
            // marginLeft: '-45px',
            marginTop: '5px',
            paddingRight: "5px"
        },
        userpermission: {
            fontSize: '12px',
            width: '150px'
        },
        clientpermission: {
            fontSize: '12px',
            width: '140px',
            paddingLeft: '8px'
        },
        notificationPhaseStyle: {
            fontSize: "13px",
            padding: '0px',
            marginLeft: '0px'
        },
        notificationSeletct: {
            padding: '3px 0px',
            marginLeft: '5px'
        },
        clientSelection: {
            marginTop: "20px !important",
            color: 'blue'
        },
        closeBtn: {
            background: "red",
            color: 'white',
            borderRadius: 20,
            fontSize: 12,
            marginRight: '70px',
            '&:hover': {
                background: "red",
            },
        },
        paper1: {
            border: "2px solid black",
        },
        smallFont: {
            fontSize: 12,
        },
        ContentStyle: {
            fontSize: 8,
            border: '1px solid #215176',
            boxShadow: 'rgba(6, 24, 44, 0.4) 0px 0px 0px 1px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
        },
        controlWidth1: {
            borderRadius: 5,
            fontSize: '8px',
            width: '100%',
            // [theme.breakpoints.up('lg')]: {
            //     width: '100%',
            //     minHeight: 'calc( 100vh - 280px) !important',
            //     maxHeight: 'calc( 100vh - 280px) !important',
            // }
        },
        expandicon: {
            backgroundColor: '#215176',
            color: 'white',
            borderRadius: 30
        },
        TextNormal1: {
            color: 'white',
            fontSize: 15
        },
        tableBodyTrack: {
            minHeight: 'calc( 100vh - 316px) !important',
            maxHeight: 'calc( 100vh - 316px) !important',
        },
        activityContainer: {
            padding: '5px !important',
            minHeight: 'calc( 100vh - 338px) !important',
            maxHeight: 'calc( 100vh - 338px) !important',
        },
    })
)

export default useStyles