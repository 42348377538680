import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: '0px 12px',
            },
        },
        rootHidden: {
            display: "none"
        },
        button: {
            borderRadius: 5,
            color: 'white',
        },
        inputField: {
            width: '90%',
            height: '38px',
            fontSize: 14,
            borderRadius: '4px',
            position: 'relative',
            transition: '0.3s',
            marginTop: '5px',
            marginBottom: '10px',
            textAlign: 'right',
            paddingRight: '10px',
            '&:hover': {
                backgroundColor: 'lightyellow'
            },
            fontStyle: 'bold',
            float: 'right',
        },
        typographyTitleStyle: {
            textAlign: 'right',
            color: '#800080',
            fontSize: '16px',
            marginBottom: '14px',
            whiteSpace: 'nowrap',
        },
        currentamountStyle: {
            textAlign: 'right',
            fontSize: '18px',
            marginBottom: '15px',
        },
        currentamountStyleColor: {
            color: 'green',
        },
        typographyStyleColor: {
            color: 'green',
        },
        updateamountStyleContent: {
            textAlign: 'right',
            fontSize: '18px',
            marginBottom: '15px',
        },
        buttonStyle: {
            borderRadius: 20,
            '& .MuiButton-label': {
                fontSize: 11,
            },
        },
        buttonStyle1: {
            borderRadius: 20,
            fontSize: 11,
        },
        gridLastSpacing: {
            marginLeft: '40px',
        },
        typographyStyle: {
            marginTop: '-7px',
            color: 'green',
        },
        textStyle: {
            fontSize: '16px',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        yesButton: {
            background: "green",
            color: 'white',
            borderRadius: 20,
            bottom: '5px',
            right: '15px',
            '&:hover': {
                background: "green",
            }
        },
        noButton: {
            background: "red",
            borderRadius: 20,
            display: 'flex',
            right: '10px',
            bottom: '5px',
            '&:hover': {
                background: "red",
            }
        },
        checkBoxStyle: {
            fontWeight: 'bold'
        },
        typographyFontStyle: {
            fontSize: '15px'
        },
        displayFlex: {
            display: 'flex',
        },
        typoFontSize: {
            fontSize: '14px',
        },
        gridFlex: {
            flex: '1',
            margin: '0px 5px',
            marginRight: '5px',
        },
        spanErrorSpacing: {
            marginBottom: "20px",
            marginLeft: "-15px"
        },
        dialogTitle: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white',
            fontSize: '18px'
        },
        dialogContent: {
            marginTop: "10px",
            color: "black",
            fontSize: 16
        },
        colorRed: {
            color: 'red',
            fontSize: 14,
            marginTop: '7px',
        },
        buttonBgStyle: {
            background: "#009900",
            padding: '8px 37px',
            '&:hover': {
                background: "green",
            }
        },
        SubmitButtonStyle: {
            color: 'white',
            height: '20px',
            weight: '20px',
            marginLeft: '10px'
        },
        btnSpacing: {
            marginLeft: '5px',
            marginBottom: '5px',
            '& .MuiButton-label': {
                fontSize: 11,
            },
            '& .MuiSvgIcon-root': {
                fontSize: 18,
            }
        },
        iconBtnStyle: {
            padding: '0px',
            margin: '0px 5px',
        },
        btnRollSpacing: {
            padding: '8px 15px',
            width: '150px'
        },
        circleStyle: {
            color: 'white',
            height: "15px",
            width: "15px",
            marginLeft: '3px'
        },
        image3: {
            width: '30px',
            height: '30px',
            color: '#007FFF'
        },
        documentname: {
            marginLeft: "10px",
            color: "black",
            fontSize: 15
        },
        Visible: {
            fontSize: 25,
            color: '#1E90FF'
        },
        NonVisible: {
            fontSize: 25,
            color: '#AEAEAE',
        },
        btnGridSpacing: {
            marginTop: '15px',
        },
        btnBoxSpacing: {
            paddingLeft: '0px !important',
            '& .MuiBox-root': {
                padding: '0px 5px'
            },
            '& .MuiBox-root:first-child': {
                paddingLeft: '0px'
            }
        },
        nopaymentSpacing: {
            marginLeft: '40px'
        },
        dialogePaper: {
            minWidth: "700px"
        },
        iconSpacingTop: {
            margin: "0px 3px",
            padding: '3px',
        },
        dialogboxPaper: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            minWidth: "700px"
        },
        titleHeaderOverride: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px'
        },
        ImageWarning: {
            width: '50px',
            height: '50px',
            marginTop: '5px',
        },
        textfield1: {
            textAlign: 'center',
            color: 'black',
            fontSize: 16
        },
        dialogaction: {
            gap: '3%',
            marginBottom: '5px'
        },
        confirmbtn: {
            background: "green",
            color: 'white',
            borderRadius: 20,
            margin: '5px',
            fontSize: 12,
            '&:hover': {
                background: "green",
            }
        },
        cancelbtn: {
            background: "red",
            borderRadius: 20,
            display: 'flex',
            right: '10px',
            fontSize: 12,
            margin: '5px',
            '&:hover': {
                background: "red",
            }
        },
    }),
)

export default useStyles