import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    div: {
        flexGrow: 1,
        padding: '8px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    notchedOutline1: {
        color: 'white',
        border: '0px'
    },
    tableBody: {
        maxHeight: 505,
        minHeight: 505,
        marginTop: '-7px',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 215px)',
            minHeight: 'calc( 100vh - 215px)',
        },
    },
    tableBody1: {
        maxHeight: 505,
        minHeight: 505,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 215px)',
            minHeight: 'calc( 100vh - 215px)',
        },
    },
    tableBodyAckReport: {
        maxHeight: 505,
        minHeight: 505,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 215px)',
            minHeight: 'calc( 100vh - 215px)',
        },
    },
    footer: {
        marginTop: '13px',
        float: 'right',
        marginRight: '70px'
    },
    paper1: {
        border: "2px solid black",
        fontSize: 12
    },
    textStyle: {
        marginLeft: '5px',
        float: 'left',
        marginTop: '10px',
    },
    caption: {
        padding: 0,
        fontSize: 13
    },
    autoCompleteFont: {
        fontSize: 12,
        padding: '4px 12px'
    },
    searchBox: {
        borderRadius: "30px",
        border: '2px solid #215176',
        height: '35px',
        textIndent: '10px',
        fontSize: 14,
        width: '100%',
        marginTop: '8px',
        paddingLeft: '10px',
        marginBottom: '5px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        '@media (max-width: 600px)': {
            marginBottom: '5px',
        },
    },
    dropDownStyle: {
        marginTop: '2px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        borderRadius: 5,
        border: '2px solid #215176',
        backgroundColor: 'white',
        height: '38px',
        fontSize: 12,
        width: '100%'
    },
    SearchDropdown: {
        marginLeft: '16px',
        marginTop: '-3px',
        width: '100%',
    },
    pagination: {
        backgroundColor: '#F5F5F5',
        border: '2px solid #ECECEC'
    },
    fontSize: {
        fontSize: 12
    },
    backbutton: {
        borderRadius: 20,
        fontSize: 11,
        marginTop: '5px'
    },
    searchInput: {
        padding: '7px',
    },
    notchedOutline: {
        color: 'white',
        border: '0px'
    },
    outlinedInput: {
        fontSize: 14,
        color: "black",
        '&$focused $notchedOutline': {
            color: 'white',
            border: '0px'
        },
    },
    maingrid: {
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        filter: 'drop-shadow(0 -6mm 4mm gray))'
    },
    searchBar: {
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        borderRadius: 10,
        border: '2px solid #215176',
        width: '100%',
        fontSize: 14,
        textIndent: '10px',
        marginBottom: '5px',
    },
    searchTextStyle: {
        padding: '10px !important',
    },
    IconSizeStyle: {
        height: '22px',
        width: '22px'
    },
    backButton: {
        borderRadius: "20px !important",
        marginTop: '10px !important',
        marginRight: '20px !important',
        fontSize: '12px !important'
    },
    ErrorMessageStyle: {
        color: 'red',
        fontSize: '16px'
    },
    Completed1: {
        backgroundColor: '#c5f5c5 !important',
        color: 'green !important',
        fontWeight: 'bold'
    },
    Inprogress1: {
        backgroundColor: '#f7dbcd !important',
        color: '#ed6c02 !important',
        fontWeight: 'bold'
    },
    CancelButton: {
        backgroundColor: 'red',
        borderRadius: '20px'
    },
    TableCellStyle1: {
        fontSize: 13,
        padding: '0px 10px'
    },
    clientAdmin: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    globalAdmin: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    superAdminColor: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    regularUser: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid black',
    },
    fileAdmin: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderRadius: "100%",
        boxShadow: "inset 0px 0px 0px 5px white",
        border: '2px solid gray',
    }
}))

export { useStyles };