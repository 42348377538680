import { AppBar, Grid, makeStyles, Toolbar, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import * as Constant from '../../constants/Constant';
import auth from '../../images/header/clientInfo.svg';
import { IProspectUsersDetails } from '../../models/accesscontrol/ProspectUsersDetails';
import { usePost } from '../../utils/apiHelper';
import { SignOutButton } from './SignOutButton';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        margin: '10px 0px'
    },
    image: {
        padding: '15px',
        width: '680px',
        height: '480px',
        marginTop: '20px',
        alignItems: "center",
    },
    MessageStyle: {
        fontSize: '20px',
        color: '#0136a1',
    },
    clientBtn: {
        borderRadius: '20px',
        marginTop: '15px',
        backgroundColor: 'green'
    },
    linkStyle: {
        textDecoration: 'none',
        color: 'white',
        fontSize: '12px'
    },
    userActiveSytyle: {
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: '18px',
    }
});

export const ProspectClientInformation: React.FC<{ userDetails: IProspectUsersDetails, isPasswordProvided: boolean }> = (props) => {
    const { userDetails } = props;
    const classes = useStyles();

    // useEffect(() => {
    //     let request = {
    //         "userName": userDetails?.prospectEmailAddress,
    //         "organizationAdminEmailId": userDetails?.organizationAdminEmailId,
    //         "organizationPhoneNumber": userDetails?.organizationPhoneNumber,
    //         "organizationName": userDetails?.organizationName
    //     }
    //     userDetails?.isWebviewUser && !userDetails?.isNewOrginization ?
    //         usePost<any>("ProspectUsersAdminEmail", request).then((r) => {
    //         })
    //         : null
    // }, []);


    return (
        <React.Fragment>
            <AppBar position="static" style={{ backgroundColor: 'White' }}>
                <Toolbar>
                    <Grid container spacing={3}>
                        <Grid item xs={2}>
                            <img alt="ARM_Solutions Logo" width="160px" height="55px" src={Constant.ARMLogo} />
                        </Grid>
                        <Grid item xs={8} style={{ color: 'black', marginTop: '18px' }}>
                            <Typography align='center'>
                                Logged in as : <span style={{ color: 'blue' }}><b>{userDetails?.prospectEmailAddress}</b></span>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <SignOutButton />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.root}>
                <Grid container spacing={0} >
                    <Grid item xs={12}>
                        <img alt="ARM_Solutions Logo" src={auth} width="160px" height="55px" className={classes.image} />
                    </Grid>
                    {!userDetails?.isAdminApproved && userDetails?.isUserActivated ?
                        <Grid item xs={12}>
                            <Typography variant='h6' className={classes.MessageStyle}>
                                Please ask your Administrator to Approve your Information.<br /> &nbsp;
                                <span className={classes.userActiveSytyle}>
                                    If you need any clarification or experiencing any difficulties, <br />please reach out &nbsp;
                                    <a href="mailto:clientservices@armsolutions.com" style={{ textDecoration: 'none' }}><b>{Constant.serviceEmailID}</b></a>
                                </span>
                            </Typography>
                        </Grid>
                        : props?.isPasswordProvided ?
                            <Grid item xs={12}>
                                <Typography variant='h6' className={classes.MessageStyle}>
                                    Please check your inbox and click the link to activate your user.<br /> &nbsp;
                                    <span className={classes.userActiveSytyle}>
                                        If you need any clarification or experiencing any difficulties, <br />please reach out &nbsp;
                                        <a href="mailto:clientservices@armsolutions.com" style={{ textDecoration: 'none' }}><b>{Constant.serviceEmailID}</b></a>
                                    </span>
                                </Typography>
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <Typography variant='h6' className={classes.MessageStyle}>
                                    Please check your inbox / junk folder and accept the invitation to activate your user.<br /> &nbsp;
                                    <span className={classes.userActiveSytyle}>
                                        If you need any clarification or experiencing any difficulties, <br />please reach out &nbsp;
                                        <a href="mailto:clientservices@armsolutions.com" style={{ textDecoration: 'none' }}><b>{Constant.serviceEmailID}</b></a>
                                    </span>
                                </Typography>
                            </Grid>
                    }
                </Grid>
            </div>
        </React.Fragment >
    );
};

