import DateFnsUtils from '@date-io/date-fns';
import { Backdrop, Box, Button, Card, CardContent, Divider, Grid, IconButton, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Badge, styled, tableCellClasses } from "@mui/material";
import TablePagination from '@mui/material/TablePagination';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PuffLoader from "react-spinners/PuffLoader";
// Icons
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// Components
import Footer from '../../components/Footer/Footer';
import AvatarTooltip from '../../components/GlobalStyles/AvatarTooltip';
import LightTooltip from '../../components/GlobalStyles/LightTooltip';
import BackupDocumentDialog from "../../components/NotificationCenterComponent/BackupDocumentDialog";
import DvExpandableRow from '../../components/NotificationCenterComponent/DisputeValidation/DVRequest';
import HideNotification from "../../components/NotificationCenterComponent/HideNotification";
import useStylesTable from '../../components/NotificationCenterComponent/NCCcss/NotiCenterCss';
import ExpandableRow from '../../components/NotificationCenterComponent/NCSettlementRequest';
import NewPaymentFile from "../../components/NotificationCenterComponent/NewPaymentFile/NewPaymentFile";
import PvNewExpandableRow from '../../components/NotificationCenterComponent/PaymentVerification/PvNewRequest';
import ReadNotification from "../../components/NotificationCenterComponent/ReadNotification";
import VideoPlayer from '../../components/YouTubePlayer/VideoPlayer';
// Models
import { notificationVideo, title } from '../../constants/Constant';
import { IUserInfo } from '../../models/accesscontrol/IUserInfo';
import { IUserInvited } from '../../models/common/IUserInvited';
import { IDownloadNotificationResult } from '../../models/NotificationCenter/IDownloadNotificationResult';
import { INotificationCenter, INotificationCountResult } from "../../models/NotificationCenter/INotificationCenter";
import { IRollClients } from '../../models/Roll/IRollClients';
import { useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
// Image Icons
import TrustStatementFiles from '../../components/NotificationCenterComponent/TrustStatement/TrustStatementFiles';
import clearIcon from '../../images/buttonIcons/clearIcon.svg';
import download from "../../images/files/download01.svg";
import BackupDocument from '../../images/Icons/BackupFile.svg';
import DisputeValidations from '../../images/Icons/DisputeValidations.svg';
import Notification from '../../images/Icons/email.png';
import NewFileUploads from '../../images/Icons/NewFileUploads.svg';
import PaymentFile from '../../images/Icons/payment.svg';
import PaymentVerifications from '../../images/Icons/PaymentVerifications.svg';
import Settlement from '../../images/Icons/Settlement.svg';
import TrustStatement from '../../images/Icons/trustStatement.png';
import { useStyles } from './NotificationCenterCss';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        background: "#007FFF",
        color: theme.palette.common.white,
        fontSize: 12,
        padding: '2px 5px',
        textTransform: 'uppercase',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: 0
    }
}));

export default function NotificationcenterClient() {
    const classes = useStyles();
    const popoverClasses = useStyles();
    const classes1 = useStylesTable();
    const { state } = useGlobalState();
    const [PaymentVerification, setPaymentVerification] = useState<INotificationCenter[]>([]);
    const [GeneralNotification, setGeneralNotification] = useState<INotificationCenter[]>([]);
    const [SettlementRequest, setSettlementRequest] = useState<INotificationCenter[]>([]);
    const [BackUpDocumentReq, setBackUpDocumentReq] = useState<INotificationCenter[]>([]);
    const [DisValidation, setDisValidation] = useState<INotificationCenter[]>([]);
    const [paymentFile, setpaymentFile] = useState<INotificationCenter[]>([]);
    const [FileIssue, setFileIssue] = useState<INotificationCenter[]>([]);
    const [ActiveNotificationTab, setActiveNotificationTab] = useState(8);
    const [selected, setSelected] = React.useState<string>();
    const [selectedTableRow, setselectedTableRow] = useState<number>(0);
    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const ClientCode = state.userAccessContext?.clientCodes.join(",");
    const [selectedFirstDate, setSelectedFirstDate] = useState(false);
    const [paymentFileAccess, setpaymentFileAccess] = useState(false);
    const [clearFilterOpen, setClearFilterOpen] = useState(false);
    const [selectedEndDate, setSelectedEndDate] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [ShowHidden, setShowHidden] = useState(false);
    const [fileExists, setFileExists] = useState(false);
    const [isExpanded, setIsEpanded] = useState(true);
    const [istoggle, setIsToggle] = useState(false);
    const [mapped, setmapped] = useState(false);
    const [open, setOpen] = useState(false);
    const [CheckInvited, setCheckInvited] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState<any>('desc');
    const [orderBy, setOrderBy] = useState<string>("");
    const prevDBTNo = useRef("");
    const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";
    const [dashboardCountResult, setDashboardCountResult] = useState<INotificationCountResult>();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setClearFilterOpen(false);
        setFileExists(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleRowClick = (accountId: string) => {
        setSelected(selected == accountId ? "" : accountId);
        prevDBTNo.current = accountId;
    }

    useEffect(() => {
        handleNotificationCount();
    }, [])

    const handleNotificationCount = () => {
        (async () => {
            setShowProgressBar(true);
            let request = {
                "userId": state.userAccessContext?.id
            }
            await usePost<INotificationCountResult>("Account/GetNotificationCount", request).then((r) => {
                setDashboardCountResult(r?.data[0]);
            })
        })().finally(() => {
            setShowProgressBar(false);
        })
    }

    useEffect(() => {
        (async () => {
            if (state.userAccessContext?.id !== undefined) {
                await useFetch<IUserInvited[]>(`User/CheckUserInvited?userId=${state.userAccessContext?.id}`).then((r) => {
                    setCheckInvited(r.data[0]?.user_id);
                });
            }
        })()
    }, []);

    useEffect(() => {
        (async () => {
            setShowProgressBar(true);
            await useFetch<IUserInfo[]>(`User/GetUser?userId=${state.userAccessContext?.id}`).then((CheckAccess) => {
                setpaymentFileAccess(CheckAccess.data["paymentRegex"]?.length === 0 || CheckAccess?.data["paymentRegex"] === null ? true : false);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    }, [])

    useEffect(() => {
        { !istoggle ? getNotificationsAsync(ActiveNotificationTab) : getHiddenNotificationsAsync(ActiveNotificationTab) }
    }, [rowsPerPage, page]);

    async function getNotificationsAsync(NotificationTypeId: number) {
        let GetClients: IRollClients[] = [];
        if (state?.userAccessContext?.clientCodes?.length) {
            state?.userAccessContext?.clientCodes?.forEach((x, index) => {
                if (x && (x != "" || x != null)) {
                    let ClientDetail: IRollClients = { clientCodes: x };
                    GetClients?.push(ClientDetail);
                }
            });
        }

        setShowProgressBar(true);
        setIsToggle(false);
        setActiveNotificationTab(NotificationTypeId);
        let request = {
            "clients": _.cloneDeep(GetClients),
            "isShowHidden": false,
            "rowsPerPage": rowsPerPage,
            "userId": state?.userAccessContext?.id,
            "startDate": (selectedFirstDate && StartDate === null ? '' : selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : ''),
            "endDate": (selectedEndDate && EndDate === null ? '' : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : ''),
            "notificationTypeId": NotificationTypeId === undefined ? null : NotificationTypeId === 0 ? null : NotificationTypeId,
            "recordNumber": (page * rowsPerPage) + 1
        };
        await usePost<{ "notificationCenter": INotificationCenter[], total: number }>("GetNotifications", request).then((r) => {
            setpaymentFile(r?.data["notificationCenter"].filter(notification => notification?.notificationTypeId == 8));
            setFileIssue(r?.data["notificationCenter"].filter(notification => notification?.notificationTypeId == 3));
            setSettlementRequest(r?.data["notificationCenter"].filter(notification => notification?.notificationTypeId == 4));
            setPaymentVerification(r?.data["notificationCenter"].filter(notification => notification?.notificationTypeId == 5));
            setBackUpDocumentReq(r?.data["notificationCenter"].filter(notification => notification?.notificationTypeId == 6));
            setDisValidation(r?.data["notificationCenter"].filter(notification => notification?.notificationTypeId == 7));
            setGeneralNotification(r?.data["notificationCenter"].filter(notification => notification?.notificationTypeId == 2));
            setmapped(r?.data["notificationCenter"].length > 0 ? true : false);
            setTotalCount(r?.data?.total);
        }).finally(() => {
            setShowProgressBar(false);
        });
    }

    async function getUnreadNotificationsAsync(NotificationTypeId: number) {
        let GetClients: IRollClients[] = [];
        if (state?.userAccessContext?.clientCodes?.length) {
            state?.userAccessContext?.clientCodes?.forEach((x, index) => {
                if (x && (x != "" || x != null)) {
                    let ClientDetail: IRollClients = { clientCodes: x };
                    GetClients.push(ClientDetail);
                }
            });
        }

        let request = {
            "clients": _.cloneDeep(GetClients),
            "isShowHidden": false,
            "rowsPerPage": rowsPerPage,
            "userId": state?.userAccessContext?.id,
            "startDate": null ? moment(StartDate).format('YYYY-MM-DD') : '',
            "endDate": null ? moment(EndDate).format('YYYY-MM-DD') : '',
            "notificationTypeId": NotificationTypeId === undefined ? null : NotificationTypeId === 0 ? null : NotificationTypeId,
            "recordNumber": (page * rowsPerPage) + 1
        };
        await usePost<{ "notificationCenter": INotificationCenter[], total: number }>("GetNotifications", request).then((r) => {
            setpaymentFile(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 8));
            setFileIssue(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 3));
            setSettlementRequest(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 4));
            setPaymentVerification(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 5));
            setBackUpDocumentReq(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 6));
            setDisValidation(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 7));
            setGeneralNotification(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 2));
            setmapped(r?.data["notificationCenter"].length > 0 ? true : false);
            setTotalCount(r?.data?.total);
            setOpen(true);
        }).finally(() => {
            setIsToggle(false);
            setShowProgressBar(false);
        });
    }

    async function clearFilter(NotificationTypeId: number) {
        let GetClients: IRollClients[] = [];
        if (state?.userAccessContext?.clientCodes?.length) {
            state?.userAccessContext?.clientCodes?.forEach((x, index) => {
                if (x && (x != "" || x != null)) {
                    let ClientDetail: IRollClients = { clientCodes: x };
                    GetClients.push(ClientDetail);
                }
            });
        }

        let request = {
            "clients": _.cloneDeep(GetClients),
            "isShowHidden": false,
            "rowsPerPage": rowsPerPage,
            "userId": state?.userAccessContext?.id,
            "startDate": '',
            "endDate": '',
            "notificationTypeId": NotificationTypeId === undefined ? null : NotificationTypeId === 0 ? null : NotificationTypeId,
            "recordNumber": (page * rowsPerPage) + 1
        };
        await usePost<{ "notificationCenter": INotificationCenter[], total: number }>("GetNotifications", request).then((r) => {
            setpaymentFile(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 8));
            setFileIssue(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 3));
            setSettlementRequest(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 4));
            setPaymentVerification(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 5));
            setBackUpDocumentReq(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 6));
            setDisValidation(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 7));
            setGeneralNotification(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 2));
            setmapped(r?.data["notificationCenter"].length > 0 ? true : false);
            setTotalCount(r?.data["notificationCenter"]?.length);
        }).finally(() => {
            setIsToggle(false);
            setShowProgressBar(false);
            setClearFilterOpen(true);
            setStartDate(null);
            setEndDate(null);
            setSelectedEndDate(false);
            setSelectedFirstDate(false);
        });
    }

    async function getHiddenNotificationsAsync(HiddenNotificationId: number) {
        setShowProgressBar(true);
        let request = {
            "isShowHidden": true,
            "userId": state?.userAccessContext?.id,
            "notificationTypeId": HiddenNotificationId === undefined ? null : HiddenNotificationId === 0 ? null : HiddenNotificationId,
            "clients": ClientCode
        };
        await usePost<INotificationCenter[]>("GetHiddenNotifications", request).then((r) => {
            setpaymentFile(r?.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 8));
            setFileIssue(r?.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 3));
            setSettlementRequest(r?.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 4));
            setPaymentVerification(r?.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 5));
            setBackUpDocumentReq(r?.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 6));
            setDisValidation(r?.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 7));
            setGeneralNotification(r?.data["hiddenNotificationCenter"].filter(notification => notification.notificationTypeId == 2));
            setmapped(r?.data["hiddenNotificationCenter"].length > 0 ? true : false);
            setTotalCount(r?.data["hiddenNotificationCenter"]?.length);
            setOpen(true);
        }).finally(() => {
            setIsToggle(true);
            setShowProgressBar(false);
        });
    }

    const getDownloadFileAsync = (fileId: string) => {
        (async () => {
            setShowProgressBar(true);
            await useFetch<IDownloadNotificationResult>(`DownloadNotificationFile?fileId=${fileId}`).then((DownloadNotificationFile) => {
                const arrayBuffer = base64ToArrayBuffer(DownloadNotificationFile.data.fileContent);
                createAndDownloadBlobFile(arrayBuffer, DownloadNotificationFile.data.fileName);
            }).catch(() => {
                setFileExists(true);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })();
    }

    function base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    function createAndDownloadBlobFile(body, filename) {
        const blob = new Blob([body]);
        const fileName = `${filename}`;
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const handleFilter = () => {
        getNotificationsAsync(ActiveNotificationTab);
        setPage(0);
        setShowProgressBar(true);
        setOpen(true);
    };

    const handleClearFilter = () => {
        (ShowHidden ? getHiddenNotificationsAsync(ActiveNotificationTab) : clearFilter(ActiveNotificationTab))
        setPage(0);
        setShowProgressBar(true);
        setStartDate(null);
        setEndDate(null);
    };

    const handleFirstDateChange = (date: Date | null) => {
        setStartDate(date);
        setSelectedFirstDate(true);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
        setSelectedEndDate(true);
    };

    function handleToggle(event) {
        if (!istoggle) {
            setShowHidden(true);
            getHiddenNotificationsAsync(ActiveNotificationTab);
            setShowProgressBar(true);
        } else {
            setShowHidden(false);
            setShowProgressBar(true);
            getUnreadNotificationsAsync(ActiveNotificationTab);
        }
    }

    const handleToggler = () => {
        setIsEpanded(s => !s);
    }

    const handleNewFileExpand = (userNotificationId: number) => {
        setselectedTableRow(userNotificationId);
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { id: "agencyID", disablePadding: false, label: " AGENCY ID ", sortable: true, alignItems: 'center' },
            { id: "account_Id", disablePadding: false, label: "ACCOUNT ID", sortable: true, alignItems: 'center' },
            { id: "client_code", disablePadding: false, label: "CLIENT CODE", sortable: true, alignItems: 'center' },
            { id: "client_name", disablePadding: false, label: "CLIENT NAME", sortable: true, alignItems: 'center' },
            { id: "name", disablePadding: false, label: "NAME", sortable: true, alignItems: 'center' },
            { id: "last_check", disablePadding: false, label: "Last 4 of CC or Check", sortable: true, alignItems: 'center' },
            { id: "payment_amount", disablePadding: false, label: "Payment Amount", sortable: true, alignItems: 'center' },
            { id: "payment_date", disablePadding: false, label: "Payment Date", sortable: true, alignItems: 'center' },
            { id: "create_Date", disablePadding: false, label: "CREATED DATE", sortable: true, alignItems: 'center' },
            { id: "action", disablePadding: false, label: "ACTIONS", sortable: false },
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <StyledTableCell
                            key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.TableCellHeadStyle}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                                style={{ alignItems: `${headCell.alignItems}` }}
                            >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    function EnhancedTableHeadBackUpDocument(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { id: "agencyID", disablePadding: false, label: " AGENCY ID", sortable: true },
            { id: "account_Id", disablePadding: false, label: " ACCOUNT NUMBER", sortable: true },
            { id: "client_code", disablePadding: false, label: "CLIENT CODE", sortable: true },
            { id: "client_name", disablePadding: false, label: " CLIENT NAME", sortable: true },
            { id: "create_Date", disablePadding: false, label: "CREATED DATE", sortable: true },
            { id: "type", disablePadding: false, label: "TYPE", sortable: true },
            { id: "action", disablePadding: false, label: "ACTIONS", sortable: false },
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.TableCellHeadStyle}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    function EnhancedTableDisputeValidation(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { id: "agencyID", disablePadding: false, label: " AGENCY ID", sortable: true },
            { id: "account_Id", disablePadding: false, label: " ACCOUNT NUMBER", sortable: true },
            { id: "client_code", disablePadding: false, label: "CLIENT CODE", sortable: true },
            { id: "client_name", disablePadding: false, label: " CLIENT NAME", sortable: true },
            { id: "create_Date", disablePadding: false, label: "CREATED DATE", sortable: true },
            { id: "fileName", disablePadding: false, label: "FILE NAME", sortable: false },
            { id: "action", disablePadding: false, label: "ACTIONS", sortable: false },
            { id: "expand", disablePadding: false, label: "EXPAND", sortable: false },
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.TableCellHeadStyle}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    function EnhancedTableHeadNewFileUpload(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { id: "create_Date", disablePadding: false, label: "CREATED DATE", sortable: true },
            { id: "file_issue_reason", disablePadding: false, label: "FILE ISSUE", sortable: true },
            { id: "attachment", disablePadding: false, label: "ATTACHMENT", sortable: true },
            { id: "download", disablePadding: false, label: "DOWNLOAD", sortable: true },
            { id: "Hide", disablePadding: false, label: "HIDE ROW", sortable: true },
            { id: "expand", disablePadding: false, label: "EXPAND", sortable: true },
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.TableCellHeadStyle}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <Snackbar open={fileExists} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={() => { setFileExists(false) }} severity="error" className="alertStyle">
                    File does not exists!
                </Alert>
            </Snackbar>
            <div style={{ display: 'flex' }}>
                <div className={`${isExpanded ? classes.sidebar : classes.sidebarCollapsed}`}>
                    <div className={classes.sidebarHeader}>
                        <IconButton size="medium" onClick={handleToggler}>
                            {
                                isExpanded ?
                                    <DoubleArrowIcon fontSize="inherit" className={`${classes.doubleArrowColor} ${classes.doubleArrowRotate}`} />
                                    :
                                    <DoubleArrowIcon fontSize="inherit" className={classes.doubleArrowColor} />
                            }
                        </IconButton>
                    </div>
                    <div className={`${classes.sidebarItem} ${ActiveNotificationTab === 8 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(8) }}>
                        {isExpanded ?
                            <AvatarTooltip title="New Payment File">
                                <img src={PaymentFile} alt="Icon for Payement File" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(8) }} />
                            </AvatarTooltip>
                            : (<>
                                <Badge badgeContent={dashboardCountResult?.newPaymentFileTotalCount || 0} color="primary">
                                    <AvatarTooltip title="New Payment File">
                                        <img src={PaymentFile} alt="Icon for Payement File" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(8) }} />
                                    </AvatarTooltip>
                                </Badge></>)
                        }
                        {isExpanded ?
                            <Badge badgeContent={dashboardCountResult?.newPaymentFileTotalCount || 0} color="primary">
                                <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty} ${ActiveNotificationTab === 8 ? classes.activeSidebarText : null}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(8) }}>
                                    New Payment File
                                </Typography>
                            </Badge>
                            : null}
                    </div>

                    {state.userAccessContext?.fileIssuesNotification ?
                        <div className={`${classes.sidebarItem} ${ActiveNotificationTab === 3 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(3) }}>
                            {isExpanded ?
                                <AvatarTooltip title="File Issue">
                                    <img src={NewFileUploads} alt="Icon for New File Upload" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(3) }} />
                                </AvatarTooltip>
                                : (<>
                                    <Badge badgeContent={dashboardCountResult?.newFileUploadTotalCount || 0} color="primary">
                                        <AvatarTooltip title="File Issue">
                                            <img src={NewFileUploads} alt="Icon for New File Upload" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(3) }} />
                                        </AvatarTooltip>
                                    </Badge></>)
                            }
                            {isExpanded ?
                                <Badge badgeContent={dashboardCountResult?.newFileUploadTotalCount || 0} color="primary">
                                    <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty} ${ActiveNotificationTab === 3 ? classes.activeSidebarText : null}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(3) }}>
                                        New File Upload
                                    </Typography>
                                </Badge>
                                : null}
                        </div>
                        :
                        null
                    }
                    {state.userAccessContext?.settlementRequestsNotification ?
                        <div className={`${classes.sidebarItem} ${ActiveNotificationTab === 4 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(4) }}>
                            {isExpanded ?
                                <AvatarTooltip title=" Settlement Authorization">
                                    <img src={Settlement} alt="Icon for Settlement Authorization" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(4) }} />
                                </AvatarTooltip>
                                : (<>
                                    <Badge badgeContent={dashboardCountResult?.settlementAuthorization || 0} color="primary">
                                        <AvatarTooltip title=" Settlement Authorization">
                                            <img src={Settlement} alt="Icon for Settlement Authorization" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(4) }} />
                                        </AvatarTooltip>
                                    </Badge></>)
                            }
                            {isExpanded ?
                                <Badge badgeContent={dashboardCountResult?.settlementAuthorization || 0} color="primary">
                                    <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty} ${ActiveNotificationTab === 4 ? classes.activeSidebarText : null}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(4) }}>
                                        Settlement Authorization
                                    </Typography>
                                </Badge>
                                : null}
                        </div>
                        :
                        null
                    }

                    {state.userAccessContext?.paymentVerificationNotification ?
                        <div className={`${classes.sidebarItem} ${ActiveNotificationTab === 5 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(5) }}>
                            {isExpanded ?
                                <AvatarTooltip title="Payment Verification">
                                    <img src={PaymentVerifications} alt="Icon for payment verification" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(5) }} />
                                </AvatarTooltip>
                                : (<>
                                    <Badge badgeContent={dashboardCountResult?.paymentVerificationTotalCount || 0} color="primary">
                                        <AvatarTooltip title="Payment Verification">
                                            <img src={PaymentVerifications} alt="Icon for payment verification" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(5) }} />
                                        </AvatarTooltip>
                                    </Badge></>)
                            }
                            {isExpanded ?
                                <Badge badgeContent={dashboardCountResult?.paymentVerificationTotalCount || 0} color="primary">
                                    <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty} ${ActiveNotificationTab === 5 ? classes.activeSidebarText : null}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(5) }}>
                                        Payment Verification
                                    </Typography>
                                </Badge>
                                : null}
                        </div>
                        :
                        null
                    }

                    {state.userAccessContext?.backupDocumentRequestNotification ?
                        <div className={`${classes.sidebarItem} ${ActiveNotificationTab === 6 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(6) }}>
                            {isExpanded ?
                                <AvatarTooltip title="Backup Documentation">
                                    <img src={BackupDocument} alt="Icon for Backup Documentation" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(6) }} />
                                </AvatarTooltip>
                                : (<>
                                    <Badge badgeContent={dashboardCountResult?.backupDocumentTotalCount || 0} color="primary">
                                        <AvatarTooltip title="Backup Documentation">
                                            <img src={BackupDocument} alt="Icon for Backup Documentation" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(6) }} />
                                        </AvatarTooltip>
                                    </Badge></>)
                            }
                            {isExpanded ?
                                <Badge badgeContent={dashboardCountResult?.backupDocumentTotalCount || 0} color="primary">
                                    <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty} ${ActiveNotificationTab === 6 ? classes.activeSidebarText : null}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(6) }}>
                                        Backup Documentation
                                    </Typography>
                                </Badge>
                                : null}
                        </div>
                        :
                        null
                    }

                    {state.userAccessContext?.disputeValidationNotification ?
                        <div className={`${classes.sidebarItem} ${ActiveNotificationTab === 7 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(7) }}>
                            {isExpanded ?
                                <AvatarTooltip title="Dispute Validation">
                                    <img src={DisputeValidations} alt="Icon for Dispute Validations" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(7) }} />
                                </AvatarTooltip>
                                : (<>
                                    <Badge badgeContent={dashboardCountResult?.disputeValidationTotalCount || 0} color="primary">
                                        <AvatarTooltip title="Dispute Validation">
                                            <img src={DisputeValidations} alt="Icon for Dispute Validations" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(7) }} />
                                        </AvatarTooltip>
                                    </Badge></>)
                            }
                            {isExpanded ?
                                <Badge badgeContent={dashboardCountResult?.disputeValidationTotalCount || 0} color="primary">
                                    <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty} ${ActiveNotificationTab === 7 ? classes.activeSidebarText : null}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(7) }}>
                                        Dispute Validation
                                    </Typography>
                                </Badge>
                                : null}
                        </div>
                        :
                        null
                    }

                    {state.userAccessContext?.generalNotification ?
                        <div className={`${classes.sidebarItem} ${ActiveNotificationTab === 2 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(2) }}>
                            {isExpanded ?
                                <AvatarTooltip title="General Notifications">
                                    <img src={Notification} alt="Icon for General Notifications" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(2) }} />
                                </AvatarTooltip>
                                :
                                (<>
                                    <Badge badgeContent={dashboardCountResult?.generalNotificationTotalCount || 0} color="primary">
                                        <AvatarTooltip title="General Notifications">
                                            <img src={Notification} alt="Icon for General Notifications" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(2) }} />
                                        </AvatarTooltip>
                                    </Badge></>)
                            }
                            {isExpanded ?
                                <Badge badgeContent={dashboardCountResult?.generalNotificationTotalCount || 0} color="primary">
                                    <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty} ${ActiveNotificationTab === 2 ? classes.activeSidebarText : null}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(2) }}>
                                        General Notifications
                                    </Typography>
                                </Badge>
                                : null}
                        </div>
                        :
                        null
                    }
                    {state.userAccessContext?.trustStatement ?
                        <div className={`${classes.sidebarItem} ${ActiveNotificationTab === 9 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(9) }}>
                            {isExpanded ?
                                <AvatarTooltip title="Trust Statement">
                                    <img src={TrustStatement} alt="Icon for General Notifications" width="35px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(9) }} />
                                </AvatarTooltip>
                                : (<>
                                    <Badge badgeContent={dashboardCountResult?.trustStatementTotalCount || 0} color="primary">
                                        <AvatarTooltip title="Trust Statement">
                                            <img src={TrustStatement} alt="Icon for General Notifications" width="35px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(9) }} />
                                        </AvatarTooltip>
                                    </Badge></>)
                            }
                            {isExpanded ?
                                <Badge badgeContent={dashboardCountResult?.trustStatementTotalCount || 0} color="primary">
                                    <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty} ${ActiveNotificationTab === 9 ? classes.activeSidebarText : null}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(9) }}>
                                        Trust Statement
                                    </Typography>
                                </Badge>
                                : null}
                        </div>
                        : null
                    }
                </div>
                <Grid container className={classes.grid} >
                    {ActiveNotificationTab != 9 ?
                        <Grid container className={classes.maingrid} justify='space-evenly'>
                            <Grid item xs={6} md={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="space-around">
                                        <KeyboardDatePicker
                                            label="Start Date"
                                            variant="inline"
                                            size="small"
                                            inputVariant="outlined"
                                            format={DateFormat}
                                            disableToolbar
                                            autoOk={true}
                                            margin="normal"
                                            id="NC_StartDate"
                                            value={StartDate}
                                            className={classes.textField1}
                                            onChange={handleFirstDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            disableFuture={true}
                                            PopoverProps={{ classes: popoverClasses }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={6} md={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container justify="space-around">
                                        <KeyboardDatePicker
                                            label="End Date"
                                            variant="inline"
                                            size="small"
                                            inputVariant="outlined"
                                            format={DateFormat}
                                            disableToolbar
                                            autoOk={true}
                                            margin="normal"
                                            className={classes.textField1}
                                            id="NC_StartDate"
                                            value={EndDate}
                                            onChange={handleEndDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            disableFuture={true}
                                            PopoverProps={{ classes: popoverClasses }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={12} md={2} className={classes.Title}>
                                {StartDate > EndDate ? <Typography variant='body1' className={classes.errorMsg}>End date should be greater than the Start date!</Typography> : null}
                            </Grid>
                            {ActiveNotificationTab != 0 ?
                                <Box className={classes.buttonStyle}>
                                    <Button id="Notification_Count" size="small" variant="contained" startIcon={<NotificationsActiveIcon />}
                                        className={classes.countButton}>
                                        Count : {totalCount}
                                    </Button>
                                </Box>
                                : null}
                            <Box className={classes.buttonStyle}>
                                <Button id="NoCenter_Filter_Button" className={classes.buttons} disabled={StartDate > EndDate} size="small"
                                    onClick={handleFilter} variant="contained" color="primary" startIcon={<FilterAltIcon className={classes.filterBtnStyle} />}>
                                    Filter
                                </Button>
                            </Box>
                            <Box className={classes.buttonStyle}>
                                <Button id="NoCenter_Filter_Button" className={classes.buttons} size="small"
                                    onClick={handleClearFilter} variant="contained" color="primary" startIcon={<img src={clearIcon} alt="Icon for clear filter button" className={classes.IconStyle} />}>
                                    Clear Filter
                                </Button>
                            </Box>
                            <Box className={classes.buttonStyle}>
                                <Button id="NoCenter_ShowHidden_Button" className={classes.buttons} disabled={StartDate > EndDate} size="small"
                                    variant="contained" color="primary" onClick={(e) => { handleToggle(e); }} startIcon={istoggle ? <VisibilityIcon /> : <VisibilityOffIcon />}>
                                    {istoggle ? "SHOW UNREAD " : "SHOW HIDDEN"}
                                </Button>
                            </Box>
                            <Box className={classes.videoIcon}>
                                <VideoPlayer video={notificationVideo} title={title} />
                            </Box>
                        </Grid>
                        : null}

                    {ActiveNotificationTab === 8 ?
                        < Grid className={`${classes.contentGrid} notifications-center`} item xs={12}>
                            <Card className={classes.card}>
                                <CardContent >
                                    <Typography variant="h6" className={classes.TitleStyleBorder} gutterBottom>
                                        New Payment File
                                    </Typography>
                                    {paymentFileAccess === true ?
                                        <Typography variant="subtitle2" gutterBottom className={classes.ErrorMessageStyle}>
                                            No Notifications to display...
                                        </Typography>
                                        :
                                        <Scrollbars autoHide
                                            autoHideTimeout={1000}
                                            autoHideDuration={10}
                                            autoHeight
                                            autoHeightMin={420}
                                            autoHeightMax={650}
                                            className={classes.scrollbars}>
                                            {mapped ? paymentFile.map(ack => {
                                                return (
                                                    <React.Fragment>
                                                        <NewPaymentFile NPF={ack} onRefresh={() => { getNotificationsAsync(8) }} onShowHidden={ShowHidden} />
                                                        <Divider className={classes.DividerStyle} />
                                                    </React.Fragment>
                                                )
                                            })
                                                : !mapped && ProgressBar === false ?
                                                    <Typography variant="subtitle2" gutterBottom className={classes.ErrorMessageStyle}>
                                                        No Notifications to display...
                                                    </Typography>
                                                    : null
                                            }
                                        </Scrollbars>
                                    }
                                </CardContent>
                            </Card>
                        </Grid>
                        : ActiveNotificationTab === 3 ?
                            <Grid className={`${classes.contentGrid} notifications-center`} item xs={12}>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <TableContainer component={Paper} className={`${classes.actions} ${"scrollbox"} ${"on-scrollbar"}`} >
                                            <Table aria-label="customized table" size="small" stickyHeader>
                                                <EnhancedTableHeadNewFileUpload
                                                    classes={classes}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                />
                                                <TableBody>
                                                    {mapped ?
                                                        FileIssue?.map((f) => {
                                                            let createdDate = moment(f?.createdDate).format(state.GlobalUtils?.settingValue);
                                                            return (
                                                                <>
                                                                    <TableRow className={classes1.tablecellPadding}>
                                                                        <TableCell >
                                                                            <span className={classes.paymentText}>{createdDate}</span>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <span className={classes1.paymentText}>{f?.extraData_3}</span>
                                                                        </TableCell>
                                                                        <TableCell >
                                                                            <span className={classes1.paymentText}>{f?.fileName}</span>
                                                                        </TableCell>
                                                                        <TableCell className={classes1.tablecellPadding}>
                                                                            {f?.extraData_2 ?
                                                                                <LightTooltip title="Download">
                                                                                    <IconButton id="NoCenter_FileNo_Download" color="primary" aria-label="upload picture" component="span" onClick={() => { getDownloadFileAsync(f?.extraData_2); }} style={{ padding: '4px' }}>
                                                                                        <img src={download} alt="Icon for Download" width="25px" height="25px" />
                                                                                    </IconButton>
                                                                                </LightTooltip>
                                                                                : null
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell className={classes1.tablecellPadding}>
                                                                            <HideNotification Notify={f} onHide={() => { getNotificationsAsync(3) }} />
                                                                        </TableCell>
                                                                        <TableCell className={classes1.tablecellPadding}>
                                                                            {selectedTableRow ?
                                                                                <IconButton onClick={() => { handleNewFileExpand(0); }} style={{ padding: '4px' }}>
                                                                                    <ExpandLessIcon />
                                                                                </IconButton>
                                                                                :
                                                                                <IconButton onClick={() => { handleNewFileExpand(f?.userNotificationId); }} style={{ padding: '4px' }}>
                                                                                    < ExpandMoreIcon />
                                                                                </IconButton>
                                                                            }
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    {selectedTableRow === f?.userNotificationId ?
                                                                        <TableRow className={selectedTableRow ? classes.root : classes.rootHidden} >
                                                                            <TableCell scope="row" colSpan={6} >
                                                                                <span className={classes1.paymentText}>
                                                                                    {f?.extraData_1}
                                                                                </span>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        : null
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                        : null
                                                    }
                                                </TableBody>
                                            </Table>
                                            {!mapped && ProgressBar === false ?
                                                <Typography variant="subtitle2" gutterBottom className={classes.ErrorMessageStyle}>
                                                    No Notifications to display...
                                                </Typography>
                                                : null}
                                        </TableContainer>
                                    </CardContent>
                                </Card>
                            </Grid>
                            : ActiveNotificationTab === 4 ?
                                <Grid className={`${classes.contentGrid} notifications-center`} item xs={12}>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Typography variant="h6" className={classes.TitleStyleBorder} gutterBottom>
                                                Settlement Requests
                                            </Typography>
                                            <React.Fragment>
                                                <TableContainer component={Paper} className={`${classes.settlementBody} ${"scrollbox"} ${"on-scrollbar"}`} >
                                                    <Table aria-label="customized table" size="small" stickyHeader>
                                                        <TableBody>
                                                            {mapped ?
                                                                SettlementRequest.map(r => <ExpandableRow dashboardCountResult={handleNotificationCount} onClick={() => { handleRowClick(r?.extraData_3); }} selected={selected === r?.extraData_3} key={r?.extraData_3} Notify={r} onHideAll={() => { getNotificationsAsync(4) }} onRefresh={() => { getNotificationsAsync(4) }} selectedDBTNumber={selected} IsInvited={(CheckInvited !== null && CheckInvited !== 0 && CheckInvited !== undefined) ? true : false} />)
                                                                : !mapped && ProgressBar === false ?
                                                                    <Typography variant="subtitle2" gutterBottom className={classes.ErrorMessageStyle}>
                                                                        No Notifications to display...
                                                                    </Typography>
                                                                    : null
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </React.Fragment>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                : ActiveNotificationTab === 5 ?
                                    <Grid className={`${classes.contentGrid}`} item xs={12}>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <TableContainer component={Paper} className={`${classes.actions} ${"scrollbox"} ${"on-scrollbar"}`} >
                                                    <Table aria-label="customized table" size="small" stickyHeader>
                                                        <EnhancedTableHead
                                                            classes={classes}
                                                            order={order}
                                                            orderBy={orderBy}
                                                            onRequestSort={handleRequestSort}
                                                        />
                                                        <TableBody>
                                                            {mapped ?
                                                                stableSort(PaymentVerification, getComparator(order, orderBy)).map(
                                                                    (r) => {
                                                                        return (
                                                                            <PvNewExpandableRow onClick={() => { handleRowClick(r?.extraData_1?.slice(0, 9)); }} selected={selected === r?.extraData_1?.slice(0, 9)} key={r?.extraData_1?.slice(0, 9)} pv={r} onRefresh={() => { getNotificationsAsync(5) }} selectedDBTNumber={selected} />
                                                                        );
                                                                    })
                                                                : null
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                    {!mapped && ProgressBar === false ?
                                                        <Typography variant="subtitle2" gutterBottom className={classes.ErrorMessageStyle}>
                                                            No Notifications to display...
                                                        </Typography>
                                                        : null}
                                                </TableContainer>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    : ActiveNotificationTab === 6 ?
                                        <Grid className={`${classes.contentGrid} notifications-center`} item xs={12}>
                                            <Card className={classes.card}>
                                                <CardContent>
                                                    <TableContainer component={Paper} className={`${classes.actions} ${"scrollbox"} ${"on-scrollbar"}`} >
                                                        <Table aria-label="customized table" size="small" stickyHeader>
                                                            <EnhancedTableHeadBackUpDocument
                                                                classes={classes}
                                                                order={order}
                                                                orderBy={orderBy}
                                                                onRequestSort={handleRequestSort}
                                                            />
                                                            <TableBody>
                                                                {mapped ?
                                                                    BackUpDocumentReq.map(BackUp => {
                                                                        return (
                                                                            <TableRow>
                                                                                <TableCell className={classes.tablecellPadding}>
                                                                                    <span className={classes.paymentText}>{BackUp?.extraData_1}</span>
                                                                                </TableCell>
                                                                                <TableCell className={classes.tablecellPadding}>
                                                                                    <span className={classes.paymentText}>{BackUp?.extraData_3?.substring(0, BackUp?.extraData_3?.lastIndexOf("+"))}</span>
                                                                                </TableCell>
                                                                                <TableCell className={classes.tablecellPadding}>
                                                                                    <span className={classes.paymentText}>{BackUp?.clT_Code}</span>
                                                                                </TableCell>
                                                                                <TableCell className={classes.tablecellPadding}>
                                                                                    <span className={classes.paymentText}>{BackUp?.clT_Name}  {BackUp?.clT_Name_2}</span>
                                                                                </TableCell>
                                                                                <TableCell className={classes.tablecellPadding}>
                                                                                    <span className={classes.paymentText}>  {moment(BackUp?.createdDate).format(state.GlobalUtils?.settingValue)}</span>
                                                                                </TableCell>
                                                                                <TableCell className={classes.tablecellPadding}>
                                                                                    {BackUp?.extraData_2}
                                                                                </TableCell>
                                                                                <TableCell className={classes.tablecellPadding}>
                                                                                    <span className={classes.paymentText}> <BackupDocumentDialog BackUpInfo={BackUp} onHideAll={() => { getNotificationsAsync(6) }} IsInvited={(CheckInvited !== null && CheckInvited !== 0 && CheckInvited !== undefined) ? true : false} /></span>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })
                                                                    : null
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                        {!mapped && ProgressBar === false ?
                                                            <Typography variant="subtitle2" gutterBottom className={classes.ErrorMessageStyle}>
                                                                No Notifications to display...
                                                            </Typography>
                                                            : null
                                                        }
                                                    </TableContainer>
                                                </CardContent >
                                            </Card >
                                        </Grid >
                                        : ActiveNotificationTab === 7 ?
                                            <Grid className={`${classes.contentGrid}`} item xs={12}>
                                                <Card className={classes.card}>
                                                    <CardContent>
                                                        <TableContainer component={Paper} className={`${classes.actions} ${"scrollbox"} ${"on-scrollbar"}`} >
                                                            <Table aria-label="customized table" size="small" stickyHeader>
                                                                <EnhancedTableDisputeValidation
                                                                    classes={classes}
                                                                    order={order}
                                                                    orderBy={orderBy}
                                                                    onRequestSort={handleRequestSort}
                                                                />
                                                                <TableBody>
                                                                    {mapped ?
                                                                        DisValidation.map(r => <DvExpandableRow onClick={() => { handleRowClick(r?.extraData_1); }} selected={selected === r?.extraData_1} key={r?.extraData_1} dv={r} onHideAll={() => { getNotificationsAsync(7) }} selectedDBTNumber={selected} IsInvited={(CheckInvited !== null && CheckInvited !== 0 && CheckInvited !== undefined) ? true : false} />)
                                                                        : null
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                            {!mapped && ProgressBar === false ?
                                                                <Typography variant="subtitle2" gutterBottom className={classes.ErrorMessageStyle}>
                                                                    No Notifications to display...
                                                                </Typography>
                                                                : null}
                                                        </TableContainer>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            : ActiveNotificationTab === 2 ?
                                                <Grid className={`${classes.contentGrid} notifications-center`} item xs={12}>
                                                    <Card className={classes.card}>
                                                        <CardContent>
                                                            <TableContainer component={Paper} className={`${classes.actions} ${"scrollbox"} ${"on-scrollbar"}`} >
                                                                <Table aria-label="customized table" size="small" stickyHeader>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell className={classes.TableCellHeadStyle}>
                                                                                CREATED DATE
                                                                            </TableCell>
                                                                            <TableCell className={classes.TableCellHeadStyle}>
                                                                                MESSAGE
                                                                            </TableCell>
                                                                            <TableCell className={classes.TableCellHeadStyle}>
                                                                                READ NOTIFICATION
                                                                            </TableCell>
                                                                            <TableCell className={classes.TableCellHeadStyle}>
                                                                                HIDE NOTIFICATION
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {mapped ?
                                                                            GeneralNotification.map(notification => {
                                                                                let CreatedDate = moment(notification?.createdDate).format(state.GlobalUtils?.settingValue)
                                                                                return (
                                                                                    <React.Fragment>
                                                                                        <TableRow>
                                                                                            <TableCell className={classes.tablecellPadding} >
                                                                                                <span className={classes.paymentText}>{CreatedDate}</span>
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.tablecellPadding}>
                                                                                                <span className={classes.paymentText}>{notification?.extraData_3}</span>
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.tablecellPadding}>
                                                                                                <ReadNotification Notify={notification} onRead={() => { getNotificationsAsync(2) }} />
                                                                                            </TableCell>
                                                                                            <TableCell className={classes.tablecellPadding}>
                                                                                                <HideNotification Notify={notification} onHide={() => { getNotificationsAsync(2) }} />
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    </React.Fragment>
                                                                                )
                                                                            }) : null
                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                                {!mapped && ProgressBar === false ?
                                                                    <Typography variant="subtitle2" gutterBottom className={classes.ErrorMessageStyle}>
                                                                        No Notifications to display...
                                                                    </Typography>
                                                                    : null}
                                                            </TableContainer>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                                : ActiveNotificationTab === 9 ?
                                                    <TrustStatementFiles onFileUpdate={handleNotificationCount} />
                                                    : null
                    }

                    {ActiveNotificationTab != 9 ?
                        <Grid container spacing={0} className={classes.paginationStyle}>
                            <Box m={0} width="60%">
                                <div className="footer">
                                    <Footer />
                                </div>
                            </Box>
                            <Box m={0} width="40%">
                                <div className="pagination">
                                    <TablePagination
                                        rowsPerPageOptions={[10, 50, 100]}
                                        count={totalCount}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        className={classes.notifyTablePagination}
                                    />
                                </div>
                            </Box>
                        </Grid>
                        :
                        <Grid container spacing={0} className={classes.paginationStyle} style={{ padding: "14px" }}>
                            <Footer />
                        </Grid>
                    }

                </Grid >
            </div >
            <Snackbar open={open} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" className="alertStyle">
                    Filter Successfully Triggered!
                </Alert>
            </Snackbar>
            <Snackbar open={clearFilterOpen} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" className="alertStyle">
                    Filter Cleared Successfully !
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}