export const Columns = [
    { field: 'Client', type: 'string', width: 100, id: 'dbT_CLIENT', sortable: true },
    { field: 'Client Name', type: 'string', width: 100, id: 'CLT_NAME_1', sortable: true },
    { field: 'Agency ID', type: 'string', width: 100, id: 'dbT_NO', sortable: true },
    { field: 'Account ID', type: 'string', width: 100, id: 'dbT_CLNT_ACNT1', sortable: true },
    { field: 'Account ID2', type: 'string', width: 100, id: 'dbT_CLNT_ACNT2', sortable: true },
    { field: 'Old Account ID', type: 'string', width: 100, id: 'DBT_FLD_24', sortable: true },
    { field: 'Name', type: 'string', width: 150, id: 'dbT_NAME', sortable: true },
    { field: 'Address', type: 'string', width: 250, id: 'deM_ADDR1', sortable: true },
    { field: 'Phone Number', type: 'string', width: 70, id: 'deM_PHONE1', sortable: true },
    // { field: 'Principal Due', type: 'string', width: 200, id: 'dbT_PRINCIPAL_DUE', sortable: true },
    // { field: 'Fee', type: 'string', width: 100, id: 'dbT_MISC1_DUE', sortable: true },
    { field: 'Total Paid', type: 'string', width: 120, id: 'dbT_PRINCIPAL_RECVD', sortable: true },
    { field: 'Total Due', type: 'string', width: 120, id: 'dbT_PRINCIPAL_DUE', sortable: true },
    { field: 'Status', type: 'string', width: 200, id: 'stS_DESC', sortable: true },
    { field: 'Delinquency Date', type: 'datetime', width: 100, id: 'dbT_LAST_CHG_DATE', sortable: true, align: "center" },
    { field: 'Referral Date', type: 'datetime', width: 100, id: 'dbT_REFERRAL_DATE', sortable: true },
    { field: 'Service', type: 'string', width: 120, id: 'dbT_COMPANY', sortable: true },
  ]