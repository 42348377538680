import { Backdrop, Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
// Icons
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import BorderColorIcon from '@material-ui/icons/BorderColor';
// Models
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './ManageGroupCss';
import { usePost } from '../../../utils/apiHelper';
import { IActivityGroupName } from '../../../models/AgentTemplate/IAgentCustomizeControl';
import { PuffLoader } from 'react-spinners';
import { regexGroupName } from '../../../constants/Constant';

const EditManageGroup: React.FC<{ row: IActivityGroupName, onUpdate: () => void }> = (props) => {
    const classes = useStyles();
    const { row, onUpdate } = props;
    const [open, setOpen] = useState<boolean>(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [agentGroupNameError, setAgentGroupNameError] = useState<string>("");

    let getActivityGroupName: IActivityGroupName = {
        activityGroupId: row?.activityGroupId,
        activityGroupName: row?.activityGroupName,
        modifiedDate: row?.modifiedDate
    }

    const [localState, setLocalState] = useState(getActivityGroupName);

    const handleUpdate = (e) => {
        let name = e.target.name;
        setLocalState({
            ...localState,
            [name]: e.target.value
        });
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickGroupUpdate = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "activityGroupId": localState?.activityGroupId,
                "activityGroupName": localState?.activityGroupName,
            };
            await usePost<any>('AgentUser/InsertAgentActivityGroup', request);
            onUpdate();
            setOpen(false);
        })().finally(() => {
            setShowProgressBar(false);
        })
    }

    const handleChangeNameValidation = (e) => {
        const GroupName = e.target.value;
        if (GroupName.match(regexGroupName)) {
            setAgentGroupNameError("");
        } else if (GroupName === "") {
            setAgentGroupNameError("Group Name is Required");
        } else {
            setAgentGroupNameError("Please enter valid group name");
        }
    };

    return (
        <React.Fragment>
            <Button id="CreateTemplate_btn" size="small" startIcon={<BorderColorIcon />} variant="contained" color="primary" onClick={handleClickOpen} className={classes.btnManTemp1}>
                Edit
            </Button>

            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} maxWidth={'sm'} TransitionComponent={Transition}
                onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        EDIT GROUP DETAILS
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Backdrop className={classes.backdrop} open={ProgressBar}>
                        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <Grid container spacing={0}>
                        <Grid xs={12}>
                            <Typography variant='subtitle1' className={classes.activityName}>
                                <b>Group Name</b>
                            </Typography>
                        </Grid>
                        <Grid xs={12}>
                            <TextField id="outlined-basic"
                                label="Group Name" variant="outlined"
                                size='small' name="activityGroupName"
                                value={localState?.activityGroupName}
                                error={!!agentGroupNameError}
                                helperText={agentGroupNameError}
                                onChange={(e) => { handleUpdate(e); handleChangeNameValidation(e); }}
                                className={classes.groupNameTextfield} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id="CPO_OK_btn" size="small" color="primary" className={classes.createGroup} startIcon={<SaveIcon />} autoFocus onClick={handleClickGroupUpdate}>
                        Update Group
                    </Button>
                    <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.cancelGroup} startIcon={<CancelIcon />} autoFocus onClick={handleClose} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

export default EditManageGroup