import DateFnsUtils from '@date-io/date-fns';
import {
    Backdrop, Box, Button, Card, CardContent, Grid, IconButton, makeStyles, Paper, Snackbar,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Badge, styled, tableCellClasses } from "@mui/material";
import TablePagination from '@mui/material/TablePagination';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
// Icons
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// Components
import Footer from '../../components/Footer/Footer';
import AvatarTooltip from '../../components/GlobalStyles/AvatarTooltip';
import VideoPlayer from '../../components/YouTubePlayer/VideoPlayer';
// Models
import { notificationVideo, title } from '../../constants/Constant';
import { INotificationCenter } from "../../models/NotificationCenter/INotificationCenter";
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
// Image Icons
import clearIcon from '../../images/buttonIcons/clearIcon.svg';
import BackupDocument from '../../images/Icons/BackupFile.svg';
import DisputeValidations from '../../images/Icons/DisputeValidations.svg';
import PaymentVerifications from '../../images/Icons/PaymentVerifications.svg';
import { IRollClients } from '../../models/Roll/IRollClients';
import AgentPvExpandableRow from '../../components/AgentNotificationCenter/AgentPaymentVerification/AgentPVNewRequest';
import AgentBackupDocumentDialog from '../../components/AgentNotificationCenter/AgentBackupDocument/AgentBackupDocumentDialog';
import AgentDvExpandableRow from '../../components/AgentNotificationCenter/AgentDisputeValidation/AgentDVRequest';

const useStyles = makeStyles((theme) => ({
    buttons: {
        borderRadius: 20,
        background: "#007FFF",
        marginTop: '12px',
        color: 'white',
        fontSize: 12,
        '&:hover': {
            background: "#007FFF",
            color: 'white',
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    countButton: {
        borderRadius: 20,
        background: "#007FFF",
        marginTop: '12px',
        color: 'white',
        fontSize: 12,
        '&:hover': {
            background: "#007FFF",
            color: 'white',
        }
    },
    details: {
        alignItems: "center",
        padding: '0px 16px',
        marginBottom: '5px',
    },
    card: {
        borderRadius: '5px',
        backgroundColor: 'white',
        width: '100%',
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        border: '1px solid black',
        maxHeight: 460,
        minHeight: 460,
        overflow: 'auto hidden',
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 235px)',
            minHeight: 'calc( 100vh - 235px)',
        },
        '& .MuiCardContent-root': {
            padding: '16px 15px 24px',
        },
    },
    grid: {
        padding: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    maingrid: {
        backgroundColor: 'white',
        borderRadius: '5px',
        border: '1px solid gray',
        '& .MuiButton-label': {
            fontSize: 12,
        },
    },
    Center: {
        justifyContent: "center",
        alignItems: "center"
    },
    Title: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginTop: '10px',
    },
    textField1: {
        width: '100%',
        margin: '7px 0px',
        '& .MuiInputLabel-root': {
            fontSize: 14
        },
        '& .MuiInputBase-input': {
            fontSize: 14
        },
        '& .MuiIconButton-root': {
            padding: '0px'
        }
    },
    buttonStyle: {
        marginLeft: '0px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    /* Side Bar Style */
    sidebar: {
        width: "17rem",
        backgroundColor: 'white',
        transition: "all 0.2s ease",
        display: 'block',
        whiteSpace: 'nowrap',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        height: "calc(100vh - 100px)",
        borderLeft: '10px solid White',
        borderRight: '10px solid White'
    },
    sidebarCollapsed: {
        borderTopColor: 'white',
        width: "5rem",
        height: "calc(100vh - 100px)",
        backgroundColor: 'white',
        transition: "all 0.2s ease",
        display: 'block',
        whiteSpace: 'nowrap',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        borderLeft: '7px solid White',
        borderRight: '10px solid White'
    },
    sidebarHeader: {
        display: 'flex',
        padding: '5px 5px',
        '& .MuiSvgIcon-root': {
            fontSize: 20
        },
    },
    sidebarItem: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '5px',
        transition: '0.2s',
        cursor: 'pointer',
        padding: '8px 10px',
        marginTop: '4px',
        height: 'auto',
        '&:hover': {
            backgroundColor: '#c8facd',
            color: '#00ab55'
        }
    },
    textpropertyOut: {
        color: 'black',
        paddingLeft: '7px',
        cursor: 'pointer',
        marginLeft: '15px',
        transitionDelay: '0.2s',
        fontSize: 16
    },
    textProperty: {
        visibility: 'hidden',
    },
    arrowIcon: {
        color: 'white'
    },
    IconStyle: {
        height: '17px',
        width: '17px'
    },
    MessageStyle: {
        width: '70%',
        marginTop: '15px',
        marginLeft: '40px',
    },
    ErrorMessageStyle: {
        color: "red",
        fontSize: 16
    },
    TitleStyle: {
        color: "white",
        textAlign: "left",
        fontSize: 16,
        height: 32,
        marginTop: '5px',
        marginLeft: '10px',
    },
    gridstyle: {
        marginTop: '5px',
    },
    paper: {
        border: '2px solid black'
    },
    GridStyle: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    paginationStyle: {
        backgroundColor: 'white',
        boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        borderRadius: '5px',
        marginTop: '5px'
    },
    footer: {
        marginTop: '15px',
        float: 'right',
    },
    contentGrid: {
        padding: '10px 0px 0px',
    },

    // This ScrollBars minWidth Need to be changed because % not be exceed 100
    scrollbars: {
        '@media (min-width: 1180px) and (max-width: 1267px)': {
            minWidth: '110%',
        },
        '@media (min-width: 1100px) and (max-width: 1180px)': {
            minWidth: '120%',
        },
        '@media (min-width: 950px) and (max-width: 1100px)': {
            minWidth: '140%',
        },
        '@media (min-width: 700px) and (max-width: 950px)': {
            minWidth: '200%',
        },
        '@media (min-width: 500px) and (max-width: 700px)': {
            minWidth: '250%',
        },
        '@media (max-width: 500px)': {
            minWidth: '350%',
        },
    },
    doubleArrowColor: {
        color: 'black',
    },
    doubleArrowRotate: {
        transform: 'rotateY(180deg)'
    },
    filterBtnStyle: {
        width: '20px',
        fontSize: 12
    },
    errorMsg: {
        color: 'red',
        fontSize: 12,
        marginTop: '0px',
        fontWeight: 'bold'
    },
    hideNotifySpacing: {
    },
    flexDirection: {
        flexDirection: 'row',
        padding: '0px 8px',
    },
    boxBorderBottom: {
        borderBottom: '1px solid black',
    },
    boxBorderBottom1: {
        borderBottom: '2px solid #00000021'
    },
    summaryHeight: {
        height: "10%",
        margin: '0px',
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '10px 0px 0px',
        },
        '& .MuiAccordionSummary-content': {
            margin: '10px 0px',
        },
        '& .MuiAccordion-root.Mui-expanded': {
            margin: '1px 0px',
        }
    },
    expandIconStyle: {
        color: '#215176',
        fontSize: 30,
    },
    boxFloatRight: {
        float: 'right',
    },
    readNotify: {
        marginTop: '8px',
    },
    ListIconStyle: {
        borderRadius: '10px',
        height: '38px',
    },
    boxStyle: {
        borderBottom: '1px solid black',
        borderRadius: '12px 12px 0px 0px',
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "#007fff",
        opacity: '0.9',
    },
    mainBoxStyle: {
        borderRadius: '14px',
        border: '1px solid black',
        marginTop: '11px'
    },
    TitleStyleBorder: {
        color: "black",
        textAlign: "left",
        borderBottom: "1px solid",
        fontSize: 16,
    },
    notifyCenterFile: {
        '& .MuiAccordionSummary-content': {
            margin: '5px 0px',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '5px 0px',
        },
    },
    notifyCenterPvDv: {
        '& .MuiTableCell-root': {
            padding: '10px',
        }
    },
    fileTextStyle: {
        fontSize: 13
    },
    fileTextStyle1: {
        fontSize: 13
    },
    innerScroll: {
        borderRadius: '16px',
    },
    lastborderScroll: {
        '& .MuiBox-root:last-child': {
            borderBottom: 'none',
        },
        '& .MuiTableRow-root:last-child .MuiTableCell-body': {
            borderBottom: 'none',
        },
    },
    allNotification: {
        '& .MuiTableRow-root': {
            border: 'none',
        }
    },
    notifyTablePagination: {
        display: 'block !important',
    },
    activeSidebar: {
        backgroundColor: '#c8facd',
        borderRadius: '5px'
    },
    activeSidebarText: {
        color: '#00ab55'
    },
    actions: {
        maxHeight: 500,
        minHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 270px)',
            minHeight: 'calc( 100vh - 270px)',
        }
    },
    settlementBody: {
        maxHeight: 500,
        minHeight: 500,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 300px)',
            minHeight: 'calc( 100vh - 300px)',
        }
    },
    tableHeaderStyle: {
        color: "white",
        fontSize: "16px",
        overflow: 'wrap',
        whiteSpace: 'pre-wrap',
        background: "#007FFF",
    },
    totalCount: {
        fontSize: 14,
        marginTop: '15px',
        '@media (min-width: 600px) and (max-width: 660px)': {
            fontSize: 12,
        }
    },
    totalCountButton: {
        borderRadius: '100%',
        width: '30px',
        height: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        background: "#215176",
        marginTop: '12px',
        fontSize: 12,
        color: "white",
        '&:hover': {
            background: "#215176",
        }
    },
    flexGrid: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiBox-root': {
            padding: '5px',
        },
    },
    tablecellPadding: {
        padding: '3px 0px 3px 10px'
    },
    paymentText: {
        fontSize: '13px'
    },
    videoIcon: {
        '& .MuiBox-root': {
            margin: '0px',
        }
    },
    TableCellHeadStyle: {
        background: "#007FFF",
        color: "white",
        fontSize: 12,
        padding: '3px 0px 3px 10px'
    },
    root: {
        fontSize: 11,
        textOverflow: 'ellipsis',
    },
    rootHidden: {
        display: "none"
    },
    IconCount: {
        borderRadius: '20px',
        backgroundColor: '#007FFF',
        marginLeft: '5px',
        fontSize: '12px',
        color: 'white',
        height: '11px',
        width: '11px'
    },
    iconCountProprty: {
        visibility: 'hidden',
    }
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        background: "#007FFF",
        color: theme.palette.common.white,
        fontSize: 12,
        padding: '2px 5px',
        textTransform: 'uppercase',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: 0
    }
}));

export default function AgentNotificationCenter() {
    const classes = useStyles();
    const popoverClasses = useStyles();
    const { state } = useGlobalState();

    const [PaymentVerification, setPaymentVerification] = useState<INotificationCenter[]>([]);
    const [BackUpDocumentReq, setBackUpDocumentReq] = useState<INotificationCenter[]>([]);
    const [DisValidation, setDisValidation] = useState<INotificationCenter[]>([]);

    const [ActiveNotificationTab, setActiveNotificationTab] = useState(5);
    const [selected, setSelected] = React.useState<string>();
    const [StartDate, setStartDate] = useState(new Date());
    const [EndDate, setEndDate] = useState(new Date());
    const [selectedFirstDate, setSelectedFirstDate] = useState(false);
    const [clearFilterOpen, setClearFilterOpen] = useState(false);
    const [selectedEndDate, setSelectedEndDate] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [ShowHidden, setShowHidden] = useState(false);
    const [fileExists, setFileExists] = useState(false);
    const [isExpanded, setIsEpanded] = useState(true);
    const [istoggle, setIsToggle] = useState(false);
    const [mapped, setmapped] = useState(false);
    const [open, setOpen] = useState(false);

    const [CheckInvited, setCheckInvited] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState<any>('desc');
    const [orderBy, setOrderBy] = useState<string>("");

    const prevDBTNo = useRef("");
    const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy" : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";
    const [paymentCount, setPaymentCount] = useState<number>(0);
    const [backupCount, setBackupCount] = useState<number>(0);
    const [disputeCount, setDisputeCount] = useState<number>(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setClearFilterOpen(false);
        setFileExists(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleRowClick = (accountId: string) => {
        setSelected(selected == accountId ? "" : accountId);
        prevDBTNo.current = accountId;
    }

    useEffect(() => {
        (async () => {
            setShowProgressBar(true);
            let request = {
                "agentUserId": state?.AgentUserAccessContext?.agentUserId,
                "clientDetails": state?.AgentUserNotificationClientCodeDetails
            };
            await usePost<any>("Account/GetAgentDashboardCount", request).then((r) => {
                setPaymentCount(r?.data?.paymentCount?.paymentVerification);
                setBackupCount(r?.data?.backupCount?.backupDocumentation);
                setDisputeCount(r?.data?.disputeCount?.disputeValidation);
            })
        })().finally(() => {
            setShowProgressBar(false);
        })
    }, [])

    useEffect(() => {
        { !istoggle ? getNotificationsAsync(ActiveNotificationTab) : null }
    }, [rowsPerPage, page]);

    async function getNotificationsAsync(NotificationTypeId: number) {
        let ClientCodeList: IRollClients[] = [];
        if (state?.AgentUserNotificationClientCodeDetails?.length) {
            state?.AgentUserNotificationClientCodeDetails?.forEach((x, index) => {
                let ClientDetail: IRollClients = { clientCodes: x?.clientCode };
                ClientCodeList?.push(ClientDetail);
            });
        }
        setShowProgressBar(true);
        setIsToggle(false);
        setActiveNotificationTab(NotificationTypeId);
        let request = {
            "clients": ClientCodeList,
            "isShowHidden": false,
            "rowsPerPage": rowsPerPage,
            "agentUserId": state?.AgentUserAccessContext?.agentUserId,
            "startDate": (selectedFirstDate && StartDate === null ? '' : selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : ''),
            "endDate": (selectedEndDate && EndDate === null ? '' : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : ''),
            "notificationTypeId": NotificationTypeId === undefined ? null : NotificationTypeId === 0 ? null : NotificationTypeId,
            "recordNumber": (page * rowsPerPage) + 1
        };
        await usePost<{ "notificationCenter": INotificationCenter[], total: number }>("AgentUserNotification/GetAgentUserNotifications", request).then((r) => {
            setPaymentVerification(r?.data["notificationCenter"].filter(notification => notification?.notificationTypeId == 5));
            setBackUpDocumentReq(r?.data["notificationCenter"].filter(notification => notification?.notificationTypeId == 6));
            setDisValidation(r?.data["notificationCenter"].filter(notification => notification?.notificationTypeId == 7));
            setmapped(r?.data["notificationCenter"].length > 0 ? true : false);
            setTotalCount(r?.data?.total);
        }).finally(() => {
            setShowProgressBar(false);
        });
    }

    async function getUnreadNotificationsAsync(NotificationTypeId: number) {
        let request = {
            "clients": state?.AgentUserNotificationClientCodeDetails,
            "isShowHidden": false,
            "rowsPerPage": rowsPerPage,
            "agentUserId": state?.AgentUserAccessContext?.agentUserId,
            "startDate": null ? moment(StartDate).format('YYYY-MM-DD') : '',
            "endDate": null ? moment(EndDate).format('YYYY-MM-DD') : '',
            "notificationTypeId": NotificationTypeId === undefined ? null : NotificationTypeId === 0 ? null : NotificationTypeId,
            "recordNumber": (page * rowsPerPage) + 1
        };
        await usePost<{ "notificationCenter": INotificationCenter[], total: number }>("AgentUserNotification/GetAgentUserNotifications", request).then((r) => {
            setPaymentVerification(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 5));
            setBackUpDocumentReq(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 6));
            setDisValidation(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 7));
            setmapped(r?.data["notificationCenter"].length > 0 ? true : false);
            setTotalCount(r?.data?.total);
            setOpen(true);
        }).finally(() => {
            setIsToggle(false);
            setShowProgressBar(false);
        });
    }

    async function clearFilter(NotificationTypeId: number) {
        let request = {
            "clients": state?.AgentUserNotificationClientCodeDetails,
            "isShowHidden": false,
            "rowsPerPage": rowsPerPage,
            "agentUserId": state?.AgentUserAccessContext?.agentUserId,
            "startDate": '',
            "endDate": '',
            "notificationTypeId": NotificationTypeId === undefined ? null : NotificationTypeId === 0 ? null : NotificationTypeId,
            "recordNumber": (page * rowsPerPage) + 1
        };
        await usePost<{ "notificationCenter": INotificationCenter[], total: number }>("AgentUserNotification/GetAgentUserNotifications", request).then((r) => {
            setPaymentVerification(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 5));
            setBackUpDocumentReq(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 6));
            setDisValidation(r?.data["notificationCenter"].filter(notification => notification.notificationTypeId == 7));
            setmapped(r?.data["notificationCenter"].length > 0 ? true : false);
            setTotalCount(r?.data?.total);
        }).finally(() => {
            setIsToggle(false);
            setShowProgressBar(false);
            setClearFilterOpen(true);
            setStartDate(null);
            setEndDate(null);
            setSelectedEndDate(false);
            setSelectedFirstDate(false);
        });
    }

    function base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    function createAndDownloadBlobFile(body, filename) {
        const blob = new Blob([body]);
        const fileName = `${filename}`;
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const handleFilter = () => {
        getNotificationsAsync(ActiveNotificationTab);
        setPage(0);
        setShowProgressBar(true);
        setOpen(true);
    };

    const handleClearFilter = () => {
        (ShowHidden ? null : clearFilter(ActiveNotificationTab))
        setPage(0);
        setShowProgressBar(true);
        setStartDate(null);
        setEndDate(null);
    };

    const handleFirstDateChange = (date: Date | null) => {
        setStartDate(date);
        setSelectedFirstDate(true);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
        setSelectedEndDate(true);
    };

    function handleToggle(event) {
        if (!istoggle) {
            setShowHidden(true);
            setShowProgressBar(true);
        } else {
            setShowHidden(false);
            setShowProgressBar(true);
            getUnreadNotificationsAsync(ActiveNotificationTab);
        }
    }

    const handleToggler = () => {
        setIsEpanded(s => !s);
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { id: "agencyID", disablePadding: false, label: " AGENCY ID ", sortable: true, alignItems: 'center' },
            { id: "account_Id", disablePadding: false, label: "ACCOUNT ID", sortable: true, alignItems: 'center' },
            { id: "client_code", disablePadding: false, label: "CLIENT CODE", sortable: true, alignItems: 'center' },
            { id: "client_name", disablePadding: false, label: "CLIENT NAME", sortable: true, alignItems: 'center' },
            { id: "name", disablePadding: false, label: "NAME", sortable: true, alignItems: 'center' },
            { id: "last_check", disablePadding: false, label: "Last 4 of CC or Check", sortable: true, alignItems: 'center' },
            { id: "payment_amount", disablePadding: false, label: "Payment Amount", sortable: true, alignItems: 'center' },
            { id: "payment_date", disablePadding: false, label: "Payment Date", sortable: true, alignItems: 'center' },
            { id: "create_Date", disablePadding: false, label: "CREATED DATE", sortable: true, alignItems: 'center' },
            { id: "action", disablePadding: false, label: "ACTIONS", sortable: false },
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <StyledTableCell
                            key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.TableCellHeadStyle}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                                style={{ alignItems: `${headCell.alignItems}` }}
                            >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    function EnhancedTableHeadBackUpDocument(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { id: "agencyID", disablePadding: false, label: " AGENCY ID", sortable: true },
            { id: "account_Id", disablePadding: false, label: " ACCOUNT NUMBER", sortable: true },
            { id: "client_code", disablePadding: false, label: "CLIENT CODE", sortable: true },
            { id: "client_name", disablePadding: false, label: " CLIENT NAME", sortable: true },
            { id: "create_Date", disablePadding: false, label: "CREATED DATE", sortable: true },
            { id: "type", disablePadding: false, label: "TYPE", sortable: true },
            { id: "action", disablePadding: false, label: "ACTIONS", sortable: false },
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.TableCellHeadStyle}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    function EnhancedTableDisputeValidation(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { id: "agencyID", disablePadding: false, label: " AGENCY ID", sortable: true },
            { id: "account_Id", disablePadding: false, label: " ACCOUNT NUMBER", sortable: true },
            { id: "client_code", disablePadding: false, label: "CLIENT CODE", sortable: true },
            { id: "client_name", disablePadding: false, label: " CLIENT NAME", sortable: true },
            { id: "create_Date", disablePadding: false, label: "CREATED DATE", sortable: true },
            { id: "fileName", disablePadding: false, label: "FILE NAME", sortable: false },
            { id: "action", disablePadding: false, label: "ACTIONS", sortable: false },
            { id: "expand", disablePadding: false, label: "EXPAND", sortable: false },
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.TableCellHeadStyle}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <Snackbar open={fileExists} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={() => { setFileExists(false) }} severity="error" className="alertStyle">
                    File does not exists!
                </Alert>
            </Snackbar>
            <div style={{ display: 'flex' }}>
                <div className={`${isExpanded ? classes.sidebar : classes.sidebarCollapsed}`}>
                    <div className={classes.sidebarHeader}>
                        <IconButton size="medium" onClick={handleToggler}>
                            {
                                isExpanded ?
                                    <DoubleArrowIcon fontSize="inherit" className={`${classes.doubleArrowColor} ${classes.doubleArrowRotate}`} />
                                    :
                                    <DoubleArrowIcon fontSize="inherit" className={classes.doubleArrowColor} />
                            }
                        </IconButton>
                    </div>

                    {state?.AgentUserAccessContext?.paymentVerification ?
                        <div className={`${classes.sidebarItem} ${ActiveNotificationTab === 5 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(5) }}>
                            {isExpanded ?
                                <AvatarTooltip title="Payment Verification">
                                    <img src={PaymentVerifications} alt="Icon for payment verification" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(5) }} />
                                </AvatarTooltip>
                                : (<>
                                    <Badge badgeContent={paymentCount} color="primary">
                                        <AvatarTooltip title="Payment Verification">
                                            <img src={PaymentVerifications} alt="Icon for payment verification" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(5) }} />
                                        </AvatarTooltip>
                                    </Badge>
                                </>)
                            }
                            {isExpanded ?
                                <Badge badgeContent={paymentCount} color="primary">
                                    <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty} ${ActiveNotificationTab === 5 ? classes.activeSidebarText : null}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(5) }}>
                                        Payment Verification
                                    </Typography>
                                </Badge>
                                : null}
                        </div>
                        :
                        null
                    }

                    {state.AgentUserAccessContext?.backupDocument ?
                        <div className={`${classes.sidebarItem} ${ActiveNotificationTab === 6 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(6) }}>
                            {isExpanded ?
                                <AvatarTooltip title="Backup Documentation">
                                    <img src={BackupDocument} alt="Icon for Backup Documentation" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(6) }} />
                                </AvatarTooltip>
                                : (<>
                                    <Badge badgeContent={backupCount} color="primary">
                                        <AvatarTooltip title="Backup Documentation">
                                            <img src={BackupDocument} alt="Icon for Backup Documentation" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(6) }} />
                                        </AvatarTooltip>
                                    </Badge>
                                </>)
                            }
                            {isExpanded ?
                                <Badge badgeContent={backupCount} color="primary">
                                    <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty} ${ActiveNotificationTab === 6 ? classes.activeSidebarText : null}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(6) }}>
                                        Backup Documentation
                                    </Typography>
                                </Badge>
                                : null}
                        </div>
                        :
                        null
                    }

                    {state?.AgentUserAccessContext?.disputeValidation ?
                        <div className={`${classes.sidebarItem} ${ActiveNotificationTab === 7 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(7) }}>
                            {isExpanded ?
                                <AvatarTooltip title="Dispute Validation">
                                    <img src={DisputeValidations} alt="Icon for Dispute Validations" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(7) }} />
                                </AvatarTooltip>
                                : (<>
                                    <Badge badgeContent={disputeCount} color="primary">
                                        <AvatarTooltip title="Dispute Validation">
                                            <img src={DisputeValidations} alt="Icon for Dispute Validations" width="32px" height="32px" onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(7) }} />
                                        </AvatarTooltip>
                                    </Badge>
                                </>)
                            }
                            {isExpanded ?
                                <Badge badgeContent={disputeCount} color="primary">
                                    <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty} ${ActiveNotificationTab === 7 ? classes.activeSidebarText : null}`} onClick={(_) => { StartDate > EndDate ? null : getNotificationsAsync(7) }}>
                                        Dispute Validation
                                    </Typography>
                                </Badge>
                                : null}
                        </div>
                        :
                        null
                    }
                </div>
                <Grid container className={classes.grid} >
                    <Grid container className={classes.maingrid} justify='space-evenly'>
                        <Grid item xs={6} md={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify="space-around">
                                    <KeyboardDatePicker
                                        label="Start Date"
                                        variant="inline"
                                        size="small"
                                        inputVariant="outlined"
                                        format={DateFormat}
                                        disableToolbar
                                        autoOk={true}
                                        margin="normal"
                                        id="NC_StartDate"
                                        value={StartDate}
                                        className={classes.textField1}
                                        onChange={handleFirstDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disableFuture={true}
                                        PopoverProps={{ classes: popoverClasses }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container justify="space-around">
                                    <KeyboardDatePicker
                                        label="End Date"
                                        variant="inline"
                                        size="small"
                                        inputVariant="outlined"
                                        format={DateFormat}
                                        disableToolbar
                                        autoOk={true}
                                        margin="normal"
                                        className={classes.textField1}
                                        id="NC_StartDate"
                                        value={EndDate}
                                        onChange={handleEndDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        disableFuture={true}
                                        PopoverProps={{ classes: popoverClasses }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} md={2} className={classes.Title}>
                            {StartDate > EndDate ? <Typography variant='body1' className={classes.errorMsg}>End date should be greater than the Start date!</Typography> : null}
                        </Grid>
                        {ActiveNotificationTab != 0 ?
                            <Box className={classes.buttonStyle}>
                                <Button id="Notification_Count" size="small" variant="contained" startIcon={<NotificationsActiveIcon />}
                                    className={classes.countButton}>
                                    Count : {totalCount}
                                </Button>
                            </Box>
                            : null}
                        <Box className={classes.buttonStyle}>
                            <Button id="NoCenter_Filter_Button" className={classes.buttons} disabled={StartDate > EndDate} size="small"
                                onClick={handleFilter} variant="contained" color="primary" startIcon={<FilterAltIcon className={classes.filterBtnStyle} />}>
                                Filter
                            </Button>
                        </Box>
                        <Box className={classes.buttonStyle}>
                            <Button id="NoCenter_Filter_Button" className={classes.buttons} size="small"
                                onClick={handleClearFilter} variant="contained" color="primary" startIcon={<img src={clearIcon} alt="Icon for clear filter button" className={classes.IconStyle} />}>
                                Clear Filter
                            </Button>
                        </Box>
                        <Box className={classes.buttonStyle}>
                            <Button id="NoCenter_ShowHidden_Button" className={classes.buttons} disabled={StartDate > EndDate} size="small"
                                variant="contained" color="primary" onClick={(e) => { handleToggle(e); }} startIcon={istoggle ? <VisibilityIcon /> : <VisibilityOffIcon />}>
                                {istoggle ? "SHOW UNREAD " : "SHOW HIDDEN"}
                            </Button>
                        </Box>
                        <Box className={classes.videoIcon}>
                            <VideoPlayer video={notificationVideo} title={title} />
                        </Box>
                    </Grid>

                    {ActiveNotificationTab === 5 ?
                        <Grid className={`${classes.contentGrid}`} item xs={12}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <TableContainer component={Paper} className={`${classes.actions} ${"scrollbox"} ${"on-scrollbar"}`} >
                                        <Table aria-label="customized table" size="small" stickyHeader>
                                            <EnhancedTableHead
                                                classes={classes}
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={handleRequestSort}
                                            />
                                            <TableBody>
                                                {mapped ?
                                                    stableSort(PaymentVerification, getComparator(order, orderBy)).map(
                                                        (r) => {
                                                            return (
                                                                <AgentPvExpandableRow onClick={() => { handleRowClick(r?.extraData_1?.slice(0, 9)); }} selected={selected === r?.extraData_1?.slice(0, 9)} key={r?.extraData_1?.slice(0, 9)} pv={r} onRefresh={() => { getNotificationsAsync(5) }} selectedDBTNumber={selected} />
                                                            );
                                                        })
                                                    : null
                                                }
                                            </TableBody>
                                        </Table>
                                        {!mapped && ProgressBar === false ?
                                            <Typography variant="subtitle2" gutterBottom className={classes.ErrorMessageStyle}>
                                                No Notifications to display...
                                            </Typography>
                                            : null}
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        </Grid>

                        : ActiveNotificationTab === 6 ?
                            <Grid className={`${classes.contentGrid} notifications-center`} item xs={12}>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <TableContainer component={Paper} className={`${classes.actions} ${"scrollbox"} ${"on-scrollbar"}`} >
                                            <Table aria-label="customized table" size="small" stickyHeader>
                                                <EnhancedTableHeadBackUpDocument
                                                    classes={classes}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                />
                                                <TableBody>
                                                    {mapped ?
                                                        BackUpDocumentReq.map(BackUp => {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell className={classes.tablecellPadding}>
                                                                        <span className={classes.paymentText}>{BackUp?.extraData_1}</span>
                                                                    </TableCell>
                                                                    <TableCell className={classes.tablecellPadding}>
                                                                        <span className={classes.paymentText}>{BackUp?.extraData_3?.substring(0, BackUp?.extraData_3?.lastIndexOf("+"))}</span>
                                                                    </TableCell>
                                                                    <TableCell className={classes.tablecellPadding}>
                                                                        <span className={classes.paymentText}>{BackUp?.clT_Code}</span>
                                                                    </TableCell>
                                                                    <TableCell className={classes.tablecellPadding}>
                                                                        <span className={classes.paymentText}>{BackUp?.clT_Name}  {BackUp?.clT_Name_2}</span>
                                                                    </TableCell>
                                                                    <TableCell className={classes.tablecellPadding}>
                                                                        <span className={classes.paymentText}>  {moment(BackUp?.createdDate).format(state.GlobalUtils?.settingValue)}</span>
                                                                    </TableCell>
                                                                    <TableCell className={classes.tablecellPadding}>
                                                                        {BackUp?.extraData_2}
                                                                    </TableCell>
                                                                    <TableCell className={classes.tablecellPadding}>
                                                                        <span className={classes.paymentText}> <AgentBackupDocumentDialog BackUpInfo={BackUp} onHideAll={() => { getNotificationsAsync(6) }} IsInvited={(CheckInvited !== null && CheckInvited !== 0 && CheckInvited !== undefined) ? true : false} /></span>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                        : null
                                                    }
                                                </TableBody>
                                            </Table>
                                            {!mapped && ProgressBar === false ?
                                                <Typography variant="subtitle2" gutterBottom className={classes.ErrorMessageStyle}>
                                                    No Notifications to display...
                                                </Typography>
                                                : null
                                            }
                                        </TableContainer>
                                    </CardContent >
                                </Card >
                            </Grid >
                            : ActiveNotificationTab === 7 ?
                                <Grid className={`${classes.contentGrid}`} item xs={12}>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <TableContainer component={Paper} className={`${classes.actions} ${"scrollbox"} ${"on-scrollbar"}`} >
                                                <Table aria-label="customized table" size="small" stickyHeader>
                                                    <EnhancedTableDisputeValidation
                                                        classes={classes}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={handleRequestSort}
                                                    />
                                                    <TableBody>
                                                        {mapped ?
                                                            DisValidation.map(r => <AgentDvExpandableRow onClick={() => { handleRowClick(r?.extraData_1); }} selected={selected === r?.extraData_1} key={r?.extraData_1} dv={r} onHideAll={() => { getNotificationsAsync(7) }} selectedDBTNumber={selected} IsInvited={(CheckInvited !== null && CheckInvited !== 0 && CheckInvited !== undefined) ? true : false} />)
                                                            : null
                                                        }
                                                    </TableBody>
                                                </Table>
                                                {!mapped && ProgressBar === false ?
                                                    <Typography variant="subtitle2" gutterBottom className={classes.ErrorMessageStyle}>
                                                        No Notifications to display...
                                                    </Typography>
                                                    : null}
                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                : null
                    }

                    <Grid container spacing={0} className={classes.paginationStyle}>
                        <Box m={0} width="60%">
                            <div className="footer">
                                <Footer />
                            </div>
                        </Box>
                        <Box m={0} width="40%">
                            <div className="pagination">
                                <TablePagination
                                    rowsPerPageOptions={[10, 50, 100]}
                                    count={totalCount}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    className={classes.notifyTablePagination}
                                />
                            </div>
                        </Box>
                    </Grid>
                </Grid >
            </div >
            <Snackbar open={open} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" className="alertStyle">
                    Filter Successfully Triggered!
                </Alert>
            </Snackbar>
            <Snackbar open={clearFilterOpen} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" className="alertStyle">
                    Filter Cleared Successfully !
                </Alert>
            </Snackbar>
        </React.Fragment >
    )
}