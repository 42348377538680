export function base64toBlob(data: string): Blob {
    const base64WithoutPrefix = data.substring('data:image/png,image/jpg,image/jpeg;base64'.length);
    const bytes = window.atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);
    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }
    return new Blob([out], { type: 'image/png, image/jpg, image/jpeg' });
};


export function base64toBlobPDF(data: string): Blob {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substring('data:application/pdf;base64'.length);
    const bytes = window.atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);
    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }
    return new Blob([out], { type: 'application/pdf' });
};