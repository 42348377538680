import {
    Grid, makeStyles, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, TableSortLabel, Typography
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';
import CreateFAQ from "../../components/GlobalAdmin/GA_FAQ/GA_CreateFAQ";
import DeleteFAQ from "../../components/GlobalAdmin/GA_FAQ/GA_DeleteFAQ";
import EditFAQ from "../../components/GlobalAdmin/GA_FAQ/GA_EditFAQ";
import { IListAllFaq } from "../../models/GA_Faq/IListAllFaq";
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from "../../utils/apiHelper";

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: "1cm",
            alignItems: "center"
        },
        actions: {
            width: "100%",
            marginTop: "1cm",
            marginRight: theme.spacing(2)
        },
        grid: {
            padding: '8px'
        },
        root: {
            flexGrow: 1,
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        tableBody: {
            maxHeight: 530,
            minHeight: 530,
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 175px)',
                minHeight: 'calc( 100vh - 175px)',
            },
        },
        footer: {
            marginTop: '15px',
            float: 'right',
            marginRight: '2.5cm'
        },
        titleheader: {
            textAlign: 'left'
        },
        tableheader: {
            background: "#007FFF",
            color: "white",
            fontSize: 12,
            padding: '3px 15px'
        },
        tablecell: {
            fontSize: 13,
            padding: '3px 15px'
        }
    })
);

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
    const {
        classes,
        order,
        orderBy,
        onRequestSort
    } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    const headCells = [
        { id: "id", disablePadding: false, label: "ID" },
        { id: "question", disablePadding: false, label: "NAME" },
        { id: "date_created", disablePadding: false, label: "CREATED DATE"},
        { id: "date_created", disablePadding: false, label: "CREATED TIME" },
        { id: "Actions", disablePadding: false, label: "ACTIONS" },
        { id: "Delete", disablePadding: false, label: "DELETE", sortable: false, order: false }
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        id="GAFAQ_TableHeader"
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableheader}
                    >
                        <TableSortLabel
                            id="GAFAQ_TableSortLable"
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label || headCell.sortable}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function GAFAQ() {
    const classes = useRowStyles()
    const [ListFaq, setListFaq] = useState<IListAllFaq[]>([])
    const { state, dispatch } = useGlobalState()
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState(" ");
    const [ProgressBar, setshowProgressBar] = useState(true);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsUpdate(false);
        setIsDelete(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleDeleteSuccess = () => {
        getFaqAsync();
        setIsDelete(true);
    };

    const handleSave = () => {
        getFaqAsync();
    };
    useEffect(GetFaqs(), [])

    function GetFaqs(): React.EffectCallback {
        return () => {
            getFaqAsync();
        };
    }

    function getFaqAsync() {
        (async () => {
            setshowProgressBar(true);
            dispatch({ type: GlobalStateAction.Busy });
            await useFetch<IListAllFaq[]>("Faq/GetAllFaq").then((ListAllFaq) => {
                setListFaq(ListAllFaq.data);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })();
    }

    function setFaqIDsAsync(listIds) {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "id": listIds
                }
                await usePost<any>("Faq/FaqPositionSet", request);
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })();
    }

    const StyledTableCell = withStyles((theme: Theme) =>
        createStyles({
            head: {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
            },
            body: {
                fontSize: 13,
            },
        }),
    )(TableCell);

    const StyledTableRow = withStyles((theme: Theme) =>
        createStyles({
            root: {
                '&:nth-of-type(odd)': {
                    backgroundColor: theme.palette.action.hover,
                },
            },
        }),
    )(TableRow);

    const reorder = (list, startIndex, endIndex) => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleDrag = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            ListFaq,
            result.source.index,
            result.destination.index
        );
        let faqIds = "";
        for (var i = 0; i < items.length; i++) {
            faqIds += items[i].id.toString();
            faqIds += ",";
        }
        setListFaq(items);
        setFaqIDsAsync(faqIds);
    }

    function getUpdateFaqAsync() {
        setshowProgressBar(true);
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            await useFetch<IListAllFaq[]>("Faq/GetAllFaq").then((GetFaq) => {
                setListFaq(GetFaq.data);
            }).finally(() => {
                setshowProgressBar(false);
                setIsUpdate(true);
            });
        })();
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <CssBaseline />
                <Grid container className={classes.grid} >
                    <Grid item xs={6} sm={4}>
                        <Typography variant="h6" gutterBottom className={`${classes.titleheader} ${"headertitle"}`}>
                            <b> FAQ</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={8}>
                        <CreateFAQ onSave={handleSave} />
                    </Grid>
                    <Grid item xs={12}>
                        <DragDropContext onDragEnd={(result) => { handleDrag(result) }}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        <TableContainer component={Paper} className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
                                            <Table aria-label="customized table" size="small" stickyHeader>
                                                <EnhancedTableHead
                                                    classes={classes}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                />
                                                <TableBody>
                                                    {stableSort(ListFaq, getComparator(order, orderBy)).map((row, index) => {
                                                        return (
                                                            <React.Fragment>
                                                                <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <StyledTableRow key={row.question} ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps} >
                                                                            <StyledTableCell component="th" scope="row" className={classes.tablecell}>
                                                                                {row.id}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell className={classes.tablecell}>
                                                                                {row.question}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell className={classes.tablecell}>
                                                                                {moment(row.date_created).format(state.GlobalUtils?.settingValue)}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell className={classes.tablecell}>
                                                                                {moment(row.date_created).format('HH:mm:ss')}
                                                                            </StyledTableCell>
                                                                            <StyledTableCell className={classes.tablecell}>
                                                                                <EditFAQ faqRow={row} onSave={getUpdateFaqAsync} />
                                                                            </StyledTableCell>
                                                                            <StyledTableCell className={classes.tablecell}>
                                                                                <DeleteFAQ faqRow={row} onDelete={handleDeleteSuccess} />
                                                                            </StyledTableCell>
                                                                        </StyledTableRow>
                                                                    )}
                                                                </Draggable>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>)}
                            </Droppable>
                        </DragDropContext>
                    </Grid>
                    <Footer />
                </Grid>
                <Snackbar open={isUpdate} className="snackBarStyle" anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={4000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                        Faq Updated Successfully!!
                    </Alert>
                </Snackbar>
                <Snackbar open={isDelete} className="snackBarStyle" anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={4000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                        Faq Deleted Successfully!!
                    </Alert>
                </Snackbar>
            </div>
        </React.Fragment>
    )
}