import {
    Backdrop, Box, Button, CircularProgress, createStyles, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableContainer,
    TableHead, TablePagination, TableSortLabel, Theme,
    Toolbar, Typography
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import download from "../../images/files/download01.svg";
import { IAckBuilder } from '../../models/IAckReport/IAckBuilder';
import { IPrintAck } from '../../models/IAckReport/IPrintAck';
import { IRollClients } from '../../models/Roll/IRollClients';
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import Footer from '../Footer/Footer';
import LightTooltip from '../GlobalStyles/LightTooltip';
import { StyledTableCell, StyledTableRow } from '../GlobalStyles/TableStyledCell';
import AckBuilderExpandableRow from './AckBuilderExpandableRow';
import AckPrintView from "./AckPrintView";

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridPadding: {
            padding: '8px'
        },
        tableHeaderStyle: {
            background: "#007FFF",
            color: "white",
            fontSize: 12,
            fontWeight: 'bold'
        },
        tableHeadRadius: {
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px'
        },
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            border: '1px solid gray',
            minHeight: '35px'
        },
        paper: {
            width: '100%',
        },
        titleColor: {
            color: "blue",
            fontWeight: 'bold',
            marginTop: '10px'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        footerBoxStyle: {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
        },
        tablebody: {
            maxHeight: 700,
            minHeight: 700,
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 218px)',
                minHeight: 'calc( 100vh - 218px)',
            },
        },
        noRecordsStyle: {
            color: "red",
            marginTop: "5px",
            fontSize: 18
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1
        },
        textdisplay: {
            color: 'black',
            fontWeight: 'bold',
            textAlign: 'center'
        },
        appBar: {
            position: 'relative',
            color: 'white',
            backgroundColor: '#007fff',
            minHeight: '50px'
        },
        menuButton: {
            marginRight: theme.spacing(1),
            width: 180
        },
        printTitle: {
            color: 'white',
            fontWeight: 'bold',
            fontSize: 20,
            textAlign: 'center'
        },
        CloseIconColor: {
            color: 'white'
        },
        downloadIconStyle: {
            color: "#a1a1a1",
            fontSize: 29,
            marginTop: '5px',
            marginLeft: '12px'
        },
        downloadIconStyle1: {
            color: "#2377E4",
            fontSize: 29,
            marginTop: '5px',
            marginLeft: '12px'
        },
        printStyle: {
            borderRadius: 30,
            marginTop: '5px',
            padding: '2px 2px'
        },
        armlogo: {
            width: 170
        },
        fabProgressPrint: {
            color: green[500],
            position: 'absolute',
            top: 3,
            left: 8,
            zIndex: 1,
        },
        floatTopRight: {
            backgroundColor: 'white',
            top: '15px',
            right: '25px',
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
            height: '70px',
        },
        tableContainerPadding: {
            padding: '15px',
            marginTop: '20px'
        },
        summeryBoxShadow: {
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
        },
        boxStyle: {
            gap: '8%',
            padding: '10px',
            float: 'right',
            backgroundColor: '#316b97',
            color: 'white',
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
            width: '430px',
            height: '220',
            marginTop: '25px'
        },
        sumGap: {
            gap: '8%',
            padding: '10px',
            float: 'right',
            backgroundColor: 'green',
            color: 'white',
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
            width: '450px',
            height: '200px',
            marginTop: '25px'
        },
        sumGap1: {
            gap: '5%',
            marginLeft: '10px',
            borderRadius: 15,
            color: 'black',
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
            width: '500px',
            height: '280px',
            marginTop: 15,
            marginRight: 30,
            flexDirection: 'column'
        },
        sumGap2: {
            gap: '5%',
            fontWeight: 'bold',
            marginTop: '8px',
            fontSize: 27,
            color: 'black'
        },
        sumGap3: {
            gap: '5%',
            fontWeight: 'bold',
            marginTop: '8px',
            fontSize: 19,
            color: 'black'
        },
        sumGap4: {
            gap: '5%',
            marginTop: '8px',
            fontSize: 16,
            color: 'green'
        },
        sumGap5: {
            gap: '5%',
            marginTop: '8px',
            fontSize: 18,
            color: 'black'
        },
        GridStyle: {
            maxHeight: '30%'
        },
        div: {
            flexGrow: 1,
        },
        GridBoxStyle: {
            marginTop: '15px'
        },
        tablebodyPrint: {
            overflowX: 'hidden',
            marginTop: 5

        },
        textStyle: {
            textAlign: 'right',
            marginTop: '20px',
            fontSize: 16
        },
        textStyle1: {
            textAlign: 'right',
            marginTop: '20px',
            marginRight: 10,
            fontSize: 16
        },
        totalRecord: {
            gap: '5%',
            marginRight: '10px',
            backgroundColor: '#316b97',
            borderRadius: 15,
            color: 'white',
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
            width: '360px',
            height: '130px',
            marginTop: 10,
            flexDirection: 'row',
            justifyContent: 'center',
        },
        report: {
            overflowX: 'hidden'
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing(1),
            right: theme.spacing(1),
            marginRight: 20,
        },
        tableCellColor: {
            color: "white",
            fontWeight: 'bold',
            fontSize: '12px !important',
            padding: '5px !important'
        },
        tableCellColor1: {
            fontSize: '12px !important',
            padding: '12px !important'
        },
        wrapper: {
            position: 'relative'
        },
        backbutton: {
            borderRadius: 20,
            fontSize: 11,
            fontWeight: 'bold',
            background: "#007FFF",
            color: "white",
            marginTop: '10px',
            marginRight: '10px'
        },
        bottomFooter: {
            marginTop: '-5px',
            marginBottom: '5px'
        }
    })
);


const AckBuilder = (props) => {
    const classes = useRowStyles();
    const { state } = useGlobalState();

    const [order, setOrder] = useState("DBT_NO");
    const [orderBy, setOrderBy] = useState<string>("DBT_NO");

    const [AckReport, setAckReport] = useState<IAckBuilder[]>([]);
    const [exportAck, setExportAck] = useState<IAckBuilder[]>([]);
    const [TotalRecords, setTotalRecords] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const [page, setPage] = useState<number>(0);

    const [loading, setLoading] = useState(false);

    let client_code: IRollClients[] = props?.location?.state?.client_code;
    let phaseId: number = props?.location?.state?.phaseName;
    let startDate: string = props?.location?.state?.startDate;
    let endDate: string = props?.location?.state?.endDate;

    useEffect(() => {
        setLoading(true);
        (async () => {
            let request = {
                "phaseId": phaseId,
                "rowsPerPage": rowsPerPage,
                "clientCodes": client_code,
                "orderDirection": order === "asc" ? 0 : 1,
                "startDate": moment(startDate).format("YYYY-MM-DD"),
                "endDate": moment(endDate).format("YYYY-MM-DD"),
                "startingRecordNumber": (page * rowsPerPage) + 1,
                "orderBy": orderBy
            }
            await usePost<{ reports: IAckBuilder[], totalRecordCount: number }>("GenerateAckReport", request).then((searchAckReport) => {
                setAckReport(searchAckReport.data["reports"]);
                setTotalRecords(searchAckReport.data.totalRecordCount);
            }).finally(() => {
                setLoading(false);
            });
        })()
    }, [page, order, orderBy, rowsPerPage])

    useEffect(() => {
        setLoading(true);
        (async () => {
            let requestExport = {
                "phaseId": phaseId,
                "clientCodes": client_code,
                "startDate": moment(startDate).format("YYYY-MM-DD"),
                "endDate": moment(endDate).format("YYYY-MM-DD"),
                "orderDirection": order === "asc" ? 0 : 1,
                "orderBy": orderBy
            }
            await usePost<{ ackReports: IAckBuilder[], ackReportSummary: IPrintAck }>("ExportAckReport", requestExport).then((r) => {
                setExportAck(r?.data["ackReports"]);
            }).finally(() => {
                setLoading(false);
            });
        })()
    }, [])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setPage(0);
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const headers = [
        { label: "PHASE", key: "dbT_COMPANY" },
        { label: "PLACEMENT DATE", key: "placemenT_DATE" },
        { label: "CLIENT CODE", key: "dbT_CLIENT" },
        { label: "AGENCY ID", key: "dbT_NO" },
        { label: "ACCOUNT ID", key: "dbT_CLNT_ACNT1" },
        { label: "NAME", key: "dbT_NAME" },
        { label: "DELINQUENCY DATE", key: "dbT_LAST_CHG_DATE" },
        { label: "AMOUNT PLACED", key: "amounT_PLACED" },
        { label: "REFERRAL DATE", key: "dbT_REFERRAL_DATE" },
        { label: "PC REFERRAL DATE", key: "dbT_PC_REFERRAL_DATE" },
        { label: "STATUS", key: "status" },
        { label: "DESCRIPTION", key: "statuS_DESCRIPTION" },
        { label: "STATUS CHANGE DATE", key: "statuS_CHANGE_DATE" },
        { label: "DESK", key: "desk" },
    ]

    const EnhancedTableToolbar = () => {
        const classes = useRowStyles();

        return (
            <Toolbar className={`${classes.root} ${classes.tableHeadRadius}`}>
                <Grid container spacing={0}>
                    <Box p={0} flexShrink={0} width="87%" display="flex" >
                        <Typography variant="h6" className={`${classes.titleColor} ${"headertitle"}`}>
                            {`Acknowledgement Report - ${moment(startDate).format(state?.GlobalUtils?.settingValue)} to ${moment(endDate).format(state?.GlobalUtils?.settingValue)}`}
                        </Typography>
                    </Box>
                    <Box display="flex" width="13%">
                        <div className={classes.wrapper}>
                            <LightTooltip title="Back">
                                <Button id="PLC_Back_btn" className={classes.backbutton}
                                    variant="contained" color="primary" component={Link} to="/report" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />}>
                                    Back
                                </Button>
                            </LightTooltip>
                        </div>
                        {/* <div className={classes.wrapper} style={{ marginTop: '-2px' }}>
                            <AvatarTooltip title="Print Report">
                                <IconButton>
                                    <img src={printImg} alt="Icon for Print" width="24px" height="24px" onClick={(_) => { exportAck.length ? setPrintBox(true) : null }} />
                                </IconButton>
                            </AvatarTooltip>
                        </div> */}

                        <AckPrintView client_code={client_code} startDate={startDate} endDate={endDate} isAvailable={exportAck?.length > 0 ? true : false} phaseId={phaseId} />
                        <div className={classes.wrapper}>
                            <LightTooltip title="Download Reports List">
                                <IconButton disabled={!exportAck.length} className={classes.printStyle}>
                                    <CSVLink
                                        data={exportAck}
                                        headers={headers}
                                        filename={`Acknowledgement Report - ${moment(startDate).format(state?.GlobalUtils?.settingValue)} to ${moment(endDate).format(state?.GlobalUtils?.settingValue)}.csv`}
                                        target="_blank" >
                                        <img src={download} alt="Icon for Download" width="30px" height="30px" className={!exportAck.length ? classes.downloadIconStyle : classes.downloadIconStyle1} />
                                    </CSVLink>
                                    {loading && <CircularProgress size={40} className={classes.fabProgressPrint} />}
                                </IconButton>
                            </LightTooltip>
                        </div>
                    </Box>
                </Grid>
            </Toolbar>
        );
    };

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { id: "dbT_COMPANY", disablePadding: false, label: "PHASE", sortable: true, fontSize: '12px' },
            { id: "placemenT_DATE", disablePadding: false, label: "PLACEMENT DATE", sortable: true, fontSize: '12px' },
            { id: "dbT_CLIENT", disablePadding: false, label: "CLIENT CODE", sortable: true, fontSize: '12px' },
            { id: "dbT_NO", disablePadding: false, label: "AGENCY ID", sortable: true, fontSize: '12px' },
            { id: "dbT_CLNT_ACNT1", disablePadding: false, label: "ACCOUNT ID", sortable: true, fontSize: '12px' },
            { id: "dbT_NAME", disablePadding: false, label: "NAME", sortable: true, fontSize: '12px' },
            { id: "dbT_LAST_CHG_DATE", disablePadding: false, label: "DELINQUENCY DATE", sortable: true, fontSize: '12px' },
            { id: "amount_Placed", disablePadding: false, label: "AMOUNT PLACED", sortable: true, textAlign: 'right', fontSize: '12px' },
            { id: "dbT_REFERRAL_DATE", disablePadding: false, label: "REFERRAL DATE", sortable: true, textAlign: 'right', fontSize: '12px' },
            { id: "dbT_PC_REFERRAL_DATE", disablePadding: false, label: "PC REFERRAL DATE", sortable: true, textAlign: 'right', fontSize: '12px' },
            { id: "status", disablePadding: false, label: "STATUS", sortable: true, textAlign: 'right', fontSize: '12px' },
            { id: "statuS_DESCRIPTION", disablePadding: false, label: "DESCRIPTION", sortable: true, textAlign: 'right', fontSize: '12px' },
            { id: "statuS_CHANGE_DATE", disablePadding: false, label: "STATUS CHANGE DATE", sortable: true, textAlign: 'right', fontSize: '12px' },
            { id: "desk", disablePadding: false, label: "DESK", sortable: true, textAlign: 'right', fontSize: '12px' },
        ];

        return (
            <TableHead>
                <StyledTableRow >
                    {headCells.map(headCell => (
                        <StyledTableCell size="small"
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.tableHeaderStyle}
                            style={{ fontSize: `${headCell.fontSize}` }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id || headCell.sortable ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))
                    }
                </StyledTableRow>
            </TableHead >
        );
    }

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        setPage(newPage);
    };

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={loading}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container className={classes.gridPadding}>
                    <Grid item xs={12} component={Paper}>
                        <EnhancedTableToolbar />
                        <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table id="AckReportsDetailList" aria-label="customized table" size="small" stickyHeader>
                                <EnhancedTableHead
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {AckReport?.map((r) => {
                                        return (
                                            <AckBuilderExpandableRow row={r} />
                                        )
                                    })
                                    }
                                </TableBody>
                            </Table>
                            {!loading && !AckReport.length ? <Typography variant="h6" gutterBottom className={classes.noRecordsStyle}>
                                No records to display...
                            </Typography> : null}
                        </TableContainer>
                        <Grid container spacing={0} className={classes.footerBoxStyle}>
                            <Box m={0} width="60%">
                                <div className="footer">
                                    <Footer />
                                </div>
                            </Box>
                            <Box m={0} width="30%">
                                <div className="pagination">
                                    <TablePagination
                                        id="Inquiry_TablePagination"
                                        rowsPerPageOptions={[50]}
                                        component="div"
                                        count={TotalRecords}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                    />
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment >
    );
}

export default AckBuilder;