
import {
  Box, Button, Grid, IconButton, InputAdornment, makeStyles, Paper,
  TablePagination, TextField, Typography
} from '@material-ui/core';
import {
  styled, Table, TableBody, TableContainer,
  TableHead, TableRow, TableSortLabel
} from '@mui/material';
import * as React from 'react';
import '../App.css';
//Icons
import Backdrop from '@material-ui/core/Backdrop';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@mui/icons-material/Close';
import PuffLoader from "react-spinners/PuffLoader";
import LoadMore from '../images/Icons/LoadMore.svg';
//Models
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useEffect, useRef, useState } from 'react';
import { Columns } from '../models/Inquiry/config';
import { IDebtor } from '../models/Inquiry/IDebtor';
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { usePost } from '../utils/apiHelper';
//Components
import Footer from '../components/Footer/Footer';
import ExpandableRow from '../components/Inquiry/ExpandableRow';
import VideoPlayer from '../components/YouTubePlayer/VideoPlayer';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  searchBox: {
    marginTop: '2px',
    borderRadius: "30px",
    border: '2px solid #215176',
    height: '38px',
    textIndent: '10px',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  div: {
    flexGrow: 1,
    padding: '8px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tableHeader: {
    overflow: 'wrap',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    background: "#007FFF",
  },
  textStyle: {
    marginTop: '5px',
    marginLeft: '5px',
    float: 'left'
  },
  cssFocused: {
    borderColor: 'white',
    borderWidth: '1px',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'white',
  },
  dropDownStyle: {
    marginTop: '2px',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    borderRadius: 5,
    border: '2px solid #215176',
    backgroundColor: 'white',
    height: '38px',
    fontSize: 12,
    width: '100%'
  },
  loadmorebutton: {
    marginTop: '5px',
    borderRadius: 20,
    textIndent: '5px',
    fontSize: 10,
    fontWeight: 'bold',
    marginRight: '40px'
  },
  tablebody: {
    maxHeight: 470,
    minHeight: 470,
    marginTop: '5px',
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 215px)',
      minHeight: 'calc( 100vh - 215px)',
    },
  },
  videoplayer: {
    marginTop: '45px',
    float: 'right'
  },
  videoplayerStyle: {
    marginTop: '9px',
    marginRight: '0px'
  },
  image: {
    width: '17px',
    height: '17px',
    display: 'flex',
  },
  paper1: {
    border: "2px solid black",
    fontSize: 12
  },
  outlinedInput: {
    fontSize: 15,
    color: "black",
    marginTop: '-3px',
    '&$focused $notchedOutline': {
      color: 'white',
      border: '0px'
    },
  },
  outlinedInput1: {
    fontSize: 13,
    color: "black",
    padding: '6px 30px 6px 10px !important',
    '&$focused $notchedOutline': {
      color: 'white',
      border: 'none',
    },
  },
  autoCompleteFont: {
    fontSize: 13,
    padding: '4px 12px'
  },
  notchedOutline1: {
    color: 'white',
    border: '0px'
  },
  notchedOutline2: {
    color: 'white',
    border: 'none',
  },
  IconSizeStyle: {
    height: '10px',
    width: '10px'
  },
  caption: {
    padding: 8,
    fontSize: 12
  },
  footer: {
    marginTop: '15px',
    float: 'right',
    marginRight: '50px'
  },
  norecord: {
    color: "red",
    marginTop: "10px",
    textAlign: 'center'
  },
  fontsize: {
    fontSize: 13
  },
  gridView: {
    // padding: '4px 5px 0px 0px'
    padding: '7px 0px 3px 4px'
  },
  SearchDropdown: {
    marginLeft: '1cm'
  },
  footerStyle: {
    display: 'flex', alignContent: "flex-end", justifyContent: "flex-end", width: "70%"
  },
  paginationStyle: {
    display: 'flex', alignContent: "flex-center", justifyContent: "flex-center", width: "30%"
  }
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: "#007FFF",
    color: theme.palette.common.white,
    padding: '1px 1px',
    fontSize: 10,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
    padding: 0
  }
}));

export default function ComparatorSortingGrid() {
  const { state, dispatch } = useGlobalState();
  const [selected, setSelected] = React.useState<string>();
  const [debtors, setDebtors] = useState<IDebtor[]>([]);
  const [searchDebtor, setSearchDebtor] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(15);
  const [orderBy, setOrderBy] = useState<string>("");
  const [order, setOrder] = useState<any>('asc');
  const [loadAll, setLoadAll] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const userName = state.userAccessContext?.id;
  const video = 'fGytSy2wS-o';
  const title = 'A.R.M. WebView: Searching & Updating Accounts';
  const classes = useStyles();
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [FieldToSearchValue, setFieldToSearchValue] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const prevDBTNo = useRef("");

  const handleRowClick = (accountId: string) => {
    setSelected(selected == accountId ? "" : accountId);
    prevDBTNo.current = accountId;
  }

  const initiateSearch = () => {
    setTriggerSearch(!triggerSearch);
    setshowProgressBar(true);
    setPageNumber(0);
  }

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setPageNumber(0);
    setshowProgressBar(true);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handlePageChange = (event, page) => {
    setPageNumber(page);
    setshowProgressBar(true);
  }

  const cancelSearch = () => {
    setSearchDebtor("");
    setPageNumber(0);
    setshowProgressBar(true);
    clearSearchText();
    setShowSearchIcon(true);
  }
  async function clearSearchText() {
    let request = {
      "userId": userName,
      "searchText": "",
      "searchType": FieldToSearchValue ?? "",
      "orderBy": orderBy,
      "orderDirection": order,
      "rowsPerPage": recordsPerPage,
      "startingRecordNumber": (pageNumber * recordsPerPage) + 1,
      "viewall": loadAll
    }
    dispatch({ type: GlobalStateAction.Busy });
    await usePost<{ debtors: IDebtor[], totalRecordCount: number }>("SearchDebtorInquiry", request).then((searchDebtor) => {
      setDebtors(searchDebtor.data["debtors"]);
      setTotalRecords(searchDebtor.data.totalRecordCount);
    }).finally(() => {
      setshowProgressBar(false);
    });
  }

  useEffect(() => {
    (async () => {
      let request = {
        "userId": userName,
        "searchText": searchDebtor?.trim().split(" ")[0] ?? "",
        "searchType": FieldToSearchValue ?? "",
        "orderBy": orderBy,
        "orderDirection": order,
        "rowsPerPage": recordsPerPage,
        "startingRecordNumber": (pageNumber * recordsPerPage) + 1,
        "viewall": loadAll
      }
      await usePost<{ debtors: IDebtor[], totalRecordCount: number }>("SearchDebtorInquiry", request).then((searchDebtor) => {
        setDebtors(searchDebtor.data["debtors"]);
        setTotalRecords(searchDebtor.data.totalRecordCount);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }, [pageNumber, triggerSearch, loadAll, orderBy, order])

  const FieldToSearch = [
    { title: 'Search All', value: "" },
    { title: 'Client', value: "DBT_CLIENT" },
    { title: 'Agency ID', value: "DBT_NO" },
    { title: 'Account ID', value: "DBT_CLNT_ACNT1" },
    { title: 'Account ID 2', value: "DBT_CLNT_ACNT2" },
    { title: 'Old Account ID', value: "DBT_FLD_24" },
    { title: 'Name', value: "DBT_NAME" },
    { title: 'Email Address', value: "DEM_EMAIL" },
    { title: 'Phone', value: "DEM_PHONE1" },
    { title: 'Tax ID', value: "DEM_TAX_ID" },
    { title: 'Address 1', value: "DEM_ADDR1" },
    { title: 'Address 2', value: "DEM_ADDR2" },
    { title: 'City', value: "DEM_CITY" },
    { title: 'State', value: "DEM_STATE" },
    { title: 'Zip', value: "DEM_ZIP" },
  ];

  const handleChange = (event, selectedvalue) => {
    setFieldToSearchValue(selectedvalue === "" || selectedvalue === null ? FieldToSearch[1].value : selectedvalue.value)
  };

  return (
    <div className={classes.div}>
      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <Grid container spacing={2} className={classes.gridView}>
        <Box component="span" m={0} width="15%" >
          <Typography variant="h5" gutterBottom className={`${classes.textStyle} ${"headertitle"}`}>
            <b>INQUIRY LIST</b>
          </Typography>
        </Box>
        <Box component="span" m={0} width="48%" >
          <TextField
            fullWidth
            size="small"
            id="standard-bare"
            variant="outlined"
            value={searchDebtor}
            className={classes.searchBox}
            type='text'
            placeholder="Search (account number, name, SSN, phone)"
            onChange={e => setSearchDebtor(e.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter' && searchDebtor != "") {
                initiateSearch(); setShowSearchIcon(false);
              } else if (event.key === 'Enter' && searchDebtor === "") {
                initiateSearch(); setShowSearchIcon(true);
              }
            }}
            inputProps={{ maxLength: 255 }}
            InputProps={{
              classes: {
                root: classes.outlinedInput,
                notchedOutline: classes.notchedOutline1,
              },
              endAdornment: (
                <InputAdornment position="end">
                  {showSearchIcon === true ?
                    <IconButton onClick={e => { initiateSearch(); setShowSearchIcon(false); }} style={{ padding: '5px' }}>
                      <SearchIcon />
                    </IconButton>
                    :
                    <IconButton onClick={e => { cancelSearch(); }} style={{ padding: '5px' }}>
                      <CloseIcon />
                    </IconButton>
                  }
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box component="span" m={0} width="20%" >
          <Autocomplete
            size="small"
            id="I_DropDown"
            className={classes.SearchDropdown}
            classes={{
              paper: classes.paper1,
              option: classes.autoCompleteFont
            }}
            options={FieldToSearch}
            defaultValue={FieldToSearch[0]}
            disableClearable={true}
            onChange={handleChange}
            getOptionLabel={(option) => option.title}
            renderInput={(params) =>
              <TextField
                placeholder="Search Fields"
                {...params}
                className={classes.dropDownStyle}
                InputProps={{
                  ...params.InputProps, disableUnderline: true,
                  classes: {
                    root: classes.outlinedInput1
                  }
                }}
              />}
          />
        </Box>
        {state?.userAccessContext?.role === 2 || state?.userAccessContext?.role === 3 ?
          <Box component="span" m={0} width="17%" style={{ float: 'right' }}>
            <Button id="Inquiry_LoadAll_Button" className={classes.loadmorebutton}
              variant="contained" disabled={loadAll} color="primary"
              onClick={(_) => { setLoadAll(true); setshowProgressBar(true); }} >
              <img src={LoadMore} alt="Load_More" className={classes.image} />
              Load More
            </Button>
            <Box className={classes.videoplayerStyle} >
              <VideoPlayer video={video} title={title} />
            </Box>
          </Box>
          : <Box width="17%" className={classes.videoplayer}>
            <VideoPlayer video={video} title={title} />
          </Box>
        }
      </Grid>
      <Paper>
        <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
          <Table sx={{ minWidth: 650 }} aria-label="customized table" size="small" stickyHeader>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                {Columns.map((headCell) => (
                  <StyledTableCell
                    align={headCell.type == "string" ? "left" : "left"}
                    key={headCell.id}
                    style={{ width: `${headCell.width}` }}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.field}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                debtors.map(r => <ExpandableRow onClick={handleRowClick} selected={selected === r.dbT_NO} key={r.dbT_NO} row={r} selectedDBTNumber={selected} searchWord={searchDebtor} />)
              }
            </TableBody>
          </Table>
          {!ProgressBar && !debtors.length ? <Typography variant="h6" gutterBottom className={classes.norecord}>
            No records to display...
          </Typography> : null}
        </TableContainer>
        <Grid container spacing={0}>
          <Grid item xs={6} sm={9} className={classes.footerStyle} >
            <div className={classes.footer}>
              <Footer />
            </div>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.paginationStyle} >
            <TablePagination
              id="Inquiry_TablePagination"
              rowsPerPageOptions={[15]}
              component="div"
              count={totalRecords}
              rowsPerPage={15}
              page={pageNumber}
              onChangePage={handlePageChange}
            />
          </Grid>
        </Grid>
      </Paper>
    </div >
  )
}