import { Box, makeStyles, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { IAgentDebtor } from '../../../models/AgentInquiry/IAgentDebtor';
import { ITransactionHistory } from '../../../models/Inquiry/TransactionHistory/ITransactionHistory';
import { useGlobalState } from '../../../store/GlobalStore';

const useStyles = makeStyles((theme) => ({
    tabs: {
        backgroundColor: theme.palette.primary.main,
        marginRight: "2px",
        marginTop: "0%",
        width: "15%",
    },
    root: {
        border: "2px solid black",
        borderRadius: 8,
        height: "80%",
        marginTop: "7px"
    },
    girdView: {
        color: "blue",
        margin: '0px'
    },
    norecord: {
        color: "red",
        fontSize: 15
    },
}))

const AccountHistory: React.FC<{ debtors: IAgentDebtor, histories: ITransactionHistory[], mapped: boolean }> = (props) => {
    const { histories, mapped } = props;
    const classes = useStyles();
    const { state } = useGlobalState();

    const useProgressStyles = makeStyles((theme) => ({
        root: {
            position: 'relative',
        },
        bottom: {
            color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        top: {
            color: '#1a90ff',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
    }));

    function Progress(props) {
        const classes = useProgressStyles();
        return (
            <div className={classes.root}>
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={30}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={30}
                    thickness={4}
                    {...props}
                />
            </div>
        );
    }

    return (
        <React.Fragment >
            <h4 className={classes.girdView}> Notes</h4>
            <Box p={1} width="100%" flexShrink={0} bgcolor="white" className={classes.root} >
                <Scrollbars autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={10}
                    autoHeight
                    autoHeightMin={140}
                    autoHeightMax={140}
                    style={{ width: "100%" }}>
                    {mapped ?
                        histories.map((Histories) => {
                            if (Histories.activityType !== "0") {
                                return (
                                    <p style={Histories.activityType === "T" ? { color: "#009900", fontSize: 13 } : { color: "black", fontSize: 13 }}>
                                        {moment(Histories.activityDt).format(state.GlobalUtils?.settingValue)}
                                        &nbsp;{Histories.activity?.split('|')[0]}
                                    </p>
                                )
                            } else {
                                return (
                                    <Typography variant="subtitle1" gutterBottom className={classes.norecord}>
                                        <b>No Records to Display</b>
                                    </Typography>
                                )
                            }
                        })
                        : <Progress />}
                </Scrollbars>
            </Box>
        </React.Fragment>
    )
}

export default AccountHistory
