import {
  Backdrop, Box, Button, Grid, Paper, Snackbar, Table, TableBody, TableCell,
  TableContainer, TableHead, TablePagination, TableRow, Typography
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useEffect, useState } from "react";
import PuffLoader from 'react-spinners/PuffLoader';
// models
import { IAllTemplateList } from "../../../models/Admin/ClientPlacements/IAllTemplateList";
import { usePost } from "../../../utils/apiHelper";
// Components
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { Link } from 'react-router-dom';
import Footer from '../../../components/Footer/Footer';
import StyledCell from "../../GlobalStyles/StyledCell";
import { useStyles } from './ClientPlacementOptionsCss';
import CreateNewTemplate from "./CreateNewTemplate";
import DeleteTemplate from "./DeleteTemplate";
import DocumentPDFViewer from './DocumentPreview/DocumentPDFViewer';
import DownloadFiles from './DocumentPreview/DownloadFiles';
import EditTemplate from "./EditTemplate";
import StyledTableRow from '../../GlobalStyles/StyledTableRow';
import { TableSortLabel } from '@mui/material';

const GA_EditTemplate = () => {
  const classes = useStyles();
  const [TemplateList, setTemplateList] = useState<IAllTemplateList[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [orderBy, setOrderBy] = useState(" ");
  const [order, setOrder] = useState("desc");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsUpdate(false);
    setChecked(false);
    setIsDelete(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleChangePage = (event, page) => {
    setShowProgressBar(true);
    setPage(page);
  }

  const handleSave = () => {
    getTemplateAsync();
    setChecked(true);
  };

  const handleUpdate = () => {
    getTemplateAsync();
    setIsUpdate(true);
  };

  const handleDeleteSuccess = () => {
    getTemplateAsync();
    setIsDelete(true);
  };

  useEffect(() => {
    setShowProgressBar(true);
    (async () => {
      let request = {
        "rowsPerPage": rowsPerPage,
        "startingRecordNumber": (page * rowsPerPage) + 1,
        "orderBy": orderBy,
        "orderDirection": order === "desc" ? 1 : 0,
      }
      await usePost<{ templateDetails: IAllTemplateList[], totalCount: number }>("ClientPlacement/GetAllTemplate", request).then((GetAllTemplateList) => {
        setTemplateList(GetAllTemplateList?.data["templateDetails"]);
        setTotalRecords(GetAllTemplateList?.data["totalCount"]);
      }).finally(() => {
        setShowProgressBar(false);
      });
    })().finally(() => {
      setShowProgressBar(false);
    });
  }, [page, rowsPerPage, order, orderBy])

  const getTemplateAsync = () => {
    setShowProgressBar(true);
    (async () => {
      let request = {
        "rowsPerPage": rowsPerPage,
        "startingRecordNumber": (page * rowsPerPage) + 1,
        "orderBy": orderBy,
        "orderDirection": order === "desc" ? 1 : 0,
      }
      await usePost<{ templateDetails: IAllTemplateList[], totalCount: number }>("ClientPlacement/GetAllTemplate", request).then((GetAllTemplateList) => {
        setTemplateList(GetAllTemplateList?.data["templateDetails"]);
        setTotalRecords(GetAllTemplateList?.data["totalCount"]);
      }).finally(() => {
        setShowProgressBar(false);
      });
    })()
  }

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;

    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    const headCells = [
      { id: "name", disablePadding: false, label: "TEMPLATE NAME", sortable: true },
      { id: "imageURI", disablePadding: false, label: "AGENCY LOGO", sortable: false },
      { id: "fileName_PDF", disablePadding: false, label: "PLACEMENT INSTRUCTIONS", sortable: false },
      { id: "pdfURI", disablePadding: false, label: "PDF PREVIEW", sortable: false },
      { id: "action", disablePadding: false, label: "ACTIONS", sortable: false },
      { id: "delete", disablePadding: false, label: "DELETE", sortable: false },
    ];

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell?.id}
              sortDirection={orderBy === headCell?.id ? order : false}
              className={classes.tableCellColor}>
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : "asc"}
                onClick={createSortHandler(headCell?.id)}>
                {headCell?.label || headCell?.sortable}
                {orderBy === headCell?.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.clientTemp}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Grid container className={classes.grid} >
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom className={`${classes.heading} ${"headertitle"}`}>
                <b>CLIENT PLACEMENT OPTIONS</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <CreateNewTemplate onSave={handleSave} />
              <Button id="CreateTemplate_btn" size="small" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />} variant="contained" component={Link}
                to="/clientplacementoptions" color="primary" className={classes.btnManTemp} style={{ marginRight: '15px' }}>BACK</Button>
            </Grid>
          </Grid>
          <Grid component={Paper} item xs={12}>
            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
              <Table aria-label="customized table" size="small" stickyHeader>
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {TemplateList?.map(row => {
                    return (
                      <StyledTableRow>
                        <StyledCell className={classes.tableData} align="left" scope="row">
                          <Typography variant="subtitle1" className={classes.subHeading}>
                            {row?.name}
                          </Typography>
                        </StyledCell>
                        <StyledCell className={classes.tableData} >
                          <DownloadFiles fileNameUri={row?.fileName_Img} fileUrl={row?.imageURI} />
                        </StyledCell>
                        <StyledCell className={classes.tableData} >
                          <DownloadFiles fileNameUri={row?.fileName_PDF} fileUrl={row?.pdfURI} />
                        </StyledCell>
                        <StyledCell className={classes.tableData} align="center">
                          <DocumentPDFViewer row={row} enable={row?.fileName_PDF?.split('.')?.pop() === 'pdf' ? true : row?.fileName_PDF?.split('.')?.pop() === 'PDF' ? true : false} />
                        </StyledCell>
                        <StyledCell className={classes.tableData} align="center">
                          <EditTemplate templateRow={row} TemplateListValue={TemplateList} onUpdate={handleUpdate} />
                        </StyledCell>
                        <StyledCell className={classes.tableData} align="center">
                          <DeleteTemplate templateRow={row} onDelete={handleDeleteSuccess} />
                        </StyledCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container spacing={0}>
              <Box m={0} width="65%">
                <div className="footer">
                  <Footer />
                </div>
              </Box>
              <Box m={0} width="35%">
                <div className="pagination">
                  <TablePagination
                    rowsPerPageOptions={[15]}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Snackbar open={isUpdate} className="snackBarStyle" anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
            Template Updated Successfully!
          </Alert>
        </Snackbar>

        <Snackbar className="snackBarStyle"
          open={checked} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
            Template has been created successfully!
          </Alert>
        </Snackbar>

        <Snackbar className="snackBarStyle" open={isDelete} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
            Template Deleted Successfully.
          </Alert>
        </Snackbar>
      </div>

    </React.Fragment >
  )
}

export default GA_EditTemplate;