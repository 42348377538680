import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {
    Avatar, Backdrop, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,
    IconButton, InputAdornment, Table, TableBody, TableContainer, TableHead,
    TablePagination, TableSortLabel, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import csvIcon from '../../../images/Report/csv.svg';
import Footer from '../../Footer/Footer';
import StyledTableRow from '../../GlobalStyles/StyledTableRow';
import { StyledTableCell } from '../../GlobalStyles/TableStyledCell';
import { useStyles } from '../../common/ReportListCss';
import SearchIcon from "@material-ui/icons/Search";
import { TextField } from '@material-ui/core';
import { usePost } from '../../../utils/apiHelper';
import { useGlobalState } from '../../../store/GlobalStore';
import DownloadReportRequested from './DownloadReportRequested';
import { IRequestedReportDetails } from '../../../models/Reports/IReportStatusList';
import DownloadingIcon from '@mui/icons-material/Downloading';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CancelIcon from '@mui/icons-material/Cancel';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import AvatarTooltip from '../../GlobalStyles/AvatarTooltip';
import FileAdmin from '../../../images/users/FileAdmin.svg';
import SuperAdmin from '../../../images/users/SuperAdmin.svg';
import GlobalAdmin from '../../../images/users/ClientAdmin.svg';
import ClientAdmin from '../../../images/users/GlobalAdmin.svg';
import RegularUser from '../../../images/users/RegularUser.svg';
import AgentUser from '../../../images/users/AgentUser.svg';
import PDF from "../../../images/Report/PDF.svg";

const ReportsRequested = () => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const [ProgressBar, setshowProgressBar] = useState(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [reportDetails, setReportDetails] = useState<IRequestedReportDetails[]>([]);
    const [totalRecordCount, setTotalRecordCount] = useState<number>(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogRemoveOpen, setDialogRemoveOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<IRequestedReportDetails[]>([]);
    const [search, setSearch] = useState<string>('');
    const [showSearchIcon, setShowSearchIcon] = useState(true);

    const handleCancel = (filteredRow) => {
        setSelectedRow(filteredRow);
        setDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleRemove = (filteredRow) => {
        setSelectedRow(filteredRow);
        setDialogRemoveOpen(true);
    }

    const handleDialogRemove = () => {
        setDialogRemoveOpen(false);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const GetReportDetail = () => {
        setshowProgressBar(true);
        (async () => {
            let request = {
                "UserId": state?.userAccessContext?.id,
                "RowsPerPage": rowsPerPage,
                "SearchParameters": search,
                "StartingRecordNumber": ((page * rowsPerPage) + 1),
                "userRole": state.userAccessContext?.role
            }
            await usePost<{ requestedReportContract: IRequestedReportDetails[], totalRecordCount: number }>("GetUserRequestedReport", request).then((r) => {
                setReportDetails(r?.data?.requestedReportContract);
                setTotalRecordCount(r?.data?.totalRecordCount);
            }).finally(() => {
                setshowProgressBar(false);
            })
        })()
    }

    useEffect(() => {
        GetReportDetail();
    }, [rowsPerPage, page])

    useEffect(() => {
        GetReportDetail();
    }, [])

    const ClearSearchText = () => {
        setshowProgressBar(true);
        (async () => {
            let request = {
                "UserId": state?.userAccessContext?.id,
                "RowsPerPage": 25,
                "SearchParameters": "",
                "StartingRecordNumber": 1,
                "userRole": state.userAccessContext?.role
            }
            await usePost<{ requestedReportContract: IRequestedReportDetails[], totalRecordCount: number }>("GetUserRequestedReport", request).then((r) => {
                setReportDetails(r?.data?.requestedReportContract);
                setTotalRecordCount(r?.data?.totalRecordCount);
                setSearch("");
            }).finally(() => {
                setshowProgressBar(false);
            })
        })()
    }

    const headCells = [
        { id: "", numeric: false, disablePadding: false, label: "", sortable: true },
        { id: "user", numeric: false, disablePadding: false, label: "USER NAME", sortable: true },
        { id: "reportName", numeric: false, disablePadding: false, label: "REPORT NAME", sortable: true },
        { id: "fileName", numeric: false, disablePadding: false, label: "FILE NAME", sortable: true },
        { id: "fileSize", numeric: false, disablePadding: false, label: "FILE SIZE", sortable: true },
        { id: "dateCreated", numeric: false, disablePadding: false, label: "REQUESTED DATE", sortable: true },
        { id: "createdTime", numeric: false, disablePadding: false, label: "TIME", sortable: true },
        { id: "status", numeric: false, disablePadding: false, label: "STATUS", sortable: true },
        { id: "download", numeric: false, disablePadding: false, label: "DOWNLOAD", sortable: false },
        { id: "remove", numeric: false, disablePadding: false, label: "REMOVE", sortable: false },
    ];

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <StyledTableRow>
                    {headCells.map(headCell => (
                        <StyledTableCell
                            key={headCell?.id}
                            sortDirection={orderBy === headCell.id ? order : false}>
                            <TableSortLabel active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}>
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </StyledTableRow>
            </TableHead>
        );
    }

    const handleRemoveReportRequest = (selectedRow) => {
        (async () => {
            setshowProgressBar(false);
            let request = {
                "reportRequestId": selectedRow?.reportRequestId,
                "isRequested": selectedRow?.isRequested,
                "isFileActive": 0,
                "fileId": 0
            }
            await usePost("DeleteRequestReport", request).then(() => {

            })
        })().finally(() => {
            setDialogOpen(false);
            GetReportDetail();
            setshowProgressBar(false);
        })
    }

    const handleDeleteReportRequest = (selectedRow) => {
        (async () => {
            setshowProgressBar(false);
            let request = {
                "reportRequestId": 0,
                "isRequested": 0,
                "isFileActive": selectedRow?.isFileActive,
                "fileId": selectedRow?.fileId
            }
            await usePost("DeleteRequestReport", request).then(() => {

            })
        })().finally(() => {
            setDialogRemoveOpen(false);
            GetReportDetail();
            setshowProgressBar(false);
        })
    }

    const removeAfterUnderscore = (filename: string, fileType: any): string => {
        const lastUnderscoreIndex = filename?.lastIndexOf('_');
        const extensionIndex = filename?.lastIndexOf('.');
        let baseName = filename;
        if (lastUnderscoreIndex !== -1 && lastUnderscoreIndex < extensionIndex) {
            baseName = filename?.substring(0, lastUnderscoreIndex);
        } else if (extensionIndex !== -1) {
            baseName = filename?.substring(0, extensionIndex);
        }
        const newExtension = fileType ? '.csv' : '.pdf';
        return `${baseName}${newExtension}`;
    };

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container spacing={0} className={classes.maingrid}>
                    <Grid item xs={3}>
                        <Typography variant="h6" gutterBottom className={`${classes.textStyle} ${"headertitle"}`} p={1}>
                            <b> REQUESTED REPORT </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            size="small"
                            id="standard-bare"
                            variant="outlined"
                            className={classes.searchBox}
                            value={search}
                            type='text'
                            placeholder="Search Requested Reports"
                            onChange={e => { setSearch(e.target.value) }}
                            onKeyPress={event => {
                                if (event.key === 'Enter' && search != "") {
                                    GetReportDetail(); setShowSearchIcon(false);
                                } else if (event.key === 'Enter' && search === "") {
                                    ClearSearchText(); setShowSearchIcon(true);
                                }
                            }}
                            inputProps={{ maxLength: 255 }}
                            InputProps={{
                                classes: {
                                    root: classes.outlinedInput,
                                    notchedOutline: classes.notchedOutline,
                                    input: classes.searchInput,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showSearchIcon === true ?
                                            <IconButton >
                                                <SearchIcon />
                                            </IconButton>
                                            :
                                            <IconButton onClick={(_) => { ClearSearchText(); setShowSearchIcon(true); }}>
                                                <CloseIcon className={classes.IconSizeStyle} />
                                            </IconButton>
                                        }

                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}>
                        <Button variant='contained' color="primary" size="small" component={Link} to="/report"
                            startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />} className={classes.backButton}>
                            Back
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table aria-label="customized table" size="small" stickyHeader>
                                <EnhancedTableHead classes={classes} />
                                <TableBody>
                                    {reportDetails.filter(row => row?.isRequested).map(filteredRow => (
                                        <StyledTableRow key={filteredRow?.reportRequestId}>
                                            <StyledTableCell component="th" scope="row" className={classes.TableCellStyle1}>
                                                {filteredRow.userRole === 3 ?
                                                    <AvatarTooltip title="Super Admin">
                                                        <Avatar className={classes.superAdminColor} src={SuperAdmin}></Avatar>
                                                    </AvatarTooltip>
                                                    : filteredRow.userRole === 2 ?
                                                        <AvatarTooltip title="Global Admin">
                                                            <Avatar className={classes.globalAdmin} src={GlobalAdmin}></Avatar>
                                                        </AvatarTooltip>
                                                        : filteredRow.userRole === 1 ?
                                                            <AvatarTooltip title="Client Admin">
                                                                <Avatar className={classes.clientAdmin} src={ClientAdmin}></Avatar>
                                                            </AvatarTooltip>
                                                            : filteredRow.userRole === 0 ?
                                                                <AvatarTooltip title="Regular User">
                                                                    <Avatar className={classes.regularUser} src={RegularUser}></Avatar>
                                                                </AvatarTooltip>
                                                                : filteredRow.userRole === 5 ?
                                                                    <AvatarTooltip title="Agent User">
                                                                        <Avatar className={classes.regularUser} src={AgentUser}></Avatar>
                                                                    </AvatarTooltip>
                                                                    : <AvatarTooltip title="File Admin">
                                                                        <Avatar className={classes.fileAdmin} src={FileAdmin}></Avatar>
                                                                    </AvatarTooltip>
                                                }
                                            </StyledTableCell>
                                            <StyledTableCell >
                                                <Typography variant='body2' className={classes.textStyle} >{filteredRow?.username}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Typography variant='body2' className={classes.textStyle} >{filteredRow?.reportName}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Box m={0} display={'flex'}>
                                                    <Box m={0} mt={"2px"}>
                                                        {filteredRow?.fileType === true ?
                                                            <img src={csvIcon} alt='icon' width={'30px'} /> :
                                                            <img src={PDF} alt='icon' width={'30px'} />
                                                        }
                                                    </Box>
                                                    <Box m={0}>
                                                        <Typography variant='body2' ml={1} mt={1} className={classes.textStyle} >
                                                            {removeAfterUnderscore(filteredRow?.fileName, filteredRow?.fileType)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Typography variant='body2' className={classes.textStyle} >{filteredRow?.fileSize}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Typography variant='body2' className={classes.textStyle} >{moment(filteredRow?.requestedDate).format(state.GlobalUtils?.settingValue)}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <Typography variant='body2' className={classes.textStyle} >{moment(filteredRow?.requestedDate)?.format('HH:mm:ss')}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {filteredRow?.isRequested === 1 || filteredRow?.isRequested === 2 ? (<>
                                                    <Chip
                                                        label="Inprogress"
                                                        className={classes.Inprogress1}
                                                        icon={<DownloadingIcon color='warning' />}
                                                    />
                                                </>)
                                                    : (<>
                                                        <Chip
                                                            label="Completed"
                                                            className={classes.Completed1}
                                                            icon={<PublishedWithChangesIcon style={{ color: '#008000' }} />}
                                                        />
                                                    </>
                                                    )}
                                            </StyledTableCell>
                                            <StyledTableCell >
                                                <DownloadReportRequested row={filteredRow} />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {filteredRow?.isRequested === 1 || filteredRow?.isRequested === 2 ?
                                                    (<><IconButton size='small' onClick={(e) => { handleCancel(filteredRow) }}>
                                                        <CancelIcon color='warning' />
                                                    </IconButton></>)
                                                    :
                                                    (<><IconButton size='small' onClick={(e) => { handleRemove(filteredRow) }}><DeleteForeverIcon color='error' fontSize='medium' /></IconButton></>)}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {!ProgressBar && !reportDetails?.length ?
                                < Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
                                    No records to display..
                                </Typography>
                                : null}
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} borderTop={'1px solid lightgray'}>
                        <Grid container spacing={0}>
                            <Box m={0} width="65%">
                                <div className="footer">
                                    <Footer />
                                </div>
                            </Box>
                            <Box m={0} width="35%">
                                <div className="pagination">
                                    <TablePagination
                                        id="Report_TablePagination"
                                        component="div"
                                        count={totalRecordCount}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                    <Dialog
                        open={dialogOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleDialogClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitleHeader id="responsive-dialog-title" onClose={handleDialogClose}><Typography><b>REPORT REQUEST PROCESS CANCEL</b></Typography></DialogTitleHeader>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                <Typography variant='h6' gutterBottom color='black'><b>Are you sure , do you want to cancel the process?</b></Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' color='success' size='small' style={{ borderRadius: '20px' }} onClick={(e) => { handleRemoveReportRequest(selectedRow) }}>Ok</Button>
                            <Button variant='contained' size='small' color="error" style={{ borderRadius: '20px' }} onClick={handleDialogClose}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={dialogRemoveOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleDialogRemove}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitleHeader id="responsive-dialog-title" onClose={handleDialogRemove}><Typography><b>REPORT REQUEST FILE DELETE</b></Typography></DialogTitleHeader>
                        <DialogContent >
                            <DialogContentText id="alert-dialog-slide-description">
                                <Typography variant='h6' gutterBottom color='black'><b>Are you sure , do you want to delete the {selectedRow[0]?.fileName} file?</b></Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' color='success' size='small' style={{ borderRadius: '20px' }} onClick={(e) => { handleDeleteReportRequest(selectedRow) }}>Ok</Button>
                            <Button variant='contained' size='small' color="error" style={{ borderRadius: '20px' }} onClick={handleDialogRemove}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </div>
        </React.Fragment >
    )
}

export default ReportsRequested