import {
    Backdrop, Box, Button, CircularProgress, createStyles, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableContainer,
    TableHead, TablePagination, TableSortLabel, Theme, Typography
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import download from "../../images/files/download01.svg";
import { IAdditionalAckBuilder } from '../../models/IAckReport/IAckBuilder';
import { IPrintAck } from '../../models/IAckReport/IPrintAck';
import { IAdditionalReportDownload } from '../../models/Roll/IRollClients';
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import Footer from '../Footer/Footer';
import LightTooltip from '../GlobalStyles/LightTooltip';
import { StyledTableCell, StyledTableRow } from '../GlobalStyles/TableStyledCell';
import AckAdditionalPrintView from './AckAdditionalPrintView';
import AdditionalAckExpandableRow from './AdditionalAckExpandableRow';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridPadding: {
            padding: '8px'
        },
        tableHeaderStyle: {
            background: "#007FFF",
            color: "white",
            padding: '5px 0px 5px 5px !important',
            fontSize: '11px'
        },
        paper: {
            width: '100%',
        },
        titleColor: {
            color: "blue",
            fontWeight: 'bold',
            marginTop: '10px',
            marginLeft: '10px'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        footerBoxStyle: {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
        },
        tablebody: {
            maxHeight: 700,
            minHeight: 700,
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 218px)',
                minHeight: 'calc( 100vh - 218px)',
            },
        },
        noRecordsStyle: {
            color: "red",
            marginTop: "5px",
            fontSize: 18
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1
        },
        downloadIconStyle: {
            color: "#a1a1a1",
            fontSize: 29,
            marginTop: '5px',
            marginLeft: '12px'
        },
        downloadIconStyle1: {
            color: "#2377E4",
            fontSize: 29,
            marginTop: '5px',
            marginLeft: '12px'
        },
        printStyle: {
            borderRadius: 30,
            marginTop: '5px',
            padding: '2px 2px'
        },
        fabProgressPrint: {
            color: green[500],
            position: 'absolute',
            top: 3,
            left: 8,
            zIndex: 1,
        },
        boxStyle: {
            gap: '8%',
            padding: '10px',
            float: 'right',
            backgroundColor: '#316b97',
            color: 'white',
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
            width: '430px',
            height: '220',
            marginTop: '25px'
        },
        sumGap: {
            gap: '8%',
            padding: '10px',
            float: 'right',
            backgroundColor: 'green',
            color: 'white',
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
            width: '450px',
            height: '200px',
            marginTop: '25px'
        },
        div: {
            flexGrow: 1,
        },
        textStyle: {
            textAlign: 'right',
            marginTop: '20px',
            fontSize: 16
        },
        totalRecord: {
            gap: '5%',
            marginRight: '10px',
            backgroundColor: '#316b97',
            borderRadius: 15,
            color: 'white',
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
            width: '360px',
            height: '130px',
            marginTop: 10,
            flexDirection: 'row',
            justifyContent: 'center',
        },
        report: {
            overflowX: 'hidden'
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing(1),
            right: theme.spacing(1),
            marginRight: 20,
        },
        tableCellColor: {
            color: "white",
            fontWeight: 'bold',
            fontSize: '12px !important',
            padding: '5px !important'
        },
        wrapper: {
            position: 'relative'
        },
        backbutton: {
            borderRadius: 20,
            fontSize: 11,
            fontWeight: 'bold',
            background: "#007FFF",
            color: "white",
            marginTop: '10px',
            marginRight: '10px'
        },
        maingrid: {
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
            filter: 'drop-shadow(0 -6mm 4mm gray))'
        }
    })
);


const AdditionalAckBuilder = (props) => {
    const classes = useRowStyles();
    const { state } = useGlobalState();

    const [order, setOrder] = useState("DBT_NO");
    const [orderBy, setOrderBy] = useState<string>("");

    const [AckReport, setAckReport] = useState<IAdditionalAckBuilder[]>([]);
    const [exportAck, setExportAck] = useState<IAdditionalAckBuilder[]>([]);
    const [ackPrintSummary, setAckPrintSummary] = useState<IPrintAck[]>([]);
    const [TotalRecords, setTotalRecords] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [page, setPage] = useState<number>(0);

    const [loading, setLoading] = useState(false);

    let client_code: IAdditionalReportDownload[] = props?.location?.state?.client_code;
    let phaseId: number = props?.location?.state?.phaseName;
    let startDate: string = props?.location?.state?.startDate;
    let endDate: string = props?.location?.state?.endDate;

    useEffect(() => {
        (async () => {
            let request = {
                "phaseId": phaseId,
                "rowsPerPage": rowsPerPage,
                "dbT_CLIENT": client_code,
                "orderDirection": order === "asc" ? 0 : 1,
                "startDate": moment(startDate).format("YYYY-MM-DD"),
                "endDate": moment(endDate).format("YYYY-MM-DD"),
                "startingRecordNumber": (page * rowsPerPage) + 1,
                "orderBy": orderBy
            }
            setLoading(true);
            await usePost<{ additionalAckReports: IAdditionalAckBuilder[], totalRecordCount: number }>("AdditionalChargesAckReport", request).then((searchAckReport) => {
                setAckReport(searchAckReport?.data.additionalAckReports);
                setTotalRecords(searchAckReport?.data?.totalRecordCount);
            }).finally(() => {
                setLoading(false);
            });
        })()
    }, [page, order, orderBy, rowsPerPage])

    useEffect(() => {
        (async () => {
            let requestExport = {
                "phaseId": phaseId,
                "dbT_CLIENT": client_code,
                "startDate": moment(startDate)?.format("YYYY-MM-DD"),
                "endDate": moment(endDate)?.format("YYYY-MM-DD"),
                "orderDirection": order === "asc" ? 0 : 1,
                "orderBy": "DBT_NO"
            }
            await usePost<{ ackReports: IAdditionalAckBuilder[], ackReportSummary: IPrintAck[] }>("AdditionalExportAckReport", requestExport)?.then((r) => {
                setExportAck(r?.data?.ackReports);
                setAckPrintSummary(r?.data?.ackReportSummary);
            });
        })()
    }, [])

    const headers = [
        { label: "Phase", key: "dbT_COMPANY" },
        { label: "Client Code", key: "dbT_CLIENT" },
        { label: "Client Name", key: "clientName" },
        { label: "Location", key: "location" },
        { label: "ARM #", key: "dbT_NO" },
        { label: "Your Account #", key: "dbT_CLNT_ACNT1" },
        { label: "Account Name", key: "dbT_NAME" },
        { label: "Account Referral Date", key: "placemenT_DATE" },
        { label: "Deliquency Date", key: "dbT_LAST_CHG_DATE" },
        { label: "Account Referral Balance", key: "amount_Placed" },
        { label: "Transaction Amount", key: "trH_AMT1" },
        { label: "New Account Balance", key: "trH_PRINC_DUE" },
        { label: "Transaction Date", key: "trH_DATE" }
    ]

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setPage(0);
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        const headCells = [
            { label: "PHASE", id: "dbT_COMPANY", disablePadding: false, sortable: true, fontSize: '10px' },
            { label: "CLIENT CODE", id: "dbT_CLIENT", disablePadding: false, sortable: true, fontSize: '10px' },
            { label: "CLIENT NAME", id: "clientName", disablePadding: false, sortable: true, fontSize: '10px' },
            { label: "LOCATION", id: "location", disablePadding: false, sortable: true, fontSize: '10px' },
            { label: "AGENCY ID", id: "dbT_NO", disablePadding: false, sortable: true, fontSize: '10px' },
            { label: "ACCOUNT ID", id: "dbT_CLNT_ACNT1", disablePadding: false, sortable: true, fontSize: '10px' },
            { label: "NAME", id: "dbT_NAME", disablePadding: false, sortable: true, fontSize: '10px' },
            { label: "PLACEMENT DATE", id: "placemenT_DATE", disablePadding: false, sortable: true, fontSize: '10px' },
            { label: "DELINQUENCY DATE", id: "dbT_LAST_CHG_DATE", disablePadding: false, sortable: true, fontSize: '10px' },
            { label: "AMOUNT PLACED", id: "amount_Placed", disablePadding: false, sortable: true, fontSize: '10px' },
            { label: "TRANSACTION AMOUNT", id: "trH_AMT1", disablePadding: false, sortable: true, fontSize: '10px' },
            { label: "PRINCIPLE DUE", id: "trH_PRINC_DUE", disablePadding: false, sortable: true, fontSize: '10px' },
            { label: "TRANSACTION DATE", id: "trH_DATE", disablePadding: false, sortable: true, fontSize: '10px' },
        ];

        return (
            <TableHead>
                <StyledTableRow >
                    {headCells?.map(headCell => (
                        <StyledTableCell size="small"
                            key={headCell?.id}
                            sortDirection={orderBy === headCell?.id ? order : false}
                            className={classes.tableHeaderStyle}
                            style={{ fontSize: `${headCell.fontSize}` }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell?.id}
                                direction={orderBy === headCell?.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                <b>{headCell.label.toUpperCase() || headCell.sortable}</b>
                                {orderBy === headCell?.id || headCell?.sortable ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))
                    }
                </StyledTableRow>
            </TableHead >
        );
    }

    const handleChangePage = (event, newPage) => {
        setLoading(true);
        setPage(newPage);
    };

    return (
        <React.Fragment>
            <div className={classes.div}>
                <Backdrop className={classes.backdrop} open={loading}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <Grid container className={classes.gridPadding}>
                    <Grid container spacing={0} className={classes.maingrid}>
                        <Box p={0} flexShrink={0} width="87%" display="flex" >
                            <Typography variant="h6" className={`${classes.titleColor} ${"headertitle"}`}>
                                {`Additional Charges Acknowledgment Report - ${moment(startDate).format(state?.GlobalUtils?.settingValue)} to ${moment(endDate).format(state?.GlobalUtils?.settingValue)}`}
                            </Typography>
                        </Box>
                        <Box display="flex" width="13%">
                            <div className={classes.wrapper}>
                                <LightTooltip title="Back">
                                    <Button id="PLC_Back_btn" className={classes.backbutton}
                                        variant="contained" color="primary" component={Link} to="/report" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />}>
                                        Back
                                    </Button>
                                </LightTooltip>
                            </div>
                            <AckAdditionalPrintView client_code={client_code} startDate={startDate} endDate={endDate} isAvailable={exportAck?.length > 0 ? true : false}
                                phaseId={phaseId} />

                            <div className={classes.wrapper}>
                                <LightTooltip title="Download Reports List">
                                    <IconButton disabled={!exportAck?.length} className={classes.printStyle}>
                                        <CSVLink
                                            data={exportAck}
                                            headers={headers}
                                            filename={`Additional Charges Acknowledgment Report - ${moment(startDate).format(state?.GlobalUtils?.settingValue)} to ${moment(endDate).format(state?.GlobalUtils?.settingValue)}.csv`}
                                            target="_blank" >
                                            <img src={download} alt="Icon for Download" width="30px" height="30px" className={!exportAck?.length ? classes.downloadIconStyle : classes.downloadIconStyle1} />
                                        </CSVLink>
                                        {loading && <CircularProgress size={40} className={classes.fabProgressPrint} />}
                                    </IconButton>
                                </LightTooltip>
                            </div>
                        </Box>
                        <Grid component={Paper} xs={12} sm={12} lg={12} style={{ marginTop: '5px' }}>
                            <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                                <Table id="AckReportsDetailList" aria-label="customized table" size="small" stickyHeader>
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {AckReport?.map((r) => {
                                            return (
                                                <AdditionalAckExpandableRow row={r} />
                                            )
                                        })
                                        }
                                    </TableBody>
                                </Table>
                                {!loading && !AckReport?.length ? <Typography variant="h6" gutterBottom className={classes.noRecordsStyle}>
                                    No records to display...
                                </Typography> : null}
                            </TableContainer>
                            <Grid container spacing={0} className={classes.footerBoxStyle}>
                                <Box m={0} width="65%">
                                    <div className="footer">
                                        <Footer />
                                    </div>
                                </Box>
                                <Box m={0} width="35%">
                                    <div className="pagination">
                                        <TablePagination
                                            id="Inquiry_TablePagination"
                                            rowsPerPageOptions={[50]}
                                            component="div"
                                            count={TotalRecords}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                        />
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment >
    );
}

export default AdditionalAckBuilder;