import {
  Box, Button, Grid, IconButton, makeStyles, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  TableSortLabel, TextField, Typography
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CssBaseline from '@material-ui/core/CssBaseline';
import InputAdornment from "@material-ui/core/InputAdornment";
import { createStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from "@material-ui/icons/Search";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import React, { ChangeEvent, useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../components/Footer/Footer';
import { IGetProspectUserActivityList, IProspectUsersDetails, ProspectUserActivityList } from '../../models/accesscontrol/ProspectUsersDetails';
import { IGetBlobFolders } from '../../models/Files/IGetBlobFolders';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import AvatarTooltip from '../GlobalStyles/AvatarTooltip';
import DeleteProspectUser from './DeleteProspectUser';
import EditProspectUser from './EditProspectUser';
import MultiLocationDetails from './MultiClientConformation/MultiLocationDetails';
import DocumentUpload from './UploadDocument/DocumentUpload';
import ViewProfileInfo from './ViewOrganizationDetails';
import ClientSetup from './ClientSetup';
import ViewHistory from './ViewHistory';
import EditClientProspectUser from './EditClientProspectUser';
import LightTooltip from '../GlobalStyles/LightTooltip';
import styled from "styled-components";
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clearIcon from '../../images/buttonIcons/clearIcon.svg';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import * as constant from '../../constants/Constant';
import NegatedUser from './NegatedUser';
import InfoIcon from '@mui/icons-material/Info';
import { useStyles } from './ProspectUserCss';
import ActivationPending from '../../images/users/ActivationPending.svg';
import TransferOwnership from "./TransferOwnership";

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    div: {
      flexGrow: 1,
      padding: '2px 8px'
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    progressBar: {
      height: '6px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    searchBar: {
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
      borderRadius: 5,
      // border: '2px solid #215176',
      width: '98%',
      margin: '4px 0px',
      fontSize: 14,
      textIndent: '10px',
      marginBottom: '5px',
    },
    tablebody: {
      maxHeight: 500,
      minHeight: 500,
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 260px)',
        minHeight: 'calc( 100vh - 260px)',
      },
    },
    outlinedInput: {
      fontSize: 14,
      color: "black",
      paddingLeft: '5px',
      '&$focused $notchedOutline': {
        color: 'white',
        border: '0px'
      },
    },
    notchedOutline1: {
      color: 'white',
      border: '0px'
    },
    IconSizeStyle: {
      height: '22px',
      width: '22px'
    },
    UserListStyle: {
      textAlign: 'center',
      marginLeft: '5px',
      marginTop: '5px',
      '@media (min-width: 600px)': {
        textAlign: 'left',
      },
    },
    TableCellStyle: {
      background: "#007FFF",
      color: "white",
      fontSize: 12,
      fontWeight: 'bold',
      padding: '3px 8px'
    },
    ErrorMessageStyle: {
      color: "red",
      marginTop: "10px"
    },
    footer: {
      marginTop: '15px',
      float: 'right',
    },
    searchTextStyle: {
      padding: '10px !important',
    },
    usertablecellStyle: {
      padding: '3px 8px'
    },
    usertablecellStyle2: {
      padding: '3px 8px 3px 0px'
    },
    TextNormal: {
      color: 'black',
      fontSize: 13
    },
    outlinedInput1: {
      fontSize: 14,
      color: "black",
      paddingLeft: '5px',
      '&$focused $notchedOutline': {
        color: 'white',
        border: 'none',
      },
    },
    paper1: {
      border: "2px solid black",
    },
    autoCompleteFont: {
      fontSize: 12,
    },
    GreenColor: {
      color: 'green'
    },
    domainBorder: {
      borderRadius: '50px',
    },
    buttons: {
      borderRadius: 20,
      fontSize: '11px',
      background: "#007FFF",
      color: "white",
      alignContent: 'flex-start',
      justify: "flex-start",
      paddingLeft: 8,
      marginTop: '8px',
      marginLeft: '30px'
    },
    ClearFilterButtonStyle: {
      borderRadius: 20,
      fontSize: '11px',
      background: "#007FFF",
      color: "white",
      marginLeft: '10px',
      paddingLeft: 8,
      marginTop: '8px'
    },
    IconStyle: {
      height: '15px',
      width: '15px',
      marginLeft: '2px'
    },
    ToolTip: {
      marginLeft: '10px'
    },
    gridheader: {
      padding: '3px 0px',
      borderRadius: '10px',
      backgroundColor: 'white',
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
      filter: 'drop-shadow(0 -6mm 4mm gray))',
    },
  })
);

const StyledLabel = styled.label<{ checked: boolean }>`  
cursor: pointer;  
text-indent: -9999px;  
width: 45px;  
height: 27px;  
background: ${({ checked }) => (!checked ? "gray" : "green")};  
display: block;  
border-radius: 100px;  
position: relative;
&:after {    
content: "";    
position: absolute;    
left: ${({ checked }) => (!checked ? "2px" : "calc(55% - 5px)")};    
top: 2px;    
width: 24px;    
height: 24px;    
background: #fff;    
border-radius: 90px;    
transition: 0.3s;  
}`;

const StyledLabelNegatedUser = styled.label<{ checkedLabel: boolean }>`
cursor: pointer;
text-indent: -9999px;
width: 45px;
height: 27px;
background: ${({ checkedLabel }) => (!checkedLabel ? "gray" : "green")};
display: block;
border-radius: 100px;
position: relative;
&:after {
content: "";
position: absolute;
left: ${({ checkedLabel }) => (!checkedLabel ? "2px" : "calc(55% - 5px)")};
top: 2px;
width: 24px;
height: 24px;
background: #fff;
border-radius: 90px;
transition: 0.3s;
}`;

export default function ProspectUsersList() {
  const classes = useRowStyles();
  const tollTipClasses = useStyles();
  const { state, dispatch } = useGlobalState();
  const [sendInvite, setInvite] = useState(false);
  const [order, setOrder] = React.useState("desc");
  const [searchText, setSearchText] = useState("");
  const [TextSearch, setTextSearch] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [orderBy, setOrderBy] = React.useState("userName");
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [selected, setSelected] = React.useState<number[]>([]);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(25);
  const [ListContainers, setListContainers] = useState<IGetBlobFolders[]>([]);
  const [ListACKContainers, setACKListContainers] = useState<IGetBlobFolders[]>([]);
  const [usersListResult, setUsersListResult] = useState<IProspectUsersDetails[]>([]);
  const [ListPaymentContainers, setPaymentListContainers] = useState<IGetBlobFolders[]>([]);
  const [showPermissionUpdatedUser, setShowPermissionUpdatedUser] = useState(false);
  const [ProspectUserActivityList, setProspectUserActivityList] = useState<IGetProspectUserActivityList[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<string>("");
  const [selectedActivityList, setSelectedActivityList] = useState<any>([]);
  const [selectedOptionValue, setSeletedOptionValue] = useState<any>(null);
  const [selectedOption, setSeletedOption] = useState<any>([]);
  const [showNegatedUsers, setShowNegatedUsers] = useState(false);
  const [IsNegatedUsersCount, setIsNegatedUsersCount] = useState<number>(0);

  const actionDropDownLost = [
    { activityName: "Client InfoConfirmed By User" },
    { activityName: "Client Verified" },
    { activityName: "Contract Uploaded" },
    { activityName: "Client Setup Confirmed" },
    { activityName: "User Activated" },
    { activityName: "New Client" },
    { activityName: "WebView Access Requested" },
  ];

  let newProspectUsersActivityList: ProspectUserActivityList = {
    ClientInfoConfirmedByUser: null,
    ContractUploaded: null,
    IsUserActivated: null,
    IsClientSetup: null,
    IsClientOnboarded: null,
    NewClient: null,
  };
  const [actionLocalState, setActionLocalState] = useState<ProspectUserActivityList>(newProspectUsersActivityList);

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, onSelectAllClick, numSelected, rowCount } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const clientHeadCells = [
      { id: "firstName", disablePadding: false, label: "FIRST NAME", sortable: true },
      { id: "lastName", disablePadding: false, label: "LAST NAME", sortable: true },
      { id: "userName", disablePadding: false, label: "EMAIL", sortable: true },
      { id: "createdDate", disablePadding: false, label: "CREATED DATE", sortable: true },
      { id: "dataOps", disablePadding: false, label: "AUTHORIZE USER", sortable: false },
      { id: "", disablePadding: false, label: "HISTORY", sortable: false },
      { id: "", disablePadding: false, label: "REJECT", sortable: false },
    ];

    const adminHeadCells = [
      { id: "firstName", disablePadding: false, label: "FIRST NAME", sortable: true },
      { id: "lastName", disablePadding: false, label: "LAST NAME", sortable: true },
      { id: "userName", disablePadding: false, label: "EMAIL", sortable: true },
      { id: "createdDate", disablePadding: false, label: "CREATED DATE", sortable: true },
      { id: "", disablePadding: false, label: "", sortable: false },
      { id: "", disablePadding: false, label: "VERIFY CLIENT", sortable: false },
      { id: "dataOps", disablePadding: false, label: "UPLOAD CONTRACT", sortable: false },
      { id: "", disablePadding: false, label: "CLIENT SETUP", sortable: true },
      { id: "dataOps", disablePadding: false, label: "AUTHORIZE USER", sortable: false },
      { id: "", disablePadding: false, label: "HISTORY    REJECT", sortable: false },
    ];

    const negatedHeadCells = [
      { id: "firstName", disablePadding: false, label: "FIRST NAME", sortable: true },
      { id: "lastName", disablePadding: false, label: "LAST NAME", sortable: true },
      { id: "userName", disablePadding: false, label: "EMAIL", sortable: true },
      { id: "createdDate", disablePadding: false, label: "CREATED DATE", sortable: true },
      { id: "switchNewOrg", disablePadding: false, label: "SWITCH NEW ORGANIZATION", sortable: false },
      { id: "orgDetails", disablePadding: false, label: "ORGANIZATION DETAILS", sortable: false },
      { id: "", disablePadding: false, label: "HISTORY    REJECT", sortable: false },
    ];

    return (
      <TableHead>
        <TableRow>
          {state?.userAccessContext?.role === 1 &&
            !usersListResult[0]?.isNegatedUser
            ? clientHeadCells.map((headCell) => (
              <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false} className={classes.TableCellStyle} >
                <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)} >
                  {headCell.label || headCell.sortable}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))
            : (state?.userAccessContext?.role === 2 && !usersListResult[0]?.isNegatedUser) || (state?.userAccessContext?.role === 3 && !usersListResult[0]?.isNegatedUser) ?
              adminHeadCells.map((headCell) => (
                <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false} className={classes.TableCellStyle} >
                  <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)} >
                    {headCell.label || headCell.sortable}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))
              : usersListResult[0]?.isNegatedUser ?
                negatedHeadCells.map((headCell) => (
                  <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false} className={classes.TableCellStyle} >
                    <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
                      {headCell.label || headCell.sortable}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))
                : null}
        </TableRow>
      </TableHead>
    );
  }

  let switchButton = 0;
  let switchNegatedUser = 0;

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setInvite(false);
  }

  useEffect(() => {
    getUsersAsync();
  }, [pageNumber, order, orderBy, recordsPerPage, showNegatedUsers, showPermissionUpdatedUser]);

  useEffect(() => {
    getProspectUserActivityList();
  }, []);

  const getProspectUserActivityList = () => {
    (async () => {
      await useFetch<IGetProspectUserActivityList[]>("User/GetProspectUserActivityList").then((result) => {
        setProspectUserActivityList(result?.data);
      }).finally(() => { });
    })();
  };

  const handleClientSelect = (event, selected) => {
    setSelectedActivity(selected?.activityName);
    setSelectedActivityList(selected);
  };

  const handleStatusChanged = (event, value) => {
    setSeletedOption(value);
    if (value?.name === "True") {
      setSeletedOptionValue(true);
    } else if (value?.name === "False") {
      setSeletedOptionValue(false);
    }
  };

  const HandleFilter = () => {
    setActionLocalState(newProspectUsersActivityList);
    if (selectedOptionValue === true) {
      if (selectedActivity?.includes(constant?.ClientInfoConfirmedByUser)) {
        setActionLocalState(prevState => ({ ...prevState, ClientInfoConfirmedByUser: true }));
      } else if (selectedActivity?.includes(constant?.IsClientOnboarded)) {
        setActionLocalState(prevState => ({ ...prevState, IsClientOnboarded: true }));
      } else if (selectedActivity?.includes(constant?.ContractUploaded)) {
        setActionLocalState(prevState => ({ ...prevState, ContractUploaded: true }));
      } else if (selectedActivity?.includes(constant?.IsClientSetup)) {
        setActionLocalState(prevState => ({ ...prevState, IsClientSetup: true }));
      } else if (selectedActivity?.includes(constant?.IsUserActivated)) {
        setActionLocalState(prevState => ({ ...prevState, IsUserActivated: true }));
      } else if (selectedActivity?.includes(constant?.NewClient)) {
        setActionLocalState(prevState => ({ ...prevState, NewClient: true }));
      } else if (selectedActivity?.includes(constant?.WebViewAccessRequested)) {
        setActionLocalState(prevState => ({ ...prevState, NewClient: false }));
      }
    } else if (selectedOptionValue === false) {
      if (selectedActivity?.includes(constant?.ClientInfoConfirmedByUser)) {
        setActionLocalState(prevState => ({ ...prevState, ClientInfoConfirmedByUser: false }));
      } else if (selectedActivity?.includes(constant?.IsClientOnboarded)) {
        setActionLocalState(prevState => ({ ...prevState, IsClientOnboarded: false }));
      } else if (selectedActivity?.includes(constant?.ContractUploaded)) {
        setActionLocalState(prevState => ({ ...prevState, ContractUploaded: false }));
      } else if (selectedActivity?.includes(constant?.IsClientSetup)) {
        setActionLocalState(prevState => ({ ...prevState, IsClientSetup: false }));
      } else if (selectedActivity?.includes(constant?.IsUserActivated)) {
        setActionLocalState(prevState => ({ ...prevState, IsUserActivated: false }));
      } else if (selectedActivity?.includes(constant?.NewClient)) {
        setActionLocalState(prevState => ({ ...prevState, NewClient: false }));
      } else if (selectedActivity?.includes(constant?.WebViewAccessRequested)) {
        setActionLocalState(prevState => ({ ...prevState, NewClient: true }));
      }
    }
  }

  useEffect(() => {
    getUsersAsync(); // calling the get function to return the filtered result
  }, [actionLocalState]);

  const handleClearFilter = () => {
    setSelectedActivityList("");
    setSelectedActivityList([]);
    setSeletedOptionValue(null);
    setSeletedOption([]);
    setActionLocalState(newProspectUsersActivityList);
    ClearUsersAsync();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setPageNumber(0);
    setshowProgressBar(true);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = usersListResult.map((n) => n.prospectUserId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleSave = () => {
    setshowProgressBar(true);
    getUsersAsync();
  };

  const handlePageChange = (event, page) => {
    setshowProgressBar(true);
    setPageNumber(page);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecordsPerPage(+event.target.value);
    setPageNumber(0);
  };

  function getUsersAsync() {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setshowProgressBar(true);
      let request = {
        "rowsPerPage": recordsPerPage,
        "searchParameters": searchText?.trimLeft().trimRight(),
        "startingRecordNumber": ((pageNumber * recordsPerPage) + 1),
        "userRole": state?.userAccessContext?.role,
        "adminEmailAddress": state.userAccessContext?.role === 0 || state.userAccessContext?.role === 1 ? state?.userAccessContext?.userName : null,
        "permissionUpdated": showPermissionUpdatedUser,
        "clientInfoConfirmedByUser": actionLocalState?.ClientInfoConfirmedByUser,
        "contractUploaded": actionLocalState?.ContractUploaded,
        "isUserActivated": actionLocalState?.IsUserActivated,
        "newClient": actionLocalState?.NewClient,
        "isClientSetup": actionLocalState?.IsClientSetup,
        "isClientOnboarded": actionLocalState?.IsClientOnboarded,
        "isNegatedUsers": showNegatedUsers,
      }
      await usePost<{ prospectUserResult: IProspectUsersDetails[], totalCount: number, isNegatedUsersCount: number }>("User/GetProspectUserDetails", request).then((GetAllUsers) => {
        setUsersListResult(GetAllUsers.data["prospectUserResult"]);
        setTotalRecords(GetAllUsers.data.totalCount);
        setIsNegatedUsersCount(GetAllUsers.data.isNegatedUsersCount);
      }).finally(() => {
        setTextSearch(true);
        setshowProgressBar(false);
      });
    })();
  }

  function ClearUsersAsync() {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setshowProgressBar(true);
      let request = {
        "rowsPerPage": recordsPerPage,
        "searchParameters": "",
        "startingRecordNumber": (searchText !== "" ? (pageNumber * 0) + 1 : (pageNumber * recordsPerPage) + 1),
        "userRole": state?.userAccessContext?.role,
        "adminEmailAddress": state.userAccessContext?.role === 0 || state.userAccessContext?.role === 1 ? state?.userAccessContext?.userName : null,
        "permissionUpdated": showPermissionUpdatedUser,
        "clientInfoConfirmedByUser": null,
        "contractUploaded": null,
        "isUserActivated": null,
        "newClient": null,
        "isClientSetup": null,
        "isClientOnboarded": null,
        "isNegatedUsers": showNegatedUsers,
      }
      await usePost<{ prospectUserResult: IProspectUsersDetails[], totalCount: number, isNegatedUsersCount: number }>("User/GetProspectUserDetails", request).then((GetAllUsers) => {
        setUsersListResult(GetAllUsers.data["prospectUserResult"]);
        setTotalRecords(GetAllUsers.data.totalCount);
        setIsNegatedUsersCount(GetAllUsers.data.isNegatedUsersCount);
      }).finally(() => {
        setTextSearch(true);
        setSearchText("");
        setshowProgressBar(false);
      });
    })();
  }

  const getDefaultContainer = () => {
    let defaultContainer: IGetBlobFolders = {
      folder_Id: -1,
      folder_Name: 'None',
      fileContains: null
    };
    return defaultContainer;
  }

  useEffect(() => {
    (async () => {
      await useFetch<IGetBlobFolders[]>("File/GetBlobFolders").then((c) => {
        c.data.unshift(getDefaultContainer());
        let response = _.cloneDeep(c.data);
        setListContainers(response.filter(r => r.folder_Id || r.folder_Id === -1));
        setPaymentListContainers(response.filter(r => r.folder_Id === -1));
        setACKListContainers(response.filter(r => r.folder_Id === -1));
      });
    })()
  }, [])

  const handleDeleteSuccess = () => {
    getUsersAsync();
  };

  function handleSwitched(e: ChangeEvent<HTMLInputElement>) {
    setShowPermissionUpdatedUser(e.target.checked);
    if (e.target.checked === true) {
      switchButton = 1;
    } else if (!e.target.checked) {
      switchButton = 0;
    }
  }

  function handleSwitchedNegatedUser(e: ChangeEvent<HTMLInputElement>) {
    setShowNegatedUsers(e.target.checked);
    if (e.target.checked === true) {
      switchNegatedUser = 1;
    } else if (!e.target.checked) {
      switchNegatedUser = 0;
    }
  }

  const dropDownValues = [
    { name: 'True' },
    { name: 'False' },
  ]

  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <div className={classes.div}>
        <Grid container spacing={0}>
          <Grid item xs={5}>
            <Typography variant="h6" className={`${classes.UserListStyle} ${"headertitle"}`}>
              <b> PROSPECT USERS LIST </b>
            </Typography>
          </Grid>
          <Grid item xs={2} spacing={0}>
            <TransferOwnership usersListResult={usersListResult} onUpdate={handleSave} />
          </Grid>
          <Grid item xs={3} spacing={0}>
            {state?.userAccessContext?.role === 3 || state?.userAccessContext?.role === 2 ?
              <Box display="flex" p={1} className={classes.ToolTip}>
                <LightTooltip title="Switch to view Negated users">
                  <StyledLabelNegatedUser htmlFor="checkbox" checkedLabel={showNegatedUsers}>
                    <input id="checkbox" type="checkbox" checked={showNegatedUsers} onChange={handleSwitchedNegatedUser} />
                  </StyledLabelNegatedUser>
                </LightTooltip>
                <Typography style={{ padding: '2px 10px', fontSize: '14px' }}><b>Show Negated Users</b></Typography>
                {IsNegatedUsersCount !== 0 ?
                  <>
                    <IconButton className={`${tollTipClasses.colorcodeTemplate} ${"zoom-in-box"}`} style={{ padding: '0px' }}>
                      <LightTooltip title={<span className={tollTipClasses.tooltipText1} >Negated Users Exist</span>}>
                        <InfoIcon />
                      </LightTooltip>
                    </IconButton>
                  </>
                  : null
                }
              </Box>
              : null
            }
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" p={1} className={classes.ToolTip}>
              <LightTooltip title="Switch to view Already Permitted users">
                <StyledLabel htmlFor="checkbox1" checked={showPermissionUpdatedUser}>
                  <input id="checkbox1" type="checkbox" checked={showPermissionUpdatedUser} onChange={handleSwitched} />
                </StyledLabel>
              </LightTooltip>
              <Typography style={{ padding: '2px 7px', fontSize: '16px' }}><b>Archived Users</b></Typography>
            </Box>
          </Grid>
          <Grid container xs={12} spacing={0} justify='space-evenly' className={classes.gridheader}>
            <Grid item xs={6}>
              <TextField value={searchText} variant="outlined"
                type='text' placeholder="Search Prospect Users (First Name, Last Name or Email)"
                className={classes.searchBar}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyPress={event => {
                  if (event.key === 'Enter' && searchText != "") {
                    getUsersAsync(); setShowSearchIcon(false);
                  } else if (event.key === 'Enter' && searchText === "") {
                    ClearUsersAsync(); setShowSearchIcon(true);
                  }
                }}
                inputProps={{ maxlength: 255 }}
                InputProps={{
                  classes: {
                    root: classes.outlinedInput,
                    notchedOutline: classes.notchedOutline1,
                    input: classes.searchTextStyle,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {showSearchIcon === true ?
                        <IconButton onClick={(_) => { getUsersAsync(); setShowSearchIcon(false); }}>
                          <SearchIcon className={classes.IconSizeStyle} />
                        </IconButton>
                        :
                        <IconButton onClick={(_) => { ClearUsersAsync(); setShowSearchIcon(true); }}>
                          <CloseIcon className={classes.IconSizeStyle} />
                        </IconButton>
                      }
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                id="free-solo-demo"
                options={actionDropDownLost}
                autoComplete autoHighlight
                classes={{
                  paper: classes.paper1,
                  option: classes.autoCompleteFont
                }}
                getOptionLabel={(option) => option.activityName}
                onChange={handleClientSelect}
                value={selectedActivityList}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <span className={`${selected ? classes.GreenColor : null}`} style={{ fontSize: 14, marginLeft: '5px', width: 250 }}>
                      {option.activityName}
                    </span>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField {...params} size="small" placeholder="Select Action"
                    className={classes.searchBar}
                    InputProps={{
                      ...params.InputProps, disableUnderline: true,
                      classes: {
                        root: classes.outlinedInput1,
                        input: classes.searchTextStyle,
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete className={classes.domainBorder}
                id="free-solo-demo"
                autoComplete autoHighlight
                classes={{
                  paper: classes.paper1,
                  option: classes.autoCompleteFont
                }}
                options={dropDownValues}
                getOptionLabel={(option) => option.name}
                value={selectedOption}
                onChange={handleStatusChanged}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <span className={`${selected ? classes.GreenColor : null}`} style={{ fontSize: 14, marginLeft: '5px', width: 250 }}>
                      {option.name}
                    </span>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField placeholder="Select True OR False"
                    {...params}
                    className={classes.searchBar}
                    InputProps={{
                      ...params.InputProps, disableUnderline: true,
                      classes: {
                        root: classes.outlinedInput1,
                        input: classes.searchTextStyle,
                      }
                    }} />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Button id="DocUpLog_Filter_Button" size="small" className={classes.buttons}
                startIcon={<FilterAltIcon />} onClick={() => { HandleFilter(); }} variant="contained" color="primary" >
                Filter
              </Button>
              <Button id="UpLog_Filter_Button" size="small" className={classes.ClearFilterButtonStyle}
                startIcon={<img src={clearIcon} alt="Icon for clear filter button" className={classes.IconStyle} />}
                onClick={() => { handleClearFilter(); }} variant="contained" color="primary" >
                Clear Filter
              </Button>
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table className="main-table" aria-label="customized table" size="small" stickyHeader>
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={usersListResult.length}
                  />
                  <TableBody>
                    {usersListResult?.map(
                      (row, index) => {
                        const isItemSelected = isSelected(row.prospectUserId);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          state?.userAccessContext?.role === 3 || state?.userAccessContext?.role === 2 ?
                            <TableRow key={index} hover selected={isItemSelected}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}>
                              <TableCell component="th" scope="row" className={classes.usertablecellStyle}>
                                <span className={classes.TextNormal}>
                                  {row.prospectGivenName}
                                </span>
                              </TableCell>
                              <TableCell align="left" className={classes.usertablecellStyle}>
                                <span className={classes.TextNormal}>
                                  {row.prospectLastName}
                                </span>
                              </TableCell>
                              <TableCell align="left" className={classes.usertablecellStyle}>
                                <span className={classes.TextNormal}>
                                  {row.prospectEmailAddress}
                                </span>
                              </TableCell>
                              <TableCell align="left" className={classes.usertablecellStyle}>
                                <span className={classes.TextNormal}>
                                  {moment(row.prospectCreatedDate).format(state.GlobalUtils?.settingValue)}
                                </span>
                              </TableCell>
                              {!row?.isNegatedUser ?
                                <TableCell align="left" className={classes.usertablecellStyle}>
                                  {row?.isUserActivated ? null
                                    :
                                    <IconButton style={{ padding: "5px" }}>
                                      <LightTooltip title={<span className={tollTipClasses.tooltipText1} >User Activation Pending!</span>}>
                                        <img src={ActivationPending} width="24px" height="24px" alt="Description" />
                                      </LightTooltip>
                                    </IconButton>
                                  }
                                </TableCell>
                                : null}
                              <TableCell align={!row?.isNegatedUser ? "center" : "left"} className={classes.usertablecellStyle2}>
                                {!row?.isNegatedUser ? <MultiLocationDetails userRow={row} onUpdate={handleSave} /> : null}
                                {row?.isNegatedUser ? <NegatedUser userRow={row} onUpdate={handleSave} /> : null}
                              </TableCell>
                              <TableCell align={!row?.isNegatedUser ? "center" : "left"} className={classes.usertablecellStyle2}>
                                <DocumentUpload userRow={row} onUpdate={handleSave} />
                                {!row?.isNewOrginization ? <ViewProfileInfo userRow={row} /> : null}
                              </TableCell>
                              {!row?.isNegatedUser ?
                                <React.Fragment>
                                  <TableCell align="center" className={classes.usertablecellStyle2}>
                                    <ClientSetup userRow={row} onUpdate={handleSave} />
                                  </TableCell>

                                  <TableCell align="center" className={classes.usertablecellStyle2}>
                                    <EditProspectUser userRow={row} onSave={handleSave} CloudContainer={ListContainers} PaymentContainer={ListPaymentContainers} AckContainer={ListACKContainers} />
                                  </TableCell>
                                </React.Fragment>
                                : null
                              }
                              <TableCell align="center" className={classes.usertablecellStyle} style={{ display: 'flex' }}>
                                <ViewHistory userRow={row} userFirstName={row?.prospectGivenName} userLastName={row?.prospectLastName} />
                                <DeleteProspectUser userRow={row} onDelete={handleDeleteSuccess} />
                              </TableCell>
                            </TableRow>
                            : state?.userAccessContext?.role === 1 ?
                              <TableRow key={index} hover selected={isItemSelected}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}>
                                <TableCell component="th" scope="row" className={classes.usertablecellStyle}>
                                  <span className={classes.TextNormal}>
                                    {row.prospectGivenName}
                                  </span>
                                </TableCell>
                                <TableCell align="left" className={classes.usertablecellStyle}>
                                  <span className={classes.TextNormal}>
                                    {row.prospectLastName}
                                  </span>
                                </TableCell>
                                <TableCell align="left" className={classes.usertablecellStyle}>
                                  <span className={classes.TextNormal}>
                                    {row.prospectEmailAddress}
                                  </span>
                                </TableCell>
                                <TableCell align="left" className={classes.usertablecellStyle}>
                                  <span className={classes.TextNormal}>
                                    {moment(row.prospectCreatedDate).format(state.GlobalUtils?.settingValue)}
                                  </span>
                                </TableCell>
                                <TableCell align="left" className={classes.usertablecellStyle2}>
                                  <EditClientProspectUser userRow={row} onSave={handleSave} />
                                </TableCell>
                                <TableCell align="center" className={classes.usertablecellStyle2} style={{ display: 'flex' }}>
                                  <ViewHistory userRow={row} userFirstName={row?.prospectGivenName} userLastName={row?.prospectLastName} />
                                </TableCell>
                                <TableCell align="left" className={classes.usertablecellStyle2}>
                                  <DeleteProspectUser userRow={row} onDelete={handleDeleteSuccess} />
                                </TableCell>
                              </TableRow>
                              : null
                        )
                      }
                    )}
                  </TableBody>
                </Table>
                {TextSearch && !usersListResult.length ?
                  <Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
                    No records to display..
                  </Typography> : null}
              </TableContainer>

              <Box m={0} display="flex" style={{ flexDirection: 'row' }}>
                <Box m={0} width="65%">
                  <div className="footer">
                    <Footer />
                  </div>
                </Box>
                <Box m={0} width="35%">
                  <div className="pagination">
                    <TablePagination
                      rowsPerPageOptions={[25, 50, 100]}
                      component="div"
                      count={totalRecords}
                      rowsPerPage={recordsPerPage}
                      page={pageNumber}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar open={sendInvite} className="snackBarStyle" anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
            Invitation Sent Successfully!
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment>
  );
}
