import { Backdrop, Box, DialogActions, makeStyles, Slide, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { TransitionProps } from '@material-ui/core/transitions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CreateIcon from '@mui/icons-material/Create';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import React, { useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { usePost } from '../../../utils/apiHelper';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
                marginBottom: "0%",
                margin: theme.spacing(2),
                width: '25ch',
                display: "flex",
            },
        },
        button: {
            borderRadius: "20px",
            fontSize: 12
        },
        box: {
            display: "flex",
        },
        centerBox: {
            justifyContent: "center",
            alignItems: "center"
        },
        DialogBottom: {
            marginRight: '20px'
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        question: {
            padding: "10px",
            border: "3px solid",
            width: "100%",
            fontSize: 15,
            borderRadius: 10
        },
        answer: {
            marginTop: "10px",
            border: "3px solid",
            fontSize: 15,
            padding: "10px",
            width: "100%",
            borderRadius: 10
        },
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white',
            fontSize: 18
        }
    })
);

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CreateFAQ: React.FC<{ onSave: () => void }> = (props) => {
    const { onSave } = props;
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const { state, dispatch } = useGlobalState();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [Question, setQuestion] = useState<string>('');
    const [show, setShow] = React.useState(false);
    const [Answer, setAnswer] = useState<string>('');
    const [ProgressBar, setShowProgressBar] = useState(false);
    const classes = useRowStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setQuestion('');
        setAnswer('');
    };

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setShow(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    async function createFaq() {
        dispatch({ type: GlobalStateAction.Busy });
        setShowProgressBar(true);
        try {
            let request = {
                "question": Question,
                "answer": Answer,
                "date_created": new Date()?.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
            };
            await usePost<any>('Faq/CreateFaq', request).finally(() => {
                setShowProgressBar(false);
            });
            setShow(true);
            setOpen(false);
            onSave();
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex });
            dispatch({ type: GlobalStateAction.Idle });
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle });
        }
    };

    return (
        <React.Fragment>
            <Box display="flex" justifyContent="flex-end">
                <Button size="small" id="GAFAQ_CreateFAQ_btn" startIcon={<QuestionAnswerIcon />} className={classes.button} variant="contained" color="primary" onClick={handleClickOpen}>
                    Create New FAQ
                </Button>
            </Box>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <Snackbar open={show} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Faq Created Successfully!!
                </Alert>
            </Snackbar>
            <Dialog PaperProps={{ style: { borderRadius: 15 } }} fullScreen={fullScreen} open={open} fullWidth={true}
                onClose={(_) => { handleClose(); }} aria-labelledby="responsive-dialog-title" maxWidth={'md'} TransitionComponent={Transition}>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        CREATE FAQ
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Box component="span" m={1} //margin
                        className={`${classes.centerBox} ${classes.box}`} >
                        <TextareaAutosize id="GAFAQ_QuestionField" rowsMin={5} placeholder="Question" className={classes.question} value={Question}
                            onChange={(e) => setQuestion(e.target.value)} />
                    </Box>
                    <Box component="span" m={1} //margin
                        className={`${classes.centerBox} ${classes.box}`} >
                        <TextareaAutosize id="GAFAQ_AnswerField" rowsMin={8} placeholder="Answer" className={classes.answer} value={Answer}
                            onChange={(e) => setAnswer(e.target.value)} />
                    </Box>
                </DialogContent>
                <DialogActions className={classes.DialogBottom} >
                    <Button size="small" id="GAFAQ_newFAQ_btn" startIcon={<CreateIcon />} autoFocus onClick={(_) => { createFaq(); handleClose(); }} color="primary" variant="contained" disabled={!Question || !Answer}
                        style={{ borderRadius: '20px', fontSize: 12 }}>
                        Create FAQ
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default CreateFAQ