import { Backdrop, Box, Chip, CircularProgress, ClickAwayListener, DialogActions, DialogContentText, FormLabel, Grid, IconButton, InputAdornment, makeStyles, Snackbar, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete, AutocompleteCloseReason } from '@material-ui/lab';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import aproval from '../../images/users/approval.svg';
import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PuffLoader from 'react-spinners/PuffLoader';
import { regexValidEmail } from '../../constants/Constant';
import { AccessContext } from '../../models/accesscontrol/AccessContext';
import { IUserPermissions } from '../../models/accesscontrol/IUserPermissions';
import { WidgetMap } from '../../models/accesscontrol/WidgetMap';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { DialogTitleDelete, DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import { Transition } from '../../components/GlobalStyles/DialogBoxTransition';
import { IProspectUsersDetails } from '../../models/accesscontrol/ProspectUsersDetails';
import { IAckClientInfo } from '../../models/IAckReport/IAckClientInfo';
import { IGroupEmailClientCodeResult } from '../../models/NotificationCenter/TrustStatementClientCodeResult';
import { trustStatementReportShareStatus } from '../../models/accesscontrol/ClientUserAccessContext';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
                marginBottom: "0%",
                margin: theme.spacing(2),
                width: '25ch',
            },
        },
        formControl: {
            margin: theme.spacing(2),
            minWidth: 390,
            borderRadius: '10px',
            fontSize: 12,
            marginTop: '25px',
            fontWeight: 'bold',
            border: 'none',
        },
        close1: {
            float: 'right',
            marginTop: '0.8cm',
            marginBottom: '0.3cm',
            marginRight: '5px',
            borderRadius: '50px',
            fontSize: 12,
            backgroundColor: 'red',
            '&:hover': {
                backgroundColor: 'red',
            }
        },
        create: {
            float: 'right',
            marginTop: '0.8cm',
            marginRight: '15px',
            borderRadius: '50px',
            fontSize: 12,
            backgroundColor: 'green',
            "&:hover": {
                backgroundColor: 'green',
            }
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        GreenColor: {
            color: 'green',
        },
        paper1: {
            border: "2px solid black",
            fontSize: 13
        },
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        },
        yesButton: {
            background: "red",
            color: 'white',
            borderRadius: 20,
            right: '15px',
            '&:hover': {
                background: "red",
            }
        },
        MessageStyle: {
            marginTop: "15px",
            color: "black",
        },
        TextBoxStyle: {
            minWidth: 390,
            backgroundColor: 'transparent',
            color: "white",
            height: 40,
            fontSize: 12,
            fontWeight: 'bold',
            borderRadius: '10px',
            border: 'none',
        },
        NameTextBoxStyle: {
            width: "76%",
            backgroundColor: 'transparent',
            color: "white",
            height: 40,
            fontSize: 12,
            fontWeight: 'bold',
            borderRadius: '10px',
            border: 'none',
        },
        outlinedInput: {
            fontSize: 14,
            color: "black",
            height: 40,
            border: 'none',
            '&$focused $notchedOutline': {
                color: 'white',
                border: 'none'
            },
        },
        outlinedInput1: {
            fontSize: 14,
            color: "black",
            border: 'none',
            '&$focused $notchedOutline': {
                color: 'white',
                border: 'none'
            },
        },
        noBorder: {
            border: "1px solid #215176",
            fontWeight: 'bold',
            borderBottom: "3px solid blue",
            fontSize: 14,
            '&:hover': {
                borderBottom: "3px solid blue",
            }
        },
        userbutton: {
            borderRadius: 20,
            fontSize: 11,
            marginTop: '5px',
            float: 'right'
        },
        titleheader1: {
            marginLeft: '1.3cm',
            fontSize: 17,
            marginTop: '15px'
        },
        checkBoxStyle: {
            backgroundColor: "light gray",
            height: "45px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
        },
        IconSizeStyle: {
            height: '20px',
            width: '20px'
        },
        textBoxStyle: {
            marginTop: '10px',
            fontSize: 10,
        },
        LabelBoxStyle: {
            marginLeft: '15px'
        },
        paper2: {
            border: '2px solid black',
            height: 'auto',
            margin: 0
        },
        clientCodeBox: {
            width: '390px',
            marginLeft: '15px',
            marginTop: '15px',
            borderRadius: '5px',
            '& .MuiOutlinedInput-root': {
                borderBottom: '3px solid blue',
                borderRadius: '5px'
            }
        },
        valueContainer: {
            "& > :not(:last-child)": {
                marginRight: '5px'
            },
            "& > *": {
                marginBottom: '5px'
            }
        },
        boxBorder: {
            border: '2px solid gray',
            marginLeft: '15px',
            padding: '2px 8px',
            borderRadius: '5px',
            maxWidth: '390px',
            marginTop: '5px',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset'
        },
        textBoxStyle2: {
            fontSize: 10,
            '& .MuiChip-labelSmall': {
                fontSize: 10
            }
        },
        scrollMargin: {
            '& div:first-child': {
                marginBottom: '5px',
            },
            '& div:nth-child(3) div': {
                backgroundColor: '#2377e4 !important'
            },
            '& div:nth-child(3)': {
                top: '10px !important',
                bottom: '5px !important'
            },
        },
        CheckBoxBorder: {
            borderTop: '1px solid gray'
        },
        dialogePaper: {
            minWidth: "800px"
        },
        permission: {
            margin: 0,
            fontSize: 14,
        },
        notApprovedBtn: {
            borderRadius: 20,
            fontSize: 11,
            background: "#215176",
            '&:hover': {
                background: "#215176",
            }
        },
        approvedBtn: {
            borderRadius: 20,
            fontSize: 11,
            background: "green",
            '&:hover': {
                background: "green",
            }
        },
        notApprovedBtn1: {
            borderRadius: 20,
            fontSize: 11,
            color: 'white',
            background: "#215176",
            '&:hover': {
                background: "#215176",
                color: 'white',
            }
        },
    })
);

const EditClientProspectUser: React.FC<{ userRow: IProspectUsersDetails, onSave: () => void }> = (props) => {
    const { userRow } = props;
    const { state, dispatch } = useGlobalState();
    const [open, setOpen] = React.useState(false);
    const [confirmedClients, setConfirmedClients] = React.useState([]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    var newUser: AccessContext = new AccessContext();
    const [localState, setState] = React.useState(newUser);
    const classes = useRowStyles()
    const [firstnameerror, setFirstNameError] = useState("");
    const [Lastnameerror, setLastNameError] = useState("");
    const [Emailerror, setEmailError] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [isDisableFN, setIsDisableFN] = useState(false);
    const [isDisableLN, setIsDisableLN] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [selectedClients, setSelectedClients] = useState<any>([]);
    const [alertOpen, setAlertOpen] = useState(false);
    const [message, setMessage] = useState<string>('');
    const [checkAll, setCheckAll] = useState(false);
    const [ClientCodeOpen, setClientCodeOpen] = useState(false);
    const [searchClientCode, setSearchClientCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [deselectClient, setDeselectClient] = useState(false);
    const [groupEmailClientCodeList, setGroupEmailClientCodeList] = useState<IGroupEmailClientCodeResult[]>([]);

    const handleAlertClose = () => {
        setAlertOpen(false);
        setCheckAll(false);
    };

    const handleRemove = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
        setIsValid(false);
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const resetValues = () => {
        localState.firstName = null;
        localState.lastName = null;
        localState.userName = null;
        localState.phone = null;
        setFirstNameError(null);
        setLastNameError(null);
        setEmailError(null);
        setSelectedClients([]);
        setSearchClientCode("");
    }

    const handleSetUserDetails = (e) => {
        setState({ ...localState, firstName: userRow?.prospectGivenName, lastName: userRow?.prospectLastName, userName: userRow?.prospectEmailAddress, isProspectUser: true, isUserValidated: true });
    }

    const handleClickOpen = () => {
        GetClientCodeBySharedTrustStatement();

        (async () => {
            setConfirmedClients([]);
            setLoading(true);
            setOpen(true);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": searchClientCode?.trim().toLocaleUpperCase(),
                "isAdmin": false
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setConfirmedClients(r.data);
                setLoading(false);
            }).finally(() => {
                setShowProgressBar(false);
                setCheckAll(false);
            });
        })()
    };

    const handleClose = () => {
        setOpen(false);
        resetValues();
        setClientCodeOpen(false);
        setCheckAll(false);
    };

    useEffect(() => {
        if (localState.flatFeeAcRem === false
            && localState.acWaitingtoMove === false
            && localState.daysSincePlacmentofAc === false
            && localState.placementHistoryofMonthAc === false
            && localState.ageImpactofonAcResolution === false
            && localState.unReadNotifications === false) {
            setState({
                ...localState, flatFeeAcRem: true,
                daysSincePlacmentofAc: true,
                acWaitingtoMove: true,
                placementHistoryofMonthAc: true,
                ageImpactofonAcResolution: true,
                unReadNotifications: true
            });
        }
    }, [localState]);

    const handleCreate = () => {
        (async () => {
            setShowProgressBar(true);
            let selectedWidgets: number[] = [];
            for (let index = 1; index <= WidgetMap.size; index++) {
                var value = WidgetMap.get(index);
                if (value && localState[value]) {
                    selectedWidgets.push(index);
                }
            }
            localState.clientCodes = selectedClients.map(r => r.client_code);
            if (selectedClients.length <= 0) {
                setIsValid(true);
            } else {
                let request = {
                    "prospectUserId": userRow?.prospectUserId,
                    "userId": state?.userAccessContext?.id
                }
                await usePost<any>("User/InsertUserCreateHistory", request);
                let trustStatementReportRequest = {
                    "actionTakenBy": state.userAccessContext?.id,
                    "emailAddress": userRow?.prospectEmailAddress,
                    "actionId": localState?.trustStatement ? trustStatementReportShareStatus.TrustNotificationAccessProvided : trustStatementReportShareStatus?.TrustNotificationAccessNotProvided
                }
                await usePost<any>("InsertTrustReportShareHistory", trustStatementReportRequest);
                await usePost<{ userInfo: IUserPermissions, totalRecordCount: number }>("User/SaveUser", localState).then(async (r) => {
                    setShowProgressBar(true);
                    const data = {
                        'userId': r.data,
                        'widgets': selectedWidgets.join(',')
                    };
                    await usePost("User/AddWidgetsForClient", data).then((ClientsWidget) => {
                        setOpen(false);
                        setSnackbarOpen(true);
                        props.onSave();
                    }).finally(() => {
                        setShowProgressBar(false);
                    });
                }).catch((err) => {
                    if (err.response.status == 400) {
                        setAlertOpen(true);
                        setMessage(err.response.data);
                        dispatch({ type: GlobalStateAction.Error, error: err.statusText });
                        setShowProgressBar(false);
                    }
                });
            }
        })().finally(() => {
            SendEmailtoAuthorizedUsers();
            resetValues();
        })
    };

    const SendEmailtoAuthorizedUsers = () => {
        //Email will trigger when a user is confirmed
        let sendEmail = {
            "userName": localState?.userName,
            "firstName": userRow?.prospectGivenName,
            "lastName": userRow?.prospectLastName
        }
        usePost<any>("SendEmailtoAuthorizedUsers", sendEmail);
    }

    const HandleFirstNameVal = (e) => {
        const FirstNameVal = e.target.value;
        if (!FirstNameVal.match(/[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/)) {
            setFirstNameError("");
            setIsDisableFN(false);
        } else {
            setFirstNameError("please enter characters only");
            setIsDisableFN(true);
        }
    };

    const HandleLastNameVal = (e) => {
        const LastNameVal = e.target.value;
        if (!LastNameVal.match(/[~!@#%^&*()-=+<>,.{};|?:[\]\\$'"]/)) {
            setLastNameError("");
            setIsDisableLN(false);
        } else {
            setLastNameError("please enter characters only");
            setIsDisableLN(true);
        }
    };

    const validateEmail = (e) => {
        const email = e.target.value;
        if (regexValidEmail.test(email)) {
            setEmailError("");
            setIsDisable(false);
        } else {
            setEmailError("please enter valid mail address");
            setIsDisable(true);
        }
    };

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...localState,
            [name]: event.target.value,
        });
    };

    const handleChecked = (event) => {
        const name = event.target.name;
        setState({
            ...localState,
            [name]: event.target.checked,
        });
    };

    const handleClientSelect = (event, selected) => {
        setSelectedClients(selected);
        if (selected?.length <= 0) {
            setCheckAll(false);
        }
    }

    const checkAllChange = (event) => {
        setCheckAll(event.target.checked);
        if (event.target.checked) {
            var NewClientList = confirmedClients.filter((c) => {
                return !selectedClients.find((r) => {
                    return c.client_code == r.client_code
                })
            });
            setSelectedClients([...new Set(selectedClients?.concat(NewClientList?.map((r) => r)))]);
            setClientCodeOpen(false);
        }
        setCheckAll(false);
        handleClear();
        setSearchClientCode("");
    };

    const DeselectAllChange = (event) => {
        setDeselectClient(event.target.checked);
        if (event.target.checked) {
            var NewClientList = selectedClients.filter((c) => {
                return !confirmedClients.find((r) => {
                    return c.client_code == r.client_code
                })
            });
            setSelectedClients(NewClientList);
            setClientCodeOpen(false);
        }
        setDeselectClient(false);
        handleClear();
        setSearchClientCode("");
    };

    const handleSearchClient = (e) => {
        setSearchClientCode(e.target.value);
    }

    const handleClear = () => {
        (async () => {
            setOpen(true);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": "",
                "isAdmin": false
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setConfirmedClients(r.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    };

    const handleClickAway = (e) => {
        (async () => {
            setClientCodeOpen(false);
            let request = {
                "userId": state?.userAccessContext?.id,
                "client_code": "",
                "isAdmin": false
            }
            await usePost<IAckClientInfo[]>("Client/searchAckClientCode", request).then((r) => {
                setConfirmedClients(r.data);
            }).finally(() => {
                setShowProgressBar(false);
            });
        })()
    };

    const onDelete = (clientcode) => () => {
        setSelectedClients((value) => value.filter((v) => v.client_code !== clientcode));
    };

    const GetClientCodeBySharedTrustStatement = () => {
        setShowProgressBar(true);
        (async () => {
            await useFetch<IGroupEmailClientCodeResult[]>("GetClientCodeBySharedTrustStatement?EmailAddress=" + userRow?.prospectEmailAddress).then((r) => {
                setGroupEmailClientCodeList(r?.data);
            })
        })().finally(() => {
            setShowProgressBar(false);
        })
    }

    return (
        <React.Fragment>
            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={alertOpen} onClose={handleAlertClose} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        CREATE USER
                    </Typography>
                </DialogTitleDelete>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="h6" className={classes.MessageStyle} gutterBottom>
                            {message}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" variant="contained" onClick={(e) => { setAlertOpen(false); setOpen(true); }} className={classes.yesButton}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Button size="small" id="EU_Edit" startIcon={<img src={aproval} width="20px" />}
                variant="contained" color="primary" disabled={userRow?.isPermissionUpdated || !userRow?.isActive} onClick={(e) => { handleClickOpen(); handleSetUserDetails(e) }}
                className={userRow?.isPermissionUpdated || !userRow?.isActive ? classes.approvedBtn : classes.notApprovedBtn} >
                {userRow?.isPermissionUpdated ?
                    <span>USER AUTHORIZED</span>
                    :
                    <span>SET PERMISSIONS</span>
                }
            </Button>
            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen}
                open={open}
                classes={{ paper: classes.dialogePaper }}
                PaperProps={{ style: { borderRadius: 15 } }}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth={'lg'}
                fullWidth={true}>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        CREATE NEW USER
                    </Typography>
                </DialogTitleHeader>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogContent >
                    <Grid container>
                        <Grid item xs={5} >
                            <div>
                                <form className={classes.root} noValidate autoComplete="off" >
                                    <TextField id="CAU_FirstName"
                                        size="small" label="First Name"
                                        variant="outlined" name="firstName"
                                        onChange={(e) => { handleChange(e); HandleFirstNameVal(e); }}
                                        helperText={firstnameerror}
                                        error={!!firstnameerror}
                                        value={localState?.firstName}
                                        inputProps={{ maxlength: 25 }}
                                        InputProps={{
                                            classes: {
                                                root: classes.outlinedInput,
                                                notchedOutline: classes.noBorder
                                            }
                                        }}
                                        className={classes.NameTextBoxStyle} />
                                    <TextField id="CAU_LastName" size="small"
                                        label="Last Name" variant="outlined"
                                        name="lastName"
                                        onChange={(e) => { handleChange(e); HandleLastNameVal(e); }}
                                        helperText={Lastnameerror}
                                        error={!!Lastnameerror}
                                        value={localState?.lastName}
                                        inputProps={{ maxlength: 25 }}
                                        InputProps={{
                                            classes: {
                                                root: classes.outlinedInput,
                                                notchedOutline: classes.noBorder
                                            }
                                        }}
                                        className={classes.NameTextBoxStyle} />
                                </form>
                            </div>
                            <div>
                                <form className={classes.root} noValidate autoComplete="off" >
                                    <TextField id="CAU_Email" size="small" label="Email"
                                        variant="outlined" name="userName"
                                        disabled
                                        type="email" onChange={(e) => { handleChange(e); validateEmail(e) }}
                                        helperText={Emailerror} error={!!Emailerror}
                                        value={localState?.userName}
                                        InputProps={{
                                            classes: {
                                                root: classes.outlinedInput,
                                                notchedOutline: classes.noBorder
                                            }
                                        }}
                                        inputProps={{ maxlength: 50 }} className={classes.TextBoxStyle} />
                                </form>
                            </div>
                            <FormControl variant="outlined" size="small" className={classes.formControl} >
                                <InputLabel htmlFor="outlined-age-native-simple">Admin Level</InputLabel>
                                <Select native
                                    id="CAU_AdminLevel"
                                    onChange={handleChange}
                                    value={localState.role}
                                    label="Template"
                                    inputProps={{
                                        name: 'role',
                                        id: 'AdminLevel',
                                        classes: {
                                            root: classes.outlinedInput1,
                                            notchedOutline: classes.noBorder
                                        }
                                    }}
                                >
                                    <option value={0}>Regular User</option>
                                    <option value={1}>Client Admin</option>
                                </Select>
                            </FormControl>
                            {groupEmailClientCodeList[0]?.clientCode != "User not found" ?
                                <Box m={0} mt={1} padding={"3px"} ml={"8px"} mr={"8px"} borderRadius={"5px"} bgcolor={'hsl(48deg 100% 96% / 50%)'} border={'1px solid hsl(36deg 100% 34% / 15%)'} >
                                    <Typography variant="body2" style={{ marginLeft: '10px', color: 'black' }}>
                                        <b>Shared Trust Statement FIle Client Code</b>
                                    </Typography>
                                    <Scrollbars autoHide
                                        autoHideTimeout={1000}
                                        autoHideDuration={10}
                                        autoHeight
                                        autoHeightMin="auto"
                                        autoHeightMax={80}
                                        style={{ width: '100%' }}>
                                        <Typography variant="body2" style={{ marginLeft: '10px', marginTop: '3px', color: 'hsl(36, 100%, 27%)' }}>
                                            {groupEmailClientCodeList?.flatMap(r => r.clientCode).join(', ')}
                                        </Typography>
                                    </Scrollbars>
                                </Box>
                                : null
                            }
                            <Box className={classes.LabelBoxStyle}>
                                <FormLabel style={{ color: 'green' }}> <b>Selected Client Code : {selectedClients?.length}</b></FormLabel>
                            </Box>
                            <div>
                                {!loading && selectedClients?.length > 0 ?
                                    <Paper className={classes.boxBorder}>
                                        <Scrollbars autoHide
                                            autoHideTimeout={1000}
                                            autoHideDuration={10}
                                            autoHeight
                                            className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                                            autoHeightMin="auto"
                                            autoHeightMax={100}
                                            style={{ width: '365px' }}>
                                            <div className={`${classes.valueContainer}`}>
                                                {selectedClients.map((v) => (
                                                    <Chip key={v.client_code} size="small" color="secondary" label={v.client_code} onDelete={onDelete(v.client_code)} />
                                                ))}
                                            </div>
                                        </Scrollbars>
                                    </Paper>
                                    :
                                    null
                                }
                                <ClickAwayListener onClickAway={handleClickAway}>
                                    <Box>
                                        <Autocomplete style={{ marginTop: '10px' }}
                                            multiple
                                            loading={loading}
                                            filterOptions={(options, state) => options}
                                            className={classes.clientCodeBox}
                                            autoComplete={false}
                                            id="checkboxes-tags-demo"
                                            options={confirmedClients}
                                            classes={{ paper: classes.paper2 }}
                                            disableClearable
                                            value={selectedClients}
                                            open={ClientCodeOpen}
                                            renderTags={() => null}
                                            onChange={(e, selected) => { handleClientSelect(e, selected); }}
                                            onOpen={() => {
                                                setClientCodeOpen(true);
                                            }}
                                            onClose={(e: any, reason: AutocompleteCloseReason) => {
                                                if (reason === "toggleInput") {
                                                    setClientCodeOpen(false);
                                                }
                                            }}
                                            getOptionDisabled={(option) => option.client_code}
                                            getOptionLabel={(option) => option.client_code}
                                            getOptionSelected={(option, value) => option.client_code == value.client_code}
                                            renderOption={(option, { selected }) => (
                                                <span className={`${selected ? classes.GreenColor : null}`} style={{ fontSize: 12, marginLeft: '5px' }}>
                                                    {option?.client_code + "--" + option?.client_name}
                                                </span>
                                            )}
                                            ListboxProps={
                                                {
                                                    style: {
                                                        maxHeight: '200px'
                                                    }
                                                }
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth size="small"
                                                    value={searchClientCode} variant="outlined"
                                                    type='text' placeholder="Search Clients"
                                                    onChange={(e) => { handleSearchClient(e) }}
                                                    onKeyDown={event => {
                                                        if (event.key === 'Enter') { handleSearchClient(event); handleClickOpen(); setClientCodeOpen(true); }
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {loading ? <CircularProgress color="primary" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={(_) => { handleClickOpen(); setClientCodeOpen(true); }}>
                                                                        <SearchIcon className={classes.IconSizeStyle} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                                <Box display="flex" width="100%" className={classes.CheckBoxBorder}>
                                                                    <Box className={classes.checkBoxStyle} width="60%">
                                                                        <Checkbox size="small" disabled={loading || !confirmedClients.length}
                                                                            checked={checkAll}
                                                                            onChange={checkAllChange}
                                                                            id="check-all"
                                                                        />
                                                                        <span style={{ fontSize: 14 }}>Select All</span>
                                                                    </Box>
                                                                    <Box className={classes.checkBoxStyle} width="40%">
                                                                        <Checkbox size="small" disabled={loading || !confirmedClients.length}
                                                                            checked={deselectClient}
                                                                            onChange={(e) => { DeselectAllChange(e); }}
                                                                            id="check-all"
                                                                        />
                                                                        <span style={{ fontSize: 14 }}> Deselect All</span>
                                                                    </Box>
                                                                </Box>
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                    className={classes.textBoxStyle}
                                                />
                                            )}
                                        />
                                    </Box>
                                </ClickAwayListener>
                            </div>
                        </Grid>
                        <Box style={{ flexGrow: 1 }}>
                            <Grid container spacing={3} >
                                <Grid item xs={6} md={4}>
                                    <Typography variant="h6" gutterBottom className={classes.titleheader1}>{"PERMISSIONS"}</Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <FormControl component="fieldset" >
                                            <FormGroup>
                                                <FormControlLabel
                                                    id="CAU_paymentUpdates" className={classes.permission}
                                                    control={<Checkbox onChange={handleChecked} name="paymentUpdates" size="small" />}
                                                    labelPlacement="end"
                                                    label="Payment Updates"
                                                />
                                                <FormControlLabel
                                                    id="CAU_closingAccounts" className={classes.permission}
                                                    control={<Checkbox onChange={handleChecked} name="closingAccounts" size="small" />}
                                                    labelPlacement="end"
                                                    label="Close Accounts"
                                                />
                                                <FormControlLabel
                                                    id="CAU_rollAccounts" className={classes.permission}
                                                    control={<Checkbox onChange={handleChecked} name="rollAccounts" size="small" />}
                                                    labelPlacement="end"
                                                    label="Roll Accounts"
                                                />
                                                <FormControlLabel
                                                    id="CAU_placeAccounts" className={classes.permission}
                                                    control={<Checkbox onChange={handleChecked} name="placeAccounts" size="small" />}
                                                    labelPlacement="end"
                                                    label="Place Accounts"
                                                />
                                                <FormControlLabel
                                                    id="CAU_editReports" className={classes.permission}
                                                    control={<Checkbox onChange={handleChecked} name="editReports" size="small" />}
                                                    labelPlacement="end"
                                                    label="Edit Reports"
                                                />
                                                <FormControlLabel
                                                    id="CAU_createNewUsers" className={classes.permission}
                                                    control={<Checkbox onChange={handleChecked} name="createNewUsers" size="small" />}
                                                    labelPlacement="end"
                                                    label="Create New Users"
                                                />
                                                <FormControlLabel
                                                    id="CAU_uploadUnmappedFile" className={classes.permission}
                                                    control={<Checkbox onChange={handleChecked} name="uploadUnmappedFile" size="small" />}
                                                    labelPlacement="end"
                                                    label="Upload Unmapped File"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Box>
                                </Grid>

                                <Grid item xs={6} md={6} >
                                    <Typography variant="h6" gutterBottom className={classes.titleheader1}>{"NOTIFICATIONS"}</Typography>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <FormControl component="fieldset" >
                                            <FormGroup>
                                                <FormControlLabel className={classes.permission}
                                                    id="U_CU_disputeValidationNotification"
                                                    control={<Checkbox size="small" onChange={handleChecked} name="disputeValidationNotification" />}
                                                    label="Dispute Validation Notification"
                                                />
                                                <FormControlLabel className={classes.permission}
                                                    id="U_CU_paymentVerificationNotification"
                                                    control={<Checkbox size="small" onChange={handleChecked} name="paymentVerificationNotification" />}
                                                    label="Payment Verification Notification"
                                                />
                                                <FormControlLabel className={classes.permission}
                                                    id="U_CU_fileIssuesNotification"
                                                    control={<Checkbox size="small" onChange={handleChecked} name="fileIssuesNotification" />}
                                                    label="File Issues Notification"
                                                />
                                                <FormControlLabel className={classes.permission}
                                                    id="U_CU_backupDocumentRequestNotification"
                                                    control={<Checkbox size="small" onChange={handleChecked} name="backupDocumentRequestNotification" />}
                                                    label="Backup Document Request Notification"
                                                />
                                                <FormControlLabel className={classes.permission}
                                                    id="U_CU_settlementRequestsNotification"
                                                    control={<Checkbox size="small" onChange={handleChecked} name="settlementRequestsNotification" />}
                                                    label="Settlement Requests Notification"
                                                />
                                                <FormControlLabel className={classes.permission}
                                                    id="U_CU_generalNotification"
                                                    control={<Checkbox size="small" onChange={handleChecked} name="generalNotification" />}
                                                    label="General Notifications"
                                                />
                                                <FormControlLabel className={classes.permission}
                                                    id="U_CU_RollReportNotification"
                                                    control={<Checkbox size="small" onChange={handleChecked} name="rollReportNotification" />}
                                                    label="Roll Report Notification"
                                                />
                                                <FormControlLabel className={classes.permission}
                                                    id="U_CU_AckReportNotification"
                                                    control={<Checkbox size="small" onChange={handleChecked} name="ackReportNotification" />}
                                                    label="Acknowledgement Report Notification"
                                                />
                                                <FormControlLabel className={classes.permission}
                                                    id="U_CU_trustStatement"
                                                    control={<Checkbox size="small" onChange={(e) => { handleChecked(e); }} name="trustStatement" checked={localState.trustStatement} />}
                                                    label="View Trust Statement"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>


                        <Grid item xs={12}>
                            <Button className={classes.close1} size="small" id="U_CU_Close_btn" onClick={handleClose} color="primary"
                                variant="contained" startIcon={<CancelIcon />}>
                                Close
                            </Button>
                            <Button className={classes.create} size="small" id="CAU_CreateNewUser" autoFocus
                                disabled={isDisable || isDisableFN || isDisableLN || (!localState.firstName || !localState.lastName || !localState.userName || !selectedClients.length)}
                                onClick={handleCreate} color="primary" variant="contained" startIcon={<AccountCircleIcon />}>
                                Confirm User
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Snackbar open={snackbarOpen} className="snackBarStyle" anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={handleRemove}>
                <Alert onClose={handleRemove} severity="success" className="alertStyle">
                    User {localState.firstName}{localState.lastName} is Created Successfully !
                </Alert>
            </Snackbar>
            <Snackbar className="snackBarStyle" open={isValid} anchorOrigin={{
                vertical: 'top', horizontal: 'center'
            }} autoHideDuration={4000} onClose={handleRemove}>
                <Alert onClose={handleRemove} severity="error" className="alertStyle">
                    Please select atleast one client code.
                </Alert>
            </Snackbar>
        </React.Fragment >
    );
}

export default EditClientProspectUser