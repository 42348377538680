import {
    Backdrop, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, ExpansionPanel, ExpansionPanelDetails, FormControl,
    FormControlLabel, Grid, IconButton, Select, Snackbar, TableContainer, TextField, Typography
} from '@material-ui/core';

import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import AgentTreeView from './AgentTreeView';
import { useStyles } from './AG_TemplateCss';
import SaveIcon from '@material-ui/icons/Save';
import InfoIcon from '@mui/icons-material/Info';
import PuffLoader from 'react-spinners/PuffLoader';
import CancelIcon from '@material-ui/icons/Cancel';
import { usePost } from '../../../utils/apiHelper';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useGlobalState } from '../../../store/GlobalStore';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { AllowOnlyAlphabetsNumbers } from '../../../constants/Constant';
import CompactExpansionPanelSummary from '../../GlobalStyles/ExpansionPanelSummary';
import { ActivityList } from '../../../models/AgentInquiry/IAgentActivityListCount';
import { IActivityContract } from '../../../models/AgentTemplate/IActivityContract';
import { IActivityGroupName } from '../../../models/AgentTemplate/IAgentCustomizeControl';

const CreateTemplate: React.FC<{ listEmailActivityJson: ActivityList[], listAdminActivityJson: ActivityList[], listCaseCreatedActivityJson: ActivityList[], activityGroupName: IActivityGroupName[], onSave: () => void }> = (props) => {
    const classes = useStyles();
    const { state } = useGlobalState();
    const [open, setOpen] = React.useState(false);
    const [isValid, setIsValid] = useState(false);
    const [nameError, setnameError] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [createTemplate, setCreateTemplate] = useState(false);
    const [defaultMethod, setDefaultMethod] = useState<number>(0);
    const [isFteTemplate, setIsFteTemplate] = useState<boolean>(false);
    const [saveActivity, setSaveActivity] = useState<IActivityContract[]>([{ activityFieldId: 53 }, { activityFieldId: 54 }, { activityFieldId: 55 }]);

    const activityGroupNameWithNone = [{ activityGroupId: 0, activityGroupName: "Select Default Communication Method" }, ...props?.activityGroupName];

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSaveActivity([{ activityFieldId: 53 }, { activityFieldId: 54 }, { activityFieldId: 55 }]);
        setTemplateName("");
    };

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setCreateTemplate(false);
    };

    const HandleTempNameVal = (e) => {
        const newValue = e.target.value;
        if (!newValue.match(AllowOnlyAlphabetsNumbers)) {
            setnameError("");
            setIsValid(false);
            setTemplateName(newValue);
        } else {
            setnameError("Please enter characters and numbers only");
            setIsValid(true);
        }
    };

    // Callback function to receive the value from the child
    const handleGetSelectedActivity = (e, parentActivityId) => {
        let activityList = _.cloneDeep(saveActivity);
        const { checked } = e.target;
        const value = Number(e.target.value);
        // Add or remove the child activity ID based on the checked status
        if (checked) {
            if (activityList.findIndex(x => x.activityFieldId === value) === -1) {
                activityList.push({ activityFieldId: value });
            }
        } else {
            const index = activityList.findIndex(x => x.activityFieldId === value);
            if (index !== -1) {
                activityList.splice(index, 1);
            }
        }
        // Add root activity ID (parentActivityId) and its ancestors recursively
        if (parentActivityId && !activityList.some(item => item.activityFieldId === parentActivityId)) {
            activityList.push({ activityFieldId: parentActivityId });
        }
        setSaveActivity(activityList);
    };

    const handleSaveTempalte = () => {
        (async () => {
            let request = {
                "templateName": templateName,
                "createdDate": moment(new Date()),
                "createdBy": state?.userAccessContext?.id,
                "activityMapingDetail": _.cloneDeep(saveActivity),
                "isFteTemplate": isFteTemplate,
                "defaultMethod": defaultMethod
            }
            await usePost("AgentUser/CreateAgentTemplate", request);
            props?.onSave();
            handleClose();
            setShowProgressBar(false);
            setCreateTemplate(true);
        })()
    }

    const handleFteChecked = (event) => {
        setIsFteTemplate(event.target.checked);
    }

    const handleEditGroupNameChange = (event) => {
        let name = event.target.name;
        activityGroupNameWithNone?.forEach((a) => {
            if (event.target.value === a?.activityGroupName) {
                setDefaultMethod(a?.activityGroupId);
            }
        })
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={ProgressBar}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>
            <Button id="CreateTemplate_btn" size="small" startIcon={<NoteAddIcon />} variant="contained" color="primary" onClick={handleClickOpen} className={classes.btnManTemp}>
                Create New Template
            </Button>
            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} classes={{ paper: classes.dialogePaper }} TransitionComponent={Transition}
                onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        AGENT ACTIVITIES TEMPLATE
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Grid container spacing={0}>
                        <Grid item xs={6} sm={6} >
                            <TextField id="CNT_Name"
                                label="Enter Template Name"
                                size="small" name="name"
                                variant="outlined"
                                fullWidth margin="dense"
                                helperText={nameError}
                                error={!!nameError}
                                inputProps={{
                                    maxLength: 250
                                }}
                                onChange={(e) => { setTemplateName(e.target.value); HandleTempNameVal(e); }} />
                        </Grid>
                        <Grid item xs={3} sm={3} >
                            <FormControl size="small" variant="outlined" style={{ paddingTop: '8px', paddingLeft: '10px' }} /* className={classes.activityDropDownStyle} */>
                                <Select native
                                    onChange={(e) => { handleEditGroupNameChange(e); }}
                                    name="activityGroupName" variant="outlined"
                                    inputProps={{
                                        name: 'activityGroupName',
                                        id: 'outlined-age-native-simple',
                                    }}>
                                    {activityGroupNameWithNone?.map((r) => {
                                        return (
                                            <option value={r?.activityGroupName}>{r?.activityGroupName}</option>
                                        )
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} sm={3} style={{ display: 'flex' }}>
                            <FormControl component="fieldset" style={{ paddingTop: '5px' }} >
                                <FormControlLabel
                                    id="AG_emailreceived"
                                    control={<Checkbox size="small" name="FTETemplate" />}
                                    labelPlacement="end"
                                    label="FTE Template"
                                    className={classes.FTEChecked}
                                    onChange={(e) => { handleFteChecked(e); }}
                                />
                            </FormControl>
                            <IconButton className={`${"zoom-in-box"}`} style={{ padding: '0px' }}>
                                <LightTooltip title="Selection of 'FTE Template' will track the Agent Users against their assignment and removal.">
                                    <InfoIcon />
                                </LightTooltip>
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <DialogTitle id="responsive-dialog-title" className={classes.textAlginEnd}>
                                <span className={classes.textStyle}><b>CUSTOMIZE ACTIVITIES</b></span>
                            </DialogTitle>
                            <Grid container spacing={0} style={{ padding: '8px 16px' }}>
                                <Grid container spacing={1} style={{ padding: '0px 10px', minHeight: '10px' }}>
                                    <Grid item xs={12} md={4}>
                                        <ExpansionPanel className={classes.controlWidth1} defaultExpanded >
                                            <CompactExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expandicon} />} >
                                                <Typography align="left" variant="subtitle1" className={classes.TextNormal1} >
                                                    Email
                                                </Typography>
                                            </CompactExpansionPanelSummary>
                                            <ExpansionPanelDetails className={classes.ContentStyle} style={{ padding: "0px" }}>
                                                <AgentTreeView data={props?.listEmailActivityJson} onSaveActivity={handleGetSelectedActivity} />
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <ExpansionPanel className={classes.controlWidth1} defaultExpanded >
                                            <CompactExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expandicon} />} >
                                                <Typography align="left" variant="subtitle1" className={classes.TextNormal1} >
                                                    Admin
                                                </Typography>
                                            </CompactExpansionPanelSummary>
                                            <ExpansionPanelDetails className={classes.ContentStyle} style={{ padding: "0px" }}>
                                                <AgentTreeView data={props?.listAdminActivityJson} onSaveActivity={handleGetSelectedActivity} />
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <ExpansionPanel className={classes.controlWidth1} defaultExpanded >
                                            <CompactExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.expandicon} />} >
                                                <Typography align="left" variant="subtitle1" className={classes.TextNormal1} >
                                                    Case Created/ Escalation
                                                </Typography>
                                            </CompactExpansionPanelSummary>
                                            <ExpansionPanelDetails className={classes.ContentStyle} style={{ padding: "0px" }}>
                                                <AgentTreeView data={props?.listCaseCreatedActivityJson} onSaveActivity={handleGetSelectedActivity} />
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.submitbtn}
                        startIcon={<SaveIcon />} onClick={() => { handleSaveTempalte(); setShowProgressBar(true); setOpen(false); }}
                        variant="contained" autoFocus color="primary" disabled={!templateName.length || !saveActivity?.length || isValid}>
                        Submit
                    </Button>
                    <Button id="CNT_CreateTemplate_Btn" size="small" className={classes.noButton1} startIcon={<CancelIcon />}
                        onClick={handleClose} variant="contained" autoFocus color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar className="snackBarStyle" open={createTemplate} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Template Created Successfully!
                </Alert>
            </Snackbar>
        </React.Fragment >
    );
}

export default CreateTemplate