import { css } from "@emotion/react";
import { Box, Card, CardActions, CardContent, Divider, FormControlLabel, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import ClockLoader from "react-spinners/ClockLoader";
import warning from '../images/WarningImage/complain.png';
import { GlobalStateAction, useGlobalState } from '../store/GlobalStore';
import { usePost } from '../utils/apiHelper';
interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}
interface Props extends SwitchProps {
    classes: Styles;
}

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '100%',
        maxWidth: '100%',
        height: '200px',
        borderRadius: '15px',
        border: '1px solid gray'
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    image: {
        width: '20px',
        height: '20px',
    },
    warningColor: {
        minWidth: 300,
        borderRadius: '15px',
        border: '1px solid gray',
        backgroundColor: '#FA8072'
    },
    cardActionStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiButton-label': {
            fontSize: 12
        },
        '& .MuiTypography-body1': {
            fontSize: 14
        }
    },
    cardTitle: {
        fontSize: 16,
        color: 'white',
        backgroundColor: '#2377E4',
    },
    currencyInputField: {
        height: '30px',
        width: '60px',
        textIndent: '10px',
        fontSize: 13
    },
    dividerStyle: {
        borderTop: '3px solid blue',
    },
    cardContentText: {
        fontSize: 13,
        padding: '10px'
    },
    btnStyle: {
        borderRadius: '20px',
    },
    timeSize: {
        fontSize: 16,
    },
    positionAbsolute: {
        position: 'absolute',
    },
    boxStyle: {
        gap: '80px',
        marginTop: '150px',
        marginBottom: '50px',
    },
    warningStyle: {
        fontSize: 14,
        color: 'orange',
    },
    gridflex: {
        display: 'flex',
        justifyContent: 'space-between',
    }
}))

const IOSSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            '&$checked': {
                transform: 'translateX(16px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#52d869',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: '#52d869',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 24,
            height: 24,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    }),
)(({ classes, ...props }: Props) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

function multipleApi() {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const [addNumber, setAddNumber] = useState<any>('');
    const [addNumberDashboard, setAddNumberDashboard] = useState<any>('');
    const [addNumberReport, setAddNumberReport] = useState<any>('');
    const [alertMsg, setAlertMsg] = useState(false);
    const [timerInquiry, setTimerInquiry] = useState(0);
    const [timerDashboard, setTimerDashboard] = useState(0);
    const [timerReport, setTimerReport] = useState(0);
    let [loadingInquiry, setLoadingInquiry] = useState(false);
    let [loadingDashboard, setLoadingDashboard] = useState(false);
    let [loadingReport, setLoadingReport] = useState(false);
    let [color, setColor] = useState("orange");
    const countRef = useRef(null);
    const [disableTrigger, setDisableTrigger] = useState(false);
    const [ToggleSwitch, setToggleSwitch] = useState(false);

    const override = css`
  display: flex;
  margin: 0 auto;
  border-color: green;`;

    //Api End points
    let endpoints = Array(Number(addNumber)).fill("SearchDebtorInquiry");

    let DashboardEndpoint = Array(Number(addNumberDashboard)).fill("Account/GetDashboardCount");

    let ReportDisplayEndpoint = Array(Number(addNumberReport)).fill("ReportDisplay");

    //Event Handlers
    const handleInquiryStart = () => {
        countRef.current = setInterval(() => {
            setTimerInquiry((timerInquiry) => timerInquiry + 1);
        }, 1000)
    }

    const handleDashboardStart = () => {
        countRef.current = setInterval(() => {
            setTimerDashboard((timerDashboard) => timerDashboard + 1);
        }, 1000)
    }

    const handleReportStart = () => {
        countRef.current = setInterval(() => {
            setTimerReport((timerReport) => timerReport + 1);
        }, 1000)
    }

    const handlePause = () => {
        clearInterval(countRef.current);
    }

    const handleReset = () => {
        clearInterval(countRef.current);
        setTimerInquiry(0);
        setTimerDashboard(0);
        setTimerReport(0);
    }

    const formatTimeInquiry = () => {
        const getSeconds = `0${(timerInquiry % 60)}`.slice(-2)
        return `${getSeconds}`
    }

    const formatTimeDashboard = () => {
        const getSeconds = `0${(timerDashboard % 60)}`.slice(-2)
        return `${getSeconds}`
    }

    const formatTimeReport = () => {
        const getSeconds = `0${(timerReport % 60)}`.slice(-2)
        return `${getSeconds}`
    }

    const handleChange = (value: string): void => {
        setAddNumber(value === undefined ? "1" : value);
    }

    const handleDashboardChange = (value: string): void => {
        setAddNumberDashboard(value === undefined ? "1" : value);
    }

    const handleReportChange = (value: string): void => {
        setAddNumberReport(value === undefined ? "1" : value);
    }

    const FireMultipleCall = () => {
        (async () => {
            try {
                let request = {
                    "userId": state.userAccessContext?.id,
                    "searchText": "",
                    "searchType": "",
                    "orderBy": " ",
                    "orderDirection": "asc",
                    "rowsPerPage": 10,
                    "startingRecordNumber": 1,
                    "viewall": ToggleSwitch
                }
                handleInquiryStart();
                setLoadingInquiry(true);
                setDisableTrigger(true);
                Promise.all(endpoints.map((endpoint) => usePost(endpoint, request))).then(
                    axios.spread((...allData) => {
                        const GetData = ({ allData });
                        handlePause();
                        setLoadingInquiry(false);
                        setDisableTrigger(false);
                    }),
                );
            } catch (ex) {
                dispatch({ type: GlobalStateAction.Idle });
                dispatch({ type: GlobalStateAction.Error, error: ex });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })();
    }

    const FireDashboardMultipleCall = () => {
        (async () => {
            try {
                let request = { clients: "AMGNEW", userId: 3242 };
                handleDashboardStart();
                setLoadingDashboard(true);
                setDisableTrigger(true);
                Promise.all(DashboardEndpoint.map((DashboardEndpoint) => usePost(DashboardEndpoint, request))).then(
                    axios.spread((...allData) => {
                        const GetData = ({ allData });
                        handlePause();
                        setLoadingDashboard(false);
                        setDisableTrigger(false);
                    })
                );
            } catch (ex) {
                dispatch({ type: GlobalStateAction.Idle });
                dispatch({ type: GlobalStateAction.Error, error: ex });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    const FireReportMultipleCall = () => {
        (async () => {
            try {
                let request = {
                    "clients": "AAIPST,AARPCI,ABALAV,ABB999,ABBNWN,ABBPST,ABCDSP,ABCPST,ABLPMG,ABPEST,ABRBPS,ABSPST,ABVEPT,ACATRK,ARMSOL",
                    "reportId": 59,
                    "orderBy": null,
                    "orderDirection": "asc",
                    "startingRecordNumber": 1,
                    "rowsPerPage": 10,
                    "userId": state.userAccessContext?.id
                }
                handleReportStart();
                setLoadingReport(true);
                setDisableTrigger(true);
                Promise.all(ReportDisplayEndpoint.map((ReportDisplayEndpoint) => usePost(ReportDisplayEndpoint, request))).then(
                    axios.spread((...allData) => {
                        const GetData = ({ allData });
                        handlePause();
                        setLoadingReport(false);
                        setDisableTrigger(false);
                    })
                );
            } catch (ex) {
                dispatch({ type: GlobalStateAction.Idle });
                dispatch({ type: GlobalStateAction.Error, error: ex });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    useEffect(() => {
        if ((addNumber > 25) || (addNumberDashboard > 25) || (addNumberReport > 25)) {
            setAlertMsg(true);
        } else {
            setAlertMsg(false);
        }
    }, [addNumberDashboard, addNumberReport, addNumber])

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToggleSwitch(event.target.checked);
    };

    return (
        <div>
            <span className={classes.positionAbsolute}>
                {alertMsg === true ?
                    <>
                        <img alt="Icon for warning" src={warning} className={classes.image} />
                        <span className={classes.warningStyle}>
                            Warning
                        </span>
                    </>
                    :
                    (null)
                }
            </span>

            <Box display='flex' p={1} className={classes.boxStyle} justifyContent="center" justifyItems="center">
                <Grid></Grid>
                <Grid xs={12} className={classes.gridflex}>
                    <Grid md={3} xs={12}>
                        <Card className={`${addNumber > 25 ? classes.warningColor : classes.root}`} >
                            <Typography className={classes.cardTitle}>
                                Inquiry
                            </Typography>
                            <Divider className={classes.dividerStyle} />
                            <CardContent>
                                <span className={classes.cardContentText}>
                                    No. of Parallel Calls
                                </span>
                                <CurrencyInput
                                    id="Test_Inquiry_Call"
                                    value={addNumber}
                                    placeholder="1"
                                    maxLength={10}
                                    onValueChange={handleChange}
                                    autoComplete='off'
                                    allowDecimals={false}
                                    allowNegativeValue={false}
                                    disableAbbreviations={true}
                                    disableGroupSeparators={true}
                                    className={classes.currencyInputField}
                                />
                            </CardContent>
                            <CardActions className={classes.cardActionStyle}>
                                <Button variant="contained" color="primary" size="small" onClick={() => { FireMultipleCall(); }} className={classes.btnStyle} disabled={disableTrigger}>
                                    Trigger
                                </Button>
                                <FormControlLabel
                                    control={<IOSSwitch checked={ToggleSwitch} onChange={handleSwitchChange} name="checked" />}
                                    label="Load All"
                                />
                            </CardActions>
                            <CardActions className={classes.cardActionStyle}>
                                <Box>
                                    <ClockLoader color={color} loading={loadingInquiry} css={override} size={30} />
                                </Box>
                                <span className={classes.timeSize}>
                                    {formatTimeInquiry()} sec
                                </span>
                                <Button className={classes.btnStyle} variant="contained" onClick={handleReset} color='primary' size='small'>
                                    Reset
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid md={3} xs={12}>
                        <Card className={`${addNumberDashboard > 25 ? classes.warningColor : classes.root}`}>
                            <Typography className={classes.cardTitle}>
                                Dashboard
                            </Typography>
                            <Divider className={classes.dividerStyle} />
                            <CardContent>
                                <span className={classes.cardContentText}>
                                    No. of Parallel Calls
                                </span>
                                <CurrencyInput
                                    id="Test_Dashboard_Call"
                                    value={addNumberDashboard}
                                    placeholder="1"
                                    maxLength={10}
                                    onValueChange={handleDashboardChange}
                                    autoComplete='off'
                                    allowDecimals={false}
                                    allowNegativeValue={false}
                                    disableAbbreviations={true}
                                    disableGroupSeparators={true}
                                    className={classes.currencyInputField}
                                />
                            </CardContent>
                            <CardActions className={classes.cardActionStyle}>
                                <Button variant="contained" color="primary" size="small" onClick={() => { FireDashboardMultipleCall(); }} className={classes.btnStyle} disabled={disableTrigger}>
                                    Trigger
                                </Button>
                            </CardActions>
                            <CardActions className={classes.cardActionStyle}>
                                <Box>
                                    <ClockLoader color={color} loading={loadingDashboard} css={override} size={30} />
                                </Box>
                                <span className={classes.timeSize}>
                                    {formatTimeDashboard()} sec
                                </span>
                                <Button className={classes.btnStyle} variant="contained" onClick={handleReset} color='primary' size='small'>
                                    Reset
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid md={3} xs={12}>
                        <Card className={`${addNumberReport > 25 ? classes.warningColor : classes.root}`}>
                            <Typography className={classes.cardTitle}>
                                Report
                            </Typography>
                            <Divider className={classes.dividerStyle} />
                            <CardContent>
                                <span className={classes.cardContentText}>
                                    No. of Parallel Calls
                                </span>
                                <CurrencyInput
                                    id="Test_Dashboard_Call"
                                    value={addNumberReport}
                                    placeholder="1"
                                    maxLength={10}
                                    onValueChange={handleReportChange}
                                    autoComplete='off'
                                    allowDecimals={false}
                                    allowNegativeValue={false}
                                    disableAbbreviations={true}
                                    disableGroupSeparators={true}
                                    className={classes.currencyInputField}
                                />
                            </CardContent>
                            <CardActions className={classes.cardActionStyle}>
                                <Button variant="contained" color="primary" size="small" onClick={() => { FireReportMultipleCall(); }} className={classes.btnStyle} disabled={disableTrigger}>
                                    Trigger
                                </Button>
                            </CardActions>
                            <CardActions className={classes.cardActionStyle}>
                                <Box>
                                    <ClockLoader color={color} loading={loadingReport} css={override} size={30} />
                                </Box>
                                <span className={classes.timeSize}>
                                    {formatTimeReport()} sec
                                </span>
                                <Button className={classes.btnStyle} variant="contained" onClick={handleReset} color='primary' size='small'>
                                    Reset
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <Grid xs={1}></Grid>
            </Box>
        </div >
    )
}

export default multipleApi
