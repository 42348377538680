import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, Grid, makeStyles, Paper, createStyles, Theme,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery, useTheme, Snackbar
} from '@material-ui/core';
import * as _ from 'lodash';
import PuffLoader from "react-spinners/PuffLoader";
import React, { useEffect, useState } from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
// Icons
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
// Models and Componants
import FilterReport from './FilterReport';
import { IFilter } from '../../models/Reports/IFilter';
import { useFetch, usePost } from '../../utils/apiHelper';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import { IReportField } from '../../models/Reports/IReportField';
import { IReportsList } from '../../models/Reports/ReportsList';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { IReportOperator } from '../../models/Reports/IReportOperator';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { IReportFieldsValues } from "../../models/Reports/IReportFieldsValues";
import { DialogTitleDelete } from '../../components/GlobalStyles/DialogStyle';
import { Link } from 'react-router-dom';

const useRowStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableBody: {
      maxHeight: 400,
      marginTop: '10px'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    reportname: {
      width: '100%',
      boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
      borderRadius: 5,
      backgroundColor: 'white',
    },
    outlinedInput: {
      fontSize: 15,
      color: "black",
      borderRadius: 5,
      paddingLeft: '10px',
      '&$focused $notchedOutline': {
        color: 'white',
        border: 'none',
      },
    },
    checkboxText: {
      marginTop: '5px',
      padding: '0px',
      marginLeft: '0px'
    },
    createReport: {
      borderRadius: '20px',
      marginRight: '15px',
      fontSize: 12
    },
    filterbutton: {
      borderRadius: '20px',
      marginRight: '15px',
      fontSize: 12
    },
    titleheader: {
      fontWeight: 'bold',
      marginTop: '5px',
      color: 'white'
    },
    yesButton: {
      background: "red",
      color: 'white',
      borderRadius: 20,
      right: '15px',
      '&:hover': {
        background: "red",
      }
    },
    MessageStyle: {
      marginTop: "15px",
      color: "black",
    },
    createreport: {
      fontSize: 11,
      marginTop: '10px'
    },
    tableHeaderStyle: {
      color: "white",
      fontSize: "12px",
      whiteSpace: 'pre-wrap',
      background: "#007FFF",
      padding: '2px 10px'
    },
    tableCellColor: {
      background: "#007FFF",
      color: "white",
      padding: '3px 10px',
      fontSize: '12px'
    },
    blueBorderBottom: {
      fontSize: 14,
      color: "black",
      borderRadius: '4px 4px 5px 5px',
      '& .MuiOutlinedInput-input': {
        borderBottom: '3px solid blue',
        borderRadius: '4px 4px 5px 5px',
      },
      '& .MuiInputBase-input': {
        padding: '11px',
      },
    },
    backButton: {
      borderRadius: 20,
      marginTop: '10px',
      marginRight: '20px',
      fontSize: '12px'
    }
  })
)

const CreateReport: React.FC<{
  reportRow?: IReportsList, clients?: any, getReports?: () => void, reportId?: any,
  states?: any, statuses?: any, phases?: any, isValid?: boolean, operators?: IReportOperator[], fieldLists?: IReportField[],
}> = (props) => {
  const theme = useTheme();
  const classes = useRowStyles();
  const [Filters, setFilters] = useState<IFilter[]>([{ field_id: null, operator_id: null, value: "", index: 0 }]);
  const [OperatorsList, setOperatorsList] = useState<IReportOperator[]>([]);
  const { reportId, reportRow, clients, states, statuses, phases } = props;
  const [fieldsList, setFieldsList] = useState<IReportField[]>([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [defaultReport, setDefaultReport] = useState(false);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [isEdit, setIsEdit] = useState(reportId != -1);
  const [filter, setFilter] = React.useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [ReportName, setReportName] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [show, setShow] = React.useState(false);
  const [disable, setDisable] = useState(false);
  const { state, dispatch } = useGlobalState();
  const UserName = state.userAccessContext?.id;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  useEffect(() => {
    if (state?.userAccessContext?.role === 2) {
      setDisable(false);
    } else if (state?.userAccessContext?.role === 3) {
      setDisable(false);
    } else if (reportRow?.default_report === 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, []);


  async function createReportAsync() {
    if (areFiltersNotNull()) {
      dispatch({ type: GlobalStateAction.Busy });
      setShowProgressBar(true);
      try {
        let request = {
          "name": ReportName,
          "user_id": UserName,
          "filters": Filters,
          "date_created": (new Date()).toISOString(),
          "report_id": reportId,
          "default_report": defaultReport
        }
        await usePost<any>("CreateReportWithFilters", request);
        props.getReports();
        setShow(true);
        handleClose();
        setShowProgressBar(false);
      } catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    } else {
      setAlertOpen(true);
      setShowProgressBar(false);
    }
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShow(false);
    setErrorMessage(false);
  };

  const closeFilterSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setFilter(false);
  };

  const handleChange = (event) => {
    setDefaultReport(event.target.checked);
    setIsDefault(event.target.checked);
  };

  async function getReportsFieldListAsync() {
    try {
      dispatch({ type: GlobalStateAction.Busy });
      await useFetch<IReportFieldsValues>("ReportOperatorsFieldsValues").then((ReportOperatorsFields) => {
        setOperatorsList(ReportOperatorsFields.data.operators);
        setFieldsList(ReportOperatorsFields.data.fields);
      });
      setOpen(true);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex });
      dispatch({ type: GlobalStateAction.Idle });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
    }
  }

  const handleClickOpen = () => {
    getReportsFieldListAsync();
  };

  const handleClose = () => {
    setFilters([{ field_id: null, operator_id: null, value: "", index: 0 }]);
    setReportName("");
    setOpen(false);
    setIsDefault(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const areFiltersNotNull = () => {
    if (!ReportName)
      return false;
    for (let i = 0; i < Filters.length; i++) {
      if (!Filters[i]["field_id"] || !Filters[i]["operator_id"] || !Filters[i]["value"])
        return false;
    }
    return true;
  }

  const addFilter = () => {
    let newFilters = _.cloneDeep(Filters);
    let lastElement = newFilters[newFilters.length - 1];
    newFilters.push({ field_id: -1, operator_id: null, value: "", index: lastElement.index + 1 });
    setFilters(newFilters);
    setFilter(true);
  }

  const handleDeleteFilter = (index) => {
    let newFilters = _.cloneDeep(Filters);
    if (Filters.length == 1)
      return;
    newFilters = newFilters.filter(filter => filter.index != index);
    newFilters.forEach((f, indexnum) => {
      f.index = indexnum
    });
    setErrorMessage(true);
    setFilters(newFilters);
  }

  const onChangeFilter = (index, filter) => {
    let newFilters = _.cloneDeep(Filters);
    newFilters[index] = filter;
    setFilters(newFilters);
  }


  const handleReportNameChange = (event) => {
    setReportName(event.target.value);
  }

  const HandleReportNameVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[~`!@#^&*()+=%<>?.,:;{}/|\\$'"]/)) {
      setError("");
      setIsValid(false);
      setReportName(newValue);
    } else {
      setError("please enter characters and numbers only");
      setIsValid(true);
    }
  };

  const headCells = [
    { id: "filter_Number", disablePadding: false, label: " FILTER NO", sortable: true, fontSize: '12px' },
    { id: "field_Name", disablePadding: false, label: "FIELD NAME", sortable: true, fontSize: '12px' },
    { id: "condition", disablePadding: false, label: " CONDITION", sortable: true, fontSize: '12px' },
    { id: "field_Values", disablePadding: false, label: " FIELD VALUES", sortable: true, fontSize: '12px' },
    { id: "action", disablePadding: false, label: "ACTION", sortable: true, fontSize: '12px' },
  ];

  return (
    <React.Fragment>
      <div>
        <Button variant='contained' color="primary" size="small" component={Link} to="/report" startIcon={<DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />} className={classes.backButton}  >
          Back
        </Button>
        <Button style={!isEdit ? { borderRadius: 20 } : { borderRadius: 20 }} size="small" className={classes.createreport}
          variant="contained" startIcon={<NoteAddIcon />} disabled={isEdit ? disable : null} color="primary" onClick={handleClickOpen}>
          Create Report
        </Button>

        <Dialog TransitionComponent={Transition}
          fullScreen={fullScreen} open={open} onClose={handleClose} PaperProps={{ style: { borderRadius: 15 } }}
          aria-labelledby="responsive-dialog-title" fullWidth={true} maxWidth={'md'} >
          <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', marginTop: '5px', color: 'white' }}>
              CREATE REPORT
            </Typography>
          </DialogTitleHeader>
          <Backdrop className={classes.backdrop} open={ProgressBar}>
            <PuffLoader size={80} color={"white"} speedMultiplier={1} />
          </Backdrop>
          <DialogContent >
            <Grid item xs={12}>
              <TextField id="CR_Name" size='small' required
                onChange={(e) => { handleReportNameChange(e); HandleReportNameVal(e); }}
                placeholder="Enter Report Name" fullWidth margin="normal" variant="outlined"
                value={ReportName} className={classes.reportname}
                InputProps={{ classes: { root: classes.blueBorderBottom } }}
                InputLabelProps={{ shrink: true, }} helperText={error}
                error={!!error} inputProps={{ maxlength: 100 }} />
              <div>

                {state.userAccessContext?.role === 2 || state.userAccessContext?.role === 3 ?
                  <FormControlLabel
                    control={<Checkbox checked={isDefault} onChange={handleChange} style={{ padding: '0px' }}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />}
                    className={classes.checkboxText}
                    label={<div style={{ marginLeft: '5px' }}>Default report? (visible to all users)</div>} />
                  : null}
              </div>
            </Grid>
            <TableContainer component={Paper} className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
              <Table aria-label="customized table" size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    {headCells.map(headCell => (
                      <TableCell align="center"
                        key={headCell.id}
                        className={classes.tableHeaderStyle}>
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Filters.map((filter) => {
                    return (
                      fieldsList?.length > 0 && OperatorsList?.length > 0 ?
                        <FilterReport key={'id' + filter.field_id + 'index' + filter.index} fieldsList={fieldsList}
                          OperatorsList={OperatorsList} filter={filter} handleDeleteFilter={handleDeleteFilter}
                          onChangeFilter={onChangeFilter} states={states} statuses={statuses} phases={phases}
                          clients={clients}>
                        </FilterReport>
                        : null)
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button id="CR_AddFilter" autoFocus color="primary" variant="contained" size="small"
              className={classes.filterbutton}
              onClick={addFilter} startIcon={<AddIcon />}>
              add Filter
            </Button>
            <Button autoFocus color="primary" variant="contained" disabled={isValid || !ReportName} size="small"
              onClick={() => { createReportAsync(); }} startIcon={<CreateIcon />} className={classes.createReport}>
              Create Report
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog TransitionComponent={Transition}
          fullScreen={fullScreen} open={alertOpen} onClose={handleAlertClose} maxWidth={'xs'}
          aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
          <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
            <Typography variant="h6" gutterBottom className={classes.titleheader}>
              REPORT
            </Typography>
          </DialogTitleDelete>
          <DialogContent>
            <DialogContentText>
              <Typography variant="h6" className={classes.MessageStyle} gutterBottom>
                Please Fill all the data
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions >
            <Button size="small" id="DU_ok_btn" variant="contained" onClick={(e) => { setAlertOpen(false); setOpen(true); }} className={classes.yesButton}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar className="snackBarStyle" open={show} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
            Report Created Successfully!
          </Alert>
        </Snackbar>
        <Snackbar className="snackBarStyle" open={filter} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={2000} onClose={closeFilterSnackbar}>
          <Alert onClose={closeFilterSnackbar} severity="success" className="alertStyle">
            Report Filter Added Successfully!
          </Alert>
        </Snackbar>
        <Snackbar className="snackBarStyle" open={errorMessage} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={2000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
            Report Filter Deleted Successfully!
          </Alert>
        </Snackbar>
      </div>
    </React.Fragment >
  );
}

export default CreateReport