import { makeStyles, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { IAckList } from '../../models/IAckReport/IAckList';
import { useGlobalState } from '../../store/GlobalStore';
import StyledCell from "../GlobalStyles/StyledCell";
import DeleteAckReport from './DeleteAckReport';
import EditAckReport from './EditAckReport';

const useStyles = makeStyles((theme) => ({
    textStyle: {
        textDecoration: 'none',
        cursor: 'pointer',
        color: 'black',
        fontSize: 16
    }
}))

const AckExpandableList: React.FC<{ row: IAckList, onClick: (id: string) => void, onCreate: () => void, onDelete: () => void, clients?: any }> = (props) => {
    const { row } = props;
    const classes = useStyles();
    let history = useHistory();
    const { state, dispatch } = useGlobalState();

    const hanldeTransmitData = () => {
        history.push('/ackbuilder', {
            phaseName: row.phaseName, client_code: row.client_code, reportName: row.reportName, startDate: row.startDate, endDate: row.endDate, client_name: row.client_name
        })
    }

    return (
        <React.Fragment>
            <TableRow>
                <StyledCell onClick={hanldeTransmitData} style={{ width: 550, }}>
                    <Typography className={classes.textStyle} >{row.reportName}</Typography>
                </StyledCell>

                <StyledCell onClick={hanldeTransmitData}>
                    <Typography className={classes.textStyle} >{moment(row.createdDate).format(state.GlobalUtils?.settingValue)}</Typography>
                </StyledCell>

                <StyledCell>
                    <Typography className={classes.textStyle} onClick={hanldeTransmitData}>{row.client_code}</Typography>
                </StyledCell>

                <StyledCell align="left" >
                    <Typography className={classes.textStyle} onClick={hanldeTransmitData}>{row.phaseName}</Typography>
                </StyledCell>

                <StyledCell>
                    <EditAckReport getReports={props.onCreate} reportId={row.id} reportRow={row} clients={props.clients}/>
                </StyledCell>

                <StyledCell>
                    <DeleteAckReport reportRow={row} onDelete={props.onDelete} />
                </StyledCell>
            </TableRow>
        </React.Fragment>
    )
}

export default AckExpandableList