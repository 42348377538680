import { Backdrop, Box, CssBaseline, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
// components
import Footer from '../../components/Footer/Footer';
import StyledCell from "../../components/GlobalStyles/StyledCell";
// Icons
import CreateNotification from "../../components/GlobalAdmin/GlobalMessage/CreateNotification";
import DeleteNotification from '../../components/GlobalAdmin/GlobalMessage/DeleteNotification';
import EditNotification from '../../components/GlobalAdmin/GlobalMessage/EditNotification';
// Models
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { IGlobalMessage } from "../../models/GlobalMessage/IGlobalMessage";
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch } from '../../utils/apiHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    div: {
      marginLeft: "30cm",
      marginTop: "4%"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    grid: {
      padding: '8px'
    },
    maingrid: {
      backgroundColor: 'white',
      borderRadius: '10px',
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    tableBody: {
      maxHeight: 505,
      minHeight: 505,
      [theme.breakpoints.up('lg')]: {
        maxHeight: 'calc( 100vh - 205px)',
        minHeight: 'calc( 100vh - 205px)',
      }
    },
    headername: {
      textAlign: 'left'
    },
    footer: {
      marginTop: '15px',
      float: 'right',
    },
    TableCellStyle: {
      background: "#007FFF",
      color: "white",
      fontSize: 12,
      padding: '3px 15px'
    },
    TableCellStyle1: {
      fontSize: 13,
      padding: '3px 15px'
    },
  }),
);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: "name", numeric: false, disablePadding: false, label: "NAME" },
  { id: "start_date", numeric: false, disablePadding: false, label: "START DATE", sortable: true },
  { id: "end_date", numeric: false, disablePadding: false, label: "END DATE", sortable: true },
  { id: "date_created", numeric: false, disablePadding: false, label: "DATE CREATED", sortable: true },
  { id: "", numeric: false, disablePadding: false, label: "ACTIONS", sortable: false },
  { id: "", numeric: false, disablePadding: false, label: "DELETE", sortable: false }
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id} id="GM_TableHeader"
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.TableCellStyle}
          >
            <TableSortLabel
              id="GM_TableSortLable"
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label || headCell.sortable}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function GlobalMessage() {
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();
  const [GlobalMessageList, setGlobalMessageList] = useState<IGlobalMessage[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("date_created");
  const [ProgressBar, setshowProgressBar] = useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (GlobalMessageList.length <= 0) {
      getGlobalMessageAsync();
    }
  }, [GlobalMessageList]);

  async function getGlobalMessageAsync() {
    dispatch({ type: GlobalStateAction.Busy });
    setshowProgressBar(true);
    await useFetch<IGlobalMessage[]>("GetGlobalMessage").then((GetMessage) => {
      setGlobalMessageList(GetMessage.data);
    }).finally(() => {
      setshowProgressBar(false);
    });
  }

  const handleSaveWithGlobalMessageInfo = (GlobalMessage: IGlobalMessage) => {
    getGlobalMessageAsync();
  };

  const handleDeleteSuccess = () => {
    getGlobalMessageAsync();
  };

  const handleSave = () => {
    getGlobalMessageAsync();
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <CssBaseline />
        <Grid container className={classes.grid} >
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Typography variant="h5" gutterBottom className={`${"headertitle"} ${classes.headername}`}>
              <b> GLOBAL MESSAGE </b>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <CreateNotification onSave={handleSave} />
          </Grid>
          <Grid item xs={12}>
            <Paper >
              <TableContainer component={Paper} className={`${classes.tableBody} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table aria-label="customized table" size='small' stickyHeader>
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(GlobalMessageList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), getComparator(order, orderBy)).map(
                      (row) => {
                        let startDate = (row.start_date !== null ? moment(row.start_date).format(state.GlobalUtils?.settingValue) : null);
                        let createdDate = (row.date_created !== null ? moment(row.date_created).format(state.GlobalUtils?.settingValue) : null);
                        let endDate = (row.end_date !== null ? moment(row.end_date).format(state.GlobalUtils?.settingValue) : null);
                        return (
                          <StyledTableRow hover >
                            <StyledCell component="th" scope="row" className={classes.TableCellStyle1}>{row.name}</StyledCell>
                            <StyledCell className={classes.TableCellStyle1}>{startDate}</StyledCell>
                            <StyledCell className={classes.TableCellStyle1}>{endDate}</StyledCell>
                            <StyledCell className={classes.TableCellStyle1}>{createdDate}</StyledCell>
                            <StyledCell className={classes.TableCellStyle1}>
                              <EditNotification Messages={row} onSave={handleSaveWithGlobalMessageInfo} />
                            </StyledCell>
                            <StyledCell className={classes.TableCellStyle1}>
                              <DeleteNotification Messages={row} onDelete={handleDeleteSuccess} />
                            </StyledCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box m={0} display="flex" style={{ flexDirection: 'row' }}>
                <Box display="flex" alignContent="flex-end" justifyContent="flex-end" width="60%" >
                  <div className={classes.footer}>
                    <Footer />
                  </div>
                </Box>
                <Box display="flex" alignContent="flex-start" justifyContent="flex-start" width="40%">
                  <TablePagination
                    id="GM_TablePagination"
                    rowsPerPageOptions={[15, 25, 50]}
                    component="div"
                    count={GlobalMessageList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}