import { createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { IAdditionalAckBuilder } from '../../models/IAckReport/IAckBuilder';
import { useGlobalState } from '../../store/GlobalStore';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 12,
        },
        rootHidden: {
            display: "none"
        },
        collapsePadding: {
            padding: '8px'
        },
        tablefont: {
            fontSize: 12,
            padding:'5px 0px 5px 5px !important'
        }
    })
);

const AdditionalAckExpandableRow: React.FC<{ row: IAdditionalAckBuilder }> = (props) => {
    const classes = useRowStyles();
    const { row } = props;
    const { state } = useGlobalState();

    return (
        <React.Fragment>
            <TableRow >
                <TableCell className={classes.tablefont}>{row?.dbT_COMPANY}</TableCell>
                <TableCell className={classes.tablefont}>{row?.dbT_CLIENT}</TableCell>
                <TableCell className={classes.tablefont}>{row?.clientName}</TableCell>
                <TableCell className={classes.tablefont}>{row?.location}</TableCell>
                <TableCell className={classes.tablefont}>{row?.dbT_NO}</TableCell>
                <TableCell className={classes.tablefont}>{row?.dbT_CLNT_ACNT1}</TableCell>
                <TableCell className={classes.tablefont}>{row?.dbT_NAME}</TableCell>
                <TableCell className={classes.tablefont}>{row?.placemenT_DATE !== null ? moment(row?.placemenT_DATE).format(state.GlobalUtils?.settingValue) : row?.placemenT_DATE}</TableCell>
                <TableCell className={classes.tablefont}>{row?.dbT_LAST_CHG_DATE !== null ? moment(row?.dbT_LAST_CHG_DATE).format(state.GlobalUtils?.settingValue) : row?.dbT_LAST_CHG_DATE}</TableCell>
                <TableCell className={classes.tablefont}>${row?.amount_Placed?.toFixed(2)}</TableCell>
                <TableCell className={classes.tablefont}>${row?.trH_AMT1}</TableCell>
                <TableCell className={classes.tablefont}>${row?.trH_PRINC_DUE}</TableCell>
                <TableCell className={classes.tablefont}>{row?.trH_DATE !== null ? moment(row?.trH_DATE).format(state.GlobalUtils?.settingValue) : row?.trH_DATE}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default AdditionalAckExpandableRow;
