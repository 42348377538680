import {
    Backdrop, Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography
} from '@material-ui/core';
import React, { useState } from 'react';
// Icons
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
// Models
import { PuffLoader } from 'react-spinners';
import { regexGroupName } from '../../../constants/Constant';
import { usePost } from '../../../utils/apiHelper';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './ManageGroupCss';

const CreateManageGroup: React.FC<{ onSave: () => void }> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [agentGroupName, setAgentGroupName] = useState<string>("");
    const [agentGroupNameError, setAgentGroupNameError] = useState<string>("");
    const [ProgressBar, setShowProgressBar] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleGroupClickSubmit = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "activityGroupId": null,
                "activityGroupName": agentGroupName,
            };
            await usePost<any>('AgentUser/InsertAgentActivityGroup', request);
            setShowProgressBar(false);
            props?.onSave();
            setOpen(false);
        })()
    }

    const handleChangeNameValidation = (e) => {
        const GroupName = e.target.value;
        if (GroupName.match(regexGroupName)) {
            setAgentGroupNameError("");
            setAgentGroupName(GroupName); // only set when successful
        } else if (GroupName === "") {
            setAgentGroupNameError("Group Name is Required");
        } else {
            setAgentGroupNameError("Please enter valid group name");
        }
    };

    return (
        <React.Fragment>
            <Button id="CreateTemplate_btn" size="small" startIcon={<NoteAddIcon />} variant="contained" color="primary" onClick={handleClickOpen} className={classes.btnManTemp}>
                Create New Group
            </Button>

            <Dialog open={open} PaperProps={{ style: { borderRadius: 15 } }} maxWidth={'sm'} TransitionComponent={Transition}
                onClose={handleClose} aria-labelledby="responsive-dialog-title" >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        CREATE NEW GROUP
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Backdrop className={classes.backdrop} open={ProgressBar}>
                        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <Grid container spacing={0}>
                        <Grid xs={12}>
                            <Typography variant='subtitle1' className={classes.activityName}>
                                <b>Group Name</b>
                            </Typography>
                        </Grid>
                        <Grid xs={12}>
                            <TextField id="outlined-basic"
                                label="Group Name" variant="outlined"
                                size='small'
                                error={!!agentGroupNameError}
                                helperText={agentGroupNameError}
                                onChange={(e) => { setAgentGroupName(e.target.value); handleChangeNameValidation(e) }}
                                className={classes.groupNameTextfield}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id="CPO_OK_btn" size="small" color="primary" className={classes.createGroup} startIcon={<SaveIcon />} autoFocus onClick={handleGroupClickSubmit}>
                        Create Group
                    </Button>
                    <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.cancelGroup} startIcon={<CancelIcon />} autoFocus onClick={handleClose} variant="contained">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

export default CreateManageGroup