import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        head: {
            display: "flex",
            flexDirection: "column",
            borderSpacing: "1cm",
            width: 375,
            color: "primary",
            marginLeft: '25px',
            marginTop: '5px'
        },
        groupedOutlined: {
            "&:not(:first-child)": {
                marginLeft: -1
            },
            "&:not(:last-child)": {
                borderRightColor: "rgba(0, 0, 0, 0.23)"
            }
        },
        button: {
            borderRadius: 7,
            width: 145,
            color: "white",
            fontSize: 10,
            fontWeight: 'bold'
        },
        buttonStyle: {
            paddingBottom: "8px",
            paddingTop: "8px",
            borderRadius: 8,
            color: "white",
            fontSize: 11
        },
        appBar: {
            position: 'relative',
            overflowY: 'hidden',
            minHeight: '40px',
            borderBottom: '2px solid #0072E5'
        },
        Documents: {
            border: "2px solid black",
            borderRadius: 8,
            marginTop: '5px',
            maxHeight: '30px',
            '& .MuiToolbar-regular': {
                maxHeight: '30px !important'
            }
        },
        title: {
            flex: 1,
            color: '#0072E5',
            textAlign: 'center',
            fontSize: 18,
            marginRight: '100px'
        },
        titleheader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white'
        },
        cancel: {
            background: "red",
            color: "white",
            fontSize: 12,
            borderRadius: 20,
            marginRight: '20px',
            '&:hover': {
                background: "red",
            }
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        yesbutton: {
            backgroundColor: 'green',
            borderRadius: '20px',
            color: 'white',
            fontSize: 12,
            '&:hover': {
                backgroundColor: 'green',
                color: 'white',
            }
        },
        nobutton: {
            background: "red",
            borderRadius: "20px",
            color: 'white',
            fontSize: 12,
            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            }
        },
        MessageStyle: {
            marginTop: "15px",
            color: "black",
            fontSize: 14
        },
        yesButton: {
            background: "red",
            color: 'white',
            borderRadius: 20,
            right: '15px',
            '&:hover': {
                background: "red",
            }
        },
        norecord: {
            color: "red",
            marginTop: "10px",
            fontSize: 14,
            marginLeft: '5px'
        },
        downloadincon: {
            fontSize: 25,
            color: '#1E90FF'
        },
        closeicon: {
            marginLeft: "1cm",
            color: "black",
            fontSize: 14
        },
        documentname: {
            marginLeft: "10px",
            color: "black",
            fontSize: 14,
            marginTop: '10px'
        },
        Visible1: {
            color: '#1E90FF'
        },
        Iconbutton: {
            padding: '4px 4px'
        },
        Visible: {
            fontSize: 22,
            color: '#1E90FF',
            marginLeft: '5px'
        },
        NonVisible: {
            fontSize: 22,
            color: '#AEAEAE',
            marginLeft: '5px'
        },
        menuButton: {
            marginTop: '5px',
            marginBottom: '5px',
            height: '40px',
            backgroundColor: 'white',
            cursor: 'default',
            '&:hover': {
                backgroundColor: 'white',
            }
        },
        menuButton1: {
            width: '120px',
            height: '40px'
        },
        dialog: {
            '& .MuiDialog-paper': {
                overflowY: 'hidden'
            }
        },
        dialogeDocumentPaper: {
            minWidth: "950px"
        },
        documentStyle: {
            display: 'flex',
            flexDirection: 'row',
        },
        dialogSpacingTop: {
            marginTop: '15px'
        },
        typoStyle: {
            marginLeft: "10px",
            color: "black",
            fontSize: 13,
            marginTop: '5px'
        },
        typoTitle1: {
            textAlign: 'center',
            marginTop: "10px",
            color: "black",
            fontSize: 18
        },
        LetterStyle: {
            color: "black",
            textAlign: 'center',
            marginTop: "10px",
            fontSize: 18
        },
        displayFlex: {
            display: 'flex',
        },
        btnSpacing: {
            marginLeft: '5px',
            marginBottom: '5px'
        },
        iconStyle: {
            fontSize: 17,
            color: '#1E90FF',
            marginLeft: '5px'
        },
        documentButton: {
            borderRadius: 7,
            fontSize: 11,
            width: 155,
            fontWeight: 'bold'
        },
        buttoncloseSpacing: {
            padding: '8px 15px 8px 15px'
        },
        HeaderStyle: {
            backgroundColor: '#bfe8fe',
            maxHeight: '30px',
            '& .MuiToolbar-regular': {
                maxHeight: '30px !important'
            }
        },
        toolbarStyle: {
            backgroundColor: 'white',
            minHeight: '40px'
        },
        documentNotApproved: {
            borderRadius: 20,
            fontSize: 11,
            background: "#215176",
            '&:hover': {
                background: "#215176",
            }
        },
        documentApproved: {
            borderRadius: 20,
            fontSize: 11,
            background: "green",
            '&:hover': {
                background: "green",
            }
        },
        container: {
            backgroundColor: "#ffffff",
            padding: "8px 20px",
            marginTop: "15px",
            borderRadius: "5px",
            overflowY: "auto",
            overflowX: "hidden",
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
            filter: 'drop-shadow(0 -6mm 4mm gray))',
            // boxShadow: 'rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px',
            // boxShadow: "0px 1px 2px 0px rgba(103, 128, 159, 1)",
            maxHeight: 500,
            minHeight: 'auto',
            [theme.breakpoints.up("lg")]: {
                minHeight: "calc( 100vh - 480px)",
                maxHeight: "calc( 100vh - 480px)",
                // minHeight: "calc( 100vh - 480px)",
            }
        },
        dialogeDocument: {
            minWidth: "750px"
        },
        DropzoneArea: {
            minHeight: '160px',
            fontSize: '12px !important',
            border: '2px dashed blue',
            backgroundColor: '#f1f5ff',
            boxShadow: 'rgba(0, 0, 0, 0.35) 3px 0px 3px 0px',
            borderRadius: '5px'
        },
        DropzoneAreaText: {
            fontSize: '18px !important',
            fontWeight: 'bold',
            color: 'blue'
        },
        UploadStyle: {
            textAlign: 'center',
            marginTop: "5px",
            padding: '5px',
            backgroundColor: 'rgba(16, 185, 129, 0.12)',
            color: 'rgb(11, 129, 90)',
            fontSize: 14,
            borderRadius: '5px'
        },
        flexDisplay: {
            display: 'flex',
            justifyContent: 'center',
        },
        noFiles: {
            color: "red",
            marginTop: "10px",
            fontSize: 14,
            marginLeft: '5px',
            textAlign: 'center'
        },
        noRecordsColor: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            marginTop: '35px',
        },
        Note: {
            backgroundColor: 'rgba(118, 53, 220, 0.08)',
            color: 'rgb(32,10,105)',
            borderRadius: '5px',
            padding: '10px',
            margin: '0px 0px 10px',
            fontSize: '13px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        noButton: {
            background: "red",
            borderRadius: 20,
            color: 'white',
            fontSize: 12,
            marginRight: '15px',
            '&:hover': {
                background: "red",
                color: 'white'
            }
        },
        MessageStyle1: {
            color: "black",
            fontSize: 17
        },
    })
)

export default useStyles