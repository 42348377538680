import {
    Backdrop,
    Box, Button, createStyles, Dialog, DialogContent, FormControlLabel,
    Grid, IconButton, makeStyles, Switch, Theme, Typography, useMediaQuery, useTheme, withStyles
} from '@material-ui/core';
import red from "@material-ui/core/colors/red";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ForwardIcon from '@mui/icons-material/Forward';
import React, { useEffect, useState } from 'react';
// Images Icon
import CSS_Impact from '../../../images/ADF/adfcontrol.gif';
import Caution from '../../../images/ADF/complain.png';
import DEV from '../../../images/ADF/dev.svg';
import PROD from '../../../images/ADF/prod.svg';
import UAT from '../../../images/ADF/uat.svg';
// Models
import { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import { WithWidth } from '@material-ui/core/withWidth';
import { Link } from 'react-router-dom';
import { IAdfEnv } from "../../../models/GLobalSettings/IAdfEnv";
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import Footer from '../../Footer/Footer';
import AvatarTooltip from '../../GlobalStyles/AvatarTooltip';
interface Styles extends Partial<Record<SwitchClassKey, string>> {
    focusVisible?: string;
}
interface Props extends SwitchProps {
    classes: Styles;
}

const useStyles = makeStyles((theme) => ({
    title: {
        color: "#474641",
        textAlign: "center",
        fontWeight: 'bold',
        fontSize: 30,
        marginBottom: '10px'
    },
    text: {
        color: "black",
        textAlign: "center",
        marginTop: '10px',
        fontWeight: 'bold',
        fontSize: 19
    },
    button1: {
        fontSize: 13,
        color: 'white',
        textTransform: "none",
        fontWeight: 'bold',
        backgroundColor: 'green',
        borderRadius: 20,
        marginLeft: '10px',
        marginTop: '5px',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    button2: {
        fontSize: 13,
        color: 'white',
        textTransform: "none",
        backgroundColor: 'red',
        fontWeight: 'bold',
        borderRadius: 20,
        marginTop: '5px',
        '&:hover': {
            backgroundColor: 'red',
        }
    },
    image1: {
        marginTop: '0.2cm',
        width: '100px',
        height: '100px',
    },
    card: {
        display: 'block',
        width: '10.5cm',
        height: '2.5cm',
        backgroundColor: 'white',
        borderRadius: 20,
        flexDirection: 'column',
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 20,
        height: '100 %',
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        margin: 0,
        backdropFilter: 'blur(5px)',
    },
    dialogbox: {
        height: '100%',
        border: "3px solid #0D324D",
        boxShadow: '0px 1px 15px 1px rgba(103, 128, 159, 1)',
    },
    dialogheader: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: '#e65100',
        textAlign: 'center',
        fontSize: 28
    },
    timeout: {
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'black',
        marginTop: '0.5cm',
        fontSize: 20
    },
    countdown: {
        color: red[700],
        marginLeft: '5px'
    },
    divdialog: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: '5px'
    },
    sidebarItem1: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '5px',
        transition: '0.2s',
        cursor: 'pointer',
        padding: '10px',
        height: '2cm',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
    },
    transformImage: {
        transform: 'rotateY(180deg)'
    },
    sidenavigation: {
        backgroundColor: '#d1d5db',
        width: '100%',
    },
    note: {
        textAlign: 'center',
        maxWidth: '70%',
        fontSize: '20px',
        justifyItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginLeft: '7cm',
        height: '50px',
    },
    turnonBox: {
        flexDirection: "row",
        marginTop: '15px',
        padding: 15,
    },
    sidebar: {
        width: "17rem",
        backgroundColor: 'white',
        transition: "all 0.2s ease",
        display: 'block',
        whiteSpace: 'nowrap',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        height: "calc(100vh - 100px)",
        borderLeft: '10px solid White',
        borderRight: '10px solid White'
    },
    sidebarItem: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '5px',
        transition: '0.2s',
        cursor: 'pointer',
        padding: '8px 10px',
        marginTop: '4px',
        height: 'auto',
        '&:hover': {
            backgroundColor: '#c8facd',
            color: '#00ab55'
        }
    },
    sidebarCollapsed: {
        borderTopColor: 'white',
        width: "4.5rem",
        height: "calc(100vh - 100px)",
        backgroundColor: 'white',
        transition: "all 0.2s ease",
        display: 'block',
        whiteSpace: 'nowrap',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        borderLeft: '7px solid White',
        borderRight: '10px solid White'
    },
    flexDispaly: {
        display: 'flex',
    },
    sidebarHeader: {
        display: 'flex',
        padding: '5px 5px',
        '& .MuiSvgIcon-root': {
            fontSize: 20
        },
    },
    textpropertyOut: {
        color: 'black',
        cursor: 'pointer',
        marginLeft: '15px',
        transitionDelay: '0.2s',
        fontSize: '16px !important',
    },
    textProperty: {
        visibility: 'hidden',
    },
    sidebartitle: {
        color: "#474641",
        textAlign: "center",
        fontWeight: 'bold',
        fontSize: 40,
        verticalAlign: 'middle',
    },
    arrowIcon: {
        color: 'black',
        fontSize: '20px'
    },
    imageStyle: {
        verticalAlign: 'middle',
        textAlign: 'center',
    },
    switchEnvStyle: {
        fontSize: '18px',
        textAlign: 'center',
    },
    gutterBottomStyle: {
        marginTop: '4px',
        color: '#215176',
        textAlign: 'center',
        fontSize: 20,
    },
    root: {
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        flex: '1 0 auto',
        margin: theme.spacing(1),
    },
    iconSpacing: {
        // padding: '5px',
    },
    iconSpacing1: {
        // padding: '12px 0px 0px 12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btnActivityLog: {
        fontSize: '12px',
        borderRadius: 20
    },
    forwardIconSize: {
        fontSize: 24
    },
    greenColor: {
        color: 'green',
    },
    adfControlImage: {
        width: '60%',
        height: '100%',
        marginTop: '10px',
        '@media screen and (max-width: 650px)': {
            width: '85%',
        }
    },
    spacingTop1: {
        marginTop: '50px',
    },
    pageLayout: {
        marginTop: '5px',
        maxHeight: 800,
        minHeight: 800,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc( 100vh - 150px)',
            minHeight: 'calc( 100vh - 150px)',
        },
        '@media (min-width: 1180px) and (max-width: 1267px)': {
            minWidth: '120%',
        }
    },
    flexDispalyDirection: {
        '@media screen and (max-width: 750px)': {
            flexDirection: 'row',
        }
    },
    footer: {
        textAlign: 'center',
        marginTop: '75px',
        width: '100%'
    },
    activeSidebar: {
        backgroundColor: '#c8facd',
        borderRadius: '5px'
    },
    activeSidebarText: {
        color: '#00ab55'
    },
    deletePaperDialogue: {
        minWidth: "750px",
    }
}))

const IOSSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 38,
            height: 22,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            '&$checked': {
                transform: 'translateX(16px)',
                color: theme.palette.common.white,
                '& + $track': {
                    backgroundColor: '#52d869',
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: '#52d869',
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 20,
            height: 20,
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.grey[50],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border']),
        }
    }),
)(({ classes, ...props }: Props) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const AdfControl: React.FC<({ adfValues: IAdfEnv, onUpdate: () => void })> = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const { dispatch } = useGlobalState();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [EnableButton, setEnableButton] = React.useState(true);
    const [open, setOpen] = React.useState(false);

    const [seconds, setSeconds] = useState(20);
    const [isExpanded, setIsEpanded] = useState(false);
    const [switchEnv, setswitchEnv] = useState(1);

    var newEnvValues: IAdfEnv = {
        isDEV: props.adfValues?.isDEV,
        isUAT: props.adfValues?.isUAT,
        isProduction: props.adfValues?.isProduction
    }

    const [localState, setlocalstate] = useState(newEnvValues);

    const handleChecked = (event) => {
        const name = event.target.name;
        setlocalstate({
            ...localState,
            [name]: event.target.checked,
        });
        setOpen(true);
    };


    useEffect(() => {
        let interval = setInterval(() => {
            seconds > 0 && setSeconds(seconds => seconds - 1);
        }, 1000);
        if (seconds === 0) {
            setEnableButton(false);
        }
        return () => clearInterval(interval);
    }, [seconds])

    function reset() {
        setSeconds(20);
    }

    const handleClickOpen = () => {
        setSeconds(20);
        setOpen(true);
    };

    const handleClose = () => {
        reset();
        setOpen(false);
        setEnableButton(true);
    };

    async function OnUpdateEnv() {
        dispatch({ type: GlobalStateAction.Busy })
        try {
            let request = {
                "isProduction": localState.isProduction,
                "isUAT": localState.isUAT,
                "isDEV": localState.isDEV,
                "updatedDate": new Date()
            };
            // await usePost<any>("GlobalSettings/UpdateADFEnvironment", request)
            //props.onUpdate();
        }
        catch (ex) {
            dispatch({ type: GlobalStateAction.Error, error: ex })
            dispatch({ type: GlobalStateAction.Idle })
        }
        finally {
            dispatch({ type: GlobalStateAction.Idle })
        }
    }

    const handleToggler = () => {
        setIsEpanded(s => !s);
    }

    return (
        <React.Fragment>
            <div className={classes.flexDispaly}>
                <div className={`${isExpanded ? classes.sidebar : classes.sidebarCollapsed}`}>
                    <div className={classes.sidebarHeader}>
                        <IconButton size="medium" className={classes.iconSpacing} onClick={handleToggler}>
                            {
                                isExpanded ?
                                    <DoubleArrowIcon fontSize="inherit" className={`${classes.transformImage} ${classes.arrowIcon}`} />
                                    :
                                    <DoubleArrowIcon fontSize="inherit" className={classes.arrowIcon} />
                            }
                        </IconButton>
                    </div>
                    <div className={`${classes.sidebarItem} ${switchEnv == 1 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setswitchEnv(1)}>
                        <AvatarTooltip title="Production">
                            <img src={PROD} alt="Icon for switch production" width="35px" height="35px" onClick={(_) => setswitchEnv(1)} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setswitchEnv(1)}>
                            Production
                        </Typography>
                    </div>

                    <div className={`${classes.sidebarItem} ${switchEnv == 2 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setswitchEnv(2)}>
                        <AvatarTooltip title="User Acceptence Test">
                            <img src={UAT} alt="Icon for switch uat" width="35px" height="35px" onClick={(_) => setswitchEnv(2)} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setswitchEnv(2)}>
                            User Acceptence Test
                        </Typography>
                    </div>

                    <div className={`${classes.sidebarItem} ${switchEnv == 3 ? classes.activeSidebar : null} ${"ripple"}`} onClick={(_) => setswitchEnv(3)}>
                        <AvatarTooltip title="Development">
                            <img src={DEV} alt="Icon for switch dev" width="35px" height="35px" onClick={(_) => setswitchEnv(3)} />
                        </AvatarTooltip>
                        <Typography variant='h6' className={`${isExpanded ? classes.textpropertyOut : classes.textProperty}`} onClick={(_) => setswitchEnv(3)}>
                            Development
                        </Typography>
                    </div>
                </div>

                <Grid container spacing={0} className={classes.iconSpacing1}>
                    <Grid xs={12} className={classes.pageLayout}>
                        <Grid item xs={12} >
                            <Typography variant="h5" className={classes.title}>
                                <b>Sync with CSS-Impact to ARM </b>
                            </Typography>
                            <div className={classes.imageStyle}>
                                <img className={classes.adfControlImage} src={CSS_Impact} alt="Production" />
                            </div>
                        </Grid>
                        <Grid item xs={12} className={`${classes.spacingTop1}`} >
                            <div className={classes.switchEnvStyle}>
                                {switchEnv == 1 ?
                                    <span>
                                        <b className={classes.greenColor}><u>Note </u> </b> :
                                        Pull CSS data to <b>PROD</b> environment? ON <b><i>(Mandatorily ON)</i></b>
                                    </span>
                                    : switchEnv == 2 ?
                                        <span>
                                            <b className={classes.greenColor}><u>Note </u> </b> :
                                            Pull CSS data to <b>UAT</b> environment? OFF <b><i>(Mandatorily OFF)</i></b>
                                        </span>
                                        : switchEnv == 3 ?
                                            <span>
                                                <b className={classes.greenColor}><u>Note </u> </b> :
                                                Pull CSS data to <b>DEV</b> environment? OFF <b><i>(Mandatorily OFF)</i></b>
                                            </span>
                                            : null}
                            </div>
                            {switchEnv == 1 ?
                                <Box className={classes.turnonBox} display="flex" justifyContent="center" alignItems="center">
                                    <Box display="flex" justifyContent="center" alignItems="center" className={classes.card}>
                                        <Typography variant="h6" gutterBottom className={classes.gutterBottomStyle}>
                                            <b> Swich to Production Mode</b>
                                        </Typography>
                                        <FormControlLabel
                                            control={<IOSSwitch checked={props.adfValues?.isProduction} onClick={handleClickOpen}
                                                value={props.adfValues?.isProduction} disabled={true} name="isProduction" />}
                                            label={<b>ON/OFF</b>}
                                        />
                                    </Box>
                                </Box>
                                : switchEnv == 2 ?
                                    <Box className={classes.turnonBox} display="flex" justifyContent="center" alignItems="center">
                                        <Box display="flex" justifyContent="center" alignItems="center" className={classes.card}>
                                            <Typography variant="h6" gutterBottom className={classes.gutterBottomStyle}>
                                                <b> Swich to User Acceptance Test Mode</b>
                                            </Typography>
                                            <FormControlLabel
                                                control={<IOSSwitch onChange={handleChecked} onClick={handleClickOpen} checked={localState.isUAT} name="isUAT" />}
                                                label={<b>ON/OFF</b>}
                                            />

                                        </Box>
                                    </Box>
                                    : switchEnv == 3 ?
                                        <Box className={classes.turnonBox} display="flex" justifyContent="center" alignItems="center">
                                            <Box display="flex" justifyContent="center" alignItems="center" className={classes.card}>
                                                <Typography variant="h6" gutterBottom className={classes.gutterBottomStyle}>
                                                    <b> Swich to DEV Mode</b>
                                                </Typography>
                                                <FormControlLabel
                                                    control={<IOSSwitch onChange={handleChecked} onClick={handleClickOpen} checked={localState.isDEV} value={props.adfValues?.isDEV} name="isDEV" />}
                                                    label={<b>ON/OFF</b>}
                                                />
                                            </Box>
                                        </Box>
                                        : null
                            }

                            <Box p={2} display="flex" justifyContent="center" alignItems="center" >
                                <Button variant="contained" component={Link} to="/adfpipelinelogs" size='small' color="primary" className={classes.btnActivityLog} endIcon={<ForwardIcon className={classes.forwardIconSize} />}>
                                    Switch to ADF Activity Log
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Footer />
                </Grid>
                <Backdrop className={classes.backdrop} open={open} >
                    <Dialog PaperProps={{ style: { borderRadius: 20, } }}
                        className={classes.dialogbox}
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                        classes={{ paper: classes.deletePaperDialogue }}
                    >
                        <DialogContent>
                            <Typography variant="h5" gutterBottom className={classes.dialogheader}>
                                ADF Environment To Sync With CSS-Impact
                            </Typography>

                            <Box display='flex' alignItems='center' justifyContent='center'>
                                <img src={Caution} alt="Caution for Update Environment" className={classes.image1} />
                            </Box>
                            <Typography variant="h6" className={classes.timeout}>
                                Are you sure, you want to update the Environment ?
                                <span className={classes.countdown}>{seconds}</span> seconds.
                            </Typography>
                            <div className={classes.divdialog}>
                                <Button id="IM_ok_btn" size="small" variant="contained" onClick={handleClose} className={classes.button1} autoFocus disabled={EnableButton}>
                                    Yes
                                </Button>
                                <Button id="IM_Cancel_Btn" size="small" variant="contained" autoFocus className={classes.button2} onClick={handleClose} >
                                    No
                                </Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                </Backdrop>
            </div>
        </React.Fragment >
    )
}

export default AdfControl