import DateFnsUtils from '@date-io/date-fns';
import {
  Avatar, Backdrop, Box, Button, CircularProgress, FormControl, Grid, Hidden, IconButton, makeStyles, Paper, Snackbar, Table,
  TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
// Icons
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import clearIcon from '../../images/buttonIcons/clearIcon.svg';
// Componants
import Footer from '../../components/Footer/Footer';
import AvatarTooltip from '../../components/GlobalStyles/AvatarTooltip';
import StyledCell from "../../components/GlobalStyles/StyledCell";
//Imges for Icon
import AgentUser from '../../images/users/AgentUser.svg';
import GlobalAdmin from '../../images/users/ClientAdmin.svg';
import FileAdmin from '../../images/users/FileAdmin.svg';
import ClientAdmin from '../../images/users/GlobalAdmin.svg';
import RegularUser from '../../images/users/RegularUser.svg';
import SuperAdmin from '../../images/users/SuperAdmin.svg';
// Models
import { ITransactionOptions } from '../../models/UpdateHistory/GetTransactionOptions';
import { IGetTransactionsHistory } from '../../models/UpdateHistory/GetTransactionsHistory';
import { IGetUpdateHistoryUserList } from "../../models/UpdateHistory/GetUpdateHistoryUserList";
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
// import { UserRole } from '../../models/accesscontrol/ClientUserAccessContext';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "2cm",
    alignItems: "center"
  },
  actions: {
    width: "100%"
  },
  buttons: {
    borderRadius: 20,
    marginRight: theme.spacing(1),
    marginTop: '15px',
    fontSize: 12
  },
  buttons1: {
    borderRadius: 20,
    marginRight: theme.spacing(1),
    marginTop: '15px',
    fontSize: 12
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginTop: '10px',
    width: '95%'
  },
  table: {
    minWidth: 700
  },
  maingrid: {
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    filter: 'drop-shadow(0 -6mm 4mm gray))'
  },
  root: {
    flexGrow: 1,
    padding: '8px'
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  Title: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: "blue",
  },
  Title1: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: "red",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  tablebody: {
    maxHeight: 500,
    minHeight: 500,
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc( 100vh - 220px)',
      minHeight: 'calc( 100vh - 220px)',
    },
  },
  filter: {
    marginTop: '0px',
  },
  actionStyle: {
    width: '250px',
    marginTop: '-6px'
  },
  userStyle: {
    width: '230px',
    marginTop: '-6px'
  },
  paper1: {
    border: "2px solid black",
  },
  TableCellStyle: {
    background: "#007FFF",
    color: "white",
  },
  ErrorMessageStyle: {
    color: "red",
    marginTop: "10px",
    textAlign: 'center'
  },
  TableRowStyle: {
    height: 40,
    fontSize: 17
  },
  footer: {
    marginTop: '15px',
    float: 'right',
    marginRight: '50px'
  },
  clientAdmin: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid black',
  },
  globalAdmin: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid black',
  },
  superAdminColor: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid black',
  },
  regularUser: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid black',
  },
  fileAdmin: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: "100%",
    boxShadow: "inset 0px 0px 0px 5px white",
    border: '2px solid gray',
  },
  tableCellStyle: {
    background: "#007FFF",
    color: "white",
    padding: '3px 0px 3px 10px',
    fontSize: 13
  },
  TableCellStyle1: {
    fontSize: 13,
    padding: '3px 0px 3px 10px'
  },
  select: {
    height: "5.5vh"
  },
  titleheader: {
    marginTop: '12px',
    textAlign: 'left',
    marginLeft: '15px'
  },
  ErrorMessageStyle1: {
    color: "red",
    textAlign: 'center',
    fontSize: 14
  },
  smallFont: {
    fontSize: 13
  },
  formControl: {
    minWidth: 240,
    marginTop: '-5px'
  },
  regularUserText: {
    backgroundColor: 'rgb(226 223 251)',
    borderRadius: '30px',
    padding: '5px 10px',
    color: '#9589e8',
    fontWeight: 500,
  },
  superAdminText: {
    backgroundColor: 'rgba(255, 180, 0, 0.12)',
    borderRadius: '30px',
    padding: '5px 10px',
    color: 'rgb(255, 180, 0)',
    fontWeight: 500,
  },
  globalAdminText: {
    backgroundColor: 'rgb(251 210 216)',
    borderRadius: '30px',
    padding: '5px 10px',
    color: '#ff7186',
    fontWeight: 500,
  },
  clientAdminText: {
    backgroundColor: 'rgb(230, 247, 255)',
    borderRadius: '30px',
    padding: '5px 10px',
    color: 'rgb(24, 144, 255)',
    fontWeight: 500,
  },
  fileAdminText: {
    backgroundColor: 'rgb(235 219 241)',
    borderRadius: '30px',
    padding: '5px 10px',
    color: '#82658d ',
    fontWeight: 500,
  },
  agentUserText: {
    backgroundColor: 'rgba(86, 202, 0, 0.12)',
    borderRadius: '30px',
    padding: '5px 10px',
    color: '#56ca00',
    fontWeight: 500,
  }
}))

const usePopoverStyles = makeStyles({
  paper: {
    border: '2px solid black'
  }
});

const UpdateHistory = () => {
  const classes = useStyles();
  const popoverClasses = usePopoverStyles();
  const { state, dispatch } = useGlobalState();

  const [orderBy, setOrderBy] = useState("updatedOn");
  const [order, setOrder] = useState("desc");

  const [StartDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());

  const [TransactionHistory, setTransactionHistory] = useState<IGetTransactionsHistory[]>([]);
  const [clientActivity, setClientActivity] = useState<ITransactionOptions[]>([]);
  const [clientuserList, setClientUserList] = useState<IGetUpdateHistoryUserList[]>([]);

  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);

  const [SelectedOperationType, setSelectedOperationType] = useState<any>([]);
  const [SelectActiveUser, setSelectActiveUser] = useState<any>([]);

  const [selectedFirstDate, setSelectedFirstDate] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(false);
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [selectedType, setSelectedType] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [mapped, setMapped] = useState(false);

  const [selectedEmail, setSelectedEmail] = React.useState("");
  const [operationGroupId, setOperationGroupId] = useState<number | null>(null);

  const DateFormat = state.GlobalUtils?.settingValue === "DD/MM/YYYY" ? "dd/MM/yyyy" : state.GlobalUtils?.settingValue === "MM/DD/YYYY" ? "MM/dd/yyyy"
    : state.GlobalUtils?.settingValue === "YYYY/MM/DD" ? "yyyy/MM/dd" : "MM/dd/yyyy";

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell key={headCell?.id} sortDirection={orderBy === headCell?.id ? order : false}
              className={classes.tableCellStyle}  >
              {headCell.sortable ? (
                <TableSortLabel active={orderBy === headCell?.id}
                  direction={orderBy === headCell?.id ? order : "asc"}
                  onClick={createSortHandler(headCell?.id)}   >
                  <b>{headCell?.label || headCell?.sortable}</b>
                  {orderBy === headCell?.id || headCell?.sortable ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc" ? "sorted descending" : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>) : (
                <span>{headCell.label}</span>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const headCells = [
    { id: "", disablePadding: false, label: "", sortable: false, },
    { id: "fullName", numeric: false, disablePadding: false, label: "USER", sortable: true },
    { id: "userRole", numeric: false, disablePadding: false, label: "USER TYPE", sortable: false },
    { id: "dbt_no", numeric: false, disablePadding: false, label: "DEBTOR NO" },
    { id: "updatedOn", numeric: false, disablePadding: false, label: "DATE", sortable: true, },
    { id: "operationTypeText", numeric: false, disablePadding: false, label: "ACTIONS", sortable: true },
    { id: "transactionText", numeric: false, disablePadding: false, label: "TRANSACTION DETAILS", sortable: true },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleFirstDateChange = (date: Date | null) => {
    setStartDate(date);
    setSelectedFirstDate(true);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    setSelectedEndDate(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setshowProgressBar(true);
    setPage(newPage);
  };

  function resetvalues() {
    setSelectedFirstDate(false);
    setSelectedEndDate(false);
    setSelectedUser(false);
    setSelectedType(false);
  }

  const handleClearFilter = () => {
    setPage(0);
    clearHistoryAsync();
    setSelectActiveUser([]);
    setSelectedOperationType([]);
    setStartDate(null);
    setEndDate(null);
  }

  async function clearHistoryAsync() {
    dispatch({ type: GlobalStateAction.Busy });
    setshowProgressBar(true);
    let request = {
      "startdate": null,
      "enddate": null,
      "emailAddress": '',
      "operationType": null,
      "operationGroupId": 0,
      "startingRecordNumber": (page * rowsPerPage) + 1, "rowsPerPage": rowsPerPage,
    };
    await usePost<{ transactionHistories: IGetTransactionsHistory[], totalRecordCount: number }>
      ("UpdateHistory/GetTransactionHistory", request).then((GetTransactionHistory) => {
        setTransactionHistory(GetTransactionHistory.data["transactionHistories"]);
        setTotalRecords(GetTransactionHistory.data.totalRecordCount);
        setMapped(!GetTransactionHistory.data["transactionHistories"].length ? true : false);
        setOpen(true);
        setPage(0);
        resetvalues();
      }).finally(() => {
        setshowProgressBar(false);
      });
  }

  useEffect(() => {
    (async () => {
      await useFetch<IGetUpdateHistoryUserList[]>("User/GetWebViewUsersAsync").then((u) => {
        setClientUserList(u.data);
      });
    })()
  }, [])

  useEffect(() => {
    (async () => {
      await useFetch<ITransactionOptions[]>("UpdateHistory/GetTransactionOperation").then((GetTransactionOperation) => {
        setClientActivity(GetTransactionOperation?.data);
      });
    })()
  }, [])

  function onUsersSelect(event, value) {
    setSelectedEmail(value?.emailAddress);
    setSelectActiveUser(value);
    setSelectedUser(true);
    setPage(0);
  }

  function onOperationTypeSelect(event, valueone) {
    setSelectedOperationType(valueone);
    setSelectedType(true);
    setPage(0);
    setOperationGroupId(valueone ? valueone?.operationGroupId : null);
  }

  const handleFilter = () => {
    setPage(0);
    getUpdateHistoryAsync();
    setOpen(true);
  }

  useEffect(() => {
    getUpdateHistoryAsync();
  }, [page, rowsPerPage, order, orderBy])

  async function getUpdateHistoryAsync() {
    let request = {
      "startDate": (selectedFirstDate && StartDate === null ? '' : selectedFirstDate ? moment(StartDate).format('YYYY-MM-DD') : null),
      "endDate": (selectedEndDate && EndDate === null ? '' : selectedEndDate ? moment(EndDate).format('YYYY-MM-DD') : null),
      "emailAddress": (selectedUser ? (SelectActiveUser === null ? '' : selectedEmail) : ""),
      "operationType": (selectedType ? (SelectedOperationType === null ? 0 : SelectedOperationType.operationType) : null),
      "actionGroup": operationGroupId,
      "startingRecordNumber": (page * rowsPerPage) + 1,
      "rowsPerPage": rowsPerPage,
      "orderBy": orderBy,
      "orderDirection": order === "desc" ? 1 : 0,
    };
    setshowProgressBar(true);
    await usePost<{ transactionHistories: IGetTransactionsHistory[], totalRecordCount: number }>
      ("UpdateHistory/GetTransactionHistory", request).then((history) => {
        setTransactionHistory(history.data["transactionHistories"]);
        setTotalRecords(history.data.totalRecordCount);
        setMapped(!history.data["transactionHistories"].length ? true : false);
      }).finally(() => {
        setshowProgressBar(false);
      });
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>

        <Grid container alignItems="stretch" >
          <Grid item xs={6} sm={6} md={6} lg={6} className={classes.Title} >
            {StartDate > EndDate ? <Typography variant='h6' className={classes.ErrorMessageStyle1}>
              End date should be greater than the Start date!</Typography> : ""}
          </Grid>

          <Grid container className={classes.maingrid} justify='space-evenly'>
            <Grid item xs={6} sm={6} md={6} lg={2} >
              <Typography variant="h6" gutterBottom className={`${"headertitle"} ${classes.titleheader}`}>
                <b> UPDATE HISTORY</b>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={6} md={4} lg={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small" disableToolbar
                  autoOk={true} inputVariant="outlined"
                  variant="inline" label="Start Date"
                  format={DateFormat} className={classes.textField}
                  margin="normal" id="UpHis_StartDate"
                  value={StartDate}
                  onChange={handleFirstDateChange}
                  disableFuture={true}
                  PopoverProps={{ classes: popoverClasses }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4} sm={6} md={4} lg={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small" disableToolbar autoOk={true} variant="inline"
                  label="End Date" format={DateFormat}
                  className={classes.textField}
                  margin="normal" id="UpHis_EndDate"
                  value={EndDate} inputVariant="outlined"
                  onChange={handleEndDateChange}
                  disableFuture={true}
                  PopoverProps={{ classes: popoverClasses }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={8} sm={6} md={4} lg={2}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete size="small"
                  id="USR_SelectUsers"
                  autoComplete autoHighlight
                  classes={{ paper: classes.paper1, option: classes.smallFont }}
                  options={clientuserList}
                  groupBy={(option) => option?.userGroupName}
                  getOptionLabel={(option) => option?.fullName}
                  value={SelectActiveUser}
                  onChange={onUsersSelect}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span style={option?.isActive === 1 ? { color: "green" } : { color: "#FA8072" }}> {option?.fullName}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Users" margin="normal" variant="outlined" style={{ fontSize: 12 }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {!clientuserList.length ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <FormControl variant="outlined" size="small" className={classes.actionStyle}>
                <Autocomplete size="small"
                  id="UpHis_SelectOperationType"
                  autoComplete autoHighlight
                  classes={{ paper: classes.paper1, option: classes.smallFont }}
                  options={clientActivity}
                  groupBy={(option) =>
                    option?.operationGroupId === 1 ? "Client Activities" :
                      option?.operationGroupId === 2 ? "Agent Activities" :
                        "Trust Statement"
                  }
                  getOptionLabel={(option) => option?.operationTypeText}
                  value={SelectedOperationType}
                  onChange={onOperationTypeSelect}
                  renderInput={(params) => (
                    <TextField {...params} label="Actions" margin="normal" variant="outlined"
                      inputProps={{ ...params.inputProps }}
                    />)}
                />
              </FormControl>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={6} sm={5} md={2} lg={2} className={classes.filter}>
                <Button id="UpHis_Filter_btn" size="small" className={classes.buttons} onClick={() => { handleFilter(); }} variant="contained" startIcon={<FilterAltIcon />} color="primary" disabled={StartDate > EndDate}>
                  Filter
                </Button>
                <Button id="UpLog_Filter_Button" size="small" startIcon={<img src={clearIcon} alt="Icon for clear filter button" width="16px" height="16px" />} className={classes.buttons1}
                  onClick={() => { handleClearFilter(); }} variant="contained" color="primary" >
                  Clear Filter
                </Button>
              </Grid>
            </Hidden>

            <Hidden lgUp>
              <Grid item xs={12} sm={5} md={2} alignContent='flex-start'>
                <IconButton id="UpHis_FilterIcon_btn" size="small" aria-label="Filter" onClick={() => { handleFilter(); }} style={{ color: 'blue' }} disabled={StartDate > EndDate}>
                  <FilterListIcon style={{ fontSize: 35 }} />
                </IconButton>
                <IconButton id="UpLog_Filter_IconButton" size="small" aria-label="Filter" onClick={() => { handleClearFilter(); }} style={{ color: 'blue' }} >
                  <ClearRoundedIcon style={{ fontSize: 35 }} />
                </IconButton>
              </Grid>
            </Hidden>

            <Grid item xs={12} component={Paper}>
              <TableContainer component={Paper} className={`${classes.tablebody} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table aria-label="customized table" size="small" stickyHeader>
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {TransactionHistory?.map(
                      (row) => {
                        return (
                          <TableRow className={classes.TableRowStyle} >
                            <StyledCell component="th" scope="row" className={classes.TableCellStyle1}>
                              {row.userRole === 3 ?
                                <AvatarTooltip title="Super Admin">
                                  <Avatar className={classes.superAdminColor} src={SuperAdmin}></Avatar>
                                </AvatarTooltip>
                                : row.userRole === 2 ?
                                  <AvatarTooltip title="Global Admin">
                                    <Avatar className={classes.globalAdmin} src={GlobalAdmin}></Avatar>
                                  </AvatarTooltip>
                                  : row.userRole === 1 ?
                                    <AvatarTooltip title="Client Admin">
                                      <Avatar className={classes.clientAdmin} src={ClientAdmin}></Avatar>
                                    </AvatarTooltip>
                                    : row?.fullName === 'API' ?
                                      <AvatarTooltip title="Placed through API">
                                        <Avatar className={classes.regularUser} src={FileAdmin}></Avatar>
                                      </AvatarTooltip>
                                      : row.userRole === 0 ?
                                        <AvatarTooltip title="Regular User">
                                          <Avatar className={classes.regularUser} src={RegularUser}></Avatar>
                                        </AvatarTooltip>
                                        : row.userRole === 5 ?
                                          <AvatarTooltip title="Agent User">
                                            <Avatar className={classes.regularUser} src={AgentUser}></Avatar>
                                          </AvatarTooltip>
                                          : <AvatarTooltip title="File Admin">
                                            <Avatar className={classes.fileAdmin} src={FileAdmin}></Avatar>
                                          </AvatarTooltip>
                              }
                            </StyledCell>
                            <StyledCell component="th" scope="row" className={classes.TableCellStyle1}>
                              {row.fullName}
                            </StyledCell>

                            <StyledCell className={classes.TableCellStyle1}>
                              {row.fullName == 'API' ? "Update API" : row.userRole == 0 ? "Regular User" : row.userRole == 1 ? "Client Admin" : row.userRole == 2 ? "Global Admin" :
                                row.userRole == 3 ? "Super Admin" : row.userRole == 5 ? "Agent User" : "File Admin"}
                            </StyledCell>

                            <StyledCell className={classes.TableCellStyle1}>
                              {row.dbT_NO}
                            </StyledCell>

                            <StyledCell className={classes.TableCellStyle1}>
                              {moment(row.updatedOn).format(state.GlobalUtils?.settingValue)}
                            </StyledCell>

                            <StyledCell className={classes.TableCellStyle1}>
                              {row.operationTypeText}
                            </StyledCell>

                            <StyledCell className={classes.TableCellStyle1}>
                              {row.transactionText}
                            </StyledCell>

                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                {mapped === true ?
                  < Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
                    No records to display..
                  </Typography>
                  : null}
              </TableContainer>
              <Grid container spacing={0}>
                <Box m={0} width="65%">
                  <div className="footer">
                    <Footer />
                  </div>
                </Box>
                <Box m={0} width="35%">
                  <div className="pagination">
                    <TablePagination
                      id="Report_TablePagination"
                      rowsPerPageOptions={[15]}
                      component="div"
                      count={totalRecords}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Snackbar open={open} className="snackBarStyle" anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" className="alertStyle">
          Filter Successfully Triggered!
        </Alert>
      </Snackbar>

    </React.Fragment >
  )
}
export default UpdateHistory