import { Backdrop, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { PuffLoader } from 'react-spinners';
import AdfControl from '../../components/GlobalSettings/AdfEnvironment/AdfControl';
import { IAdfEnv } from '../../models/GLobalSettings/IAdfEnv';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch } from '../../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
}))

function AdfSettings() {
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();
    const [adfActivePipeline, setAdfActivePipeline] = useState<IAdfEnv[]>([]);

    useEffect(() => {
        getADFDetails();
    }, [])

    const getADFDetails = () => {
        (async () => {
            try {
                dispatch({ type: GlobalStateAction.Busy })
                await useFetch<IAdfEnv[]>("GlobalSettings/GetADFEnvironmentDetails").then((adf) => {
                    setAdfActivePipeline(adf.data);
                });
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex })
                dispatch({ type: GlobalStateAction.Idle })
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle })
            }
        })()
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={state.busy}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
            </Backdrop>
            {adfActivePipeline.map((adf) => {
                return (
                    <AdfControl adfValues={adf} onUpdate={getADFDetails} />
                )
            })
            }
        </React.Fragment>
    )
}

export default AdfSettings
