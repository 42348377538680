import {
    Backdrop, Box, Button, CheckboxProps, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, Grid,
    ListSubheader, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination,
    TableRow, TableSortLabel, Toolbar, Typography, useMediaQuery, Paper
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { green } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import { createStyles, makeStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import download from "../../images/files/download01.svg";
//Icons
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PuffLoader from 'react-spinners/PuffLoader';
//Models
import { IBlobContainerNames } from '../../models/Files/IBlobContainerNames';
import { IBlobFolders } from '../../models/Files/IBlobFolders';
import { IBlobs } from '../../models/Files/IBlobs';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useFetch, usePost } from '../../utils/apiHelper';
import { DialogTitleDelete, DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import LightTooltip from "../GlobalStyles/LightTooltip";
//component
import CreateNewFolder from './CreateNewFolder';
import Footer from '../../components/Footer/Footer';
import { Transition } from '../GlobalStyles/DialogBoxTransition';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        girdPadding: {
            padding: '8px'
        },
        container: {
            maxWidth: "210vh",
            alignItems: "center",
            border: '2px solid gray',
            borderRadius: 10,
            backgroundColor: 'white',
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
            overflowY: 'hidden'
        },
        alignFlexStart: {
            display: 'flex',
            marginTop: '10px',
            marginBottom: '10px',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
        },
        button: {
            borderRadius: 20,
            fontSize: 12,
            marginLeft: '10px',
            margin: theme.spacing(0.5),
        },
        previewChip: {
            minWidth: 160,
            maxWidth: 210
        },
        yesbutton: {
            backgroundColor: 'green',
            borderRadius: '20px',
            fontSize: 12,
            color: 'white',
            '&:hover': {
                backgroundColor: 'green',
                color: 'white',
            }
        },
        nobutton: {
            background: "red",
            borderRadius: "20px",
            fontSize: 12,
            color: 'white',
            '&:hover': {
                backgroundColor: 'red',
                color: 'white',
            }
        },
        title: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white',
            fontSize: 18
        },
        textStyle: {
            fontWeight: 'bold',
            marginTop: '25px',
            color: 'blue',
            textAlign: 'center'
        },
        ActionCell: {
            display: 'flex',
            alignItems: 'right',
            justifyContent: 'right'
        },
        tableBoxShadow: {
            maxHeight: 500,
            minHeight: 500,
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 225px)',
                minHeight: 'calc( 100vh - 225px)',
            }
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        searchBox: {
            borderRadius: 30,
            border: '2px black solid',
            width: '100%',
            height: '40px',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            fontSize: 16,
            textIndent: '10px'
        },
        TextHighlight: {
            color: 'black',
            fontWeight: 'bold',
            background: "yellow",
            fontSize: 13,
        },
        TextNormal: {
            color: 'black',
            fontSize: 13
        },
        title1: {
            textAlign: "left",
            color: "blue",
            marginLeft: '15px'
        },
        div: {
            height: '76vh'
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        MessageStyle: {
            color: "red",
            fontSize: 15
        },
        FolderGrid: {
            padding: '10px',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
        },
        FileGrid: {
            padding: '0px',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
            backgroundColor: 'white'
        },
        titleColor: {
            fontWeight: 'bold',
            fontSize: 17,
            marginLeft: '20px'
        },
        listVerticalHeight: {
            marginTop: '0px'
        },
        CheckBoxStyle: {
            color: "#00e676",
        },
        FolderBorderStyle: {
            borderLeft: '8px solid red',
            borderRadius: '8px'
        },
        fileNameStyle: {
            padding: '5px'
        },
        listIconSize: {
            fontSize: 20
        },
        fileText: {
            fontSize: '15px',
        },
        ListIconStyle: {
            borderLeft: `6px solid #4527a0`,
            borderRadius: '8px',
            fontSize: 15
        },
        tableHeight: {
            maxHeight: '430px'
        },
        textdisplay: {
            color: 'black',
            textAlign: 'center',
            fontWeight: 'bold',
            marginTop: '3px'
        },
        iconSize: {
            fontSize: 33
        },
        DeleteIconSize: {
            color: 'red',
            fontSize: 33
        },
        AuditIcon: {
            marginTop: '15px'
        },
        tableHeadStyle: {
            background: "lightgray",
            color: "black",
            fontSize: 13,
        },
        toolBarStyle: {
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            borderRadius: '10px',
            height: 'auto'
        },
        titleHeader: {
            fontWeight: 'bold',
            marginTop: '5px',
            color: 'white',
            fontSize: 18
        },
        TableCellPadding: {
            padding: '3px 8px',
            fontSize: 13
        },
        footer: {
            marginTop: '15px',
            float: 'right',
            marginRight: '15px'
        },
        folderTitle: {
            flex: '1 1 100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            fontSize: 15
        },
        folderSubTitle: {
            flex: '1 1 100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            fontSize: 12
        },
        tableBodyStyle: {
            maxHeight: 520,
            minHeight: 520,
            [theme.breakpoints.up('lg')]: {
                maxHeight: 'calc( 100vh - 125px)',
                minHeight: 'calc( 100vh - 125px)',
            }
        },
    }),
);

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const ManageClientFolderAccess = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { state, dispatch } = useGlobalState();
    const UserName = state.userAccessContext?.id;

    const [SelectedContainer, setSelectedContianer] = useState<IBlobContainerNames[]>([]);
    const [ListContainers, setListContainers] = useState<IBlobContainerNames[]>([]);
    const [DeleteSelected, setDeleteSelected] = useState<IBlobContainerNames[]>([]);
    const [SelectedFolder, setSelectedFolder] = useState<IBlobFolders[]>([]);
    const [ListFolders, setListFolders] = useState<IBlobFolders[]>([]);
    const [SelectedFiles, setSelectedFiles] = useState<any>();
    const [ListBlobs, setListBlobs] = useState([]);

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [DeleteContaineropen, setDeleteContaineropen] = useState(false);
    const [DeleteBlobopen, setDeleteBlobopen] = useState(false);
    const [disableUpload, setDisableUpload] = useState(false);
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [ProgressBar, setshowProgressBar] = useState(true);
    const [CheckAccess, setCheckAccess] = useState(false);
    const [open, setOpen] = useState(false);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const [GetSelectedFileId, setGetSelectedFileId] = useState("");
    const [storeContainerName, setStoreContainerName] = useState("");
    const [orderBy, setOrderBy] = useState<string>("upload_Date");
    const [GetSelectedFile, setGetSelectedFile] = useState("");
    const [searchFiles, setSearchFiles] = useState("");
    const [order, setOrder] = useState<any>('desc');

    //Alert
    const [isDeleteFolder, setIsDeleteFolder] = useState(false);
    const [isDeleteFile, setIsDeleteFile] = useState(false);
    const [isDownload, setIsDownloaded] = useState(false);
    const [isExists, setIsExists] = useState(false);

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsDownloaded(false);
        setIsDeleteFolder(false);
        setIsDeleteFile(false);
        setIsExists(false);
    };

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDialogDelete = () => {
        setDeleteContaineropen(false);
    }

    const handleFileDelete = () => {
        setDeleteBlobopen(false);
    }

    const handleDeleteContainerClick = () => {
        setDeleteContaineropen(true);
    };

    const handleDeleteContainerClose = () => {
        setDeleteContaineropen(false);
    };

    const handleDeleteBlobClick = () => {
        setDeleteBlobopen(true);
    };

    const handleDeleteBlobClose = () => {
        setDeleteBlobopen(false);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setshowProgressBar(true);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setshowProgressBar(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        createFolder();
    }, [])

    const createFolder = () => {
        if (state.userAccessContext?.role !== 3) {
            (async () => {
                await useFetch<IBlobFolders[]>(`User/GetUserFolders?Id=${UserName}`).then((GetUserFolders) => {
                    setListFolders(GetUserFolders.data);
                });
                setCheckAccess(true);
            })()
        }
    }

    useEffect(() => {
        getBlobs(storeContainerName);
    }, [rowsPerPage, page])

    const getBlobs = (CN) => {
        (async () => {
            setshowProgressBar(true);
            let request = {
                "containerName": CN,
                "searchParameter": searchFiles ?? "",
                "startingRecordNumber": (page * rowsPerPage) + 1,
                "rowsperPage": rowsPerPage,
                "userId": state.userAccessContext?.id
            }
            await usePost<IBlobs[]>("File/ListClientBlobs", request).then(r => {
                setListBlobs(r.data);
            }).finally(() => {
                setshowProgressBar(false);
            });
        })()
    }

    function empty() {
        setSelectedContianer([]);
        setDeleteSelected([]);
    }

    const DeleteContainer = () => {
        (async () => {
            let request = {
                "containerName": GetSelectedContainers()[0],
            }
            await usePost<any>("File/DeleteContainer", request).then((DeleteContainer) => {
                HandleRefresh();
                if (DeleteContainer.status == 400) {
                    dispatch({ type: GlobalStateAction.Error, error: DeleteContainer.statusText })
                }
                else {
                    setIsDeleteFolder(true);
                }
                empty();
            });
        })()
    }

    const DeleteBlob = (FileName: string, File_Id: string) => {
        (async () => {
            let request = {
                "FileName": FileName,
                "containerName": GetSelectedFolder()[0],
                "fileId": File_Id
            }
            await usePost<any>("File/DeleteBlob", request).then((DeleteBlob) => {
                getBlobs(GetSelectedFolder()[0]);
                if (DeleteBlob.status == 400) {
                    dispatch({ type: GlobalStateAction.Error, error: DeleteBlob.statusText })
                }
            });
        })()
    }

    const handleContainerToggle = (CNames: IBlobContainerNames) => () => {
        let newSelectedContainers = [...SelectedContainer].filter(r => r.containerNames == r.containerNames[""]);

        if (newSelectedContainers.some(c => c.containerNames == CNames.containerNames)) {
            newSelectedContainers = newSelectedContainers.filter(c => c.containerNames != CNames.containerNames);
        } else {
            newSelectedContainers.push(CNames);
        }

        let showBobs = "";
        for (var checkBlob in newSelectedContainers) {
            showBobs += newSelectedContainers[checkBlob].containerNames;
        }

        setSelectedContianer(newSelectedContainers);
        setDeleteSelected(newSelectedContainers);
        getBlobs(showBobs);
        setStoreContainerName(showBobs);
        setPage(0);
    }

    //Client Admin
    const handleFolderToggle = (FNames: IBlobFolders) => () => {
        let newSelectedContainers = [...SelectedFolder].filter(r => r.value == r.value[""]);

        if (newSelectedContainers.some(c => c.value == FNames.value)) {
            newSelectedContainers = newSelectedContainers.filter(c => c.value != FNames.value);
        } else {
            newSelectedContainers.push(FNames);
        }

        let showBobs = "";
        for (var checkBlob in newSelectedContainers) {
            showBobs += newSelectedContainers[checkBlob].value;
        }

        setSelectedFolder(newSelectedContainers);
        getBlobs(showBobs);
    }

    function HandleDownload(FileId: string) {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            const responseDownloadFile = await useFetch<any>(`File/FileDownload?fileId=${FileId}`);
            const arrayBuffer = base64ToArrayBuffer(responseDownloadFile.data.fileContent);
            createAndDownloadBlobFile(arrayBuffer, responseDownloadFile.data.fileName);
        })();
        (async () => {
            setshowProgressBar(true);
            await useFetch<any>(`File/FileDownload?fileId=${FileId}`).then((FileDownload) => {
                const arrayBuffer = base64ToArrayBuffer(FileDownload.data.fileContent);
                createAndDownloadBlobFile(arrayBuffer, FileDownload.data.fileName);
            }).catch(() => {
                setIsExists(true);
            }).finally(() => {
                setshowProgressBar(false);
                setIsDownloaded(true);
            });
        })();
    }

    function base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }

    function createAndDownloadBlobFile(body, filename) {
        const blob = new Blob([body]);
        const fileName = `${filename}`;
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        const headCells = [
            { id: "file_Name", numeric: false, disablePadding: false, label: "FILE NAME", sortable: true },
            { id: "upload_Date", numeric: false, disablePadding: false, label: "UPLOADED DATE", sortable: true },
            { id: "", numeric: false, disablePadding: false, label: "ACTION", sortable: false }
        ];

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.tableHeadStyle}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)} >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setPage(0);
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const StyledTableCell = withStyles((theme: Theme) =>
        createStyles({
            head: {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
            },
            body: {
                fontSize: 15,
                height: 40
            },
        }),
    )(TableCell);

    const StyledTableRow = withStyles((theme: Theme) =>
        createStyles({
            root: {
                '&:nth-of-type(odd)': {
                    backgroundColor: theme.palette.action.hover,
                },
                width: "100%",
                height: 30,
            },
        }),
    )(TableRow);

    const ListofContainers = () => (
        <React.Fragment>
            <List dense component="div" role="list" className={classes.tableBodyStyle}
                subheader={
                    <React.Fragment>
                        <Box display='flex' flexDirection="row" width="90%">
                            <Box alignContent="flex-start" justifyContent="flex-start">
                                <Typography variant='h6' gutterBottom className={`${classes.titleColor} ${"headertitle"}`}>
                                    MANAGE FILE ACCESS
                                </Typography>
                            </Box>
                            <Box display='flex' alignContent="flex-end" justifyContent="flex-end">
                                {DeleteSelected.length > 0 && state.userAccessContext?.deletefolder ?
                                    <ListSubheader component="div" id="nested-list-subheader" >
                                        <LightTooltip title="Delete" style={{ marginTop: '-10px' }}>
                                            <IconButton aria-label="delete" color="primary" onClick={handleDeleteContainerClick} >
                                                <DeleteForeverIcon style={{ fontSize: 28, color: 'red' }} />
                                            </IconButton>
                                        </LightTooltip>
                                    </ListSubheader>
                                    : null
                                }
                            </Box>
                        </Box>
                    </React.Fragment>
                }>

                {!CheckAccess ?
                    ListContainers.map((CName, index) => {
                        return (
                            <ListItem key={index} role="listitem" button onClick={handleContainerToggle(CName)} className={classes.listVerticalHeight}>
                                <ListItemIcon className={classes.ListIconStyle}>
                                    <GreenCheckbox
                                        id="FV_Checkbox"
                                        size="small"
                                        className={classes.CheckBoxStyle}
                                        checked={SelectedContainer.some(c => c.containerNames == CName.containerNames)}
                                        disabled={SelectedContainer.some(c => c.containerNames != CName.containerNames)}
                                        tabIndex={-1}
                                    />
                                </ListItemIcon>

                                <ListItemIcon className={classes.fileNameStyle}>
                                    <span>
                                        {SelectedContainer.some(c => c.containerNames == CName.containerNames) ? <FolderOpenIcon className={classes.listIconSize} style={{ color: '#FFC107' }} /> : <FolderIcon className={classes.listIconSize} style={{ color: "#FFC107" }} />}
                                    </span>
                                </ListItemIcon>
                                <ListItemText primary={<span className={classes.fileText}>{CName.containerNames}</span>} />
                            </ListItem>
                        );
                    })
                    :
                    ListFolders.map((FName, index) => {
                        return (
                            <ListItem key={index} role="listitem" button onClick={handleFolderToggle(FName)} className={classes.listVerticalHeight}>
                                <ListItemIcon className={classes.ListIconStyle}>
                                    <GreenCheckbox
                                        id="FV_Checkbox"
                                        size="small"
                                        className={classes.CheckBoxStyle}
                                        checked={SelectedFolder.some(c => c.value == FName.value)}
                                        disabled={SelectedFolder.some(c => c.value != FName.value && FName.id)}
                                        tabIndex={-1}
                                    />
                                </ListItemIcon>
                                <ListItemIcon >
                                    {SelectedFolder.some(c => c.value == FName.value) ?
                                        <FolderOpenIcon className={classes.listIconSize} style={{ color: '#FFC107' }} />
                                        : <FolderIcon className={classes.listIconSize} style={{ color: '#FFC107' }} />}
                                </ListItemIcon>
                                <ListItemText primary={<span className={classes.fileText}>{FName.value}</span>} />
                            </ListItem>
                        );
                    })
                }
                <ListItem />
            </List>
        </React.Fragment>
    );

    const ListBlob = () => (
        <React.Fragment>
            <TableContainer component={Paper} className={`${classes.tableBoxShadow} ${"scrollbox"} ${"on-scrollbar"}`}>
                <Table size="small" stickyHeader>
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody className={classes.tableBodyStyle}>
                        <Backdrop className={classes.backdrop} open={ProgressBar}>
                            <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                        </Backdrop>
                        {stableSort(ListBlobs, getComparator(order, orderBy)).map((row, index) => {
                            return (
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row" className={classes.TableCellPadding}>
                                        <span className={`${searchFiles === "" ? classes.TextNormal : row?.file_Name?.includes(searchFiles) ? classes.TextHighlight : classes.TextNormal}`}>
                                            {row.file_Name}
                                        </span>
                                    </StyledTableCell>

                                    <StyledTableCell className={classes.TableCellPadding}>
                                        <span className={`${searchFiles === "" ? classes.TextNormal : row?.upload_Date?.split('T')[0].includes(searchFiles) ? classes.TextHighlight : classes.TextNormal}`}>
                                            {moment(row.upload_Date).format(state.GlobalUtils?.settingValue)}
                                        </span>
                                    </StyledTableCell>

                                    <StyledTableCell className={classes.TableCellPadding}>
                                        <Box display="flex">
                                            {state.userAccessContext?.downloadfile &&
                                                <ListItemIcon>
                                                    <IconButton aria-label="delete" color="primary" style={{ padding: '0px' }} onClick={(_) => { HandleDownload(row.file_Id); }}>
                                                        <img src={download} alt="Icon for Download" width="25px" height="25px" />
                                                    </IconButton>
                                                </ListItemIcon>
                                            }
                                            {state.userAccessContext?.deletefile &&
                                                <ListItemIcon>
                                                    <IconButton aria-label="delete" color="primary" style={{ padding: '0px' }} onClick={(_) => { handleDeleteBlobClick(); setGetSelectedFileId(row.file_Id); setGetSelectedFile(row.file_Name); }}>
                                                        <DeleteForeverIcon style={{ color: 'red', fontSize: 25 }} />
                                                    </IconButton>
                                                </ListItemIcon>
                                            }
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })
                        }
                        <ListItem />
                    </TableBody>
                </Table>
                {(ListBlobs.length === 0 && !ProgressBar) ?
                    < Typography variant="h6" gutterBottom className={classes.MessageStyle}>
                        No Files To display..
                    </Typography>
                    : null
                }
            </TableContainer>
            <Grid container spacing={0}>
                <Grid item xs={6} sm={7}>
                    <div className={classes.footer}>
                        <Footer />
                    </div>
                </Grid>
                <Grid item xs={6} sm={5}>
                    <TablePagination
                        id="Files_TablePagination"
                        rowsPerPageOptions={[10, 15, 25]}
                        component="div"
                        count={ListBlobs.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );

    const HandleRefresh = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            await useFetch<IBlobContainerNames[]>("File/GetAllContainers").then((GetAllContainers) => {
                setListContainers(GetAllContainers.data);
            });
        })()
    }

    const GetSelectedContainers = () => {
        let CNames = "";
        let CTypes = "";
        SelectedContainer.forEach((C) => {
            CNames += C.containerNames;
            CTypes += C.containerType;
        })
        return [CNames, CTypes];
    }

    const GetSelectedFolder = () => {
        let CNames = "";
        let CTypes = "";
        SelectedFolder.forEach((C) => {
            CNames += C.value;
            CTypes += C.containerType;
        })
        return [CNames, CTypes];
    }

    //upload file to container
    useEffect(() => {
        if (GetSelectedContainers()[1] === "CloudContainer" || GetSelectedFolder()[1] === "CloudContainer") {
            CloudFilesUpload(SelectedFiles);
        }
        else if (GetSelectedContainers()[1] === "PaymentContainer" || GetSelectedFolder()[1] === "PaymentContainer") {
            PaymentFileUpload(SelectedFiles);
        }
        else if (GetSelectedContainers()[1] === "AckContainer" || GetSelectedFolder()[1] === "AckContainer") {
            AckFileUpload(SelectedFiles);
        }
    }, [SelectedFiles])

    const CloudFilesUpload = (selectedFile: File[]) => {
        if (state.userAccessContext?.role === 3) {
            (async () => {
                selectedFile.map(async (item) => {
                    const formData = new FormData();
                    formData.append("FormFile", item);
                    formData.append("file_name", item.name);
                    formData.append("upload_date", new Date().toString());
                    formData.append("file_size", item.size.toString());
                    formData.append("ContainerName", GetSelectedContainers()[0]);
                    formData.append("userid", state.userAccessContext?.id.toString());
                    await usePost<any>("File/UploadFile", formData);
                    getBlobs(GetSelectedContainers()[0]);
                });
            })()
        }
        else {
            (async () => {
                selectedFile.map(async (item) => {
                    const formData = new FormData();
                    formData.append("FormFile", item);
                    formData.append("file_name", item.name);
                    formData.append("upload_date", new Date().toString());
                    formData.append("file_size", item.size.toString());
                    formData.append("ContainerName", GetSelectedFolder()[0]);
                    formData.append("userid", state.userAccessContext?.id.toString());
                    await usePost<any>("File/UploadFile", formData);
                    getBlobs(GetSelectedFolder()[0]);
                });
            })()
        }
    }

    const PaymentFileUpload = (selectedFile: File[]) => {
        if (state.userAccessContext?.role === 3) {
            (async () => {
                selectedFile.map(async (item) => {
                    const formData = new FormData();
                    formData.append("FormFile", item);
                    formData.append("file_name", item.name);
                    formData.append("upload_date", new Date().toString());
                    formData.append("file_size", item.size.toString());
                    formData.append("ContainerName", GetSelectedContainers()[0]);
                    formData.append("userid", state.userAccessContext?.id.toString());
                    await usePost<any>("File/UploadPaymentFile", formData);
                    getBlobs(GetSelectedContainers()[0]);
                });
            })()
        }
        else {
            (async () => {
                selectedFile.map(async (item) => {
                    const formData = new FormData();
                    formData.append("FormFile", item);
                    formData.append("file_name", item.name);
                    formData.append("upload_date", new Date().toString());
                    formData.append("file_size", item.size.toString());
                    formData.append("ContainerName", GetSelectedFolder()[0]);
                    formData.append("userid", state.userAccessContext?.id.toString());
                    await usePost<any>("File/UploadPaymentFile", formData);
                    getBlobs(GetSelectedFolder()[0]);
                });
            })()
        }
    }

    const AckFileUpload = (selectedFile: File[]) => {
        if (state.userAccessContext?.role === 3) {
            (async () => {
                selectedFile.map(async (item) => {
                    const formData = new FormData();
                    formData.append("FormFile", item);
                    formData.append("file_name", item.name);
                    formData.append("upload_date", new Date().toString());
                    formData.append("file_size", item.size.toString());
                    formData.append("ContainerName", GetSelectedContainers()[0]);
                    formData.append("userid", state.userAccessContext?.id.toString());
                    await usePost<any>("File/UploadACKFile", formData);
                    getBlobs(GetSelectedContainers()[0]);
                });
            })()
        }
        else {
            (async () => {
                selectedFile.map(async (item) => {
                    const formData = new FormData();
                    formData.append("FormFile", item);
                    formData.append("file_name", item.name);
                    formData.append("upload_date", new Date().toString());
                    formData.append("file_size", item.size.toString());
                    formData.append("ContainerName", GetSelectedFolder()[0]);
                    formData.append("userid", state.userAccessContext?.id.toString());
                    await usePost<any>("File/UploadACKFile", formData);
                    getBlobs(GetSelectedFolder()[0]);
                });
            })()
        }
    }

    useEffect(() => {
        if (state.userAccessContext?.role === 3) {
            if (GetSelectedContainers()[0].length === 0) {
                setDisableUpload(true);
            } else {
                setDisableUpload(false);
            }
        }
        else {
            if (GetSelectedFolder()[0].length === 0) {
                setDisableUpload(true);
            } else {
                setDisableUpload(false);
            }
        }
    }, [disableUpload, SelectedContainer, SelectedFolder])

    const initiateSearch = () => {
        setTriggerSearch(!triggerSearch);
        getBlobs(GetSelectedContainers()[0]);
        setPage(0);
    }

    return (
        <React.Fragment>
            <Grid container className={classes.girdPadding}>
                <Grid item xs={3} className={classes.FolderGrid} style={{ backgroundColor: "#FAF9F8" }}>
                    <Scrollbars autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={10}
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={550}
                        style={{ width: "100%" }}>
                        {ListofContainers()}
                    </Scrollbars>
                </Grid>

                <Grid item xs={9} className={classes.FileGrid}>
                    <Toolbar className={classes.toolBarStyle}>
                        <Box alignContent="flex-start" justifyContent="flex-start" alignItems="flex-start" width="35%" style={{ borderLeft: `6px solid #ffbc13`, borderRadius: '8px', paddingLeft: "6px" }}>
                            <Typography className={classes.folderTitle} variant="h6" id="tableTitle">
                                {state.userAccessContext?.role === 3 ? SelectedContainer[0]?.containerNames?.toUpperCase() : SelectedFolder[0]?.value?.toUpperCase()}
                            </Typography>
                            <Typography className={classes.folderSubTitle} variant="subtitle2" id="tableTitle">
                                {(GetSelectedContainers()[1] === "CloudContainer" || GetSelectedFolder()[1] === "CloudContainer") ? "Cloud Folder" : (GetSelectedContainers()[1] === "PaymentContainer" || GetSelectedFolder()[1] === "PaymentContainer") ? "Payment Folder" : "Acknowledgement Folder"}
                            </Typography>
                        </Box>
                        <Box display="flex" p={1} alignContent="flex-start" justifyContent="flex-start" width="90%">
                            <input value={searchFiles}
                                type='text' placeholder="Search Files"
                                className={classes.searchBox}
                                onChange={e => setSearchFiles(e.target.value.trim())}
                                onKeyPress={event => { if (event.key === 'Enter') { initiateSearch(); } }}
                                maxLength={255}
                            />
                        </Box>

                        <Box display="flex" width="10%" justifyContent="flex-end" alignItems="flex-end">
                            {state.userAccessContext?.createfolder && <CreateNewFolder OnCreate={createFolder} />}
                            {state.userAccessContext?.uploadfile &&
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    className={classes.button}
                                    onClick={handleClick}
                                    startIcon={<CloudUploadIcon style={{ fontSize: 20 }} />}
                                    disabled={disableUpload}>
                                    Upload
                                </Button>
                            }
                        </Box>
                    </Toolbar>
                    {ListBlob()}
                </Grid>

                <Dialog TransitionComponent={Transition}
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={'sm'}
                    PaperProps={{ style: { borderRadius: 15 } }}
                >
                    <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                        <Typography variant="h5" gutterBottom className={classes.titleHeader}>
                            UPLOAD FILE
                        </Typography>
                    </DialogTitleHeader>
                    <DialogContent style={{ marginTop: '10px' }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Grid container>
                                <Grid item xs={12}>
                                    <DropzoneArea
                                        onChange={(files) => { setSelectedFiles(files) }}
                                        showPreviews={false}
                                        showPreviewsInDropzone={false}
                                        showAlerts={true}
                                        maxFileSize={15728640} // Max file Limit 15mb only
                                        filesLimit={10}
                                        onDrop={() => { setOpen(false); }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id="folder_close" size="small" variant="contained" autoFocus className={classes.nobutton}
                            onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Delete Container Dialog */}
                <Dialog TransitionComponent={Transition}
                    PaperProps={{ style: { borderRadius: 10 } }}
                    fullScreen={fullScreen}
                    open={DeleteContaineropen}
                    id="FV_DeleteContainerDialog"
                >
                    <DialogTitleDelete id="responsive-dialog-title" onClose={handleDialogDelete}>
                        <Typography variant="h6" gutterBottom className={classes.title}>
                            DELETE FOLDER
                        </Typography>
                    </DialogTitleDelete>
                    <DialogContent>
                        <Typography variant="h6" style={{ color: 'black', fontSize: 17 }}>
                            Are you sure you want to delete {GetSelectedContainers()} folder ?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={(_) => { DeleteContainer(); handleDeleteContainerClose(); }} color="primary" variant="contained" className={classes.yesbutton} autoFocus>
                            Yes
                        </Button>
                        <Button size="small" autoFocus onClick={handleDeleteContainerClose} color="primary" variant="contained" className={classes.nobutton}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Delete Blob Dialog */}
                <Dialog TransitionComponent={Transition}
                    PaperProps={{ style: { borderRadius: 15 } }}
                    fullScreen={fullScreen}
                    open={DeleteBlobopen}
                    id="FV_DeleteBlobDialog"
                >
                    <DialogTitleDelete id="responsive-dialog-title" onClose={handleFileDelete}>
                        <Typography variant="h5" gutterBottom className={classes.title}>
                            DELETE FILE
                        </Typography>
                    </DialogTitleDelete>
                    <DialogContent >
                        <Typography variant="h6" style={{ color: 'black', fontSize: 17 }}>
                            Are you sure you want to delete {GetSelectedFile} file ?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" onClick={(_) => { DeleteBlob(GetSelectedFile, GetSelectedFileId); handleDeleteBlobClose(); }} color="primary" autoFocus variant="contained" className={classes.yesbutton}>
                            Yes
                        </Button>
                        <Button size="small" autoFocus onClick={handleDeleteBlobClose} color="primary" variant="contained" className={classes.nobutton}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar className="snackBarStyle" open={isDownload} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={4000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                        File Downloaded Successfully.
                    </Alert>
                </Snackbar>

                <Snackbar className="snackBarStyle" open={isDeleteFolder} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={4000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                        Folder Deleted Successfully.
                    </Alert>
                </Snackbar>

                <Snackbar className="snackBarStyle" open={isDeleteFile} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={4000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                        File Deleted Successfully.
                    </Alert>
                </Snackbar>

                <Snackbar className="snackBarStyle" open={isExists} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} autoHideDuration={4000} onClose={closeSnackbar}>
                    <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                        File Not Exists!
                    </Alert>
                </Snackbar>
            </Grid>
        </React.Fragment >
    )
}

export default ManageClientFolderAccess