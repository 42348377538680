import axios from "axios";

const baseURL = "https://webviewapi.armsolutions.com/api/";
const baseURLDev = "https://localhost:44316/api/";

export const instance = axios.create({
  baseURL: baseURL,
  timeout: 300000,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});
