import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, makeStyles, Box, useMediaQuery, useTheme, Backdrop
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";
import Session from '../images/session/Timer.svg'
import expired from '../images/session/session.svg'
import FastForwardIcon from '@material-ui/icons/FastForward';
import CachedIcon from '@material-ui/icons/Cached';

const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: 0
    },
    button: {
        fontSize: 15,
        textTransform: "none",
        fontWeight: 'bold',
        width: 200,
        height: 40,
        borderTopLeftRadius: 10,
        borderBottomRightRadius: 10,
    },
    button1: {
        fontSize: 15,
        textTransform: "none",
        fontWeight: 'bold',
        width: 150,
        height: 40,
        borderTopLeftRadius: 10,
        borderBottomRightRadius: 10,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 20,
        height: '100 %',
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        margin: 0,
        backdropFilter: 'blur(5px)',
    },
    logout: {
        color: "#fff",
        backgroundColor: red[500],
        "&:hover": {
            backgroundColor: red[700]
        }
    },
    countdown: {
        color: red[700]
    },
    dialogbox: {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        backdropFilter: 'blur(5px)',
        height: '100 %',
    },
    session: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    session1: {
        height: 100,
        width: 100,
    },
    timeout: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    timeout1: {
        textAlign: 'center',
        marginTop: "3px",
        fontSize: 15
    },
    buttonbox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
}));


const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            <Backdrop className={classes.backdrop} open={open} >
                <Dialog
                    fullScreen={fullScreen}
                    maxWidth={'xs'}
                    open={open}
                    className={classes.dialogbox}
                    PaperProps={{
                        style: {
                            border: "3px solid #0D324D",
                            borderTopLeftRadius: 20,
                            borderBottomRightRadius: 20,
                            boxShadow: '0px 1px 15px 1px rgba(103, 128, 159, 1)',
                        }
                    }}
                    classes={{ paper: classes.dialog }}
                >
                    <DialogContent>
                        {countdown !== 0 ?
                            <>
                                <DialogTitle className={classes.session}>
                                    <img src={Session} alt="Session_Timeout" className={classes.session1} />
                                </DialogTitle>
                                <Typography variant="h6" className={classes.timeout}>
                                    The current session is about to expire in{" "}
                                    <span className={classes.countdown}>{countdown}</span> seconds.
                                </Typography>
                                <Typography variant="subtitle1" className={classes.timeout1}>
                                    {`Would you like to continue the session?`}
                                </Typography>
                            </>
                            :
                            <>
                                <DialogTitle className={classes.session}>
                                    <img src={expired} alt="Session_Timeout" className={classes.session1} />
                                </DialogTitle>
                                <Typography variant="h6" gutterBottom className={classes.timeout}>
                                    Session expired while you were away,
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom className={classes.timeout1}>
                                    <b>Please refresh to continue</b>
                                </Typography>
                            </>
                        }
                    </DialogContent>
                    <DialogActions >
                        <Box component="span" width='100%' m={1} className={classes.buttonbox} >
                            {countdown !== 0 ?
                                <>
                                    <Button
                                        size="small"
                                        onClick={onContinue}
                                        color="primary"
                                        variant="contained"
                                        endIcon={<FastForwardIcon style={{ fontSize: 25 }} />}
                                        className={classes.button}>
                                        Continue Session
                                    </Button>
                                </>
                                :
                                <Button color="primary" variant="contained" className={classes.button1} startIcon={<CachedIcon style={{ fontSize: 25 }} />} onClick={onContinue}>
                                    Refresh
                                </Button>
                            }
                        </Box>
                    </DialogActions>
                </Dialog >
            </Backdrop>
        </>
    );
}
export default SessionTimeoutDialog;