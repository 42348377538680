import { createStyles, TableCell, Theme, withStyles } from '@material-ui/core';

const StyledCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 13,
        },
    }),
)(TableCell);

export default StyledCell

