export interface agentAccessContext {
  agentUserId: number;
  emailAddress: string;
  firstName: string;
  lastName: string;
  paymentUpdates: boolean;
  closeAccounts: boolean;
  rollAccounts: boolean;
  clientInformation: boolean;
  readOnlyAccess: boolean;
  isActive: boolean;
  userRole: AgentUserRole;
  portalUserExist: boolean;
  remainingAccountCount: boolean;
  dailyActivityCount: boolean;
  dailyTargetedCount: boolean;
  accountPlacementCount: boolean;
  accountUpdateHistory: boolean;
  accountActivityHistory: boolean;
  createdDate: string;
  modifiedDate: string;
  agentUsersReport: boolean;
  disputeValidation: boolean;
  paymentVerification: boolean;
  backupDocument: boolean;
  trustStatment: boolean;
  caseCreatedReport: boolean;
}

export enum AgentUserRole {
  AgentUser = 5,
}
