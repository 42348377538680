import {
  Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText,
  Grid, InputAdornment, Snackbar, TableContainer, TextField, Typography
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonIcon from '@material-ui/icons/Person';
import SendIcon from '@material-ui/icons/Send';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import React, { useEffect, useState } from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import Footer from '../../../components/Footer/Footer';
import { IClientCodeName } from '../../../models/SendNotification/IClientCodeName';
import { IInsertNotification } from '../../../models/SendNotification/IInsertNotification';
import { IUserForClient } from '../../../models/SendNotification/IUserForClient';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './NotificationCss';

export default function NativeSelects() {
  const classes = useStyles();
  const { dispatch } = useGlobalState()
  const [AgencyId, setAgencyId] = useState<string>("");
  const [AccountNo, setAccountNo] = useState<string>("");
  const [Settlement, setSettlement] = useState<string>("");
  const [Description, setDescription] = useState<string>("");
  const [ClientCodes, setClientCodes] = useState<IClientCodeName[]>([]);
  const [SelectedClientCodes, setSelectedClientCodes] = useState<IClientCodeName[]>([]);
  const [UsersForClient, setUsersForClient] = useState<IUserForClient[]>([]);
  const [SelectedUsers, setSelectedUsers] = useState<IUserForClient[]>([]);
  const [SearchTerm, setSearchTerm] = useState("");
  const [AgencyIdError, setAgencyIdError] = useState<string>("");
  const [AccountNoError, setAccountNoError] = useState<string>("");
  const [SettlementError, setSettlementError] = useState<string>("");
  const [searchText, setSearchText] = useState<string>('');
  const [isValid, setIsValid] = useState(false);
  const [selectedClient, setSelectedClient] = useState<string>('');
  const [ProgressBar, setshowProgressBar] = useState(true);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const [message, setMessage] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [buttonLoading, setButtonLoading] = useState(false);
  const [confirmDialogBox, setConfirmDialogBox] = useState(false);

  const handleAlertClose = () => {
    setAlertOpen(false);
    setConfirmDialogBox(false);
    setButtonLoading(false);
  };
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    (async () => {
      setshowProgressBar(true);
      await useFetch<IClientCodeName[]>('GetClientCodeList').then((GetClientCodeList) => {
        setClientCodes(GetClientCodeList.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }, [])

  const getUsersForClient = (clientCodes) => {
    (async () => {
      setshowProgressBar(true);
      let request = {
        "client_code": clientCodes,
        "name": SearchTerm?.trimLeft().trimRight(),
        "isSettlementOption": true
      }
      await usePost<IUserForClient[]>("GetUsersForClient", request).then((GetUsersForClient) => {
        setUsersForClient(GetUsersForClient.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }

  const GetClientLists = () => {
    setshowProgressBar(true);
    (async () => {
      await useFetch<IClientCodeName[]>(`GetClientSearchList?searchText=${searchText.trim()}`).then((GetClientSearchList) => {
        setClientCodes(GetClientSearchList.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }

  const GetSelectedUserID = () => {
    let userIds = "";
    SelectedUsers.forEach((user) => {
      userIds += user.id;
      userIds += ","
    })
    return userIds.substring(0, userIds.length - 1);
  }

  const getClientUsersAsync = () => {
    setshowProgressBar(true);
    (async () => {
      let request = {
        "client_code": selectedClient,
        "name": SearchTerm?.trimLeft().trimRight(),
        "isSettlementOption": true
      }
      await usePost<IUserForClient[]>("GetUsersForClient", request).then((GetUsersForClient) => {
        setUsersForClient(GetUsersForClient.data);
      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }

  const Insert = () => {
    setConfirmDialogBox(false);
    if (SelectedUsers.length > 0) {
      setButtonLoading(true);
      (async () => {
        dispatch({ type: GlobalStateAction.Busy })
        if (!isValidRequest()) {
          setMessage("Please enter all the required details!");
          setAlertOpen(true);
          setButtonLoading(false);
          return;
        }
        const formData = new FormData();
        formData.append("TypeId", "4");
        formData.append("ExtraData1", GetSelectedUserID());
        formData.append("ExtraData2", AgencyId);
        formData.append("ExtraData3", AccountNo);
        formData.append("ExtraData4", Settlement + "µ" + Description);

        await usePost<IInsertNotification>('InsertAdminNotification', formData).then(async (r) => {
          if (r.status === 202) {
            sendSettlementEmailToCustomer();
            setOpen(true);
            resetEverything();
            setButtonLoading(false);
          }
        }).catch(() => {
          setMessage("Kindly check your AgencyNo, ClientAccNO, Selected Users are Valid");
          setAlertOpen(true);
          setButtonLoading(false);
        }).finally(() => {
          setshowProgressBar(false);
        });
      })()
    }
    else {
      setMessage("Kindly Select Users to Send Notification.");
      setAlertOpen(true);
      setButtonLoading(false);
    }
  }

  const sendSettlementEmailToCustomer = () => {
    (async () => {
      let SelectedUserList = [];
      SelectedUsers?.map((result) => {
        let newUserList = {
          username: result?.userName
        }
        SelectedUserList.push(newUserList);
      })
      await usePost<any>('SendSettlementEmailToCustomer', SelectedUserList).then((response) => {

      }).finally(() => {
        setshowProgressBar(false);
      });
    })()
  }

  const handleClientCodeToggle = (client: IClientCodeName) => () => {
    let newSelectedClientCodes = [...SelectedClientCodes];

    if (newSelectedClientCodes.some(c => c.clientCode == client.clientCode)) {
      newSelectedClientCodes = newSelectedClientCodes.filter(c => c.clientCode != client.clientCode);
    } else {
      newSelectedClientCodes.push(client);
    }

    let clientcodes = "";
    for (var checkClientCode in newSelectedClientCodes) {
      clientcodes += newSelectedClientCodes[checkClientCode].clientCode;
      clientcodes += ',';
    }
    setSelectedClientCodes(newSelectedClientCodes);
    getUsersForClient(clientcodes);
    setSelectedClient(clientcodes);
    setshowProgressBar(true);
  }

  const handleUserToggle = (user: IUserForClient) => () => {
    let newSelectedUsers = [...SelectedUsers];
    if (newSelectedUsers.some(u => u.id == user.id)) {
      newSelectedUsers = newSelectedUsers.filter(u => u.id != user.id);
    } else {
      newSelectedUsers.push(user);
    }
    setSelectedUsers(newSelectedUsers);
  }

  const handleAllRight = () => {
    let clientcodes = "";
    for (var checkClientCode in ClientCodes) {
      clientcodes += ClientCodes[checkClientCode].clientCode;
      clientcodes += ',';
    }
    getUsersForClient(clientcodes);
    setSelectedClientCodes(ClientCodes);
    setshowProgressBar(false);
  };

  const handleAllLeftClient = () => {
    let clientcodes = "";
    getUsersForClient(clientcodes);
    setSelectedClientCodes([]);
    setshowProgressBar(false);
  }

  const handleCheckedRight = () => {
    setSelectedUsers(UsersForClient);
    setshowProgressBar(false);
  };

  const handleAllLeft = () => {
    setSelectedUsers([]);
    setshowProgressBar(false);
  };

  function isValidRequest() {
    if (AgencyId != "" && AccountNo != "" && Settlement != "")
      return true;
    return false;
  }

  function resetEverything() {
    setAgencyId("");
    setAccountNo("");
    setSettlement("");
    setDescription("");
  }

  const HandleAgencyIdVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[~`!@#^&*()-+=%<>?.,:;a-zA-Z {}[\]/\\$'"]/)) {
      setAgencyIdError("");
      setIsValid(false);
      setAgencyId(newValue);
    } else {
      setAgencyIdError("Please Enter Numbers only Without Space , ");
      setIsValid(true);
    }
  };

  const HandleAmountVal = (e) => {
    const newValue = e.target.value;
    if (!newValue.match(/[~`!@#^&*()-+=%<>?:;a-zA-Z{}/|[\]\\$'" ]/)) {
      setSettlementError("");
      setIsValid(false);
      setSettlement(newValue);
    } else {
      setSettlementError("Please Enter Numbers only");
      setIsValid(true);
    }
  };

  useEffect(() => {
    if (AgencyId.length > 9) {
      setAgencyIdError("Max length is 9");
      setIsValid(true);
    }
    else if (Settlement.length > 9) {
      setSettlementError("Max length is 9");
      setIsValid(true);
    }
    else {
      setIsValid(false);
    }
  }, [AgencyId, AccountNo, Settlement])

  const clientCodeList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" gutterBottom color="secondary" className={classes.clientheader} >
        <b>CLIENTS</b>
      </Typography>
      <div className={`${AgencyIdError || AccountNoError || SettlementError ? classes.paper2 : classes.paper} ${"scrollbox"} ${"on-scrollbar"}`}>
        <List dense className={classes.selectboxpadding} component="div" role="list">
          {ClientCodes.slice(0, 150).map((client, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem" button onClick={handleClientCodeToggle(client)}>
                <ListItemIcon>
                  <Checkbox
                    size="small" style={{ color: "#1bc42c" }}
                    className={classes.checkBoxStyle}
                    id="SA_ClientCode_Checkbox"
                    checked={SelectedClientCodes.some(c => c.clientCode == client.clientCode)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText className={classes.clientlistSpacing} id={labelId}>
                  <Typography variant="body2" className={classes.textUser}>
                    <b>{client.clientCode}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          {searchText && !ClientCodes.length ?
            <Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
              No Clients to display..
            </Typography> : null}
          <ListItem />
        </List>
      </div>
    </React.Fragment>
  );

  const usersList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" className={classes.clientheader} gutterBottom color="secondary">
        <b>USERS</b>
      </Typography>
      <div className={`${AgencyIdError || AccountNoError || SettlementError ? classes.paper2 : classes.paper} ${"scrollbox"} ${"on-scrollbar"}`}>
        <List dense className={classes.selectboxpadding} component="div" role="list">
          {UsersForClient.map((user, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem" button onClick={handleUserToggle(user)}>
                <ListItemIcon>
                  <Checkbox
                    size="small" style={{ color: "#1bc42c" }}
                    className={classes.checkBoxStyle}
                    id="NF_Users_Checkbox"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    checked={SelectedUsers.some(u => u.id == user.id)}
                  />
                </ListItemIcon>
                <ListItemText className={classes.clientlistSpacing} id={labelId}>
                  <Typography variant="body2" className={classes.textUser}>
                    <b>{user.firstName} {user.lastName}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          {SearchTerm && !UsersForClient.length ?
            <Typography variant="h6" gutterBottom className={classes.ErrorMessageStyle}>
              No Users to display..
            </Typography> : null}
          <ListItem />
        </List>
      </div>
    </React.Fragment>
  );

  const selectedUsersList = () => (
    <React.Fragment>
      <Typography variant="button" display="block" className={classes.clientheader} gutterBottom color="secondary" >
        <b>ADD TO NOTIFICATION</b>
      </Typography>
      <div className={`${AgencyIdError || AccountNoError || SettlementError ? classes.paper2 : classes.paper} ${"scrollbox"} ${"on-scrollbar"}`}>
        <List dense className={classes.selectboxpadding} component="div" role="list">
          {SelectedUsers.map(({ firstName, lastName }, index) => {
            const labelId = `transfer-list-item-${index}-label`;
            return (
              <ListItem key={index} role="listitem">
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText className={classes.clientlistSpacing} id={labelId}>
                  <Typography variant="body2" className={classes.textUser}>
                    <b>{firstName}{lastName}</b>
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className={classes.div}>
        <TableContainer className={`${classes.adminLayout} ${"scrollbox"} ${"on-scrollbar"}`}>
          <Backdrop className={classes.backdrop} open={ProgressBar}>
            <PuffLoader size={80} color={"white"} speedMultiplier={1} />
          </Backdrop>
          <CssBaseline />
          <Typography variant="h6" gutterBottom className={`${"headertitle"} ${classes.titleHeader}`} >
            <b> SETTLEMENT AUTHORIZATION </b>
          </Typography>
          <Container fixed className={classes.container}>
            <span className={classes.ErrorMessageStyle1}>
              {AgencyIdError} {AccountNoError} {SettlementError}
            </span>
            <Grid container spacing={0}>
              <Grid item className={classes.gridPadding} xs={12} md={6}>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <Typography variant="button" display="block" gutterBottom color="secondary" className={classes.agencyfieldnames}>
                      <b>Agency Acct# : </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="SA_AgencyAcct" size="small" placeholder="Agency Acct#" variant="outlined" name="ExtraData2" value={AgencyId}
                      onChange={(e) => { setAgencyId(e.target.value); HandleAgencyIdVal(e); }} inputProps={{ maxlength: 9 }}
                      error={!!AgencyIdError}
                      className={`${!AgencyIdError ? classes.agencyfiled : classes.agencyFiledErrorStyle}`}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          root: classes.outlinedInput,
                          focused: classes.focused,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <Typography variant="button" display="block" gutterBottom color="secondary" className={classes.agencyfieldnames}>
                      <b>Client Acct# : </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="SA_ClinetAcct" size="small" placeholder="Client Acct#" variant="outlined" name="ExtraData3"
                      value={AccountNo} onChange={(e) => { setAccountNo(e.target.value) }} inputProps={{ maxlength: 20 }}
                      error={!!AccountNoError}
                      className={`${!AccountNoError ? classes.agencyfiled : classes.agencyFiledErrorStyle}`}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          root: classes.outlinedInput,
                          focused: classes.focused,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <Typography variant="button" display="block" gutterBottom color="secondary" className={classes.agencyfieldnames}>
                      <b>Settlement Offer : </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField id="SA_SettlementOffer" size="small" variant="outlined" name="ExtraData4"
                      value={Settlement} onChange={(e) => { setSettlement(e.target.value); HandleAmountVal(e); }}
                      inputProps={{ maxlength: 9 }} error={!!SettlementError}
                      className={`${!SettlementError ? classes.agencyfiled : classes.agencyFiledErrorStyle}`}
                      InputLabelProps={{ shrink: true }}
                      placeholder="Amount #"
                      InputProps={{
                        startAdornment: (<InputAdornment position="start">
                          <AttachMoneyIcon />
                        </InputAdornment>),
                        classes: {
                          root: classes.outlinedInput,
                          focused: classes.focused,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.gridPadding} xs={12} md={6}>
                <TextField
                  placeholder="Enter the Message..."
                  size="medium"
                  id="SA_Description"
                  multiline
                  rows={5}
                  value={Description} onChange={(e) => setDescription(e.target.value)}
                  variant="outlined"
                  inputProps={{ maxlength: 500 }}
                  InputLabelProps={{ shrink: true }}
                  className={classes.description}
                  InputProps={{
                    classes: {
                      root: classes.outlinedInput,
                      focused: classes.focused,
                      notchedOutline: classes.notchedOutline,
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0} style={{ marginTop: '0.5cm' }}>
              <Grid item xs={3}>
                <input value={searchText}
                  type='text' placeholder="Search Clients"
                  className={classes.searchIcon1}
                  onChange={e => setSearchText(e.target.value)}
                  onKeyPress={event => { if (event.key === 'Enter') { GetClientLists(); } }}
                  maxLength={255}
                />
              </Grid>
              <Grid item xs={2}>
              </Grid>
              <Grid item xs={3}>
                <input value={SearchTerm}
                  type='text' placeholder="Search Users"
                  className={classes.searchIcon}
                  onChange={e => setSearchTerm(e.target.value)}
                  onKeyPress={event => { if (event.key === 'Enter') { getClientUsersAsync(); } }}
                  maxLength={255}
                />
              </Grid>
              <Grid item xs={1}>
              </Grid>
              <Grid item xs={3}>
                <Button id="SA_SendNotification_btn" className={classes.sendNotification} endIcon={<SendIcon />}
                  variant="contained" color="primary" onClick={(_) => { SelectedUsers.length > 0 ? setConfirmDialogBox(true) : Insert() }} disabled={!AgencyId || !AccountNo || !Settlement || !Description || isValid || !SelectedUsers.length}>
                  Send Notification
                  {buttonLoading === true ? <CircularProgress size="small" className={classes.circlarBarStyle} /> : null}
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={0} justify='space-evenly'>
              <Grid item xs={5} sm={6} md={2} className={classes.gridstyle} >
                {clientCodeList()}
              </Grid>
              <Grid item xs={3} sm={4} md={2}>
                <Grid>
                  <Button
                    id="NF_SelectAll_btn"
                    className={classes.selectallbutton}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleAllRight}
                    aria-label="move all right"
                    startIcon={<GroupAddIcon fontSize="large" style={{ fontSize: 25 }} />} >
                    Select All
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    id="NF_RemoveAllClient_btn"
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.removeallbutton}
                    onClick={handleAllLeftClient}
                    disabled={SelectedClientCodes.length === 0}
                    aria-label="move all left" startIcon={<PersonRemoveAlt1Icon fontSize="large" style={{ fontSize: 25 }} />} >
                    Remove All
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={5} sm={3} md={2} className={classes.gridstyle} >
                {usersList()}
              </Grid>
              <Grid item xs={5} sm={5} md={2} >
                <Grid>
                  <Button
                    id="NF_AddAll_btn"
                    variant="contained"
                    color="primary"
                    className={classes.selectallbutton}
                    size="small"
                    onClick={handleCheckedRight}
                    aria-label="move selected right" startIcon={<GroupAddIcon fontSize="large" style={{ fontSize: 25 }} />}>
                    Add All
                  </Button>
                </Grid>
                <Grid style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                  <Button
                    id="NF_RemoveAll_btn"
                    variant="contained"
                    color="primary"
                    className={classes.removeallbutton}
                    size="small"
                    onClick={handleAllLeft}
                    aria-label="move all left" startIcon={<PersonRemoveAlt1Icon fontSize="large" style={{ fontSize: 25 }} />}>
                    Remove All
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={5} sm={5} md={2} className={classes.gridstyle} >
                {selectedUsersList()}
              </Grid>
            </Grid>
          </Container>
        </TableContainer>
        <div >
          <Footer />
        </div>
      </div >
      <Snackbar open={open} className="snackBarStyle" anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" className="alertStyle">
          Settlement Offer Sent Successfully !
        </Alert>
      </Snackbar>
      <Dialog TransitionComponent={Transition}
        fullScreen={fullScreen} open={confirmDialogBox} onClose={handleAlertClose} maxWidth={'sm'}
        aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
        <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            Confirmation Message
          </Typography>
        </DialogTitleDelete>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6" className={classes.MessageStyle} gutterBottom>
              Are you sure you want to send this notification to {SelectedUsers.length} users?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button id="DU_ok_btn" size="small" variant="contained" onClick={(e) => { Insert(); }} className={classes.yesButton}>
            Yes
          </Button>
          <Button id="DU_ok_btn" size="small" variant="contained" onClick={(e) => { setConfirmDialogBox(false); }} className={classes.noButton}>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog TransitionComponent={Transition}
        fullScreen={fullScreen} open={alertOpen} onClose={handleAlertClose} maxWidth={'xs'}
        aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
        <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
          <Typography variant="h5" gutterBottom className={classes.titleheader}>
            SETTLEMENT AUTHORIZATION
          </Typography>
        </DialogTitleDelete>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h5" className={classes.MessageStyle} gutterBottom>
              {message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button id="DU_ok_btn" size="small" variant="contained" onClick={(e) => { setAlertOpen(false); setButtonLoading(false); }} className={classes.yesButton}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment >
  );
}