import {
    Avatar, Box, makeStyles, Table, TableBody, TableCell, TableHead,
    TableRow, TableSortLabel, Typography
} from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import GlobalAdmin from '../../images/users/ClientAdmin.svg';
import ClientAdmin from '../../images/users/GlobalAdmin.svg';
import SuperAdmin from '../../images/users/SuperAdmin.svg';
import emptyFile from '../../images/webviewfiles/noData.svg';
import UserAvatar from '../../images/webviewfiles/UserAvatar.png';
import { IGetFileAuditLog } from '../../models/AuditLog/IGetFileAuditLog';
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import AvatarTooltip from '../GlobalStyles/AvatarTooltip';

const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
        divPadding: {
            borderRadius: '10px',
            padding: '0px'
        },
        divColor: {
            backgroundColor: 'white'
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableDesign: {
            borderRadius: '10px'
        },
        buttonStyle: {
            borderRadius: '10px',
            border: '1px solid gray',
            marginBottom: '3px'
        },
        rowhead: {
            backgroundColor: '#d7d9d8',
            color: 'black',
            fontSize: '10px',
            padding: '2px 16px'

        },
        tableFontSize: {
            fontSize: '12px',
        },
        avatarStyle: {
            width: '30px',
            height: '30px',
            marginLeft: '5px',
            borderRadius: "100%",
            boxShadow: "inset 0px 0px 0px 5px white",
            border: '2px solid black'
        },
        noRecord: {
            // border: '2px solid gray',
            color: 'red',
            textAlign: 'center',
            marginTop: '4px',
            fontSize: '13px'
        },
        spinnerStyle: {
            display: 'flex',
            marginTop: '5px',
            marginBottom: '5px',
        },
        spinnerStyle1: {
            marginTop: '5px',
            marginLeft: '8px',
            marginBottom: '5px',
        },
        noRecordsColor: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        toolbar: {
            "& > p:nth-of-type(2)": {
                fontSize: 12,
                color: "black",
            }
        }
    })
);

const FileAuditLog: React.FC<{ getFileId: number, categoryId: number }> = (props) => {
    const classes = useRowStyles();
    const { categoryId } = props;
    const { state } = useGlobalState();
    const [order, setOrder] = useState<any>('desc');
    const [orderBy, setOrderBy] = useState<string>("audit_Created");
    const [page, setPage] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [listFileLog, setListFileLog] = useState<IGetFileAuditLog[]>([]);
    const [progress, setProgress] = useState(false);

    useEffect(() => {
        (async () => {
            setProgress(true);
            if (props?.getFileId !== 0 || props?.getFileId !== undefined) {
                let request = {
                    categoryId: props?.categoryId,
                    fileId: props?.getFileId,
                    rowsperPage: rowsPerPage,
                    recordNumber: (page * rowsPerPage) + 1
                }
                await usePost<{ getAuditFiles: IGetFileAuditLog[], total: number }>("AuditLog/GetFilesAudit", request).then((r) => {
                    setListFileLog(r?.data["getAuditFiles"]);
                    setTotalFiles(r?.data?.total);
                    setProgress(false);
                }).finally(() => {
                    setProgress(false);
                });
            }
        })()
    }, [rowsPerPage, page])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    const headCells = [
        { id: "role", numeric: false, disablePadding: false, label: "USER ROLE", sortable: true, fontSize: '12px' },
        { id: "actionName", numeric: false, disablePadding: false, label: "ACTION TYPE", sortable: true, fontSize: '12px' },
        { id: "actionText", numeric: false, disablePadding: false, label: "ACTION DESCRIPTION", sortable: true, fontSize: '12px' },
        { id: "status", numeric: false, disablePadding: false, label: "STATUS", sortable: true, fontSize: '12px' },
        { id: "audit_Created", numeric: false, disablePadding: false, label: "CREATED DATE", sortable: true, fontSize: '12px' },
        { id: "audit_Modified", numeric: false, disablePadding: false, label: "MODIFIED DATE", sortable: true, fontSize: '12px' },
    ];

    const headCellsTrustStatement = [
        { id: "role", numeric: false, disablePadding: false, label: "USER ROLE", sortable: true, fontSize: '12px' },
        { id: "actionName", numeric: false, disablePadding: false, label: "ACTION TYPE", sortable: true, fontSize: '12px' },
        { id: "actionText", numeric: false, disablePadding: false, label: "ACTION DESCRIPTION", sortable: true, fontSize: '12px' },
        { id: "audit_Created", numeric: false, disablePadding: false, label: "CREATED DATE", sortable: true, fontSize: '12px' },
        { id: "errorName", numeric: false, disablePadding: false, label: "ERROR DESCRIPTION", sortable: true, fontSize: '12px' }, // Added for category 7
    ];

    function EnhancedTableHead(props) {
        const {
            classes,
            order,
            orderBy,
            onRequestSort,
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };

        // Conditionally modify the headCells array based on the category
        const updatedHeadCells = categoryId === 7
            ? headCellsTrustStatement
            : headCells;

        return (
            <TableHead>
                <TableRow >
                    {updatedHeadCells?.map(headCell => (
                        <TableCell
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.rowhead}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label || headCell.sortable}
                                {orderBy === headCell.id || headCell.sortable ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <div className={classes.divColor}>
            {!listFileLog?.length && progress ?
                <React.Fragment>
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%" className={classes.spinnerStyle}>
                        <div className="loader3" />
                        <span className={classes.spinnerStyle1}>Loading Logs</span>
                    </Box>
                </React.Fragment>
                :
                <React.Fragment>
                    {!listFileLog?.length && !progress ?
                        <React.Fragment>
                            <div className={classes.noRecordsColor}>
                                <img src={emptyFile} alt="Icon for no logs to display" width="40px" height="40px" />
                            </div>
                            <Typography variant='h6' className={classes.noRecord}>
                                No logs to display
                            </Typography>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <TableContainer component={Paper} className={classes.divPadding}>
                                <Table aria-label="collapsible table" size="small" stickyHeader >
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {stableSort(listFileLog, getComparator(order, orderBy)).map((r) => {
                                            return (
                                                <TableRow >
                                                    <TableCell >
                                                        {r?.role === 3 ?
                                                            <AvatarTooltip title="Super Admin">
                                                                <Avatar className={classes.avatarStyle} src={SuperAdmin}></Avatar>
                                                            </AvatarTooltip>
                                                            : r?.role === 2 ?
                                                                <AvatarTooltip title="Global Admin">
                                                                    <Avatar className={classes.avatarStyle} src={GlobalAdmin}></Avatar>
                                                                </AvatarTooltip>
                                                                : r?.role === 1 ?
                                                                    <AvatarTooltip title="Client Admin">
                                                                        <Avatar className={classes.avatarStyle} src={ClientAdmin}></Avatar>
                                                                    </AvatarTooltip>
                                                                    :
                                                                    <AvatarTooltip title="System">
                                                                        <Avatar className={classes.avatarStyle} src={UserAvatar}></Avatar>
                                                                    </AvatarTooltip>
                                                        }
                                                    </TableCell>
                                                    <TableCell className={classes.tableFontSize}>
                                                        {r?.actionName}
                                                    </TableCell>
                                                    <TableCell className={classes.tableFontSize}>
                                                        {r?.actionText}
                                                    </TableCell>
                                                    {categoryId != 7 && (
                                                        <TableCell className={classes.tableFontSize}>
                                                            {r?.file_Status_Name}
                                                        </TableCell>
                                                    )}
                                                    <TableCell className={classes.tableFontSize}>
                                                        {moment(r?.created_Date).format(state?.GlobalUtils?.settingValue)}
                                                    </TableCell>
                                                    {categoryId != 7 && (
                                                        <TableCell className={classes.tableFontSize}>
                                                            {moment(r?.modified_Date).format(state?.GlobalUtils?.settingValue)}
                                                        </TableCell>
                                                    )}
                                                    {categoryId === 7 && (
                                                        <TableCell className={classes.tableFontSize}>
                                                            {r?.errorName}
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            )
                                        })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={totalFiles === undefined ? 0 : totalFiles}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                classes={{
                                    toolbar: classes.toolbar
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </div >
    )
}

export default FileAuditLog