import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { ITransactionHistory } from '../../models/Inquiry/TransactionHistory/ITransactionHistory';
import { IDebtor } from '../../models/NotificationCenter/IDebtor';
import { useGlobalState } from '../../store/GlobalStore';
import useStyles from './NCCcss/NotiCenterCss';

const NCAccountHistory: React.FC<{ debtors: IDebtor, histories: ITransactionHistory[], mapped: boolean }> = (props) => {
    const { histories } = props;
    const classes = useStyles();
    const { state, dispatch } = useGlobalState();

    const useProgressStyles = makeStyles((theme) => ({
        root: {
            position: 'relative',
        },
        bottom: {
            color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        top: {
            color: '#1a90ff',
            animationDuration: '550ms',
            position: 'absolute',
            left: 0,
        },
        circle: {
            strokeLinecap: 'round',
        },
    }));

    function Progress(props) {
        const classes = useProgressStyles();
        return (
            <div className={classes.root}>
                <CircularProgress
                    variant="determinate"
                    className={classes.bottom}
                    size={40}
                    thickness={4}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    className={classes.top}
                    classes={{
                        circle: classes.circle,
                    }}
                    size={40}
                    thickness={4}
                    {...props}
                />
            </div>
        );
    }

    return (
        <React.Fragment >
            <Box p={0} style={{ padding: '0px 10px' }}>
                <h3 className={classes.typoTitle}> Notes</h3>
                <Box p={1} width="100%" flexShrink={0} bgcolor="white" className={classes.rootHistory} >
                    <Scrollbars autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={10}
                        autoHeight
                        autoHeightMin={150}
                        autoHeightMax={150}
                        width="100%">
                        {histories.length > 0 ?
                            histories.map((Histories) => {
                                if (Histories.activityType !== "0") {
                                    return (
                                        <p className={Histories.activityType === "T" ? classes.typoRecordData1 : classes.typoRecordData2}>
                                            {moment(Histories.activityDt).format(state.GlobalUtils?.settingValue)}
                                            &nbsp;{Histories.activity?.split('|')[0]}
                                        </p>
                                    )
                                } else {
                                    return (
                                        < Typography variant="h6" gutterBottom style={{ color: "red", marginTop: "10px" }}>
                                            No Records to Display
                                        </Typography>
                                    )
                                }
                            })
                            : <Progress />
                        }
                    </Scrollbars>
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default NCAccountHistory
