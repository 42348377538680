import { Backdrop, Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from '@material-ui/core';
import React, { useState } from 'react';
// Icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// Models
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './ManageGroupCss';
import { useDelete } from '../../../utils/apiHelper';
import { PuffLoader } from 'react-spinners';

const DeleteManageGroup: React.FC<{ activityGroupId: number, onDelete: () => void }> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickDelete = () => {
        setOpen(false);
        setShowProgressBar(true);
        useDelete("AgentUser/DeleteAgentActivityGroup?ActivityGroupId=" + props?.activityGroupId).then(() => {
            props.onDelete();
        }).finally(() => {
            setShowProgressBar(false);
        });
    };

    return (
        <React.Fragment>
            <div>
                <IconButton id="CPO_Delete_btn" aria-label="delete" onClick={handleClickOpen} style={{ padding: '2px' }}>
                    <DeleteForeverIcon className={classes.deleteIcon} />
                </IconButton>
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>

                <Dialog
                    PaperProps={{ style: { borderRadius: 10 } }}
                    open={open}
                    maxWidth={'xs'}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    aria-labelledby="responsive-dialog-title" >
                    <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
                        <Typography variant="h6" gutterBottom className={classes.titleheader}>
                            DELETE GROUP DETAILS
                        </Typography>
                    </DialogTitleDelete>
                    <DialogContent>
                        <Typography variant="h6" className={classes.titleConfirm} gutterBottom>
                            Are you sure, you want to delete this Group ?
                        </Typography>
                    </DialogContent>
                    <DialogActions >
                        <Button id="CPO_OK_btn" size="small" color="primary" className={classes.yesButton} autoFocus onClick={handleClickDelete}>
                            Yes
                        </Button>
                        <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.noButton} autoFocus onClick={handleClose} variant="contained">
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment>
    );
}

export default DeleteManageGroup;