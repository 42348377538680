import DialogTitle from '@material-ui/core/DialogTitle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@mui/material/IconButton';
import React from 'react';

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

export const DialogTitleHeader = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle
            style={{
                color: "white", display: 'flex', backgroundColor: '#007FFF',
                justifyContent: 'center', alignItems: 'center', height: 40
            }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 3,
                        top: 1,
                        color: 'white',
                        padding: '4px'
                    }}
                >
                    <HighlightOffIcon style={{ fontSize: 30 }} />
                </IconButton >
            ) : null}
        </DialogTitle >
    );
};

export const DialogTitleDelete = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle
            style={{
                color: "white", display: 'flex', backgroundColor: '#007FFF',
                justifyContent: 'center', alignItems: 'center', height: 40
            }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 3,
                        top: 1,
                        color: 'white',
                        fontWeight: 'bold',
                        padding: '4px'
                    }}
                >
                    <HighlightOffIcon style={{ fontSize: 30 }} />
                </IconButton >
            ) : null}
        </DialogTitle >
    );
};

export const DialogDownloadHeader = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle
            style={{
                color: "white", display: 'flex', backgroundColor: '#007FFF',
                justifyContent: 'center', alignItems: 'center', height: 60
            }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 3,
                        top: 1,
                        color: 'white',

                    }}
                >
                    <HighlightOffIcon style={{ fontSize: 30 }} />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};