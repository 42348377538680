import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import React, { useState } from 'react';
import { INotificationCenter } from '../../models/NotificationCenter/INotificationCenter';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';

const useStyles = makeStyles((theme) => ({
    button: {
        fontSize: 12,
        backgroundColor: '#007FFF',
        borderRadius: 20,
        '&:hover': {
            backgroundColor: '#007FFF'
        }
    }
}))

const ReadNotification: React.FC<{ Notify: INotificationCenter, onRead: () => void }> = (props) => {
    const { Notify } = props;
    const classes = useStyles();
    const { dispatch } = useGlobalState();
    const [Read, setRead] = useState(Notify.readFlag);

    const ReadNotify = () => {
        (async () => {
            dispatch({ type: GlobalStateAction.Busy });
            try {
                let request = {
                    "UserNotificationId": Notify.userNotificationId,
                };
                await usePost("ReadNotification", request);
                setRead(true);
                props.onRead();
            }
            catch (ex) {
                dispatch({ type: GlobalStateAction.Error, error: ex });
                dispatch({ type: GlobalStateAction.Idle });
            }
            finally {
                dispatch({ type: GlobalStateAction.Idle });
            }
        })()
    }

    return (
        <React.Fragment>
            <Button id="NC_Read_btn" size="small" startIcon={<MarkChatReadIcon />} className={classes.button} disabled={Read || Notify.readFlag} onClick={(_) => { ReadNotify(); }} variant="contained" color="primary">
                Read
            </Button>
        </React.Fragment>
    );
}

export default ReadNotification